import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import CreateNewOrientationModal from "src/common/components/dialogs/CreateNewOrientationModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { GCCorporateOfficeOrientationsQuery } from "src/common/types/generated/relay/GCCorporateOfficeOrientationsQuery.graphql";
import OrientationModulesTable from "src/domain-features/siteorientation/components/OrientationModulesTable";
import { graphql } from "babel-plugin-relay/macro";
import { useNavigate } from "react-router-dom";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";

const GCCorporateOfficeOrientations: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const data = useLazyLoadQuery<GCCorporateOfficeOrientationsQuery>(
    graphql`
      query GCCorporateOfficeOrientationsQuery($gcId: uuid!) {
        general_contractor_connection(where: { id: { _eq: $gcId } }) {
          edges {
            node {
              gc_projects {
                project {
                  pk: id @__clientField(handle: "pk")
                  name
                }
              }
            }
          }
        }
        orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            general_contractor_id: { _eq: $gcId }
            project_id: { _is_null: true }
          }
          order_by: [{ order: asc }, { name: asc }]
          first: 10000
        )
          @connection(
            key: "GCCorporateOfficeOrientationsQuery_orientation_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name
              type
              created_at
              project_id
              user {
                name
                employee {
                  general_contractor {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    { gcId: employee.general_contractor.id },
  );
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <CreateNewOrientationModal
        visible={showCreateModal}
        gcProjectIds={data.general_contractor_connection.edges[0]?.node.gc_projects.map(
          (p) => p.project.pk,
        )}
        type="corporate"
        gcId={employee.general_contractor.id}
        onSuccess={(orientationId) => {
          setShowCreateModal(false);
          navigate(
            `/gce/hierarchy/corporate-office/orientations/${orientationId}`,
          );
        }}
        onCancel={() => setShowCreateModal(false)}
      />
      <div id="table">
        <OrientationModulesTable
          isHigherLevelLogin
          onAddOrientation={() => {
            setShowCreateModal(true);
          }}
          excludeColumns={["inperson", "require", "registration", "tv"]}
          dataSource={data.orientation_connection.edges.map((o) => ({
            ...o.node,
            project_orientations: [],
          }))}
          onRow={(item: any) => {
            const orientationId = item.pk;
            navigate(
              `/gce/hierarchy/corporate-office/orientations/${orientationId}`,
            );
          }}
        />
      </div>
    </>
  );
};

export default withAppPageLayout(
  withCustomSuspense(withCorporateAdminWrapper(GCCorporateOfficeOrientations)),
  { pageTitle: "Orientation" },
);
