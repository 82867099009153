import { OrganizationUnitProps } from "../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import GCAllOfficeLevelChilds from "../components/child-levels/GCAllOfficeLevelChilds";

const GCOrgUnitOffices: React.FC<OrganizationUnitProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  switch (organizationUnitType) {
    case "division":
      return (
        <GCAllOfficeLevelChilds
          addNewLevelInitialVals={{ divisionId: organizationUnitId }}
          divisionWhere={{ id: { _eq: organizationUnitId } }}
          buWhere={{ gc_division_id: { _eq: organizationUnitId } }}
          officeWhere={{
            gc_division_id: { _eq: organizationUnitId },
          }}
        />
      );
    case "business_unit":
      return (
        <GCAllOfficeLevelChilds
          buWhere={{ id: { _eq: organizationUnitId } }}
          divisionWhere={{ id: { _is_null: true } }}
          addNewLevelInitialVals={{ businessUnitId: organizationUnitId }}
          officeWhere={{ gc_business_unit_id: { _eq: organizationUnitId } }}
        />
      );
    default:
      return null;
  }
};

export default GCOrgUnitOffices;
