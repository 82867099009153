import { FC, useMemo } from "react";
import {
  Incident_Set_Input,
  useGetProjectInfoForIncidentQuery,
  useUpdateIncidentMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSearchParams } from "react-router-dom";
import { Button, Card, Typography } from "antd";
import IncidentSelectField from "./basic/SelectField";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../utils/IncidentTypeProps";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import IncidentSubViewLayout from "./IncidentSubViewLayout";

const IncidentProjectInfo: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();

  const { data: projectInfo } = useGetProjectInfoForIncidentQuery({
    variables: {
      projectId: incident.project_id,
    },
    fetchPolicy: "cache-first",
  });
  const projectAddressObject = projectInfo?.project.at(0)?.address;
  const { Text } = Typography;
  let projectAddress = "";
  if (projectAddressObject) {
    if (projectAddressObject.line_1) {
      projectAddress = projectAddressObject.line_1;
    }
    if (projectAddressObject.line_2) {
      projectAddress = `${projectAddress}, ${projectAddressObject.line_2}`;
    }
    if (projectAddressObject.zip_code) {
      projectAddress = `${projectAddress}, ${projectAddressObject.zip_code}`;
    }
    if (projectAddressObject.city) {
      projectAddress = `${projectAddress}, ${projectAddressObject.city}`;
    }
    if (projectAddressObject.state_code) {
      projectAddress = `${projectAddress}, ${projectAddressObject.state_code}`;
    }
  }
  const projectEmployeeOptions = useMemo(() => {
    const projectEmployees = projectInfo?.project
      .at(0)
      ?.project_employees.map((pe) => ({
        value: pe.employee.uid,
        label: pe.employee.user.name,
      }));

    return projectEmployees || [];
  }, [projectInfo]);

  const [updateIncident, { loading: updateIncidentLoading }] =
    useUpdateIncidentMutation();

  const updateIncidentProjectInfo = async (
    _set: Omit<
      Incident_Set_Input,
      "id" | "project_id" | "created_at" | "description_id"
    >,
    comment: string,
  ) => {
    const updatedIncident = { ...incident, ..._set };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          comment: comment,
          edited_by_uid: authUser.uid,
          edit_type: "project-info-edit",
          incident_id: incident.id,
        },
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          ..._set,
          id: incident.id,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  return (
    <IncidentSubViewLayout title="Project Details">
      <div className="flex flex-col gap-1">
        <Card>
          <div className="space-y-1.5">
            <div className="flex flex-col">
              <div className="text-sm font-medium text-gray-700 ">
                Project Name
              </div>
              <div>
                <Text strong className="text-base">
                  {projectInfo?.project.at(0)?.name}
                </Text>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="font-semibold text-base text-gray-700 ">
                Project Address
              </div>
              <div>
                <Text strong className="text-base">
                  {projectAddress}
                </Text>
              </div>
            </div>

            <IncidentSelectField
              title="Project Executive"
              onChange={async (option) => {
                if (option === null) {
                  await updateIncidentProjectInfo(
                    { project_executive_id: null },
                    `Removed Project Executive`,
                  );
                } else if (typeof option.value === "string")
                  await updateIncidentProjectInfo(
                    { project_executive_id: option.value },
                    `Updated Project Executive to ${option.label}`,
                  );
              }}
              options={projectEmployeeOptions}
              value={incident?.project_executive?.uid}
              loading={updateIncidentLoading}
            />

            <IncidentSelectField
              title="EH&S Area Supervisor"
              onChange={async (option) => {
                if (option === null) {
                  await updateIncidentProjectInfo(
                    { area_supervisor_id: null },
                    `Removed EH&S Area Supervisor`,
                  );
                } else if (typeof option.value === "string")
                  await updateIncidentProjectInfo(
                    { area_supervisor_id: option.value },
                    `Updated EH&S Area Supervisor to ${option.label}`,
                  );
              }}
              options={projectEmployeeOptions}
              value={incident?.area_supervisor?.uid}
              loading={updateIncidentLoading}
            />

            <IncidentSelectField
              title="Project Superintendent"
              onChange={async (option) => {
                if (option === null) {
                  await updateIncidentProjectInfo(
                    { project_superintendent_id: null },
                    `Removed Project Superintendent`,
                  );
                } else if (typeof option.value === "string")
                  await updateIncidentProjectInfo(
                    { project_superintendent_id: option.value },
                    `Updated Project Superintendent to ${option.label}`,
                  );
              }}
              options={projectEmployeeOptions}
              value={incident?.project_superintendent?.uid}
              loading={updateIncidentLoading}
            />

            <IncidentSelectField
              title="Project Safety Manager"
              onChange={async (option) => {
                if (option === null) {
                  await updateIncidentProjectInfo(
                    { project_safety_manager_id: null },
                    `Removed Project Safety Manager`,
                  );
                } else if (typeof option.value === "string")
                  await updateIncidentProjectInfo(
                    { project_safety_manager_id: option.value },
                    `Updated Project Safety manager to ${option.label}`,
                  );
              }}
              options={projectEmployeeOptions}
              value={incident?.project_safety_manager?.uid}
              loading={updateIncidentLoading}
            />
          </div>
        </Card>

        <div>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => setSearchParams({ subview: "incident_detail" })}
          >
            Save
          </Button>
        </div>
      </div>
    </IncidentSubViewLayout>
  );
};

export default withCustomSuspense(IncidentProjectInfo);
