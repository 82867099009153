import React, { FC, useRef, useState } from "react";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import StyledContent from "src/common/components/layouts/StyledContent";
import { Button, message, Popconfirm } from "antd";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { SdsTable_sds$data } from "src/common/types/generated/relay/SdsTable_sds.graphql";
import PdfViewer from "src/common/components/pdf/PdfViewer";
import {
  GCSubcontractorSDS_InsertSdsMutation,
  safety_data_sheet_insert_input,
} from "src/common/types/generated/relay/GCSubcontractorSDS_InsertSdsMutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import * as uuid from "uuid";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import AddSdsFromCompanyLibraryModal from "src/domain-features/sitesafety/safety-data-sheets/components/AddSdsFromCompanyLibraryModal";
import SdsTable from "src/domain-features/sitesafety/safety-data-sheets/components/SdsTable";
import UploadSdsModal from "src/domain-features/sitesafety/safety-data-sheets/components/UploadSdsModal";
import UploadSDSToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadSdsToSiteformModal";
import { auth } from "src/common/functions/firebase";

const insertSdsMutation = graphql`
  mutation GCSubcontractorSDS_InsertSdsMutation(
    $object: safety_data_sheet_insert_input!
  ) {
    insert_safety_data_sheet_one(object: $object) {
      ...SdsFrag @relay(mask: false)
    }
  }
`;

const GCSubcontractorSDS: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
}> = ({ data, subcontractorId, projectId }) => {
  const companySdsdata = [
    ...data.subcontractor_connection.edges[0].node.safety_data_sheets,
  ];

  const [hintVisible, setHintVisible] = useState(true);
  const companySdsTableRef = useRef<DataScrollTableRef>(null);
  const projectSdsTableRef = useRef<DataScrollTableRef>(null);
  const [scSdsPdfModal, setSCSdsPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [addSdsFromCompanyLibraryModal, setAddSdsFromCompanyLibraryModal] =
    useState(false);
  const [openSdsUploadModal, setOpenSdsUploadModal] = useState(false);
  const [openSdsUploadToSiteformModal, setOpenSdsUploadToSiteformModal] =
    useState(false);
  const [insertSds, loading] =
    useAsyncMutation<GCSubcontractorSDS_InsertSdsMutation>(insertSdsMutation);
  const addToProject = async (
    sds: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"],
  ) => {
    const sdsInsertInput: safety_data_sheet_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: projectId,
      subcontractor_id: subcontractorId,
      company_name: sds.company_name,
      product_name: sds.product_name,
      issue_date: sds.issue_date,
      reference_number: sds.reference_number,
      url: sds.url,
      file_type: sds.file_type,
      id: uuid.v4(),
      company_safety_data_sheet_id: sds.pk,
    };
    await insertSds({
      variables: {
        object: sdsInsertInput,
      },
    });
    message.success("Added to Project Library");
    projectSdsTableRef.current?.refetch();
  };

  return (
    <StyledContent padding={false} backgroundColor="white">
      <UploadSdsModal
        visible={openSdsUploadModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onClose={() => {
          setOpenSdsUploadModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setOpenSdsUploadModal(false);
          setHintVisible(true);
          projectSdsTableRef.current?.refetch();
        }}
      />

      <UploadSDSToSiteFormModal
        visible={openSdsUploadToSiteformModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onClose={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
      />

      <PdfViewer
        visible={scSdsPdfModal}
        onClose={() => {
          setSCSdsPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <AddSdsFromCompanyLibraryModal
        projectId={projectId}
        visible={addSdsFromCompanyLibraryModal}
        closeModal={() => {
          setAddSdsFromCompanyLibraryModal(false);
          setHintVisible(true);
        }}
        subcontractorId={subcontractorId}
        refresh={() => {
          projectSdsTableRef.current?.refetch();
        }}
        title={"Add SDS from Company Library"}
        tableWhere={{
          subcontractor_id: { _eq: subcontractorId },
          deleted_at: { _is_null: true },
        }}
      />

      <StyledContent padding={false} backgroundColor="white">
        <SdsTable
          loadAll
          title="Project Library"
          ref={projectSdsTableRef}
          where={{
            subcontractor_id: { _eq: subcontractorId },
            project_id: { _eq: projectId },
            deleted_at: { _is_null: true },
          }}
          excludedKeys={["projectName", "addToProjects"]}
          topBarButtons={[
            {
              label: "+ SDS",
              secondary: true,
              onClick: () => setHintVisible(true),
              hint: hintVisible ? (
                <div className="w-24">
                  <div
                    className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                    onClick={() => {
                      setOpenSdsUploadToSiteformModal(true);
                      setHintVisible(false);
                    }}
                  >
                    Easy Upload (not instant)
                  </div>
                  <div
                    className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                    onClick={() => {
                      setOpenSdsUploadModal(true);
                      setHintVisible(false);
                    }}
                  >
                    Manual Upload (instant)
                  </div>
                  <div
                    className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                    onClick={() => {
                      setAddSdsFromCompanyLibraryModal(true);
                      setHintVisible(false);
                    }}
                  >
                    Add from Company Library
                  </div>
                  <div
                    className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                    onClick={() => {
                      setAddSdsFromCompanyLibraryModal(true);
                      setHintVisible(false);
                    }}
                  >
                    Add from Database
                  </div>
                </div>
              ) : undefined,
            },
          ]}
          onSdsRowClick={(sds) => {
            setPdfUrl(sds.url);
            setSCSdsPdfModal(true);
          }}
          loading={loading}
        />
      </StyledContent>

      <StyledContent padding={false} backgroundColor="white">
        <SdsTable
          loadAll
          title="Company Library"
          ref={companySdsTableRef}
          where={{
            subcontractor_id: { _eq: subcontractorId },
            project_id: { _is_null: true },
            deleted_at: { _is_null: true },
          }}
          onSdsRowClick={(sds) => {
            setPdfUrl(sds.url);
            setSCSdsPdfModal(true);
          }}
          excludedKeys={[
            "projectName",
            "addToProjects",
            "select",
            "subcontractorName",
          ]}
          loading={loading}
          extraColumns={[
            {
              title: "Action",
              dataIndex: [""],
              size: "sm",
              key: "action",
              render: (_, sds) => {
                return (
                  <>
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={noPropagation(() => {
                        addToProject(sds);
                      })}
                      onCancel={noPropagation()}
                      okText="Yes"
                      cancelText="Cancel"
                    >
                      <Button type="link" onClick={noPropagation()}>
                        Add to Project
                      </Button>
                    </Popconfirm>
                  </>
                );
              },
              onCellClick: (record: any, event: any) => {},
            },
          ]}
        />
      </StyledContent>
    </StyledContent>
  );
};

export default GCSubcontractorSDS;
