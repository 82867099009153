import {
  Button,
  Dropdown,
  Input,
  Space,
  Table,
  TableProps,
  Typography,
} from "antd";
import getVideoType from "src/common/components/media-players/video-player/utils/getVideoType";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { videoUrlDemoExamples } from "../videoPlayerDemoUtils";

interface VideoPlayerAutoPlayDemoInputsTableProps {
  videoUrlList: string[];
  setVideoUrlList: (videoUrlList: string[]) => void;
}

type TableDataType = {
  videoUrl: string;
  key: string;
};

const VideoPlayerAutoPlayDemoInputsTable: React.FC<
  VideoPlayerAutoPlayDemoInputsTableProps
> = (props) => {
  // TODO:
  // Change sorting by drag n drop

  const onAddNewVideo = (newVideoUrl?: string) => {
    props.setVideoUrlList([...props.videoUrlList, newVideoUrl || ""]);
  };

  const dataSource = props.videoUrlList.map((videoUrl, index) => ({
    key: index + "",
    videoUrl: videoUrl,
    actions: (
      <Space>
        <Button
          onClick={() => {
            const newVideoUrlList = props.videoUrlList.filter(
              (_, i) => i !== index,
            );
            props.setVideoUrlList(newVideoUrlList);
          }}
          danger
          icon={<DeleteOutlined />}
        />
      </Space>
    ),
  }));

  const columns: TableProps<TableDataType>["columns"] = [
    {
      title: "Video URL",
      dataIndex: "videoUrl",
      key: "videoUrl",

      render: (text, record, index) => {
        return (
          <Input
            type="text"
            value={text}
            className={`w-full`}
            onChange={(e) => {
              const newVideoUrlList = [...props.videoUrlList];
              newVideoUrlList[index] = e.target.value;
              props.setVideoUrlList(newVideoUrlList);
            }}
          />
        );
      },
    },
    {
      title: "Video Type",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        return (
          <Typography.Text code>
            {getVideoType(record.videoUrl) + ""}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const fillRandomVideos = () => {
    const arrayLength = videoUrlDemoExamples.length;
    // Randomize the order of the videos
    const randomIndexes = Array.from({ length: arrayLength }, (_, i) => i);
    for (let i = arrayLength - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [randomIndexes[i], randomIndexes[j]] = [
        randomIndexes[j],
        randomIndexes[i],
      ];
    }
    const randomVideos = randomIndexes.map(
      (randomIndex) => videoUrlDemoExamples[randomIndex].url,
    );
    props.setVideoUrlList(randomVideos);
  };
  return (
    <Table<TableDataType>
      pagination={false}
      footer={() => (
        <Space>
          <Space.Compact block>
            <Dropdown.Button
              icon={<DownOutlined />}
              menu={{
                items: videoUrlDemoExamples.map((videoExample) => ({
                  key: videoExample.url,
                  label: videoExample.title,
                  onClick: () => {
                    onAddNewVideo(videoExample.url);
                  },
                })),
              }}
              onClick={() => {
                onAddNewVideo();
              }}
            >
              Add Video
            </Dropdown.Button>
          </Space.Compact>
          <Button onClick={fillRandomVideos}>Randomize</Button>
        </Space>
      )}
      dataSource={dataSource}
      columns={[...columns]}
    />
  );
};

export default VideoPlayerAutoPlayDemoInputsTable;
