import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import GCBuObservations from "../levels/business-unit/observation/GCBuObservations";
import GCDivisionObservations from "../levels/division/observation/GCDivisionObservations";
import GCOfficeObservations from "../levels/office/observation/GCOfficeObservations";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";

const GCOrgUnitObservations: React.FC<OrganizationUnitProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  switch (organizationUnitType) {
    case "division":
      return <GCDivisionObservations divisionId={organizationUnitId} />;
    case "business_unit":
      return <GCBuObservations businessUnitId={organizationUnitId} />;
    case "office":
      return <GCOfficeObservations officeId={organizationUnitId} />;
  }
};

export default withAppPageLayout(
  withOrganizationUnitWrapper(GCOrgUnitObservations),
  { pageTitle: "Observations" },
);
