import {
  Alert,
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Popconfirm,
} from "antd";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import pluralize from "pluralize";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import useAuthUser from "src/common/hooks/useAuthUser";
import { DeliveryType } from "../utilities/sitedeliveryTypes";
import {
  DeliveryCompanyId,
  DeliveryCompany,
} from "./SitedeliveryAddNewDeliveryModal";
import {
  useUpdateDeliveryMutation,
  GetDeliveryFieldsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import useManageDeliveryTexts from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/useManageDeliveryTexts";
import * as uuid from "uuid";

interface DeliveryDetailsDrawerProps {
  delivery: DeliveryType;
  onClose: () => void;
  visible: boolean;
  calendars: { id: string; title: string }[];
  deliveryCompanies: DeliveryCompany[];
}

const SitedeliveryDetailsDrawer: React.FC<DeliveryDetailsDrawerProps> = ({
  delivery,
  onClose,
  visible,
  calendars,
  deliveryCompanies,
}) => {
  const { manageDeliveryTexts } = useManageDeliveryTexts();
  const [updateDelivery] = useUpdateDeliveryMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const durationOptions = useMemo(
    () =>
      [...Array(47)].map((_, i) => {
        if (i % 2 == 0)
          return {
            title:
              (i != 0
                ? pluralize("hour", Math.floor((i + 1) * 0.5), true) + " "
                : "") +
              "30 " +
              "minutes",
            value: (i + 1) * 0.5,
          };
        else
          return {
            title: pluralize("hour", Math.floor((i + 1) * 0.5), true),
            value: (i + 1) * 0.5,
          };
      }),
    [],
  );
  const authUser = useAuthUser();
  function GetDeliveryCompanyId(company: DeliveryCompanyId): string {
    switch (company.type) {
      case "sub":
        return `sub_${company.subId}`;
      case "gc":
        return `gc_${company.gcId}`;
      default:
        throw new Error("Uknown company type");
    }
  }

  const startEditing = () => {
    form.setFieldsValue({
      name: delivery.title.text,
      calendars: delivery.calendars.map((cal) => cal.id),
      dateAndTime: dayjs(delivery.from),
      detail: delivery.detail?.text,
      storageLocation: delivery.storageLocation?.text,
      duration: dayjs(delivery.to).diff(dayjs(delivery.from), "hours", true),
    });
    setIsEditing(true);
  };

  const handleTextTranslationUpdate = async (
    currentText: { text: string; id: string } | undefined,
    newText: string,
    fieldName: string,
    deliveryId: string,
  ) => {
    await manageDeliveryTexts(newText, fieldName, deliveryId, currentText);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const values = await form.validateFields().catch(() => null);
      if (!values) return;

      const initialValues = {
        name: delivery.title.text,
        calendarIds: delivery.calendars.map((cal) => cal.id),
        startAt: delivery.from,
        durationIndex: durationOptions.findIndex(
          (opt) =>
            opt.value ===
            dayjs(delivery.to).diff(dayjs(delivery.from), "hours", true),
        ),
        detail: delivery.detail?.text,
        storageLocation: delivery.storageLocation?.text,
        company: GetDeliveryCompanyId(deliveryCompanies[0]),
      };

      const insertCalendarsIds = values.calendars.filter(
        (id: string) => !initialValues.calendarIds.includes(id),
      );
      const deleteCalendarIds = initialValues.calendarIds.filter(
        (id: string) => !values.calendars.includes(id),
      );

      const isChanged =
        values.dateAndTime?.toString() !== initialValues.startAt?.toString() ||
        values.name !== initialValues.name ||
        values.duration !==
          dayjs(delivery.to).diff(dayjs(delivery.from), "hours", true) ||
        values.detail !== initialValues.detail ||
        values.storageLocation !== initialValues.storageLocation ||
        insertCalendarsIds.length > 0 ||
        deleteCalendarIds.length > 0;

      if (isChanged) {
        await updateDelivery({
          variables: {
            id: delivery.id,
            set: {
              start_at: dayjs(values.dateAndTime).toISOString(),
              duration: values.duration,
              updated_at: dayjs().format(),
              updated_by_user_id: authUser.uid,
            },
            deleteFilterCalendarWhere: {
              id: { _eq: delivery.id },
            },
            includeDeleteFilterCalendar: true,
            deleteCalendarId: deleteCalendarIds,
            insertDeliveryCalendar: insertCalendarsIds.map((calId: string) => ({
              id: uuid.v4(),
              delivery_id: delivery.id,
              calendar_id: calId,
            })),
          },
          update: (cache, { data }) => {
            const deliveryCalendars =
              data?.insert_delivery_calendar?.returning || [];
            cache.modify<GetDeliveryFieldsQuery["delivery"][number]>({
              id: cache.identify({ __typename: "delivery", id: delivery.id }),
              fields: {
                calendars: (currList = [], { toReference, readField }) => {
                  return [
                    ...currList.filter(
                      (c) =>
                        readField("calendar_id", c) &&
                        !deleteCalendarIds.includes(
                          readField("calendar_id", c) || "",
                        ),
                    ),
                    ...deliveryCalendars.map((delCal) => toReference(delCal)!),
                  ];
                },
              },
            });
          },
          optimisticResponse: {
            delete_delivery_calendar: {
              returning: [],
            },
            insert_delivery_calendar: {
              returning: [],
            },
            update_delivery_by_pk: {
              __typename: "delivery",
              id: delivery.id,
              status: delivery.reviewed?.type,
              start_at: dayjs(values.dateAndTime).toISOString(),
              duration: values.duration,
            },
          },
        });
      }
      await Promise.all([
        handleTextTranslationUpdate(
          { text: delivery.title.text, id: delivery.title.id },
          values.name,
          "title",
          delivery.id,
        ),
        handleTextTranslationUpdate(
          delivery.detail
            ? { text: delivery.detail.text, id: delivery.detail.id }
            : undefined,
          values.detail,
          "detail",
          delivery.id,
        ),
        handleTextTranslationUpdate(
          delivery.storageLocation
            ? {
                text: delivery.storageLocation.text,
                id: delivery.storageLocation.id,
              }
            : undefined,
          values.storageLocation,
          "storage_location",
          delivery.id,
        ),
      ]);

      message.success("Delivery updated successfully");
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const handleStatusUpdate = async (status: "Approved" | "Rejected") => {
    await updateDelivery({
      variables: {
        id: delivery.id,
        set: { status },
        deleteFilterCalendarWhere: { id: { _is_null: true } },
        includeDeleteFilterCalendar: false,
        deleteCalendarId: [],
        insertDeliveryCalendar: [],
      },
      optimisticResponse: {
        update_delivery_by_pk: {
          __typename: "delivery",
          id: delivery.id,
          status,
          start_at: delivery.from.toISOString(),
          duration: dayjs(delivery.to).diff(
            dayjs(delivery.from),
            "hours",
            true,
          ),
        },
      },
    });

    if (status === "Rejected") {
      onClose();
    }
  };

  const renderContent = () => {
    if (!isEditing) {
      return (
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="flex">
              <span className="font-accent min-w-12">Delivery Name:</span>
              <span>{delivery.title.text}</span>
            </div>

            <div className="flex">
              <span className="font-accent min-w-12">Status:</span>
              <span
                className={`${
                  delivery.reviewed?.type === "reject"
                    ? "text-red-500"
                    : delivery.reviewed?.type === "approve"
                    ? "text-green-500"
                    : "text-yellow-500"
                }`}
              >
                {delivery.reviewed?.type === "approve"
                  ? "Approved"
                  : delivery.reviewed?.type === "reject"
                  ? "Rejected"
                  : "Pending"}
              </span>
            </div>

            <div className="flex">
              <span className="font-accent min-w-12">Company</span>
              <span>
                {"subcontractor" in delivery && delivery.subcontractor
                  ? delivery.subcontractor.title
                  : "generalContractor" in delivery &&
                    delivery.generalContractor
                  ? delivery.generalContractor.title
                  : "Unknown"}
              </span>
            </div>

            <div className="flex">
              <span className="font-accent min-w-12">Calendars:</span>
              <span>
                {delivery.calendars.map((cal) => cal.title).join(", ")}
              </span>
            </div>

            <div className="flex">
              <span className="font-accent min-w-12">Date and Time:</span>
              <span>{dayjs(delivery.from).format("MMM DD, YYYY HH:mm A")}</span>
            </div>

            <div className="flex">
              <span className="font-accent min-w-12">Duration:</span>
              <span>
                {(() => {
                  const diffInMinutes = dayjs(delivery.to).diff(
                    dayjs(delivery.from),
                    "minutes",
                  );
                  if (diffInMinutes < 60) {
                    return `${diffInMinutes} minutes`;
                  }
                  const hours = Math.floor(diffInMinutes / 60);
                  const minutes = diffInMinutes % 60;
                  return minutes > 0
                    ? `${hours} hours ${minutes} minutes`
                    : `${hours} hours`;
                })()}
              </span>
            </div>

            {delivery.detail && (
              <div className="flex">
                <span className="font-accent min-w-12">Details:</span>
                <span>{delivery.detail.text}</span>
              </div>
            )}

            {delivery.storageLocation && (
              <div className="flex">
                <span className="font-accent min-w-12">Storage Location:</span>
                <span>{delivery.storageLocation.text}</span>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <Form form={form} layout="vertical">
        {errorMessage && (
          <Alert message={errorMessage} type="error" className="mb-4" />
        )}

        <Form.Item
          label="Delivery Name"
          name="name"
          rules={[{ required: true, message: "Enter Name of the delivery" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={"Select which Calendar(s)"}
          name={"calendars"}
          rules={[
            {
              required: true,
              message: "Select atleast one calendar for the delivery",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={
              "What access point(s), equipment, lifts are required for this delivery?"
            }
            showSearch
            filterOption={getNormalSelectOptionsFilter}
            options={calendars.map((c) => ({
              value: c.id,
              label: c.title,
            }))}
          />
        </Form.Item>

        {deliveryCompanies.length > 0 && (
          <Form.Item
            label={"Delivery for"}
            name={"deliveryCompanyId"}
            initialValue={GetDeliveryCompanyId(deliveryCompanies[0])}
            rules={[
              { required: true, message: "Select whom the delivery is for" },
            ]}
          >
            <Select
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              options={deliveryCompanies.map((company) => ({
                label: company.name,
                value: GetDeliveryCompanyId(company),
              }))}
            />
          </Form.Item>
        )}

        <Form.Item
          label={"Date and Time of Delivery"}
          name={"dateAndTime"}
          rules={[
            {
              required: true,
              message: "Select date and time of the delivery",
            },
          ]}
          // initialValue={moment().format("YYYY-MM-DD h:mm A")}
        >
          <DatePicker
            showTime={{
              format: "h:mm A",
              minuteStep: 5,
            }}
            minDate={dayjs().subtract(30, "days")}
            placeholder="When is the delivery arriving?"
            format="YYYY-MM-DD h:mm A"
            className="w-full"
          />
        </Form.Item>

        <Form.Item
          label={"Duration of Delivery"}
          name={"duration"}
          rules={[
            { required: true, message: "Select duration of the delivery" },
          ]}
        >
          <Select placeholder={"Duration of the Delivery"}>
            {durationOptions.map((option) => (
              <Select.Option
                id={option.value}
                value={option.value}
                label={option.title}
              >
                {option.title}
              </Select.Option>
            ))}{" "}
          </Select>
        </Form.Item>

        <Form.Item label={"Delivery Contents and Details"} name={"detail"}>
          <Input placeholder="What is being delivered?" />
        </Form.Item>
        <Form.Item label={"Storage Location"} name={"storageLocation"}>
          <Input placeholder="Where will it be stored?" />
        </Form.Item>

        <div className="flex justify-end space-x-2">
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button type="primary" onClick={handleSave} loading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    );
  };

  const headerActions = (
    <div className="flex flex-row items-center gap-1">
      {!isEditing && delivery.pendingApproval && (
        <>
          <Popconfirm
            title="Approve Delivery"
            description="Are you sure you want to approve this delivery?"
            onConfirm={() => handleStatusUpdate("Approved")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Approve</Button>
          </Popconfirm>
          <Popconfirm
            title="Reject Delivery"
            description="Are you sure you want to reject this delivery?"
            onConfirm={() => handleStatusUpdate("Rejected")}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            <Button type="primary" danger>
              Reject
            </Button>
          </Popconfirm>
        </>
      )}
      {!isEditing && (
        <Button type="primary" onClick={startEditing}>
          Edit
        </Button>
      )}
    </div>
  );

  return (
    <Drawer
      title="Delivery Details"
      placement="right"
      onClose={onClose}
      open={visible}
      width={"30%"}
      extra={headerActions}
    >
      {renderContent()}
    </Drawer>
  );
};

export default SitedeliveryDetailsDrawer;
