import { FC } from "react";
import ReportDetail from "src/domain-features/sitesafety/components/ReportDetail";
import { useGetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface Props {
  projectId: string;
  reportId: string;
}

const GCProjectReportsPTPsDetail: FC<Props> = ({ reportId, projectId }) => {
  const { data } = useGetReportByPkQuery({
    variables: { reportId },
  });

  return (
    data && (
      <ReportDetail
        showBackButton
        routeId={{ type: "gce", id: projectId }}
        report={data.report_by_pk as any}
      />
    )
  );
};

export default GCProjectReportsPTPsDetail;
