import NestedRoute from "src/common/types/manual/NestedRoute";
import { Outlet } from "react-router-dom";
import React from "react";
import GCProjectReportsPermits from "./GCProjectReportsPermits";
import GCProjectPermitDetailPage from "./GCProjectPermitDetailPage";

const permitsRoute: NestedRoute = {
  element: <Outlet />,
  handle: {
    breadcrumb: "Permits",
  },
  children: [
    {
      path: "",
      element: <GCProjectReportsPermits />,
    },
    {
      path: ":permitType",
      children: [
        {
          path: ":permitId",
          element: <GCProjectPermitDetailPage />,
          handle: {
            breadcrumb: "Permit Detail",
          },
        },
      ],
    },
  ],
};

export default permitsRoute;
