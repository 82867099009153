import { SortOrder } from "./ScrollTable";

// TODO: it can be moved to separate file:
export type SortOrderStorageRecord = {
  key: string;
  order: SortOrder;
};

export function isSortOrder(value: unknown): value is SortOrder {
  return value === "asc" || value === "desc" || value === null;
}
export function isSortOrderStorageRecord(
  value: unknown,
): value is SortOrderStorageRecord {
  return (
    value !== null &&
    typeof value === "object" &&
    "key" in value &&
    typeof value.key === "string" &&
    "order" in value &&
    isSortOrder(value.order)
  );
}

// declare read/write logic separately
export function loadSortOrderRecord(
  sortOrderStorageKey: string,
): SortOrderStorageRecord | null {
  const item = localStorage.getItem(sortOrderStorageKey);
  if (!item) return null;
  try {
    const value = JSON.parse(item);
    return isSortOrderStorageRecord(value) ? value : null;
  } catch (error) {
    return null;
  }
}

export function saveSortOrderRecord(
  sortOrderStorageKey: string,
  value: SortOrderStorageRecord,
) {
  localStorage.setItem(sortOrderStorageKey, JSON.stringify(value));
}

export function removeSortOrderRecord(sortOrderStorageKey: string) {
  localStorage.removeItem(sortOrderStorageKey);
}
