import AudioPlayer from "../../../../../common/components/media-players/audio-player/AudioPlayer";
import { useState } from "react";
import { Card, Input } from "antd";
import { LanguageProvider } from "../../../../../utility-features/i18n/context/languageContext";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";

const audioSrcOptios = [
  "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
];

const AudioPlayerDemo: React.FC = () => {
  const [audioSrc, setAudioSrc] = useState<string>(audioSrcOptios[0]);

  return (
    <AppPageLayout pageTitle={`Audio Player Demo`}>
      <Card title={"AudioPlayer"}>
        <div className={`flex flex-col gap-1`}>
          <Input
            value={audioSrc}
            onChange={(e) => setAudioSrc(e.target.value)}
            placeholder={"Audio Source"}
          />
          <LanguageProvider>
            <AudioPlayer
              allowedControls={["skip", "rewind"]}
              audioSrc={audioSrc}
            />
          </LanguageProvider>
        </div>
      </Card>
    </AppPageLayout>
  );
};

export default AudioPlayerDemo;
