import React from "react";
import { Button, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";

interface IncidentBottomButtonsProps {
  onNextClick: () => void;
  onSave: () => void;
  saveDisabled: boolean;
}

const IncidentBottomButtons: React.FunctionComponent<
  IncidentBottomButtonsProps
> = ({ onNextClick, onSave, saveDisabled }) => {
  return (
    <Space>
      <Button type="primary" htmlType="submit" onClick={onNextClick}>
        Next
      </Button>
      <Button
        type="primary"
        icon={<CheckOutlined />}
        htmlType="submit"
        onClick={onSave}
        disabled={saveDisabled}
      >
        Section Complete
      </Button>
    </Space>
  );
};

export default IncidentBottomButtons;
