/**
 * @generated SignedSource<<5f5e391619854c22865b732f03415dca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type ProcoreProjectConfigModal_procoreProjectData_Query$variables = {
  projectId: string;
};
export type ProcoreProjectConfigModal_procoreProjectData_Query$data = {
  readonly procore_project_data_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly integration_enabled: boolean;
        readonly procore_company_id: string | null | undefined;
        readonly procore_company_name: string | null | undefined;
        readonly procore_project_id: string | null | undefined;
        readonly procore_project_name: string | null | undefined;
        readonly use_dmsa: boolean;
      };
    }>;
  };
};
export type ProcoreProjectConfigModal_procoreProjectData_Query = {
  response: ProcoreProjectConfigModal_procoreProjectData_Query$data;
  variables: ProcoreProjectConfigModal_procoreProjectData_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "project_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "procore_project_dataConnection",
    "kind": "LinkedField",
    "name": "procore_project_data_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "procore_project_dataEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_project_data",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "procore_project_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "procore_project_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "procore_company_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "procore_company_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integration_enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "use_dmsa",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreProjectConfigModal_procoreProjectData_Query",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcoreProjectConfigModal_procoreProjectData_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8a58592bb665676d5b0761169462f1da",
    "id": null,
    "metadata": {},
    "name": "ProcoreProjectConfigModal_procoreProjectData_Query",
    "operationKind": "query",
    "text": "query ProcoreProjectConfigModal_procoreProjectData_Query(\n  $projectId: uuid!\n) {\n  procore_project_data_connection(where: {project_id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        procore_project_id\n        procore_project_name\n        procore_company_id\n        procore_company_name\n        integration_enabled\n        use_dmsa\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e890618234c0b31aec3f54ce38f41e85";

export default node;
