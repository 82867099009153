import clsx from "clsx";
import React from "react";
import { Button, Tooltip } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import useDeliveryHeaderOffset from "../utilities/useDeliveryHeaderOffset";

type SitedeliveryCalendarWeekChangeButtonInterface = { onClick: () => void } & (
  | { next: true }
  | { prev: true }
);

const SitedeliveryCalendarWeekChangeButton: React.FC<
  SitedeliveryCalendarWeekChangeButtonInterface
> = (props) => {
  const topOffset = useDeliveryHeaderOffset();
  return (
    <div className="relative z-50 flex flex-col items-center justify-start">
      <div
        className={clsx(
          "sticky bg-white",
          "next" in props ? "right-0" : "left-0",
        )}
        style={{
          top: topOffset,
        }}
      >
        {"next" in props ? (
          <Tooltip title={"Next week"}>
            <Button icon={<ArrowRightOutlined />} onClick={props.onClick} />
          </Tooltip>
        ) : (
          <Tooltip title={"Previous week"}>
            <Button icon={<ArrowLeftOutlined />} onClick={props.onClick} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SitedeliveryCalendarWeekChangeButton;
