import { notification } from "antd";

const showUserErrorMessage: (config: { message: string, description?: string }) => void = (config) => {
    notification.error({
        message: config.message,
        description: config.description ?? "Please try again.",
      });
  
  };
  
export default showUserErrorMessage;