import dayjs from "dayjs";
import React, { useMemo } from "react";
import GCSubOnsitePerformanceTableUi from "src/domain-features/sitesafety/components/performance/GCSubOnsitePerformanceTableUi";
import {
  GetCompletionAuditV4Data,
  useGetCompletionAuditV4Query,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ExtendedRow } from "./gcSubOnsitePerformanceTypes";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import useGetSwitchers from "../onsite-settings/useGetSwitchersOld";

interface SubColumnType {
  title: string;
  id: string;
  startDate: string;
  endDate: string;
}

type ReportTypeType = "dailyReport" | "ptpReport";

const getSubColumnsData = ({
  dailyCompletion,
  monthlyCompletion,
  timezone,
  subColumnData,
  reportType,
}: {
  dailyCompletion: GetCompletionAuditV4Data["dailyCompletion"];
  reportType: "safety" | "daily";
  timezone: string;
  monthlyCompletion: GetCompletionAuditV4Data["monthlyCompletion"];
  subColumnData: Array<SubColumnType>;
}): ExtendedRow["columnsData"] => {
  const reportSubmissionCountKey =
    `${reportType}_reports_completion_count` as const;
  const repotrTotalCountKey = `total_${reportType}_reports_required` as const;
  return new Map(
    subColumnData.map((col) => {
      const colData = { total: 0, submitted: 0, missingDates: [] as Date[] };
      [...monthlyCompletion].reverse().forEach((monthCompletion) => {
        if (
          dayjs(col.startDate).startOf("month") <=
            dayjs(monthCompletion.start_date) &&
          dayjs(col.endDate).endOf("month") >= dayjs(monthCompletion.start_date)
        ) {
          monthCompletion[`missing_${reportType}_report_dates`]?.forEach(
            (date) =>
              colData.missingDates.push(dayjs.tz(date, timezone).toDate()),
          );
          colData.total += monthCompletion[repotrTotalCountKey];
          colData.submitted += monthCompletion[reportSubmissionCountKey];
        }
      });
      dailyCompletion.forEach((completion) => {
        if (
          dayjs(col.startDate) <= dayjs(completion.date) &&
          dayjs(col.endDate) >= dayjs(completion.date)
        ) {
          const submitted = completion[reportSubmissionCountKey];
          const total = completion[repotrTotalCountKey];
          colData.total += total;
          colData.submitted += submitted;
          if (total > submitted)
            colData.missingDates.push(
              dayjs.tz(completion.date, timezone).toDate(),
            );
        }
      });

      return [col.id, colData];
    }),
  );
};

export interface SubOnsiteData {
  subId: string;
  subName: string;
  projectSubId: string;
  onsite: boolean;
  safety_reports_required: boolean;
  toolbox_talks_required: boolean;
  daily_reports_required: boolean;
  crews: Array<{
    crewName: string;
    crewLead?: string;
    crewId: string;
    deleted_at?: string | null;
    onsite: boolean;
    safety_reports_required: boolean;
    toolbox_talks_required: boolean;
    daily_reports_required: boolean;
  }>;
}

export interface GCDashAppReportsTableProps {
  reportType: ReportTypeType;
  projectId: string;
  start: dayjs.Dayjs;
  projectTimezone: string;
  subOnsiteData?: SubOnsiteData[];
  onsiteSwitcher?: boolean;
  crewLeads: { [id: string]: string };
}

const GCSubOnsitePerformanceTable: React.FC<GCDashAppReportsTableProps> = ({
  projectId,
  subOnsiteData,
  onsiteSwitcher,
  reportType,
  projectTimezone,
  crewLeads,
  start,
}) => {
  const { data, loading } = useGetCompletionAuditV4Query({
    variables: {
      input: {
        startDate: dayjs(start)
          .clone()
          // .subtract(1, "year")
          .tz(projectTimezone)
          .startOf("day")
          .format("YYYY-MM-DD"),
        endDate: dayjs().tz(projectTimezone).endOf("day").format("YYYY-MM-DD"),
        projectId,
        timezone: projectTimezone,
      },
    },
    fetchPolicy: "network-only",
  });
  console.log(data?.getCompletionAuditV4);
  const { getSubSwitchersFor, getCrewSwitchersFor } =
    useGetSwitchers(projectId);
  const subColumnData: Array<SubColumnType> = useMemo(
    () => [
      {
        title: "All time",
        id: "all_time",
        startDate: dayjs(start).format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      },
      {
        title: "30D MA",
        id: "30D_MA",
        startDate:
          dayjs(start).format("YYYY-MM-DD") >
          dayjs().subtract(29, "d").format("YYYY-MM-DD")
            ? dayjs(start).format("YYYY-MM-DD")
            : dayjs().subtract(29, "d").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      },
      {
        title: "Last week",
        id: "last_week",
        startDate:
          dayjs(start).format("YYYY-MM-DD") >
          dayjs().startOf("week").subtract(7, "d").format("YYYY-MM-DD")
            ? dayjs(start).format("YYYY-MM-DD")
            : dayjs().startOf("week").subtract(7, "d").format("YYYY-MM-DD"),
        endDate:
          dayjs(start).format("YYYY-MM-DD") >
          dayjs().startOf("week").subtract(1, "d").format("YYYY-MM-DD")
            ? dayjs(start).format("YYYY-MM-DD")
            : dayjs().startOf("week").subtract(1, "d").format("YYYY-MM-DD"),
      },
      {
        title: "This week",
        id: "this_week",
        startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      },
    ],
    [start],
  );

  if (loading) {
    return <LoadingContent />;
  }
  const rows: ExtendedRow[] = (data?.getCompletionAuditV4 || []).map((sub) => {
    const subPermanentData = subOnsiteData?.find((p) => p.subId === sub.id);
    const currentCrews = sub.crewData.filter((c) => !c.is_deleted);

    return {
      id: sub.id,
      title:
        sub.name +
        (currentCrews.length > 1 ? ` (${currentCrews.length} Crews) ` : ""),
      switchers:
        onsiteSwitcher && subPermanentData
          ? [getSubSwitchersFor(subPermanentData).onsite]
          : [],
      columnsData: getSubColumnsData({
        dailyCompletion: sub.dailyCompletion,
        monthlyCompletion: sub.monthlyCompletion,
        reportType: reportType === "dailyReport" ? "daily" : "safety",
        subColumnData,
        timezone: projectTimezone,
      }),
      childRows: currentCrews.map((crew) => {
        const crewPermanentData = subPermanentData
          ? subPermanentData.crews.find((c) => c.crewId === crew.id)
          : undefined;
        return {
          id: crew.id,
          title:
            crew.name + (crewLeads[crew.id] ? `, ${crewLeads[crew.id]}` : ""),
          switchers:
            onsiteSwitcher && subPermanentData && crewPermanentData
              ? [
                  getCrewSwitchersFor(crewPermanentData, subPermanentData)
                    .onsite,
                ]
              : [],
          parentId: sub.id,
          columnsData: getSubColumnsData({
            dailyCompletion: crew.dailyCompletion,
            monthlyCompletion: crew.monthlyCompletion,
            reportType: reportType === "dailyReport" ? "daily" : "safety",
            subColumnData,
            timezone: projectTimezone,
          }),
        };
      }),
    };
  });

  return (
    <GCSubOnsitePerformanceTableUi
      id="sub"
      rows={rows}
      columns={subColumnData}
      switchTitles={[{ title: "Onsite" }]}
      initiallyHideChilds
      title="Subcontractors"
    />
  );
};

export default GCSubOnsitePerformanceTable;
