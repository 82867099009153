import withAppPageLayout from "../../../../../../common/components/layouts/main/content/withAppPageLayout";
import React from "react";
import withProjectIdUrlParam from "../../../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../../../common/types/manual/ProjectIdProps";

import useGcOrientationData from "../../../../utils/useGcOrientationData";
import { Typography } from "antd";
import Button from "../../../../../../common/components/general/button/Button";
import InPersonQR from "../settings/components/qr/InPersonQRs";
import RemoteQR from "../settings/components/qr/RemoteQR";
import SiteOrientationQuizQRCode from "../settings/components/qr/SiteOrientationQuizQRCode";
import GCProjectOrientationLinkedMessage from "../settings/GCProjectOrientationLinkedMessage";

const GCProjectOrientationQrCodes: React.FC<ProjectIdProps> = (props) => {
  const { getProjectOrientation, orientationData: data } = useGcOrientationData(
    props.projectId,
  );
  const projectData = getProjectOrientation();

  return projectData.orientation_project_id === props.projectId ? (
    <div className={`flex flex-col gap-1 w-full`}>
      {projectData.orientation_tv_player &&
      projectData.in_person_orientation ? (
        <>
          <div className="flex justify-center w-full">
            {/*TODO reuse the same qr code component*/}
            <div
              className={`p-2 rounded-1.5 w-full bg-suplementary-1 max-w-48`}
            >
              <div className={`flex flex-row gap-2`}>
                <div className={`flex-1 flex flex-col gap-1`}>
                  <p className={`text-1.5 font-accent`}>
                    Start In-Person Orientation
                  </p>
                  <Typography className={`text-static-secondary`}>
                    <ul className="list-disc">
                      <li>Click this link to start an orientation.</li>
                      <li>
                        SiteForm will display a QR code that workers can scan
                        with their devices. Registered workers will be displayed
                        on the TV.
                      </li>
                      <li>
                        Once the orientation starts, do not refresh or reload
                        the webpage.
                      </li>
                      <li>
                        If your orientation includes a Quiz, a QR Code will
                        display on the final screen to take the quiz.
                      </li>
                    </ul>
                  </Typography>
                </div>
                <div className={`w-12 relative`}>
                  <Button
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/gce/orientation/inperson-screen/${props.projectId}`,
                        "_blank",
                      )
                    }
                    label="Start Orientation"
                  ></Button>
                </div>
              </div>
            </div>
            <div className="ml-2 w-1/6"></div>
          </div>
          <InPersonQR />
          <RemoteQR
            agcUniversalOrientation={projectData.agc_universal_orientation}
            projectId={props.projectId}
          />
        </>
      ) : (
        <>
          <RemoteQR
            agcUniversalOrientation={projectData.agc_universal_orientation}
            projectId={props.projectId}
          />
          <InPersonQR />
        </>
      )}
      <div className="flex justify-center w-full">
        <SiteOrientationQuizQRCode
          projectId={props.projectId}
          destinationUrl={
            data.quiz_code.edges[0]
              ? `${document.location.origin}/singInLink/${data.quiz_code.edges[0].node.pk}?projectId=${props.projectId}`
              : undefined
          }
        />
        <div className="ml-2 w-1/6"></div>
      </div>
    </div>
  ) : (
    <GCProjectOrientationLinkedMessage
      linkedProjectId={projectData.orientation_project_id}
    />
  );
};

export default withAppPageLayout(
  withProjectIdUrlParam(GCProjectOrientationQrCodes),
  {
    pageTitle: "Start Orientation",
  },
);
