import React from "react";
import IncidentDocUploader from "./IncidentDocUploader";
import IncidentDocViewer, { DeletedDocument } from "./IncidentDocViewer";
import {
  Document_Insert_Input,
  DocumentFragmentFragment,
} from "src/common/types/generated/apollo/graphQLTypes";

interface IncidentDocViewAndUploadProps {
  groupId: string;
  type: string;
  uploadDocument: (objects: Document_Insert_Input[]) => Promise<void>;
  deleteDocument: (doc: DeletedDocument) => Promise<void>;
  documents?: Array<DocumentFragmentFragment>;
}

const IncidentDocViewAndUpload: React.FunctionComponent<
  IncidentDocViewAndUploadProps
> = ({ groupId, uploadDocument, deleteDocument, documents, type }) => {
  return (
    <div className={`flex flex-col gap-1`}>
      {!!documents?.length && (
        <IncidentDocViewer
          documents={documents.filter((file) => !!file.image)}
          deleteDocument={deleteDocument}
        />
      )}

      <IncidentDocUploader
        acceptedFiles=".png,.jpg,.jpeg"
        insertDoc={uploadDocument}
        docType="image"
        type={type}
        title="Upload Images (area of incident, property damage, etc.)"
        groupId={groupId}
      />

      {!!documents?.length && (
        <IncidentDocViewer
          documents={documents.filter((file) => !file.image)}
          deleteDocument={deleteDocument}
        />
      )}
      <IncidentDocUploader
        acceptedFiles="video/*, audio/*, .xlsx, .docx, .doc, application/pdf"
        insertDoc={uploadDocument}
        docType="other"
        type={type}
        title="Add Any Related Documents (police/incident reports, etc.)"
        groupId={groupId}
      />
    </div>
  );
};

export default IncidentDocViewAndUpload;
