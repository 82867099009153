import { useState } from "react";

function useSelectedIndicies<T extends string | number = number>(
  initialSelectedIndices: Set<T> = new Set<T>(),
) {
  const [selectedIndices, setSelectedIndices] = useState<Set<T>>(
    initialSelectedIndices,
  );

  const toggleIndex = (index: T) => {
    setSelectedIndices((prev) => {
      const newIndices = new Set([...prev]);
      if (newIndices.has(index)) {
        newIndices.delete(index);
      } else {
        newIndices.add(index);
      }
      return newIndices;
    });
  };

  const addIndex = (index: T) => {
    setSelectedIndices((prev) => {
      return new Set([...prev, index]);
    });
  };

 
  const removeIndex = (index: T) => {
    setSelectedIndices((prev) => {
      return new Set([...prev].filter(value => value !== index));
    });
  };
 

  return [selectedIndices, toggleIndex, setSelectedIndices, addIndex, removeIndex] as const;
}

export default useSelectedIndicies;
