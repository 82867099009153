import { useSuspenseQuery } from "@apollo/client";
import {
  GetGcBusinessUnitsDocument,
  GetGcBusinessUnitsQuery,
  GetGcBusinessUnitsQueryVariables,
  useInsertGcBusinessUnitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import useAuthUser from "src/common/hooks/useAuthUser";
import GCAddNewLevelButton, {
  GCAddNewLevelButtonProps,
} from "../GCAddNewLevelButton";
import BusinessUnitTable from "../BusinessUnitTable";
import useGcEmployee from "../../organization-unit/utils/useGcEmployee";
import React from "react";

const GCAllBuLevelChilds: React.FC<
  Omit<GetGcBusinessUnitsQueryVariables, "includeTableData"> & {
    addNewLevelInitialVals?: GCAddNewLevelButtonProps["addNewLevelInitialVals"];
  }
> = ({ buWhere, divisionWhere, addNewLevelInitialVals }) => {
  const gc = useGcEmployee().general_contractor;
  const { data, refetch } = useSuspenseQuery<
    GetGcBusinessUnitsQuery,
    GetGcBusinessUnitsQueryVariables
  >(GetGcBusinessUnitsDocument, {
    variables: {
      buWhere,
      divisionWhere,
      includeTableData: true,
    },
    fetchPolicy: "network-only",
  });
  const [insertBu, { loading }] = useInsertGcBusinessUnitMutation();
  const authUser = useAuthUser();
  return gc.hierarchy_business_unit_name ? (
    <BusinessUnitTable
      businessUnits={data.gc_business_unit}
      officeTitle={gc.hierarchy_office_name}
      buTitle={gc.hierarchy_business_unit_name}
      addButton={
        <GCAddNewLevelButton
          {...{
            levelName: gc.hierarchy_business_unit_name,
            inserting: loading,
            addNewLevelInitialVals,
            divisionSelectOptions: data.gc_division,
            divisionLevelName: gc.hierarchy_division_name,
            onInsert: async (vals) => {
              await insertBu({
                variables: {
                  object: {
                    name: vals.name,
                    created_by_uid: authUser.uid,
                    gc_division_id: vals.divisionId || null,
                    general_contractor_id: gc.id,
                    logo_url: vals.uploadedLogoUrl,
                    address: {
                      data: {
                        line_1: vals.addressLine1,
                        zip_code: vals.zip,
                        city: vals.city,
                        state_code: vals.stateCode,
                      },
                    },
                  },
                },
              });
              refetch();
            },
          }}
        />
      }
    />
  ) : null;
};
export default GCAllBuLevelChilds;
