import React, { useRef, useState } from "react";
import VideoPlayer from "src/common/components/media-players/video-player/VideoPlayer";
import { VideoPlayerRef } from "src/common/components/media-players/video-player/utils/VideoPlayerTypes";
import { Alert, Button, Modal } from "antd";
import { CurrentLangStrings } from "../../../../../../utility-features/i18n/language-utils/i18nTypes";
import VideoPlayerCustomControls from "src/common/components/media-players/video-player/VideoPlayerCustomControls";
import reportException from "../../../../../../utility-features/error-handling/reportException";

export interface SiteOrientationSlidesViewerSlideVideoProps {
  onWatched?: () => void;
  videSrc: string;
  watchFullVideo: boolean;
  hideCustomControls?: boolean;
  disableControls: boolean;
  langStrings: CurrentLangStrings;
  showWarningIfRequiredNotComplete: boolean;
  videoMaxHeight?: string;
}

const SiteOrientationSlidesViewerSlideVideo: React.FC<
  SiteOrientationSlidesViewerSlideVideoProps
> = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayStateChanging, setIsPlayStateChanging] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  const videoRef = useRef<VideoPlayerRef>(null);
  const [showWarning, setShowWarning] = useState(false);
  const startTime = useRef(Date.now());
  const togglePlayPause = async (toPlay: boolean) => {
    const player = videoRef.current;
    if (!player) return;
    setIsPlayStateChanging(true);
    try {
      if (toPlay) {
        await player.play();
        setIsPlaying(true);
      } else {
        await player.pause();
        setIsPlaying(false);
      }
    } catch (e) {
      reportException(e);
    } finally {
      setIsPlayStateChanging(false);
    }
  };

  return (
    <div className={`flex flex-col gap-0.5`}>
      <Modal
        open={showWarning}
        okText="OK"
        onOk={() => {
          startTime.current = Date.now();
          setShowWarning(false);
        }}
        onCancel={() => {
          startTime.current = Date.now();
          setShowWarning(false);
        }}
        footer={
          <Button
            type="primary"
            onClick={() => {
              startTime.current = Date.now();
              setShowWarning(false);
            }}
          >
            Replay
          </Button>
        }
      >
        <Alert
          className="mt-1"
          type="warning"
          message={
            "Warning: Please watch the full video without skipping ahead"
          }
        />
      </Modal>
      <VideoPlayer
        ref={videoRef}
        videoUrl={props.videSrc}
        disableControls={props.disableControls}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        maxHeight={props.videoMaxHeight}
        autoPlay={true}
        onReady={() => {
          setVideoReady(true);
        }}
        onLoadFailed={() => {
          setVideoReady(false);
        }}
        onWatched={async () => {
          const player = videoRef.current;
          if (!player) return; // if callback is called after page is unmounted .. we do not need to run this logic
          setIsPlaying(false);
          if (props.watchFullVideo) {
            const endTime = Date.now();
            const videoDuration = await player.getDuration();
            const watchedSec = (endTime - startTime.current) / 1000;
            console.log("watched", watchedSec, videoDuration);
            if (
              props.showWarningIfRequiredNotComplete &&
              watchedSec + 2 < videoDuration
            ) {
              setShowWarning(true);
              return;
            }
          }
          props.onWatched?.();
        }}
      />
      {!props.hideCustomControls && (
        <VideoPlayerCustomControls
          onRewindClick={() => {
            const player = videoRef.current;
            if (player) {
              player.rewind();
            }
          }}
          onPlayClick={() => {
            togglePlayPause(true);
          }}
          onPauseClick={() => {
            togglePlayPause(false);
          }}
          isLoading={isPlayStateChanging}
          isPlaying={isPlaying}
          disabled={!videoReady}
        />
      )}
    </div>
  );
};

export default SiteOrientationSlidesViewerSlideVideo;
