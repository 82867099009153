import { Divider, Space } from "antd";
import { PropsWithChildren } from "react";

const DividerSplitWrapper: React.FC<PropsWithChildren<{}>> = (props) => {
  return (
    <Space
      direction={"vertical"}
      className={`w-full`}
      split={<Divider />}
      size={0}
    >
      {props.children}
    </Space>
  );
};

export default DividerSplitWrapper;
