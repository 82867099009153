import { Navigate } from "react-router-dom";
import React from "react";
import GCProjectObservations from "./GCProjectObservations";
import GCObservation from "./GCObservation";
import GCObservationNavigation from "./GCObservationNavigation";
import GCProjectAudits from "./GCProjectAudits";
import ObservationInspectionDetail from "../../components/ObservationInspectionDetail";
import GCProjObsDashboard from "./dashboard/GCProjObsDashboard";
import NestedRoute from "../../../../common/types/manual/NestedRoute";

const gcObservationRoute: NestedRoute = {
  element: <GCObservationNavigation />,
  handle: {
    breadcrumb: "Observations",
  },
  children: [
    { path: "", element: <Navigate to={"observations"} /> },
    {
      path: "observations",
      element: <GCProjectObservations />,
      children: [
        {
          path: ":observationId",
          element: <GCObservation />,
        },
      ],
    },
    {
      path: "audits",
      element: <GCProjectAudits />,
    },
    {
      path: "audits/:auditId",
      element: <ObservationInspectionDetail />,
    },
    {
      path: "charts",
      element: <GCProjObsDashboard />,
    },
  ],
};
export default gcObservationRoute;
