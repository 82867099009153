import { TreeDataNode, TreeSelect, Typography } from "antd";
import {
  ApartmentOutlined,
  CheckOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { GcHierarchyTreeNodeType } from "./gcHierarchyTreeTypes";
import React, { useRef } from "react";
import remToPx from "../../../../../../common/functions/remToPx";
import sendHotjarEvent from "../../../../../../utility-features/event-tracking/hotjar/sendHotjarEvent";
import compareStrings from "../../../../../../common/functions/compareStrings";

// Utils
const nodeTypeOrder: GcHierarchyTreeNodeType[] = [
  "corporate_office",
  "organization_unit",
  "project",
];
const getNodeTypeOrder = (node: TreeNode) => nodeTypeOrder.indexOf(node.type);

// Icons
const nodeIcon = <ApartmentOutlined />;
const projectIcon = <HomeOutlined />;

const iconByType = (type: GcHierarchyTreeNodeType) => {
  let icon: React.ReactNode | undefined;
  switch (type) {
    case "organization_unit":
    case "corporate_office":
      icon = nodeIcon;
      break;
    case "project":
      icon = projectIcon;
      break;
    default:
      break;
  }
  return icon;
};

type BaseTreeNode = {
  label: string;
  value: string;
};

type TreeNodeTemplate<T extends GcHierarchyTreeNodeType> = T extends "project"
  ? {
      type: T;
      completed?: boolean;
    } & BaseTreeNode
  : {
      type: T;
      children?: TreeNodeTemplate<GcHierarchyTreeNodeType>[];
    } & BaseTreeNode;

export type ProjectTreeNode = TreeNodeTemplate<"project">;
export type OrganizationUnitTreeNode = TreeNodeTemplate<
  Exclude<GcHierarchyTreeNodeType, "project">
>;

// Union type for general usage
export type TreeNode = ProjectTreeNode | OrganizationUnitTreeNode;

const findNodeByValue = (value: string, tree: TreeNode[]): TreeNode | null => {
  for (const node of tree) {
    if (node.value === value) {
      return node;
    }
    const foundInChildren =
      "children" in node && !!node.children?.length
        ? findNodeByValue(value, node.children)
        : null;
    if (foundInChildren) {
      return foundInChildren;
    }
  }
  return null;
};

export interface GCNavigationHierarchyMenuUiProps {
  loading?: boolean;
  onSelect?: (node: TreeNode) => void;
  onClear?: () => void;
  selectedNodeValue?: string | null;
  tree: TreeNode[];
}

const GCNavigationHierarchyMenuUI: React.FC<
  GCNavigationHierarchyMenuUiProps
> = (props) => {
  const treeSelectRef = useRef<React.ElementRef<typeof TreeSelect>>(null);

  const onChange = (value?: string) => {
    if (props.onSelect) {
      if (value) {
        const selectedNode = findNodeByValue(value, props.tree);
        if (selectedNode) {
          props.onSelect(selectedNode);
        } else {
          props.onClear?.();
        }
      } else {
        props.onClear?.();
      }
    }
  };

  // Find selected Node
  const selectedNode: TreeNode | null = props.selectedNodeValue
    ? findNodeByValue(props.selectedNodeValue, props.tree)
    : null;
  //
  const remapTreeData: (originalTree: TreeNode[]) => TreeDataNode[] = (
    originalTree,
  ) =>
    originalTree
      .sort((nodeA, nodeB) => {
        // Sort By...
        // 1. Type
        const nodeAOrder = getNodeTypeOrder(nodeA);
        const nodeBOrder = getNodeTypeOrder(nodeB);
        if (nodeAOrder !== nodeBOrder) {
          return nodeAOrder - nodeBOrder;
        }
        // 2. Completed vs Active projects
        if (nodeA.type === "project" && nodeB.type === "project") {
          const completedA = nodeA.completed ? 1 : 0;
          const completedB = nodeB.completed ? 1 : 0;
          if (completedA !== completedB) {
            return completedA - completedB;
          }
        }
        // 3. ABC Label
        return compareStrings(nodeA.label, nodeB.label);
      })
      .map((node) => ({
        value: node.value,
        key: node.value,
        icon: iconByType(node.type),
        ...(node.type === "project"
          ? {
              title: node.completed ? (
                <Typography.Text type={"secondary"}>
                  {node.label} <CheckOutlined />
                </Typography.Text>
              ) : (
                node.label
              ),
              completed: node.completed,
            }
          : {
              title: node.label,
              children: node.children?.length
                ? remapTreeData(node.children)
                : undefined,
            }),
      }));

  const treeData: TreeDataNode[] = remapTreeData(props.tree);

  // Calculate popup height and width
  let popupHeight: undefined | number = undefined;
  let popupWidth: number | undefined = undefined;

  const treeSelectElement = treeSelectRef.current?.nativeElement;
  if (treeSelectElement) {
    const treeSelectBoundingRect = treeSelectElement.getBoundingClientRect();
    const treeSelectWidth = treeSelectElement.getBoundingClientRect().width;

    // Width
    popupWidth = treeSelectWidth * 2;

    // Height
    // (popup inner height) = (windows height) - (popup top) - (outer padding) - (inner padding)
    const popupY =
      treeSelectBoundingRect.top +
      treeSelectBoundingRect.height +
      remToPx(0.25);
    const outerPopupPaddingSize = remToPx(0.25);
    const innerPaddingSize = remToPx(0.5);
    popupHeight =
      window.innerHeight -
      popupY -
      outerPopupPaddingSize * 2 -
      innerPaddingSize * 2;
  }

  return (
    <div className={`p-0.25`}>
      <TreeSelect
        onDropdownVisibleChange={(open) => {
          open && sendHotjarEvent("hierarchy_menu_opened");
        }}
        ref={treeSelectRef}
        loading={props.loading}
        showSearch
        listHeight={popupHeight}
        popupMatchSelectWidth={popupWidth}
        className={`w-full`}
        prefix={selectedNode?.type ? iconByType(selectedNode.type) : undefined}
        value={
          !props.loading && !!props.selectedNodeValue
            ? props.selectedNodeValue
            : undefined
        }
        placeholder="Office / Project"
        allowClear={!!props.onClear}
        treeIcon
        filterTreeNode={(input, node) => {
          return !!node.title
            ?.toString()
            .toLocaleLowerCase()
            .includes(input.toLocaleLowerCase());
        }}
        onClear={props.onClear}
        onChange={onChange}
        treeData={treeData}
        treeDefaultExpandAll
      />
    </div>
  );
};

export default GCNavigationHierarchyMenuUI;
