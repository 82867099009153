import SupportContactForm from "../../../contact-button/SupportContactForm";
import { Alert, Button, Popover, Tooltip } from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { auth } from "../../../../functions/firebase";
import sendHotjarEvent from "../../../../../utility-features/event-tracking/hotjar/sendHotjarEvent";

const ContactUsButton: React.FC = () => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const userId = auth.currentUser?.uid;
  const onOpenChange = (newValue: boolean) => {
    newValue && sendHotjarEvent("contact_us_opened");
    setPopoverVisible(newValue);
  };

  return (
    <Popover
      content={
        userId ? (
          <SupportContactForm
            onDone={() => {
              setPopoverVisible(false);
            }}
            userId={userId}
          />
        ) : (
          <Alert message={"Please login first"} type={"warning"} />
        )
      }
      title={"Contact Us"}
      trigger="click"
      open={popoverVisible}
      onOpenChange={onOpenChange}
      placement={"topLeft"}
    >
      <Tooltip title={"Ask Us"}>
        <Button disabled={!userId} icon={<QuestionOutlined />} />
      </Tooltip>
    </Popover>
  );
};

export default ContactUsButton;
