import { graphql } from "babel-plugin-relay/macro";
import React, { forwardRef } from "react";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { CrewTableQuery } from "src/common/types/generated/relay/CrewTableQuery.graphql";
import { CrewTable_project_crew$data } from "src/common/types/generated/relay/CrewTable_project_crew.graphql";
import { CrewProjectWorkerOptions_project_worker_connection_frag$data } from "src/common/types/generated/relay/CrewProjectWorkerOptions_project_worker_connection_frag.graphql";
import CrewTableActionPopover from "./CrewTableActionPopover";

export type ColumnKeys = "name" | "workers" | "leadForeman" | "action";

const CONNECTION_NAME = "project_crew_connection";
export type DConnection = CrewTable_project_crew$data[typeof CONNECTION_NAME];

type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  CrewTableQuery,
  { pk: string }
> & {
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    CrewTableQuery
  >["columns"];
  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  showOnsite?: boolean;
  datePickerDataIndex?: string[];
  workersOnProject: CrewProjectWorkerOptions_project_worker_connection_frag$data;
  defaultSortColumnKey?: ColumnKeys;
};

// declare getSuffix to avoid depricated substr

const CrewTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Crews",
      onRowClick,
      workersOnProject,
      searchString,
      datePickerDataTitle,
      datePickerDataIndex,
      headerComponent,
      extraColumns,
      where,
      showOnsite,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    return (
      <DataScrollTable<DConnection, ColumnKeys, CrewTableQuery>
        {...props}
        newCustomTableLook
        extraQueryVariables={{ showOnsite: !!showOnsite }}
        filterNotVisibleByDefault
        ref={ref}
        title={title}
        onRowClick={
          onRowClick
            ? ({ pk }) => {
                onRowClick({ pk });
              }
            : undefined
        }
        where={{ ...where }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allProjectCrewsConnection"}
        datePickerDataIndex={datePickerDataIndex}
        datePickerDataTitle={datePickerDataTitle}
        columns={[
          {
            title: "Crew Name",
            key: "name",
            size: "md",
            sortable: true,
            contentType: {
              type: "row-title",
            },
            defaultSortOrder:
              defaultSortColumnKey === undefined ||
              defaultSortColumnKey === "name"
                ? "asc"
                : undefined,
            dataIndex: ["name"],
            searchDataIndex: ["name"],
          },
          {
            title: "Workers",
            key: "workers",
            size: "md",
            dataIndex: ["project_workers_aggregate", "aggregate", "count"],
          },
          {
            title: "Crew Lead",
            key: "leadForeman",
            size: "md",
            dataIndex: ["lead_foreman_project_worker", "user", "name"],
            //searchValue: searchString,
            searchDataIndex: ["lead_foreman_project_worker", "user", "name"],
            render: (_, row) => {
              if (
                !row.lead_foreman_project_worker ||
                !row.lead_foreman_project_worker.subcontractor_worker
              ) {
                return `No Crew Lead`;
              } else {
                return (
                  <div className="flex">
                    {row.lead_foreman_project_worker.user?.name}
                  </div>
                );
              }
            },
          },
          ...(extraColumns || []),
          {
            title: "Action",
            key: "action",
            size: "icon",
            dataIndex: [],
            render: (_, row) => (
              <div onClick={(e) => e.stopPropagation()}>
                <CrewTableActionPopover
                  {...{ row, workersOnProject, showOnsite }}
                />
              </div>
            ),
          },
        ]}
        queryNode={graphql`
          query CrewTableQuery(
            $first: Int!
            $after: String
            $where: project_crew_bool_exp!
            $showOnsite: Boolean!
            $order_by: [project_crew_order_by!]!
          ) {
            ...CrewTable_project_crew
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
                showOnsite: $showOnsite
              )
            ...CrewTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment CrewTable_total on query_root
          @argumentDefinitions(where: { type: "project_crew_bool_exp!" })
          @refetchable(queryName: "CrewTableTotalRefetchableQuery") {
            allProjectCrewsConnection: project_crew_connection(where: $where) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment CrewTable_project_crew on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "project_crew_bool_exp!" }
            order_by: { type: "[project_crew_order_by!]!" }
            showOnsite: { type: "Boolean!" }
          )
          @refetchable(queryName: "CrewTableRefetchableQuery") {
            project_crew_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "CrewTableQuery_project_crew_connection"
                filters: []
              ) {
              edges {
                node {
                  ...CrewFrag @relay(mask: false)
                  project_subcontractor @include(if: $showOnsite) {
                    project_subcontractor_onsite_periods(
                      where: { close_date: { _is_null: true } }
                    ) {
                      id
                    }
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default CrewTable;
