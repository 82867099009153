import React, { useState } from "react";
import GCProjectReportsRow from "./GCProjectReportsRow";
import { GCProjectReportsDownloadQuery$data } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import WorkerHourCountReportCreator from "./WorkerHourCountReportCreator";
import withCustomSuspense from "../../../../../../common/components/general/withCustomSuspense";

const WorkerHourCountReportWidget: React.FC<{
  projectId: string;
  data: GCProjectReportsDownloadQuery$data;
}> = ({ projectId, data }) => {
  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);

  return (
    <>
      <GCProjectReportsRow
        title="Timesheet Report"
        subtitle="See which days and hours workers were reported to be onsite"
        handleClick={() => {
          setReportDrawerVisible(true);
        }}
      />
      <WorkerHourCountReportCreator
        visible={reportDrawerVisible}
        projectId={projectId}
        handleClose={() => setReportDrawerVisible(false)}
        data={data}
      />
    </>
  );
};

export default withCustomSuspense(WorkerHourCountReportWidget);
