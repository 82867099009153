import {
  useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation,
  useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation$data,
} from "src/common/types/generated/relay/useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation.graphql";
import {
  useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation,
  useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation$data,
} from "src/common/types/generated/relay/useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GetFullID from "src/common/functions/GetFullId";
import { obs_notify_user_insert_input } from "src/common/types/generated/relay/types";
import { graphql } from "babel-plugin-relay/macro";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { ScrollTableColumn } from "src/common/components/tables/basic/ScrollTable";
import { Switch } from "antd";
import capitalize from "src/common/functions/capitalize";

export type NotifyForObsInsertUpdaterType = (
  store: RecordSourceSelectorProxy<useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation$data>,
  rowDataId: string,
) => void;
export type NotifyForObsDeleteUpdaterType = (
  store: RecordSourceSelectorProxy<useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation$data>,
  rowDataId: string,
) => void;

const useInsertObsNotify = () => {
  const [insert] =
    useAsyncMutation<useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation>(graphql`
      mutation useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation(
        $objects: [obs_notify_user_insert_input!]!
      ) {
        insert_obs_notify_user(objects: $objects) {
          returning {
            id
            pk: id @__clientField(handle: "pk")
            risk_level_value
          }
        }
      }
    `);
  const insertObsNotifyUser = async (
    insertObj: obs_notify_user_insert_input,
    rowDataId: string,
    updater: NotifyForObsInsertUpdaterType,
  ) => {
    if (!insertObj.id) throw new Error("inserting obj id required");
    await insert({
      variables: { objects: [insertObj] },
      updater: (store) => updater(store, rowDataId),
      optimisticUpdater: (store) => updater(store, rowDataId),
      optimisticResponse: {
        insert_obs_notify_user: {
          returning: [
            {
              pk: insertObj.id,
              risk_level_value: insertObj.risk_level_value,
              id: GetFullID("obs_notify_user", insertObj.id),
            },
          ],
        },
      },
    });
  };
  return insertObsNotifyUser;
};

const useDeleteObsNotify = () => {
  const [deleteEntry] =
    useAsyncMutation<useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation>(graphql`
      mutation useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation(
        $where: obs_notify_user_bool_exp!
      ) {
        delete_obs_notify_user(where: $where) {
          returning {
            id
          }
        }
      }
    `);
  const deleteObsNotifyUser = async (
    toDeleteId: string,
    rowDataId: string,
    updater: NotifyForObsDeleteUpdaterType,
  ) => {
    await deleteEntry({
      variables: { where: { id: { _eq: toDeleteId } } },
      updater: (store) => updater(store, rowDataId),
      optimisticUpdater: (store) => updater(store, rowDataId),
      optimisticResponse: {
        delete_obs_notify_user: {
          returning: [{ id: GetFullID("obs_notify_user", toDeleteId) }],
        },
      },
    });
  };
  return deleteObsNotifyUser;
};
const useGetSubadminObsRiskLevelColumns = <
  T extends {
    id: string;
    readonly notify_for_obs: readonly {
      id: string;
      risk_level_value: string | null | undefined;
      pk: string;
    }[];
  },
  ColumnKeys extends string = string,
>({
  getObsNotifyUserInsertObj,
  notifyForObsDeleteUpdater,
  notifyForObsInsertUpdater,
  riskLevelColsToShow,
}: {
  getObsNotifyUserInsertObj: (
    row: T,
    risk_level_value: string,
  ) => obs_notify_user_insert_input;
  riskLevelColsToShow?: { value: string; name: string; color_hex: string }[];
  notifyForObsInsertUpdater: NotifyForObsInsertUpdaterType;
  notifyForObsDeleteUpdater: NotifyForObsDeleteUpdaterType;
}) => {
  const deleteObsNotifyUser = useDeleteObsNotify();
  const insertObsNotifyUser = useInsertObsNotify();
  const getRiskLevelColumns = () =>
    (riskLevelColsToShow || []).map(
      (risk): ScrollTableColumn<T, ColumnKeys> => ({
        title: "Notify for Observations - " + capitalize(risk.value),
        key: risk.value as unknown as ColumnKeys,
        dataIndex: ["notify_for_obs", "risk_level_value"],
        render: (_, row) => {
          const foundedNotifyee = row.notify_for_obs.find(
            (s) => s.risk_level_value === risk.value,
          );
          return (
            <Switch
              value={!!foundedNotifyee}
              onChange={async (newVal) => {
                if (newVal) {
                  await insertObsNotifyUser(
                    getObsNotifyUserInsertObj(row, risk.value),
                    row.id,
                    notifyForObsInsertUpdater,
                  );
                } else if (foundedNotifyee) {
                  deleteObsNotifyUser(
                    foundedNotifyee.pk,
                    row.id,
                    notifyForObsDeleteUpdater,
                  );
                } else
                  throw new Error("no notifyee entry found to be turned Off");
              }}
            />
          );
        },
      }),
    );
  return getRiskLevelColumns;
};
export default useGetSubadminObsRiskLevelColumns;
