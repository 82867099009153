import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import { DatesRangeType } from "src/common/types/manual/DatesRange";
import * as uuid from "uuid";
import SitedeliveryAddNewDeliveryModal, {
  DeliveryCompany,
} from "../components/SitedeliveryAddNewDeliveryModal";
import { CalendarBaseType, DeliveryType } from "../utilities/sitedeliveryTypes";
import GCProjectCalendarSitedeliveryDeliveriesUI from "./GCProjectCalendarSitedeliveryDeliveriesUI";
import {
  useInsertUserProjectFilterCalendarMutation,
  useDeleteUserProjectFilterCalendarMutation,
  Order_By,
  GetDeliveryFieldsDocument,
  GetDeliveryFieldsQuery,
  GetDeliveryFieldsQueryVariables,
  useInsertProjectCalendarMutation,
  useUpdateCalendarMutation,
  Project_Calendar_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSearchParams } from "react-router-dom";
import { GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery } from "src/common/types/generated/relay/GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery.graphql";
import { useSuspenseQuery } from "@apollo/client";
import SitedeliveryDetailsDrawer from "../components/SitedeliveryDetailsDrawer";

const timezoneQuery = graphql`
  query GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery(
    $where: project_bool_exp!
  ) {
    project_connection(where: $where) {
      edges {
        node {
          timezone
        }
      }
    }
  }
`;

export interface GCProjectCalendarSitedeliveryDeliveriesProps {
  staticDate?: dayjs.Dayjs; // Static date means the date is not adjustable via UI
  deliveryContentShownOnCards?: boolean;
  keepTimelineInView?: boolean;
  subcontractorId?: string;
  isUserSubAdmin?: boolean;
}

const GCProjectCalendarSitedeliveryDeliveries: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesProps
> = (props) => {
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error("projectId param is missing");
  }

  const timezoneData =
    useLazyLoadQuery<GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery>(
      timezoneQuery,
      { where: { id: { _eq: projectId } } },
    );

  const projectZoneQuery = timezoneData.project_connection.edges[0];
  if (!projectZoneQuery) {
    throw new Error("Project timezone query: Project does not exist");
  }

  const projectTimezone = projectZoneQuery.node.timezone;

  const [calendarVisibilityToggleLoading, setCalendarVisibilityToggleLoading] =
    useState(false);

  const [searchParams] = useSearchParams("dayDifference");
  const initialDateRangeFrom =
    props.staticDate?.tz(projectTimezone, true) ||
    dayjs().tz(projectTimezone, true).startOf("week").add(1, "d");

  const [datesRange, setDatesRange] = useState<DatesRangeType>({
    from: initialDateRangeFrom,
    to: initialDateRangeFrom.add(6, "d").endOf("day"),
  });

  const [addDeliveryModalVisible, setAddDeliveryModalVisible] = useState(false);

  const [selectedDeliveryId, setSelectedDeliveryId] = useState<string | null>(
    null,
  );

  const userId = auth.currentUser?.uid;

  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState({
    fetchKey: 0,
  });

  const [insertCalendar] = useInsertProjectCalendarMutation();
  const [updateCalendar] = useUpdateCalendarMutation();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = dayjs();

      if (props.staticDate && !searchParams.get("dayDifference")) {
        setDatesRange((prev) => ({
          from:
            prev.from.format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")
              ? prev.from.add(1, "d")
              : prev.from,
          to:
            prev.to.format("YYYY-MM-DD") !==
            currentTime.add(6, "d").format("YYYY-MM-DD")
              ? prev.to.add(1, "d")
              : prev.to,
        }));
      }

      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      }));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const queryVariables: GetDeliveryFieldsQueryVariables = {
    skipUserFilterCalendar: !userId,
    delivery_where: {
      calendars: {
        calendar: {
          projects: {
            project_id: { _eq: projectId },
          },
        },
      },
      start_at: {
        _gte: dayjs(datesRange.from).subtract(1, "day").toISOString(),
        _lte: dayjs(datesRange.to).toISOString(),
      },
      status: { _in: ["Approved", "Pending"] },
    },
    delivery_order_by: [{ start_at: Order_By.Desc }],
    calendar_where: {
      projects: {
        project_id: { _eq: projectId },
        is_archive: { _eq: false },
      },
    },
    hiddenCalendarWhere: !!userId
      ? {
          filter_type: { _eq: "web-deliveries-hidden-calendar" },
          project_id: { _eq: projectId },
          user_id: { _eq: userId },
        }
      : {},
    projectId: projectId,
    includeLogisticsAndCalendarFilter: !!userId,
    gcEmployeeWhere: { uid: { _eq: userId } },
  };

  const { data: deliveryFieldsData } = useSuspenseQuery<
    GetDeliveryFieldsQuery,
    GetDeliveryFieldsQueryVariables
  >(GetDeliveryFieldsDocument, {
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
  });

  if (!deliveryFieldsData) {
    throw new Error("Delivery fields data not found");
  }

  const generalContractorId =
    deliveryFieldsData.general_contractor_employee.at(0)?.general_contractor_id;
  const project = deliveryFieldsData.project.at(0);
  if (!project) throw new Error("project does not exist");

  const availableCompanies: Array<DeliveryCompany> = [
    {
      type: "gc",
      gcId: project.general_contractor.id,
      name: project.general_contractor.name,
    },
    ...project.project_subcontractors.map(
      (projSub): DeliveryCompany => ({
        type: "sub",
        subId: projSub.subcontractor.id,
        name: projSub.subcontractor.name,
      }),
    ),
  ];
  const [loading, setLoading] = useState(false);
  const [insertFilterCalendar] = useInsertUserProjectFilterCalendarMutation();
  const [deleteFilterCalendar] = useDeleteUserProjectFilterCalendarMutation();

  const handleInsertCalendar = async (
    calendar: Project_Calendar_Insert_Input[],
  ) => {
    await insertCalendar({
      variables: {
        objects: calendar,
      },
      update: (cache, { data }) => {
        if (!data?.insert_project_calendar?.returning)
          throw new Error("Insert project calendar returned null");
        const newCalendar =
          data.insert_project_calendar.returning.at(0)?.calendar;
        if (!newCalendar)
          throw new Error("Insert project calendar returned null");

        cache.modify<GetDeliveryFieldsQuery>({
          fields: {
            calendar: (existingCalendars = [], { toReference }) => {
              const newCalendarRef = toReference(newCalendar);
              if (!newCalendarRef) return existingCalendars;
              return [...existingCalendars, newCalendarRef];
            },
          },
        });
      },
    });
  };

  const calendarData = useMemo(
    () => deliveryFieldsData.calendar.map((c) => c),
    [deliveryFieldsData.calendar],
  );
  const filterCalendarsData = useMemo(
    () => (deliveryFieldsData.user_project_filter_calendar || []).map((c) => c),
    [deliveryFieldsData.user_project_filter_calendar],
  );
  const deliveryData = deliveryFieldsData.delivery || [];
  const gcData = project.general_contractor;

  const calendars = useMemo(
    () =>
      calendarData.map((c) => ({
        id: c.id,
        title: c.name.en,
        titleId: c.name.id,
        colorHex: c.color_hex,
        visible: !filterCalendarsData.find((fc) => fc.calendar.id === c.id),
        isArchive:
          c.projects.find((p) => p.project.id === projectId)?.is_archive ??
          false,
      })),
    [calendarData, filterCalendarsData],
  );

  const deliveries: DeliveryType[] = useMemo(() => {
    return deliveryData
      .map((delivery): DeliveryType => {
        const deliveryNode = delivery;
        const startTime = dayjs(deliveryNode.start_at).tz(projectTimezone);
        const endTime = startTime.add(deliveryNode.duration, "hour");
        const subcontractor = deliveryNode.subcontractor;

        return {
          type: "delivery",
          title: { text: deliveryNode.name.en, id: deliveryNode.name.id },
          detail: deliveryNode.detail?.en
            ? { text: deliveryNode.detail.en, id: deliveryNode.detail.id }
            : undefined,
          storageLocation: deliveryNode.storage_location?.en
            ? {
                text: deliveryNode.storage_location.en,
                id: deliveryNode.storage_location.id,
              }
            : undefined,
          from: startTime,
          subcontractor: subcontractor
            ? {
                title: subcontractor.name,
                id: subcontractor.id,
              }
            : undefined,

          generalContractor: { id: gcData.id, title: gcData.name },
          id: deliveryNode.id,
          to: endTime,
          createdAt: dayjs(deliveryNode.created_at),
          createdBy: deliveryNode.user.name,
          reviewed:
            deliveryNode.approved_by &&
            deliveryNode.user.id !== deliveryNode.approved_by.id
              ? {
                  by: deliveryNode.approved_by.name,
                  type: "approve",
                  remark: deliveryNode.remark?.en,
                  date: deliveryNode.approved_at
                    ? dayjs(deliveryNode.approved_at)
                    : undefined,
                }
              : undefined,

          calendars: deliveryNode.calendars.map(({ calendar }) => ({
            colorHex: calendar.color_hex,
            id: calendar.id,
            titleId: calendar.name.id,
            title: calendar.name.en,
          })),
          pendingApproval: deliveryNode.status === "Pending",
          // onClick: () => {
          //   setDeliveryDetailsDrawerVisible(true);
          // },
        };
      })
      .filter(
        (
          delivery, // TODO: lets modify query to exclude such deliveries from it
        ) =>
          delivery.calendars.some(
            (deliveryCalendar) =>
              !filterCalendarsData.find(
                (fc) => deliveryCalendar.id === fc.calendar.id,
              ),
          ),
      );
  }, [deliveryData, calendars, datesRange]);

  // TODO: exclude deliveries from query where calendar is unarchived

  const handleOnCalendarHide = async (calendar: CalendarBaseType) => {
    setCalendarVisibilityToggleLoading(true);
    try {
      const filterCalendarId = uuid.v4();
      const oldCalendar = calendarData?.find((c) => c.id === calendar.id);
      if (!oldCalendar) {
        throw new Error("Wrong calendar Id");
      }
      await insertFilterCalendar({
        variables: {
          object: {
            id: filterCalendarId,
            calendar_id: calendar.id,
            filter_type: "web-deliveries-hidden-calendar",
            project_id: projectId,
            user_id: userId,
          },
        },
        optimisticResponse: {
          insert_user_project_filter_calendar_one: {
            id: filterCalendarId,
            calendar: {
              id: calendar.id,
              name: {
                id: oldCalendar.name.id,
                en: calendar.title,
              },
              color_hex: calendar.colorHex,
            },
          },
        },
        update: (cache, { data }) => {
          if (!data?.insert_user_project_filter_calendar_one)
            throw new Error(
              "Insert user project filter calendar returned null",
            );

          cache.modify<GetDeliveryFieldsQuery>({
            fields: {
              user_project_filter_calendar: (
                existing = [],
                { toReference },
              ) => {
                const newCalendar =
                  data.insert_user_project_filter_calendar_one?.calendar;
                if (!newCalendar) return existing;
                const newCalendarRef = toReference(newCalendar);
                if (!newCalendarRef) return existing;
                return [...existing, newCalendarRef];
              },
            },
          });
        },
      });
    } finally {
      setCalendarVisibilityToggleLoading(false);
    }
  };

  const handleOnCalendarShow = async (calendar: CalendarBaseType) => {
    setCalendarVisibilityToggleLoading(true);
    try {
      await deleteFilterCalendar({
        variables: {
          where: {
            calendar_id: { _eq: calendar.id },
            project_id: { _eq: projectId },
            user_id: { _eq: userId },
          },
        },
        update: (cache, { data }) => {
          if (!data?.delete_user_project_filter_calendar?.returning)
            throw new Error(
              "Delete user project filter calendar returned null",
            );

          const deletedId =
            data.delete_user_project_filter_calendar.returning.at(0)?.id;

          cache.modify<GetDeliveryFieldsQuery>({
            fields: {
              user_project_filter_calendar: (
                existingFilterCalendars = [],
                { readField },
              ) => {
                return existingFilterCalendars.filter(
                  (filterCalendarRef) =>
                    readField("id", filterCalendarRef) !== deletedId,
                );
              },
            },
          });
        },
      });
    } finally {
      setCalendarVisibilityToggleLoading(false);
    }
  };

  const handleDeliverySelect = (deliveryId: string) => {
    setSelectedDeliveryId(deliveryId);
  };

  const selectedDelivery = selectedDeliveryId
    ? deliveries.find((delivery) => delivery.id === selectedDeliveryId)
    : null;

  return (
    <>
      {selectedDelivery && (
        <SitedeliveryDetailsDrawer
          visible={true}
          onClose={() => setSelectedDeliveryId(null)}
          delivery={selectedDelivery}
          calendars={calendars.map((cal) => ({ id: cal.id, title: cal.title }))}
          deliveryCompanies={props.isUserSubAdmin ? [] : availableCompanies}
        />
      )}

      <GCProjectCalendarSitedeliveryDeliveriesUI
        deliveries={deliveries}
        datesRange={datesRange}
        timezone={projectTimezone}
        logisticPlan={project.logistic_plans}
        keepTimelineInView={!props.staticDate}
        onDatesRangeChange={!props.staticDate ? setDatesRange : undefined}
        calendars={calendars}
        toggleCalendarVisible={(calendar, visible) => {
          visible
            ? handleOnCalendarShow(calendar)
            : handleOnCalendarHide(calendar);
        }}
        deliveryContentShownOnCards={props.deliveryContentShownOnCards}
        hideLogistic={!!props.staticDate}
        gcId={gcData.id}
        handleDeliverySelect={handleDeliverySelect}
        openAddNewDeliveryModal={() => {
          setAddDeliveryModalVisible(true);
        }}
        onInsertCalendar={handleInsertCalendar}
      />

      {!props.staticDate && (
        <div className="fixed bottom-0.5 left-0 right-0 flex justify-center align-middle">
          {addDeliveryModalVisible && (
            <SitedeliveryAddNewDeliveryModal
              modalClose={() => {
                setAddDeliveryModalVisible(false);
              }}
              projectId={projectId}
              timezone={projectTimezone}
              gcId={gcData.id}
              isUserGC={props.isUserSubAdmin || props.staticDate ? false : true}
              isApprovalNeeded={project.is_sitedelivery_approval_needed}
              modalVisible={true}
              deliveryCompanies={props.isUserSubAdmin ? [] : availableCompanies}
              subcontractorId={props.subcontractorId}
              deliveryData={deliveryFieldsData}
              queryVariables={queryVariables}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GCProjectCalendarSitedeliveryDeliveries;
