import { DatePicker, Form } from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";

interface DatepickerProps {
  label: string;
  value?: string;
  format?: string;
  showTime?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange: (date: string) => Promise<void>;
}

const Datepicker: React.FC<DatepickerProps> = ({
  label,
  value,
  format,
  showTime,
  disabled,
  required,
  onChange,
}) => {
  const [newValue, setNewValue] = useState<string | undefined>(value);

  const handleDateChange = async (time: dayjs.Dayjs | null) => {
    if (time) {
      const formattedDate = time.format();
      setNewValue(formattedDate);
      await onChange(formattedDate);
    }
  };

  return (
    <Form.Item label={label} layout="vertical">
      <DatePicker
        format={format ?? "YYYY-MM-DD"}
        showTime={showTime}
        required={required}
        maxDate={dayjs()}
        value={newValue ? dayjs(newValue) : value ? dayjs(value) : undefined}
        onChange={handleDateChange}
        className="w-full"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default Datepicker;
