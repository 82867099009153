import dayjs from "dayjs";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  SubBlockEditModalMutation,
  SubBlockEditModalMutationVariables,
  DeleteSubBlockMutation,
  DeleteSubBlockMutationVariables,
  SubBlockEditModalDocument,
  DeleteSubBlockDocument,
  GetSubBlockFieldsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { SubBlockType } from "../utilities/sitedeliveryTypes";
import SitedeliveryTimeBlockModal, {
  SitedeliveryTimeBlockModalValues,
} from "./SitedeliveryTimeBlockModal";

interface SubBlockEditModalProps {
  subBlock: SubBlockType;
  modalClose: () => void;
  modalVisible: boolean;
}

const SubBlockEditModal: React.FC<SubBlockEditModalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const subBlockData = props.subBlock;

  const [updateSubBlock] = useMutation<
    SubBlockEditModalMutation,
    SubBlockEditModalMutationVariables
  >(SubBlockEditModalDocument, {
    optimisticResponse: (vars) => ({
      __typename: "mutation_root",
      update_project_delivery_sub_block_by_pk: {
        __typename: "project_delivery_sub_block",
        id: vars.sub_block_id,
        start_time: vars.updateSubBlockSet.start_time!,
        end_time: vars.updateSubBlockSet.end_time!,
        weekday: vars.updateSubBlockSet.weekday!,
      },
      insert_sub_block_subcontractor: null,
      insert_sub_block_calendar: null,
      delete_sub_block_calendar: null,
      delete_sub_block_subcontractor: null,
    }),
  });

  const [deleteSubBlock] = useMutation<
    DeleteSubBlockMutation,
    DeleteSubBlockMutationVariables
  >(DeleteSubBlockDocument);

  const onSubmit = async (values: SitedeliveryTimeBlockModalValues) => {
    setLoading(true);
    try {
      const insertSubBlockCalendarIds = values.calendarIds.filter(
        (c) => !subBlockData.calendarIds.find((sbc) => sbc === c),
      );
      const insertSubBlockSubIds = values.subcontractorIds.filter(
        (s) => !(subBlockData.subcontractorExpected.id === s),
      );
      const deleteSubBlockCalendarIds = subBlockData.calendarIds.filter(
        (sbd) => !values.calendarIds.find((c) => c === sbd),
      );
      const deleteSubBlockSubIds = [subBlockData.subcontractorExpected]
        .filter((sbd) => !values.subcontractorIds.find((s) => s === sbd.id))
        .map((sbd) => sbd.id);

      await updateSubBlock({
        variables: {
          sub_block_id: subBlockData.id,
          updateSubBlockSet: {
            weekday: values.weekday,
            start_time: dayjs(values.startTime).format("HH:mm"),
            end_time: dayjs(values.endTime).format("HH:mm"),
          },
          deleteSubBlockCalendarWhere: {
            sub_block_id: { _eq: subBlockData.id },
            calendar_id: { _in: deleteSubBlockCalendarIds },
          },
          deleteSubBlockSubWhere: {
            sub_block_id: { _eq: subBlockData.id },
            subcontractor_id: { _in: deleteSubBlockSubIds },
          },
          insertSubBlockCalendarObjects: insertSubBlockCalendarIds.map(
            (ic) => ({
              calendar_id: ic,
              sub_block_id: subBlockData.id,
            }),
          ),
          insertSubBlockSubObjects: insertSubBlockSubIds.map((is) => ({
            subcontractor_id: is,
            sub_block_id: subBlockData.id,
          })),
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (subBlockId: string) => {
    setLoading(true);
    try {
      await deleteSubBlock({
        variables: {
          subBlockId: subBlockId,
        },
        update(cache, { data }) {
          const deletedId =
            data?.delete_project_delivery_sub_block?.returning[0]?.id;
          if (!deletedId) throw new Error("No deleted ID");

          cache.modify<GetSubBlockFieldsQuery>({
            fields: {
              project_delivery_sub_block: (
                existingRefs = [],
                { readField },
              ) => {
                return existingRefs.filter(
                  (ref) => deletedId !== readField("id", ref),
                );
              },
            },
          });
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SitedeliveryTimeBlockModal
      loading={loading}
      modalClose={props.modalClose}
      modalVisible={props.modalVisible}
      onSubmit={onSubmit}
      onDelete={onDelete}
      timeBlock={{
        subBlockId: subBlockData.id,
        calendarsIds: subBlockData.calendarIds,
        subcontractorIds: [subBlockData.subcontractorExpected.id],
        weekdayMask: subBlockData.weekday,
        time: {
          start: dayjs(subBlockData.from),
          end: dayjs(subBlockData.to),
        },
      }}
    />
  );
};

export default SubBlockEditModal;
