import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import { GCProjectCalendarSitedeliverySubBlocksTimezoneQuery } from "src/common/types/generated/relay/GCProjectCalendarSitedeliverySubBlocksTimezoneQuery.graphql";
import {
  GetDeliveryAndSubBlockFieldsDocument,
  GetDeliveryAndSubBlockFieldsQuery,
  GetDeliveryAndSubBlockFieldsQueryVariables,
  Order_By,
  useDeleteUserProjectFilterCalendarMutation,
  useInsertUserProjectFilterCalendarMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import { DatesRangeType } from "src/common/types/manual/DatesRange";
import * as uuid from "uuid";
import { SitedeliveryCalendarType } from "../utilities/sitedeliveryTypes";
import GCProjectCalendarSitedeliverySubBlocksUI from "./GCProjectCalendarSitedeliverySubBlocksUI";
import compareStrings from "src/common/functions/compareStrings";

const timezoneQuery = graphql`
  query GCProjectCalendarSitedeliverySubBlocksTimezoneQuery(
    $where: project_bool_exp!
  ) {
    project_connection(where: $where) {
      edges {
        node {
          timezone
        }
      }
    }
  }
`;

interface GCProjectCalendarSitedeliverySubBlocksProps {
  hideNewTimeBlockButton?: boolean;
}

const getCalendarWhereFromStoreFieldName = (storeFieldName: string) => {
  try {
    const jsonVariables = storeFieldName.substring(
      storeFieldName.indexOf("{"),
      storeFieldName.lastIndexOf("}") + 1,
    );
    const variables = JSON.parse(jsonVariables);
    return variables.where;
  } catch {
    return null;
  }
};

const GCProjectCalendarSitedeliverySubBlocks: React.FC<
  GCProjectCalendarSitedeliverySubBlocksProps
> = (props) => {
  const { projectId } = useParams();
  const [insertFilterCalendar] = useInsertUserProjectFilterCalendarMutation();
  const [deleteFilterCalendar] = useDeleteUserProjectFilterCalendarMutation();

  const projectTimeZone =
    useLazyLoadQuery<GCProjectCalendarSitedeliverySubBlocksTimezoneQuery>(
      timezoneQuery,
      { where: { id: { _eq: projectId } } },
    ).project_connection.edges.at(0)?.node.timezone;

  const [datesRange, setDatesRange] = useState<DatesRangeType>({
    from: dayjs().startOf("week").add(1, "d").tz(projectTimeZone, true),
    to: dayjs().endOf("week").add(1, "d").tz(projectTimeZone, true),
  });
  const [loading, setLoading] = useState(false);

  const userId = auth.currentUser?.uid;
  if (!projectId) {
    throw new Error("project id does not exist");
  }
  if (!userId) {
    throw new Error("user id does not exist");
  }

  const { data } = useSuspenseQuery<
    GetDeliveryAndSubBlockFieldsQuery,
    GetDeliveryAndSubBlockFieldsQueryVariables
  >(GetDeliveryAndSubBlockFieldsDocument, {
    variables: {
      projectId,
      delivery_where: {
        project_id: { _eq: projectId },
      },
      delivery_order_by: [{ start_at: Order_By.Desc }],
      blockout_where: {
        project_id: { _eq: projectId },
      },
      sub_block_where: {
        project_id: { _eq: projectId },
      },
      calendar_where: {
        projects: {
          project_id: { _eq: projectId },
          is_archive: { _eq: false },
        },
      },
      hiddenCalendarWhere: {
        filter_type: { _eq: "web-hidden-calendar" },
        project_id: { _eq: projectId },
        user_id: { _eq: userId },
      },
    },
  });

  const filterCalendarsData = useMemo(
    () =>
      data.hiddenCalendars
        .map((c) => c.calendar)
        .sort((fc1, fc2) => compareStrings(fc2.id, fc1.id)),
    [data.hiddenCalendars],
  );

  const calendarsData = useMemo(
    () => [...data.calendars].sort((c1, c2) => compareStrings(c2.id, c1.id)),
    [data.calendars],
  );
  const deliveryData = data.deliveries;
  const blockoutData = data.blockouts;
  const subBlockData = data.subBlocks;
  const gcData = data.project.at(0)?.general_contractor;

  // Adding Calendars
  const siteDeliveryData = useMemo<SitedeliveryCalendarType[]>(() => {
    const tempSiteDeliveryData = calendarsData.map(
      (c): SitedeliveryCalendarType => {
        return {
          id: c.id,
          colorHex: c.color_hex.slice(1, 7),
          title: c.name.en,
          events: [],
          titleId: c.name.id,
        };
      },
    );

    // Adding deliveries
    for (
      let deliveryIndex = 0;
      deliveryIndex < deliveryData.length;
      deliveryIndex++
    ) {
      const deliveryNode = deliveryData[deliveryIndex];
      const deliveryCalendarData = deliveryNode.calendars
        .map((c) => c.calendar)
        .sort((c1, c2) => compareStrings(c2.id, c1.id));
      //sort in descending order

      for (
        let deliveryCalendarIndex = 0, siteDeliveryIndex = 0;
        deliveryCalendarIndex < deliveryCalendarData.length;
        deliveryCalendarIndex++
      ) {
        while (
          siteDeliveryIndex < tempSiteDeliveryData.length &&
          tempSiteDeliveryData[siteDeliveryIndex].id >
            deliveryCalendarData[deliveryCalendarIndex]?.id
        ) {
          siteDeliveryIndex++;
        }
        while (
          deliveryCalendarIndex < deliveryCalendarData.length &&
          tempSiteDeliveryData[siteDeliveryIndex].id <
            deliveryCalendarData[deliveryCalendarIndex]?.id
        ) {
          deliveryCalendarIndex++;
        }
        const deliveryDuration = deliveryNode.duration;
        if (
          siteDeliveryIndex < tempSiteDeliveryData.length &&
          deliveryCalendarIndex < deliveryCalendarData.length &&
          tempSiteDeliveryData[siteDeliveryIndex].id ===
            deliveryCalendarData[deliveryCalendarIndex]?.id &&
          !!deliveryDuration
        ) {
          tempSiteDeliveryData[siteDeliveryIndex].events.push({
            type: "delivery",
            title: {
              id: deliveryNode.name.id,
              text: deliveryNode.name.en,
            },
            detail: deliveryNode.detail
              ? {
                  id: deliveryNode.detail.id,
                  text: deliveryNode.detail.en,
                }
              : undefined,
            from: dayjs(deliveryNode.start_at).tz(projectTimeZone),
            subcontractor: deliveryNode.subcontractor
              ? {
                  title: deliveryNode.subcontractor.name,
                  id: deliveryNode.subcontractor.id,
                }
              : {
                  title: gcData?.name ?? "",
                  id: gcData?.id ?? "",
                },
            // TODO: check if this is correct
            id: deliveryNode.id,
            to: dayjs(deliveryNode.start_at)
              .add(deliveryDuration, "hour")
              .tz(projectTimeZone),
            createdAt: dayjs(deliveryNode.created_at),
            createdBy: deliveryNode.user.name,
            reviewed: deliveryNode.approved_by
              ? {
                  by: deliveryNode.approved_by?.name ?? "",
                  type: "approve",
                  remark: deliveryNode.remark?.en,
                }
              : undefined,
            storageLocation: deliveryNode.storage_location
              ? {
                  id: deliveryNode.storage_location.id,
                  text: deliveryNode.storage_location.en,
                }
              : undefined,
            calendars: deliveryNode.calendars.map(({ calendar }) => ({
              colorHex: calendar.color_hex,
              id: calendar.id,
              title: calendar.name.en,
              titleId: calendar.name.id,
            })),
          });
        }
      }
    }

    // Adding blockouts
    for (
      let siteDeliveryIndex = 0;
      siteDeliveryIndex < tempSiteDeliveryData.length;
      siteDeliveryIndex++
    ) {
      for (
        let blockoutIndex = 0;
        blockoutIndex < blockoutData.length;
        blockoutIndex++
      ) {
        for (
          let dateIndex = datesRange.from;
          dateIndex < datesRange.to;
          dateIndex = dayjs(dateIndex).add(1, "d")
        )
          if (dayjs(dateIndex).day() === blockoutData[blockoutIndex].weekday)
            tempSiteDeliveryData[siteDeliveryIndex].events.push({
              type: "blocked out",
              from: dayjs(
                dayjs(dateIndex)
                  .tz(projectTimeZone)
                  // .set("day", blockoutData[blockoutIndex].node.weekday)
                  .format("YYYY-MM-DD ") +
                  blockoutData[blockoutIndex].start_time,
              ),
              to: dayjs(
                dayjs(dateIndex)
                  .tz(projectTimeZone)
                  // .set("day", blockoutData[blockoutIndex].node.weekday)
                  .format("YYYY-MM-DD ") + blockoutData[blockoutIndex].end_time,
              ),
              id: blockoutData[blockoutIndex].id,
            });
      }
    }

    //Adding sub blocks
    for (
      let subBlockIndex = 0;
      subBlockIndex < subBlockData.length;
      subBlockIndex++
    ) {
      const subBlockCalendarsData = subBlockData[subBlockIndex].calendars
        .map((c) => c.calendar)
        .sort((c1, c2) => compareStrings(c2.id, c1.id));
      // We can do it for multiple subcontractors.
      const subBlockSubcontractorData =
        subBlockData[subBlockIndex].subcontractors[0];
      if (subBlockSubcontractorData.subcontractor) {
        for (
          let subBlockCalendarIndex = 0, siteDeliveryIndex = 0;
          subBlockCalendarIndex < subBlockCalendarsData.length;
          subBlockCalendarIndex++
        ) {
          while (
            siteDeliveryIndex < tempSiteDeliveryData.length &&
            tempSiteDeliveryData[siteDeliveryIndex].id >
              subBlockCalendarsData[subBlockCalendarIndex]!.id
          ) {
            siteDeliveryIndex++;
          }
          while (
            subBlockCalendarIndex < subBlockCalendarsData.length &&
            siteDeliveryIndex < tempSiteDeliveryData.length &&
            tempSiteDeliveryData[siteDeliveryIndex].id <
              subBlockCalendarsData[subBlockCalendarIndex]!.id
          ) {
            subBlockCalendarIndex++;
          }
          if (
            siteDeliveryIndex < tempSiteDeliveryData.length &&
            tempSiteDeliveryData[siteDeliveryIndex].id ===
              subBlockCalendarsData[subBlockCalendarIndex]?.id
          ) {
            for (
              let dateIndex = datesRange.from;
              dateIndex.isBefore(datesRange.to);
              dateIndex = dayjs(dateIndex).add(1, "d")
            )
              if (
                (subBlockData[subBlockIndex].weekday &
                  (1 << +dayjs(dateIndex).format("d"))) !=
                0
              ) {
                tempSiteDeliveryData[siteDeliveryIndex].events.push({
                  type: "sub block",
                  id: subBlockData[subBlockIndex].id,
                  from: dayjs(
                    dayjs(dateIndex).format("YYYY-MM-DD ") +
                      subBlockData[subBlockIndex].start_time,
                  ).tz(projectTimeZone, true),
                  to: dayjs(
                    dayjs(dateIndex).format("YYYY-MM-DD ") +
                      subBlockData[subBlockIndex].end_time,
                  ).tz(projectTimeZone, true),
                  subcontractorExpected: {
                    id: subBlockSubcontractorData.subcontractor.id,
                    title: subBlockSubcontractorData.subcontractor.name,
                  },
                  calendarIds: subBlockCalendarsData.map((c) => c.id),
                  weekday: subBlockData[subBlockIndex].weekday,
                });
              }
          }
        }
      }
    }

    return tempSiteDeliveryData;
  }, [calendarsData, deliveryData, datesRange, blockoutData, subBlockData]);

  const handleOnCalendarHide = async (calendarId: string) => {
    setLoading(true);
    const filterCalendarId = uuid.v4();
    try {
      const selectedCalendar = calendarsData.find((c) => c.id === calendarId);
      if (!selectedCalendar) {
        throw new Error("Calendar not found");
      }

      await insertFilterCalendar({
        variables: {
          object: {
            id: filterCalendarId,
            calendar_id: calendarId,
            filter_type: "web-hidden-calendar",
            project_id: projectId,
            user_id: userId,
          },
        },
        update(cache, { data }) {
          if (!data?.insert_user_project_filter_calendar_one) {
            throw new Error("No data returned from insertFilterCalendar");
          }
          const insertedCalendar = data.insert_user_project_filter_calendar_one;
          cache.modify({
            fields: {
              user_project_filter_calendar: (
                existing = [],
                { readField, storeFieldName, toReference },
              ) => {
                const whereClause =
                  getCalendarWhereFromStoreFieldName(storeFieldName);
                if (
                  whereClause?.filter_type?._eq !== "web-hidden-calendar" ||
                  whereClause?.project_id?._eq !== projectId ||
                  whereClause?.user_id?._eq !== userId
                ) {
                  return existing;
                }
                const newRef = toReference(insertedCalendar);
                if (!newRef) {
                  return existing;
                }
                const updatedEntries = [...existing, newRef];
                return updatedEntries;
              },
            },
          });
        },
        optimisticResponse: {
          insert_user_project_filter_calendar_one: {
            __typename: "user_project_filter_calendar",
            id: filterCalendarId,
            calendar: {
              __typename: "calendar",
              id: selectedCalendar.id,
              name: {
                __typename: "text_translation",
                id: selectedCalendar.name?.id,
                en: selectedCalendar.name?.en,
              },
              color_hex: selectedCalendar.color_hex,
            },
          },
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOnCalendarShow = async (calendarId: string) => {
    setLoading(true);
    try {
      await deleteFilterCalendar({
        variables: {
          where: {
            calendar_id: { _eq: calendarId },
            project_id: { _eq: projectId },
            user_id: { _eq: userId },
          },
        },
        update(cache, { data }) {
          if (!data?.delete_user_project_filter_calendar) {
            throw new Error("No deletion result received");
          }
          const deletedId =
            data.delete_user_project_filter_calendar.returning[0].id;

          cache.modify({
            fields: {
              user_project_filter_calendar: (
                existing = [],
                { readField, storeFieldName, toReference },
              ) => {
                const whereClause =
                  getCalendarWhereFromStoreFieldName(storeFieldName);
                if (
                  whereClause?.filter_type?._eq !== "web-hidden-calendar" ||
                  whereClause?.project_id?._eq !== projectId ||
                  whereClause?.user_id?._eq !== userId
                ) {
                  return existing;
                }
                const filtered = existing.filter(
                  (ref: any) => readField("id", ref) !== deletedId,
                );
                return filtered;
              },
            },
          });
        },
        optimisticResponse: {
          delete_user_project_filter_calendar: {
            __typename: "user_project_filter_calendar_mutation_response",
            returning: [
              {
                __typename: "user_project_filter_calendar",
                id: filterCalendarsData.find((c) => c.id === calendarId)!.id,
              },
            ],
          },
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <GCProjectCalendarSitedeliverySubBlocksUI
      calendars={siteDeliveryData}
      datesRange={datesRange}
      onDatesRangeChange={function (newDatesRange: DatesRangeType): void {
        setDatesRange(newDatesRange);
      }}
      hiddenCalendarIds={filterCalendarsData.map((c) => c.id)}
      onCalendarHideClick={handleOnCalendarHide}
      onCalendarShowClick={handleOnCalendarShow}
      loading={loading}
      timezone={projectTimeZone}
      hideNewTimeBlockButton={props.hideNewTimeBlockButton}
    />
  );
};

export default GCProjectCalendarSitedeliverySubBlocks;
