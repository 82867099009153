import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
// import Button from "src/common/components/general/Button";
import { SitedailyEmailSettingsQuery } from "src/common/types/generated/relay/SitedailyEmailSettingsQuery.graphql";
import SelectEmailUserModal from "./SelectEmailUserModal";
import { Button, Card } from "antd";

const query = graphql`
  query SitedailyEmailSettingsQuery($projectId: uuid!) {
    email_project_user_connection(
      first: 10000
      where: { project_id: { _eq: $projectId }, type: { _eq: "site_update" } }
    )
      @connection(
        key: "SitedailyEmailSettings_email_project_user_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          type
          user {
            id
            pk: id @__clientField(handle: "pk")
            name
            email
          }
        }
      }
    }
  }
`;

const SitedailyEmailSettings: FC = ({}) => {
  const { projectId } = useParams();
  const [showSelectEmailUserModal, setShowSelectEmailUserModal] =
    useState(false);
  const data = useLazyLoadQuery<SitedailyEmailSettingsQuery>(query, {
    projectId: projectId!,
  });

  const selectedUsers = data.email_project_user_connection.edges;
  const modalOpen = () => {
    setShowSelectEmailUserModal(true);
  };

  const modalClose = () => {
    setShowSelectEmailUserModal(false);
  };

  return (
    <Card title={`SiteUpdate - Send Daily Email`}>
      <p style={{ marginBottom: 9, fontWeight: 400 }}>
        Team Members to be emailed when sub is on site and not submitted the PTP
      </p>
      <div className="-mx-0.25">
        {selectedUsers.map((user) => (
          <span
            className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
            key={user.node.user?.id}
          >
            {user.node.user?.name}
          </span>
        ))}
      </div>

      <div className="flex flex-row justify-start">
        <Button onClick={() => modalOpen()} loading={false} type="primary">
          Select Team Member(s)
        </Button>
      </div>
      {showSelectEmailUserModal && (
        <SelectEmailUserModal
          users={selectedUsers.map((user) => {
            return {
              userId: user.node.user.pk,
              emailProjectUserId: user.node.pk,
            };
          })}
          modalClose={modalClose}
          modalVisible={showSelectEmailUserModal}
        />
      )}
    </Card>
  );
};
export default SitedailyEmailSettings;
