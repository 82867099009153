import { DatePicker, Form, Select } from "antd";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import Button from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  GenerateWorkersListExcelInput,
  useGenerateWorkersDetailsPdfMutation,
  useGenerateWorkersListExcelMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface FormValues {
  subcontractor?: string;
  workerRole?: string;
  workerTitleIds?: Array<string>;
  hasOsha?: 0 | 1 | 2;
  onsiteDate: dayjs.Dayjs;
}

interface GCDownloadWorkersListModalProps {
  visible?: boolean;
  projectId: string;
  roleOptions: Array<{ label: string; value: string }>;
  titleOptions: Array<{ label: string; value: string }>;
  subOptions: Array<{ label: string; value: string }>;
  onCancel?: () => void;
}

export type GCDownloadWorkersListModalRef = FModalRef<FormValues> | null;

const GCDownloadWorkersListModal = forwardRef<
  GCDownloadWorkersListModalRef,
  GCDownloadWorkersListModalProps
>(({ projectId, roleOptions, titleOptions, subOptions }, ref) => {
  const modal = useRef<FModalRef<FormValues>>(null);
  const [fileExtenstion, setFileExtenstion] = useState<"pdf" | "csv">("pdf");
  const [loading, setLoading] = useState(false);
  const [generateWorkersDetailsPdf] = useGenerateWorkersDetailsPdfMutation();
  const [generateWorkersListExcel] = useGenerateWorkersListExcelMutation();
  useImperativeHandle<
    GCDownloadWorkersListModalRef,
    GCDownloadWorkersListModalRef
  >(ref, () => modal.current);
  const reset = () => {
    setLoading(false);
    modal.current?.form.resetFields();
    modal.current?.close();
  };
  const changeFileExtenstion = () => {
    setFileExtenstion((prevState) => {
      if (prevState === "csv") {
        return "pdf";
      } else {
        return "csv";
      }
    });
  };

  return (
    <FModal
      ref={modal}
      confirmLoading={loading}
      form={{
        initialValues: {
          hasOsha: 0,
          workerRole: "all",
          subcontractor: "all",
        },
      }}
      title={`Download Workers List ${
        fileExtenstion === "pdf" ? "PDF" : "CSV"
      }`}
      okText="Download"
      cancelText="Cancel"
      onCancel={() => {
        reset();
      }}
      onOk={() => {
        const form = modal.current?.form;
        if (!form) return;
        form.validateFields().then(async (values) => {
          setLoading(true);
          if (fileExtenstion === "pdf") {
            await generateWorkersDetailsPdf({
              variables: {
                input: {
                  projectId: projectId,
                },
              },
            })
              .then((data) => {
                if (data.data?.generateWorkersDetailsPdf)
                  downloadFromUrl(data.data.generateWorkersDetailsPdf);
              })
              .catch((e) => console.error(e));
          } else {
            const passOsha = values.hasOsha !== 0;
            let input: GenerateWorkersListExcelInput = {
              projectId: projectId,
              ...(values.onsiteDate && {
                onsiteDate: values.onsiteDate.format(),
              }),
              ...(values.subcontractor !== "all" && {
                subcontractorId: values.subcontractor,
              }),
              ...(values.workerRole !== "all" && {
                workerRole: values.workerRole,
              }),
              ...(values.workerTitleIds && {
                workerTitleIds: values.workerTitleIds,
              }),
              ...(passOsha && {
                hasOsha: values.hasOsha === 1 ? true : false,
              }),
            };
            await generateWorkersListExcel({
              variables: {
                input: input,
              },
            })
              .then((data) => {
                if (data.data?.generateWorkersListExcel)
                  downloadFromUrl(data.data?.generateWorkersListExcel);
              })
              .catch((e) => console.error(e));
          }
          reset();
        });
      }}
    >
      <div className="flex content-center justify-center items-center mb-2">
        <Button
          onClick={changeFileExtenstion}
          label={`Change to .${fileExtenstion === "csv" ? "PDF" : "CSV"}`}
          secondary
        ></Button>
      </div>
      {fileExtenstion === "csv" && (
        <>
          <div className="flex content-center justify-center items-center text-1.5">
            Apply Filters
          </div>

          <Form.Item name="subcontractor" label="Companies and Trade Partners">
            <Select
              className="w-full"
              filterOption={getNormalSelectOptionsFilter}
              showSearch
              options={[{ label: "All subs", value: "all" }, ...subOptions]}
            />
          </Form.Item>

          <Form.Item
            name="onsiteDate"
            label="Onsite (leave blank to see the date last onsite or select a specific date to see who was onsite)"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="All Dates"
              showToday={false}
            />
          </Form.Item>
          <Form.Item name="workerRole" label="Role">
            <Select
              className="w-full"
              filterOption={getNormalSelectOptionsFilter}
              showSearch
              options={[{ label: "All roles", value: "all" }, ...roleOptions]}
            />
          </Form.Item>
          <Form.Item name="workerTitleIds" label="Trade">
            <Select
              className="w-full"
              mode="multiple"
              placeholder="All trades"
              filterOption={getNormalSelectOptionsFilter}
              showSearch
              options={titleOptions}
            />
          </Form.Item>
          <Form.Item name="hasOsha" label="OSHA certified workers only?">
            <Select className="w-full">
              <Select.Option key={0} value={0}>
                All OSHA Results
              </Select.Option>
              <Select.Option key={1} value={1}>
                Only workers with OSHA training
              </Select.Option>
              <Select.Option key={2} value={2}>
                Only workers WITHOUT OSHA training
              </Select.Option>
            </Select>
          </Form.Item>
        </>
      )}
    </FModal>
  );
});

export default withCustomSuspense(GCDownloadWorkersListModal);
