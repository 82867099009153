import { useSuspenseQuery } from "@apollo/client";
import {
  GetGcOfficesDocument,
  GetGcOfficesQuery,
  GetGcOfficesQueryVariables,
  useInsertGcOfficeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import GCAddNewLevelButton, {
  GCAddNewLevelButtonProps,
} from "../GCAddNewLevelButton";
import useAuthUser from "src/common/hooks/useAuthUser";
import OfficeTable from "../OfficeTable";
import { BusinessUnitProps } from "../../utils/withBusinessUnitId";
import useGcEmployee from "../../organization-unit/utils/useGcEmployee";

const GCAllOfficeLevelChilds: React.FC<
  Omit<GetGcOfficesQueryVariables, "includeTableData"> & {
    addNewLevelInitialVals?: GCAddNewLevelButtonProps["addNewLevelInitialVals"];
  }
> = ({ buWhere, divisionWhere, officeWhere, addNewLevelInitialVals }) => {
  const gc = useGcEmployee().general_contractor;
  const { data, refetch } = useSuspenseQuery<
    GetGcOfficesQuery,
    GetGcOfficesQueryVariables
  >(GetGcOfficesDocument, {
    variables: {
      divisionWhere,
      buWhere,
      officeWhere,
      includeTableData: true,
    },
  });
  const bu = data.gc_business_unit[0];
  if (!bu) throw new Error("Bu not found");
  const authUser = useAuthUser();
  const [insertOffice, { loading }] = useInsertGcOfficeMutation();

  return gc.hierarchy_office_name ? (
    <OfficeTable
      officeTitle={gc.hierarchy_office_name}
      offices={data.gc_office}
      addButton={
        <GCAddNewLevelButton
          {...{
            levelName: gc.hierarchy_office_name,
            inserting: loading,
            addNewLevelInitialVals,
            buSelectOptions: data.gc_business_unit,
            buLevelName: gc.hierarchy_business_unit_name,
            divisionLevelName: gc.hierarchy_division_name,
            divisionSelectOptions: data.gc_division,
            onInsert: async (vals) => {
              await insertOffice({
                variables: {
                  object: {
                    name: vals.name,
                    gc_business_unit_id: vals.businessUnitId,
                    gc_division_id: vals.divisionId,
                    general_contractor_id: gc.id,
                    created_by_uid: authUser.uid,
                    logo_url: vals.uploadedLogoUrl,
                    address: {
                      data: {
                        line_1: vals.addressLine1,
                        zip_code: vals.zip,
                        city: vals.city,
                        state_code: vals.stateCode,
                      },
                    },
                  },
                },
              });
              refetch();
            },
          }}
        />
      }
    />
  ) : null;
};
export default GCAllOfficeLevelChilds;
