import React, { createContext } from "react";
import {
  PtpQrUserType,
  DailyWorkLog,
  WorkersInfo,
  ProjectHazardsAndAwareness,
  ReportManagement,
  PendingTBT,
  PtpQrTaskType,
} from "src/domain-features/sitesafety/entry-routes/qr-project-reports/routes/ptp/types/PtpQrUserTypes";

import { InspectionUploadedImageType } from "src/domain-features/siteinspection/utils/siteInspectionTypes.d";

export type PtpQrUserState = {
  userInfo: PtpQrUserType | null;
  signInType: "quick" | "signature" | null;
  workersInfo: WorkersInfo;
  dailyWorkLog: DailyWorkLog;
  pictures: Array<InspectionUploadedImageType>;
  selectedTasks: {
    [key: string]: PtpQrTaskType;
  };
  selectedLocationIds: Array<string>;
  reportManagement: ReportManagement;
  pendingTbts: Array<PendingTBT>;
  projectHazardAndAwareness: ProjectHazardsAndAwareness;
  workerSignMap: {
    [key: string]: string;
  };
  selectedProjectCrews: Set<string>;
  timezone: string;
  isMarkOnsiteEnabled: boolean;
  isSubOnsite: boolean;
};

export type PtpQrUserContextType = {
  ptpQrUser: PtpQrUserState;
  projectId: string;
  locationOptions: Array<{ value: string; label: string }>;
  setPtpQrUser: React.Dispatch<React.SetStateAction<PtpQrUserState>>;
} | null;

const PtpQrUserContext = createContext<PtpQrUserContextType>(null);

export default PtpQrUserContext;
