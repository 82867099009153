import { useNavigate } from "react-router-dom";
import { MenuProps } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import useSelectedMenuKeys from "./useSelectedMenuKeys";

const useNavigateMenuItems: (
  menuItems: ItemType[],
  basePath: string,
) => {
  selectedKeys: MenuProps["selectedKeys"];
  onSelect: MenuProps["onSelect"];
} = (menuItems, basePath) => {
  const navigate = useNavigate();

  const onSelect: MenuProps["onSelect"] = (selectInfo) => {
    const { keyPath } = selectInfo;
    const keyPathStr = keyPath.reverse().join("/");
    if (keyPath.length > 0) {
      navigate(`${basePath}/${keyPathStr}`);
    }
  };

  const selectedKeys = useSelectedMenuKeys(basePath, menuItems);

  return {
    selectedKeys,
    onSelect,
  };
};

export default useNavigateMenuItems;
