import { Button, Card, Radio, Spin, Tag } from "antd";
import React, { FC, useState } from "react";

import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import EmailProjectUserSettings from "../EmailProjectUserSettings";

import ProjectLocalCities from "../ProjectLocalCities";
import ChangeProjectHierarchyModal from "../../../components/ChangeProjectHierarchyModal";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import getLogoToShowFromProjectData from "src/common/functions/getLogoToShow";
import ProjectLocations from "../ProjectLocations";
import { useGetProjectLocationsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import ProcoreProjectIntegrationSettings from "../ProcoreProjectIntegrationSettings";
import ProfileAvatar from "../../../../../subcontractor/people/workers/worker-profile/ProfileAvatar";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import useGetProjectSettingsData from "../utils/useGetProjectSettingsData";
import {
  useUpdateProcoreProjectDataValue,
  useUpdateProjectValue,
} from "../utils/projectRelatedSettingsUpdateFunctions";
import GCProjectSettingsReportsWeeklyEmail from "./GCProjectSettingsWeeklyEmail";
import { useApolloClient } from "@apollo/client";

const GCProjectSettingsGeneral: FC<ProjectIdProps> = ({ projectId }) => {
  const {
    data: locationsData,
    loading: locationsLoading,
    error,
  } = useGetProjectLocationsQuery({
    variables: { where: { project_id: { _eq: projectId } } },
  });
  const { project, procore_project_data } =
    useGetProjectSettingsData(projectId);
  const { userData } = useUserData();

  const { updateProcoreProjectValue } = useUpdateProcoreProjectDataValue(
    projectId,
    procore_project_data,
  );
  const apolloClient = useApolloClient();
  const { updateProjectValue, loading: projectUpdating } =
    useUpdateProjectValue(projectId, project);
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);

  return (
    <div className="flex flex-col gap-1">
      <Card title={`Upload/Change Project Logo`}>
        <div className={`w-20`}>
          <ProfileAvatar
            imageUrl={getLogoToShowFromProjectData(project) || undefined}
            loading={projectUpdating}
            onFileUpload={async (imageUrl) =>
              await updateProjectValue({ logo_url: imageUrl }, false)
            }
            onFileRemove={async () =>
              await updateProjectValue({ logo_url: null }, false)
            }
          />
        </div>
      </Card>

      <Card title={`Project Status`}>
        <Spin spinning={projectUpdating}>
          <Radio.Group
            className={`flex flex-col gap-0.5`}
            onChange={async (e) => {
              const completed = e.target.value;
              await updateProjectValue({ completed }, false);
              apolloClient.cache.modify({
                id: apolloClient.cache.identify({
                  id: project.pk,
                  __typename: "project",
                }),
                fields: { completed: () => completed },
              });
            }}
            value={project.completed}
            options={[
              { value: false, label: <Tag>Active</Tag> },
              { value: true, label: <Tag>Completed </Tag> },
            ]}
          />
        </Spin>
      </Card>
      <Card title={`Project Hierarchy`}>
        {userData.employee?.is_corporate_admin &&
        (userData.employee.general_contractor.hierarchy_business_unit_name ||
          userData.employee.general_contractor.hierarchy_division_name ||
          userData.employee.general_contractor.hierarchy_office_name) ? (
          <>
            {showHierarchyModal && (
              <ChangeProjectHierarchyModal
                projectId={projectId}
                showLevels={["business-unit", "division", "office"]}
                onSuccess={() => setShowHierarchyModal(false)}
                onCancel={() => setShowHierarchyModal(false)}
                officeId={project.gc_office_id}
                divisionId={project.gc_division_id}
                businessUnitId={project.gc_business_unit_id}
              />
            )}
            <Button type="primary" onClick={() => setShowHierarchyModal(true)}>
              Change Project Hierarchy Level
            </Button>
          </>
        ) : null}
      </Card>
      <EmailProjectUserSettings
        type="active_users"
        key="active_users"
        projectId={projectId}
        title="Active Users"
        description="Team Members to be emailed on the 1st day of each month the list of Active Users (users that have access to the project in the mobile app or website)"
      />
      <EmailProjectUserSettings
        type="safety_directors"
        key="safety_directors"
        projectId={projectId}
        title="Area Safety Director"
        buttonTitle="Select Safety Director"
        description="Safety Director(s) assigned to this project"
      />
      <EmailProjectUserSettings
        type="incorrect_workers"
        key="incorrect_workers"
        projectId={projectId}
        title="Employee Verification"
        description="Select your Team Member that will be notified by email when a Subcontractor marks a worker as “Not our Employee” meaning they do not recognize an employee "
        projectEmpFilter={{
          _or: [
            { direct_project_assign: { _eq: true } },
            { direct_project_assign: { _is_null: true } },
          ],
        }}
      />
      <Card title={`Project Locations`}>
        {locationsLoading ? (
          <Spin />
        ) : locationsData ? (
          <ProjectLocations
            data={locationsData}
            projectId={projectId}
            projectSubs={project.project_subcontractors.map(
              (s) => s.subcontractor_id,
            )}
          />
        ) : error ? (
          <div className="text-semantic-negative">{error.message}</div>
        ) : (
          "Locations data not found"
        )}
      </Card>
      <ProjectLocalCities
        stateCode={project.address.state_code}
        currentCities={project.project_local_cities}
        projectId={projectId}
      />

      <ProcoreProjectIntegrationSettings
        projectId={projectId}
        project={project}
        updateProcoreProjectValue={updateProcoreProjectValue}
      />
      <Card title={`Weekly Email`}>
        <GCProjectSettingsReportsWeeklyEmail />
      </Card>
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectSettingsGeneral);
