import { Form, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";
import {
  GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation,
  email_general_contractor_user_insert_input,
} from "src/common/types/generated/relay/GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation.graphql";
import { GCCompleteCorporateEmailSelectModal_updat_project_Mutation } from "src/common/types/generated/relay/GCCompleteCorporateEmailSelectModal_updat_project_Mutation.graphql";
import * as uuid from "uuid";

interface GCCompleteCorporateEmailSelectModalValues {
  projects: Array<string>;
  users: Array<string>;
}

interface GCCompleteCorporateEmailSelectModalProps {
  visible: boolean;
  onClose: () => void;
  projects: Array<{ name: string; id: string }>;
  gcUsers: Array<{ name: string; id: string }>;
  selectedUsers: Array<string>;
  selectedProjects: Array<string>;
  gcId: string;
}

const GCCompleteCorporateEmailSelectModal: React.FC<
  GCCompleteCorporateEmailSelectModalProps
> = ({
  visible,
  onClose,
  selectedUsers,
  gcId,
  selectedProjects,
  gcUsers,
  projects,
}) => {
  const [updatEmailGCUser, isUpdating] =
    useMutation<GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation>(graphql`
      mutation GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation(
        $objects: [email_general_contractor_user_insert_input!]!
        $where: email_general_contractor_user_bool_exp!
      ) {
        insert_email_general_contractor_user(objects: $objects) {
          returning {
            id
            pk: id @__clientField(handle: "pk")
            general_contractor_id
            user_id
            type
          }
        }
        delete_email_general_contractor_user(where: $where) {
          returning {
            general_contractor_id
            user_id
            type
            id
          }
        }
      }
    `);
  const [updateProject, isUpdatingProject] =
    useMutation<GCCompleteCorporateEmailSelectModal_updat_project_Mutation>(graphql`
      mutation GCCompleteCorporateEmailSelectModal_updat_project_Mutation(
        $projectToSetTrue: [uuid!]!
        $projectToSetFalse: [uuid!]!
      ) {
        setTrue: update_project(
          where: { id: { _in: $projectToSetTrue } }
          _set: { send_corporate_video_completion_report: true }
        ) {
          returning {
            id
            send_corporate_video_completion_report
          }
        }
        setFalse: update_project(
          where: { id: { _in: $projectToSetFalse } }
          _set: { send_corporate_video_completion_report: false }
        ) {
          returning {
            id
            send_corporate_video_completion_report
          }
        }
      }
    `);
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={visible}
      confirmLoading={isUpdating || isUpdatingProject}
      title="Corporate Video Completion List Report"
      okText="Confirm"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values: GCCompleteCorporateEmailSelectModalValues) => {
            const insertEmailGCUsers: Array<email_general_contractor_user_insert_input> =
              values.users
                .filter(
                  (uid) => !selectedUsers.find((userId) => userId === uid),
                )
                .map((user) => {
                  const insertEmailGCUserId = uuid.v4();
                  return {
                    id: insertEmailGCUserId,
                    general_contractor_id: gcId,
                    user_id: user,
                    type: "corporate_completion_weekly_email",
                  };
                });

            const deleteEmailGCUsers = selectedUsers.filter(
              (user) =>
                !values.users.find((u) => {
                  return u === user;
                }),
            );
            const projectToSetFalse: Array<string> = [],
              projectToSetTrue: Array<string> = [];
            projects.forEach((project) => {
              if (
                !values.projects.find((p) => {
                  return p === project.id;
                })
              ) {
                projectToSetFalse.push(project.id);
              } else {
                projectToSetTrue.push(project.id);
              }
            });
            updateProject({
              variables: { projectToSetFalse, projectToSetTrue },
              optimisticResponse: {
                setTrue: {
                  returning: projectToSetTrue.map((p) => ({
                    id: p,
                    send_corporate_video_completion_report: true,
                  })),
                },
                setFalse: {
                  returning: projectToSetFalse.map((p) => ({
                    id: p,
                    send_corporate_video_completion_report: false,
                  })),
                },
              },
            });
            updatEmailGCUser({
              variables: {
                objects: insertEmailGCUsers,
                where: {
                  general_contractor_id: { _eq: gcId },
                  type: { _eq: "corporate_completion_weekly_email" },
                  user_id: {
                    _in: deleteEmailGCUsers,
                  },
                },
              },
              updater: (store, payload) => {
                const insertedEmailGCUsers = store.getRootField(
                  "insert_email_general_contractor_user",
                );
                const deletedEmailGCUser = store.getRootField(
                  "delete_email_general_contractor_user",
                );
                const conn1 = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "GCCompanySettingsGeneralQuery_email_general_contractor_user_connection",
                );
                if (conn1) {
                  insertedEmailGCUsers
                    .getLinkedRecords("returning")
                    .forEach((c) => {
                      const edge = store.create(uuid.v4(), "edge");
                      edge.setLinkedRecord(c, "node");
                      ConnectionHandler.insertEdgeAfter(conn1, edge);
                    });
                  deletedEmailGCUser
                    .getLinkedRecords("returning")
                    .forEach((c) => {
                      ConnectionHandler.deleteNode(conn1, c.getDataID());
                    });
                }
              },
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
        onClose();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          users: selectedUsers,
          projects: selectedProjects,
        }}
      >
        <Form.Item name="users" label="Select Users to send email">
          <Select
            mode="multiple"
            disabled={isUpdating || isUpdatingProject}
            // value={Object.keys(selectedUsers).map((v) => v)}
          >
            {gcUsers.map((gcu) => (
              <Select.Option value={gcu.id} key={gcu.id}>
                {gcu.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="projects" label="Select Projects to send email">
          <Select
            mode="multiple"
            disabled={isUpdating || isUpdatingProject}
            // value={Object.keys(selectedProjects).map((v) => v)}
          >
            {projects.map((p) => (
              <Select.Option value={p.id} key={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GCCompleteCorporateEmailSelectModal;
