import { useNavigate } from "react-router-dom";
import GCProjectReportsPermitsDetail from "../../components/ProjectReportsPermitsDetail";
import AppPageLayout from "../../../../common/components/layouts/main/content/AppPageLayout";
import { SettingOutlined } from "@ant-design/icons";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";

const GCProjectPermitDetailPage: React.FC<ProjectIdProps> = (props) => {
  const navigate = useNavigate();

  const goToSettings = () => {
    navigate(getGcProjectSettingsPath(props.projectId, "permits"));
  };

  return (
    <AppPageLayout
      pageTitle={"Permit Detail"}
      pageActions={[
        {
          children: "Settings",
          onClick: goToSettings,
          icon: <SettingOutlined />,
        },
      ]}
    >
      <GCProjectReportsPermitsDetail />
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCProjectPermitDetailPage);
