import { Card, DatePicker, Divider, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import GCOnsiteSettings from "src/domain-features/sitesafety/components/onsite-settings/GCOnsiteSettings";
import { GCProjectSettingsOnsiteUpdateProjectMutation } from "src/common/types/generated/relay/GCProjectSettingsOnsiteUpdateProjectMutation.graphql";
import { GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation } from "src/common/types/generated/relay/GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { RangeType } from "src/common/components/tables/basic/CustomTableDateRangePicker";
import useGetReportManagementData from "src/domain-features/sitesafety/entry-routes/report-settings/utils/useGetReportManagementData";
import ProjectIdProps from "../../../../../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import SwitchWithText from "../../../../../../../../common/components/SwitchWithText";

const updateProjectMutation = graphql`
  mutation GCProjectSettingsOnsiteUpdateProjectMutation(
    $projectId: uuid!
    $set: project_set_input!
  ) {
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $set) {
      id
      is_project_reporting_active
      mark_sub_onsite_on_report_submission
    }
  }
`;

const updatePauseDashNotificationDateMutation = graphql`
  mutation GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation(
    $projectId: uuid!
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    update_project_by_pk(
      pk_columns: { id: $projectId }
      _set: {
        pause_dash_notification_start_date: $startDate
        pause_dash_notification_end_date: $endDate
      }
    ) {
      id
      pause_dash_notification_start_date
      pause_dash_notification_end_date
    }
  }
`;
const GCProjectSettingsOnsite: React.FC<ProjectIdProps> = ({ projectId }) => {
  const { project, ...props } = useGetReportManagementData(projectId);
  const [updateProject, loading] =
    useAsyncMutation<GCProjectSettingsOnsiteUpdateProjectMutation>(
      updateProjectMutation,
    );
  const [updatePauseDashNotificationDate, updatePauseNotifLoading] =
    useAsyncMutation<GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation>(
      updatePauseDashNotificationDateMutation,
    );

  const pauseNotificationDate: [Dayjs | null, Dayjs | null] | null =
    project.pause_dash_notification_start_date &&
    project.pause_dash_notification_end_date &&
    dayjs(project.pause_dash_notification_end_date)
      .tz(project.timezone)
      .utc(true)
      .local()
      .toDate()
      .getTime() >= dayjs().toDate().getTime()
      ? [
          dayjs(project.pause_dash_notification_start_date)
            .tz(project.timezone)
            .utc(true)
            .local(),
          dayjs(project.pause_dash_notification_end_date)
            .tz(project.timezone)
            .utc(true)
            .local(),
        ]
      : [null, null];
  const onChangePauseNotificationDate = async (range: RangeType | null) => {
    if (!range) {
      await updatePauseDashNotificationDate({
        variables: {
          projectId: projectId,
          startDate: null,
          endDate: null,
        },
        optimisticResponse: {
          update_project_by_pk: {
            id: project.id,
            pause_dash_notification_start_date: null,
            pause_dash_notification_end_date: null,
          },
        },
      });
      return;
    }
    const [from, to] = range;
    if (!from || !to) {
      return;
    }
    await updatePauseDashNotificationDate({
      variables: {
        projectId: projectId,
        startDate: from.startOf("d").tz(project.timezone, true).toISOString(),
        endDate: to.endOf("d").tz(project.timezone, true).toISOString(),
      },
      optimisticResponse: {
        update_project_by_pk: {
          id: projectId,
          pause_dash_notification_start_date: from
            .startOf("d")
            .tz(project.timezone, true)
            .toISOString(),
          pause_dash_notification_end_date: to
            .endOf("d")
            .tz(project.timezone, true)
            .toISOString(),
        },
      },
    });
  };

  const setMarkOnsiteOnReportSubmission = async (newValue: boolean) => {
    await updateProject({
      variables: {
        projectId,
        set: { mark_sub_onsite_on_report_submission: newValue },
      },
      optimisticResponse: {
        update_project_by_pk: {
          id: project.id,
          is_project_reporting_active: project.is_project_reporting_active,
          mark_sub_onsite_on_report_submission: newValue,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-1">
      <Card title={"Auto-Set Company ONSITE"}>
        <SwitchWithText
          loading={loading}
          text={`Automatically set a company ONSITE when they submit a report in SiteForm`}
          checked={project[`mark_sub_onsite_on_report_submission`]}
          onChange={(newValue) => setMarkOnsiteOnReportSubmission(newValue)}
        />
      </Card>

      <Card title={`Pause Automatic Notifications`}>
        <Typography>
          <Typography.Paragraph>
            Select the date range to pause notifications
          </Typography.Paragraph>
        </Typography>

        <DatePicker.RangePicker
          value={pauseNotificationDate}
          allowEmpty={[true, true]}
          allowClear
          disabled={updatePauseNotifLoading}
          disabledDate={(date) => date < dayjs().startOf("d")}
          onChange={onChangePauseNotificationDate}
        />
      </Card>

      <Divider />
      <GCOnsiteSettings
        projectId={projectId}
        crewLeads={props.crewLeads}
        subOnsiteData={props.subOnsiteData}
        projectTimezone={project.timezone}
      />
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectSettingsOnsite);
