import { useState } from "react";
import usePermanentOnsiteSetting from "../../utils/usePermanentOnsiteSetting";
import usePermanentReportRequireSettings from "../../utils/usePermanentReportRequireSettings";
import { SubOnsiteData } from "../performance/GCSubOnsitePerformanceTable";
import { OnsiteSettingsSwitchers } from "./onsiteSettings";
import { Switch, SwitchProps } from "antd";

type ReportType = "safety_report" | "toolbox_talk" | "daily_report";
const useGetSwitchers = (projectId: string) => {
  const { setCrewNotRequire, setCrewRequire, setSubNotRequire, setSubRequire } =
    usePermanentReportRequireSettings();
  const { setCrewOffSite, setCrewOnSite, setSubOffSite, setSubOnSite } =
    usePermanentOnsiteSetting(projectId);
  const [loadingCrew, setLoadingCrew] = useState<string[]>([]);
  const [loadingSub, setLoadingSub] = useState<string[]>([]);
  // const [subData, setSubData] = useState(subOnsiteData);

  const crewLoadingStart = (loadingCrewIds: Set<string>) =>
    setLoadingCrew((prev) => [...prev, ...loadingCrewIds]);
  const crewLoadingStop = (loadingCrewIds: Set<string>) =>
    setLoadingCrew((prev) => [...prev.filter((p) => !loadingCrewIds.has(p))]);

  const setCrewRequireSettings = async (item: {
    setTo: boolean;
    crewIds: string[];
    subId: string;
    type: ReportType;
    loadingAlreadyHandled?: boolean;
  }) => {
    if (item.setTo) {
      if (item.crewIds[0])
        await setCrewRequire({
          subId: item.subId,
          crewId: item.crewIds[0],
          type: item.type,
        });
      else throw new Error("crew Id not found");
    } else {
      await setCrewNotRequire({
        subId: item.subId,
        crewIds: item.crewIds,
        type: item.type,
      });
    }
  };
  const setSubRequireSettings = async (item: {
    setTo: boolean;
    subId: string;
    type: ReportType;
  }) => {
    setLoadingSub((prev) => [...prev, item.subId + "_" + item.type]);
    try {
      if (item.setTo) {
        await setSubRequire({
          subId: item.subId,
          type: item.type,
          projectId,
        });
      } else {
        await setSubNotRequire({
          subId: item.subId,
          type: item.type,
          projectId,
        });
      }
    } finally {
      setLoadingSub((prev) => [
        ...prev.filter((p) => p !== item.subId + "_" + item.type),
      ]);
    }
  };
  const handleSubReportSwitcherChange = async (
    require: boolean,
    sub: SubOnsiteData,
    reportType: ReportType,
  ) => {
    const crewIds: Array<string> = [];
    const loadingIds: Array<string> = [];
    if (!require) {
      sub.crews.forEach((crew) => {
        if (crew[`${reportType}s_required`]) {
          crewIds.push(crew.crewId);
          loadingIds.push(crew.crewId + "_" + reportType);
        }
      });
    }
    try {
      setLoadingCrew((prev) => [...prev, ...loadingIds]);
      await setSubRequireSettings({
        subId: sub.subId,
        setTo: require,
        type: reportType,
      });
      if (crewIds.length) {
        await setCrewRequireSettings({
          subId: sub.subId,
          setTo: false,
          crewIds,
          type: reportType,
          loadingAlreadyHandled: true,
        });
      }
    } finally {
      setLoadingCrew((prev) => [
        ...prev.filter((p) => !loadingIds.includes(p)),
      ]);
    }
  };
  const getSubReportSwitcherProps = (
    sub: SubOnsiteData,
    reportType: ReportType,
  ): SwitchProps => ({
    onChange: async (newValue) =>
      await handleSubReportSwitcherChange(newValue, sub, reportType),
    loading: !!loadingSub.find((s) => s === sub.subId + "_" + reportType),
    value: sub[`${reportType}s_required`],
  });

  const handleSubOnsiteSwitcherChange = async (
    newValue: boolean,
    sub: SubOnsiteData,
  ) => {
    if (newValue) {
      setLoadingSub((prev) => [...prev, sub.subId]);
      try {
        await setSubOnSite(sub.subId);
      } finally {
        setLoadingSub((prev) => [...prev.filter((p) => sub.subId !== p)]);
      }
    } else {
      const crewIds = new Set<string>();
      sub.crews.forEach((crew) => {
        if (crew.onsite) crewIds.add(crew.crewId);
      });
      setLoadingSub((prev) => [...prev, sub.subId]);
      crewLoadingStart(crewIds);
      try {
        await setSubOffSite(sub.subId);
      } finally {
        crewLoadingStop(crewIds);
        setLoadingSub((prev) => [...prev.filter((p) => p !== sub.subId)]);
      }
    }
  };
  const getSubSwitchersFor: (sub: SubOnsiteData) => OnsiteSettingsSwitchers = (
    sub,
  ) => ({
    onsite: (
      <Switch
        {...{
          onChange: async (newValue) =>
            await handleSubOnsiteSwitcherChange(newValue, sub),
          loading: !!loadingSub.find((s) => s === sub.subId),
          value: sub.onsite,
          checkedChildren: "Onsite",
          unCheckedChildren: "Offsite",
        }}
      />
    ),
    preTaskPlans: (
      <Switch {...getSubReportSwitcherProps(sub, "safety_report")} />
    ),
    dailyReports: (
      <Switch {...getSubReportSwitcherProps(sub, "daily_report")} />
    ),
    toolboxTalks: (
      <Switch {...getSubReportSwitcherProps(sub, "toolbox_talk")} />
    ),
  });

  const getCrewReportSwitcherProps = (
    crew: SubOnsiteData["crews"][number],
    sub: SubOnsiteData,
    reportType: ReportType,
  ): SwitchProps => ({
    onChange: async (require) => {
      crewLoadingStart(new Set([crew.crewId + "_" + reportType]));
      if (require && !sub[`${reportType}s_required`]) {
        await setSubRequireSettings({
          subId: sub.subId,
          setTo: true,
          type: reportType,
        });
      }
      await setCrewRequireSettings({
        setTo: require,
        subId: sub.subId,
        crewIds: [crew.crewId],
        type: reportType,
      });
      crewLoadingStop(new Set([crew.crewId + "_" + reportType]));
    },
    loading: !!loadingCrew.find((s) => s === crew.crewId + "_" + reportType),
    value: crew[`${reportType}s_required`],
  });

  const handleCrewOnsiteSwitcherChange = async (
    newValue: boolean,
    crew: SubOnsiteData["crews"][number],
    sub: SubOnsiteData,
  ) => {
    const crewLoadingItems = new Set([crew.crewId]);
    try {
      if (newValue) {
        crewLoadingStart(crewLoadingItems);

        if (!sub.onsite) {
          setLoadingSub((prev) => [...prev, sub.subId]);
          await setSubOnSite(sub.subId);
        }
        await setCrewOnSite(crew.crewId, sub.subId);
      } else {
        crewLoadingStart(crewLoadingItems);
        await setCrewOffSite(crew.crewId, sub.subId);
      }
    } finally {
      crewLoadingStop(crewLoadingItems);
      setLoadingSub((prev) => prev.filter((p) => p !== sub.subId));
    }
  };

  const getCrewSwitchersFor: (
    crew: SubOnsiteData["crews"][number],
    sub: SubOnsiteData,
  ) => OnsiteSettingsSwitchers = (crew, sub) => ({
    onsite: (
      <Switch
        {...{
          onChange: async (newValue) =>
            await handleCrewOnsiteSwitcherChange(newValue, crew, sub),
          loading: !!loadingCrew.find((s) => s === crew.crewId),
          value: crew.onsite,
          checkedChildren: "Onsite",
          unCheckedChildren: "Offsite",
        }}
      />
    ),
    preTaskPlans: (
      <Switch {...getCrewReportSwitcherProps(crew, sub, "safety_report")} />
    ),
    dailyReports: (
      <Switch {...getCrewReportSwitcherProps(crew, sub, "daily_report")} />
    ),
    toolboxTalks: (
      <Switch {...getCrewReportSwitcherProps(crew, sub, "toolbox_talk")} />
    ),
  });

  return {
    getSubSwitchersFor,
    getCrewSwitchersFor,
  };
};
export default useGetSwitchers;
