/**
 * @generated SignedSource<<c85b2ffc5d8feb507b2d2f852284e527>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation$variables = {
  GCId: string;
  switch: boolean;
};
export type GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation$data = {
  readonly insert_procore_auth_data_one: {
    readonly general_contractor_id: string;
    readonly integration_enabled: boolean;
  } | null | undefined;
};
export type GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation = {
  response: GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation$data;
  variables: GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "GCId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "switch"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "general_contractor_id",
        "variableName": "GCId"
      },
      {
        "kind": "Variable",
        "name": "integration_enabled",
        "variableName": "switch"
      }
    ],
    "kind": "ObjectValue",
    "name": "object"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "procore_auth_data_pkey",
      "update_columns": [
        "integration_enabled"
      ]
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integration_enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_auth_data",
        "kind": "LinkedField",
        "name": "insert_procore_auth_data_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_auth_data",
        "kind": "LinkedField",
        "name": "insert_procore_auth_data_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37738ced8c2c9629920b40afc5b3147d",
    "id": null,
    "metadata": {},
    "name": "GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation(\n  $switch: Boolean!\n  $GCId: uuid!\n) {\n  insert_procore_auth_data_one(object: {general_contractor_id: $GCId, integration_enabled: $switch}, on_conflict: {constraint: procore_auth_data_pkey, update_columns: [integration_enabled]}) {\n    general_contractor_id\n    integration_enabled\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "264514c5123e99f0a1ce07092fe5b6cb";

export default node;
