import { OrganizationLevelName } from "./hierarchyTypes";

const getGcOrganizationUnitPath = (
  type: OrganizationLevelName,
  id: string,
  featurePath?: string,
): string => {
  return `/gce/hierarchy/organization-unit/${type}/${id}${
    featurePath ? `/${featurePath}` : ""
  }`;
};

export default getGcOrganizationUnitPath;
