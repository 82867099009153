import React from "react";
import { useSearchParams } from "react-router-dom";
import SubProjectMobilizationView from "./SubProjectMobilizationView";

export interface SubProjectMobilizationViewWrapperProps {}

const SubProjectMobilizationPseudoLoginView: React.FC<
  SubProjectMobilizationViewWrapperProps
> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get("project_id");
  const subcontractorId = searchParams.get("subcontractor_id");

  if (!projectId) {
    throw new Error("project_id param is missing");
  }

  if (!subcontractorId) {
    throw new Error("subcontractor_id param is missing");
  }

  return (
    <SubProjectMobilizationView
      projectId={projectId}
      subcontractorId={subcontractorId}
      showLoginText={true}
    />
  );
};

export default SubProjectMobilizationPseudoLoginView;
