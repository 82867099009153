import { useSuspenseQuery } from "@apollo/client";
import { Button, Card, Form, Input, Select } from "antd";
import { useState } from "react";
import getAddressText from "src/common/functions/getAddressText";
import {
  GetBusinessUnitDetailDocument,
  GetBusinessUnitDetailQuery,
  GetBusinessUnitDetailQueryVariables,
  useGetStatesQuery,
  useUpdateBuDetailMutation,
  useUpdateBuLogoMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import AddressFormItems, {
  AddressFormItemsName,
} from "../../../../components/AddressFormItems";
import ProfileAvatar from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfileAvatar";
import LogoExplainerText from "../../../../components/LogoExplainerText";
import { BusinessUnitProps } from "../../../../utils/withBusinessUnitId";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

const GCBusinessUnitDetail: React.FC<BusinessUnitProps> = ({
  businessUnitId,
}) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found");

  const { data, refetch } = useSuspenseQuery<
    GetBusinessUnitDetailQuery,
    GetBusinessUnitDetailQueryVariables
  >(GetBusinessUnitDetailDocument, {
    variables: {
      buId: businessUnitId,
      gcId: emp.general_contractor.id,
    },
    fetchPolicy: "network-only",
  });
  const { data: statesData } = useGetStatesQuery();
  const bu = data.gc_business_unit_by_pk;

  if (!bu) throw new Error("Business Unit not found");
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const gc = emp.general_contractor;
  const initialFields = {
    name: bu.name,
    division: bu.gc_division?.id,
    ...(bu.address
      ? {
          addressLine1: bu.address.line_1,
          city: bu.address.city,
          zip: bu.address.zip_code,
          stateCode: bu.address.state.code,
        }
      : {}),
  };
  console.log(bu);
  const buProjectIds = bu.projects.map((p) => p.id);
  const [updateBuDetail, { loading }] = useUpdateBuDetailMutation();
  const [updateBuLogo] = useUpdateBuLogoMutation();
  return (
    <div className={`flex flex-col gap-1 `}>
      {!editing && (
        <Card
          title={`
            Upload/Change {gc.hierarchy_business_unit_name} Logo
          `}
        >
          <div className={`w-20`}>
            <ProfileAvatar
              imageUrl={
                bu.logo_url ||
                bu.gc_division?.logo_url ||
                bu.general_contractor.logo_url ||
                undefined
              }
              onFileUpload={async (imageUrl) => {
                await updateBuLogo({
                  variables: { buId: bu.id, imageUrl },
                  optimisticResponse: {
                    update_gc_business_unit_by_pk: {
                      id: bu.id,
                      logo_url: imageUrl,
                    },
                  },
                });
              }}
              onFileRemove={async () => {
                await updateBuLogo({
                  variables: { buId: bu.id, imageUrl: null },
                  optimisticResponse: {
                    update_gc_business_unit_by_pk: {
                      id: bu.id,
                      logo_url: null,
                    },
                  },
                });
              }}
            />
          </div>
          <LogoExplainerText />
        </Card>
      )}

      <Card
        title={`
        ${gc.hierarchy_business_unit_name} Details
      `}
      >
        <Form
          form={form}
          initialValues={initialFields}
          onFinish={async (
            vals: AddressFormItemsName & {
              name: string;
              division?: string;
            },
          ) => {
            console.log(bu.gc_division?.id, vals.division, businessUnitId);
            const divChange = vals.division !== bu.gc_division?.id;
            const oldDivEmps = (
              divChange ? bu.gc_division?.gc_division_employees || [] : []
            ).map((g) => g.user_id);
            const newDivEmp = (
              divChange
                ? data.gc_division.find((d) => d.id === vals.division)
                    ?.gc_division_employees || []
                : []
            ).map((g) => g.user_id);
            const tt = await updateBuDetail({
              variables: {
                buId: businessUnitId,
                buSet: {
                  name: vals.name,
                  gc_division_id: vals.division || null,
                },
                projectWhere: { gc_business_unit_id: { _eq: businessUnitId } },
                projectSet: divChange
                  ? { gc_division_id: vals.division || null }
                  : {},
                gcOfficeWhere: { gc_business_unit_id: { _eq: businessUnitId } },
                gcOfficeSet: { gc_division_id: vals.division || null },
                addressId: bu.address.id,
                addressSet: {
                  state_code: vals.state_code,
                  zip_code: vals.zip,
                  city: vals.city,
                  line_1: vals.addressLine1,
                },
                deleteProjEmpWhere: {
                  direct_project_assign: { _eq: false },
                  project: { gc_business_unit_id: { _eq: businessUnitId } },
                  employee_id: { _in: oldDivEmps },
                },
                projEmpObjs: newDivEmp.flatMap((empId) =>
                  buProjectIds.map((p) => ({
                    project_id: p,
                    direct_project_assign: false,
                    employee_id: empId,
                    status: "Inactive",
                  })),
                ),
              },
            });
            console.log(tt);
            refetch();
            setEditing(false);
          }}
        >
          {editing ? (
            <>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Enter Name" }]}
              >
                <Input />
              </Form.Item>{" "}
              Address
              <AddressFormItems
                onStateChange={(v) => {}}
                states={statesData?.state || []}
              />
            </>
          ) : (
            <div>
              <div>Name: {bu.name}</div>
              <br />
              Address: {getAddressText(bu.address)}
            </div>
          )}
          <div
            className={
              gc.hierarchy_division_name?.trim() && editing ? "" : "hidden"
            }
          >
            <Form.Item
              name="division"
              label={`Change ${gc.hierarchy_division_name}`}
            >
              <Select
                allowClear
                showSearch
                filterOption={getNormalSelectOptionsFilter}
                options={data.gc_division.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
              />
            </Form.Item>
            <div className="-mt-1">
              (NOTE: Changing {gc.hierarchy_division_name} will remove access
              from current {gc.hierarchy_division_name} and grant access to
              employees under new {gc.hierarchy_division_name})
            </div>
          </div>
          {!editing && gc.hierarchy_division_name ? (
            <div className="mt-1.5">
              {gc.hierarchy_division_name}:{" "}
              {bu.gc_division?.name || `No ${gc.hierarchy_division_name}`}
            </div>
          ) : null}
          <br />
          {emp.is_corporate_admin && (
            <>
              <Button
                type={editing ? "default" : "primary"}
                loading={loading}
                onClick={() => {
                  if (editing) form.setFieldsValue(initialFields);
                  setEditing((i) => !i);
                }}
              >
                {editing ? "Cancel" : "Edit"}
              </Button>
              {editing && (
                <Button
                  loading={loading}
                  className="ml-2"
                  htmlType="submit"
                  type="primary"
                >
                  Save
                </Button>
              )}
            </>
          )}
        </Form>
      </Card>
    </div>
  );
};
export default GCBusinessUnitDetail;
