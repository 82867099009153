import { Modal, Form, Input, ColorPicker } from "antd";
import React, { useState } from "react";
import {
  useInsertProjectCalendarMutation,
  useUpdateCalendarMutation,
  Project_Calendar_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useParams } from "react-router-dom";
import updateTextTranslation from "src/common/functions/updateTextTranslation";

interface SitedeliveryAddOrEditCalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
  calendarData?: {
    id: string;
    name: string;
    nameId: string;
    color: string;
  };
  onInsertCalendar: (
    calendar: Project_Calendar_Insert_Input[],
  ) => Promise<void>;
}

type FormValues = {
  name: string;
  color: string;
};

const SitedeliveryAddOrEditCalendarModal: React.FC<
  SitedeliveryAddOrEditCalendarModalProps
> = ({
  isOpen,
  onClose,
  calendarData,
  onInsertCalendar,
}: SitedeliveryAddOrEditCalendarModalProps) => {
  const { projectId } = useParams();
  const authUser = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm<FormValues>();
  const [updateCalendar] = useUpdateCalendarMutation();
  const [insertCalendar] = useInsertProjectCalendarMutation();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();

      if (calendarData) {
        if (values.name !== calendarData.name) {
          await updateTextTranslation(calendarData.nameId, values.name);
        }

        if (values.color !== calendarData.color) {
          await updateCalendar({
            variables: {
              where: { id: { _eq: calendarData.id } },
              _set: {
                color_hex: values.color,
              },
            },
            optimisticResponse: {
              update_calendar: {
                returning: [
                  {
                    __typename: "calendar",
                    id: calendarData.id,
                    color_hex: values.color,
                  },
                ],
              },
            },
          });
        }
      } else {
        await onInsertCalendar([
          {
            project_id: projectId,
            created_by_user_id: authUser.uid,
            calendar: {
              data: {
                name: {
                  data: {
                    en: values.name,
                    original: values.name,
                  },
                },
                color_hex: values.color,
                created_by_user_id: authUser.uid,
              },
            },
          },
        ]);
      }

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={calendarData ? "Edit Calendar" : "Add New Calendar"}
      open={isOpen}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={calendarData ? "Update" : "Submit"}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical" initialValues={calendarData}>
        <Form.Item
          name="name"
          label="Calendar Name"
          rules={[
            { required: true, message: "Please enter the calendar name" },
          ]}
        >
          <Input placeholder="Enter calendar name" />
        </Form.Item>
        <Form.Item
          name="color"
          label="Select Color"
          rules={[{ required: true, message: "Please select a color" }]}
        >
          <ColorPicker
            onChangeComplete={(color) =>
              form.setFieldsValue({ color: color.toHexString() })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SitedeliveryAddOrEditCalendarModal;
