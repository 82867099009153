import React, { PropsWithChildren } from "react";

type AppNavigationLayoutContextType = {
  headerHeightPx: number;
  setHeaderHeightPx: React.Dispatch<React.SetStateAction<number>>;
};

export const AppNavigationLayoutContext =
  React.createContext<AppNavigationLayoutContextType | null>(null);

export const AppNavigationLayoutProvider: React.FC<PropsWithChildren> = (
  props,
) => {
  const [headerHeightPx, setHeaderHeightPx] = React.useState<number>(0);
  return (
    <AppNavigationLayoutContext.Provider
      value={{
        headerHeightPx,
        setHeaderHeightPx,
      }}
    >
      {props.children}
    </AppNavigationLayoutContext.Provider>
  );
};
