import React, { FC, useRef, useState } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { auth } from "src/common/functions/firebase";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import SdsTable, { SDS } from "./SdsTable";
import UploadSDSToSiteFormModal from "../../job-hazard-analysis/components/modals/UploadSdsToSiteformModal";
import { SdsTable_sds$data } from "src/common/types/generated/relay/SdsTable_sds.graphql";
import { Button, message, Popconfirm, Space } from "antd";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { SCProjectSDS_InsertSdsMutation } from "src/common/types/generated/relay/SCProjectSDS_InsertSdsMutation.graphql";
import type {
  safety_data_sheet_bool_exp,
  safety_data_sheet_insert_input,
} from "src/common/types/generated/relay/types";
import * as uuid from "uuid";
import UploadSdsModal from "./UploadSdsModal";
import AddSdsFromCompanyLibraryModal from "./AddSdsFromCompanyLibraryModal";
import { SCProjectSDS_UpdateSdsMutation } from "src/common/types/generated/relay/SCProjectSDS_UpdateSdsMutation.graphql";
import PdfViewer from "../../../../common/components/pdf/PdfViewer";
import CustomButton from "src/common/components/general/button/Button";
import dayjs from "dayjs";

interface SCProjectSdsProps {
  projectId: string;
  subcontractorId: string;
  viewOnly?: boolean;
}

const SCProjectSDS: FC<SCProjectSdsProps> = ({
  projectId,
  subcontractorId,
  viewOnly,
}) => {
  const [hintVisible, setHintVisible] = useState(true);
  const loggedInUserId = auth.currentUser?.uid;
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [addSdsFromCompanyLibraryModal, setAddSdsFromCompanyLibraryModal] =
    useState(false);
  const [openSiteFormUploadModal, setOpenSiteFormUploadModal] = useState(false);
  const [openSdsUploadToSiteformModal, setOpenSdsUploadToSiteformModal] =
    useState(false);
  const sdsTableRef = useRef<DataScrollTableRef>(null);
  const [tableWhere, setTableWhere] = useState<safety_data_sheet_bool_exp>({
    subcontractor_id: { _eq: subcontractorId },
    project_id: { _eq: projectId },
    deleted_at: { _is_null: true },
  });
  const [allSdsVisible, setAllSdsVisible] = useState(false);

  const [insertSds, loading] = useAsyncMutation<SCProjectSDS_InsertSdsMutation>(
    graphql`
      mutation SCProjectSDS_InsertSdsMutation(
        $object: safety_data_sheet_insert_input!
      ) {
        insert_safety_data_sheet_one(object: $object) {
          ...SdsFrag @relay(mask: false)
        }
      }
    `,
  );

  const [updateSds, updating] =
    useAsyncMutation<SCProjectSDS_UpdateSdsMutation>(
      graphql`
        mutation SCProjectSDS_UpdateSdsMutation(
          $_set: safety_data_sheet_set_input
          $where: safety_data_sheet_bool_exp!
        ) {
          update_safety_data_sheet(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `,
    );

  const onDelete = async (
    sds: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"],
  ) => {
    await updateSds({
      variables: {
        _set: { deleted_at: dayjs().toISOString() },
        where: { id: { _eq: sds.pk } },
      },
    })
      .then(async (d) => {
        sdsTableRef.current?.refetch();
        message.success("Sds Deleted");
      })
      .catch((e) => console.log(e));
  };
  const addToProjectOrCompany = async (
    sds: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"],
    type: "add-to-project" | "add-to-company",
  ) => {
    if (!sds) {
      return;
    }
    const sdsInsertInput: safety_data_sheet_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: type === "add-to-project" ? projectId : null,
      subcontractor_id: subcontractorId,
      company_name: sds.company_name,
      product_name: sds.product_name,
      url: sds.url,
      issue_date: sds.issue_date,
      reference_number: sds.issue_date,
      file_type: sds.file_type,
      id: uuid.v4(),
      company_safety_data_sheet_id: sds.company_safety_data_sheet_id
        ? sds.company_safety_data_sheet_id
        : sds.pk,
    };
    await insertSds({
      variables: {
        object: { ...sdsInsertInput },
      },
    });
    message.success(
      type === "add-to-company"
        ? "Added to Company Library"
        : "Added to Project Library",
    );
  };
  return loggedInUserId ? (
    <BasicWrapper scrollable>
      <UploadSdsModal
        visible={openSiteFormUploadModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onClose={() => {
          setOpenSiteFormUploadModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setOpenSiteFormUploadModal(false);
          sdsTableRef.current?.refetch();
          setHintVisible(true);
        }}
      />
      <UploadSDSToSiteFormModal
        visible={openSdsUploadToSiteformModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onClose={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
      />
      <PdfViewer
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <AddSdsFromCompanyLibraryModal
        projectId={projectId}
        visible={addSdsFromCompanyLibraryModal}
        closeModal={() => {
          setAddSdsFromCompanyLibraryModal(false);
          setHintVisible(false);
        }}
        subcontractorId={subcontractorId}
        refresh={() => {
          sdsTableRef.current?.refetch();
        }}
        title="Add SDS from Company"
        tableWhere={{
          deleted_at: { _is_null: true },
          subcontractor_id: { _eq: subcontractorId },
          project_id: { _is_null: true },
        }}
      />
      <SdsTable
        loadAll
        title={allSdsVisible ? "SDS Database1" : "Project SDS Library"}
        explainerText={
          allSdsVisible ? ` [Search and Add SDS to the Project]` : ""
        }
        ref={sdsTableRef}
        where={tableWhere}
        childSdsWhere={
          allSdsVisible
            ? {
                project_id: { _eq: projectId },
                subcontractor_id: { _eq: subcontractorId },
              }
            : {
                project_id: { _is_null: true },
                subcontractor_id: { _eq: subcontractorId },
              }
        }
        excludedKeys={
          allSdsVisible
            ? [
                "projectName",
                "addToProjects",
                "select",
                "subcontractorName",
                "addedBy",
                "addedDate",
                "delete",
              ]
            : ["projectName", "addToProjects", "select", "subcontractorName"]
        }
        onSdsRowClick={(sds) => {
          setPdfUrl(sds.url);
          setPdfModal(true);
        }}
        loading={loading || updating}
        topBarButtons={
          viewOnly
            ? [
                {
                  label: "Add from Company Library",
                  onClick: () => {
                    setAddSdsFromCompanyLibraryModal(true);
                    setHintVisible(false);
                  },
                },
              ]
            : [
                {
                  label: "+ Upload New",
                  secondary: true,
                  onClick: () => setHintVisible(true),
                  hint: hintVisible ? (
                    <div>
                      {" "}
                      <div
                        className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                        onClick={() => {
                          setOpenSdsUploadToSiteformModal(true);
                          setHintVisible(false);
                        }}
                      >
                        Easy Upload (not instant)
                      </div>
                      <div
                        className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                        onClick={() => {
                          setOpenSiteFormUploadModal(true);
                          setHintVisible(false);
                        }}
                      >
                        Manual Upload (instant)
                      </div>{" "}
                      <div
                        className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                        onClick={() => {
                          setAddSdsFromCompanyLibraryModal(true);
                          setHintVisible(false);
                        }}
                      >
                        Add from Company Library
                      </div>
                    </div>
                  ) : undefined,
                },
              ]
        }
        headerComponent={
          viewOnly ? undefined : (
            <div className="flex flex-row space-x-1">
              <CustomButton
                secondary
                large
                label={"Company SDS"}
                onClick={() =>
                  window.open(`${window.location.origin}/sub/sds`, "_blank")
                }
              />
              <CustomButton
                secondary
                large
                label={allSdsVisible ? "Project SDS" : "SDS Database"}
                onClick={() => {
                  allSdsVisible
                    ? setTableWhere({
                        subcontractor_id: { _eq: subcontractorId },
                        project_id: { _eq: projectId },
                        deleted_at: { _is_null: true },
                      })
                    : setTableWhere({
                        deleted_at: { _is_null: true },
                        company_safety_data_sheet_id: { _is_null: true },
                      });
                  allSdsVisible
                    ? setAllSdsVisible(false)
                    : setAllSdsVisible(true);
                }}
              />
            </div>
          )
        }
        extraColumns={
          viewOnly
            ? undefined
            : [
                {
                  title: "Action",
                  dataIndex: [""],
                  size: "ml",
                  key: "action",
                  render: (
                    _,
                    sds: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"],
                  ) => {
                    return (
                      <>
                        <Popconfirm
                          title="Are you sure?"
                          onConfirm={noPropagation(() => {
                            addToProjectOrCompany(
                              sds,
                              allSdsVisible
                                ? "add-to-project"
                                : "add-to-company",
                            );
                          })}
                          onCancel={noPropagation()}
                          okText="Yes"
                          cancelText="Cancel"
                        >
                          {allSdsVisible &&
                          sds.child_sds?.findIndex(
                            (sds) => sds.project_id === projectId,
                          ) !== -1 ? (
                            <span className="ml-1"> Already on project </span>
                          ) : (!allSdsVisible &&
                              sds.child_sds?.findIndex(
                                (childSds) =>
                                  childSds.subcontractor_id ===
                                  sds.subcontractor_id,
                              ) !== -1) ||
                            sds.parent_sds?.subcontractor_id ===
                              sds.subcontractor_id ? (
                            <span className="ml-1"> </span>
                          ) : (
                            <Button type="link" onClick={noPropagation()}>
                              {allSdsVisible
                                ? "Add to Project SDS Library"
                                : "Add to Company SDS Library"}
                            </Button>
                          )}
                        </Popconfirm>
                      </>
                    );
                  },
                  onCellClick: (record: any, event: any) => {
                    console.log(record);
                  },
                },
                {
                  title: "",
                  dataIndex: [""],
                  size: "md",
                  key: "delete",
                  render: (_: any, sds: SDS) => (
                    <>
                      <Space>
                        <Popconfirm
                          title="Are you sure?"
                          onConfirm={noPropagation(() => {
                            onDelete(sds);
                          })}
                          onCancel={noPropagation()}
                          okText="Yes"
                          cancelText="Cancel"
                        >
                          <Button danger type="link" onClick={noPropagation()}>
                            Delete
                          </Button>
                        </Popconfirm>
                      </Space>
                    </>
                  ),
                },
              ]
        }
      />
    </BasicWrapper>
  ) : (
    <div className="flex flex-col items-center justify-center mt-3">
      Authentication Failed
    </div>
  );
};
export default SCProjectSDS;
