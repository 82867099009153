import { Button, Card, Divider, Form, message, Space } from "antd";
import React, { FC } from "react";
import withEmployeeData from "src/common/components/EmployeeDataHOC";
import { graphql } from "babel-plugin-relay/macro";
import { VisitorSettings_UpdateWaiverText_Mutation } from "src/common/types/generated/relay/VisitorSettings_UpdateWaiverText_Mutation.graphql";
import { VisitorSettings_toggleSignWaiverSwitch_Mutation } from "src/common/types/generated/relay/VisitorSettings_toggleSignWaiverSwitch_Mutation.graphql";
import { useLazyLoadQuery } from "react-relay/hooks";

import { useGetVisitorQrCodePosterMutation } from "src/common/types/generated/apollo/graphQLTypes";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import PosterDownload from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/PosterDownload";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import JoditEditor from "jodit-react";
import { JoditConfig } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import SiteOrientationGcQrCode from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/qr/SiteOrientationGcQrCode";
import { GCProjectVisitorsQuery } from "src/common/types/generated/relay/GCProjectVisitorsQuery.graphql";

import useAuthUser from "src/common/hooks/useAuthUser";
import { gcProjectVisitorsQuery } from "./GCProjectVisitors";
import ProjectIdProps from "../../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../common/hoc/withProjectIdUrlParam";
import SwitchWithText from "../../../../../common/components/SwitchWithText";

const agcPosterBackgroundImageUrl =
  "https://storage.googleapis.com/siteform-3170b.appspot.com/StaticAssets/scan%20qr%20code%20(4).png";

// TODO add new item in table while editing
const VisitorSettings: FC<ProjectIdProps> = ({ projectId }) => {
  const authUser = useAuthUser();
  if (!projectId) throw new Error("ProjectId not found");
  const data = useLazyLoadQuery<GCProjectVisitorsQuery>(
    gcProjectVisitorsQuery,
    { projectId: projectId, empId: authUser.uid },
    { fetchPolicy: "store-or-network" },
  );
  const [updateWaiverText, waiverTextUpdating] =
    useAsyncMutation<VisitorSettings_UpdateWaiverText_Mutation>(graphql`
      mutation VisitorSettings_UpdateWaiverText_Mutation(
        $projectId: uuid!
        $waiverText: String!
      ) {
        update_project(
          where: { id: { _eq: $projectId } }
          _set: { visitor_waiver_text: $waiverText }
        ) {
          affected_rows
        }
      }
    `);
  // TODO by Shubham: these toggles/switcher should not need loading, we should use optimistic response here
  const [toggleSignWaiverEnabled, signWaiverToggleLoading] =
    useAsyncMutation<VisitorSettings_toggleSignWaiverSwitch_Mutation>(
      graphql`
        mutation VisitorSettings_toggleSignWaiverSwitch_Mutation(
          $projectId: uuid!
          $_set: project_set_input!
        ) {
          update_project(where: { id: { _eq: $projectId } }, _set: $_set) {
            returning {
              id
              visitor_sign_waiver
            }
          }
        }
      `,
    );

  const project = data.project_connection.edges[0];
  const gc = data.general_contractor_connection.edges[0];
  const [form] = Form.useForm();
  const content =
    project.node.visitor_waiver_text && project.node.visitor_waiver_text.length
      ? project.node.visitor_waiver_text
      : gc.node.visitor_waiver_text;

  const [getVisitorQRCodePoster, { loading: orientationQRCodePosterLoading }] =
    useGetVisitorQrCodePosterMutation();

  return (
    <div className={`flex flex-col gap-1`}>
      <div className="flex ">
        <SiteOrientationGcQrCode
          title={<>Visitor QR Code</>}
          body={
            <div>
              <ul className="p-1 list-disc text-static-secondary">
                <li>
                  This QR code allows any visitors to your project to check-in
                  and “sign” your visitor log.
                </li>
                <li>
                  Visitors can review and sign your visitor policy, waiver, or
                  other documents.
                </li>
                <li>
                  This will add any visitor's estimated hours to your project
                  man-hour totals.
                </li>
                <li>
                  This can auto-populate visitors into your Daily Log in Procore
                  (Procore settings are listed at the bottom of the Project
                  Settings page).
                </li>
              </ul>
            </div>
          }
          qrCode={{
            destinationUrl: `${document.location.origin}/visitors/${projectId}/form`,
            alwaysVisible: true,
            downloadableFileName: "Visitors_QR_Code",
          }}
        />
        <PosterDownload
          imageUrl={agcPosterBackgroundImageUrl}
          header="Visitor QR Code Poster"
          description="Download your project specific poster"
          loading={orientationQRCodePosterLoading}
          onDownload={async () => {
            await getVisitorQRCodePoster({
              variables: { input: { projectId: projectId ?? "" } },
            }).then((data) => {
              if (data.data?.generateVisitorQrCodePoster) {
                downloadFromUrl(data.data?.generateVisitorQrCodePoster);
              }
            });
          }}
        />
      </div>
      <Card title={`Visitor Waiver`}>
        <Space
          size={0}
          className={`w-full`}
          split={<Divider />}
          direction={"vertical"}
        >
          <SwitchWithText
            checked={
              !!(
                project.node.visitor_sign_waiver ||
                (gc.node.visitor_waiver_text &&
                  gc.node.visitor_waiver_text.length)
              )
            }
            loading={signWaiverToggleLoading}
            text={"Require visitors to Review & Accept your Waiver Terms"}
            onChange={async (newValue) => {
              const checked =
                newValue ||
                (gc.node.visitor_waiver_text &&
                  gc.node.visitor_waiver_text.length) === 1;
              await toggleSignWaiverEnabled({
                variables: {
                  projectId: projectId ?? "",
                  _set: { visitor_sign_waiver: checked },
                },
              });
            }}
            explanation={
              <p>
                Do you require visitors to review and acknowledge a Visitor
                Waiver? The text below is your Company Standard Waiver. Only
                make EDITS if there are Site-Specific requirements.
              </p>
            }
          />

          {(project.node.visitor_sign_waiver ||
            (gc.node.visitor_waiver_text &&
              gc.node.visitor_waiver_text.length)) && (
            <div>
              <Form
                form={form}
                layout="vertical"
                initialValues={{ modifier: "public" }}
              >
                <Form.Item
                  name="waiverText"
                  label={
                    <div>
                      <strong>Visitor Waiver</strong> (pre-populated text is
                      Company Standard)
                    </div>
                  }
                  initialValue={content}
                >
                  <JoditEditor
                    value=""
                    config={{
                      ...JoditConfig,
                    }}
                  />
                </Form.Item>
              </Form>
              <Button
                loading={waiverTextUpdating}
                onClick={async () => {
                  try {
                    const value = await form.validateFields();
                    await updateWaiverText({
                      variables: {
                        projectId: projectId ?? "",
                        waiverText: value.waiverText,
                      },
                    }).then(() => {
                      message.success("Waiver text updated");
                    });
                  } catch (e) {
                    throw e;
                  }

                  // form
                  //   .validateFields()
                  //   .then(async (values) => {
                  //     updatingWaiverText(true);
                  //     await updateWaiverText({
                  //       variables: {
                  //         projectId: projectId ?? "",
                  //         waiverText: values.waiverText,
                  //       },
                  //     }).then((value) => {
                  //       updatingWaiverText(false);
                  //       notification.success({
                  //         message: "Done",
                  //         duration: 2,
                  //       });
                  //     });
                  //   })
                  //   .catch((info) => {
                  //     console.log("Validate Failed:", info);
                  //     notification.error({
                  //       message: "Failed",
                  //       duration: 2,
                  //     });
                  //   });
                }}
              >
                Save
              </Button>
            </div>
          )}
        </Space>
      </Card>
    </div>
  );
};

export default withProjectIdUrlParam(withEmployeeData(VisitorSettings));
