import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import CustomTabsBase from "./CustomTabsBase";
import CustomSuspense from "../../general/CustomSuspense";
import LoadingContent from "../../general/loading-fallback/LoadingContent";
import useGetActivePath from "../../../../utility-features/navigation/active-element/useGetActivePath";
import { TabsProps } from "antd";

// TODO (suggestion)
// Find a way to define basePath automatically
// Or...
// Pass a dynamic route as a basePath.
// E.g. `/projects/:projectId/tabs` instead of `/projects/1234/tabs`

type CustomRoutedTabType = {
  path: string; // relative path
  label: React.ReactNode;
  icon?: React.ReactNode;
};

export interface CustomTabsRoutedProps {
  tabs: CustomRoutedTabType[];
  basePath: string;
  tabBarExtraContent?: TabsProps["tabBarExtraContent"];
}

const CustomTabsRouted: React.FunctionComponent<CustomTabsRoutedProps> = (
  props,
) => {
  const navigate = useNavigate();

  const onTabChange = (key: string) => {
    navigate(key);
  };

  const selectedTabRoute = useGetActivePath(
    props.basePath,
    props.tabs.map((tab) => tab.path),
  );

  return (
    <CustomTabsBase
      onChange={onTabChange}
      activeKey={selectedTabRoute || undefined}
      tabBarExtraContent={props.tabBarExtraContent}
      items={props.tabs.map((tab) => ({
        key: tab.path,
        label: tab.label,
        children: (
          <CustomSuspense fallback={<LoadingContent />}>
            <Outlet />
          </CustomSuspense>
        ),
        icon: tab.icon,
      }))}
    />
  );
};

export default CustomTabsRouted;
