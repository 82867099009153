import dayjs from "dayjs";
import React, { FC, useEffect } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  Order_By,
  useGetSiteBoardPermitQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import SiteBoardPermitsComponent, {
  SiteBoardPermitType,
} from "./components/SiteBoardPermitsUI";
import { SiteBoardPermitProps } from "./components/SiteBoardPermit";
import { getTypeConfinedSpaceTitle } from "src/domain-features/sitesafety/components/ProjectReportsPermitsDetail";
import getNotPermissibleReadingsCount from "src/domain-features/sitesafety/entry-routes/permits/components/utils/getNotPermissibleReadingsCount";

interface SiteBoardPermitsProps {
  projectId: string;
}

const SiteBoardPermitsView: FC<SiteBoardPermitsProps> = (props) => {
  const projectId = props.projectId;
  const { data, loading, error, refetch } = useGetSiteBoardPermitQuery({
    variables: {
      projectId,
      where: {
        project_id: { _eq: projectId },
        current_status: { _nin: ["closed", "pending"] },
        is_historical: { _eq: false },
      },
      order_by: {
        updated_at: Order_By.Desc,
      },
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  if (loading || !data)
    return (
      <div
        className="flex items-center justify-center w-full h-full"
        style={{ backgroundColor: "black" }}
      >
        <h1 className="text-2" color="white">
          {loading ? "" : "The project wasn't found"}
        </h1>
      </div>
    );

  const project = data.project_by_pk;

  const permits = data.permit.map((p): SiteBoardPermitType => {
    // TODO - Instead of passing the permit with type of "confined space" by default, we should filter off that permits that have no status.

    const permit: SiteBoardPermitType = {
      id: p.id,
      status: p.current_status,
      type: "confined space",
      time: dayjs(p.updated_at).toDate(), //For interior penetration, dig excavation and ladder it'll be changed to work start at below
    };
    let workDuration = null;
    permit.workerName = p.applicant_project_worker?.worker.user.name;
    permit.workerRole = p.applicant_project_worker?.worker_role;
    permit.location = p.work_location.original;
    permit.description = p.work_description.original;
    permit.companyName = p.applicant_project_worker?.worker.subcontractor?.name;
    if (p.type === "confined_space") {
      permit.type = "confined space";
      permit.subConfinedType = getTypeConfinedSpaceTitle(
        p.confined_space_permit?.type,
      );
      workDuration = p.confined_space_permit?.work_duration;
      const readings =
        p.confined_space_permit?.confined_space_permit_readings || [];
      const showStopWork = !!readings.find(
        (r) =>
          new Date(r.created_at).getTime() >
            new Date(p.permit_histories[0]?.created_at).getTime() &&
          getNotPermissibleReadingsCount(r) > 0,
      );

      if (showStopWork) permit.status = "stop work";
    } else if (p.type === "dig") {
      permit.type = "dig";
      workDuration = p.dig_permit?.work_duration;
    } else if (p.type === "hot_work") {
      permit.type = project?.general_contractor
        .hot_work_permit_name as SiteBoardPermitProps["type"];
      workDuration = p.hot_work_permit?.work_duration;
    } else if (p.type === "work_above") {
      permit.type = "work above";
      workDuration = p.work_above_permit?.work_duration;
      permit.workAboveElevationType = p.work_above_permit?.work_above_floor
        ? p.work_above_permit?.work_above_floor.work_above_elevation
            .work_above_elevation_type.name.en
        : "";
    } else if (p.type === "ladder") {
      permit.type = p.ladder_permit?.is_access_ladder
        ? "access ladder"
        : "working ladder";
      permit.time =
        p.ladder_permit &&
        (!project?.project_setting ||
          project.project_setting.require_ladder_inspection_daily)
          ? dayjs(p.ladder_permit.last_inspection_at).toDate()
          : p.work_start_at
          ? dayjs(p.work_start_at).toDate()
          : permit.time;
    } else if (p.type === "historical_access") {
      permit.type = "historical access";
    } else if (p.type === "interior_penetration") {
      permit.type = "interior penetration";
      permit.time = p.work_start_at
        ? dayjs(p.work_start_at).toDate()
        : permit.time;
    } else if (p.type === "dig_excavation") {
      permit.type = "dig excavation";
      permit.time = p.work_start_at
        ? dayjs(p.work_start_at).toDate()
        : permit.time;
    } else if (p.type === "steel_erection") {
      permit.type = "steel erection";
      workDuration = p.steel_erection_permit?.work_duration || undefined;
    } else if (p.type === "out_of_basket") {
      permit.type = "out of basket";
    } else if (p.type === "loto") {
      permit.type = "loto";
      if (p.loto_permit) {
        permit.location =
          p.work_location.original +
          (p.loto_permit.loto_permit_lock_details.length > 0
            ? ` (${p.loto_permit.loto_permit_lock_details.length})`
            : "");
        permit.description = undefined;
      }
    } else if (p.type === "ceiling_access") {
      permit.type = "ceiling access";
      if (
        p.current_status === "submitted" &&
        p.ceiling_access_permit?.closing_review_status === "in_review"
      ) {
        permit.status = "review required";
      }
    }

    permit.hoursDuration = workDuration
      ? parseInt(workDuration.split(":")[0], 10)
      : undefined;
    return permit;
  });

  return (
    <SiteBoardPermitsComponent
      data={permits.sort((p1, p2) => p2.time.getTime() - p1.time.getTime())}
    />
  );
};

export default withCustomSuspense(SiteBoardPermitsView);
