import React, { FC, useState } from "react";
import EditUsersForNotificationModal from "src/common/components/dialogs/EditUsersForNotificationModal";
import {
  useDeleteingUserToBeNotifiedForProjectMutation,
  useInsertingUserToBeNotifiedForProjectMutation,
  useUsersToBeNotifiedQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import compareTwoLists from "./ComparingTwoLists";
import { Button, Card } from "antd";
import withCustomSuspense from "./general/withCustomSuspense";

interface PropsInt {
  projectId: string;
}

const JHAEditNotifications: FC<PropsInt> = ({ projectId }) => {
  const [editUsersOpenModal, setEditUsersOpenModal] = useState(false);
  const [
    deleteUsersToBeNotified,
    { data: usersAfterDeletion, loading: deletingUsersToBeNotified },
  ] = useDeleteingUserToBeNotifiedForProjectMutation();
  const [
    insertUsersToBeNotified,
    { data: usersAfterInsertion, loading: insertingUsersToBeNotified },
  ] = useInsertingUserToBeNotifiedForProjectMutation();
  let x = 1;
  // console.log(projectGcUsers.data?.project[0]);

  const { refetch, data, loading } = useUsersToBeNotifiedQuery({
    variables: {
      where: { project_id: { _eq: projectId }, user_id: { _is_null: false } },
    },
  });
  const usersToBeNotified = data?.users_notified_for_task_edits || [];

  return (
    <Card title={`JHA Creations & Edits Notifications`}>
      <p style={{ marginBottom: 9, fontWeight: 400 }}>
        Team Members to be emailed when a JHA is Added or Edited by a Foreman or
        Sub Admin
      </p>
      <div className="-mx-0.25">
        {usersToBeNotified.map((cur, i) => (
          <span
            className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
            key={cur.user_id || i}
          >
            {cur.user_to_be_notified?.name}
          </span>
        ))}
      </div>

      <div className="flex flex-row justify-start">
        <Button
          onClick={() => setEditUsersOpenModal(true)}
          loading={
            deletingUsersToBeNotified || insertingUsersToBeNotified || loading
          }
          type="primary"
        >
          Select Team Member(s)
        </Button>
      </div>
      <EditUsersForNotificationModal
        selectedUserIds={usersToBeNotified.map((c) => c.user_id!)} //user Id present as we are only calling those with user_id
        projectId={projectId}
        visible={editUsersOpenModal}
        onCancel={() => setEditUsersOpenModal(false)}
        onDoneEditing={async (values) => {
          setEditUsersOpenModal(false);
          const [toBeInsertedUserIds, toBeDeletedUserIds] = compareTwoLists(
            values.usersToBeNotified,
            usersToBeNotified.map((c) => c.user_id!),
          );
          await deleteUsersToBeNotified({
            awaitRefetchQueries: true,
            variables: {
              where: {
                project_id: { _eq: projectId },
                user_id: { _in: toBeDeletedUserIds },
              },
            },
          });

          await insertUsersToBeNotified({
            awaitRefetchQueries: true,
            variables: {
              objects: toBeInsertedUserIds.map((userId) => ({
                project_id: projectId,
                user_id: userId,
              })),
            },
          });
          await refetch();
        }}
      />
    </Card>
  );
};
export default withCustomSuspense(JHAEditNotifications);
