import React, { useContext, useState } from "react";
import VisitorOrientationOrienteeContext from "src/domain-features/sitesafety/entry-routes/visitor/utils/visitorOrientationOrienteeContext";

import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import validateEmail from "src/common/functions/validateEmail";
import onEnterDown from "src/domain-features/siteorientation/utils/onEnterDown";
import { Input } from "antd";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";
import useSiteVisitor from "../../utils/siteVisitorHook";

export interface VisitorOrientationStepsEmailProps
  extends SiteOrientationStepProps {
  gcId: string;
  onEnterEmail: (email: string) => Promise<void>;
}

const VisitorOrientationStepsEmail: React.FC<
  VisitorOrientationStepsEmailProps
> = (props) => {
  const visitorContext = useSiteVisitor();

  const [loading, setLoading] = useState(false);
  const langStrings = useCurrentLangStrings();

  const [email, setEmail] = useState<string>(
    visitorContext.visitor?.email || localStorage.getItem("visitorEmail") || "",
  );

  const onEnter = async () => {
    if (!emailIsValid) {
      return;
    }
    console.log(email);
    visitorContext.updateVisitor((v) => (v ? { ...v, email } : { email }));
    setLoading(true);
    await props.onEnterEmail(email);
    setLoading(false);
    props.onNext();
  };
  const emailIsValid = validateEmail(email);

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      loading={loading}
      nextButtonDisabled={!emailIsValid}
    >
      <SiteFeatureStepsInputLayout>
        <div className={`flex flex-col gap-0.5`}>
          <p className={`text-1.5`}>
            Enter your email, we’ll check if you have been here before
          </p>
          <p className="text-static-secondary">
            {langStrings.strings.SiteFormIncIsCommitedToYourPrivacy}
          </p>
          <Input
            type={`email`}
            disabled={loading}
            onKeyDown={(e) => onEnterDown(e, onEnter)}
            placeholder="name@example.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            enterKeyHint={"enter"}
          />
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default VisitorOrientationStepsEmail;
