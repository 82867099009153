import {
  Button,
  message,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import dayjs from "dayjs";
import capitalize from "src/common/functions/capitalize";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useGcOrientationDataQuery } from "src/common/types/generated/relay/useGcOrientationDataQuery.graphql";
import {
  OrientationModulesTable_UpdateProjectOrientationMutation,
  project_orientation_set_input,
} from "src/common/types/generated/relay/OrientationModulesTable_UpdateProjectOrientationMutation.graphql";
import {
  OrientationModulesTable_UpsertProjectOrientationMutation,
  OrientationModulesTable_UpsertProjectOrientationMutation$data,
} from "src/common/types/generated/relay/OrientationModulesTable_UpsertProjectOrientationMutation.graphql";
import GetFullID from "src/common/functions/GetFullId";
import * as uuid from "uuid";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { PlusOutlined } from "@ant-design/icons";

type Orientation =
  useGcOrientationDataQuery["response"]["orientation_connection"]["edges"][0]["node"];
type ColumnKeys =
  | "name"
  | "inperson"
  | "registration"
  | "require"
  | "tv"
  | "type"
  | "created_by"
  | "created_at";

interface OrientationModulesTableProps {
  onAddOrientation?: () => void;
  dataSource: Array<Orientation>;
  projectId?: string;
  hasUniversal?: boolean;
  isHigherLevelLogin: boolean;
  makeAllSettingDisabled?: boolean;
  hasInPerson?: boolean;
  excludeColumns?: ColumnKeys[];
  onRow: (item: { pk: string }) => void;
}

const OrientationModulesTable: FC<OrientationModulesTableProps> = ({
  ...props
}) => {
  const [upsertProjectOrientation] =
    useAsyncMutation<OrientationModulesTable_UpsertProjectOrientationMutation>(graphql`
      mutation OrientationModulesTable_UpsertProjectOrientationMutation(
        $object: project_orientation_insert_input!
      ) {
        insert_project_orientation_one(
          object: $object
          on_conflict: {
            constraint: project_orientation_project_id_orientation_id_key
            update_columns: [
              play_during_in_person
              play_during_registration
              required_by_all_workers
              hide_but_give_credit
            ]
          }
        ) {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          orientation_id
          play_during_in_person
          play_during_registration
          required_by_all_workers
          hide_but_give_credit
        }
      }
    `);
  const upsertProjectOrientationUpdater = (
    store: RecordSourceSelectorProxy<OrientationModulesTable_UpsertProjectOrientationMutation$data>,
    orientationDataId: string,
  ) => {
    const upsertObj = store.getRootField("insert_project_orientation_one");
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectOrientationsQuery_orientation_connection",
    );

    if (conn) {
      const edge = (conn.getLinkedRecords("edges") || []).find(
        (edge) =>
          edge.getLinkedRecord("node")?.getDataID() === orientationDataId,
      );
      if (edge) {
        const node = edge.getLinkedRecord("node");
        if (node) {
          const newList = (
            node.getLinkedRecords("project_orientations", {
              where: {
                project: {
                  linked_orientation_projects: { id: { _eq: props.projectId } },
                },
              },
              order_by: { created_at: Order_By.Asc },
            }) || []
          ).filter((s) => s.getDataID() !== upsertObj.getDataID());

          newList.push(upsertObj);
          node.setLinkedRecords(newList, "project_orientations", {
            where: {
              project: {
                linked_orientation_projects: { id: { _eq: props.projectId } },
              },
            },
            order_by: { created_at: Order_By.Asc },
          });
        }
      }
    }
  };

  const [updateProjectOrientation] =
    useAsyncMutation<OrientationModulesTable_UpdateProjectOrientationMutation>(graphql`
      mutation OrientationModulesTable_UpdateProjectOrientationMutation(
        $where: project_orientation_bool_exp!
        $_set: project_orientation_set_input!
      ) {
        update_project_orientation(where: $where, _set: $_set) {
          returning {
            id
            play_during_in_person
            play_during_registration
            required_by_all_workers
            hide_but_give_credit
          }
        }
      }
    `);

  const updateOrientationSetting = async (
    changes: project_orientation_set_input,
    o: Orientation,
  ) => {
    const id = uuid.v4();
    const projectOrientation =
      o.type === "universal"
        ? o.project_orientations[0] ?? {
            id,
            pk: id,
            play_during_in_person: false,
            play_during_registration: false,
            required_by_all_workers: false,
            hide_but_give_credit: false,
          }
        : o.project_orientations[0];

    const returnObj = projectOrientation
      ? {
          id: GetFullID("project_orientation", projectOrientation.pk),
          play_during_in_person: projectOrientation.play_during_in_person,
          play_during_registration: projectOrientation.play_during_registration,
          required_by_all_workers: projectOrientation.required_by_all_workers,
          hide_but_give_credit: projectOrientation.hide_but_give_credit,
          ...changes,
        }
      : {
          play_during_in_person: false,
          play_during_registration: true,
          required_by_all_workers: true,
          hide_but_give_credit: false,
          ...changes,
        };
    try {
      if (o.type === "universal" && !props.projectId) {
        //case when we have AGC User and he is changing things here.
        await updateProjectOrientation({
          variables: {
            where: {
              orientation_id: { _eq: o.pk },
              project_id: { _is_null: true },
            },
            _set: changes,
          },
          optimisticResponse: {
            update_project_orientation: {
              returning: [returnObj],
            },
          },
        });
      } else {
        await upsertProjectOrientation({
          variables: {
            object: {
              ...returnObj,
              id,
              project_id: props.projectId,
              orientation_id: o.pk,
            },
          },
          optimisticResponse: {
            insert_project_orientation_one: {
              project_id: props.projectId,
              orientation_id: o.pk,
              ...returnObj,
            },
          },
          optimisticUpdater: (store) => {
            upsertProjectOrientationUpdater(store, o.id);
          },
          updater: (store) => {
            upsertProjectOrientationUpdater(store, o.id);
          },
        });
      }
      Object.entries(changes).forEach((e) => {
        e[1] ? message.success("On") : message.warning("Off");
      });
    } catch (e) {
      const msg = e instanceof Error ? e.message : JSON.stringify(e);
      console.log("Settings change error:", e);
      message.error("Setting change error: " + msg);
    }
  };
  const dataSource = props.dataSource.filter(
    (o) => o.type !== "universal" || props.hasUniversal,
  );
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: ["name"],
    },
    {
      title: "SiteForm will play during in person",
      width: props.hasInPerson ? "9%" : "12%",
      key: "inperson",
      dataIndex: ["project_orientations"],
      render: (v: boolean, o: Orientation) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            disabled={props.makeAllSettingDisabled}
            checked={
              o.project_orientations[0]
                ? o.project_orientations[0].play_during_in_person
                : false
            }
            onChange={async (e) =>
              await updateOrientationSetting({ play_during_in_person: e }, o)
            }
          />
        </div>
      ),
    },
    {
      title: "SiteForm will play during registration",
      width: props.hasInPerson ? "9%" : "12%",
      key: "registration",
      dataIndex: ["project_orientations"],
      render: (v: boolean, o: Orientation) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            disabled={props.makeAllSettingDisabled}
            checked={
              o.project_orientations[0]
                ? o.project_orientations[0].play_during_registration
                : false
            }
            onChange={async (e) =>
              await updateOrientationSetting({ play_during_registration: e }, o)
            }
          />
        </div>
      ),
    },
    {
      title: props.projectId ? "Required by  all workers" : "Activated",
      width: props.hasInPerson ? "8%" : "10%",
      key: "require",
      dataIndex: ["project_orientations"],
      render: (v: boolean, o: Orientation) =>
        o.type === "universal" && props.projectId ? (
          <>Yes</>
        ) : !props.isHigherLevelLogin ? (
          o.project_orientations[0]?.required_by_all_workers ? (
            "Yes"
          ) : (
            "No"
          )
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            <Switch
              disabled={props.makeAllSettingDisabled}
              checked={
                o.project_orientations[0]
                  ? o.project_orientations[0].required_by_all_workers
                  : false
              }
              onChange={async (e) =>
                await updateOrientationSetting(
                  { required_by_all_workers: e },
                  o,
                )
              }
            />
          </div>
        ),
    },
    ...(props.hasInPerson
      ? [
          {
            title: "I will show on TV",
            width: "8%",
            key: "tv",
            dataIndex: ["project_orientations"],
            render: (_: null, o: Orientation) => (
              <div onClick={(e) => e.stopPropagation()}>
                <Switch
                  disabled={props.makeAllSettingDisabled}
                  checked={
                    o.project_orientations[0]
                      ? o.project_orientations[0].hide_but_give_credit
                      : false
                  }
                  onChange={async (e) =>
                    await updateOrientationSetting(
                      { hide_but_give_credit: e },
                      o,
                    )
                  }
                />
              </div>
            ),
          },
        ]
      : []),
    {
      title: "Type",
      key: "type",
      width: "8%",
      dataIndex: ["type"],
      render: (value: string) =>
        value === "corporate" ? "Company" : capitalize(value),
    },
    {
      title: "Created On",
      key: "created_at",
      width: "15%",
      dataIndex: ["created_at"],
      //            .tz("America/New_York")
      render: (date: Date) =>
        dayjs(date).tz("America/New_York").format("MMMM Do YYYY"),
    },
    {
      title: "Created By",
      width: "15%",
      key: "created_by",
      dataIndex: ["user", "name"],
      render: (val: string, rec: Orientation) => {
        return (
          val + ", " + (rec.user?.employee?.general_contractor?.name ?? "")
        );
      },
    },
  ].filter((t) => {
    if (
      props.excludeColumns &&
      props.excludeColumns.find((key) => key === t.key)
    )
      return false;
    return true;
  });

  return (
    <Table
      bordered
      columns={columns}
      dataSource={dataSource}
      title={() => (
        <Space align={"center"}>
          <Typography.Title level={5}>Orientation Modules</Typography.Title>
          {!!props.onAddOrientation && (
            <Tooltip title={"Add New Orientation Module"}>
              <Button
                icon={<PlusOutlined />}
                onClick={props.onAddOrientation}
              />
            </Tooltip>
          )}
        </Space>
      )}
      onRow={(item: any) => ({
        onClick: () => {
          props.onRow(item);
        },
      })}
    />
  );
};

export default OrientationModulesTable;
