import GCAdminObservations from "../../components/observation/GCAdminObservations";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import AppPageLayout from "../../../../../../../common/components/layouts/main/content/AppPageLayout";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const GCCorporateOfficeObservations: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const navigate = useNavigate();
  const projWhere = {
    general_contractor_id: { _eq: employee.general_contractor.id },
  };
  return (
    <AppPageLayout
      pageTitle={"Observations"}
      pageActions={[
        {
          onClick: () => {
            navigate(`/gce/hierarchy/corporate-office/settings/observations`);
          },
          children: "Settings",
          icon: <SettingOutlined />,
        },
      ]}
    >
      <GCAdminObservations
        projWhere={projWhere}
        optionsQueryVars={{
          projWhere,
          gcBuWhere: employee.general_contractor.hierarchy_business_unit_name
            ? {
                general_contractor_id: {
                  _eq: employee.general_contractor.id,
                },
              }
            : { id: { _is_null: true } },
          gcDivisionWhere: employee.general_contractor.hierarchy_division_name
            ? {
                general_contractor_id: {
                  _eq: employee.general_contractor.id,
                },
              }
            : { id: { _is_null: true } },
          gcOfficeWhere: employee.general_contractor.hierarchy_office_name
            ? {
                general_contractor_id: {
                  _eq: employee.general_contractor.id,
                },
              }
            : { id: { _is_null: true } },
        }}
        organizationId={employee.general_contractor.id}
        organizationLevel="gc"
      />
    </AppPageLayout>
  );
};
export default withCorporateAdminWrapper(GCCorporateOfficeObservations);
