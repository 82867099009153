import {
  compareTimeEvents,
  parseTime,
} from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/timeEvents";

type BaseBlockOutNode = {
  weekday: number;
  start_time: string;
  end_time: string;
};

type BlockOutEvent<T extends BaseBlockOutNode> = {
  type: "block_out_start" | "block_out_end";
  time: number;
  block: T;
};

type SubBlockNode = {
  weekday: number;
  start_time: string;
  end_time: string;
};

type SubBlockEvent<T extends SubBlockNode> = {
  type: "sub_block_start" | "sub_block_end";
  time: number;
  block: T;
};

export type TimeInterval = {
  start: number;
  end: number;
};

export const groupBlockOutIntervalsByDay = <T extends BaseBlockOutNode>(
  blocks: T[],
) => {
  const blocks_per_day: Array<BlockOutEvent<T>[]> = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  for (const block of blocks) {
    blocks_per_day[block.weekday].push({
      type: "block_out_start",
      time: parseTime(block.start_time),
      block: block,
    });
    blocks_per_day[block.weekday].push({
      type: "block_out_end",
      time: parseTime(block.end_time),
      block: block,
    });
  }
  for (const day of blocks_per_day) day.sort(compareTimeEvents);
  return blocks_per_day;
};

export const groupSubBlockIntervalsByDay = <T extends SubBlockNode>(
  blocks: T[],
) => {
  const blocks_per_day: Array<SubBlockEvent<T>[]> = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  for (const block of blocks) {
    const startTime = parseTime(block.start_time);
    const endTime = parseTime(block.end_time);
    for (let day = 0; day < 7; day++) {
      if ((block.weekday & (1 << day)) === 0) continue;
      blocks_per_day[day].push({
        type: "sub_block_start",
        time: startTime,
        block: block,
      });
      blocks_per_day[day].push({
        type: "sub_block_end",
        time: endTime,
        block: block,
      });
    }
  }
  for (const day of blocks_per_day) day.sort(compareTimeEvents);
  return blocks_per_day;
};
