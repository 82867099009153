import React from "react";
import { useParams } from "react-router-dom";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import SubProjectMobilizationView from "./SubProjectMobilizationView";

export interface SubProjectMobilizationViewWrapperProps {}

const SubProjectMobilizationViewWrapper: React.FC<
  SubProjectMobilizationViewWrapperProps
> = (props) => {
  const subcontractorId = useSubcontractorId();
  const projectId = useParams().projectId;

  if (!projectId) {
    throw new Error("No projectId found");
  }

  return (
    <SubProjectMobilizationView
      projectId={projectId}
      subcontractorId={subcontractorId}
      showLoginText={false}
    />
  );
};

export default SubProjectMobilizationViewWrapper;
