import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { usePermanentOnsiteSettingInsertSubOnsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingInsertSubOnsiteMutation.graphql";
import { usePermanentOnsiteSettingSubOffsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingSubOffsiteMutation.graphql";
import { usePermanentOnsiteSettingInsertCrewOnsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingInsertCrewOnsiteMutation.graphql";
import { usePermanentOnsiteSettingUpdateCrewOnsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingUpdateCrewOnsiteMutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import {
  ConnectionHandler,
  RecordProxy,
  RecordSourceSelectorProxy,
} from "relay-runtime";
import GetFullID from "src/common/functions/GetFullId";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import GetFullId from "src/common/functions/GetFullId";

const updateCrewPermanentOnsiteMutation = graphql`
  mutation usePermanentOnsiteSettingUpdateCrewOnsiteMutation(
    $_set: crew_onsite_period_set_input!
    $where: crew_onsite_period_bool_exp!
  ) {
    update_crew_onsite_period(where: $where, _set: $_set) {
      returning {
        id
      }
    }
  }
`;
const insertCrewPermanentOnsiteMutation = graphql`
  mutation usePermanentOnsiteSettingInsertCrewOnsiteMutation(
    $object: crew_onsite_period_insert_input!
  ) {
    insert_crew_onsite_period_one(
      object: $object
      on_conflict: {
        constraint: crew_onsite_period_project_crew_id_start_date_key
        update_columns: [close_date]
      }
    ) {
      id
    }
  }
`;
const insertProjectSubcontractorPermanentOnsiteMutation = graphql`
  mutation usePermanentOnsiteSettingInsertSubOnsiteMutation(
    $object: project_subcontractor_onsite_period_insert_input!
  ) {
    insert_project_subcontractor_onsite_period_one(
      object: $object
      on_conflict: {
        constraint: project_subcontractor_onsite_period_project_id_subcontractor_id
        update_columns: [close_date]
      }
    ) {
      id
    }
  }
`;

const setSubOffsiteMutation = graphql`
  mutation usePermanentOnsiteSettingSubOffsiteMutation(
    $projectId: uuid!
    $subId: uuid!
    $closeDate: date!
  ) {
    update_project_subcontractor_onsite_period(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_id: { _eq: $subId }
        close_date: { _is_null: true }
      }
      _set: { close_date: $closeDate }
    ) {
      returning {
        id
      }
    }
    update_crew_onsite_period(
      where: {
        close_date: { _is_null: true }
        project_crew: {
          project_id: { _eq: $projectId }
          subcontractor_id: { _eq: $subId }
        }
      }
      _set: { close_date: $closeDate }
    ) {
      returning {
        project_crew_id
        id
      }
    }
  }
`;

const usePermanentOnsiteSetting = (projectId: string) => {
  const [insertProjectSubcontractorPermanentOnsite, loading1] =
    useAsyncMutation<usePermanentOnsiteSettingInsertSubOnsiteMutation>(
      insertProjectSubcontractorPermanentOnsiteMutation,
    );

  const [mutateSubToOffsite, loading2] =
    useAsyncMutation<usePermanentOnsiteSettingSubOffsiteMutation>(
      setSubOffsiteMutation,
    );
  const [insertCrewPermanentOnsite, loading3] =
    useAsyncMutation<usePermanentOnsiteSettingInsertCrewOnsiteMutation>(
      insertCrewPermanentOnsiteMutation,
    );

  const [updateCrewPermanentOnsite, loading4] =
    useAsyncMutation<usePermanentOnsiteSettingUpdateCrewOnsiteMutation>(
      updateCrewPermanentOnsiteMutation,
    );

  type CrewUpdaterType = {
    store: RecordSourceSelectorProxy;
    subId: string;
    newRecords?: Array<RecordProxy<{ readonly id: string }>>;
  } & ({ type: "for_all_sub_crews" } | { type: "crew"; crewId: string });

  const projCrewSetLinkedRecords = (
    projCrew: RecordProxy<{}>,
    newRecords?: CrewUpdaterType["newRecords"],
  ) =>
    projCrew.setLinkedRecords(newRecords || [], `crew_onsite_periods`, {
      where: { close_date: { _is_null: true } },
    });

  const crewOnOffSiteUpdater = ({
    store,
    subId,
    newRecords,
    ...args
  }: CrewUpdaterType) => {
    const setForProjSubCrews = (
      projSub?: RecordProxy<{}> | null | undefined,
    ) => {
      if (projSub) {
        const projCrews =
          projSub.getLinkedRecords("project_crews", {
            order_by: { name: "asc" },
          }) || [];
        if (args.type === "crew") {
          const projCrew = projCrews.find(
            (pc) => pc.getDataID() == GetFullID("project_crew", args.crewId),
          );
          if (projCrew) projCrewSetLinkedRecords(projCrew, newRecords);
        } else
          projCrews.forEach((projCrew) =>
            projCrewSetLinkedRecords(projCrew, newRecords),
          );
      }
    };
    const subTableConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCSubcontractorsQuery_project_subcontractor_connection",
    );
    if (subTableConn) {
      const edges = subTableConn.getLinkedRecords("edges");
      if (edges) {
        const edgeIndex = edges.findIndex(
          (edge) =>
            edge.getLinkedRecord("node")?.getValue("subcontractor_id") ===
            subId,
        );
        if (edges[edgeIndex]) {
          const node = edges[edgeIndex].getLinkedRecord("node");
          setForProjSubCrews(node);
        }
      }
    }
    const crewTableConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "CrewTableQuery_project_crew_connection",
    );
    if (crewTableConn) {
      const edges = crewTableConn.getLinkedRecords("edges");
      if (edges) {
        if (args.type === "crew") {
          const edgeIndex = edges.findIndex((edge) => {
            const node = edge.getLinkedRecord("node");

            return node?.getDataID() === GetFullId("project_crew", args.crewId);
          });
          if (edges[edgeIndex]) {
            const node = edges[edgeIndex].getLinkedRecord("node");
            if (node) projCrewSetLinkedRecords(node, newRecords);
          }
        } else {
          edges.forEach((edge) => {
            const node = edge.getLinkedRecord("node");
            const isSameSub = node?.getValue("subcontractor_id") === subId;
            if (isSameSub && node) projCrewSetLinkedRecords(node, newRecords);
          });
        }
      }
    }
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "useGetReportManagementDataQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];

      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find(
            (ps) => ps.getValue("subcontractor_id") === subId,
          );
          setForProjSubCrews(projSub);
        }
      }
    }
  };
  const subOnOffSiteUpdater = ({
    store,
    subId,
    newRecord,
  }: {
    store: RecordSourceSelectorProxy;
    subId: string;
    newRecord?: RecordProxy<{
      readonly id: string;
    }>;
  }) => {
    const crewTableConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "CrewTableQuery_project_crew_connection",
    );
    if (crewTableConn && newRecord) {
      const subCrewEdges = (
        crewTableConn.getLinkedRecords("edges") || []
      ).filter(
        (rec) =>
          rec.getLinkedRecord("node")?.getValue("subcontractor_id") === subId,
      );
      if (subCrewEdges) {
        subCrewEdges.forEach((rec) => {
          const node = rec.getLinkedRecord("node");
          if (node) {
            const projSub = node.getLinkedRecord("project_subcontractor");
            if (projSub)
              projSub.setLinkedRecords(
                [newRecord], //This case is when sub is OFF and one of crews is turned ON so we turn on sub also, which dictates to having only one newRecord
                "project_subcontractor_onsite_periods",
                { where: { close_date: { _is_null: true } } },
              );
          }
        });
      }
    }
    const subTableConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCSubcontractorsQuery_project_subcontractor_connection",
    );
    if (subTableConn) {
      const edges = subTableConn.getLinkedRecords("edges");
      if (edges) {
        const edgeIndex = edges.findIndex((edge) => {
          const node = edge.getLinkedRecord("node");
          return node?.getValue("subcontractor_id") === subId;
        });
        if (edges[edgeIndex]) {
          const node = edges[edgeIndex].getLinkedRecord("node");
          if (node) {
            node.setLinkedRecords(
              newRecord ? [newRecord] : [],
              "project_subcontractor_onsite_periods",
              { where: { close_date: { _is_null: true } } },
            );
          }
        }
      }
    }
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "useGetReportManagementDataQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];

      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find((ps) => {
            const toReturn = ps.getValue("subcontractor_id") === subId;
            return toReturn;
          });

          if (projSub) {
            projSub.setLinkedRecords(
              newRecord ? [newRecord] : [],
              `project_subcontractor_onsite_periods`,
              { where: { close_date: { _is_null: true } } },
            );
          }
        }
      }
    }
  };
  const setCrewOnSite = async (crewId: string, subId: string) => {
    try {
      await insertCrewPermanentOnsite({
        variables: {
          object: {
            project_crew_id: crewId,
            close_date: null,
            start_date: dayjs().format("YYYY-MM-DD"),
          },
        },
        updater: (store) => {
          const inserted = store.getRootField("insert_crew_onsite_period_one");
          crewOnOffSiteUpdater({
            store,
            crewId,
            newRecords: [inserted],
            subId,
            type: "crew",
          });
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setCrewOffSite = async (crewId: string, subId: string) => {
    await updateCrewPermanentOnsite({
      variables: {
        where: {
          close_date: { _is_null: true },
          project_crew_id: { _eq: crewId },
        },
        _set: { close_date: dayjs().format("YYYY-MM-DD") },
      },
      updater: (store) =>
        crewOnOffSiteUpdater({
          store,
          crewId,
          subId,
          type: "crew",
        }),
    });
  };
  const setSubOnSite = async (subId: string) => {
    await insertProjectSubcontractorPermanentOnsite({
      variables: {
        object: {
          subcontractor_id: subId,
          project_id: projectId,
          close_date: null,
          start_date: dayjs().format("YYYY-MM-DD"),
        },
      },
      updater: (store) => {
        const inserted = store.getRootField(
          "insert_project_subcontractor_onsite_period_one",
        );
        subOnOffSiteUpdater({ store, subId, newRecord: inserted });
      },
    });
  };
  const setSubOffSite = async (subId: string) => {
    await mutateSubToOffsite({
      variables: {
        closeDate: dayjs().format("YYYY-MM-DD"),
        projectId,
        subId,
      },
      updater: (store) => {
        subOnOffSiteUpdater({ store, subId });
        crewOnOffSiteUpdater({
          store,
          subId,
          type: "for_all_sub_crews",
        });
      },
    });
  };
  return {
    setCrewOffSite,
    setCrewOnSite,
    setSubOnSite,
    setSubOffSite,
    loading: loading1 || loading2 || loading3 || loading4,
  };
};
// setCrewOnOffSite: async ({
//   crewId,
//   subId,
//   turnOnOrOff,
// }: {
//   crewId: string;
//   subId: string;
//   turnOnOrOff: boolean;
// }) => {
//   if (turnOnOrOff) await setCrewOnSite(crewId, subId);
//   else setCrewOffSite(crewId, subId);
// },
// setSubOnOffSite: async ({
//   subId,
//   turnOnOrOff,
// }: {
//   subId: string;
//   turnOnOrOff: boolean;
// }) => {
//   if (turnOnOrOff) await setSubOnSite(subId);
//   else setSubOffSite(subId);
// },
export default usePermanentOnsiteSetting;
