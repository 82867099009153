import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceData } from "./types";
import InvoiceList from "./InvoiceList";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { IconCreditCard } from "@tabler/icons";
import { message } from "antd";
import { useStripeCreatePortalSessionMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { auth } from "src/common/functions/firebase";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import Button from "src/common/components/general/button/Button";
import { GCBillingInvoicesWorkerQuery } from "src/common/types/generated/relay/GCBillingInvoicesWorkerQuery.graphql";

interface GCBillingInvoicesProps {
  projectId: string;
}

const query = graphql`
  query GCBillingInvoicesWorkerQuery($userId: uuid!, $projectId: uuid!) {
    invoice_connection(
      where: {
        status: { _in: ["open", "paid"] }
        stripe_account: {
          _or: [
            {
              general_contractor_billing_settings: {
                general_contractor: { employees: { uid: { _eq: $userId } } }
              }
            }
            {
              project_billing_settings: {
                project_id: { _eq: $projectId }
                project: {
                  general_contractor: { employees: { uid: { _eq: $userId } } }
                }
              }
            }
          ]
        }
      }
      order_by: { period_start: desc }
      first: 12
    ) {
      edges {
        node {
          id
          due_date
          amount
          status
          created_at
          stripe_invoice_id
          period_start
          period_end
          paid
          number
          payment_url
          invoice_pdf
          lines {
            id
            description
            project_name
            quantity
            amount
            state_json
            price_type
            price_fixed
            unit_price_first
            unit_price_others
            date_from
            date_to
          }
        }
      }
    }
    general_contractor_connection(
      where: { employees: { uid: { _eq: $userId } } }
    ) {
      edges {
        node {
          billing_settings {
            payment_model
            gc_price_type
          }
          projects(where: { id: { _eq: $projectId } }) {
            billing_settings {
              use_project_level_price
              payment_model
              gc_price_type
            }
          }
        }
      }
    }
  }
`;

const GCBillingInvoices: React.FC<GCBillingInvoicesProps> = ({ projectId }) => {
  const navigate = useNavigate();

  const [createStripeSession, { loading }] =
    useStripeCreatePortalSessionMutation();
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error("User not authenticated");
  }

  const data = useLazyLoadQuery<GCBillingInvoicesWorkerQuery>(query, {
    userId,
    projectId,
  });

  const gc = data.general_contractor_connection.edges[0]?.node;
  if (!gc) {
    throw new Error("User is not gc employee, access denied");
  }

  const project = gc.projects[0];
  const gc_level_price = gc.billing_settings?.gc_price_type === "fixed";

  const paymentMethoddsClick = () => {
    createStripeSession({
      variables: {
        return_url: document.location.href,
        project_id: gc_level_price ? undefined : projectId,
      },
    })
      .then((res) => {
        const url = res.data?.stripeCreatePortalSession.url;
        if (url) document.location.href = url;
      })
      .catch((error) => {
        message.error(error.message);
      });
    //navigate("settings");
  };

  const invoices = useMemo<InvoiceData[]>(() => {
    const invoices = data.invoice_connection.edges.map((e) => e.node);

    return invoices.map((invoice) => ({
      id: invoice.id,
      date: dayjs(invoice.period_start),
      paid: invoice.paid,
      period_start: dayjs(invoice.period_start),
      period_end: dayjs(invoice.period_end),
      title: "Invoice",
      detail: "Service detail",
      number: invoice.number ?? "",
      amount: invoice.amount / 100,
      payment_url: invoice.payment_url,
      invoice_pdf: invoice.invoice_pdf,
      lines: invoice.lines.map((line) => {
        const extra_data = line.state_json ? JSON.parse(line.state_json) : null;
        return {
          date: line.date_from || null,
          name:
            line.price_type === "per_worker" && line.project_name
              ? line.project_name
              : line.description,
          description:
            line.price_type === "per_worker"
              ? `${line.quantity} user${line.quantity !== 1 ? "s" : ""}`
              : "",
          hint: Array.isArray(extra_data)
            ? (extra_data.map((v) => v.name) as string[])
            : [],
          amount: line.amount / 100,
        };
      }),
    }));
  }, [data]);

  return (
    <div className="flex flex-col w-48 gap-2">
      <div className={`flex flex-row items-center gap-1`}>
        <p className="flex-1 text-2">{`Invoices`}</p>
        <Button
          onClick={paymentMethoddsClick}
          label={`Payment methods`}
          icon={IconCreditCard}
        />
      </div>
      {!!invoices.length ? (
        <InvoiceList invoices={invoices} />
      ) : (
        <ErrorMessage message={`There're no invoices yet`} />
      )}
    </div>
  );
};

export default GCBillingInvoices;
