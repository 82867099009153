import { Space } from "antd";
import GCAdminHeaderFilterButton, {
  GCAdminHeaderFilterButtonProps,
} from "../../filter-inputs/GCAdminHeaderFilterButton";
import GCAdminIncidentsReportCreatorButton, {
  GCAdminIncidentsReportCreatorButtonProps,
} from "./GCAdminIncidentsReportCreatorButton";
import GCAdminIncidentsSettingsButton from "./GCAdminIncidentsSettingsButton";
import GCAdminHeaderFilterTags from "../../filter-inputs/GCAdminHeaderFilterTags";

interface GCAdminIncidentsHeaderProps {
  reportCreatorButtonProps: GCAdminIncidentsReportCreatorButtonProps;
  filters?: GCAdminHeaderFilterButtonProps["filters"];
  showSetting?: boolean;
}

const GCAdminIncidentsHeader: React.FC<GCAdminIncidentsHeaderProps> = (
  props,
) => {
  return (
    <div className={`flex flex-col gap-1`}>
      <div className={`flex flex-row gap-1`}>
        <Space>
          {props.filters && (
            <GCAdminHeaderFilterButton filters={props.filters} />
          )}
          <GCAdminIncidentsReportCreatorButton
            {...props.reportCreatorButtonProps}
          />
          {props.showSetting && <GCAdminIncidentsSettingsButton />}
        </Space>
      </div>
      {props.filters && <GCAdminHeaderFilterTags filters={props.filters} />}
    </div>
  );
};

export default GCAdminIncidentsHeader;
