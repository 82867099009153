import * as React from "react";
import GCWorkersTable, {
  Subcontractor,
} from "src/common/components/tables/GCWorkersTable";
import { useGetSubcontractorsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import { auth } from "src/common/functions/firebase";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "../../../../../common/components/layouts/BasicWrapper";

const GCEmployeeVerification: React.FunctionComponent = () => {
  const userId = auth.currentUser?.uid;

  if (!userId) {
    throw new Error("User not logged in");
  }

  const { data, loading, error } = useGetSubcontractorsQuery({
    variables: {
      where: {
        project_workers: {
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
          archived_at: { _is_null: true },
          project: {
            project_employees: {
              employee: {
                user: { id: { _eq: userId } },
                general_contractor: { employee_verification: { _eq: true } },
              },
            },
          },
        },
      },
    },
  });
  if (loading) {
    return <LoadingContent />;
  }
  if (!data) {
    throw Error(
      error?.message ?? "An Error occurred while fetching subcontractors",
    );
  }

  const subContractorFilterOptions = data.subcontractor.map(
    (subcontractor): Subcontractor => {
      return {
        id: subcontractor.id,
        name: subcontractor.name,
      };
    },
  );

  return (
    <BasicWrapper>
      <GCWorkersTable
        userId={userId}
        title="Employee Verification"
        subContractorFilterOptions={subContractorFilterOptions}
        where={{
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
          archived_at: { _is_null: true },
          project: {
            project_employees: {
              employee: {
                user: { id: { _eq: userId } },
                general_contractor: { employee_verification: { _eq: true } },
              },
            },
          },
        }}
      ></GCWorkersTable>
    </BasicWrapper>
  );
};

export default withCustomSuspense(GCEmployeeVerification);
