import React, { ComponentType, forwardRef } from "react";
import CustomSuspense, {
  CustomSuspenseProps,
} from "src/common/components/general/CustomSuspense";

// TODO:  find out how to type assertion in withCustomSuspense

const withCustomSuspense = <Props,>(
  Component: ComponentType<Props>,
  options?: CustomSuspenseProps,
) =>
  forwardRef<unknown, Props>((props, ref) => {
    return (
      <CustomSuspense {...options}>
        <Component {...(props as Props)} ref={ref} />
      </CustomSuspense>
    );
  });
export default withCustomSuspense;
