import { Breadcrumb, Skeleton } from "antd";
import { useMatches, useNavigate } from "react-router-dom";
import React from "react";
import withCustomSuspense from "../../../general/withCustomSuspense";

type CrumbType = { title: React.ReactNode | string; pathname: string };

const AppPageBreadcrumbs: React.FC = () => {
  const navigate = useNavigate();
  const matches = useMatches();

  const crumbs: CrumbType[] = [];

  matches.forEach((match) => {
    if (
      match.handle &&
      typeof match.handle === "object" &&
      "breadcrumb" in match.handle &&
      (React.isValidElement(match.handle.breadcrumb) ||
        typeof match.handle.breadcrumb === "string")
    ) {
      crumbs.push({
        pathname: match.pathname,
        title: match.handle.breadcrumb,
      });
    }
  });

  return (
    <div>
      <Breadcrumb
        items={crumbs.map((breadcrumb, index) => ({
          title: (
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate(breadcrumb.pathname);
              }}
              className={`inline`}
            >
              {breadcrumb.title}
            </div>
          ),
          href: breadcrumb.pathname,
        }))}
      />
    </div>
  );
};

export default withCustomSuspense(AppPageBreadcrumbs, {
  fallback: (
    <div className={`w-8`}>
      <Skeleton.Button active={true} size={"small"} block />
    </div>
  ),
});
