/**
 * @generated SignedSource<<834ffcb30a87bbc06d986d45ceb0b991>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_orientation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationInPersonManagerStartingView_MarkSelectedMutation$variables = {
  mainProjectId: string;
  nextHH?: number | null | undefined;
  projectIds: ReadonlyArray<string>;
  records: ReadonlyArray<user_orientation_insert_input>;
  workerIds?: ReadonlyArray<string> | null | undefined;
};
export type SiteOrientationInPersonManagerStartingView_MarkSelectedMutation$data = {
  readonly insert_user_orientation: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_project_by_pk: {
    readonly next_hard_hat_number: number | null | undefined;
    readonly pk: string;
  } | null | undefined;
  readonly update_project_worker: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_user_orientation: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SiteOrientationInPersonManagerStartingView_MarkSelectedMutation = {
  response: SiteOrientationInPersonManagerStartingView_MarkSelectedMutation$data;
  variables: SiteOrientationInPersonManagerStartingView_MarkSelectedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mainProjectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nextHH"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "records"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerIds"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "records"
    },
    {
      "kind": "Literal",
      "name": "on_conflict",
      "value": {
        "constraint": "user_orientation_user_id_project_id_key",
        "update_columns": [
          "selected"
        ]
      }
    }
  ],
  "concreteType": "user_orientation_mutation_response",
  "kind": "LinkedField",
  "name": "insert_user_orientation",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_in",
      "variableName": "projectIds"
    }
  ],
  "kind": "ObjectValue",
  "name": "project_id"
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "_set",
      "value": {
        "archived_at": null,
        "archived_by_uid": null,
        "deleted_at": null
      }
    },
    {
      "fields": [
        (v7/*: any*/),
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_in",
              "variableName": "workerIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "worker_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "project_worker_mutation_response",
  "kind": "LinkedField",
  "name": "update_project_worker",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "_set",
      "value": {
        "selected": false
      }
    },
    {
      "fields": [
        (v7/*: any*/),
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_nin",
              "variableName": "workerIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "user_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "user_orientation_mutation_response",
  "kind": "LinkedField",
  "name": "update_user_orientation",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v10 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "next_hard_hat_number",
        "variableName": "nextHH"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "mainProjectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "next_hard_hat_number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationInPersonManagerStartingView_MarkSelectedMutation",
    "selections": [
      (v6/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "update_project_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteOrientationInPersonManagerStartingView_MarkSelectedMutation",
    "selections": [
      (v6/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "update_project_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "643e20152ccec00ef08c2b45d91b68a6",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationInPersonManagerStartingView_MarkSelectedMutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationInPersonManagerStartingView_MarkSelectedMutation(\n  $projectIds: [uuid!]!\n  $mainProjectId: uuid!\n  $workerIds: [uuid!]\n  $records: [user_orientation_insert_input!]!\n  $nextHH: Int\n) {\n  insert_user_orientation(objects: $records, on_conflict: {constraint: user_orientation_user_id_project_id_key, update_columns: [selected]}) {\n    affected_rows\n  }\n  update_project_worker(where: {project_id: {_in: $projectIds}, worker_id: {_in: $workerIds}}, _set: {archived_at: null, archived_by_uid: null, deleted_at: null}) {\n    affected_rows\n  }\n  update_user_orientation(where: {project_id: {_in: $projectIds}, user_id: {_nin: $workerIds}}, _set: {selected: false}) {\n    affected_rows\n  }\n  update_project_by_pk(pk_columns: {id: $mainProjectId}, _set: {next_hard_hat_number: $nextHH}) {\n    pk: id\n    next_hard_hat_number\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc71ac60da6c608cd56dce73e38211bf";

export default node;
