import { Typography } from "antd";
import React from "react";

const GCChildUnitsExplainerText: React.FC = () => {
  return (
    <Typography>
      <Typography.Paragraph>
        <Typography.Text strong> Child Units</Typography.Text> represent
        different departments like offices, business units, and divisions within
        your organization.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Before adding <Typography.Text strong>Child Units</Typography.Text>, you
        must first define your organization's hierarchy on "Organization
        Hierarchy" page on "Corporate Office" level.
      </Typography.Paragraph>
    </Typography>
  );
};

export default GCChildUnitsExplainerText;
