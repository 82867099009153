/**
 * @generated SignedSource<<be206d67969a05940c0663c125f70b43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, project_worker_bool_exp, project_worker_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GCWorkerTableRefetchableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: ReadonlyArray<project_worker_order_by>;
  where: project_worker_bool_exp;
};
export type GCWorkerTableRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GCWorkersTable_project_worker">;
};
export type GCWorkerTableRefetchableQuery = {
  response: GCWorkerTableRefetchableQuery$data;
  variables: GCWorkerTableRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order_by"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCWorkerTableRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "GCWorkersTable_project_worker"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCWorkerTableRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hard_hat_number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "where",
                            "value": {
                              "user": {
                                "created_password": {
                                  "_eq": true
                                }
                              }
                            }
                          }
                        ],
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employees",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": "subcontractor_employees(where:{\"user\":{\"created_password\":{\"_eq\":true}}})"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "GCWorkersTable_project_worker_connection",
        "kind": "LinkedHandle",
        "name": "project_worker_connection"
      }
    ]
  },
  "params": {
    "cacheID": "951ee55a38ae63f4a5826928e0a2b04b",
    "id": null,
    "metadata": {},
    "name": "GCWorkerTableRefetchableQuery",
    "operationKind": "query",
    "text": "query GCWorkerTableRefetchableQuery(\n  $after: String\n  $first: Int!\n  $order_by: [project_worker_order_by!]!\n  $where: project_worker_bool_exp!\n) {\n  ...GCWorkersTable_project_worker_4g04uc\n}\n\nfragment GCWorkersTable_project_worker_4g04uc on query_root {\n  project_worker_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        hard_hat_number\n        created_at\n        worker {\n          uid\n          status\n          id\n        }\n        subcontractor {\n          id\n          name\n          subcontractor_employees(where: {user: {created_password: {_eq: true}}}) {\n            user {\n              email\n              id\n            }\n            id\n          }\n        }\n        project {\n          name\n          id\n        }\n        user {\n          name\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e4f2afee6c36e4ad075f4d71f1a9c2c";

export default node;
