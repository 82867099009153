import GCObsNotifications from "../../../component/observation/notify/GCObsNotifications";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../../../corporate-office/withCorporateAdminWrapper";

const GCCorporateObsNotifications: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const gc = employee.general_contractor;
  return (
    <GCObsNotifications
      gcId={gc.id}
      queryVariables={{
        obsNotifWhere: { gc_id: { _eq: gc.id } },
        userOptionsWhere: {
          email: { _is_null: false },
          employee: { general_contractor_id: { _eq: gc.id } },
        },
      }}
    />
  );
};
export default withCorporateAdminWrapper(GCCorporateObsNotifications);
