import clsx from "clsx";
import React from "react";

interface SiteDeliveryCalendarTimeStampDigitsProps {
  timeStr: string;
}

const SiteDeliveryCalendarTimeStampDigits: React.FC<
  SiteDeliveryCalendarTimeStampDigitsProps
> = (props) => {
  return (
    <div className={clsx("relative flex flex-row justify-start items-center")}>
      <span className="whitespace-nowrap text-static-secondary text-0.75">
        {props.timeStr}
      </span>
    </div>
  );
};

export default SiteDeliveryCalendarTimeStampDigits;
