/**
 * @generated SignedSource<<27fb7c21e179f4efd6b38a6980c94dcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { general_contractor_employee_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type AddGCProjectTeamModalQuery$variables = {
  gcEmployeeWhere: general_contractor_employee_bool_exp;
};
export type AddGCProjectTeamModalQuery$data = {
  readonly general_contractor_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly uid: string;
        readonly user: {
          readonly id: string;
          readonly name: string;
          readonly profile_picture: {
            readonly id: string;
            readonly url: string;
          } | null | undefined;
        };
      };
    }>;
  };
};
export type AddGCProjectTeamModalQuery = {
  response: AddGCProjectTeamModalQuery$data;
  variables: AddGCProjectTeamModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gcEmployeeWhere"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "image",
      "kind": "LinkedField",
      "name": "profile_picture",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": {
        "name": "asc"
      }
    }
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "gcEmployeeWhere"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddGCProjectTeamModalQuery",
    "selections": [
      {
        "alias": "general_contractor_employee_connection",
        "args": null,
        "concreteType": "general_contractor_employeeConnection",
        "kind": "LinkedField",
        "name": "__AddGCProjectTeamModal_general_contractor_employee_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddGCProjectTeamModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "general_contractor_employeeConnection",
        "kind": "LinkedField",
        "name": "general_contractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "AddGCProjectTeamModal_general_contractor_employee_connection",
        "kind": "LinkedHandle",
        "name": "general_contractor_employee_connection"
      }
    ]
  },
  "params": {
    "cacheID": "b8180bc96166070cf027778a835cc12c",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "general_contractor_employee_connection"
          ]
        }
      ]
    },
    "name": "AddGCProjectTeamModalQuery",
    "operationKind": "query",
    "text": "query AddGCProjectTeamModalQuery(\n  $gcEmployeeWhere: general_contractor_employee_bool_exp!\n) {\n  general_contractor_employee_connection(where: $gcEmployeeWhere, order_by: {user: {name: asc}}, first: 10000) {\n    edges {\n      node {\n        id\n        pk: id\n        uid\n        user {\n          id\n          name\n          profile_picture {\n            id\n            url\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f9cf0dc3de8f757ca9ed5b63a114709";

export default node;
