import React, { useState } from "react";
import { CalendarBaseType } from "../utilities/sitedeliveryTypes";
import { useParams } from "react-router-dom";
import { Card } from "antd";
import SitedeliveryAddOrEditCalendarModal from "../components/SitedeliveryAddOrEditCalendarModal";
import SitedeliveryAddCalendarFromOtherProjectModal from "../components/SitedeliveryAddCalendarFromOtherProjectModal";
import {
  IconEye,
  IconEyeOff,
  IconArchive,
  IconArchiveOff,
  IconInfoCircle,
} from "@tabler/icons";
import {
  Project_Calendar_Insert_Input,
  useUpdateProjectCalendarMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import Icon from "src/common/components/general/Icon";

export interface GCProjectCalendarSitedeliveryCalendarsProps {
  calendars: Array<
    CalendarBaseType & { isArchive: boolean } & { visible: boolean }
  >;
  toggleCalendarVisible: (
    calendar: CalendarBaseType,
    newValue: boolean,
  ) => void;
  gcId?: string;
  onInsertCalendar: (
    calendar: Project_Calendar_Insert_Input[],
  ) => Promise<void>;
}

const GCProjectCalendarSitedeliveryCalendars: React.FC<
  GCProjectCalendarSitedeliveryCalendarsProps
> = (props) => {
  const { projectId } = useParams();
  const [updateCalendar] = useUpdateProjectCalendarMutation();
  const [isAddNewCalendarModalOpen, setIsAddNewCalendarModalOpen] =
    useState(false);
  const [calendarData, setCalendarData] = useState<
    { id: string; nameId: string; name: string; color: string } | undefined
  >(undefined);
  const [
    isAddCalendarFromOtherProjectModalOpen,
    setIsAddCalendarFromOtherProjectModalOpen,
  ] = useState(false);
  const [showHelpText, setShowHelpText] = useState(false);
  const [showShowingCalendarsHelp, setShowShowingCalendarsHelp] =
    useState(false);
  const [showHiddenCalendarsHelp, setShowHiddenCalendarsHelp] = useState(false);
  const [showArchivedCalendarsHelp, setShowArchivedCalendarsHelp] =
    useState(false);

  return (
    <div>
      {props.gcId && (
        <SitedeliveryAddCalendarFromOtherProjectModal
          isOpen={isAddCalendarFromOtherProjectModalOpen}
          setIsOpen={setIsAddCalendarFromOtherProjectModalOpen}
          gcId={props.gcId}
        />
      )}
      <SitedeliveryAddOrEditCalendarModal
        isOpen={isAddNewCalendarModalOpen}
        onClose={() => {
          setIsAddNewCalendarModalOpen(false);
          setCalendarData(undefined);
        }}
        calendarData={calendarData}
        onInsertCalendar={props.onInsertCalendar}
        key={calendarData?.id}
      />
      <div className="mb-3">
        <div className="mb-1 text-1.2 text-static-primary flex items-center gap-0.5">
          Need to set up deliveries?
          <Icon
            icon={IconInfoCircle}
            color="static-secondary"
            size="small"
            onClick={() => setShowHelpText((prev) => !prev)}
          />
        </div>
        {showHelpText && (
          <Card className="mb-1">
            <div className="text-static-secondary text-1">
              Choose how to get started with your delivery locations. You can
              create a new calendar for locations like loading docks, gates, or
              elevators; or import a calendar from an existing project that is
              sharing locations and resources.
            </div>
          </Card>
        )}
        <Card className="flex justify-center items-center mb-1">
          <div
            className="text-static-secondary text-1 cursor-pointer"
            onClick={() => setIsAddNewCalendarModalOpen(true)}
          >
            + Create New Calendar
          </div>
        </Card>
        {props.gcId && (
          <Card className="flex justify-center items-center">
            <div
              className="text-static-secondary text-1 cursor-pointer"
              onClick={() => setIsAddCalendarFromOtherProjectModalOpen(true)}
            >
              + Import from Another Project
            </div>
          </Card>
        )}
      </div>

      <div className="mb-1 text-1.2 text-static-primary flex items-center gap-0.5">
        Showing Calendars
        <Icon
          icon={IconInfoCircle}
          color="static-secondary"
          size="small"
          onClick={() => setShowShowingCalendarsHelp((prev) => !prev)}
        />
      </div>
      {showShowingCalendarsHelp && (
        <Card className="mb-1">
          <div className="text-static-secondary text-1">
            These calendars and their deliveries are visible to you. All
            deliveries scheduled on these calendars will be shown. Hiding
            calendars does not affect any other users.
          </div>
        </Card>
      )}

      {props.calendars
        .filter((calendar) => calendar.visible && !calendar.isArchive)
        .map((calendar) => (
          <Card
            key={calendar.title}
            className="flex justify-between items-center cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setCalendarData({
                id: calendar.id,
                nameId: calendar.titleId,
                name: calendar.title,
                color: calendar.colorHex,
              });
              setIsAddNewCalendarModalOpen(true);
            }}
          >
            <div className="flex items-center w-full">
              <div
                className="w-1 h-1 rounded-full"
                style={{ backgroundColor: calendar.colorHex }}
              />
              <div className="font-accent min-w-12 max-w-12 text-static-primary ml-1">
                {calendar.title}
              </div>

              <div className="flex w-1 ml-auto gap-0.5">
                <Icon
                  icon={IconEyeOff}
                  color="static-secondary"
                  size="small"
                  hoverContent={{
                    content: "Hide",
                    enableOnClick: true,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.toggleCalendarVisible(calendar, false);
                  }}
                />
                <Icon
                  icon={IconArchive}
                  color="static-secondary"
                  size="small"
                  hoverContent={{
                    content: "Archive",
                    enableOnClick: true,
                  }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await updateCalendar({
                      variables: {
                        where: {
                          calendar_id: { _eq: calendar.id },
                          project_id: { _eq: projectId },
                        },
                        _set: { is_archive: true },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </Card>
        ))}

      <div className="mb-1 mt-2 text-1.2 text-static-primary flex items-center gap-0.5">
        Hidden Calendars
        <Icon
          icon={IconInfoCircle}
          color="static-secondary"
          size="small"
          onClick={() => setShowHiddenCalendarsHelp((prev) => !prev)}
        />
      </div>
      {showHiddenCalendarsHelp && (
        <Card className="mb-1">
          <div className="text-static-secondary text-1">
            Hidden calendars and their deliveries won't appear in your schedule,
            unless a delivery includes a calendar that is showing. Deliveries
            can still be scheduled on hidden calendars.
          </div>
        </Card>
      )}
      {props.calendars
        .filter((calendar) => !calendar.visible && !calendar.isArchive)
        .map((calendar) => (
          <Card key={calendar.id} className="flex justify-between items-center">
            <div className="flex items-center w-full">
              <div
                className="w-1 h-1 rounded-full"
                style={{ backgroundColor: calendar.colorHex }}
              />
              <div className="font-accent min-w-12 max-w-12 text-static-primary ml-1">
                {calendar.title}
              </div>

              <div className="flex w-1 ml-auto gap-0.5">
                <Icon
                  icon={IconEye}
                  color="static-secondary"
                  hoverContent={{
                    content: "Show",
                    enableOnClick: true,
                  }}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.toggleCalendarVisible(calendar, true);
                  }}
                />
                <Icon
                  icon={IconArchive}
                  color="static-secondary"
                  hoverContent={{
                    content: "Archive",
                    enableOnClick: true,
                  }}
                  size="small"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await updateCalendar({
                      variables: {
                        where: {
                          calendar_id: { _eq: calendar.id },
                          project_id: { _eq: projectId },
                        },
                        _set: { is_archive: true },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </Card>
        ))}

      <div className="mb-1 mt-2 text-1.2 text-static-primary flex items-center gap-0.5">
        Archived (No New Deliveries)
        <Icon
          icon={IconInfoCircle}
          color="static-secondary"
          size="small"
          onClick={() => setShowArchivedCalendarsHelp((prev) => !prev)}
        />
      </div>
      {showArchivedCalendarsHelp && (
        <Card className="mb-1">
          <div className="text-static-secondary text-1">
            Important: Archived calendars are locked - no new deliveries can be
            scheduled. Existing deliveries remain intact but the calendar cannot
            be used for future scheduling. Use this for permanently inactive
            calendars (e.g., decommissioned temp hoists).
          </div>
        </Card>
      )}
      {props.calendars
        .filter((calendar) => calendar.isArchive)
        .map((calendar) => (
          <Card key={calendar.id} className="flex justify-between items-center">
            <div className="flex items-center w-full">
              <div
                className="w-1 h-1 rounded-full"
                style={{ backgroundColor: calendar.colorHex }}
              />
              <div className="font-accent min-w-12 max-w-12 text-static-primary ml-1">
                {calendar.title}
              </div>

              <div className="flex w-1 ml-2 gap-0.5">
                <Icon
                  icon={IconArchiveOff}
                  color="static-secondary"
                  hoverContent={{
                    content: "Unarchive",
                    enableOnClick: true,
                  }}
                  size="small"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await updateCalendar({
                      variables: {
                        where: {
                          calendar_id: { _eq: calendar.id },
                          project_id: { _eq: projectId },
                        },
                        _set: { is_archive: false },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </Card>
        ))}
    </div>
  );
};

export default GCProjectCalendarSitedeliveryCalendars;
