import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { GetGcBusinessUnitsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import OfficeTable from "./OfficeTable";
import getGcOrganizationUnitPath from "../utils/getGcOrganizationUnitPath";

type BusinessUnitType = GetGcBusinessUnitsQuery["gc_business_unit"][number];

const BusinessUnitTable: React.FC<{
  businessUnits: Array<BusinessUnitType>;
  buTitle: string | null;
  officeTitle?: string | null;
  addButton?: React.ReactNode;
}> = ({ businessUnits, buTitle, officeTitle, addButton }) => {
  const expandables = businessUnits.filter((p) => p.gc_offices?.length);
  const navigate = useNavigate();
  return (
    <Table
      title={() => (
        <div className="text-1.25 flex items-center">
          {buTitle} ({businessUnits.length}){" "}
          <div className="text-0.9 ml-1">{addButton}</div>
        </div>
      )}
      // components={{ header: { row: () => null } }}
      onRow={(bu) => ({
        onClick: () => {
          navigate(getGcOrganizationUnitPath("business_unit", bu.id));
        },
      })}
      dataSource={businessUnits}
      rowKey={(o) => o.id}
      columns={[
        {
          title: "",
          dataIndex: ["id"],
          render: (_, r, i) => i + 1,
          width: "10%",
        },
        {
          title: "name",
          dataIndex: ["name"],
          width: "30%",
        },
        {
          title: "Projects",
          dataIndex: ["projects_aggregate", "aggregate", "count"],
          render: (v) => v || "",
        },
      ]}
      expandable={{
        expandIcon: () => "",
        rowExpandable: (bu) => !!bu.gc_offices?.length,
        expandedRowKeys: expandables.map((r) => r.id),
        expandedRowRender: (bu) => (
          <>
            {!!officeTitle && !!bu.gc_offices?.length && (
              <OfficeTable
                offices={bu.gc_offices || []}
                officeTitle={officeTitle}
              />
            )}
          </>
        ),
      }}
    />
  );
};
export default BusinessUnitTable;
