import SettingsPageLayout from "../../../../../../../common/components/layouts/settings-page/SettingsPageLayout";
import { getMenuItemsByRoutedMenu } from "../../../../../../../utility-features/navigation/routed-menu/routedMenu";
import gcOrgUnitSettingsRoutedMenu from "./gcOrgUnitSettingsRoutedMenu";
import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";

const GCOrgUnitSettings: React.FC<OrganizationUnitProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  const basePath = `/gce/hierarchy/organization-unit/${organizationUnitType}/${organizationUnitId}/settings`;
  return (
    <SettingsPageLayout
      basePath={basePath}
      menuItems={getMenuItemsByRoutedMenu(gcOrgUnitSettingsRoutedMenu)}
    />
  );
};

export default withOrganizationUnitWrapper(GCOrgUnitSettings);
