import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import { QrProjectSubcontractorQuery } from "src/common/types/generated/relay/QrProjectSubcontractorQuery.graphql";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import CardWrapper from "../../../components/CardWrapper";
import ProjectOverviewCard from "../../../components/ProjectOverviewCard";
import { auth } from "src/common/functions/firebase";

const query = graphql`
  query QrProjectSubcontractorQuery(
    $subcontractorId: uuid!
    $projectId: uuid!
  ) {
    project_subcontractor_connection(
      where: {
        subcontractor_id: { _eq: $subcontractorId }
        project_id: { _eq: $projectId }
      }
    ) {
      edges {
        node {
          project {
            name
            general_contractors {
              general_contractor {
                name
              }
            }
            address {
              line_1
              line_2
              city
              state_code
              state {
                name
              }
              zip_code
            }
          }
          tasks(
            where: {
              report_id: { _is_null: true }
              deleted_at: { _is_null: true }
              request_state: { _is_null: true }
              is_pending_acceptance: { _eq: false }
            }
            order_by: { description: { en: asc } }
          ) {
            show_type
            description {
              en
            }
            id
            pk: id @__clientField(handle: "pk")
          }
          subcontractor {
            name
          }
        }
      }
    }
  }
`;

const QrProjectSubcontractor: FC<ProjectIdProps & SubcontractorProps> = ({
  subcontractorId,
  projectId,
}) => {
  const navigate = useNavigate();
  const data = useLazyLoadQuery<QrProjectSubcontractorQuery>(
    query,
    {
      subcontractorId,
      projectId,
    },
    { fetchPolicy: "network-only" },
  ).project_subcontractor_connection.edges[0]?.node;
  const projectData = data.project;
  const subcontractorData = data.subcontractor;

  const address = [
    projectData.address.line_1,
    projectData.address.line_2,
    projectData.address.city,
    projectData.address.state_code,
  ]
    .filter((str) => !!str?.length)
    .join(", ");
  useEffect(() => {
    console.log(auth.currentUser?.uid);
    const getRole = async () => {
      const user = await auth.currentUser?.getIdTokenResult();

      if (user && user.claims.role === "pseudo_worker") {
        auth.signOut();
        console.log("Logged Out");
      }
    };
    getRole();
  }, []);
  return (
    <MobileViewWrapper>
      <div className="flex flex-col justify-center h-full gap-1">
        <div className="flex flex-col justify-center gap-1">
          <div className="flex flex-col gap-0">
            <h1 className=" font-accent text-2">Project JHAs</h1>
            <p className="text-static-secondary text-1">
              (Job Hazard Analysis)
            </p>
          </div>
          <p className="font-accent text-1">
            Review and Sign your project specific JHAs
          </p>
          <ProjectOverviewCard
            projectTitle={projectData.name}
            gcTitle={projectData.general_contractors
              .map((gc) => gc.general_contractor.name)
              .join(", ")}
            projectAddress={address}
          />
        </div>
        <CardWrapper>
          <div className="flex flex-col gap-0.5">
            <p className="font-accent">JHAs:</p>
            {data.tasks.map((c) => {
              return (
                <Button
                  key={c.pk}
                  onClick={() => {
                    navigate(
                      `/qr/p/${projectId}/sub/${subcontractorId}/t/${c.pk}`,
                    );
                  }}
                  label={
                    c.description.en == ""
                      ? "<Unnamed>"
                      : c.description.en +
                        (c.show_type === "images" ? " (image upload)" : "")
                  }
                  fullWidth
                  wrapText
                />
              );
            })}
          </div>
        </CardWrapper>
      </div>
    </MobileViewWrapper>
  );
};

export default withCustomSuspense(QrProjectSubcontractor);
