import isNetworkError from "./isNetworkError";
import { isDevelopmentEnvironment } from "../../common/constants/environment";
import { notification } from "antd";
import ErrorCodeBlock from "./components/ErrorCodeBlock";
import sendHotjarEvent from "../event-tracking/hotjar/sendHotjarEvent";
import * as Sentry from "@sentry/react";
import NotifyUserException from "./NotifyUserException";
import showUnexpectedErrorMessage from "./user-feedback/showUnexpectedErrorMessage";
import showNetworkErrorMessage from "./user-feedback/showNetworkErrorMessage";
import showUserErrorMessage from "./user-feedback/showUserErrorMessage";
import { isApolloError } from "@apollo/client";

const handleError = (error: unknown, config?: { errorTitle?: string } ) => {
  if (error instanceof NotifyUserException) {
    showUserErrorMessage({
      message: error.errorTitle ?? config?.errorTitle ?? "Error",
      description: error.message,
    });
    return;
  }
  //  const reportError = error instanceof ActionFailedException? error.originalError : error;
  //  const hint =  error instanceof ActionFailedException? error.message : undefined;


  // TODO: after we make sure that network error detection works well, we will move Sentry.captureExcetpion to else branch.
  //  At the moment it is here because server errors may be classified incorrecly as network errors..
  //  and such errors should be reported to centry.
  const networkError = isNetworkError(error); 

  const shortId = (Math.random() + 1).toString(36).substring(6);
  Sentry.captureException(error, {
    tags: 
      { 
        shortId: shortId, 
        isNetworkError: networkError, 
      }
    }
  );

  sendHotjarEvent("error");
  const isCustomApolloError = error instanceof Error && isApolloError(error) && error.graphQLErrors.some(e => !/([a-z])+/.test(e.extensions?.code ?? ''));
  if (isCustomApolloError) {
    console.log('keys,', Object.keys(error));
    showUserErrorMessage({
      message: config?.errorTitle ?? "Error",
      description: error.message,
    })    
  } else if (networkError) {
    showNetworkErrorMessage();
  } else {
    showUnexpectedErrorMessage(shortId, config?.errorTitle);
  }
  // Show notification to developer
  if (isDevelopmentEnvironment) { 
    console.error(error);
    if (error instanceof Error) {
      console.error(error.stack);
    }  
    notification.error({
      message: "On Dev Environment Only",
      description: (
        <ErrorCodeBlock
          showCopyButton={true}
          errorMessage={String(error)}
        />
      ),
    });
  }
};

export default handleError;