import { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import useGetActivePath from "../../../../../../../utility-features/navigation/active-element/useGetActivePath";

const useHierarchyMenuProps: (
  features: {
    label: string;
    key: string;
  }[],
  basePath: string,
  title?: string,
) => MenuProps = (features, basePath, orgUnitTitle) => {
  const navigate = useNavigate();
  const featuresMenuItems: MenuProps["items"] = orgUnitTitle
    ? [
        {
          key: "group",
          type: "group",
          label: orgUnitTitle,
          children: features,
        },
      ]
    : features;
  const onSelect = (key: string) => {
    if (!key) return;
    navigate(`${basePath}/${key}`);
  };

  const menuKeys: string[] = features.map((feature) => feature.key);

  const selectedMenuKey = useGetActivePath(basePath, menuKeys);
  return {
    items: featuresMenuItems,
    onClick: (menuInfo) => {
      onSelect(menuInfo.key);
    },
    selectedKeys: selectedMenuKey ? [selectedMenuKey] : [],
  };
};

export default useHierarchyMenuProps;
