import React, { FC } from "react";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";
import ProjectCrewWorkerDetails from "../../components/ProjectCrewWorkerDetails";
import { useNavigate } from "react-router-dom";

interface SCProjectCrewsProps {
  projectId: string;
  subcontractorId: string;
  refresh: () => void;
  data: SCProjectQuery$data;
}
const SCProjectCrews: FC<SCProjectCrewsProps> = ({
  subcontractorId,
  projectId,
  data,
}) => {
  const navigate = useNavigate();
  return (
    <ProjectCrewWorkerDetails
      projectId={projectId}
      workersDataKey={data}
      subcontractorId={subcontractorId}
      showOnsite={false}
      onRowClick={(crewId) =>
        navigate(`/sub/projects/${projectId}/crew?id=${crewId}`)
      }
    ></ProjectCrewWorkerDetails>
  );
};
export default SCProjectCrews;
