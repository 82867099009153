import AppPageLayout from "../../../../../../../../common/components/layouts/main/content/AppPageLayout";
import GCCorporateUsers from "../../../components/hierarchy-node-users/GCCorporateUsers";

interface GCCorporateOfficeEmployeesProps {}

const GCCorporateOfficeEmployees: React.FC<GCCorporateOfficeEmployeesProps> = (
  props,
) => {
  return (
    <AppPageLayout pageTitle={"Employees"}>
      <GCCorporateUsers hierarchyNodeManagersOnly={false} />
    </AppPageLayout>
  );
};

export default GCCorporateOfficeEmployees;
