import Icon from "src/common/components/general/Icon";
import {
  IconEye,
  IconEyeOff,
  IconInfoCircle,
  IconCalendar,
  IconMap,
} from "@tabler/icons";
import React, { useState } from "react";
import hexToRgba from "src/common/functions/hexToRgba";
import { CalendarBaseType } from "../utilities/sitedeliveryTypes";
import { Button, Drawer, Popover, Tooltip, Card } from "antd";
import {
  GetDeliveryFieldsQuery,
  Project_Calendar_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import PdfModal from "src/common/components/modals/PdfModal";
import { useNavigate, useParams } from "react-router-dom";

import GCProjectCalendarSitedeliveryCalendars from "./GCProjectCalendarSitedeliveryCalendars";
import { auth } from "src/common/functions/firebase";

export interface GCProjectCalendarSitedeliveryDeliveriesCalendarsFilterProps {
  logisticPlan: GetDeliveryFieldsQuery["project"][number]["logistic_plans"];
  calendars: (CalendarBaseType & {
    visible: boolean;
  } & { isArchive: boolean })[];
  toggleCalendarVisible: (
    calendar: CalendarBaseType,
    newValue: boolean,
  ) => void;
  hideLogistic?: boolean;
  gcId?: string;
  openAddNewDeliveryModal?: () => void;
  onInsertCalendar: (
    calendar: Project_Calendar_Insert_Input[],
  ) => Promise<void>;
}

const GCProjectCalendarSitedeliveryDeliveriesCalendarsFilter: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesCalendarsFilterProps
> = (props) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const authUser = auth.currentUser?.uid;
  const [pdfUrl, setPdfUrl] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isAddNewCalendarModalOpen, setIsAddNewCalendarModalOpen] =
    useState(false);
  const [isAddNewDeliveryModalOpen, setIsAddNewDeliveryModalOpen] =
    useState(false);
  const [showHelpText, setShowHelpText] = useState(false);
  return (
    <div className="flex justify-between gap-1">
      <div className="flex flex-row w-full gap-1">
        <div className="font-accent flex ml-1">Available Calendars:</div>
        <div className="flex-1 w-0">
          <div className="w-full overflow-x-auto">
            <div className="flex flex-row gap-0.25">
              {props.calendars
                .filter((calendar) => !calendar.isArchive)
                .map((calendar) => (
                  <div
                    className={
                      "flex flex-row items-center px-0.5 whitespace-nowrap rounded-1 gap-0.25 text-0.75 border cursor-pointer select-none transition-colors group"
                    }
                    key={calendar.id}
                    onClick={() => {
                      props.toggleCalendarVisible(calendar, !calendar.visible);
                    }}
                    style={
                      calendar.visible
                        ? {
                            backgroundColor: hexToRgba(calendar.colorHex, 0.4),
                            borderColor: "transparent",
                          }
                        : {
                            borderColor: hexToRgba(calendar.colorHex, 0.4),
                            backgroundColor: "transparent",
                          }
                    }
                  >
                    <div
                      className={
                        calendar.visible
                          ? "text-static-primary font-accent text-1.2"
                          : "text-static-secondary"
                      }
                    >
                      {calendar.title}
                    </div>
                    <div
                      className={
                        "opacity-0 group-hover:opacity-100 transition-opacity"
                      }
                    >
                      <Icon
                        icon={calendar.visible ? IconEyeOff : IconEye}
                        size="small"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <PdfModal
        destroyOnClose
        visible={!!pdfUrl}
        onClose={() => {
          setPdfUrl("");
        }}
        pdfUrl={pdfUrl}
      />

      {!!authUser && (
        <>
          <Tooltip
            title={
              !props.calendars.some((cal) => !cal.isArchive)
                ? "Create a calendar to schedule a delivery"
                : ""
            }
          >
            <Button
              className="flex"
              type="primary"
              onClick={() => {
                props.openAddNewDeliveryModal?.();
              }}
              disabled={!props.calendars.some((cal) => !cal.isArchive)}
            >
              + Add Delivery
            </Button>
          </Tooltip>

          <Button
            className="flex"
            type="default"
            onClick={() => {
              setDrawerVisible(true);
            }}
            icon={<Icon icon={IconCalendar} size="small" />}
          >
            Calendars
          </Button>

          <Drawer
            title={
              <div className="flex items-center gap-0.5">
                Calendar Management
                <Icon
                  icon={IconInfoCircle}
                  color="static-secondary"
                  size="small"
                  onClick={() => {
                    setShowHelpText((prev) => !prev);
                  }}
                />
              </div>
            }
            placement="right"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
          >
            {showHelpText && (
              <Card className="mb-1">
                <div className="text-static-secondary text-1">
                  A calendar represents a delivery location at your facility,
                  such as:
                  <ul className="list-disc ml-4 mt-1">
                    <li>Loading docks</li>
                    <li>Gates</li>
                    <li>Elevators</li>
                    <li>Forklift or Crane</li>
                  </ul>
                </div>
              </Card>
            )}
            <GCProjectCalendarSitedeliveryCalendars
              calendars={props.calendars}
              toggleCalendarVisible={props.toggleCalendarVisible}
              gcId={props.gcId}
              onInsertCalendar={props.onInsertCalendar}
            />
          </Drawer>
        </>
      )}

      {props.hideLogistic ? (
        <></>
      ) : props.logisticPlan &&
        props.logisticPlan.at(0) &&
        props.logisticPlan[0].documents.length !== 0 ? (
        <Popover
          open={openPopover}
          onOpenChange={(v) => setOpenPopover(v)}
          content={
            props.logisticPlan
              ? props.logisticPlan[0].documents.map((doc) => (
                  <Button
                    key={doc.id}
                    className="flex text-interactive-primary"
                    type="text"
                    onClick={() => {
                      setOpenPopover(false);
                      setPdfUrl(doc.url);
                    }}
                  >
                    {doc.name}
                  </Button>
                ))
              : []
          }
        >
          <Button
            className="flex"
            icon={<Icon icon={IconMap} size="small" />}
            type="default"
            onClick={() => {}}
          >
            Logistics Plan
          </Button>
        </Popover>
      ) : (
        <Button
          className="flex"
          type="default"
          onClick={() => {
            navigate(`/gce/projects/${projectId}/settings`);
          }}
        >
          Add Logistics Plan
        </Button>
      )}
    </div>
  );
};

export default GCProjectCalendarSitedeliveryDeliveriesCalendarsFilter;
