import { Modal, ModalFuncProps } from "antd";

const showModalConfirmation = (
  options: Omit<ModalFuncProps, "onOk" | "onCancel">,
) => {
  return new Promise<boolean>((resolve) => {
    Modal.confirm({
      ...options,
      onOk: () => resolve(true), // Resolve `true` when confirmed
      onCancel: () => resolve(false), // Resolve `false` when canceled
    });
  });
};
export default showModalConfirmation;
