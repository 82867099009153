import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import GCAdminIncidentsTable from "../../components/incident/GCAdminIncidentsTable";

const GCCorporateIncidentsTable: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => (
  <GCAdminIncidentsTable
    organizationLevel="gc"
    organizationId={employee.general_contractor.id}
  />
);
export default withCorporateAdminWrapper(GCCorporateIncidentsTable);
