import { Button, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import {
  browserSessionPersistence,
  signInWithCustomToken,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { roleVar } from "src/common/api/apollo/cache";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import { auth } from "src/common/functions/firebase";
import {
  usePseudoWorkerSignInMutation,
  usePseudoWorkerSignInThroughSecurityQuestionsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { QuizQrQuery } from "src/common/types/generated/relay/QuizQrQuery.graphql";
import { useAuth } from "src/utility-features/authorization/AuthProvider";

const getNonOrientationQuizQuery = graphql`
  query QuizQrQuery($projectId: uuid!, $time: timestamptz!) {
    project_employee_connection(
      where: {
        project: { orientation_project_id: { _eq: $projectId } }
        user_orientation: { in_person_orientated_at: { _gte: $time } }
      }
      order_by: { employee: { user: { name: asc } }, user_orientation: {} }
    ) {
      edges {
        node {
          employee_id
          hard_hat_number
          employee {
            general_contractor {
              name
            }
            user {
              name
              email
              birth_date
            }
            oac_company {
              name
            }
          }
          user_orientation {
            all_user_orientation_results(
              where: {
                orientation: { slides: { content_type: { _eq: "question" } } }
                _or: [
                  { project_id: { _is_null: true } }
                  {
                    project: {
                      general_contractor: {
                        projects: { id: { _eq: $projectId } }
                      }
                    }
                  }
                ]
                quiz_results: {}
              }
            ) {
              orientation_id
              expired_at
              orientation {
                duration_valid
              }
              completed_at
              created_at
              quiz_results {
                id
                answer
                slide {
                  id
                  pk: id @__clientField(handle: "pk")
                }
              }
            }
          }
        }
      }
    }
    project_worker_connection(
      where: {
        project: { orientation_project_id: { _eq: $projectId } }
        subcontractor_worker: {}
        user_orientation: { in_person_orientated_at: { _gte: $time } }
      }
      order_by: { user: { name: asc }, user_orientation: {} }
    ) {
      edges {
        node {
          worker_id
          hard_hat_number
          user {
            email
            birth_date
            name
          }
          subcontractor {
            name
          }
          user_orientation {
            all_user_orientation_results(
              where: {
                orientation: { slides: { content_type: { _eq: "question" } } }
                _or: [
                  { project_id: { _is_null: true } }
                  {
                    project: {
                      general_contractor: {
                        projects: { id: { _eq: $projectId } }
                      }
                    }
                  }
                ]
                quiz_results: {}
              }
            ) {
              orientation_id
              expired_at
              orientation {
                duration_valid
              }
              completed_at
              created_at
              quiz_results {
                id
                answer
                slide {
                  id
                  pk: id @__clientField(handle: "pk")
                }
              }
            }
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          agc_universal_orientation
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        slides: {
          content_type: { _eq: "question" }
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
        }
        _and: [
          {
            _or: [
              {
                general_contractor_id: { _is_null: true }
                _and: [
                  {
                    project_orientations: {
                      project_id: { _is_null: true }
                      required_by_all_workers: { _eq: true }
                    }
                  }
                  {
                    project_orientations: {
                      project_id: { _eq: $projectId }
                      hide_but_give_credit: { _eq: true }
                    }
                  }
                ]
              }
              {
                general_contractor: { projects: { id: { _eq: $projectId } } }
                project_orientations: {
                  required_by_all_workers: { _eq: true }
                  hide_but_give_credit: { _eq: true }
                  project_id: { _eq: $projectId }
                }
              }
            ]
          }
          {
            _or: [
              { project_id: { _is_null: true } }
              {
                project: {
                  linked_orientation_projects: { id: { _eq: $projectId } }
                }
              }
            ]
          }
        ]
      }
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          duration_valid
          type
          pk: id @__clientField(handle: "pk")
          slides(
            where: {
              content_type: { _eq: "question" }
              deleted_at: { _is_null: true }
              archived_at: { _is_null: true }
            }
            order_by: { order: asc }
          ) {
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }
  }
`;
const QuizQR: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId") as string;
  const time = dayjs().subtract(5, "hours").startOf("hour").toISOString();
  const data = useLazyLoadQuery<QuizQrQuery>(getNonOrientationQuizQuery, {
    projectId,
    time,
  });
  const [pseudoWorkerSignInThroughSecurityQuestions, { loading: isSigning }] =
    usePseudoWorkerSignInThroughSecurityQuestionsMutation();
  const [pseudoWorkerSignIn] = usePseudoWorkerSignInMutation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const slides = data.orientation_connection.edges
    .filter((o) =>
      data.project_connection.edges[0].node.agc_universal_orientation
        ? true
        : o.node.type !== "universal",
    )
    .flatMap((o) => o.node.slides);

  useEffect(() => {
    auth.setPersistence(browserSessionPersistence);
  }, []);

  const authUser = useAuth();
  const pending: {
    [workerId: string]: {
      name: string;
      id: string;
      email?: string | null;
      birth_date?: string | null;
      company: string;
      hardhat?: string | null;
    };
  } = {};
  const completed: {
    [workerId: string]: {
      name: string;
      id: string;
      company: string;
      hardhat?: string | null;
    };
  } = {};
  data.project_employee_connection.edges.forEach((project_employee) => {
    const userSlideMap: {
      [slideId: string]: boolean;
    } = {};
    (project_employee.node.user_orientation?.all_user_orientation_results || [])
      .filter(
        (p) =>
          !p.expired_at ||
          dayjs(p.expired_at).isSameOrAfter(
            dayjs(p.completed_at ?? p.created_at)
              .endOf("day")
              .add(
                (p.orientation?.duration_valid ?? 1) - 1, // added ?? 1, so that if a quiz result is expiring in a month user can now answer it again to update the quiz result
                "months",
              ),
          ),
      )
      .forEach((all_user_orientation_result) => {
        all_user_orientation_result.quiz_results.forEach((quiz_result) => {
          userSlideMap[quiz_result.slide.pk] = true;
        });
      });
    if (
      slides.length === 0 ||
      slides.some((s) => {
        if (!userSlideMap[s.pk]) return true;
        return false;
      })
    ) {
      pending[project_employee.node.employee_id] = {
        name: project_employee.node.employee.user.name,
        email: project_employee.node.employee.user.email,
        birth_date: project_employee.node.employee.user.birth_date,
        id: project_employee.node.employee_id,
        hardhat: project_employee.node.hard_hat_number,
        company: project_employee.node.employee.general_contractor.name,
      };
    } else {
      completed[project_employee.node.employee_id] = {
        name: project_employee.node.employee.user.name,
        id: project_employee.node.employee_id,
        hardhat: project_employee.node.hard_hat_number,
        company: project_employee.node.employee.general_contractor.name,
      };
    }
  });
  data.project_worker_connection.edges.forEach((project_worker) => {
    const userSlideMap: {
      [slideId: string]: boolean;
    } = {};
    (project_worker.node.user_orientation?.all_user_orientation_results || [])
      .filter(
        (p) =>
          !p.expired_at ||
          dayjs(p.expired_at).isSameOrAfter(
            dayjs(p.completed_at ?? p.created_at)
              .endOf("day")
              .add(
                (p.orientation?.duration_valid ?? 1) - 1, // added ?? 1, so that if a quiz result is expiring in a month user can now answer it again to update the quiz result
                "months",
              ),
          ),
      )
      .forEach((all_user_orientation_result) => {
        all_user_orientation_result.quiz_results.forEach((quiz_result) => {
          userSlideMap[quiz_result.slide.pk] = true;
        });
      });
    if (
      slides.length === 0 ||
      slides.some((s) => {
        if (!userSlideMap[s.pk]) return true;
        return false;
      })
    ) {
      pending[project_worker.node.worker_id] = {
        name: project_worker.node.user!.name,
        email: project_worker.node.user!.email,
        birth_date: project_worker.node.user!.birth_date,
        id: project_worker.node.worker_id,
        hardhat: project_worker.node.hard_hat_number,
        company: project_worker.node.subcontractor.name,
      };
    } else {
      completed[project_worker.node.worker_id] = {
        name: project_worker.node.user!.name,
        id: project_worker.node.worker_id,
        hardhat: project_worker.node.hard_hat_number,
        company: project_worker.node.subcontractor.name,
      };
    }
  });
  if (slides.length === 0)
    return (
      <div className="text-1.25 flex justify-center items-center mt-2">
        No Questions Found
      </div>
    );
  return loading ? (
    <LoadingContent />
  ) : (
    <div>
      <div className="header text-center text-2 font-accent p-1 pb-0">
        Orientation Knowledge Check
      </div>
      <div className="text-center text-1 font-accent pb-2">
        select your name
      </div>
      <div className="content overflow-scroll max-h-24 items-center flex flex-col">
        {Object.values(pending).map((pw) => (
          <Button
            key={pw.id}
            className="border-0.125 border-interactive-primary border-solid w-11/12 max-w-32 m-0.25 rounded-2"
            onClick={async () => {
              const quizURL = `/orientation/quiz/${projectId}/question/${pw.id}`;
              setLoading(true);
              try {
                console.log("pw email", pw.email);                
                if (pw.email) {
                  const { data } = await pseudoWorkerSignIn({
                    variables: {
                      input: {
                        returnToken: true,
                        email: pw.email,
                        projectId,
                      },
                    },
                  });
                  const workerSignIn = data?.pseudoWorkerSignIn;
                  if (!workerSignIn) {
                    throw new Error("Data not found to sign in user");
                  }
                  await authUser.runAuthTask(async () => {
                    const { token } = workerSignIn;
                    if (token) {
                      await auth.signOut();
                      await signInWithCustomToken(auth, token);
                    }
                    navigate(quizURL);                      
                  })

                } else if (pw.birth_date) {
                  const { data } =
                    await pseudoWorkerSignInThroughSecurityQuestions({
                      variables: {
                        input: {
                          returnToken: true,
                          projectId,
                          workerDob: dayjs(pw.birth_date).format("YYYY-MM-DD"),
                          name: pw.name,
                        },
                      },
                    });
                  const workerSingIn =
                    data?.pseudoWorkerSignInThroughSecurityQuestions;
                  if (!workerSingIn)
                    throw new Error("Data not found to sign in user");

                  await authUser.runAuthTask(async () => {
                    const { token } = workerSingIn;
                    if (token) {
                      await auth.signOut();
                      await signInWithCustomToken(auth, token);
                    }
                    navigate(quizURL);                      
                  })

                } else {
                  message.warning("User not fully registered yet");
                }
              } finally {
                setLoading(false);
              }
            }}
          >
            <div className="truncate">
              <span className="font-accent">{pw.name}</span>
              &nbsp; &nbsp; &nbsp;
              <b>
                {pw.hardhat ? `#${pw.hardhat}` : ""}&nbsp; &nbsp; &nbsp;{" "}
                {pw.company}
              </b>
            </div>
          </Button>
        ))}
      </div>
      <div className="text-center">Completed Workers</div>
      <div className="content overflow-scroll max-h-24 items-center flex flex-col">
        {Object.values(completed).map((pw) => (
          <Button
            key={pw.id}
            className="border-0.125 border-semantic-positive-green border-solid w-11/12 max-w-32 m-0.25 rounded-2"
            onClick={() => {
              // navigate(
              //   "/orientation/quiz/ques/" + projectId + "/" + pw.worker_id
              // );
            }}
          >
            <div className="truncate">
              <span className="font-accent">{pw.name}</span>
              &nbsp; &nbsp; &nbsp;
              <b>
                {pw.hardhat ? `#${pw.hardhat}` : ""}&nbsp; &nbsp; &nbsp;{" "}
                {pw.company}
              </b>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};
export default QuizQR;
