import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  GetCompletionAuditV4Data,
  useGetCompletionAuditV4Query,
} from "src/common/types/generated/apollo/graphQLTypes";
import { SiteBoardOnsiteReportColumnProps } from "./components/SiteBoardOnsiteReportColumn";
import SiteBoardOnsiteUI from "./components/SiteBoardOnsiteUI";
import { SiteBoardOnsiteWorkersColumnProps } from "./components/SiteBoardOnsiteWorkersColumn";
import { SiteBoardOnsiteViewQuery } from "src/common/types/generated/relay/SiteBoardOnsiteViewQuery.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import getBgColorFromPercent from "./utils/getBgColorFromPercent";
import { time } from "console";

interface SubOnsiteDataType extends GetCompletionAuditV4Data {
  workerOnsite: number;
  lastDayWorkerOnsite: number;
  type: "onlySub" | "hasCrews";
  ptpRequired: number;
  ptpCompleted: number;
  drRequired: number;
  drCompleted: number;
}

const todayAndYesterdayOnsiteProjectWorkersQuery = graphql`
  query SiteBoardOnsiteViewQuery(
    $projectId: uuid!
    $todayStartTime: timestamptz!
    $todayStartDate: date!
    $todayEndTime: timestamptz!
    $todayEndDate: date!
    $yesterdayStartTime: timestamptz!
    $yesterdayStartDate: date!
    $yesterdayEndTime: timestamptz!
    $yesterdayEndDate: date!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          timezone
        }
      }
    }
    todayProjectWorker: project_worker_connection(
      where: {
        project_id: { _eq: $projectId }
        is_last: { _eq: true }
        deleted_at: { _is_null: true }
        project_workers: {
          _or: [
            {
              reports: {
                report: {
                  created_at: { _gte: $todayStartTime, _lte: $todayEndTime }
                }
              }
            }
            {
              report_injuries: {
                report: {
                  created_at: { _gte: $todayStartTime, _lte: $todayEndTime }
                }
              }
            }
            {
              toolbox_talks: {
                toolbox_talk: {
                  created_at: { _gte: $todayStartTime, _lte: $todayEndTime }
                }
              }
            }
            {
              daily_work_log_workers: {
                daily_work_log: {
                  date: { _gte: $todayStartDate, _lte: $todayEndDate }
                }
              }
            }
            {
              permits: {
                permit: {
                  type: { _neq: "ladder" }
                  created_at: { _gte: $todayStartTime, _lte: $todayEndTime }
                  current_status: { _in: ["submitted", "resubmitted"] }
                  is_historical: { _eq: true }
                }
              }
            }
          ]
        }
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          subcontractor_id
        }
      }
    }
    yesterdayProjectWorker: project_worker_connection(
      where: {
        project_id: { _eq: $projectId }
        is_last: { _eq: true }
        deleted_at: { _is_null: true }
        project_workers: {
          _or: [
            {
              reports: {
                report: {
                  created_at: {
                    _gte: $yesterdayStartTime
                    _lte: $yesterdayEndTime
                  }
                }
              }
            }
            {
              report_injuries: {
                report: {
                  created_at: {
                    _gte: $yesterdayStartTime
                    _lte: $yesterdayEndTime
                  }
                }
              }
            }
            {
              toolbox_talks: {
                toolbox_talk: {
                  created_at: {
                    _gte: $yesterdayStartTime
                    _lte: $yesterdayEndTime
                  }
                }
              }
            }
            {
              daily_work_log_workers: {
                daily_work_log: {
                  date: { _gte: $yesterdayStartDate, _lte: $yesterdayEndDate }
                }
              }
            }
            {
              permits: {
                permit: {
                  type: { _neq: "ladder" }
                  created_at: {
                    _gte: $yesterdayStartTime
                    _lte: $yesterdayEndTime
                  }
                  current_status: { _in: ["submitted", "resubmitted"] }
                  is_historical: { _eq: true }
                }
              }
            }
          ]
        }
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          subcontractor_id
        }
      }
    }
  }
`;

interface SiteBoardOnsiteProps {}

const ICON_PTP = "🚧"; //pre-task plans
const ICON_DR = "👷"; // daily reports

const SiteBoardOnsiteView: React.FC<SiteBoardOnsiteProps> = (props) => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  dayjs.extend(isoWeek);

  if (!projectId) {
    throw new Error("Project Id not found");
  }
  let currentOnsiteDay = dayjs().clone();
  let prevOnsiteDay = dayjs(currentOnsiteDay).subtract(1, "day");
  while (
    currentOnsiteDay.weekday() === 1 &&
    (prevOnsiteDay.weekday() === 6 || prevOnsiteDay.weekday() === 0)
  ) {
    // Show dates like, Fri-Sat, Sat-Sun Fri-Mon
    prevOnsiteDay = prevOnsiteDay.subtract(1, "day");
  }

  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState({
    fetchKey: 0,
  });
  const data = useLazyLoadQuery<SiteBoardOnsiteViewQuery>(
    todayAndYesterdayOnsiteProjectWorkersQuery,
    {
      projectId,
      todayStartTime: currentOnsiteDay.startOf("d").toISOString(),
      todayStartDate: currentOnsiteDay.startOf("d").format("YYYY-MM-DD"),
      todayEndTime: currentOnsiteDay.endOf("d").toISOString(),
      todayEndDate: currentOnsiteDay.endOf("d").format("YYYY-MM-DD"),
      yesterdayStartTime: prevOnsiteDay.startOf("d").toISOString(),
      yesterdayStartDate: prevOnsiteDay.startOf("d").format("YYYY-MM-DD"),
      yesterdayEndTime: prevOnsiteDay.endOf("d").toISOString(),
      yesterdayEndDate: prevOnsiteDay.endOf("d").format("YYYY-MM-DD"),
    },
    {
      fetchPolicy: "store-and-network",
      ...refreshedQueryOptions,
    },
  );
  const onsiteData = new Map<string, number>();
  data.todayProjectWorker.edges.map((pw) => {
    onsiteData.set(
      pw.node.subcontractor_id,
      (onsiteData.get(pw.node.subcontractor_id) || 0) + 1,
    );
  });
  const prevOnsiteData = new Map<string, number>();
  data.yesterdayProjectWorker.edges.map((pw) => {
    prevOnsiteData.set(
      pw.node.subcontractor_id,
      (prevOnsiteData.get(pw.node.subcontractor_id) || 0) + 1,
    );
  });
  const project = data.project_connection.edges[0];
  if (!project) throw new Error("project does not exist");
  const timezone = project.node.timezone;

  const today = dayjs().tz(timezone);
  const startOfWeek = today.startOf("isoWeek");
  const daysFromStartOfWeek = today.diff(startOfWeek, "day");

  const { data: auditData, refetch } = useGetCompletionAuditV4Query({
    variables: {
      input: {
        projectId: projectId,
        startDate: startOfWeek.format("YYYY-MM-DD"),
        endDate: today.format("YYYY-MM-DD"),
        timezone,
      },
    },
  });
  // let tbtCompletedSubsNew: Array<string> = [];
  let tbtCompletedSubsNew = new Set();
  const weekSubCountNew: {
    [id: string]: number;
  } = {};

  (auditData?.getCompletionAuditV4 || []).forEach((sub) => {
    for (let i = 0; i <= daysFromStartOfWeek; i++) {
      const completion = sub.dailyCompletion[i];
      let onsiteSub = false;
      if (completion.total_safety_reports_required) {
        onsiteSub = true;
      }
      if (completion.total_daily_reports_required) {
        onsiteSub = true;
      }
      if (completion.toolbox_talks_completion_count) {
        if (!tbtCompletedSubsNew.has(sub.id)) {
          tbtCompletedSubsNew.add(sub.id);
        }
      }
      if (onsiteSub && completion.toolbox_talks_required) {
        if (!weekSubCountNew[sub.id]) {
          weekSubCountNew[sub.id] = 1;
        } else {
          weekSubCountNew[sub.id] += 1;
        }
      }
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      }));
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  let totalReports = 0;
  // let tbtCompletedSubs: Array<string> = [];

  const subData = (auditData?.getCompletionAuditV4 || []).map(
    (sub): SubOnsiteDataType => {
      let ptpRequired = 0,
        ptpCompleted = 0,
        drRequired = 0,
        drCompleted = 0;

      const completion = sub.dailyCompletion[daysFromStartOfWeek];
      let subCount = false;
      if (
        completion.total_safety_reports_required ||
        completion.total_daily_reports_required
      ) {
        subCount = true;
      }
      ptpRequired = completion.total_safety_reports_required;
      ptpCompleted = completion.safety_reports_completion_count;
      drRequired = completion.total_daily_reports_required;
      drCompleted = completion.daily_reports_completion_count;
      if (subCount) totalReports += 1;
      // TODO TEST IF SUB SHOW IF ONLY TBT Submitted
      return {
        ...sub,
        type: sub.crewData.length > 1 ? "hasCrews" : "onlySub",
        workerOnsite: onsiteData.get(sub.id) || 0,
        lastDayWorkerOnsite: prevOnsiteData.get(sub.id) || 0,
        ptpCompleted,
        drCompleted,
        drRequired,
        ptpRequired,
      };
    },
  );

  const filteredSubData = subData.filter(
    (r) => r.drRequired || r.ptpRequired || r.workerOnsite,
  );
  let tbtReq = 0;
  let tbtSubmitted = 0;

  let tbtReqSubs = new Set();
  filteredSubData.forEach((sub) => {
    if (weekSubCountNew[sub.id] > 2) {
      if (!tbtReqSubs.has(sub.id)) {
        tbtReqSubs.add(sub.id);
        tbtReq += 1;
      }
    }
    if (tbtCompletedSubsNew.has(sub.id)) {
      tbtSubmitted += 1;
      if (!tbtReqSubs.has(sub.id)) {
        tbtReqSubs.add(sub.id);
        tbtReq += 1;
      }
    }
  });

  const reports: SiteBoardOnsiteReportColumnProps[] = [];
  if (!!searchParams.get("ptp")) {
    reports.push({
      longTitle: "Pre-task Plans",
      reportIcon: ICON_PTP,
      shortTitle: "PTP",
      reportsSubmitted: filteredSubData.map((sub, i) => {
        const percent = sub.ptpRequired
          ? sub.ptpCompleted / sub.ptpRequired
          : 0;
        return {
          amountSubmitted: sub.ptpCompleted,
          color: getBgColorFromPercent(percent * 100),
          type: sub.type,
          total: sub.ptpRequired,
          header: "Pre-task Plans",
        };
      }),
      totalRequired: filteredSubData.reduce(
        (sum, sub) => sum + sub.ptpRequired,
        0,
      ),
      totalSubmitted: filteredSubData.reduce(
        (sum, sub) => sum + sub.ptpCompleted,
        0,
      ),
    });
  }
  if (!!searchParams.get("daily")) {
    reports.push({
      longTitle: "Daily Reports ",
      reportIcon: ICON_DR,
      shortTitle: "DR",
      reportsSubmitted: filteredSubData.map((sub, i) => {
        const percent = sub.drRequired ? sub.drCompleted / sub.drRequired : 0;
        return {
          amountSubmitted: sub.drCompleted,
          type: sub.type,
          total: sub.drRequired,
          color: getBgColorFromPercent(percent * 100),
          header: "Daily Reports ",
        };
      }),
      totalRequired: filteredSubData.reduce(
        (sum, sub) => sum + sub.drRequired,
        0,
      ),
      totalSubmitted: filteredSubData.reduce(
        (sum, sub) => sum + sub.drCompleted,
        0,
      ),
    });
  }
  if (!!searchParams.get("tbt")) {
    reports.push({
      longTitle: "TBT",
      reportIcon: "🧰",
      shortTitle: "TBT",
      reportsSubmitted: filteredSubData.map((sub, i) => {
        const submitted = tbtCompletedSubsNew.has(sub.id) ? 1 : 0;
        const reqSub = tbtReqSubs.has(sub.id);
        return {
          amountSubmitted: submitted,
          type: sub.type,
          total: (reqSub && daysFromStartOfWeek > 2) || submitted ? 1 : 0,
          color: submitted
            ? getBgColorFromPercent(100)
            : daysFromStartOfWeek == 3
            ? "#fb8c00"
            : getBgColorFromPercent(0),
          header: "TBT ",
        };
      }),
      totalRequired: tbtReq,
      totalSubmitted: tbtSubmitted,
    });
  }
  let workersAmountsColumn: SiteBoardOnsiteWorkersColumnProps | undefined;
  if (!!searchParams.get("workers")) {
    workersAmountsColumn = {
      headers: [
        {
          date: prevOnsiteDay,
          totalAmount: filteredSubData.reduce(
            (sum, r) => sum + r.lastDayWorkerOnsite,
            0,
          ),
        },
        {
          date: currentOnsiteDay,
          totalAmount: filteredSubData.reduce(
            (sum, r) => sum + r.workerOnsite,
            0,
          ),
        },
      ],
      workerAmountRows: filteredSubData.map((r) => [
        r.lastDayWorkerOnsite,
        r.workerOnsite,
      ]),
    };
  }

  return (
    <SiteBoardOnsiteUI
      reports={reports}
      totalReports={totalReports}
      subcontractors={filteredSubData.map((r, i) => {
        const percent =
          r.drRequired + r.ptpRequired
            ? (r.drCompleted + r.ptpCompleted) / (r.drRequired + r.ptpRequired)
            : 0;
        return {
          title: r.name,
          bgColor: getBgColorFromPercent(percent * 100),
          reportsCount: Math.max(r.ptpRequired, r.drRequired),
          allReportsSubmitted:
            r.drRequired === r.drCompleted && r.ptpCompleted === r.ptpRequired,
        };
      })}
      workersAmounts={workersAmountsColumn}
    />
  );
};

export default SiteBoardOnsiteView;
