import PTPSettings from "src/domain-features/sitesafety/entry-routes/preTaskPlans/components/modal/PTPSettings";
import SitedailyEmailSettings from "../components/SitedailyEmailSettings";

const GCProjectSettingsReportsPtp: React.FC = () => {
  return (
    <div className={`flex flex-col gap-1`}>
      <SitedailyEmailSettings />
      <PTPSettings />
    </div>
  );
};

export default GCProjectSettingsReportsPtp;
