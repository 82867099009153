import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetPtpSettingsDocument,
  GetPtpSettingsQuery,
  GetPtpSettingsQueryVariables,
  useUpdateProjSettingPtpSettingMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import AddDRWithPTPSettings from "../../../daily-reports/components/settings/basic/AddDRWithPTPSettings";
import withProjectIdUrlParam from "src/common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import SwitchWithText from "../../../../../../common/components/SwitchWithText";
import { Card } from "antd";

const PTPSettings: React.FC<ProjectIdProps> = ({ projectId }) => {
  const { data } = useSuspenseQuery<
    GetPtpSettingsQuery,
    GetPtpSettingsQueryVariables
  >(GetPtpSettingsDocument, { variables: { projectId } });
  const proj = data.project_by_pk;
  if (!proj) throw new Error("project not found for given projectId");
  const [updateProjSetting] = useUpdateProjSettingPtpSettingMutation();
  const requireAllWorkersSign =
    proj.project_setting?.ptp_require_all_workers_to_sign;
  return (
    <div className={`flex flex-col gap-1`}>
      <Card title="Pre-Task Plan Settings">
        <SwitchWithText
          text={`Require all workers to sign the Pre-Task Plan. Do not allow photo
        documentation of the crew.`}
          checked={!!requireAllWorkersSign}
          onChange={async (newValue) => {
            const toSet = {
              ptp_require_all_workers_to_sign: newValue,
            };
            await updateProjSetting({
              variables: { projectId, _set: toSet },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  __typename: "project_setting",
                  project_id: projectId,
                  ...toSet,
                },
              },
            });
          }}
        />
      </Card>
      <AddDRWithPTPSettings addDrWithPtpProjectDataFrag={proj} />
    </div>
  );
};
export default withCustomSuspense(withProjectIdUrlParam(PTPSettings));
