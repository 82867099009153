import { ApolloProvider } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createRelayEnvironment } from "src/common/api/relay/RelayEnvironment";
import apolloClient from "../common/api/apollo/apolloClient";
import rootRoute from "./routes/rootRoute";
//
import { wrapCreateBrowserRouter } from "@sentry/react";
import useUnhandledException from "../utility-features/error-handling/useUnhandledException";
import {
  useAuth,
  withAuthProvider,
} from "src/utility-features/authorization/AuthProvider";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import CustomSuspense from "src/common/components/general/CustomSuspense";

const App: FC = () => {
  const auth = useAuth();

  const [relayEnv, setRelayEnv] = useState<RelayModernEnvironment>();
  useUnhandledException();

  useEffect(() => {
    const res = createRelayEnvironment();
    // console.log('set env', !!res.relayEnvironment, Object.keys(res));
    setRelayEnv(res.relayEnvironment);

    return () => {
      //res.subscriptionClient.unsubscribeAll();
      //res.subscriptionClient.close();
    };
  }, [auth.uid]);

  const router = wrapCreateBrowserRouter(createBrowserRouter)([
    {
      path: "/",
      ...rootRoute,
    },
  ]);

  if (!relayEnv) return <div>connecting....</div>;

  return (
    <RelayEnvironmentProvider environment={relayEnv}>
      <ApolloProvider client={apolloClient}>
        <CustomSuspense>
          <div className="w-full h-screen">
            {<RouterProvider router={router} />}
          </div>
        </CustomSuspense>
      </ApolloProvider>
    </RelayEnvironmentProvider>
  );
};

/*git-try*/
export default withAuthProvider(App);
