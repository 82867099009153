import { IconFileText, IconTrash } from "@tabler/icons";
import { Popconfirm } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import Image from "src/common/components/general/images/Image";
import { getCertificateExpiryValueFromDate } from "src/common/functions/getCertificateExpiryValue";
import getDateStr from "src/common/functions/dates/getDateStr";
import Button from "../../../../../../../common/components/general/button/Button";

interface CertificateProps {
  name: string;
  expirationDate?: Date;
  imageUrl?: URL;
  onRemove: () => void;
  fromDate?: Date;
}

const Certificate: React.FunctionComponent<CertificateProps> = ({
  imageUrl,
  name,
  expirationDate,
  onRemove,
  fromDate,
}) => {
  const [warningVisible, setWarningVisible] = useState(false);
  const hideWarning = () => {
    setWarningVisible(false);
  };
  const openWarning = () => {
    setWarningVisible(true);
  };

  const expiryTextColor = getCertificateExpiryValueFromDate(
    expirationDate ? expirationDate : fromDate ? fromDate : undefined,
  );
  let expiryText = undefined;
  if (expirationDate || fromDate) {
    const expiryMoment = dayjs(
      expirationDate ? expirationDate : fromDate ? fromDate : undefined,
    );
    const currentMoment = dayjs();
    const days = expiryMoment.diff(currentMoment, "days");
    if (days < 0) {
      expiryText = "EXPIRED!";
    } else if (days < 90) {
      expiryText = "Expiring Soon!";
    }
  }

  return (
    <div className="flex flex-row margin-gap-x-1">
      <div
        className={`w-2 h-3 bg-suplementary-2 border border-white rounded-0.5 flex items-center justify-center transition-all overflow-hidden`}
      >
        <Image icon={IconFileText} src={imageUrl?.href} preview />
      </div>
      <div className="flex flex-col justify-center flex-1">
        <span className="font-accent">{name}</span>
        <div className="flex flex-col">
          {expirationDate && (
            <span className="text-static-secondary">
              expires on {getDateStr(expirationDate)}
            </span>
          )}
          {fromDate && (
            <span className="text-static-secondary">
              expires on {getDateStr(fromDate)}
            </span>
          )}
          <span className={`text-static-secondary ${expiryTextColor}`}>
            {expiryText}
          </span>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <Popconfirm
          title={`Are you sure you want to remove the certification?`}
          okText={"Remove"}
          onConfirm={onRemove}
          onCancel={hideWarning}
          open={warningVisible}
        />
        <Button icon={IconTrash} small onClick={openWarning} secondary />
      </div>
    </div>
  );
};

export default Certificate;
