import AppPageTitle, { AppPageTitleProps } from "./AppPageTitle";
import CustomSuspense from "../../../general/CustomSuspense";
import LoadingContent from "../../../general/loading-fallback/LoadingContent";
import React from "react";

export interface AppPageLayoutProps {
  children?: React.ReactNode;
  pageTitle: AppPageTitleProps["title"];
  pageActions?: AppPageTitleProps["actions"];
  pageExplainer?: AppPageTitleProps["explainer"];
}

const AppPageLayout: React.FC<AppPageLayoutProps> = (props) => {
  return (
    <div className={`w-full flex flex-col gap-1`}>
      <AppPageTitle
        explainer={props.pageExplainer}
        title={props.pageTitle}
        actions={props.pageActions}
      />
      <CustomSuspense fallback={<LoadingContent />}>
        {props.children}
      </CustomSuspense>
    </div>
  );
};

export default AppPageLayout;
