import { Button, Card, Input, Space, Typography } from "antd";
import { useState } from "react";
import Separator from "src/common/components/general/Separator";
import { videoUrlDemoExamples } from "../videoPlayerDemoUtils";
import getVideoType from "src/common/components/media-players/video-player/utils/getVideoType";
import VideoPlayer from "src/common/components/media-players/video-player/VideoPlayer";

const VideoPlayerDemoDefault: React.FC = () => {
  const [videoUrl, setVideoUrl] = useState<string>("");

  return (
    <div className={`grid desktop:grid-cols-2 gap-1`}>
      <Card title={"Inputs"}>
        <Space className={`w-full`} direction={"vertical"}>
          <Input
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            placeholder="Enter video URL"
            allowClear
          />
          <Separator />
          <Space direction={"vertical"}>
            <Typography.Title level={5}>Example URLs</Typography.Title>
            {
              // Display example video URLs
              videoUrlDemoExamples.map((example) => (
                <Button
                  key={example.url}
                  onClick={() => {
                    setVideoUrl(example.url);
                  }}
                >
                  {example.title}
                </Button>
              ))
            }
          </Space>

          <Separator />
          <Typography>
            <Typography.Title level={5}>{"Video Type"}</Typography.Title>
            <Typography.Text code>
              {getVideoType(videoUrl) + ""}
            </Typography.Text>
          </Typography>
        </Space>
      </Card>

      <Card title={"VideoPlayer"}>
        <VideoPlayer videoUrl={videoUrl} />
      </Card>
    </div>
  );
};

export default VideoPlayerDemoDefault;
