import { Typography } from "antd";

import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { GCProjectReportsDownloadQuery } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import MorningManPowerReportWidget from "./MorningManPowerReportWidget";
import WorkerHourCountReportWidget from "./WorkerHourCountReportWidget";
import MonthlyReportWidget from "./MonthlyReportWidget";
import withProjectIdUrlParam from "../../../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../../../common/types/manual/ProjectIdProps";
import withAppPageLayout from "../../../../../../common/components/layouts/main/content/withAppPageLayout";

const GCProjectReportDownload: React.FunctionComponent<ProjectIdProps> = ({
  projectId,
}) => {
  const data = useLazyLoadQuery<GCProjectReportsDownloadQuery>(
    graphql`
      query GCProjectReportsDownloadQuery($projectId: uuid!) {
        project_subcontractor_connection(
          where: { project_id: { _eq: $projectId } }
        ) {
          edges {
            node {
              subcontractor_id
              subcontractor {
                name
              }
            }
          }
        }
        email_project_user_connection(
          first: 10000
          where: {
            project_id: { _eq: $projectId }
            type: { _eq: "morning_man_power_report" }
          }
        )
          @connection(
            key: "MorningManPowerReport_email_project_user_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              user {
                id
                pk: id @__clientField(handle: "pk")
                name
                email
              }
            }
          }
        }
        project_employee_connection(
          where: { project: { id: { _eq: $projectId } } }
          order_by: { employee: { user: { name: asc } } }
        ) {
          edges {
            node {
              employee {
                user {
                  id
                  pk: id @__clientField(handle: "pk")
                  name
                  email
                }
              }
            }
          }
        }
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              id
              morning_man_power_report_email_time
              timezone
              project_setting {
                id
                send_weekly_email
                require_gc_to_mark_observation_complete
                auto_email_mmr
                include_all_subs_mmr
              }
            }
          }
        }
        injury_body_part_affected_connection(
          order_by: { translation: { en: asc } }
        ) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        incident_type_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        injury_cause_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        injury_type_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        incident_type_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        injury_severity: incident_severity_connection(
          where: { severity_type: { _eq: "injury" } }
          order_by: { name: { en: asc } }
        ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
        }
        risk_level_connection(order_by: { weight: desc }) {
          edges {
            node {
              name
              value
              color_hex
            }
          }
        }
        observation_category_type_connection(order_by: { name: { en: asc } }) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
              observation_categories(order_by: { name: { en: asc } }) {
                id
                pk: id @__clientField(handle: "pk")
                name {
                  en
                }
              }
            }
          }
        }
      }
    `,
    {
      projectId,
    },
  );

  const subcontractorData = data.project_subcontractor_connection.edges;
  const subIds = new Array<string>();
  subcontractorData.map((t) => {
    subIds.push(t.node.subcontractor_id);
  });

  return (
    <div>
      <Typography>
        <Typography.Title level={5}>Report Templates</Typography.Title>
        <Typography.Paragraph>
          Select a template to generate your report
        </Typography.Paragraph>
      </Typography>

      <div className="flex flex-col gap-1.5">
        <MonthlyReportWidget projectId={projectId} data={data} />
        <MorningManPowerReportWidget projectId={projectId} data={data} />
        <WorkerHourCountReportWidget projectId={projectId} data={data} />
      </div>
    </div>
  );
};

export default withAppPageLayout(
  withProjectIdUrlParam(GCProjectReportDownload),
  {
    pageTitle: "Reports Download",
  },
);
