import React, { FunctionComponent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetHtWorkerDataQuery,
  useGetUniversalOrientationDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import SiteFeatureLayoutBody, {
  SiteFeatureLayoutStepProps,
} from "../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import { WorkerType } from "../../../utils/siteOrientationTypes";
import { auth } from "src/common/functions/firebase";
import SiteOrientationUniversalEmail from "../components/steps/email/SiteOrientationUniversalEmail";
import SiteOrientationUniversalFullName from "../components/steps/fullName/SiteOrientationUniversalFullName";
import SiteOrientationUniversalRole from "../components/steps/role/SiteOrientationUniversalRole";
import SiteOrientationUniversalTrade from "../components/steps/trade/SiteOrientationUniversalTrade";
import SiteOrientationUniversalBirthDate from "../components/steps/birthDate/SiteOrientationUniversalBirthDate";
import SiteOrientationUniversalUnion from "../components/steps/union/SiteOrientationInPersonUniversalUnion";
import SiteOrientationUniversalPhoneNumber from "../components/steps/phoneNumber/SiteOrientationUniversalPhoneNumber";
import { useGetUniversalOrientationDataQuery$data } from "src/common/types/generated/relay/useGetUniversalOrientationDataQuery.graphql";
import getUniqueCertsList from "../../../utils/getUniqueCertsList";
import dayjs from "dayjs";
import { fetchUniversalOrientationData } from "./utils/useGetUniversalOrientationData";
import OnlineOrientationCompletion from "../../../components/OnlineOrientationCompletion";
import SiteOrientationUniversalStepsCertifications from "../components/steps/certifications/SiteOrientationUniversalCertifications";
import SiteOrientationUniversalStepsViewer from "../components/steps/orientationViewer/SiteOrientationUniversalStepsViewer";
import SiteOrientationUniversalProfilePhoto from "../components/steps/profilePhoto/SiteOrientationUniversalProfilePhoto";
import {
  useSiteFeatureStepsNavigationDirection,
  useSiteFeatureStepsNavigationEffect,
} from "../../../../../common/components/layouts/steps-navigation-layout/siteFeatureRegistrationNavigationHooks";
import withSiteFeatureNavigationWrapper from "../../../../../common/components/layouts/steps-navigation-layout/withSiteFeatureNavigationWrapper";
import SiteOrientationUniversalStepHello from "../components/steps/hello/SiteOrientationUniversalStepHello";
import { useRelayEnvironment } from "react-relay/hooks";
import { withLanguageProvider } from "src/utility-features/i18n/context/languageContext";
import { withSiteOrientationUniversalProvier } from "./utils/siteOrientationUniversalProvider";
import { useSiteOrientationUniversalUser } from "./utils/siteOrientationUniversalHook";
import { useApolloClient } from "@apollo/client";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import { UniversalOrientationUser } from "./utils/siteOrientationUniversalContext";
import { AVAILABLE_LANGUAGES } from "../../../../../utility-features/i18n/languages";
import stringToLanguage from "../../../../../utility-features/i18n/language-utils/stringToLanguage";
import useLangStrings, {
  useCurrentLanguageState,
} from "../../../../../utility-features/i18n/context/languageHooks";
import identifyUserForTracking from "../../../../../utility-features/event-tracking/identifyUserForTracking";
import { inMemoryPersistence } from "firebase/auth";

const SiteOrientationUniversalOnly: FunctionComponent = () => {
  console.log("Render me");

  const [searchParams] = useSearchParams();
  const [skipUnion, setSkipUnion] = useState(!!searchParams.get("su"));

  const { data: formData } = useGetUniversalOrientationDataQuery();

  const [language, setLanguage] = useCurrentLanguageState();
  const langStrings = useLangStrings(language);

  const [user, setUser] = useSiteOrientationUniversalUser();

  const [cleanUser, setCleanUser] = useState<any>({ email: undefined });
  const resetUser = () => {
    console.log("reset user");
    setUser(cleanUser);
  };
  const environment = useRelayEnvironment();
  const apolloClient = useApolloClient();
  const { goToNextStep, goToPrevStep, nextStepDirection, clearStepDirection } =
    useSiteFeatureStepsNavigationDirection();
  useEffect(() => {
    if (auth.currentUser)
      auth.currentUser?.getIdTokenResult().then((d) => {
        if (d.claims?.role !== "employee")
          auth.setPersistence(inMemoryPersistence);
      });
    else auth.setPersistence(inMemoryPersistence);
  }, []);

  const setUserForUniversal = (
    userFound: useGetUniversalOrientationDataQuery$data["user_connection"]["edges"][number]["node"],
    fillNew: boolean = false,
  ) => {
    console.log("setUserForUniversal");
    if (userFound.worker) {
      const userData: WorkerType & { htWorkerCode?: string } = {
        userType: "worker",
        email: userFound.email,
        birthDate: {
          old: userFound.birth_date ? dayjs(userFound.birth_date) : undefined,
        },
        name: { old: userFound.name },
        phoneNumber: cleanUser.phoneNumber ?? userFound.phone_number,
        id: userFound.pk,
        prevCompanyId: userFound.worker.subcontractor_id,
        profileImg: userFound.profile_picture
          ? { old: userFound.profile_picture.url }
          : userFound.worker.pendingProfile.length > 0 &&
            userFound.worker.pendingProfile[0].front_image
          ? { old: userFound.worker.pendingProfile[0].front_image.url }
          : undefined,
        universalCompleted: userFound.universal_orientations.length > 0,
        isSubAdmin: !!userFound.subcontractor_employee,
        role: userFound.worker.current_worker_role,
        certifications: {
          old: getUniqueCertsList([
            ...userFound.worker.worker_certifications,
          ]).map((p) => ({
            title: p.name,
            id: p.id,
            expiresOn: p.expires_on ? dayjs(p.expires_on) : undefined,
            frontSidePresent: p.images > 0,
            backSidePresent: p.images > 1,
          })),
        },
        pendingCertsLength: userFound.worker.pendingCertificates.length,
        title: userFound.worker.worker_title
          ? {
              id: userFound.worker.worker_title_id!,
              name: userFound.worker.worker_title.translation.en,
            }
          : undefined,
        ...(userFound.worker.labor_union_chapter
          ? {
              unionChapterId: userFound.worker.labor_union_chapter_id!,
              unionId: userFound.worker.labor_union_chapter.labor_union_id,
            }
          : {}),
        htWorkerCode: cleanUser.htWorkerCode,
      };
      if (fillNew) {
        if (userData.birthDate) userData.birthDate.new = userData.birthDate.old;
        if (userData.name) userData.name.new = userData.name.old;
      }
      setUser(userData);
    }
  };

  const steps: SiteFeatureLayoutStepProps[] = [
    {
      id: "welcome",
      title: langStrings.strings.welcome,
      body: <SiteOrientationUniversalStepHello onNext={goToNextStep} />,
    },
    {
      id: "email",
      title: langStrings.strings.email,
      body: (
        <SiteOrientationUniversalEmail
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={async (workerId) => {
            if (auth.currentUser?.uid) {
              const returnData = await fetchUniversalOrientationData(
                environment,
                workerId,
              );
              if (returnData.user_connection.edges.length == 0)
                throw new Error(`Worker with id = ${workerId} does not exist`);
              setUserForUniversal(returnData.user_connection.edges[0].node);
            }
          }}
          onNoEmailPress={() => {
            resetUser();
            goToNextStep();
          }}
        />
      ),
    },
    {
      title: langStrings.strings.fullName,
      id: "full_name",
      body: (
        <SiteOrientationUniversalFullName
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      disabled: !!user?.name?.old,
    },
    {
      title: langStrings.strings.birthDate,
      id: "birth_date",
      body: (
        <SiteOrientationUniversalBirthDate
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={async (workerId) => {
            if (auth.currentUser?.uid) {
              const returnData = await fetchUniversalOrientationData(
                environment,
                workerId,
              );
              if (returnData.user_connection.edges.length == 0)
                throw new Error(`Worker with id = ${workerId} does not exist`);
              setUserForUniversal(returnData.user_connection.edges[0].node);
            }
          }}
        />
      ),
      disabled: !!user?.email,
    },
    // {
    //   title: "Employer",
    //   id: "employer",
    //   body: (
    //     <SiteOrientationUniversalEmployer
    //       onNext={goToNextStep} onBack={goToPrevStep}
    //       options={formData?.workerFormQuery?.projectSubs || []}
    //     />
    //   ),
    // },
    {
      title: langStrings.strings.roleLevel,
      id: "role",
      body: (
        <SiteOrientationUniversalRole
          onNext={goToNextStep}
          onBack={goToPrevStep}
          options={formData?.getUniversalOrientationDataQuery?.roles || []}
        />
      ),
    },
    {
      title: langStrings.strings.tradeTitle,
      id: "trade",
      body: (
        <SiteOrientationUniversalTrade
          onNext={goToNextStep}
          onBack={goToPrevStep}
          trades={formData?.getUniversalOrientationDataQuery?.titles || []}
        />
      ),
    },
    {
      title: langStrings.strings.union,
      id: "union",
      body: (
        <SiteOrientationUniversalUnion
          onNext={goToNextStep}
          onBack={goToPrevStep}
          unions={formData?.getUniversalOrientationDataQuery?.laborUnions || []}
        />
      ),
      disabled:
        skipUnion || user?.userType !== "worker" || !!user.unionChapterId,
    },
    {
      title: "Phone number",
      id: "phone_number",
      body: (
        <SiteOrientationUniversalPhoneNumber
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={async (workerId) => {
            if (auth.currentUser?.uid) {
              const returnData = await fetchUniversalOrientationData(
                environment,
                workerId,
              );
              if (returnData.user_connection.edges.length === 0) {
                throw new Error(`Worker with id = ${workerId} does not exist`);
              }
              setUserForUniversal(returnData.user_connection.edges[0].node);
            }
          }}
        />
      ),
    },

    ...(user?.id
      ? [
          {
            title: "Your photo",
            id: "photo",
            body: (
              <SiteOrientationUniversalProfilePhoto
                onNext={goToNextStep}
                onBack={goToPrevStep}
              />
            ),
          },
          {
            title: "Certifications",
            id: "certifications",
            body: (
              <SiteOrientationUniversalStepsCertifications
                onNext={goToNextStep}
                onBack={goToPrevStep}
              />
            ),
          },
          {
            title: "Orientation",
            body: (
              <SiteOrientationUniversalStepsViewer
                onNext={goToNextStep}
                onBack={goToPrevStep}
                type="universal"
              />
            ),
            id: "orientation",
          },
          {
            title: "Completed",
            body: (
              <OnlineOrientationCompletion
                userId={user.id}
                lang={language}
                type={"universal"}
              />
            ),
            id: "completed",
          },
        ]
      : []),
  ];

  const { currentStepId, goToStepById } = useSiteFeatureStepsNavigationEffect(
    nextStepDirection,
    clearStepDirection,
    steps,
  );

  const userId = searchParams.get("id");
  const htWorkerCode = searchParams.get("ht");
  const [loadingUserData, setLoadingUserData] = useState(!!userId);
  console.log("stepId = ", currentStepId);

  const { loading } = useGetHtWorkerDataQuery({
    variables: { input: { htWorkerCode: htWorkerCode! } },
    skip: !htWorkerCode,
    onCompleted(data) {
      const userData = data.htGetWorkerData;
      console.log("userData", userData);
      if (userData) {
        const defultUser: UniversalOrientationUser = {
          userType: "worker",
          email: userData.email,
          birthDate: {
            new: userData.birthdate ? dayjs(userData.birthdate) : undefined,
          },
          phoneNumber: userData.phone,
          htWorkerCode: htWorkerCode ?? undefined,
          name: {
            new: userData.name,
          },
        };
        setUser(defultUser);
        setCleanUser(defultUser);
        setSkipUnion(!!userData.skipUnion);
      }
    },
  });

  useEffect(() => {
    console.log("useEffect is called");
    const lang = stringToLanguage(searchParams.get("lang"));
    if (lang) {
      setLanguage(lang);
      //      goToStepById("email");
    }
    if (userId) {
      console.log(`fetching userId = ${userId}`);
      fetchUniversalOrientationData(environment, userId)
        .then(
          (res) => {
            console.log("result", res.user_connection.edges);
            if (res.user_connection.edges.length > 0) {
              setUserForUniversal(res.user_connection.edges[0].node);
              setCleanUser(res.user_connection.edges[0].node);
              goToStepById("role");
            }
          },
          (error) => {
            console.error("Data loading error", error);
          },
        )
        .finally(() => {
          setLoadingUserData(false);
        });
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      identifyUserForTracking({
        id: user.id,
        fullName: user.name?.new || user.name?.old,
        role: user.userType,
        email: user.email ?? undefined,
      });
    }
  }, [user]);

  if (loadingUserData || loading) {
    // TODO: add better UI
    return <LoadingContent />;
  }

  return (
    <SiteFeatureLayoutBody
      steps={steps}
      currentStepId={currentStepId || undefined}
      languagePicker={{
        availableLangs: AVAILABLE_LANGUAGES,
        selectedLang: language,
        onLangeChange: setLanguage,
      }}
    />
  );
};

export default withSiteFeatureNavigationWrapper(
  withLanguageProvider(
    withSiteOrientationUniversalProvier(SiteOrientationUniversalOnly),
  ),
);
