import { useSuspenseQuery } from "@apollo/client";
import {
  GetProjNameDocument,
  GetProjNameQuery,
  GetProjNameQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import withProjectIdUrlParam from "../../../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../../../common/types/manual/ProjectIdProps";

const GCProjectBreadcrumb: React.FC<ProjectIdProps> = (props) => {
  const projId = props.projectId;
  const { data } = useSuspenseQuery<
    GetProjNameQuery,
    GetProjNameQueryVariables
  >(GetProjNameDocument, { variables: { projId } });

  return <>{data.project_by_pk?.name} </>;
};

export default withProjectIdUrlParam(GCProjectBreadcrumb);
