import { IconCalendar, IconDownload } from "@tabler/icons";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import Button from "src/common/components/general/button/Button";
import CounterLabel from "src/common/components/general/CounterLabel";
import GCProjectReportsDailyCollapse from "../GCProjectReportsDailyCollapse";
import Icon from "src/common/components/general/Icon";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import getDateStr from "src/common/functions/dates/getDateStr";
import { useGenerateDailyLogsMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useBoolean } from "usehooks-ts";
import GCProjectReportsDailySummary, {
  GCProjectReportsDailySummaryProps,
} from "../summary/GCProjectReportsDailySummary";
import GCProjectReportsDailySummaryPhotos from "../summary/GCProjectReportsDailySummaryPhotos";
import GCProjectReportsDailyDayAddButton from "./GCProjectReportsDailyDayAddButton";
import GCProjectReportsDailyDayBody from "./GCProjectReportsDailyDayBody";
import GCProjectReportsDailySummaryEmailButton from "./GCProjectReportsDailyDayEmailButton";
import dayjs from "dayjs";

export interface GCProjectReportsDailyDayProps {
  day: dayjs.Dayjs;
  projectId: string;
  summary: GCProjectReportsDailySummaryProps;
  selectedTagIds: string[];
  selectedSubcontractorIds: string[];
  searchQuery: string;
}

const GCProjectReportsDailyDay: React.FC<
  PropsWithChildren<GCProjectReportsDailyDayProps>
> = ({
  day,
  projectId,
  summary,
  selectedTagIds,
  selectedSubcontractorIds,
  searchQuery,
}) => {
  const { setFalse: hide, value: expanded, setTrue: open } = useBoolean(false);
  const [generateLogs, { loading: generatingDailyLogs }] =
    useGenerateDailyLogsMutation();
  const handleDownloadClick = async () => {
    const { data: downloadedData } = await generateLogs({
      variables: {
        input: {
          projectId,
          date: day.format("YYYY-MM-DD"),
          type: "gc",
        },
      },
    });
    if (downloadedData?.generateDailyLogs) {
      downloadFromUrl(downloadedData?.generateDailyLogs);
    }
  };

  const totalOfAll =
    summary.deliveries.length +
    summary.entries +
    summary.manHours +
    summary.workersOriented +
    summary.permits +
    summary.ptps +
    summary.photos.length +
    summary.schedule +
    summary.subcontractors +
    summary.tags.length +
    summary.tbts +
    summary.weather +
    summary.workers;

  const noReports = !totalOfAll;
  return (
    <GCProjectReportsDailyCollapse
      hide={hide}
      expand={open}
      expanded={!noReports ? expanded : false}
      head={
        <div className="flex flex-row justify-center w-full">
          <div className="relative flex items-center justify-center gap-1">
            <div
              className={clsx(
                "relative flex flex-row items-center justify-center transform gap-1 left-0",
                !noReports && expanded && "left-1/2 -translate-x-1/2",
              )}
            >
              <div>
                <Icon icon={IconCalendar} />
              </div>
              <p className="">
                <span className="text-1.5">
                  {getDateStr(day, {
                    format: "full",
                    withYear: false,
                    withWeekday: true,
                  })}
                </span>
              </p>
            </div>
            <div
              className={clsx(
                expanded && "opacity-0 pointer-events-none",
                "transition-opacity",
              )}
            >
              <GCProjectReportsDailyDayAddButton {...{ day, projectId }} />
            </div>
            <div
              className={clsx(
                "flex flex-row items-center gap-1 transition-opacity",
                expanded && "opacity-0",
              )}
            >
              <CounterLabel count={summary.entries} label={`Entries`} />
              <CounterLabel
                count={summary.subcontractors}
                label={`Subcontractor`}
              />
              <CounterLabel count={summary.workers} label={`Workers`} />
              <CounterLabel count={summary.manHours} label={`Man Hours`} />
              <CounterLabel
                count={summary.photos.length}
                label={`Photos`}
                modalOnClick={
                  summary.photos.length
                    ? {
                        content: (
                          <GCProjectReportsDailySummaryPhotos
                            {...{ items: summary.photos }}
                          />
                        ),
                        title: `Photos`,
                      }
                    : undefined
                }
              />
              <CounterLabel count={summary.visitors} label={`Visitors`} />
            </div>
          </div>
        </div>
      }
    >
      {!noReports ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center justify-center gap-1">
            <Button
              icon={IconDownload}
              onClick={handleDownloadClick}
              loading={generatingDailyLogs}
              label={`Download Daily Report`}
            />
            <GCProjectReportsDailySummaryEmailButton {...{ day, projectId }} />
            <GCProjectReportsDailyDayAddButton {...{ day, projectId }} />
          </div>
          <GCProjectReportsDailySummary {...summary} />
          <GCProjectReportsDailyDayBody
            date={day}
            projectId={projectId}
            selectedTagIds={selectedTagIds}
            selectedSubcontractorIds={selectedSubcontractorIds}
            searchQuery={searchQuery}
          />
        </div>
      ) : undefined}
    </GCProjectReportsDailyCollapse>
  );
};

export default GCProjectReportsDailyDay;
