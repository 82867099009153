import GCProjectIncidentDashboard from "../GCProjectIncidentDashboard";
import GcIncidentManagement from "./GcIncidentManagement";

import { Navigate } from "react-router-dom";
import GCProjectIncidentsTable from "./GCProjectIncidentsTable";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import GCProjectIncidentTabs from "./GCProjectIncidentTabs";

const gcIncidentRoute: NestedRoute = {
  element: <GCProjectIncidentTabs />,
  handle: {
    breadcrumb: "Incidents",
  },
  children: [
    { path: "", element: <Navigate to="table" /> },
    { path: "charts", element: <GCProjectIncidentDashboard /> },
    {
      path: "table",
      element: <GCProjectIncidentsTable />,
      children: [{ path: ":incidentId", element: <GcIncidentManagement /> }],
    },
  ],
};

export default gcIncidentRoute;
