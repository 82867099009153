import { Card, Typography } from "antd";
import ChartWrapperCardBuilder from "./ChartWrapperCardBuilder";
import PieChartDemo from "./PieChartDemo";
import ColumnChartDemo from "./ColumnChartDemo";
import LineChartDemo from "./LineChartDemo";
import React from "react";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";
import DividerSplitWrapper from "../../../../../common/components/DividerSplitWrapper";

const ChartsDemo: React.FC = () => {
  return (
    <AppPageLayout pageTitle={"Charts Demo"}>
      <DividerSplitWrapper>
        {/* Intro */}
        <Card>
          <Typography>
            <Typography.Title>Charts:</Typography.Title>
            <Typography.Paragraph>
              All charts are wrapped with{" "}
              <Typography.Text code>ChartWrapperCard</Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <ol>
                <li>
                  <Typography.Text code>ShowPieChart.tsx</Typography.Text>
                </li>
                <li>
                  <Typography.Text code>ColumnChart.tsx</Typography.Text>
                </li>
                <li>
                  <Typography.Text code>LineChart.tsx</Typography.Text>
                </li>
              </ol>
            </Typography.Paragraph>
          </Typography>
        </Card>
        {/* Chart Wrapper Builder */}
        <ChartWrapperCardBuilder />
        {/* Pie Chart */}
        <PieChartDemo />
        {/* Column Chart */}
        <ColumnChartDemo />
        {/* Line Chart */}
        <LineChartDemo />
      </DividerSplitWrapper>
    </AppPageLayout>
  );
};

export default ChartsDemo;
