import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { GetGcDivisionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import OfficeTable from "./OfficeTable";
import getGcOrganizationUnitPath from "../utils/getGcOrganizationUnitPath";
import BusinessUnitTable from "./BusinessUnitTable";

type DivisionType = GetGcDivisionsQuery["gc_division"][number];

const DivisionTable: React.FC<{
  divisions: Array<DivisionType>;
  divisionTitle: string | null;
  officeTitle?: string | null;
  buTitle?: string | null;
  addButton: React.ReactNode;
}> = ({ officeTitle, addButton, divisionTitle, divisions, buTitle }) => {
  const expandables = divisions.filter(
    (p) => p.gc_offices?.length || p.gc_business_units?.length,
  );
  const navigate = useNavigate();
  return (
    <Table
      title={() => (
        <div className="text-1.25 flex items-center">
          {divisionTitle} ({divisions.length}){" "}
          <div className="text-0.9 ml-1">{addButton}</div>
        </div>
      )}
      // components={{ header: { row: () => null } }}
      onRow={(division) => ({
        onClick: () => {
          navigate(getGcOrganizationUnitPath("division", division.id));
        },
      })}
      dataSource={divisions}
      rowKey={(o) => o.id}
      columns={[
        {
          title: "",
          dataIndex: ["id"],
          render: (_, r, i) => i + 1,
          width: "10%",
        },
        {
          title: "name",
          dataIndex: ["name"],
          width: "30%",
        },
        {
          title: "Projects",
          dataIndex: ["projects_aggregate", "aggregate", "count"],
          render: (v) => v || "",
        },
      ]}
      expandable={{
        expandIcon: () => "",
        rowExpandable: (division) =>
          !!division.gc_offices?.length || !!division.gc_business_units?.length,
        expandedRowKeys: expandables.map((r) => r.id),
        expandedRowRender: (division) => (
          <>
            {!!buTitle && !!division.gc_business_units?.length && (
              <BusinessUnitTable
                businessUnits={division.gc_business_units || []}
                buTitle={buTitle}
              />
            )}
            {!!officeTitle && !!division.gc_offices?.length && (
              <OfficeTable
                offices={division.gc_offices || []}
                officeTitle={officeTitle}
              />
            )}
          </>
        ),
      }}
    />
  );
};
export default DivisionTable;
