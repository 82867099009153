import { IconArrowBackUp } from "@tabler/icons";
import React from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "src/common/components/layouts/tabs/CustomTabsRouted";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";

interface GCProjectPartnersProps {}

const GCProjectPartners: React.FunctionComponent<
  GCProjectPartnersProps
> = () => {
  const { projectId, subcontractorId, crewId, taskId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idFromGCProj = searchParams.get("idFromGCProj");
  const type = searchParams.get("type");
  const { userData } = useUserData();
  if (typeof projectId !== "string") {
    throw new Error("Project is not found");
  }

  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "table",
      label: "Subcontractors",
    },
    ...(userData?.employee?.general_contractor.employee_verification
      ? [
          {
            path: "employeeverification",
            label: "Employee Verification",
          },
        ]
      : []),
  ];

  const basePath = `/gce/hierarchy/project/${projectId}/subcontractors`;

  return subcontractorId ? (
    <div className="flex flex-col w-full ">
      {!idFromGCProj && (
        <div>
          <Button
            onClick={() => {
              type === "jha_log"
                ? navigate(-1)
                : navigate(
                    `/gce/hierarchy/project/${projectId}/subcontractors/${
                      crewId || taskId || idFromGCProj
                        ? subcontractorId +
                          (crewId
                            ? "/workers-and-crews"
                            : taskId
                            ? "/jhas"
                            : "")
                        : ""
                    }`,
                  );
            }}
            icon={IconArrowBackUp}
            label={"Back"}
            fullWidth={false}
          />
        </div>
      )}
      <Outlet />
    </div>
  ) : tabs.length > 1 ? (
    <CustomTabsRouted tabs={tabs} basePath={basePath} />
  ) : (
    <div className="flex w-full h-full">
      <Outlet />
    </div>
  );
};

export default withAppPageLayout(GCProjectPartners, {
  pageTitle: "Subcontractors",
});
