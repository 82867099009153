import { IconKey, IconLogout } from "@tabler/icons";
import { notification } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { roleVar } from "src/common/api/apollo/cache";
import BusinessCard from "src/common/components/BusinessCard";
import Button, {
  ButtonProps,
} from "src/common/components/general/button/Button";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import { auth } from "src/common/functions/firebase";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  useForgotPasswordMutation,
  useGetEmployeeUserByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import withAppPageLayout from "../../../../../common/components/layouts/main/content/withAppPageLayout";

interface GCProfileProps {
  additionalButtons?: ButtonProps[];
}

const GCProfile: React.FunctionComponent<GCProfileProps> = (props) => {
  const authUser = useAuthUser();
  const { data, loading } = useGetEmployeeUserByPkQuery({
    variables: { uid: authUser.uid },
  });
  const [forgotPassword] = useForgotPasswordMutation();
  const navigate = useNavigate();

  if (loading || !data?.user_by_pk) {
    return <LoadingContent />;
  }

  if (data.user_by_pk.role !== "employee")
    return (
      <div className="flex items-center justify-center">
        No Valid Login Found
      </div>
    );
  const fullName = data.user_by_pk.name;
  const userName = data.user_by_pk.username;
  const company = data.user_by_pk?.employee?.general_contractor.name;
  const position = data.user_by_pk?.employee?.employee_title?.name.clientText;
  const phoneNumber = data.user_by_pk.phone_number ?? undefined;
  const email = data.user_by_pk.email ?? undefined;

  return (
    <div className="flex flex-col gap-2">
      {fullName && (
        <BusinessCard
          {...{
            company,
            fullName,
            position,
            userName: userName ?? undefined,
            email,
            phoneNumber,
          }}
        />
      )}
      <div className="flex flex-row items-center gap-1">
        <Button
          label="Log out"
          icon={IconLogout}
          onClick={async () => {
            roleVar("none");
            await auth.signOut();
            navigate("/");
          }}
        ></Button>
        <Button
          onClick={async () => {
            if (data.user_by_pk?.email) {
              forgotPassword({
                variables: {
                  input: { loginIdentifier: data.user_by_pk!.email },
                },
              });
            }
            notification.open({
              message: "We emailed you a password reset link!",
            });
          }}
          icon={IconKey}
          label={`Update password`}
        ></Button>
      </div>
    </div>
  );
};

export default withAppPageLayout(GCProfile, { pageTitle: "Profile" });
