import { Button, Space, Typography } from "antd";
import { ButtonProps } from "antd/es/button";
import ExplanationIcon from "../../../ExplanationIcon";

export interface AppPageTitleProps {
  title?: React.ReactNode;
  actions?: ButtonProps[];
  explainer?: React.ReactNode;
}

const AppPageTitle: React.FC<AppPageTitleProps> = (props) => {
  return (
    <div className={`flex flex-row gap-1 justify-between items-center`}>
      <Typography.Title style={{ margin: "0" }} level={4}>
        {props.title}
        {props.explainer && (
          <>
            {" "}
            <ExplanationIcon content={props.explainer} />
          </>
        )}
      </Typography.Title>

      {!!props.actions?.length && (
        <Space direction={"horizontal"}>
          {props.actions.map((actionProps, i) => (
            <Button key={i + ""} {...actionProps} />
          ))}
        </Space>
      )}
    </div>
  );
};

export default AppPageTitle;
