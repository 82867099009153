import React from "react";
import { useUpdateGcEmployeeMutation } from "src/common/types/generated/apollo/graphQLTypes";
import GCHierarchyTeam from "./GCHierarchyTeam";

import GCEmployeesCommonProps from "../../utils/GCEmployeesCommonProps";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../../corporate-office/withCorporateAdminWrapper";
import getOrgEmployeeWhere from "../../utils/getOrgEmployeeWhere";

const GCCorporateUsers: React.FC<
  CorporateAdminWrapperProps & GCEmployeesCommonProps
> = ({ employee, hierarchyNodeManagersOnly }) => {
  const [updateGcEmployee] = useUpdateGcEmployeeMutation();

  return (
    <GCHierarchyTeam
      {...{
        getRemainingGcTeamVariables: {
          projectsToInviteWhere: {},
          gcEmployeeWhere: hierarchyNodeManagersOnly
            ? {
                is_corporate_admin: { _eq: true },
                general_contractor_id: {
                  _eq: employee.general_contractor.id,
                },
              }
            : getOrgEmployeeWhere("gc", employee.general_contractor.id),
        },

        title: "Corporate Team",
        onInviteSuccess: async (invitedUserIds) => {
          await updateGcEmployee({
            variables: {
              where: { uid: { _in: invitedUserIds } },
              _set: { is_corporate_admin: true },
            },
            optimisticResponse: {
              update_general_contractor_employee: {
                affected_rows: invitedUserIds.length,
                returning: invitedUserIds.map((uid) => ({
                  uid,
                  is_corporate_admin: true,
                  __typename: "general_contractor_employee",
                })),
              },
            },
          });
        },
        isAdmin: (employee) => employee.is_corporate_admin,
        onRemoveFromTeam: async (userId) => {
          await updateGcEmployee({
            variables: {
              where: { uid: { _eq: userId } },
              _set: { is_corporate_admin: false },
            },
            optimisticResponse: {
              update_general_contractor_employee: {
                affected_rows: 1,
                returning: [
                  {
                    uid: userId,
                    is_corporate_admin: false,
                    __typename: "general_contractor_employee",
                  },
                ],
              },
            },
          });
        },

        excludeEmployeeWhere: {
          is_corporate_admin: { _eq: true },
        },
      }}
    />
  );
};

export default withCorporateAdminWrapper(GCCorporateUsers);
