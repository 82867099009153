import dayjs from "dayjs";
import getDateStr from "./getDateStr";

const formatRelativeDate = (
  date: dayjs.Dayjs,
  defaultFormat?: string,
): string => {
  if (date.isToday()) return "Today";
  if (date.isTomorrow()) return "Tomorrow";
  if (date.isYesterday()) return "Yesterday";
  return date.format(defaultFormat || getDateStr(date, { format: "numeric" })); // Fallback format
};

export default formatRelativeDate;
