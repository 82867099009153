import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import { GCOrganizationUnitType } from "../gcHierarchyTreeTypes";

export const getOrganizationUnitType = (
  paramOrganizationUnitType: string | null | undefined,
) => {
  switch (paramOrganizationUnitType) {
    case "division":
      return "division";

    case "business_unit":
      return "business_unit";

    case "office":
      return "office";

    default:
      throw new Error("Invalid organization unit type");
  }
};

export interface OrganizationUnitProps {
  organizationUnitId: string;
  organizationUnitType: GCOrganizationUnitType;
}

const withOrganizationUnitWrapper =
  <Props extends object>(Component: React.FC<Props & OrganizationUnitProps>) =>
  (props: Props) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("Undeifned user id");

    const params = useParams();
    const organizationUnitType = getOrganizationUnitType(
      params.organizationUnitType,
    );
    if (!organizationUnitType)
      throw new Error("no valid organization unit type found");
    const organizationUnitId = params.organizationUnitId;
    if (!organizationUnitId) throw new Error("organizationUnitId not found");

    return (
      <Component {...{ organizationUnitType, organizationUnitId }} {...props} />
    );
  };

export default withOrganizationUnitWrapper;
