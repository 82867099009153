import * as React from "react";
import { Outlet } from "react-router-dom";
import AppNavigationLayout from "../../../../common/components/layouts/main/AppNavigationLayout";
import GCNavigationHierarchyMenu from "./components/hiearchy-navigation/GCNavigationHierarchyMenu";

export interface GCNavigationProps {}

const GCNavigation: React.FunctionComponent<GCNavigationProps> = () => {
  return (
    <AppNavigationLayout
      sideMenu={{
        body: (
          <div className={`w-full min-h-full`}>
            <GCNavigationHierarchyMenu />
          </div>
        ),
      }}
    >
      <Outlet />
    </AppNavigationLayout>
  );
};

export default GCNavigation;
