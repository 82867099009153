import { Spin } from "antd";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "./AuthProvider";
import { useApolloClient } from "@apollo/client";
import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { roleVar, userDataVar } from "src/common/api/apollo/cache";
import identifyUserForTracking from "../event-tracking/identifyUserForTracking";
import { auth } from "src/common/functions/firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { agcCompanyId } from "src/common/hooks/useCheckAGCUser";

export type UserContextDataType = {
  uid?: string;
  role?: string;
  employee?: NonNullable<GetUserQuery["user_by_pk"]>["employee"];
};

const UserDataContext = React.createContext<{
  userData: UserContextDataType | null;
  updateUserData: React.Dispatch<
    React.SetStateAction<UserContextDataType | null>
  >;
} | null>(null);

export const UserDataProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authData = useAuth();
  const [userData, setUserData] = useState<UserContextDataType | null>(null);
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const firstMount = useRef<boolean>(true);
  const [searchParams] = useSearchParams();
  const redirectLink = searchParams.get("path");
  const signOutAndThrowError = (errorMessage: string) => {
    roleVar("none");
    setUserData({});
    auth.signOut();
    throw new Error(errorMessage);
  };

  const redirectToPath = (userPath: string) =>
    navigate(redirectLink || userPath);
  useEffect(() => {
    const isFirstMount = firstMount.current;
    firstMount.current = false;
    const fetchUserData = async () => {
      setUserData(null);
      if (!authData.uid) {
        roleVar("none");
        setUserData({}); // todo add mode data here
        // TODO clear other vars
      } else {
        // TODO: change includes to startsWith (check that it does not add problems)
        const path = window.location.pathname;
        if (
          !path.startsWith("/im") &&
          !path.includes("/request") && /// should it match jhas/requested-task ?
          !path.startsWith("/singInLink") &&
          !path.startsWith("/sl") &&
          !path.startsWith("/invite") &&
          !path.startsWith("/upload") &&
          authData.role !== "employee" &&
          authData.role !== "customer_portal" &&
          authData.role !== "admin" &&
          authData.role !== "worker" &&
          authData.role !== "sf_employee" &&
          authData.role !== "worker" &&
          authData.role !== "subcontractor_employee" &&
          authData.role !== "pseudo_worker" &&
          (authData.role !== "mobilization_user" ||
            !window.location.pathname.startsWith("/sub-admin-mobilization")) &&
          (authData.role !== "quiz_qr_user" ||
            !path.startsWith("/orientation/quiz"))
        ) {
          auth.signOut();
          const newPath = path.trim() ? `/?path=${path}` : "/";  
          await navigate(newPath);
          return;
        }

        roleVar(authData.role);
        const userInfoResponse = await apolloClient.query<
          GetUserQuery,
          GetUserQueryVariables
        >({
          query: GetUserDocument,
          variables: {
            userId: authData.uid,
            includeEmployee: authData.role === "employee",
          },
        });
        const user_by_pk = userInfoResponse.data.user_by_pk;

        if (!user_by_pk) {
          signOutAndThrowError("User does not exist");
          return;
        }

        if (!!user_by_pk.terminated_at) {
          signOutAndThrowError(
            "The account has been terminated. Please contact GC for assistance.",
          );
        }

        identifyUserForTracking({
          role: authData.role,
          id: authData.uid,
          userName: user_by_pk.username ?? undefined,
          email: user_by_pk.email ?? undefined,
          fullName: user_by_pk.name ?? undefined,
        });

        userDataVar({
          id: user_by_pk.id,
          name: user_by_pk.name,
        });
        setUserData({
          uid: user_by_pk.id,
          role: user_by_pk.role,
          ...(user_by_pk.employee ? { employee: user_by_pk.employee } : {}),
        }); // todo add mode data here
        if (path !== "/" || !isFirstMount) {
          return;
        }
        switch (authData.role) {
          case "employee":
            // TODO: do not use hardcoded ID of dynamic entitites
            if (user_by_pk.employee?.general_contractor.id === agcCompanyId) {
              redirectToPath("/agc");
            } else {
              redirectToPath("/gce");
            }
            break;
          case "admin":
            redirectToPath("/admin");
            break;
          case "subcontractor_employee":
            redirectToPath("/sub");
            break;
          case "worker":
            redirectToPath("/worker");
            break;
          case "customer_portal":
            redirectToPath("/csr");
            break;
          case "sf_employee":
            redirectToPath("/sfe");
            break;
          default:
            break;
        }
      }
    };
    fetchUserData().catch((error) => {
      setUserData(() => {
        throw error;
      });
    });
  }, [authData.uid, authData.uid ? authData.role : null]);

  if (!userData) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full gap-1">
        <h1>Loading user data</h1>
        <Spin />
      </div>
    );
  } else
    return (
      <UserDataContext.Provider
        value={{ userData: userData, updateUserData: setUserData }}
      >
        {children}
      </UserDataContext.Provider>
    );
};

export const useUserData = () => {
  const user = useContext(UserDataContext);
  if (!user) {
    throw new Error("useUserData requires AuthProvider");
  }
  if (!user.userData)
    throw new Error("useUserData requires AuthProvider");

  // Return stable object (do not create new object on each return)
  return user as {userData: UserContextDataType, updateUserData: React.Dispatch<React.SetStateAction<UserContextDataType | null>> };
};
