import { Select, Form } from "antd";
import React from "react";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export type SelectOption = {
  label: string;
  value: string | number | undefined;
};

interface SelectFieldProps {
  value?: string | number | boolean | undefined;
  title: string;
  required?: boolean;
  options: Array<SelectOption>;
  onChange: (option: SelectOption | null) => Promise<void>;
  loading?: boolean;
  placeholder?: string;
}

const SelectField: React.FC<SelectFieldProps> = ({
  value,
  title,
  options,
  onChange,
  placeholder,
  loading = false,
  required = false,
}) => {
  return (
    <Form.Item label={title} layout="vertical" required={required}>
      <Select
        className="w-full"
        value={value}
        options={options}
        loading={loading}
        placeholder={placeholder}
        allowClear
        showSearch
        filterOption={getNormalSelectOptionsFilter}
        onChange={async (_, option) => {
          if (!option) {
            await onChange(null);
            return;
          }
          if (!Array.isArray(option)) {
            await onChange(option);
          } else option[0] && (await onChange(option[0]));
        }}
      />
    </Form.Item>
  );
};

export default SelectField;
