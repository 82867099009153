import {
  Button,
  Checkbox,
  Divider,
  Form,
  Popconfirm,
  Radio,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  GetGcPermitChecklistItemsDocument,
  useDeletePermitOptionsMutation,
  useInsertPermitOptionsMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import noPropagation from "src/common/functions/noPropagation";
import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitChecklistsSettingTables from "./basic/PermitChecklistsSettingTables";

const WorkingLadderSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  checklistGc,
  data,
  project,
}) => {
  const [workingLadderEditing, setWorkingLadderEditing] = useState(false);
  const [ladderPermitForm] = Form.useForm();
  const [insertPermitOptions, { loading: insertPermitOptionsLoading }] =
    useInsertPermitOptionsMutation();
  const [deletePermitOptions, { loading: deletePermitOptionsLoading }] =
    useDeletePermitOptionsMutation();

  const [updateProjectSetting] = useUpdateProjectSettingByPkForPermitMutation();
  const project_setting = project?.project_setting;
  const initialWorkingLadderType = data.gcWorkingLadderPermitTypes.map(
    (type) => type.value,
  );
  const initialLadderCapacity = data.gcLadderPermitCapcity.map(
    (type) => type.value,
  );
  const initialWorkingLadderHeight = data.gcWorkingLadderPermitHeight.map(
    (type) => type.value,
  )[0];

  const ladderCapacityOptions = ["225lbs", "250lbs", "300lbs", "375lbs"];
  const workingLadderHeightOptions = [
    "0 feet",
    "1 feet",
    "2 feet",
    "3 feet",
    "4 feet",
    "5 feet",
    "6 feet",
  ];

  const workingLadderTypesOptions = [
    "A-Frame",
    "Extension",
    "Job Made",
    "Platform",
    "Podium",
    "Step",
    "Tripod",
    "Trestle",
    "Other",
  ];

  return (
    <>
      <StyledContent>
        {!workingLadderEditing && (
          <>
            <div className="flex flex-col mb-2">
              <p style={{ fontWeight: 400 }}>
                {initialWorkingLadderType.length > 0
                  ? "Ladders that are allowed on the project and require a Permit"
                  : "No Ladder Types selected"}
              </p>
              <div className="-mx-0.25 mt-0.5 ">
                {initialWorkingLadderType.map((type) => (
                  <span
                    className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                    key={type}
                  >
                    {type}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex flex-col mb-2">
              <p style={{ fontWeight: 400 }}>
                {initialLadderCapacity.length > 0
                  ? "The weight capacity allowed for ladders on your project"
                  : "No Weight Capacity Selected"}
              </p>
              <div className="-mx-0.25 mt-0.5 ">
                {initialLadderCapacity.map((c) => (
                  <span
                    className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                    key={c}
                  >
                    {c}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex flex-col mb-2">
              <p style={{ fontWeight: 400 }}>
                Height fall protection is required if working above{" "}
                {initialWorkingLadderHeight ?? 6} feet
              </p>
            </div>
          </>
        )}
        {workingLadderEditing && (
          <Form
            form={ladderPermitForm}
            layout="vertical"
            initialValues={{ modifier: "public" }}
            onSubmitCapture={async (e) => {
              const workingLadderTypes: string[] =
                ladderPermitForm.getFieldValue("workingLadderTypes");
              const ladderCapacity: string[] =
                ladderPermitForm.getFieldValue("ladderCapcity");
              const workingLadderHeight: string =
                ladderPermitForm.getFieldValue("workingLadderHeight");
              const insertPermitOptionValues: Array<{
                type: string;
                value: string;
              }> = [
                ...workingLadderTypes
                  .filter(
                    (e) => !initialWorkingLadderType.find((ie) => ie === e),
                  )
                  .map((e) => ({
                    type: "working_ladder_type",
                    value: e,
                  })),
                ...ladderCapacity
                  .filter((e) => !initialLadderCapacity.find((ie) => ie === e))
                  .map((e) => ({
                    type: "ladder_capacity",
                    value: e,
                  })),
              ];
              if (workingLadderHeight !== initialWorkingLadderHeight) {
                insertPermitOptionValues.push({
                  type: "working_ladder_height",
                  value: workingLadderHeight,
                });
              }
              const deletePermitOptionValues: Array<{
                type: string;
                value: string;
              }> = [
                ...initialWorkingLadderType
                  .filter((ie) => !workingLadderTypes.find((e) => ie === e))
                  .map((ie) => ({
                    type: "working_ladder_type",
                    value: ie,
                  })),
                ...initialLadderCapacity
                  .filter((ie) => !ladderCapacity.find((e) => ie === e))
                  .map((ie) => ({
                    type: "ladder_capacity",
                    value: ie,
                  })),
              ];
              if (
                initialWorkingLadderHeight &&
                workingLadderHeight !== initialWorkingLadderHeight
              ) {
                deletePermitOptionValues.push({
                  type: "working_ladder_height",
                  value: initialWorkingLadderHeight,
                });
              }
              await insertPermitOptions({
                variables: {
                  objects: insertPermitOptionValues.map((e) => ({
                    type: e.type,
                    value: e.value,
                    general_contractor_id: queryVariables.gcId,
                  })),
                },
              });
              await deletePermitOptions({
                variables: {
                  where: {
                    _or: deletePermitOptionValues.map((e) => ({
                      type: { _eq: e.type },
                      value: { _eq: e.value },
                      general_contractor_id: { _eq: queryVariables.gcId },
                    })),
                  },
                },
              });
              const newData: typeof data = {
                ...data,
                gcWorkingLadderPermitTypes: workingLadderTypes.map((e) => ({
                  value: e,
                })),
                gcLadderPermitCapcity: ladderCapacity.map((e) => ({
                  value: e,
                })),
                gcWorkingLadderPermitHeight: [{ value: workingLadderHeight }],
              };
              gcPermitCheckListItems.client.writeQuery({
                query: GetGcPermitChecklistItemsDocument,
                variables: queryVariables,
                data: newData,
              });
              setWorkingLadderEditing(false);
            }}
          >
            <Form.Item
              name="workingLadderTypes"
              label="Select the ladders that are allowed on the project and require a Permit"
              initialValue={initialWorkingLadderType}
            >
              <Select
                listItemHeight={10}
                mode="multiple"
                showArrow
                bordered
                style={{ width: 400 }}
                options={workingLadderTypesOptions.map((o) => ({
                  value: o,
                  key: o,
                  label: o,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="ladderCapcity"
              label="Select the weight capacity allowed for ladders on your project "
              initialValue={initialLadderCapacity}
            >
              <Select
                listItemHeight={10}
                mode="multiple"
                showArrow
                bordered
                style={{ width: 400 }}
                options={ladderCapacityOptions.map((o) => ({
                  value: o,
                  key: o,
                  label: o,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="workingLadderHeight"
              label="Select what height fall protection is required if working above:"
              initialValue={initialWorkingLadderHeight}
            >
              <Select
                listItemHeight={4}
                showArrow
                bordered
                style={{ width: 400 }}
                options={workingLadderHeightOptions.map((o, index) => ({
                  value: index.toString(),
                  key: index.toString(),
                  label: o,
                }))}
              />
            </Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  insertPermitOptionsLoading || deletePermitOptionsLoading
                }
              >
                Done
              </Button>
              <Button onClick={() => setWorkingLadderEditing(false)}>
                Cancel
              </Button>
            </Space>
          </Form>
        )}
        {!workingLadderEditing && (
          <Popconfirm
            title={`Changes to these questions will be applied company wide on all projects. Are you sure?`}
            onConfirm={() => setWorkingLadderEditing(true)}
            onCancel={() => noPropagation()}
            okText="Yes"
            cancelText="Cancel"
          >
            <Button type={"primary"} onClick={() => noPropagation()}>
              Edit Ladder Permit Configurations
            </Button>
          </Popconfirm>
        )}
        <Divider />
        {project_setting && (
          <>
            <StyledContent>
              <Checkbox
                checked={project_setting.worker_can_add_working_ladder_number}
                onChange={async (e) => {
                  const set = {
                    worker_can_add_working_ladder_number: e.target.checked,
                  };
                  console.log(project_setting, "2");
                  await updateProjectSetting({
                    variables: {
                      project_id: project_setting.project_id,
                      _set: set,
                    },
                    optimisticResponse: {
                      update_project_setting_by_pk: {
                        ...project_setting,
                        ...set,
                      },
                    },
                  });
                  console.log(project_setting, "3");
                }}
              >
                Worker enters ladder ID or Tag # during permit submission
              </Checkbox>
            </StyledContent>
            <StyledContent>
              Permit is valid for{" "}
              <Radio.Group
                options={[
                  { value: "week", label: "Week" },
                  { value: "day", label: "Day" },
                ]}
                value={project_setting.working_ladder_max_duration}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const set = {
                    working_ladder_max_duration: newValue,
                  };
                  updateProjectSetting({
                    variables: {
                      project_id: project_setting.project_id,
                      _set: set,
                    },
                    optimisticResponse: {
                      update_project_setting_by_pk: {
                        ...project_setting,
                        ...set,
                      },
                    },
                  });
                }}
              />
            </StyledContent>
            <StyledContent>
              <Checkbox
                checked={project_setting.require_ladder_inspection_daily}
                onChange={(e) => {
                  const set = {
                    require_ladder_inspection_daily: e.target.checked,
                  };
                  updateProjectSetting({
                    variables: {
                      project_id: project_setting.project_id,
                      _set: set,
                    },
                    optimisticResponse: {
                      update_project_setting_by_pk: {
                        ...project_setting,
                        ...set,
                      },
                    },
                  });
                }}
              >
                Require ladder inspection to be done each day in the mobile app
              </Checkbox>
            </StyledContent>
          </>
        )}
        <PermitChecklistsSettingTables
          {...{
            checklistGc,
            data,
            project,
            gcPermitCheckListItems,
            queryVariables,
            checklistItemsType: "ladder_non_access",
          }}
        />
      </StyledContent>
    </>
  );
};
export default WorkingLadderSettings;
