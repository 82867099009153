import { useNavigate, useParams } from "react-router-dom";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../common/components/layouts/tabs/CustomTabsRouted";
import AppPageLayout from "../../../../common/components/layouts/main/content/AppPageLayout";
import { SettingOutlined } from "@ant-design/icons";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";

const GCObservationNavigation: React.FC<ProjectIdProps> = (props) => {
  const navigate = useNavigate();
  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "charts",
      label: "Overview",
    },
    {
      path: "observations",
      label: "Observations",
    },
    {
      path: "audits",
      label: "Audits",
    },
  ];
  const projectId = useParams().projectId;

  const basePath = `/gce/hierarchy/project/${projectId}/observations`;

  return (
    <AppPageLayout
      pageTitle={"Observations"}
      pageActions={[
        {
          children: "Settings",
          icon: <SettingOutlined />,
          onClick: () => {
            navigate(getGcProjectSettingsPath(props.projectId, "observations"));
          },
        },
      ]}
    >
      <CustomTabsRouted tabs={tabs} basePath={basePath} />
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCObservationNavigation);
