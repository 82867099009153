import NestedRoute from "src/common/types/manual/NestedRoute";
import React from "react";
import GCProjectReportsDaily from "./GCProjectReportsDaily";

const dailyRoute: NestedRoute = {
  element: <GCProjectReportsDaily />,
  handle: {
    breadcrumb: "Daily Reports",
  },
};

export default dailyRoute;
