import React, { useState } from "react";
import CreateCustomInvoiceModal from "./CreateCustomInvoiceModal";
import EditInvoiceSettingsModal from "./EditInvoiceSettingsModal";
import {
  GetSubctonractorProjectPricesDocument,
  useCreateCustomSubInvoiceMutation,
  useGetSubctonractorProjectPricesQuery,
  useStripeCreateAccountSubMutation,
  useUpdateSubcontractorBillingSettingsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Button, message, Space, Switch } from "antd";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import SelectStripeEmailModal from "src/common/components/dialogs/SelectStripeEmailModal";
import SubcontractorProjectPricingTable, {
  getProjectPriceDescription,
  getProjectSubBillingSettings,
} from "src/root/routes/views/admin/data/subcontractors/SubcontractorProjectPricingTable";
import StyledContent from "src/common/components/layouts/StyledContent";
import { stripeDashboardLink } from "src/common/functions/stripeDashboardUrl";

const StripeIdStyle: React.CSSProperties = {
  fontSize: "14px",
  borderRadius: "5px",
  boxShadow: "rgb(235, 238, 241) 0px 0px 0px 1px inset",
  padding: "2px 5px",
  backgroundColor: "rgb(246, 248, 250)",
  fill: "rgb(84, 89, 105)",
  color: "rgb(65, 69, 82)",
};

function parseCurrency(value: string): number {
  return Math.round(parseFloat(value) * 100) | 0;
}

interface UserEmail {
  name: string;
  email: string;
}

interface AdminSubcontractorBilling {
  subcontractorId: string;
  employeeEmails: Array<UserEmail>;
}

const AdminSubcontractorBillingContent: React.FC<AdminSubcontractorBilling> = ({
  subcontractorId,
  employeeEmails,
}) => {
  const {
    data: prices,
    loading: pricesLoading,
    error: priceError,
  } = useGetSubctonractorProjectPricesQuery({
    variables: {
      subId: subcontractorId,
    },
  });

  const refetchOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetSubctonractorProjectPricesDocument,
        variables: {
          subId: subcontractorId,
        },
      },
    ],
  };
  const [updateBillingSettings, { loading: updatingBillingSettings }] =
    useUpdateSubcontractorBillingSettingsMutation(refetchOptions);

  const [createStripeAccountSub, { loading: creatingStripeAccount }] =
    useStripeCreateAccountSubMutation(refetchOptions);
  const [createStripeSubInvoice, { loading: creatingInvoice }] =
    useCreateCustomSubInvoiceMutation();

  const [autoCreateInvoices, setAutoCreateInvoices] = useState(false);
  const [selectEmailVisible, setSelectEmailVisible] = useState(false);
  const [createInvoiceVisible, setCreateInvoiceVisible] = useState(false);
  const [invoiceSettingsVisible, setInvoiceSettingsVisible] = useState(false);

  if (pricesLoading) {
    return <LoadingContent />;
  }
  if (priceError) {
    return (
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {priceError?.toString()}
      </Space>
    );
  }
  const subcontractor = prices?.subcontractor_by_pk;
  if (!subcontractor) {
    return (
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        Subcontractor does not exist.
      </Space>
    );
  }
  console.log(
    "render :",
    pricesLoading,
    updatingBillingSettings,
    autoCreateInvoices,
  );

  const { billing_settings } = subcontractor;
  const billing_active = !!billing_settings?.default_billing_email;
  const autoCreateInvoicesValue =
    pricesLoading || updatingBillingSettings
      ? autoCreateInvoices
      : billing_settings?.auto_generate_invoices;
  const invoiceSettings = {
    description: billing_settings?.invoice_description,
    footer: billing_settings?.invoice_footer,
    default_billing_email: billing_settings?.default_billing_email,
  };

  const project_prices = subcontractor.subcontractor_projects.map(
    (sp) => sp.project,
  );
  return (
    <>
      <SelectStripeEmailModal
        users={employeeEmails}
        companyName={subcontractor.name}
        visible={selectEmailVisible}
        onCancel={() => {
          setSelectEmailVisible(false);
        }}
        onSelect={(email) => {
          setSelectEmailVisible(false);
          createStripeAccountSub({
            variables: {
              input: {
                sub_id: subcontractorId,
                account_email: email,
              },
            },
          })
            .then((res) => {
              const errors =
                res.data?.stripeCreateAccountSub.filter((res) => res.error) ||
                [];
              if (errors.length > 0) {
                const success =
                  res.data?.stripeCreateAccountSub.filter(
                    (res) => !res.error,
                  ) || [];
                const msg =
                  `${success.length} created, ${errors.length} errors:\n` +
                  errors
                    .map((v) => `${v.project_name ?? ""}: ${v.error ?? ""}`)
                    .join("\n");
                message.error(msg);
              }
            })
            .catch((e) => {
              message.error("Failed to create stripe customer: " + String(e));
            });
        }}
      />
      {createInvoiceVisible && (
        <CreateCustomInvoiceModal
          companyName={subcontractor.name}
          visible={createInvoiceVisible}
          projects={project_prices
            .filter((p) => {
              const settings = getProjectSubBillingSettings(p);
              return (
                settings &&
                settings.payment_model === "sub" &&
                settings.sub_price_type !== "free"
              );
            })
            .map((p) => ({
              id: p.id,
              name: p.name,
              gc: p.general_contractor.name,
              price: getProjectPriceDescription(p),
            }))}
          invSettings={invoiceSettings}
          onSelect={async (
            startDate,
            monthCount,
            projectIds,
            finalize,
            description,
            footer,
            excludeZeroLines,
          ) => {
            return createStripeSubInvoice({
              variables: {
                input: {
                  subId: subcontractorId,
                  startDate: startDate.format("YYYY-MM-DD"),
                  monthCount,
                  projectIds,
                  finalize,
                  description,
                  footer,
                  excludeZeroLines,
                },
              },
            }).then(
              (result) => {
                setCreateInvoiceVisible(false);
                const invoiceId =
                  result.data?.stripeCreateSubInvoice.stripeInvoiceId;
                if (invoiceId)
                  message.success(
                    <span>
                      <a
                        target="_blank"
                        href={`${stripeDashboardLink}/invoices/${invoiceId}`}
                      >
                        {projectIds.length}{" "}
                        {projectIds.length > 1 ? "invoices" : "invoice"}
                      </a>{" "}
                      {projectIds.length > 1 ? "have" : "has"} been created
                      successfully
                    </span>,
                    30,
                  );
              },
              (error) => {
                message.error(String(error));
              },
            );
          }}
          onCancel={() => {
            setCreateInvoiceVisible(false);
          }}
        />
      )}
      {billing_active && invoiceSettingsVisible && (
        <EditInvoiceSettingsModal
          companyName={subcontractor.name}
          visible={invoiceSettingsVisible}
          isGC={false}
          onSave={async (values) => {
            await updateBillingSettings({
              variables: {
                sub_id: subcontractorId,
                set: {
                  invoice_description: values.description,
                  invoice_footer: values.footer,
                  default_billing_email: values.default_billing_email,
                },
              },
            });
            setInvoiceSettingsVisible(false);
          }}
          onCancel={() => {
            setInvoiceSettingsVisible(false);
          }}
          initValues={invoiceSettings}
          users={employeeEmails}
        />
      )}
      {!billing_active ? (
        <Button
          type="primary"
          style={{ marginTop: "10px" }}
          loading={creatingStripeAccount}
          onClick={() => setSelectEmailVisible(true)}
        >
          Enable billing
        </Button>
      ) : (
        <div>
          <div style={{ padding: "10px" }}>
            Default billing email:{" "}
            <span style={StripeIdStyle}>
              {billing_settings.default_billing_email}
            </span>
          </div>
          <div style={{ padding: "10px" }}>
            <Button
              onClick={() => {
                setCreateInvoiceVisible(true);
              }}
            >
              Create custom invoice
            </Button>
            &nbsp;
            <Button
              className="pl-1"
              onClick={() => {
                setInvoiceSettingsVisible(true);
              }}
            >
              Edit invoice settings
            </Button>
          </div>
          <div style={{ padding: "10px" }}>
            <Switch
              checked={autoCreateInvoicesValue}
              onChange={(checked) => {
                setAutoCreateInvoices(checked);
                updateBillingSettings({
                  variables: {
                    sub_id: subcontractorId,
                    set: {
                      auto_generate_invoices: checked,
                    },
                  },
                }).catch((error) => {
                  message.error("Error: " + String(error));
                });
              }}
            />{" "}
            Create invoices automatically at the end of each month
          </div>
          <SubcontractorProjectPricingTable
            loading={pricesLoading}
            dataSource={subcontractor.subcontractor_projects}
            onCreateCustomer={async (item) => {
              await createStripeAccountSub({
                variables: {
                  input: {
                    sub_id: subcontractorId,
                    project_id: item.project.id,
                  },
                },
              })
                .then((res) => {
                  const errors =
                    res.data?.stripeCreateAccountSub.filter((v) => v.error) ||
                    [];
                  if (errors.length > 0) {
                    throw new Error(
                      errors
                        .map(
                          (v) => `${v.project_name ?? "GC"}: ${v.error ?? ""}`,
                        )
                        .join(","),
                    );
                  }
                })
                .catch((e) => {
                  message.error(
                    "Failed to create stripe customer: " + String(e),
                  );
                });
            }}
          />
          {/*             <div style={{padding:'10px'}}>Price for new projects:
        <div style={{padding:'10px 10px 10px 20px'}}>
         <div>
            <Space direction="horizontal"> 
              Price type:
              <Select style={{width: '150px'}}>
                <Select.Option value="custom">Custom Price</Select.Option>
                <Select.Option value="stripe">Stripe Price</Select.Option>
              </Select>                                      
            </Space>
          </div> 
          <div>
          <Space direction="horizontal"> {              
              priceEditing || updatingBillingSettings ? 
                <Input ref={firstPriceRef} disabled={updatingBillingSettings} onKeyDown={(e) => {if (e.key === 'Enter') applyPrice()}} style={{width: '100px'}} defaultValue={formatCurrency(billing_settings.worker_price_first)}></Input> : formatCurrency(billing_settings.worker_price_first) 
              } USD - first user, {
                priceEditing? <Input ref={secondPriceRef} disabled={updatingBillingSettings} onKeyDown={(e) => {if (e.key === 'Enter') applyPrice()}} style={{width: '100px'}} defaultValue={formatCurrency(billing_settings.worker_price_others)}></Input> : formatCurrency(billing_settings.worker_price_others) 
              } USD for second and others {
                priceEditing? <>
                  <Button loading={updatingBillingSettings} onClick={applyPrice}>Apply</Button> 
                  <Button loading={updatingBillingSettings} onClick={() => {setPriceEditing(false)}}>Cancel</Button></>
                :
                <Button onClick={() => {setPriceEditing(true)}}>Edit Price</Button>
              }
            </Space>
          </div> 
        </div>
      </div>*/}
        </div>
      )}
    </>
  );
};

const AdminSubcontractorBillingSection: React.FC<AdminSubcontractorBilling> = (
  props,
) => (
  <StyledContent>
    <h3 style={{ paddingBottom: "20px", fontWeight: "bold", fontSize: "17px" }}>
      Billing settings
    </h3>
    <AdminSubcontractorBillingContent {...props} />
  </StyledContent>
);

export default AdminSubcontractorBillingSection;
