import {
  useUpdateIncidentMutation,
  GetIncidentByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const useUpdateMonetarySeverity = () => {
  const [updateIncident, { loading: updateIncidentLoading }] =
    useUpdateIncidentMutation();
  const authUser = useAuthUser();

  const updateMonetarySeverity = async (
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    value: string | null,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      monetary_severity_id: value,
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateIncident({
      variables: {
        incidentId: incident.id,
        _set: {
          monetary_severity_id: value,
        },
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "monetary-severity-update",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          id: incident.id,
          monetary_severity_id: value,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  return [updateMonetarySeverity, { loading: updateIncidentLoading }] as const;
};

export default useUpdateMonetarySeverity;
