import * as uuid from "uuid";
import { useSuspenseQuery } from "@apollo/client";
import { Button, Select, Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  GetObsNotificationsDocument,
  GetObsNotificationsQuery,
  GetObsNotificationsQueryVariables,
  useDeleteObsNotifMutation,
  useUpdateObsNotifMutation,
  useUpdateObsNotifyUsersMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import CreateNewObsNotifModal from "./CreateNewObsNotifModal";
import { useMemo, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export const notifToggleLabels = {
  notify_selected_user: "Send to specific people in your organization",
  notify_proj_team: "Send to all project Team Members",
  notify_sub_poc: "Send to Subcontractors POCs",
  notify_worker: "Send to the Workers/Foremen assigned to the Observation",
};

export type ToggleFields = keyof typeof notifToggleLabels;
const notifToggleKeys: ToggleFields[] = [
  "notify_proj_team",
  "notify_sub_poc",
  "notify_worker",
  "notify_selected_user",
];
const GCObsNotifications: React.FC<{
  queryVariables: GetObsNotificationsQueryVariables;
  gcId: string;
}> = ({ queryVariables, gcId }) => {
  const { data } = useSuspenseQuery<
    GetObsNotificationsQuery,
    GetObsNotificationsQueryVariables
  >(GetObsNotificationsDocument, {
    variables: queryVariables,
  });
  const [updateObsNotif] = useUpdateObsNotifMutation();
  const [updateObsNotifyUsers] = useUpdateObsNotifyUsersMutation();
  const [deleteObsNotif] = useDeleteObsNotifMutation();
  const deleteNotifyee = async (
    userId: string,
    row: GetObsNotificationsQuery["observation_notification"][number],
  ) => {
    const foundNotifyee = row.obs_notify_users.find(
      (notifyee) => notifyee.user_id === userId,
    );
    if (!foundNotifyee) throw new Error("Notifyee not found");
    await updateObsNotifyUsers({
      variables: {
        deleteWhere: { id: { _eq: foundNotifyee.id } },
        objects: [],
      },
      optimisticResponse: {
        insert_obs_notify_user: { returning: [] },
        delete_obs_notify_user: {
          returning: [{ __typename: "obs_notify_user", id: foundNotifyee.id }],
        },
      },
      update: (cache, returning) => {
        const deletedId =
          returning.data?.delete_obs_notify_user?.returning[0]?.id;
        if (!deletedId)
          throw new Error("Server returning null for deleted notifyee");
        cache.modify<typeof row>({
          id: cache.identify(row),
          fields: {
            obs_notify_users(existing = [], { readField }) {
              return existing.filter((notifyee) => {
                const id = readField("id", notifyee);
                return typeof id === "string" && id !== deletedId;
              });
            },
          },
        });
      },
    });
  };
  const insertNotifyee = async (
    userId: string,
    row: GetObsNotificationsQuery["observation_notification"][number],
  ) => {
    const notifyeeId = uuid.v4();
    const obj = {
      id: notifyeeId,
      observation_notification_id: row.id,
      user_id: userId,
    };
    await updateObsNotifyUsers({
      variables: { objects: [obj], deleteWhere: { id: { _is_null: true } } },
      optimisticResponse: {
        insert_obs_notify_user: {
          returning: [{ __typename: "obs_notify_user", ...obj }],
        },
      },
      update: (cache, returning) => {
        const insertedObj = (returning.data?.insert_obs_notify_user
          ?.returning || [])[0];
        if (!insertedObj) throw new Error("No new inserted entry found");
        cache.modify<typeof row>({
          id: cache.identify(row),
          fields: {
            obs_notify_users(existing = [], { toReference }) {
              const newObjRef = toReference(insertedObj);
              if (!newObjRef) return existing;
              return [...existing, newObjRef];
            },
          },
        });
      },
    });
  };
  const pendingRiskLevelOptions = useMemo(
    () =>
      data.risk_level.filter(
        (risk) =>
          !data.observation_notification.some(
            (notif) => notif.risk_level.value === risk.value,
          ),
      ),
    [data.observation_notification, data.risk_level],
  );
  const selectableUsers = useMemo(
    () =>
      data.user.map((u) => ({
        label: u.name + (u.email ? `, ${u.email}` : ""),
        value: u.id,
      })),
    [data.user],
  );
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const getToggleColumn = (
    field: ToggleFields,
  ): ColumnsType<
    GetObsNotificationsQuery["observation_notification"][number]
  >[number] => ({
    title: notifToggleLabels[field],
    dataIndex: [field],
    key: field,
    render: (val, row) => (
      <Switch
        value={val}
        onChange={async (newVal) =>
          await updateObsNotif({
            variables: { id: row.id, _set: { [field]: newVal } },
            optimisticResponse: {
              update_observation_notification_by_pk: {
                ...row,
                [field]: newVal,
              },
            },
          })
        }
      />
    ),
  });
  const deleteNotif = async (id: string) =>
    await deleteObsNotif({
      variables: { id },
      optimisticResponse: {
        delete_observation_notification_by_pk: {
          __typename: "observation_notification",
          id,
        },
      },
      update: (cache, { data }) => {
        const deletedId = data?.delete_observation_notification_by_pk?.id;
        if (!deletedId)
          throw new Error("Server return empty response for deleting");
        cache.modify<GetObsNotificationsQuery>({
          fields: {
            observation_notification(existing = [], { readField }) {
              return existing.filter(
                (entry) => readField("id", entry) !== deletedId,
              );
            },
          },
        });
      },
    });
  return (
    <>
      <CreateNewObsNotifModal
        riskLevels={pendingRiskLevelOptions}
        gcId={gcId}
        open={openCreateModal}
        selectableUsers={selectableUsers}
        onClose={() => setOpenCreateModal(false)}
        onFinish={() => setOpenCreateModal(false)}
      />
      <Table
        bordered
        title={() =>
          pendingRiskLevelOptions.length ? (
            <div className="flex flex-row gap-1">
              <Button type="primary" onClick={() => setOpenCreateModal(true)}>
                Add New
              </Button>
            </div>
          ) : null
        }
        dataSource={data.observation_notification}
        columns={[
          {
            title: "Risk Level",
            dataIndex: ["risk_level", "name"],
            key: "risk_level",
            render: (risk, r) => (
              <div
                style={{ color: r.risk_level.color_hex, fontWeight: "bold" }}
              >
                {risk}
              </div>
            ),
          },
          ...notifToggleKeys.map(getToggleColumn),
          {
            title: "Selected users",
            dataIndex: ["obs_notify_users"],
            key: "obs_notify_users",
            width: "30%",
            render: (_, row) =>
              row.notify_selected_user ? (
                <Select
                  className="min-w-16"
                  mode="multiple"
                  showSearch
                  filterOption={getNormalSelectOptionsFilter}
                  value={row.obs_notify_users.map((n) => n.user_id)}
                  onDeselect={(userId) => deleteNotifyee(userId, row)}
                  onSelect={(userId) => insertNotifyee(userId, row)}
                  options={selectableUsers}
                />
              ) : null,
          },
          {
            title: "",
            width: "3%",
            key: "action",
            dataIndex: ["id"],
            render: (id) => (
              <Button
                danger
                ghost
                size="large"
                icon={<DeleteOutlined />}
                onClick={async () => deleteNotif(id)}
              />
            ),
          },
        ]}
        rowKey={(item) => item.id}
      />
    </>
  );
};
export default withCustomSuspense(GCObsNotifications);
