import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useGetProjectSettingsDataQuery } from "src/common/types/generated/relay/useGetProjectSettingsDataQuery.graphql";

const query = graphql`
  query useGetProjectSettingsDataQuery($projectId: uuid!, $userId: uuid!) {
    state_connection(order_by: { name: asc }) {
      edges {
        node {
          code
          name
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }, first: 1)
      @connection(
        key: "useGetProjectSettingsDataQuery_project_connection"
        filters: []
      ) {
      edges {
        node {
          id
          ...ProjectLogoFrag @relay(mask: false)
          pk: id @__clientField(handle: "pk")
          is_sitedelivery_active
          is_sitedelivery_on
          is_sitedelivery_approval_needed
          is_sitedelivery_block_out_active
          completed
          name
          gc_division_id
          gc_business_unit_id
          gc_office_id
          is_visitor_log_and_tracking_active
          is_visitor_log_procore_integration_enabled
          project_setting {
            id
            delivery_stacking
          }
          address {
            state_code
          }
          gc_office {
            gc_office_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          gc_division {
            gc_division_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          gc_business_unit {
            gc_business_unit_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          project_subcontractors {
            subcontractor_id
          }
          procore_project_data {
            id
            procore_project_id
            procore_project_name
            procore_company_id
            procore_company_name
            integration_enabled
            user_id
            user_name
            user_login
            service_account_client_id
            sandbox
            upload_daily_construction_report_enabled
            upload_delivery_enabled
            use_dmsa
            has_dmsa_user
          }
          logistic_plans(order_by: { created_at: desc }, limit: 1) {
            ...LogisticPlanFrag @relay(mask: false)
          }
          project_local_cities(order_by: { created_at: desc }) {
            city_id
            city {
              name
            }
          }
          general_contractor {
            pk: id @__clientField(handle: "pk")
            logo_url
            procore_auth_data {
              integration_enabled
              user_id
              user_name
              user_login
              service_account_client_id
              sandbox
            }
          }
        }
      }
    }
  }
`;
const useGetProjectSettingsData = (projectId: string) => {
  const authUser = useAuthUser();
  const data = useLazyLoadQuery<useGetProjectSettingsDataQuery>(
    query,
    { userId: authUser.uid, projectId },
    //refreshedQueryOptions ?? {}
  );

  // Procore Project Modal
  const projectEdge = data.project_connection.edges[0];
  if (!projectEdge) throw new Error("Project does not exist");

  const project = projectEdge.node;
  const procore_project_data = project.procore_project_data;
  const project_settings = project.project_setting;
  if (!project_settings) throw new Error("project settings relation not found");
  return {
    procore_project_data,
    project_settings,
    project,
    state_connection: data.state_connection,
  };
};
export default useGetProjectSettingsData;
