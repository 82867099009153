import { Form, Input, Modal, message } from "antd";
import { FC, useEffect, useState } from "react";
import validateEmail from "src/common/functions/validateEmail";
import { useStripeCreateAccountSubMutation } from "src/common/types/generated/apollo/graphQLTypes";

interface SubcontractorBillingDetailsModalProps {
  visible: boolean;
  subcontractorId: string;
  projectId: string;
  defaultEmail?: string | null;
  onClose: () => void;
  onSubmit: () => void;
}

const SubcontractorBillingDetailsModal: FC<
  SubcontractorBillingDetailsModalProps
> = ({
  visible,
  subcontractorId,
  projectId,
  defaultEmail,
  onClose,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [createStripeAccountSub] = useStripeCreateAccountSubMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (defaultEmail) {
      form.setFieldsValue({ email: defaultEmail });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      title={
        <div>
          Enter Billing Information
          <br />
        </div>
      }
      okText="OK"
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        const val = await form.validateFields().catch(() => null);
        if (!val)
            return;
        setLoading(true);
        try {
          const res = await createStripeAccountSub({
            variables: {
              input: {
                sub_id: subcontractorId,
                project_id: projectId,
                account_email: val.email,
              },
            },
          });

          if (!res.data?.stripeCreateAccountSub)
              throw new Error("createStripeAccountSub returned empty response");
          
          message.success("Uploaded To SiteForm");
          form.resetFields();
          onSubmit();
        } finally {
          setLoading(false);
        }
      }}
    >
      <Form form={form} layout="vertical" title="Billing Details Form">
        <Form.Item
          className="mt-2"
          label="Billing Email Address"
          name="email"
          rules={[
            { required: true, message: "Email address required" },
            {
              validator: async (rule, value) => {
                if (value && !validateEmail(value))
                  throw new Error("Please enter a valid email address");
              },
            }
          ]}
        >
          <Input placeholder="Enter email address"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default SubcontractorBillingDetailsModal;
