import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { Form, notification } from "antd";
import * as uuid from "uuid";
import { useLazyLoadQuery } from "react-relay/hooks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { QuizQuestions_UpsertQuizResult_Mutation } from "src/common/types/generated/relay/QuizQuestions_UpsertQuizResult_Mutation.graphql";
import { QuizQuestionsQuery } from "src/common/types/generated/relay/QuizQuestionsQuery.graphql";
import { QuizQuestions_InsertOrientationResult_Mutation } from "src/common/types/generated/relay/QuizQuestions_InsertOrientationResult_Mutation.graphql";
import { translateSlide } from "src/domain-features/siteorientation/utils/translateSlide";
import fixEnglish from "src/domain-features/siteorientation/utils/fixEnglish";
import { updateOrientationResultMutation } from "../../components/slidesViewer/SiteOrientationSlidesViewer";
import { InsertImagesMutation } from "src/common/types/generated/relay/InsertImagesMutation.graphql";
import insertImages from "src/common/api/relay/mutations/InsertImages";
import SiteOrientationSlidesViewerUI from "../../components/slidesViewer/SiteOrientationSlidesViewerUI";

import insertOrientationViewedSlide from "../../../../common/api/relay/mutations/InsertOrientationViewedSlide";
import { InsertOrientationViewedSlideMutation } from "src/common/types/generated/relay/InsertOrientationViewedSlideMutation.graphql";
import dayjs from "dayjs";
import viewedSlideInsertUpdater from "../../utils/viewedSlideInsertUpdater";
import { SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation } from "src/common/types/generated/relay/SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation.graphql";
import { returnVal } from "../gcDashboard/components/OrientationProjectWorkerTable";
import {
  OrientationPage,
  SlideAnswersMapType,
} from "../../siteorientationTypes";
import { Language } from "../../../../utility-features/i18n/language-utils/i18nTypes";
import { AVAILABLE_LANGUAGES } from "../../../../utility-features/i18n/languages";
import useLangStrings from "../../../../utility-features/i18n/context/languageHooks";
import { auth } from "src/common/functions/firebase";
import stringToLanguage from "src/utility-features/i18n/language-utils/stringToLanguage";

// type SlideRecord = SlideArray[number];

interface QuizResults {
  [key: string]: string;
}

interface OrientationResults {
  signature_url?: string;
  results: { [key: string]: QuizResults };
}

const getQuizQuestionsQuery = graphql`
  query QuizQuestionsQuery(
    $userId: uuid!
    $projectId: uuid!
    $time: timestamptz!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          agc_universal_orientation
          name
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          lang
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        slides: {
          content_type: { _eq: "question" }
          deleted_at: { _is_null: true }
          archived_at: { _is_null: true }
        }
        _and: [
          {
            _or: [
              {
                general_contractor_id: { _is_null: true }
                _and: [
                  {
                    project_orientations: {
                      project_id: { _is_null: true }
                      required_by_all_workers: { _eq: true }
                    }
                  }
                  {
                    project_orientations: {
                      project_id: { _eq: $projectId }
                      hide_but_give_credit: { _eq: true }
                    }
                  }
                ]
              }
              {
                general_contractor: { projects: { id: { _eq: $projectId } } }
                project_orientations: {
                  required_by_all_workers: { _eq: true }
                  hide_but_give_credit: { _eq: true }
                  project_id: { _eq: $projectId }
                }
              }
            ]
          }
          {
            _or: [
              { project_id: { _is_null: true } }
              {
                project: {
                  linked_orientation_projects: { id: { _eq: $projectId } }
                }
              }
            ]
          }
        ]
      }
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
          type
          duration_valid
          slides(
            where: {
              content_type: { _eq: "question" }
              deleted_at: { _is_null: true }
              archived_at: { _is_null: true }
            }
            order_by: { order: asc }
          ) {
            ...SlideFrag @relay(mask: false)
            viewed_by(
              where: { user_id: { _eq: $userId } }
              order_by: { created_at: desc }
            ) {
              created_at
              id
            }
          }
          notQuizSignedOrientationResults: orientation_results(
            where: {
              user_id: { _eq: $userId }
              signature_image_id: { _is_null: true }
              _or: [
                { expired_at: { _gte: $time } }
                { status: { _eq: "pending" } }
              ]
            }
          ) {
            id
            pk: id @__clientField(handle: "pk")
            group_id
          }
          orientation_results(
            where: {
              user_id: { _eq: $userId }
              _or: [
                { expired_at: { _gte: $time } }
                { status: { _eq: "pending" } }
              ]
            }
          ) {
            pk: id @__clientField(handle: "pk")
            id
            expired_at
            completed_at
            created_at
            orientation_id
            orientation {
              duration_valid
            }
            group_id
            quiz_results {
              id
              answer
              lang
              orientation_result_id
              orientation_slide_id
              slide {
                id
                pk: id @__clientField(handle: "pk")
              }
            }
          }
        }
      }
    }
  }
`;

const QuizQuestions: React.FC<{ lang: Language }> = ({ lang }) => {
  let { projectId, userId } = useParams() as {
    projectId: string;
    userId: string;
  };
  const data = useLazyLoadQuery<QuizQuestionsQuery>(getQuizQuestionsQuery, {
    userId,
    projectId,
    time: dayjs().endOf("day").format(),
  });
  console.log(data.orientation_connection, data.user_connection);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [insertSignatureImage, isInserting] =
    useAsyncMutation<InsertImagesMutation>(insertImages);
  const [insertViewedSlide] =
    useAsyncMutation<InsertOrientationViewedSlideMutation>(
      insertOrientationViewedSlide,
    );
  const [upsertQuizResult] =
    useAsyncMutation<QuizQuestions_UpsertQuizResult_Mutation>(graphql`
      mutation QuizQuestions_UpsertQuizResult_Mutation(
        $object: orientation_quiz_result_insert_input!
      ) {
        insert_orientation_quiz_result_one(
          object: $object
          on_conflict: {
            constraint: orientation_quiz_result_orientation_result_id_orientation_slide
            update_columns: [answer, lang]
          }
        ) {
          id
        }
      }
    `);
  const [updateOrientationResult] =
    useAsyncMutation<SiteOrientationSlidesViewer_UpdateOrientationResult_Mutation>(
      updateOrientationResultMutation,
    );
  const [insertOrientationResults] =
    useAsyncMutation<QuizQuestions_InsertOrientationResult_Mutation>(graphql`
      mutation QuizQuestions_InsertOrientationResult_Mutation(
        $objects: [orientation_result_insert_input!]!
      ) {
        insert_orientation_result(objects: $objects) {
          affected_rows
        }
      }
    `);

  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const langStrings = useLangStrings(lang);
  const slideAnswers = useRef<SlideAnswersMapType>({}); // slide_id and answer
  const orientationResultsMap = useRef<{
    [key: string]: {
      orientationResultId: string;
      groupId?: string | null;
      durationValid: number | undefined;
    };
  }>({});
  const orientations = useMemo(() => {
    return data.orientation_connection.edges
      .filter((o) =>
        data.project_connection.edges[0].node.agc_universal_orientation
          ? true
          : o.node.type !== "universal",
      )
      .map((e) => e.node)
      .sort((a, b) => {
        const val1 =
          a.type === "universal" ? 1 : a.type === "corporate" ? 2 : 3;
        const val2 =
          b.type === "universal" ? 1 : b.type === "corporate" ? 2 : 3;
        const returnedValue = returnVal(val1, val2);
        return -returnedValue;
      });
  }, [data]);
  orientations.forEach((o) => {
    o.notQuizSignedOrientationResults.forEach((r) => {
      orientationResultsMap.current[o.pk] = {
        orientationResultId: r.pk,
        groupId: r.group_id,
        durationValid: o.duration_valid,
      };
    });

    o.orientation_results
      .filter(
        (p) =>
          !p.expired_at ||
          dayjs(p.expired_at).isSameOrAfter(
            dayjs(p.completed_at ?? p.created_at).add(
              o.duration_valid ?? 1, // added ?? 1, so that if a quiz result is expiring in a month user can now answer it again to update the quiz result
              "months",
            ),
          ),
      )
      .forEach((r) => {
        r.quiz_results.forEach((q) => {
          slideAnswers.current[q.orientation_slide_id] = {
            answer: q.answer,
            lang: stringToLanguage(q.lang),
          };
        });
      });
  });

  useEffect(() => {
    auth.currentUser?.getIdTokenResult().then(console.log);
    if (orientations.length > 0) {
      const groupId =
        orientationResultsMap.current[orientations[0].pk]?.groupId ?? uuid.v4();
      const resultsToBeInserted = orientations.filter(
        (o) => o.notQuizSignedOrientationResults.length === 0,
      );
      if (resultsToBeInserted.length !== 0) {
        insertOrientationResults({
          variables: {
            objects: resultsToBeInserted.map((o) => {
              const orientResultId = uuid.v4();
              const newValue = {
                orientationResultId: orientResultId,
                groupId: groupId,
                durationValid: o.duration_valid,
              };
              orientationResultsMap.current[o.pk] = newValue;
              return {
                id: orientResultId,
                status: "pending",
                group_id: groupId,
                project_id: projectId,
                user_id: userId,
                orientation_id: o.pk,
                viewed_slides: 0,
                total_slides: o.slides.length,
              };
            }),
          },
        }).catch(console.error);
      }
    }
  }, []);
  const differentThanEnglish =
    AVAILABLE_LANGUAGES.includes(lang) && lang !== "en";

  const pages = useMemo(() => {
    let pagesArr: Array<OrientationPage> = [];

    for (const orientation of orientations) {
      const slides = orientation.slides;
      let idx = 0;
      while (idx < slides.length) {
        const slide = differentThanEnglish
          ? translateSlide(slides[idx], lang)
          : fixEnglish(slides[idx]);
        const pageItem: OrientationPage = {
          title: slide.title,
          orientation,
          pageLang: lang,
          viewed:
            slide.viewed_by[0] && slideAnswers.current[slide.pk]
              ? dayjs().isSameOrBefore(
                  dayjs(slide.viewed_by[0].created_at).add(
                    orientation.duration_valid ?? 1,
                    "months",
                  ),
                )
              : false, // || viewedSlides.includes(slide.pk),
          slides: [slide],
          type: "questions",
        };
        pagesArr.push(pageItem);
        idx++;
      }
    }

    if (orientations.length > 0) {
      pagesArr.push({
        type: "signature",
        pageLang: lang,
        title: langStrings.strings.signature,
        orientation: orientations[orientations.length - 1],
        viewed: false,
        slides: [],
      });
    }
    // setOpenedButNotViewed(fistUnviewed);
    return pagesArr;
  }, [orientations]);
  const [pageIndex, setPageIndex] = useState(() => {
    let index = 0;
    while (index < pages.length && pages[index].viewed) index++;
    return index;
  });
  const page = pageIndex < pages.length ? pages[pageIndex] : null;

  const quizResults = useRef<OrientationResults>({
    results: Object.fromEntries(orientations.map((o) => [o.pk, {}])),
  });
  const onSubmitQuiz = async () => {
    if (!page) return;
    const values = await form.validateFields().catch(() => null);
    if (!values) return;
    const results = quizResults.current.results[page.orientation.pk];
    for (const name of Object.keys(values)) {
      if (!name.startsWith("field_")) continue;
      const field_id = name.substring(6);
      results[field_id] = values[name];
      slideAnswers.current[field_id] = {
        answer: values[name],
        lang: page.pageLang,
      };
      upsertQuizResult({
        variables: {
          object: {
            answer: values[name],
            lang: page.pageLang,
            orientation_slide_id: field_id,
            orientation_result_id:
              orientationResultsMap.current[page.orientation.pk]
                ?.orientationResultId,
          },
        },
      }).then((d) => console.log("Added quiz result"));
    }

    setQuizSubmitted(true);

    if (page.slides[0]?.check_correct_answer) {
      await form
        .validateFields()
        .then((v) => console.log("valid"))
        .catch(() => null);
    } else moveToNextPage();
  };
  const moveToNextPage = () => {
    if (!page) return;

    const slide = page.slides[0];
    if (slide) {
      const viewedSlide = {
        id: uuid.v4(),
        user_id: userId,
        slide_id: slide.pk,
        created_at: dayjs().format(),
        orientation_result_id:
          orientationResultsMap.current[slide.orientation_id]
            ?.orientationResultId,
      };
      insertViewedSlide({
        variables: { objects: [viewedSlide] },
        optimisticResponse: {
          insert_orientation_viewed_slide: {
            returning: [viewedSlide],
          },
        },
        updater: viewedSlideInsertUpdater,
        optimisticUpdater: viewedSlideInsertUpdater,
      }).catch(console.error);
    }
  };
  const saveResults = async (sigUrl: string) => {
      const imageId = uuid.v4();

      await insertSignatureImage({
        variables: {
          objects: [
            {
              id: imageId,
              url: sigUrl,
              lg_url: sigUrl,
              created_by_user_id: userId,
            },
          ],
        },
      });
      await updateOrientationResult({
        variables: {
          orientationResultWhere: {
            id: {
              _in: Object.entries(orientationResultsMap.current).map(
                ([p, obj]) => obj.orientationResultId,
              ),
            },
          },
          orientationResultSet: {
            signature_image_id: imageId,
          },
        },
      });
      navigate("/orientation/quiz/" + projectId + "/completion/" + userId);
  };
  if (!page) {
    const text =
      orientations.length === 0
        ? "Orinetation does not exist"
        : "Orientation is empy. Please add slides";
    return (
      <>
        <div className="w-full border-solid h-fulll border-1 border-grey">
          <h1 className="text-center mt-2 text-1.25">{text}</h1>
        </div>
      </>
    );
  }
  return (
    <SiteOrientationSlidesViewerUI
      {...{
        pages,
        lang,
        quizSubmitted,
        setQuizSubmitted,
        slideAnswers,
        page,
        userId,
        projectId,
        setPageIndex,
        pageIndex,
        form,
        inPersonType: true,
        type: "inperson",
        orientations,
        moveToNextPage,
        saveResults,
        projectName: data.project_connection.edges[0].node.name,
        userName: data.user_connection.edges[0].node.name,
        onSubmitQuiz,
      }}
    />
  );
};
export default QuizQuestions;
