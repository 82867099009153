import AppPageLayout from "src/common/components/layouts/main/content/AppPageLayout";
import { Menu } from "antd";
import { Outlet } from "react-router-dom";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import useNavigateMenuItems from "../../../../utility-features/navigation/routed-menu/useNavigateMenuItems";
import { ItemType } from "antd/lib/menu/interface";

interface SettingsPageLayoutProps {
  basePath: string;
  menuItems: ItemType[];
}

const SettingsPageLayout: React.FC<SettingsPageLayoutProps> = (props) => {
  const { selectedKeys, onSelect } = useNavigateMenuItems(
    props.menuItems,
    props.basePath,
  );

  return (
    <AppPageLayout pageTitle={"Settings"}>
      <div className={`flex flex-row gap-1`}>
        <div className={`w-14 `}>
          <Menu
            mode={"inline"}
            className={`w-full sticky top-4`}
            selectedKeys={selectedKeys}
            items={props.menuItems}
            onSelect={onSelect}
          />
        </div>
        <div className={`flex-1 min-w-0`}>
          <CustomSuspense fallback={<LoadingContent />}>
            <Outlet />
          </CustomSuspense>
        </div>
      </div>
    </AppPageLayout>
  );
};

export default SettingsPageLayout;
