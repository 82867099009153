import apolloClient from "src/common/api/apollo/apolloClient";
import {
  UpdateTextTranslationByPkDocument,
  UpdateTextTranslationByPkMutation,
  UpdateTextTranslationByPkMutationVariables,
} from "src/common/types/generated/apollo/graphQLTypes";

const updateTextTranslation = async (id: string, text: string) => {
  const res = await apolloClient.mutate<
    UpdateTextTranslationByPkMutation,
    UpdateTextTranslationByPkMutationVariables
  >({
    mutation: UpdateTextTranslationByPkDocument,
    variables: {
      pk_columns: { id },
      _set: { original: text, en: text },
    },
    optimisticResponse: {
      update_text_translation_by_pk: {
        original: text,
        clientText: text,
        lang: "en",
        en: text,
        es: text,
        pt: text,
        id: id,
      },
    },
  });

  return res;
};

export default updateTextTranslation;
