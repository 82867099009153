import * as uuid from "uuid";
import updateTextTranslation from "src/common/functions/updateTextTranslation";
import {
  GetDeliveryFieldsQuery,
  useInsertDeliveryTextsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
const useManageDeliveryTexts = () => {
  const [insertDeliveryTexts] = useInsertDeliveryTextsMutation();

  const manageDeliveryTexts = async (
    newText: string,
    fieldName: string,
    deliveryId: string,
    currentText?: { text: string; id: string },
  ) => {
    if (!currentText && newText) {
      const newId = uuid.v4();
      await insertDeliveryTexts({
        variables: {
          objects: [
            {
              id: newId,
              original: newText,
              en: newText,
            },
          ],
          id: deliveryId,
          set: { [`${fieldName}_id`]: newId },
        },
        update: (cache, { data }) => {
          const updatedDelivery = data?.update_delivery_by_pk;
          if (!updatedDelivery) return;
          //make sure text field and text relation have _id difference only, maybe in future modify it to support other cases
          cache.modify<GetDeliveryFieldsQuery>({
            id: cache.identify({ __typename: "delivery", id: deliveryId }),
            fields: {
              [fieldName]: () => ({
                __typename: "text",
                id: newId,
                original: newText,
                en: newText,
              }),
            },
          });
        },
      });
    } else if (currentText && currentText.text !== newText) {
      await updateTextTranslation(currentText.id, newText);
    }
  };

  return { manageDeliveryTexts };
};

export default useManageDeliveryTexts;
