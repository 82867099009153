import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "src/common/components/layouts/tabs/CustomTabsRouted";
import getGcCorporateOfficePath from "../../../corporate-office/utils/getGcCorporateOfficePath";

const GCObsSettingTabs = () => {
  const basePath = getGcCorporateOfficePath("settings/observations");
  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "requirements",
      label: "Goals",
    },
    {
      path: "notification",
      label: "Notification",
    },
  ];
  return <CustomTabsRouted tabs={tabs} basePath={basePath} />;
};
export default GCObsSettingTabs;
