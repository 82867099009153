import agcDashboardRoute from "./entryRoutes/agcDashboard/agcDashboardRoute";
import gcOrientationDashboardRoute from "./entryRoutes/gcDashboard/gcOrientationDashboardRoute";
import inPersonRoute from "./entryRoutes/inPerson/inPersonRoute";
import orientationTestRoute from "./entryRoutes/orientationTest/orientationTestRoute";
import quizQrRoute from "./entryRoutes/quizqr/quizQrRoute";
import remoteRoute from "./entryRoutes/remote/remoteRoute";
import universalOrientationRoute from "./entryRoutes/universal-orientation/universalOrientationRoute";
import universalOrientationShortRoute from "./entryRoutes/universal-orientation/universalOrientationShortRoute";
import workerUniversalOrientationRoute from "./entryRoutes/worker-universal-orientation/workerUniversalOrientationRoute";

const siteOrientationRoutes = {
  gcDashboard: gcOrientationDashboardRoute,
  agcDashboard: agcDashboardRoute,
  remoteOrientation: remoteRoute,
  orientationTest: orientationTestRoute,
  inPersonOrientation: inPersonRoute,
  universalOrientation: universalOrientationRoute,
  universalOrientationShort: universalOrientationShortRoute,
  quizQR: quizQrRoute,
  workerUniversalOrientation: workerUniversalOrientationRoute,
};

export default siteOrientationRoutes;
