import * as React from "react";
import GCProjectReportsPermitsTable from "./components/table/GCProjectReportsPermitsTable";
import { useNavigate } from "react-router-dom";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import AppPageLayout from "../../../../common/components/layouts/main/content/AppPageLayout";
import { SettingOutlined } from "@ant-design/icons";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";

const GCProjectReportsPermits: React.FunctionComponent<ProjectIdProps> = (
  props,
) => {
  const navigate = useNavigate();
  const openPermit = (params: { permitId: string; permitType: string }) => {
    navigate(`${params.permitType}/${params.permitId}`);
  };

  return (
    <AppPageLayout
      pageTitle={"Permits"}
      pageActions={[
        {
          children: "Settings",
          onClick: () =>
            navigate(getGcProjectSettingsPath(props.projectId, `permits`)),
          icon: <SettingOutlined />,
        },
      ]}
    >
      <GCProjectReportsPermitsTable
        projectId={props.projectId}
        openPermit={openPermit}
      />
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCProjectReportsPermits);
