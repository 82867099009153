import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const useGetActivePath = (basePath: string, paths: string[]) => {
  const [activePath, setActivePath] = useState<string | null>(null);

  const location = useLocation();

  useEffect(() => {
    const matchingPath = paths.find((path) =>
      location.pathname.startsWith(`${basePath}/${path}`),
    );

    setActivePath(matchingPath ?? null);
  }, [location.pathname]);

  return activePath;
};

export default useGetActivePath;
