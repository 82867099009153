import SignaturePad from "signature_pad";
import { useUploadBase64FileMutation } from "../../../types/generated/apollo/graphQLTypes";
import { useState } from "react";
import uploadImage, {
  UploadResult,
} from "src/common/functions/upload-utility/uploadImage";

// Not for stand-alone use
// This hook is used in the `useSignaturePad` hook

export type UploadSignatureFuncType = () => Promise<string>;
export type UploadSignatureAsImageFuncType = () => Promise<UploadResult>;
export interface UploadSignatureReturnType {
  uploadSignature: UploadSignatureFuncType;
  uploading: boolean;
  uploadSignatureAsImage: UploadSignatureAsImageFuncType;
}
const useUploadSignature: (
  signaturePad: SignaturePad | null,
) => UploadSignatureReturnType = (signaturePad) => {
  const [uploadBase64] = useUploadBase64FileMutation();
  const [uploading, setUploading] = useState<boolean>(false);

  const uploadSignature = async () => {
    const sigPad = signaturePad; 
    if (!sigPad) {
      throw new Error("SignaturePad is not initialized");
    }
    setUploading(true);
    try {
      const base64Url = sigPad.toDataURL();
      const { data, errors } = await uploadBase64({
        variables: { input: { base64: base64Url } },
      });
      if (!data) {
        if (errors && errors.length > 0) 
          throw errors[0];
        else
          throw new Error("upload signature failed");
      }
      const signatureUrl = data.uploadBase64File;
      if (!signatureUrl) 
        throw new Error("Upload retruned empty url");
      return signatureUrl;
    } finally {
      setUploading(false);
    }
  };

  const uploadSignatureAsImage = async () => {
    const sigPad = signaturePad; 
    if (!sigPad) {
      throw new Error("SignaturePad is not initialized");
    }
    setUploading(true);
    try {
      const data = sigPad.toDataURL();
      const uploadRes = await uploadImage(data);
      return uploadRes;
    } finally {
      setUploading(false);
    }
  };

  return { uploadSignature, uploadSignatureAsImage, uploading };
};

export default useUploadSignature;
