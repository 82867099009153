import { useSuspenseQuery } from "@apollo/client";
import AnonProjectWorkerTable from "src/common/components/tables/AnonProjectWorkerTable";
import {
  GetGcAdminWorkersDocument,
  GetGcAdminWorkersQuery,
  GetGcAdminWorkersQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";
import useGcEmployee from "../utils/useGcEmployee";

const GCOrgUnitWorkers: React.FC<OrganizationUnitProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  const employee = useGcEmployee();
  const typeKey = `gc_${organizationUnitType}_id`;
  const { data } = useSuspenseQuery<
    GetGcAdminWorkersQuery,
    GetGcAdminWorkersQueryVariables
  >(GetGcAdminWorkersDocument, {
    variables: {
      gcId: employee.general_contractor.id,
      projWhere: {
        [typeKey]: { _eq: organizationUnitId },
      },
    },
  });
  return (
    <div className="bg-white h-full w-full">
      <AnonProjectWorkerTable
        excludedKeys={["crew", "corporateDate"]}
        where={{
          project: { [typeKey]: { _eq: organizationUnitId } },
          is_last: { _eq: true },
        }}
        projectsOptions={data.project}
      />
    </div>
  );
};
export default withAppPageLayout(
  withOrganizationUnitWrapper(GCOrgUnitWorkers),
  { pageTitle: "Workers" },
);
