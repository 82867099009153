import {
  GetAdminObservationOptionsQueryVariables,
  Project_Bool_Exp,
  useGetAdminObservationOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import GCObservationCharts from "src/domain-features/observation/entryRoutes/gc/charts/GCObservationCharts";
import GCAdminObservationsHeader from "./header/GCAdminObservationsHeader";
import useGetGCAdminCommonFilters from "../../utils/useGetGCAdminCommonFilters";
import useGetObservationFilters from "./utils/useGetObservationFilters";
import { OrganizationLevelName } from "../../utils/hierarchyTypes";

const GCAdminObsDashboard: React.FC<{
  organizationLevel: OrganizationLevelName;
  organizationId: string;
  projWhere: Project_Bool_Exp;
  onSettingsClick?: () => void;
  optionsQueryVars: Omit<
    GetAdminObservationOptionsQueryVariables,
    "includeProjSetting"
  >;
}> = ({ organizationLevel, organizationId, optionsQueryVars, projWhere }) => {
  const {
    data: options,
    error: optionsError,
    loading: optionsLoading,
  } = useGetAdminObservationOptionsQuery({
    variables: { ...optionsQueryVars, includeProjSetting: false },
  });
  const {
    categoriesFilter,
    dateFilter,
    companiesFilter,
    riskLevelFilter,
    selectFilters: obsSelectFilters,
    setDateFilter,
    subCategoriesFilter,
  } = useGetObservationFilters(options);
  const {
    projectStatusFilter,
    projectsFilter,
    radioFilters,
    selectFilters: commonSelectFilters,
    businessUnitsFilter,
    divisionsFilter,
    officesFilter,
  } = useGetGCAdminCommonFilters(options);

  if (optionsError) throw optionsError;

  return (
    <div className={`flex flex-col gap-1`}>
      <GCAdminObservationsHeader
        reportCreatorButtonProps={{
          options,
          organizationId,
          organizationLevel,
        }}
        filters={{
          radioFilters: [...radioFilters],
          selectFilters: [...commonSelectFilters, ...obsSelectFilters],

          dates: {
            selectedDates: dateFilter,
            onSelectedDatesChange: setDateFilter,
          },
        }}
      />
      <GCObservationCharts
        projWhere={projWhere}
        optionsLoading={optionsLoading}
        options={options}
        currentFilters={{
          subCategoriesFilter,
          companiesFilter,
          dateFilter,
          riskLevelFilter,
          categoriesFilter,
          projectsFilter,
          projectStatusFilter,
          businessUnitsFilter,
          divisionsFilter,
          officesFilter,
        }}
      />
    </div>
  );
};
export default GCAdminObsDashboard;
