import SelectProjectSubcontractorModal from "src/common/components/dialogs/SelectProjectSubcontractorModal";
import {
  ProjDrWithPtpSettingFragmentFragment,
  useUpdateProjPtpSettingMutation,
  useUpdateProjSubPtpSettingMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useState } from "react";
import { Button, Card, Divider, message, Space, Typography } from "antd";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import SwitchWithText from "../../../../../../../common/components/SwitchWithText";

const AddDRWithPTPSettings: React.FC<{
  addDrWithPtpProjectDataFrag: ProjDrWithPtpSettingFragmentFragment;
}> = ({ addDrWithPtpProjectDataFrag }) => {
  const projectId = addDrWithPtpProjectDataFrag.id;
  const projSubsWithAddDrWithPtpSettings =
    addDrWithPtpProjectDataFrag.project_subcontractors.map((projSub) => ({
      id: projSub.id,
      name: projSub.subcontractor.name,
      selected: projSub.add_daily_work_log_during_ptp,
    }));
  const projAddDrWithPtp =
    addDrWithPtpProjectDataFrag.add_daily_work_log_during_ptp;
  const [
    showSelectProjectSubcontractorModal,
    setShowSelectProjectSubcontractorModal,
  ] = useState(false);
  const selectProjectSubcontractorModalOpen = () => {
    setShowSelectProjectSubcontractorModal(true);
  };
  const selectProjectSubcontractorModalClose = () => {
    setShowSelectProjectSubcontractorModal(false);
  };
  const [updateProj] = useUpdateProjPtpSettingMutation();
  const [updateProjectSub] = useUpdateProjSubPtpSettingMutation();
  const onProjDrWithPTPChange = async (addDrWithPtp: boolean) => {
    const _set = { add_daily_work_log_during_ptp: addDrWithPtp };
    const { data } = await updateProj({
      variables: { projectId, _set },
      optimisticResponse: {
        update_project_by_pk: { __typename: "project", id: projectId, ..._set },
      },
    });
    const value = data?.update_project_by_pk?.add_daily_work_log_during_ptp;
    value ? message.success("On") : message.error("Off");
  };
  const onAddDrWithPtpSelectSubChange = async (projectSubIds: string[]) => {
    const [toSelect, toDeselect] = compareTwoLists(
      projectSubIds,
      projSubsWithAddDrWithPtpSettings.reduce((projSubIds, projSub) => {
        if (projSub.selected) projSubIds.push(projSub.id);
        return projSubIds;
      }, [] as string[]),
    );

    await updateProjectSub({
      variables: {
        updates: [
          {
            where: { id: { _in: toSelect } },
            _set: { add_daily_work_log_during_ptp: true },
          },
          {
            where: { id: { _in: toDeselect } },
            _set: { add_daily_work_log_during_ptp: false },
          },
        ],
      },
      optimisticResponse: {
        update_project_subcontractor_many: [
          {
            returning: toSelect.map((projSubId) => ({
              __typename: "project_subcontractor",
              id: projSubId,
              add_daily_work_log_during_ptp: true,
            })),
          },
          {
            returning: toDeselect.map((projSubId) => ({
              __typename: "project_subcontractor",
              id: projSubId,
              add_daily_work_log_during_ptp: false,
            })),
          },
        ],
      },
    });
  };
  return (
    <Card title={"Daily Report with Pre-Task Plan"}>
      <Space
        size={0}
        split={<Divider />}
        className={`w-full`}
        direction={"vertical"}
      >
        <SwitchWithText
          text={`Foremen will submit their Daily Report at the same time as their
        Pre-Task Plan`}
          checked={projAddDrWithPtp}
          onChange={async (ptpDailyReportOn) => {
            await onProjDrWithPTPChange(ptpDailyReportOn);
          }}
        />

        {projAddDrWithPtp && (
          <Space direction={"vertical"}>
            <Typography>
              <Typography.Paragraph>
                You can require all or some Subcontractors to submit their Daily
                Report with their Pre-Task Plan
                <ul>
                  {projSubsWithAddDrWithPtpSettings
                    .filter((projSub) => projSub.selected)
                    .map((projSub) => (
                      <li key={projSub.id}>{projSub.name}</li>
                    ))}
                </ul>
              </Typography.Paragraph>
            </Typography>

            <Button
              type="primary"
              onClick={() => selectProjectSubcontractorModalOpen()}
            >
              Select Subcontractor(s)
            </Button>

            <SelectProjectSubcontractorModal
              defaultProjectSubs={projSubsWithAddDrWithPtpSettings
                .filter((sub) => sub.selected)
                .map((sub) => sub.id)}
              modalClose={selectProjectSubcontractorModalClose}
              modalVisible={showSelectProjectSubcontractorModal}
              onSubmit={onAddDrWithPtpSelectSubChange}
            />
          </Space>
        )}
      </Space>
    </Card>
  );
};
export default AddDRWithPTPSettings;
