import { useSuspenseQuery } from "@apollo/client";
import { Button, Card, Form, Input } from "antd";
import { useState } from "react";
import getAddressText from "src/common/functions/getAddressText";
import {
  GetDivisionDetailDocument,
  GetDivisionDetailQuery,
  GetDivisionDetailQueryVariables,
  useGetStatesQuery,
  useUpdateDivDetailMutation,
  useUpdateDivisionLogoMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import AddressFormItems, {
  AddressFormItemsName,
} from "../../../../components/AddressFormItems";
import ProfileAvatar from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfileAvatar";
import LogoExplainerText from "../../../../components/LogoExplainerText";
import { DivisionProps } from "../../../../utils/withDivisionId";
import useGcEmployee from "../../../utils/useGcEmployee";

const GCDivisionDetail: React.FC<DivisionProps> = ({ divisionId }) => {
  const emp = useGcEmployee();

  const { data, refetch } = useSuspenseQuery<
    GetDivisionDetailQuery,
    GetDivisionDetailQueryVariables
  >(GetDivisionDetailDocument, {
    variables: {
      divisionId,
    },
    fetchPolicy: "network-only",
  });
  const { data: statesData } = useGetStatesQuery();
  const division = data.gc_division_by_pk;

  if (!division) throw new Error("Business Unit not found");
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const gc = emp.general_contractor;
  const initialFields = {
    name: division.name,
    ...(division.address
      ? {
          addressLine1: division.address.line_1,
          city: division.address.city,
          zip: division.address.zip_code,
          stateCode: division.address.state.code,
        }
      : {}),
  };
  const [updateDivDetail, { loading }] = useUpdateDivDetailMutation();
  const [updateDivLogo] = useUpdateDivisionLogoMutation();
  return (
    <div className={`flex flex-col gap-1 `}>
      {!editing && (
        <Card
          title={`
            Upload/Change ${gc.hierarchy_division_name} Logo
          `}
        >
          <div className={`w-20`}>
            <ProfileAvatar
              imageUrl={
                division.logo_url ||
                division.general_contractor.logo_url ||
                undefined
              }
              onFileUpload={async (imageUrl) => {
                await updateDivLogo({
                  variables: { divisionId, imageUrl },
                  optimisticResponse: {
                    update_gc_division_by_pk: {
                      id: divisionId,
                      logo_url: imageUrl,
                    },
                  },
                });
              }}
              onFileRemove={async () => {
                await updateDivLogo({
                  variables: { divisionId, imageUrl: null },
                  optimisticResponse: {
                    update_gc_division_by_pk: {
                      id: divisionId,
                      logo_url: null,
                    },
                  },
                });
              }}
            />
          </div>
          <LogoExplainerText />
        </Card>
      )}
      <Card title={`${gc.hierarchy_division_name} Details`}>
        <Form
          form={form}
          initialValues={initialFields}
          onFinish={async (
            vals: AddressFormItemsName & {
              name: string;
              division?: string;
            },
          ) => {
            const newState =
              division.address.state.code !== vals.state_code
                ? statesData?.state.find((s) => s.code === vals.state_code)
                : division.address.state;
            await updateDivDetail({
              variables: {
                addressId: division.address.id,
                divisionId,
                divisionSet: { name: vals.name },
                addressSet: {
                  state_code: vals.state_code,
                  zip_code: vals.zip,
                  city: vals.city,
                  line_1: vals.addressLine1,
                },
              },
              optimisticResponse: {
                update_address_by_pk: {
                  ...division.address,
                  state: newState || division.address.state,
                  zip_code: vals.zip,
                  city: vals.city,
                  line_1: vals.addressLine1,
                },
                update_gc_division_by_pk: {
                  id: divisionId,
                  name: vals.name,
                },
              },
            });
            // refetch();
            setEditing(false);
          }}
        >
          {editing ? (
            <>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Enter Name" }]}
              >
                <Input />
              </Form.Item>
              Address
              <AddressFormItems
                onStateChange={(v) => {}}
                states={statesData?.state || []}
              />
            </>
          ) : (
            <div>
              <div>Name: {division.name}</div>
              <br />
              Address: {getAddressText(division.address)}
            </div>
          )}
          {emp.is_corporate_admin && (
            <>
              <Button
                type={editing ? "default" : "primary"}
                loading={loading}
                onClick={() => {
                  if (editing) form.setFieldsValue(initialFields);
                  setEditing((i) => !i);
                }}
              >
                {editing ? "Cancel" : "Edit"}
              </Button>
              {editing && (
                <Button
                  loading={loading}
                  className="ml-2"
                  htmlType="submit"
                  type="primary"
                >
                  Save
                </Button>
              )}
            </>
          )}
        </Form>
      </Card>
    </div>
  );
};
export default GCDivisionDetail;
