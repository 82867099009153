import { Button, Form, Input, message, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import * as uuid from "uuid";
import { useEmailSubAdminsToAddJhaMutation } from "src/common/types/generated/apollo/graphQLTypes";
import ViewEmailToBeSentToBeSentToSubAdminsToAddJHAsModal from "../item-modals/ViewEmailToBeSentToSubAdminsToAddJHAsModal";
import { GCProjectJhasQuery$data } from "src/common/types/generated/relay/GCProjectJhasQuery.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SelectSubAdminsToUploadJHAsModalMutation } from "src/common/types/generated/relay/SelectSubAdminsToUploadJHAsModalMutation.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface FormValues {
  subcontractor?: string;
  usersToBeNotified: Array<string>;
  taskDescription: string;
  shortDescription: string;
}

const SelectSubAdminsToUploadJHAsModal: React.FC<
  {
    projectId: string;
    iniviteText: boolean;
    projectName: string;
    sentFromCompanyName: string;
    visible: boolean;
    onCancel: () => void;
    type: "gc_project_jha" | "jha_log";
  } & (
    | {
        type: "gc_project_jha";
        subcontractorId: string;
        subAdminUsersData: { id: string; name: string }[];
      }
    | {
        type: "jha_log";
        subcontractorOptions: GCProjectJhasQuery$data["project_subcontractor_connection"]["edges"];
      }
  )
> = ({
  sentFromCompanyName,
  projectName,
  projectId,
  iniviteText,
  visible,
  onCancel,
  ...props
}) => {
  const [emailSubAdminsToAddJha] = useEmailSubAdminsToAddJhaMutation();
  const [viewEmailOpenModal, setViewEmailOpenModal] = useState(false);

  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    setSelectedSubId(undefined);
    onCancel();
  };

  const [insertTask] =
    useAsyncMutation<SelectSubAdminsToUploadJHAsModalMutation>(graphql`
      mutation SelectSubAdminsToUploadJHAsModalMutation(
        $objects: [task_insert_input!]!
      ) {
        insert_task(objects: $objects) {
          affected_rows
        }
      }
    `);

  const [selectedSubId, setSelectedSubId] = useState<string | undefined>(
    undefined,
  );

  const subAdminOptions =
    props.type === "jha_log"
      ? props.subcontractorOptions
          .find((sub) => sub.node.subcontractor_id === selectedSubId)
          ?.node.subcontractor.subcontractor_employees.map((subAdmin) => ({
            id: subAdmin.user_id,
            name: subAdmin.user.name,
          })) || []
      : props.subAdminUsersData;

  return (
    <>
      <ViewEmailToBeSentToBeSentToSubAdminsToAddJHAsModal
        projectName={projectName}
        visible={viewEmailOpenModal}
        gcName={sentFromCompanyName}
        onCancel={() => setViewEmailOpenModal(false)}
      />
      <Modal
        open={visible}
        title="Send an Email Request to Upload a JHA"
        okText="Send"
        cancelText="Cancel"
        cancelButtonProps={{ style: { display: "inline" } }}
        onCancel={handleCancel}
        onOk={() => {
          form.validateFields().then(async (values: FormValues) => {
            const subId =
              props.type === "jha_log"
                ? values.subcontractor
                : props.subcontractorId;

            if (!subId) {
              message.error("Please Select Subcontractor");
              return;
            }

            if (!values.usersToBeNotified) {
              message.error("Please Select Users");
              return;
            }

            if (!values.taskDescription) {
              message.error("Please enter Task Description");
              return;
            }

            message.loading({
              content: "Task Request is in progress",
              duration: 0,
            });

            try {
              const taskId = uuid.v4();
              await insertTask({
                variables: {
                  objects: [
                    {
                      id: taskId,
                      description: {
                        data: {
                          original: values.taskDescription,
                          en: values.taskDescription,
                        },
                      },
                      subcontractor_id: subId,
                      project_id: projectId,
                      request_state: "requested",
                    },
                  ],
                },
              });
              await emailSubAdminsToAddJha({
                variables: {
                  input: {
                    taskId: taskId,
                    subcontractorId: subId,
                    subAdmins: values.usersToBeNotified,
                    projectId: projectId,
                    shortMessage: values.shortDescription,
                  },
                },
              }).then(() => {
                message.destroy();
                handleCancel();
                message.success("Email(s) Sent");
                form.resetFields();
              });
            } catch (err) {
              console.log("Validate Failed:", err);
            }
          });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          {props.type === "jha_log" && (
            <Form.Item
              name="subcontractor"
              label={` Select the Subcontractor/Trade Partner you are requesting from`}
            >
              <Select
                className="w-full"
                showSearch
                filterOption={getNormalSelectOptionsFilter}
                onChange={(e) => {
                  form.setFieldsValue({ usersToBeNotified: undefined });
                  setSelectedSubId(e);
                }}
                options={props.subcontractorOptions.map((option) => ({
                  label: option.node.subcontractor.name,
                  value: option.node.subcontractor_id,
                }))}
              ></Select>
            </Form.Item>
          )}
          {subAdminOptions.length > 0 && (
            <Form.Item
              name="usersToBeNotified"
              label={
                <span>
                  Select who to send the email request to (Emails include an
                  upload link)
                </span>
              }
            >
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select Subcontractor Administrator(s) to send JHA Email Request"
                showSearch
                filterOption={getNormalSelectOptionsFilter}
                options={subAdminOptions.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
              />
            </Form.Item>
          )}

          {iniviteText && (
            <>
              <div>
                <b>Don’t see your subcontractor point of contact?</b>
                <br /> If your Trade Partner point of contact is not listed
                above, push Cancel and select{" "}
                <b>“Invite Subcontractor Administrator”</b> at the top of the
                screen.
              </div>
              <br />
            </>
          )}

          <Form.Item
            name="taskDescription"
            label="JHA Name/Description"
            rules={[{ required: true, message: "Enter Task Description" }]}
          >
            <Input placeholder="Enter Task Description" />
          </Form.Item>
          <Form.Item
            name="shortDescription"
            label="Short Description of what you need (optional)"
          >
            <Input placeholder="We need a JHA for installing light fixtures" />
          </Form.Item>

          <Button
            type="link"
            className="underlined-button"
            onClick={() => setViewEmailOpenModal(true)}
          >
            Example JHA EMAIL
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default SelectSubAdminsToUploadJHAsModal;
