import { useParams } from "react-router-dom";
import ProjectIdProps from "../types/manual/ProjectIdProps";
import React from "react";

const withProjectIdUrlParam =
  <Props extends object>(Component: React.FC<Props & ProjectIdProps>) =>
  (props: Props) => {
    const params = useParams();
    const projectId = params.projectId;
    if (!projectId) throw new Error("No project found");

    return <Component {...{ projectId }} {...props} />;
  };

export default withProjectIdUrlParam;
