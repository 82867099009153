import { nanoid } from "nanoid/non-secure";
import apolloClient from "src/common/api/apollo/apolloClient";
import validateEmail from "src/common/functions/validateEmail";
import {
  CreateInviteDataObject,
  CreateInviteDocument,
  CreateInviteMutation,
  CreateInviteMutationVariables,
} from "src/common/types/generated/apollo/graphQLTypes";

export type sendInviteProps = {
  toUid?: string;
  sendTo: string;
  sendToName?: string;
  sentFromName?: string;
  sentFromCompanyName?: string;
  joinRole?: string;
  joinProjectId?: string;
  joinGeneralContractorId?: string;
  joinSubcontractorId?: string;
  projectName?: string;
  claimingAccount?: boolean;
  automaticSend?: boolean;
  disableToast?: boolean;
  inviteType?: string;
  redirectToMobilization?: boolean;
};

const createInvites = async (data: CreateInviteDataObject[]) => {
  const res = await apolloClient.mutate<
    CreateInviteMutation,
    CreateInviteMutationVariables
  >({
    mutation: CreateInviteDocument,
    variables: {
      objects: data,
    },
  });

  return res;
};

export const sendInvites = async (
  data: sendInviteProps[],
): Promise<string[]> => {
  const createInviteData = data.map((obj): CreateInviteDataObject => {
    const inviteId = nanoid(12);
    const inviteData: CreateInviteDataObject = {
      inviteId: inviteId,
      joinProjectId: obj.joinProjectId,
      joinSubcontractorId: obj.joinSubcontractorId,
      joinGeneralContractorId: obj.joinGeneralContractorId,
      automaticSend: false,
      joinRole: obj.joinRole,
      serverContact: true,
      redirectToMobilization: obj.redirectToMobilization ?? false,
    };
    if (obj.sendTo.includes("@")) {
      inviteData.toEmail = obj.sendTo;
    }
    if (obj.claimingAccount) {
      inviteData.claimingAccount = obj.claimingAccount;
    }
    if (obj.inviteType) {
      inviteData.inviteType = obj.inviteType;
    }
    return inviteData;
  });
  await createInvites(createInviteData);
  return createInviteData.map(data => `https://app.siteform.net/invite/${data.inviteId}`)
};

const sendInvite = async (
  data: sendInviteProps,
): Promise<string> => {
  const [res] = await sendInvites([data]);
  return res;
};

export default sendInvite;
