import React, { FC, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import GCProjectPreTaskPlansByRolesChart from "src/domain-features/sitesafety/entry-routes/dashboard/GCProjectPreTaskPlansByRolesChart";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import GCDisabledProjectSubcontractorTable from "src/common/components/tables/GCDisabledProjectSubcontractorTable";
import WorkerStatistics, {
  DefaultStats,
  WorkerAveragesSchema,
  WorkerStatsSchema,
} from "src/common/models/WorkerStatistics";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  useEmailDashboardSpreadsheetMutation,
  useGetPermitAggregateByProjectPkQuery,
  useGetWorkerStatsByRangeLazyQuery,
  useGetWorkerStatsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import GCSubOnsitePerformance from "../../components/performance/GCSubOnsitePerformance";
import styled from "styled-components";
import AppPageLayout from "../../../../common/components/layouts/main/content/AppPageLayout";
import useGetReportManagementData from "../report-settings/utils/useGetReportManagementData";

const { Text } = Typography;
const StyledStatistic = styled(Statistic).attrs({
  valueStyle: { fontSize: 36 },
  precision: 0,
})`
  text-align: center;
`;

interface StatCardProps {
  record: any;
}

const StatCard = ({ record }: StatCardProps) => {
  return (
    <div className="flex flex-1 w-full">
      <Card
        style={{
          minHeight: "229px",
          height: "100%",
          width: "100%",
        }}
      >
        <Space className={`w-full`} direction={"vertical"}>
          <StyledStatistic
            title={<h3 style={{ letterSpacing: "1.5" }}>{record.title}</h3>}
            value={record.value}
            suffix={record.suffix}
          />
          <div style={{ textAlign: "right", minHeight: "22px" }}>
            {record.action !== undefined && (
              <a onClick={record.action.onClick}>{record.action.title}</a>
            )}
          </div>
          <Divider className={`w-full`} />
          {record.footer !== undefined && record.footer()}
        </Space>
      </Card>
    </div>
  );
};

type MemoizedStats = {
  onsite: WorkerStatsSchema;
  manhours: WorkerStatsSchema;
  averages: Pick<
    WorkerAveragesSchema,
    "average30" | "averageThisWeek" | "averageLastWeek"
  >;
};

const DefaultAverages = (): {
  average30: number;
  averageThisWeek: number;
  averageLastWeek: number;
} => ({
  average30: 0,
  averageThisWeek: 0,
  averageLastWeek: 0,
});

const GCProjectDashboard: FC<ProjectIdProps> = ({ projectId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: permitsData,
    loading: permitsLoading,
    error: permitsError,
  } = useGetPermitAggregateByProjectPkQuery({
    variables: {
      projectId,
    },
  });

  const {
    data: workersStatistics,
    loading: workersStatisticsLoading,
    error: workersStatisticsError,
  } = useGetWorkerStatsQuery({
    variables: {
      projectId,
    },
  });
  const { crewLeads, subOnsiteData } = useGetReportManagementData(projectId);

  const dashboardStartDate = workersStatistics?.project[0].dashboard_updated_at;
  const isDashboardActive = workersStatistics?.project[0].is_dashboard_active;
  const projectTimezone =
    workersStatistics?.project[0].timezone || "America/New_York";

  const [
    getRangeStats,
    {
      data: statsByRange,
      loading: statsByRangeLoading,
      error: statsByRangeError,
    },
  ] = useGetWorkerStatsByRangeLazyQuery();

  const [emailDashboardSpreadsheet, { loading: inserting }] =
    useEmailDashboardSpreadsheetMutation();

  const loading =
    permitsLoading || workersStatisticsLoading || statsByRangeLoading;
  const error = permitsError || workersStatisticsError || statsByRangeError;

  const workerStats = useMemo<MemoizedStats>((): MemoizedStats => {
    getRangeStats({
      variables: {
        projectId,
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .startOf("day")
          .toISOString(),
        endDate: dayjs().endOf("day").toISOString(),
      },
    });

    if (loading || !workersStatistics || !statsByRange) {
      return {
        onsite: DefaultStats(),
        manhours: DefaultStats(),
        averages: DefaultAverages(),
      };
    } else {
      const { worker_onsite_stats, worker_manhour_stats } = workersStatistics;
      const { workersByRange, manHoursByRange } = statsByRange;

      const stats = new WorkerStatistics(
        worker_onsite_stats,
        worker_manhour_stats,
        workersByRange,
        manHoursByRange,
      );

      return {
        onsite: stats.onSite(),
        manhours: stats.manHours(),
        averages: {
          //Workers Onsite 30 avg
          average30: stats.average("onsite", 30, "d") || 0,
          // Manhours avg this week
          averageThisWeek:
            stats.average("manhours", [
              dayjs().startOf("isoWeek").toString(),
              dayjs().endOf("isoWeek").toString(),
            ]) || 0,
          // Manhours avg last week
          averageLastWeek:
            stats.average("manhours", [
              dayjs().subtract(1, "week").startOf("isoWeek").toString(),
              dayjs().subtract(1, "week").endOf("isoWeek").toString(),
            ]) || 0,
        },
      };
    }
  }, [loading, workersStatistics, statsByRange]);

  const getPermitCount = (type: "active" | "total"): number => {
    if (!loading && !error) {
      return !!permitsData
        ? (permitsData[type]?.aggregate?.count as number)
        : 0;
    }
    return 0;
  };

  const dataSnapshots: Array<{
    id: string | number;
    title: React.ReactNode;
    value: string | number | undefined;
    action?: {
      title: string;
      onClick: () => void;
    };
    footer?: () => JSX.Element;
  }> = [
    {
      id: "subCount",
      title: "Subs On-Site",
      value: workerStats.onsite.subs.length.toLocaleString(),
      // action: {
      //   title: 'See Subs',
      //   onClick() {
      //     const base = history.location.pathname
      //       .split('/')
      //       .slice(1, 4)
      //       .join('/');
      //     history.replace(`/${base}/subcontractors`);
      //   },
      // },
      footer: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "left",
            paddingTop: "12px",
          }}
        >
          {/* <StyledP>Average Crew Size</StyledP> */}
          {/* <StyledP>{subsAverageCrew || 0}</StyledP> */}
        </div>
      ),
    },
    {
      id: "workerCount",
      title: "Workers On-Site Today",
      value: workerStats.onsite.today.toLocaleString(),
      action: {
        title: "See Workers",
        onClick() {
          const base = location.pathname.split("/").slice(1, 4).join("/");
          navigate(`/${base}/workers`);
        },
      },
      footer: () => (
        <div className={`flex flex-row w-full pt-0.75 justify-between`}>
          <Text>30 Day Average</Text>
          <Text>{workerStats.averages.average30 || 0}</Text>
        </div>
      ),
    },
    {
      id: "manHourWeek",
      title: "Manhours this Week",
      value: workerStats.manhours.thisWeek.toLocaleString(),

      footer: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "12px",
          }}
        >
          <span>
            <Text style={{ fontSize: "14px" }}>Avg. Last Week </Text>
            <Text style={{ fontSize: "14px" }}>
              {workerStats.averages.averageLastWeek.toLocaleString()}
            </Text>
          </span>
          <span>
            <Text style={{ fontSize: "14px" }}>Avg. This Week </Text>
            <Text style={{ fontSize: "14px" }}>
              {workerStats.averages.averageThisWeek.toLocaleString()}
            </Text>
          </span>
        </div>
      ),
    },
    {
      id: "activePermitCount",
      title: "Active Permits",
      value: getPermitCount("active"),
      action: {
        title: "See Permits",
        onClick() {
          const base = location.pathname.split("/").slice(1, 4).join("/");
          navigate(`/${base}/reports/permits`, { replace: true });
        },
      },
      footer: () => (
        <div className={`flex flex-row w-full pt-0.75 justify-between`}>
          <Text>Project Total</Text>
          <Text>{getPermitCount("total")}</Text>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Typography.Title level={3}>Today</Typography.Title>
      {loading ? (
        <LoadingContent />
      ) : (
        <>
          <div className="flex flex-col w-full gap-1">
            <section className="flex flex-row w-full gap-1">
              {dataSnapshots.map((col) => (
                <StatCard key={col.id} record={col} />
              ))}
            </section>
            <section>
              <GCSubOnsitePerformance
                subOnsiteData={subOnsiteData}
                onsiteSwitcher={true}
                projectTimezone={projectTimezone}
                crewLeads={crewLeads}
                projectId={projectId}
                dashboardStart={
                  dashboardStartDate
                    ? dayjs(dashboardStartDate)
                    : dayjs().startOf("day")
                }
              />
            </section>
            <section style={{ paddingTop: "24px" }}>
              <GCProjectPreTaskPlansByRolesChart projectId={projectId} />
            </section>
            <section style={{ paddingTop: "24px" }}>
              {isDashboardActive ? (
                <StyledContent padding backgroundColor="white">
                  <div>
                    <h3 style={{ display: "inline" }}>
                      Report Tracking (PTP, TBT, and Daily Reports)
                    </h3>
                    <div
                      style={{
                        display: "inline",
                        float: "right",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 14,
                        }}
                      >
                        Download Report Data Spreadsheet&nbsp;&nbsp;&nbsp;&nbsp;
                      </Text>

                      <Button
                        type="primary"
                        loading={inserting}
                        onClick={async () => {
                          const { data: emailData } =
                            await emailDashboardSpreadsheet({
                              variables: {
                                input: {
                                  projectId,
                                  startAt:
                                    dayjs(dashboardStartDate).toISOString(),
                                  endAt: dayjs().toISOString(),
                                  timezone: dayjs.tz.guess(),
                                },
                              },
                            });
                          if (emailData?.emailDashboardSpreadsheet) {
                            downloadFromUrl(
                              emailData.emailDashboardSpreadsheet,
                            );
                          }
                        }}
                      >
                        Generate
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <Row gutter={16}>
                    <Col span={24}>
                      {/* <BSpace> */}
                      <Table
                        columns={[
                          {
                            title: "Name",
                          },
                          {
                            title: "Trade",
                          },
                        ]}
                      />
                      {/* </BSpace> */}
                    </Col>
                  </Row>
                </StyledContent>
              ) : (
                <GCDisabledProjectSubcontractorTable
                  where={{
                    project_subcontractor: {
                      project_id: { _eq: projectId },
                    },
                  }}
                />
              )}
            </section>
          </div>
        </>
      )}
    </div>
  );
};

const GCProjectDashboardWrapper = () => {
  const { projectId } = useParams();
  return (
    <AppPageLayout pageTitle={"Project Dashboard"}>
      {typeof projectId !== "string" ? (
        <Empty />
      ) : (
        <GCProjectDashboard {...{ projectId: projectId }} />
      )}
    </AppPageLayout>
  );
};

export default GCProjectDashboardWrapper;
