import React from "react";
import { GCInspectionsQuery$data } from "src/common/types/generated/relay/GCInspectionsQuery.graphql";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import ManageInspections from "src/domain-features/siteinspection/components/ManageInspections";

const GCManageInspections: React.FC<
  ProjectIdProps & { data: GCInspectionsQuery$data }
> = ({ projectId, data }) => {
  return (
    <ManageInspections
      where={{ project_id: { _eq: projectId }, deleted_at: { _is_null: true } }}
      gcId={data.project_connection.edges[0].node.general_contractor_id}
      projectId={projectId}
      labelOptions={data.inspection_label_type_connection.edges.map((p) => ({
        label: p.node.label_name,
        value: p.node.pk,
      }))}
      generalContractorId={
        data.project_connection.edges[0].node.general_contractor_id
      }
      inspectionQROptions={data.inspection_qr_code_connection.edges.map(
        (inspQR) => ({ label: inspQR.node?.name, value: inspQR.node?.pk }),
      )}
      projectEmployees={data.project_employee_connection.edges.map((pe) => ({
        id: pe.node.employee_id,
        name: pe.node.employee.user.name,
        email: pe.node.employee.user.email!,
      }))}
    />
  );
};
export default GCManageInspections;
