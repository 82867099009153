import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Drawer, Form, Input, message } from "antd";
import dayjs from "dayjs";
import JoditEditor from "jodit-react";
import { useMemo, useState } from "react";
import FModal from "src/common/components/dialogs/FModal";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import {
  GenerateCustomSummaryInput,
  useGenerateCustomSummaryMutation,
  useGetProjectSummaryLazyQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectReportsDownloadQuery$data } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import IncidentReportFields, {
  IncidentReportFieldsFormVals,
} from "src/domain-features/incident-management/components/basic/IncidentReportFields";
import ObservationReportFields, {
  ObservationReportFieldsFormVals,
} from "src/domain-features/observation/components/ObservationReportFields";
import { JoditConfig } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import { useCopyToClipboard } from "usehooks-ts";
import { ShowSummary } from "../../project-summary/GCProjectSummaryList";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

interface FormVals
  extends IncidentReportFieldsFormVals,
    ObservationReportFieldsFormVals {
  dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  subIds: string[];
  includeProjectSummary?: boolean;
  projectSummary?: string;
  manHours?: number;
  includeIncidents?: boolean;
  includeIncidentsCharts?: boolean;
  includeIncidentsSummary?: boolean;
  includeObservations?: boolean;
  includeObsCharts?: boolean;
  includeObsSummary?: boolean;
}

const MonthlyReportCreator: React.FC<{
  visible: boolean;
  handleClose: () => void;
  projectId: string;
  data: GCProjectReportsDownloadQuery$data;
}> = ({ visible, handleClose, projectId, data }) => {
  const [form] = Form.useForm<FormVals>();

  const [showProjectSummary, setShowProjectSummary] = useState(false);
  const [showIncidents, setShowIncidents] = useState(false);
  const [showObservations, setShowObservations] = useState(false);
  const [dateRangeSelected, setDateRangeSelected] = useState(false);

  const [getProjectSummary, { data: projectSummaryData }] =
    useGetProjectSummaryLazyQuery();
  const [generateCustomSummary, { loading: generatingSummary }] =
    useGenerateCustomSummaryMutation();
  const [copiedText, copyFn] = useCopyToClipboard();

  const summaries = useMemo(() => {
    if (!projectSummaryData) return [];

    return [...projectSummaryData.project_summary].sort(
      (a, b) =>
        new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
    );
  }, [projectSummaryData]);

  const handleValuesChange = (changedValues: any) => {
    if ("includeProjectSummary" in changedValues) {
      setShowProjectSummary(changedValues.includeProjectSummary);
    } else if ("includeIncidents" in changedValues) {
      setShowIncidents(changedValues.includeIncidents);
    } else if ("includeObservations" in changedValues) {
      setShowObservations(changedValues.includeObservations);
    } else if ("dateRange" in changedValues) {
      if (changedValues.dateRange) {
        setDateRangeSelected(true);

        const startDate = changedValues.dateRange[0];
        const endDate = changedValues.dateRange[1];

        getProjectSummary({
          variables: {
            where: {
              archived_at: { _is_null: true },
              deleted_at: { _is_null: true },
              project_id: { _eq: projectId },
              start_date: { _lte: endDate },
              end_date: { _gte: startDate },
            },
          },
        });
      } else {
        setDateRangeSelected(false);
      }
    }
  };

  const subOptions = useMemo(
    () =>
      data.project_subcontractor_connection.edges.map((sub) => ({
        label: sub.node.subcontractor.name,
        value: sub.node.subcontractor_id,
      })),
    [data],
  );

  const incidentTypeOptions = useMemo(
    () =>
      data?.incident_type_connection.edges.map((p) => ({
        label: p.node.translation.en,
        value: p.node.value,
      })) || [],
    [data],
  );

  const injuryTypeOptions = useMemo(
    () =>
      data.injury_type_connection?.edges.map((p) => ({
        label: p.node.translation.en,
        value: p.node.value,
      })) || [],
    [data],
  );

  const injurySeverityOptions = useMemo(
    () =>
      data?.injury_severity.edges.map((p) => ({
        label: p.node.name.en,
        value: p.node.id,
      })) || [],
    [data],
  );
  const injuryCauseOptions = useMemo(
    () =>
      data?.injury_cause_connection.edges.map((p) => ({
        label: p.node.translation.en,
        value: p.node.value,
      })) || [],
    [data],
  );
  const bodyPartOptions = useMemo(
    () =>
      data?.injury_body_part_affected_connection.edges.map((p) => ({
        label: p.node.translation.en,
        value: p.node.value,
      })) || [],
    [data],
  );

  const showCompleted =
    data.project_connection.edges[0].node.project_setting
      ?.require_gc_to_mark_observation_complete;
  const statusCheckboxOptions = useMemo(() => {
    const statusOptions = [
      { label: "Open", value: "open" },
      { label: "Corrected", value: "corrected" },
    ];
    if (showCompleted) {
      statusOptions.push({ label: "Completed", value: "completed" });
    }

    statusOptions.push({ label: "Safe", value: "safe" });
    statusOptions.push({ label: "All", value: "all" });
    return statusOptions;
  }, [showCompleted]);

  const handleDownload = async () => {
    const values = await form.validateFields().catch(() => null);
    if (!values) return;

    const timezone = dayjs.tz.guess() || "America/New_York";

    const startDate = values.dateRange[0]
      ? values.dateRange[0].format("YYYY-MM-DD")
      : null;

    const endDate = values.dateRange[1]
      ? values.dateRange[1].format("YYYY-MM-DD")
      : null;
    if (!startDate || !endDate) {
      message.error("Please select start and end date");
      return;
    }
    const statuses =
      values.includeObservations && values.statuses.includes("all")
        ? []
        : values.statuses;
    const incidentFilters: GenerateCustomSummaryInput["incidentFilters"] =
      values.includeIncidents
        ? {
            bodyPartsAffected: values.body_parts_affected,
            incidentTypes: values.incident_types,
            injuryCauses: values.injury_causes,
            injurySeverityIds: values.injury_severities,
            injuryTypes: values.injury_types,
            includeSummary: values.includeIncidentsSummary,
            includeCharts: values.includeIncidentsCharts,
          }
        : null;
    const observationFilters: GenerateCustomSummaryInput["observationFilters"] =
      values.includeObservations
        ? {
            categoryIds: values.category_ids,
            categoryTypeIds: values.category_type_ids,
            riskLevels: values.risk_levels,
            statuses,
            includeSummary: values.includeObsSummary,
            includeCharts: values.includeObsCharts,
          }
        : null;
    const manhours =
      values.manHours && !isNaN(values.manHours)
        ? Number(values.manHours)
        : undefined;
    const input: GenerateCustomSummaryInput = {
      timezone,
      startDate,
      endDate,
      manhours,
      subIds: values.subIds,
      projectId: projectId,
      ...(values.includeProjectSummary
        ? { projSummary: values.projectSummary }
        : {}),
      incidentFilters,
      observationFilters,
    };
    console.log(input);
    const { data } = await generateCustomSummary({ variables: { input } });
    if (data) {
      downloadFromUrl(data.generateCustomSummary);
    } else throw new Error("Null returned as data for generating summary");
  };
  const initialVals: FormVals = {
    dateRange: [null, null],
    statuses: ["open"],
    includeIncidentsCharts: true,
    includeIncidentsSummary: true,
    includeObsCharts: true,
    includeObsSummary: true,
    body_parts_affected: [],
    category_ids: [],
    category_type_ids: [],
    incident_statuses: [],
    incident_types: [],
    injury_causes: [],
    injury_severities: [],
    injury_types: [],
    risk_levels: [],
    subIds: [],
  };
  return (
    <Drawer
      title="Project Summary Report"
      width={"60%"}
      open={visible}
      onClose={handleClose}
      destroyOnClose
      footer={
        <div className="flex gap-1">
          <Button loading={generatingSummary} onClick={handleClose}>
            Close
          </Button>
          <Button
            type={"primary"}
            icon={<DownloadOutlined />}
            loading={generatingSummary}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout={`horizontal`}
        initialValues={initialVals}
        className="flex flex-col gap-1"
        onValuesChange={handleValuesChange}
      >
        <Card title="Report Configuration">
          <Form.Item
            name="dateRange"
            label="Date Range"
            rules={[{ required: true, message: "Select a date range" }]}
          >
            <DatePicker.RangePicker
              className="min-w-12"
              maxDate={dayjs()}
              presets={[
                {
                  label: "This week",
                  value: [dayjs().startOf("week"), dayjs()],
                },
                {
                  label: "Last week",
                  value: [
                    dayjs().subtract(1, "week").startOf("week"),
                    dayjs().subtract(1, "week").endOf("week"),
                  ],
                },
                {
                  label: "This month",
                  value: [dayjs().startOf("month"), dayjs()],
                },
                {
                  label: "Last month",
                  value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                },
                {
                  label: "This year",
                  value: [dayjs().startOf("year"), dayjs()],
                },
                {
                  label: "Last year",
                  value: [
                    dayjs().subtract(1, "year").startOf("year"),
                    dayjs().subtract(1, "year").endOf("year"),
                  ],
                },
              ]}
            />
          </Form.Item>
          <FModal.Select
            name={"subIds"}
            label="Company"
            props={{
              mode: "multiple",
              placeholder: "All Companies Included",
              allowClear: true,
              showSearch: true,
              filterOption: getNormalSelectOptionsFilter,
              options: subOptions,
            }}
          />
        </Card>
        <Card title="Project Summary">
          <FModal.Checkbox name="includeProjectSummary">
            Include a Project Summary
          </FModal.Checkbox>
          {showProjectSummary && (
            <div className="ml-1.5">
              {dateRangeSelected && summaries && summaries.length > 0 && (
                <div className="flex flex-col gap-y-1">
                  <div className="text-1.2">Project Summaries</div>
                  {summaries.map((summary) => (
                    <ShowSummary
                      summary={summary}
                      key={summary.id}
                      projectId={projectId}
                      showCopyIcon
                      onCopy={() => {
                        if (!form.getFieldValue("projectSummary")?.trim()) {
                          form.setFieldValue("projectSummary", summary.text);
                        } else {
                          copyFn(summary.text);
                          message.success("Previous Entry Copied to Clipboard");
                        }
                      }}
                    />
                  ))}
                </div>
              )}
              <div className="mt-1">
                <Form.Item name="projectSummary">
                  <JoditEditor
                    value=""
                    config={{ ...JoditConfig, height: 100 }}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <Form.Item label="Manhours" name="manHours">
            <Input
              inputMode="numeric"
              type="number"
              placeholder="Enter the total manhours on the project to date"
            />
          </Form.Item>
        </Card>
        <Card
          title={
            <FModal.Checkbox name="includeIncidents" className="mb-0">
              <span className="text-1.2 ml-0.5 font-accent">Incidents</span>
            </FModal.Checkbox>
          }
          classNames={showIncidents ? undefined : { body: "hidden" }}
        >
          {showIncidents && (
            <div>
              <IncidentReportFields
                incidentTypeOptions={incidentTypeOptions}
                injuryTypeOptions={injuryTypeOptions}
                injurySeverityOptions={injurySeverityOptions}
                injuryCauseOptions={injuryCauseOptions}
                bodyPartOptions={bodyPartOptions}
              />
              <FModal.Checkbox name="includeIncidentsCharts">
                Include Charts
              </FModal.Checkbox>
              <FModal.Checkbox name="includeIncidentsSummary">
                Include Summary
              </FModal.Checkbox>
            </div>
          )}
        </Card>
        <Card
          title={
            <FModal.Checkbox name="includeObservations" className="mb-0">
              <span className="text-1.2 ml-0.5 font-accent">
                Safety Audits & Observations
              </span>
            </FModal.Checkbox>
          }
          classNames={showObservations ? undefined : { body: "hidden" }}
        >
          {showObservations && (
            <div>
              <ObservationReportFields
                riskOptions={data.risk_level_connection.edges.map((risk) => ({
                  value: risk.node.value,
                  label: risk.node.name,
                  color_hex: risk.node.color_hex,
                }))}
                categoryOptions={data.observation_category_type_connection.edges.map(
                  (cateType) => ({
                    value: cateType.node.pk,
                    label: cateType.node.name.en,
                    subCategories: cateType.node.observation_categories.map(
                      (cate) => ({
                        value: cate.pk,
                        label: cate.name.en,
                      }),
                    ),
                  }),
                )}
                statusOptions={statusCheckboxOptions}
              />
              <FModal.Checkbox name="includeObsCharts">
                Include Charts
              </FModal.Checkbox>
              <FModal.Checkbox name="includeObsSummary">
                Include Summary
              </FModal.Checkbox>
            </div>
          )}
        </Card>
      </Form>
    </Drawer>
  );
};
export default MonthlyReportCreator;
