import { FC, useState } from "react";
import {
  useGetIncidentFilterDataQuery,
  useInsertIncidentMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useNavigate, useParams } from "react-router-dom";
import GCIncidentsPage from "./components/GCIncidentsPage";
import withCustomSuspense from "../../../../common/components/general/withCustomSuspense";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import { IconPlus } from "@tabler/icons";
import AddIncidentModal from "../../components/modals/AddIncidentModal";

const GCProjectIncidentsTable: FC = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("Project id not found");
  const navigate = useNavigate();
  const [addNewIncident, setAddNewIncident] = useState(false);
  const [insertIncident, { loading }] = useInsertIncidentMutation();
  const authUser = useAuthUser();
  const { data } = useGetIncidentFilterDataQuery({
    variables: { projectId },
  });
  
  const addIncident = async (values: Array<string>) => {
    const comment = `Incident Report created`;
    const res = await insertIncident({
      variables: {
        object: {
          project_id: projectId,
          incident_types: { data: values.map((val) => ({ type_value: val })) },
          submitted_by_uid: authUser.uid,
          equipment_incident: { data: {} },
          theft_incident: { data: {} },
          near_miss_incident: { data: {} },
          environmental_incident: { data: {} },
          vehicle_incident: { data: {} },
          property_damage_incident: { data: {} },
          utility_incident: { data: {} },
          root_cause_analysis: { data: {} },
          status: "open",
          incident_edits: {
            data: [
              {
                comment: comment,
                edited_by_uid: authUser.uid,
                patch: [],
                edit_type: "log",
              },
            ],
          },
        },
      },
    });
    const updatedIncident = res.data?.insert_incident_one;
    if (updatedIncident?.id) {
      navigate(`${updatedIncident?.id}?subview=project_info`);
    }
  };

  return (
    <>
      <AddIncidentModal
        onCancel={() => setAddNewIncident(false)}
        onCreate={async (values) => {
          addIncident(values.types);
          setAddNewIncident(false);
        }}
        visible={addNewIncident}
      />
      <GCIncidentsPage
        where={{
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
        }}
        optionsData={data}
        excludedKeys={["project"]}
        topBarButtons={[
          {
            label: "New Incident",
            onClick: () => setAddNewIncident(true),
            icon: IconPlus,
            loading,
          },
        ]}
      />
    </>
  );
};

export default withCustomSuspense(GCProjectIncidentsTable);
