import { Layout } from "antd";
import AppLayoutSideMenu, {
  AppLayoutSideMenuProps,
} from "./side-menu/AppLayoutSideMenu";
import React from "react";
import { AppNavigationLayoutProvider } from "./utils/AppNavigationLayoutContext";
import AppNavigationLayoutHeader from "./header/AppNavigationLayoutHeader";

interface AppLayoutProps {
  sideMenu?: AppLayoutSideMenuProps;
  children?: React.ReactNode;
}

const AppNavigationLayout: React.FC<AppLayoutProps> = (props) => {
  // TODO implement breadcrumbs
  return (
    <AppNavigationLayoutProvider>
      <Layout className={`w-full bg-white`} hasSider>
        {/* Side Menu */}
        {!!props.sideMenu && <AppLayoutSideMenu {...props.sideMenu} />}
        <Layout.Content className={`w-full min-h-screen bg-white`}>
          {/*Header*/}
          <AppNavigationLayoutHeader />
          {/*Content*/}
          <div className={`w-full p-1 desktop:p-2`}>{props.children}</div>
        </Layout.Content>
      </Layout>
    </AppNavigationLayoutProvider>
  );
};

export default AppNavigationLayout;
