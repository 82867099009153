import React from "react";
import BlockOutDeliveryTimesTableColumn from "./BlockOutDeliveryTimesTableColumn";
import { TimeBlockType, TimeRange } from "./type";
import { Skeleton } from "antd";

interface BlockOutDeliveryTimesTableProps {
  timesTable: TimeBlockType[][];
  removeTimeBlock: (timeBlockId: string) => void;
  addTimeBlock: (time: TimeRange, weekDayNumber: number) => void;
  loading?: boolean;
}

const BlockOutDeliveryTimesTable: React.FunctionComponent<
  BlockOutDeliveryTimesTableProps
> = (props) => {
  return (
    <div
      className={`flex flex-row gap-px ${props.loading ? "cursor-wait" : ""}`}
    >
      {props.timesTable.map((weekDayTimes, i) => (
        <Skeleton loading={props.loading}>
          <div className={`flex-1`}>
            <BlockOutDeliveryTimesTableColumn
              weekDay={i}
              times={weekDayTimes.map((weekDayTimeBlock, j) => ({
                ...weekDayTimeBlock.time,
                onRemove: () => {
                  props.removeTimeBlock(weekDayTimeBlock.id);
                },
              }))}
              onCreateNewTimeBlock={(time) => {
                props.addTimeBlock(time, i);
              }}
            />
          </div>
        </Skeleton>
      ))}
    </div>
  );
};

export default BlockOutDeliveryTimesTable;
