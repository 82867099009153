export const PROCORE_CLIENT_ID_DEV =
  "31d26d681768b703be90af905e76be451fe32efd300fd6cffeba6cc4d2e2076f";
export const PROCORE_CLIENT_ID_PROD =
  "20b570270d2ffcd88494338889ea498b54af4edf2e2e3acc25c37b8cb40262f9";

export const PROCORE_DMSA_CLIENT_ID_DEV = '7dc8aa99fa363ceb8b2a2cb13f63773a75274f1708f1da0a7af64c295017bc22'; 
export const PROCORE_DMSA_CLIENT_ID_PROD = '17pt0_hF6pOBjUJFJ70UUFmDZhStTifPoIJxo5uESNw'; 

export const PROCORE_SERVER_DEV = "login-sandbox.procore.com";
export const PROCORE_SERVER_PROD = "login.procore.com";
