import LoadingContent from "../../../../../../common/components/general/loading-fallback/LoadingContent";

const GCNavigationHierarchyMenuLoadingFallback: React.FC = () => {
  return (
    <div className={`p-1`}>
      <LoadingContent />
    </div>
  );
};

export default GCNavigationHierarchyMenuLoadingFallback;
