import { useSuspenseQuery } from "@apollo/client";
import { Button, Input, Table, message } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  GetIncidentNotesDocument,
  GetIncidentNotesQuery,
  GetIncidentNotesQueryVariables,
  useInsertIncidentNotesMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import IncidentSubViewLayout from "../IncidentSubViewLayout";

const { TextArea } = Input;

const IncidentNotesTable: FC<{ projectId: string; incidentId: string }> = ({
  projectId,
  incidentId,
}) => {
  const authUser = useAuthUser();
  const [textAreaValue, setTextAreaValue] = useState(``);
  const [onAddHandler, { loading }] = useInsertIncidentNotesMutation();
  const variables = {
    where: {
      project_id: { _eq: projectId },
      incident_id: { _eq: incidentId },
    },
  };
  const { data, refetch } = useSuspenseQuery<
    GetIncidentNotesQuery,
    GetIncidentNotesQueryVariables
  >(GetIncidentNotesDocument, { variables, fetchPolicy: "cache-and-network" });
  //console.log(taskNotes);
  const dataSource = data.incident_note.map((current) => {
    let obj = {
      createdAt: current.created_at,
      createdBy:
        current.created_by_user.name +
        (current.created_by_user.employee?.employee_title
          ? `, ${current.created_by_user.employee.employee_title.name.en}`
          : ""),
      text: current.text.en || current.text.original,
      key: current.id,
    };
    return obj;
  });
  const onChange = (e: any) => {
    setTextAreaValue(e.target.value);
  };
  const addNote = async () => {
    if (textAreaValue.trim() !== "") {
      await onAddHandler({
        variables: {
          object: {
            created_by_uid: authUser.uid,
            incident_id: incidentId,
            project_id: projectId,
            text: {
              data: { original: textAreaValue, en: textAreaValue, lang: "en" },
            },
          },
        },
        update: (cache, returnData) => {
          if (returnData.data?.insert_incident_note_one)
            cache.writeQuery<GetIncidentNotesQuery>({
              data: {
                incident_note: [
                  returnData.data?.insert_incident_note_one,
                  ...data.incident_note,
                ],
                __typename: "query_root",
              },
              variables,
              query: GetIncidentNotesDocument,
            });
        },
      });
      message.success("successfully added note");
    }
    setTextAreaValue("");
  };
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  const columns = [
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "25%",
      render: (d: string | number | Date) =>
        d
          ? dayjs
              .tz(dayjs(new Date(d)).format("LLL"), dayjs.tz.guess())
              .format("MMMM DD YYYY, hh:mm a")
          : "",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",

      width: "25%",
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      width: "50%",
    },
  ];

  return (
    <IncidentSubViewLayout title="Incident Notes">
      <div className="flex flex-col gap-1">
        <Table columns={columns} dataSource={dataSource} bordered />
        <TextArea
          disabled={loading}
          value={textAreaValue}
          onChange={onChange}
          placeholder="You can document any Incident activity here"
        />
        <div>
          <Button
            loading={loading}
            onClick={addNote}
            type="primary"
            style={{ marginTop: "20px" }}
          >
            Add note
          </Button>
        </div>
      </div>
    </IncidentSubViewLayout>
  );
};

export default withCustomSuspense(IncidentNotesTable);
