import { Typography } from "antd";

interface IncidentSubViewLayoutProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

const IncidentSubViewLayout: React.FC<IncidentSubViewLayoutProps> = (props) => {
  return (
    <div className={`flex flex-col gap-1 w-full`}>
      <Typography.Title className={`mb-0`} level={2}>
        {props.title}
      </Typography.Title>
      {props.children}
    </div>
  );
};

export default IncidentSubViewLayout;
