import VideoPlayerSequenceDemo from "./sequence/VideoPlayerSequenceDemo";
import VideoPlayerDemoDefault from "./default/VideoPlayerDemoDefault";
import Separator from "../../../../../common/components/general/Separator";
import VideoElementDemo from "./video-tag/VideoElementDemo";
import { Typography } from "antd";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";

const VideoPlayerDemo: React.FC = () => {
  return (
    <AppPageLayout pageTitle={"Video Player"}>
      <div className={`flex flex-col gap-1`}>
        <Typography.Title level={2}>Simple Video Player</Typography.Title>
        <VideoPlayerDemoDefault />
        <Separator />
        <Typography.Title level={2}>Video Sequence</Typography.Title>
        <VideoPlayerSequenceDemo />
        <VideoElementDemo />
      </div>
    </AppPageLayout>
  );
};

export default VideoPlayerDemo;
