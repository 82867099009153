import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { auth } from "src/common/functions/firebase";
import { GCAddNewProjectQuery } from "src/common/types/generated/relay/GCAddNewProjectQuery.graphql";
import GCNewProjectStepGeneral from "./steps/GCNewProjectStepGeneral";
import GCNewProjectStepCoWorkers from "./steps/GCNewProjectStepCoWorkers";
import GCNewProjectStepSubcontractos from "./steps/GCNewProjectStepSubcontractors";

const query = graphql`
  query GCAddNewProjectQuery($uid: uuid!) {
    user_connection(where: { id: { _eq: $uid } }) {
      edges {
        node {
          employee {
            general_contractor_id
            general_contractor {
              require_dob
              require_phone
              agc_universal_orientation
              gc_orientations(where: { type: { _eq: "corporate" } }) {
                pk: id @__clientField(handle: "pk")
              }
            }
          }
        }
      }
    }
    general_contractor_employee_connection(
      where: { general_contractor: { employees: { uid: { _eq: $uid } } } }
      order_by: { user: { name: asc } }
    ) {
      edges {
        node {
          uid
          user {
            id
            name
          }
        }
      }
    }
    subcontractor_connection(
      where: {
        subcontractor_projects: {
          project: { general_contractor: { employees: { uid: { _eq: $uid } } } }
        }
      }
      order_by: { name: asc }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
    state_connection(order_by: { name: asc }) {
      edges {
        node {
          name
          code
        }
      }
    }
  }
`;

const GCAddNewProject: React.FC = () => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error("Authentication error: user must be authenticated");
  }
  const data = useLazyLoadQuery<GCAddNewProjectQuery>(query, {
    uid: userId,
  });

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [projectId, setProjectId] = useState<string | null>(null);

  const navigate = useNavigate();
  if (!data.user_connection.edges[0]?.node?.employee?.general_contractor_id) {
    throw new Error("User must be gc employ to add new project");
  }
  const gcId =
    data.user_connection.edges[0].node.employee.general_contractor_id;
  const gc = data.user_connection.edges[0].node.employee.general_contractor;
  const finish = () => {
    navigate(`/gce/hierarchy/project/${projectId}`);
  };

  const goNext = () => {
    setCurrentStep((prevState) => {
      const newState = prevState + 1;
      if (newState < formSteps.length) {
        return newState;
      } else {
        finish();
        return prevState;
      }
    });
  };

  const formSteps = [
    GCNewProjectStepGeneral,
    GCNewProjectStepCoWorkers,
    GCNewProjectStepSubcontractos,
  ];

  return (
    <BasicWrapper scrollable>
      <div className="flex flex-col w-48 gap-2">
        <p className="text-2">Your new project</p>
        <div className="flex flex-col gap-2">
          <GCNewProjectStepGeneral
            userId={userId}
            gcData={{
              id: gcId,
              requireDob: gc.require_dob,
              agcUniversalOrientation: gc.agc_universal_orientation,
              requirePhone: gc.require_phone,
              corporateOrientations: gc.gc_orientations.map((o) => o.pk),
            }}
            onProjectCreated={setProjectId}
            states={data.state_connection}
            open={currentStep === 0}
            goToNext={goNext}
          />
          {projectId && (
            <GCNewProjectStepCoWorkers
              userId={userId}
              gcId={gcId}
              projectId={projectId}
              gcemployee={data.general_contractor_employee_connection}
              open={currentStep === 1}
              goToNext={goNext}
            />
          )}
          {projectId && (
            <GCNewProjectStepSubcontractos
              projectId={projectId}
              subcontractors={data.subcontractor_connection}
              open={currentStep === 2}
              goToNext={goNext}
            />
          )}
        </div>
      </div>
    </BasicWrapper>
  );
};

export default GCAddNewProject;
