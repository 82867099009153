import React, { FC } from "react";
// Lib
import { Card, Col, Image, Space, Typography } from "antd";
import { HeatMapOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import capitalize from "src/common/functions/capitalize";
import { TablePaginationConfig } from "antd/lib/table";
// Components
import BaseTable from "src/common/components/tables/basic/BaseTable";
import Meta from "antd/lib/card/Meta";
import { titleCase } from "src/common/functions/stringFormatters";

const { Text } = Typography;

export interface IWorkersTableProps {
  data: any[] | undefined;
  caption?: string;
  columns?: any;
}

export interface PermitPhotoSignInImagesProps {
  dataSource: Array<{ id: string; url: string }>;
  imageTitle: string;
}

export const PermitImages = ({
  dataSource,
  imageTitle,
}: PermitPhotoSignInImagesProps) => {
  if (dataSource.length === 0) return <></>;
  return (
    <div>
      <p style={{ marginLeft: "1rem", fontWeight: "bold", fontSize: "17px" }}>
        Permit {imageTitle} Images
      </p>
      <div className="flex gap-0.5 flex-wrap mt-0.5 mb-1 ml-1">
        {dataSource.map((p, i) => (
          <Card
            key={p.id}
            className="w-1/6"
            styles={{
              body: {
                padding: 2,
                borderColor: "black",
                borderWidth: 1,
                borderStyle: "solid",
              },
            }}
          >
            <div className="relative z-0">
              <Image className="z-0" src={p.url} alt={"Image: " + (i + 1)} />
            </div>
            <Meta
              title={
                imageTitle + " Image" + (dataSource.length > 1 ? i + 1 : "")
              }
            />
          </Card>
        ))}
      </div>
    </div>
  );
};
export const WorkersTable = ({ data, caption }: IWorkersTableProps) => {
  const columns = [
    {
      title: "Name",
      dataIndex: ["project_worker"],
      key: "workerName",
      render: (worker: any) => (
        <span style={{ lineHeight: "1.2rem" }}>
          <p>{worker.user.name}</p>
          {worker.hard_hat_number && <p>Hard Hat# {worker.hard_hat_number}</p>}
          {worker.user.phone_number && (
            <p>Contact# {worker.user.phone_number}</p>
          )}
        </span>
      ),
    },
    {
      title: "Trade",
      key: "workerTrade",
      dataIndex: ["project_worker", "title", "translation", "clientText"],
    },
    {
      title: "Role",
      key: "workerRole",
      dataIndex: ["project_worker", "worker_role"],
    },
  ];
  if (
    (data?.filter((e) => {
      return e.signature_image;
    })?.length ?? 0) > 0
  ) {
    columns.push({
      title: "Signature",
      key: "workerSignature",
      dataIndex: ["signature_image", "url"],
      render: (image: any) => (
        <span>
          {image ? (
            <img src={image} alt="worker signature" width={200} />
          ) : (
            <p>Photo Sign In</p>
          )}
        </span>
      ),
    });
  }

  return (
    <Col span={24}>
      <Text className="text-1.2 mt-1">{caption || ""}</Text>
      <BaseTable pagination={false} columns={columns} dataSource={data} />
    </Col>
  );
};

export const SupervisorsTable = ({ data }: IWorkersTableProps) => {
  const columns = [
    {
      title: "Name",
      dataIndex: ["project_worker"],
      key: "workerName",
      render: (worker: any) => (
        <span style={{ lineHeight: "1.2rem" }}>
          <p>{titleCase(worker.user.name)}</p>
          {worker.hard_hat_number && <p>Hard Hat# {worker.hard_hat_number}</p>}
          {worker.user.phone_number && (
            <p>Contact# {worker.user.phone_number}</p>
          )}
        </span>
      ),
    },
    {
      title: "Trade",
      key: "workerTrade",
      dataIndex: ["project_worker", "title", "translation", "clientText"],
    },
    {
      title: "Role",
      key: "workerRole",
      dataIndex: ["project_worker", "worker_role"],
      render: (role: any) => capitalize(role),
    },
  ];
  if (
    (data?.filter((e) => {
      return e.signature_image;
    })?.length ?? 0) > 0
  ) {
    columns.push({
      title: "Signature",
      key: "workerSignature",
      dataIndex: ["signature_image", "url"],
      render: (image: any) => (
        <span>
          {image ? (
            <img src={image} alt="worker signature" width={200} />
          ) : (
            <p>Photo Sign In</p>
          )}
        </span>
      ),
    });
  }
  return (
    <Col span={24}>
      <Text className="text-1.2 ">Supervisors</Text>
      <BaseTable pagination={false} columns={columns} dataSource={data} />
    </Col>
  );
};

export interface IChecklistTableProps {
  title: string;
  data: any;
}

export const ChecklistTable: FC<IChecklistTableProps> = ({ title, data }) => {
  const columns = [
    {
      title: "Description",
      key: "checklistDescription",
      dataIndex: ["description", "clientText"],
    },
    {
      title: "Answer",
      key: "checklistAnswer",
      dataIndex: ["option_response"],
    },
    {
      title: "Worker Response (if required)",
      key: "checklistResponse",
      dataIndex: ["text_response", "clientText"],
    },
  ];
  return (
    <Col span={24}>
      <Text>{title}</Text>
      <BaseTable pagination={false} columns={columns} dataSource={data} />
    </Col>
  );
};

export interface IMonitoringEquipmentTableProps {
  dataSource: any;
  caption?: string | undefined;
}

export const MonitoringEquipmentTable: FC<IMonitoringEquipmentTableProps> = ({
  dataSource,
  caption,
}) => {
  const columns = [
    {
      key: "equipmentMake",
      title: "Make",
      dataIndex: "make",
    },
    {
      key: "equipmentModel",
      title: "Model",
      dataIndex: "model",
    },
    {
      key: "serialNumber",
      title: "Serial #",
      dataIndex: "serial_number",
      render: (status: string) => capitalize(status),
    },
    {
      key: "factoryCalibrationDate",
      title: "Factory Calibration Date",
      dataIndex: "factory_calibration_date",
      render: (d?: string) => (d ? dayjs(d).format("lll") : ""),
    },
    {
      key: "fieldCalibrationDate",
      title: "Field Calibration Date",
      dataIndex: "field_calibration_date",
      render: (d?: string) => (d ? dayjs(d).format("lll") : ""),
    },
    {
      key: "calibratedBy",
      title: "Calibrated By",
      dataIndex: "calibrated_by",
    },
  ];
  return (
    <Col span={24}>
      <Text>{caption || ""}</Text>
      <BaseTable pagination={false} columns={columns} dataSource={dataSource} />
    </Col>
  );
};
type Validator = (val: any) => boolean;
type ValidationFunc = (val: string, validator: Validator) => object;
export const EquipmentReadingsTable: FC<IMonitoringEquipmentTableProps> = ({
  dataSource,
  caption,
}) => {
  const o2IsValid: Validator = (o2) =>
    !!o2 ? Number(o2) >= 19.5 && Number(o2) <= 23.5 : true;
  const lelIsValid: Validator = (lel) => (!!lel ? Number(lel) < 10 : true);
  const coIsValid: Validator = (co) => (!!co ? Number(co) < 25 : true);
  const h2sIsValid: Validator = (h2s) => (!!h2s ? Number(h2s) < 10 : true);
  const isValid: ValidationFunc = (
    reading,
    cb,
  ): { style: object; icon: React.ReactNode } =>
    cb(reading)
      ? { style: { color: "red" }, icon: <HeatMapOutlined /> }
      : { style: { color: "inherit" }, icon: null };

  const columns = [
    {
      key: "sampleTime",
      title: "Time Taken",
      dataIndex: "taken_at",
      render: (d?: string) => (d ? dayjs(d).format("lll") : ""),
    },
    {
      key: "percentOxygen",
      title: "% Oxygen",
      dataIndex: "o2_percent",
      render: (val: any) => {
        const result: any = isValid(val, o2IsValid);
        return (
          <span style={result.style}>
            <Space size="small">
              {result.icon}
              {`${val}%`}
            </Space>
          </span>
        );
      },
    },
    {
      key: "lowerExplosiveLimit",
      title: "Lower Explosive Limit (LEL)",
      dataIndex: "lower_explosive_limit",
      render: (val: any) => {
        const result: any = isValid(val, lelIsValid);
        return (
          <span style={result.style}>
            <Space size="small">
              {result.icon}
              {`${val}%`}
            </Space>
          </span>
        );
      },
    },
    {
      key: "carbonMonoxide",
      title: "Carbon Monoxide (CO)",
      dataIndex: "co_ppm",
      render: (val: any) => {
        const result: any = isValid(val, coIsValid);
        return (
          <span style={result.style}>
            <Space size="small">
              {result.icon}
              {`${val} PPM`}
            </Space>
          </span>
        );
      },
    },
    {
      key: "hydrogenSulfide",
      title: "Hydrogen Sulfide (H2S)",
      dataIndex: "h2s_ppm",
      render: (val: any) => {
        const result: any = isValid(val, h2sIsValid);
        return (
          <span style={result.style}>
            <Space size="small">
              {result.icon}
              {`${val} PPM`}
            </Space>
          </span>
        );
      },
    },
    // {
    //   // TODO: add button to launch lightbox with image
    //   key: 'readingImages',
    //   title: 'Images',
    //   dataIndex: '',
    // },
    {
      key: "readingBy",
      title: "Reading By",
      dataIndex: ["taken_by_project_worker"],
      render: (worker: any) => (
        <span>
          {titleCase(worker.user.name)}, {worker.title.name}
        </span>
      ),
    },
  ];
  return (
    <Col span={24}>
      <Text>{caption || ""}</Text>
      <BaseTable pagination={false} columns={columns} dataSource={dataSource} />
    </Col>
  );
};
