import {
  Button,
  Card,
  Divider,
  message,
  notification,
  Popconfirm,
  Radio,
  Space,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import ProcoreFolderConfigModal, {
  ProcoreFolderConfigModalRef,
} from "src/common/components/dialogs/ProcoreFolderConfigModal";
import ProcoreProjectConfigModal, {
  ProcoreProjectConfigModalRef,
} from "src/common/components/dialogs/ProcoreProjectConfigModal";
import ProcoreSubConfigModal, {
  ProcoreSubConfigModalRef,
} from "src/common/components/dialogs/ProcoreSubConfigModal";
import ProcoreTradeConfigModal, {
  ProcoreTradeConfigModalRef,
} from "src/common/components/dialogs/ProcoreTradeConfigModal";
import {
  procore_project_data_set_input,
  useGetProjectSettingsDataQuery$data,
} from "src/common/types/generated/relay/useGetProjectSettingsDataQuery.graphql";
import ConnectAsUserButton from "src/common/components/ConnectProcoreUserButton";
import { ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation } from "src/common/types/generated/relay/ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation.graphql";

import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import SwitchWithText from "../../../../../../../common/components/SwitchWithText";

const ChangeProcoreAccountWarning =
  "WARNING: Changing procore account\\user will reset procore configuration for this project, are you sure you wish to contine?";

type ProjectSettingsNode =
  useGetProjectSettingsDataQuery$data["project_connection"]["edges"][number]["node"];

type ProcoreConnectionMethod = "project" | "company" | "dmsa";
type ProcoreDMSACompany = {
  id: string;
  name: string;
};

type ProcoreDMSACompanies = Array<ProcoreDMSACompany>;

const ProcoreProjectIntegrationSettings: React.FC<{
  projectId: string;
  project: ProjectSettingsNode;
  updateProcoreProjectValue: (
    set: procore_project_data_set_input,
  ) => Promise<void>;
}> = ({ projectId, project, updateProcoreProjectValue }) => {
  const { userData } = useUserData();

  const gc = project.general_contractor;
  const auth_data = gc.procore_auth_data;
  const prcore_project_data = project.procore_project_data;

  const company_integration_enabled = !!auth_data?.integration_enabled;
  const has_company_auth = !!(
    auth_data?.service_account_client_id || auth_data?.user_id
  );
  const has_project_auth = !!(
    prcore_project_data?.service_account_client_id ||
    prcore_project_data?.user_id ||
    prcore_project_data?.use_dmsa
  );

  const has_connected_project = !!prcore_project_data?.procore_project_id;
  const procore_company_name = prcore_project_data?.procore_company_name;
  const procore_project_name = prcore_project_data?.procore_project_name;
  //const procore_company_id = prcore_project_data?.procore_company_id;
  const isHigherLevelAdmin = !!(
    project.gc_business_unit?.gc_business_unit_employees_aggregate.aggregate
      ?.count ||
    project.gc_office?.gc_office_employees_aggregate.aggregate?.count ||
    project.gc_division?.gc_division_employees_aggregate.aggregate?.count ||
    userData.employee?.is_corporate_admin
  );
  const switchDisabled = !prcore_project_data || !company_integration_enabled;
  //const [switchDisabled, setSwitchDisabledProject] = useState(
  //    !procoreProject || !has_auth_data
  //);

  const company_sandbox_suffix = auth_data?.sandbox ? " [sandbox]" : "";
  const project_sandbox_suffix = prcore_project_data?.sandbox
    ? " [sandbox]"
    : "";
  const company_conection_state = auth_data?.user_login
    ? `Company procore user: ${auth_data.user_login} ${
        auth_data.user_name || ""
      }${company_sandbox_suffix}`
    : auth_data?.service_account_client_id
    ? `Company service account Client Id: ${auth_data.service_account_client_id}${company_sandbox_suffix}`
    : "authentication method is not enabled";
  const project_connection_state = prcore_project_data?.user_login && !prcore_project_data.use_dmsa 
    ? `Project procore user: ${prcore_project_data.user_login} ${
        prcore_project_data.user_name || ""
      }${project_sandbox_suffix}`
    : prcore_project_data?.service_account_client_id
    ? `Project service account Client Id: ${prcore_project_data.service_account_client_id}${project_sandbox_suffix}`
    : "Setup Procore login for this project.";

  const connect_as_user_title = prcore_project_data?.user_login
    ? "Change Procore User"
    : "Connect as Procore User";

  const select_user_title = prcore_project_data?.has_dmsa_user
    ? "Get Company from Procore User"
    : "Select Procore Company";

  //const completed = project.completed;
  const integrationSwitch =
    prcore_project_data?.integration_enabled && !switchDisabled;
  //const connectionMethod = has_project_auth? 1 : 0;

  console.log(
    "has_company_auth",
    has_company_auth,
    "integrationSwitch",
    integrationSwitch,
  );

  const [connectionMethod, setConnectionMethod] = useState<ProcoreConnectionMethod>(
    has_project_auth ? (prcore_project_data?.use_dmsa ? "dmsa" : "project") : "company",
  );

  const has_credential =
    (has_company_auth && connectionMethod === "company") ||
    (has_project_auth && (connectionMethod === "project" || connectionMethod === "dmsa" && prcore_project_data.has_dmsa_user));

  //  const [procoreProjectSwitch, setProcoreProjectSwitch] = useState(
  //       procoreProject?.integration_enabled && !switchDisabled
  //  );
  const procoreProjectConfigModal = useRef<ProcoreProjectConfigModalRef>(null);
  const procoreSubConfigModal = useRef<ProcoreSubConfigModalRef>(null);
  const procoreFolderConfigModal = useRef<ProcoreFolderConfigModalRef>(null);
  const procoreTradeConfigModal = useRef<ProcoreTradeConfigModalRef>(null);

  const [deleteProcoreProjectData] =
    useAsyncMutation<ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation>(graphql`
      mutation ProcoreProjectIntegrationSettings_deleteProcoreProjectData_Mutation(
        $projectId: uuid!
      ) {
        delete_procore_subcontractor(
          where: { project_id: { _eq: $projectId } }
        ) {
          returning {
            id @deleteRecord
          }
        }
        delete_procore_project_data_by_pk(project_id: $projectId) {
          id @deleteRecord
        }
      }
    `);

  const updateIntegrationSwitch = async (value: boolean) => {
    if (!prcore_project_data) {
      if (!has_company_auth)
        message.error("Please setup procore connection first");
      else message.error("Please setup procore integration first");
      return;
    }
    //if (!has_connected_project)
    //throw new Error("procore project is not configured");
    return await updateProcoreProjectValue({ integration_enabled: value });
  };

  useEffect(() => {
    const search = document.location.search;
    const params = new URLSearchParams(search);
    if (params.has("error")) {
      notification.error({
        message: "Error: " + params.get("error"),
        description: params.get("error_description"),
      });
    }
  }, []);

  const onRadioClicked = async (value: ProcoreConnectionMethod) => {
    //if (value === "company" && prcore_project_data /*&& !is_same_account*/) {
    if (connectionMethod !== value) {
      await deleteProcoreProjectData({
        variables: {
          projectId,
        },
      });
    }
    //}
    setConnectionMethod(value);
  };

  const renderOption = (
    show_warning: boolean,
    value: ProcoreConnectionMethod,
    children: React.ReactNode,
  ) => {
    const clickHandler = () => onRadioClicked(value);
    if (show_warning && connectionMethod !== value) {
      console.log("value " + value + " protected");
      return (
        <Popconfirm
          title={ChangeProcoreAccountWarning}
          onConfirm={clickHandler}
        >
          <Radio style={{ whiteSpace: "break-spaces" }} value={value}>
            {children}
          </Radio>
        </Popconfirm>
      );
    } else {
      console.log("value " + value + " unprotected");
      return (
        <Radio
          style={{ whiteSpace: "break-spaces" }}
          value={value}
          onClick={clickHandler}
        >
          {children}
        </Radio>
      );
    }
  };
  const is_same_account =
    has_company_auth &&
    has_project_auth &&
    (auth_data?.user_id === prcore_project_data?.user_id ||
      auth_data?.service_account_client_id ===
        prcore_project_data?.service_account_client_id);
  const show_warning = has_connected_project; // && !is_same_account;
  // console.log(
  //   "has_connected_project = ",
  //   has_connected_project,
  //   "is_same_acc ",
  //   is_same_account
  // );
  const companyOption = renderOption(
    show_warning,
    "company",
    company_conection_state,
  );
  const projectOption = renderOption(
    show_warning,
    "project",
    project_connection_state,
  );
  const dmsaOption = renderOption(
    show_warning,
    "dmsa",
    "Connect via dmsa" + (procore_company_name ? (` to ${procore_company_name} (${procore_project_name})`): prcore_project_data?.has_dmsa_user ? `, ${prcore_project_data.user_login} [click Project Setup to select company]`: ''),
//    "Connect via dmsa" + (dmsaCompanies ? (" to " + dmsaCompanies): ""),
  );

  return (
    <Card title={`Procore Integration`}>
      <Space
        className={`w-full`}
        size={0}
        split={<Divider />}
        direction={"vertical"}
      >
        {company_integration_enabled && (
          <Space
            size={0}
            className={`w-full`}
            split={<Divider />}
            direction={"vertical"}
          >
            <SwitchWithText
              text={`Procore integration`}
              onChange={async (newValue) => {
                await updateIntegrationSwitch(newValue);
              }}
              checked={!!integrationSwitch}
            />
            <SwitchWithText
              text={`Daily construction reports`}
              checked={
                !!prcore_project_data?.upload_daily_construction_report_enabled
              }
              onChange={async (newValue) =>
                await updateProcoreProjectValue({
                  upload_daily_construction_report_enabled: newValue,
                })
              }
            />

            {has_company_auth && (
              <Radio.Group value={connectionMethod}>
                <div className="flex flex-col gap-0.5">
                  { companyOption }
                  { projectOption }
                  { dmsaOption }
                </div>
              </Radio.Group>
            )}
            {!has_company_auth && project_connection_state}
            {(connectionMethod === "project" || connectionMethod === "dmsa" || !has_company_auth) && (
              <>
                <ConnectAsUserButton
                  title={ connectionMethod === "dmsa" ? select_user_title : connect_as_user_title }
                  projectId={projectId}
                  dmsa={connectionMethod === "dmsa"}
                />
                {has_project_auth && !has_company_auth && (
                  <Popconfirm
                    title="Are you sure you want to clear procore integration configuation?"
                    onConfirm={() =>
                      deleteProcoreProjectData({
                        variables: {
                          projectId,
                        },
                      })
                    }
                    okText="Confirm"
                    cancelText="No"
                  >
                    <Button danger>Clear login data</Button>
                  </Popconfirm>
                )}
              </>
            )}
          </Space>
        )}
        {company_integration_enabled && has_credential && (
          <div className="flex items-start flex-col gap-1">
            <ProcoreProjectConfigModal
              ref={procoreProjectConfigModal}
              projectId={projectId}
            />

            <Button
              onClick={() => {
                procoreProjectConfigModal.current?.open();
              }}
              type="primary"
            >
              Project Setup
            </Button>
            {has_connected_project && (
              <>
                <ProcoreSubConfigModal
                  ref={procoreSubConfigModal}
                  projectId={projectId}
                  GCId={gc.pk}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    procoreSubConfigModal.current?.open();
                  }}
                >
                  Subcontractor Setup
                </Button>
                <ProcoreFolderConfigModal
                  ref={procoreFolderConfigModal}
                  projectId={projectId}
                  GCId={gc.pk}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    procoreFolderConfigModal.current?.open();
                  }}
                >
                  PDF Folder Setup
                </Button>
                <ProcoreTradeConfigModal
                  ref={procoreTradeConfigModal}
                  projectId={projectId}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    procoreTradeConfigModal.current?.open();
                  }}
                >
                  Trade Setup
                </Button>
              </>
            )}
          </div>
        )}
      </Space>
    </Card>
  );
};

export default ProcoreProjectIntegrationSettings;
