import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import LogisticPlan from "../LogisticPlan";
import useGetProjectSettingsData from "../utils/useGetProjectSettingsData";

const GcProjectSettingsLogisticPlans: React.FC<ProjectIdProps> = ({
  projectId,
}) => {
  const { project } = useGetProjectSettingsData(projectId);
  return <LogisticPlan projectId={projectId} project={project} />;
};

export default withProjectIdUrlParam(GcProjectSettingsLogisticPlans);
