import ProjectTable from "src/root/routes/views/general-contractor/hierarchy/components/ProjectTable";

import { useRef, useState } from "react";
import GCAddProject from "../../components/GCAddProject";
import { IconPlus } from "@tabler/icons";
import { message } from "antd";

import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";
import useGcEmployee from "../utils/useGcEmployee";

const GCOrgUnitProjects: React.FC<OrganizationUnitProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  const employee = useGcEmployee();
  const typeKey = `gc_${organizationUnitType}_id` as const;
  const [open, setOpen] = useState(false);
  const ref = useRef<DataScrollTableRef>(null);
  return (
    <>
      {open && (
        <GCAddProject
          onCancel={() => setOpen(false)}
          toInsertWith={{ [typeKey]: organizationUnitId }}
          onCreate={() => {
            message.success("Project Created Successfully");
            setOpen(false);
          }}
        />
      )}
      <ProjectTable
        showLevels={
          organizationUnitType === "division"
            ? ["business-unit", "office"]
            : organizationUnitType === "business_unit"
            ? ["office"]
            : []
        }
        ref={ref}
        topBarButtons={[
          {
            label: "Add New Project",
            icon: IconPlus,
            onClick: () => setOpen(true),
          },
        ]}
        excludedKeys={["gc"]}
        where={{
          general_contractors: {
            general_contractor_id: {
              _eq: employee.general_contractor.id,
            },
          },
          [typeKey]: { _eq: organizationUnitId },
        }}
      />
    </>
  );
};

export default withAppPageLayout(
  withOrganizationUnitWrapper(GCOrgUnitProjects),
  { pageTitle: "Projects" },
);
