import React from "react";
import ErrorMessage from "../../utility-features/error-handling/components/ErrorMessage";
import LoadingContent from "./general/loading-fallback/LoadingContent";

interface ErrorOrLoadingProps {
  loading?: boolean;
  error?: Error;
}

const ErrorOrLoading: React.FC<ErrorOrLoadingProps> = (props) => {
  if (props.error) {
    console.error(props.error);
  }
  return props.loading ? (
    <LoadingContent />
  ) : props.error ? (
    <ErrorMessage message={props.error?.message} />
  ) : (
    <></>
  );
};

export default ErrorOrLoading;
