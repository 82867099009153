import dayjs from "dayjs";
import { DatesRangeType } from "../../types/manual/DatesRange";

const datesFromRange: (datesRange: DatesRangeType) => dayjs.Dayjs[] = (
  datesRange,
) => {
  let date = dayjs(datesRange.from).startOf("day");
  const dates: dayjs.Dayjs[] = [date];
  while (date.isBefore(datesRange.to.startOf("day"))) {
    date = date.add(1, "days");
    dates.push(date);
  }
  return dates;
};

export default datesFromRange;
