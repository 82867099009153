import { Spin } from "antd";
import { browserLocalPersistence, signInWithCustomToken } from "firebase/auth";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import { Task_Select_Column_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns } from "src/common/types/generated/apollo/graphQLTypes";
import SiteOrientationUniversalBirthDate from "src/domain-features/siteorientation/entryRoutes/universal-orientation/components/steps/birthDate/SiteOrientationUniversalBirthDate";

type AuthMethods =  {
  runAuthTask: (executor: () => Promise<void>) => Promise<void>
};

type UserId =   
  | {
      uid: null;
    }
  | {
      uid: string;
      role: string;
    };

type AuthData = AuthMethods & UserId;


const AuthContext = React.createContext<AuthData | null>(null);

interface EmptyProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<EmptyProps> = ({ children }) => {
  const [running, setRunning] = useState(false);
  const transactionCount = useRef<number>(0);
  const transactionState = useRef<UserId>({uid: null});

  const runAuthTask = useCallback(async (executor: () => Promise<void>) => {
    if (transactionCount.current === 0)
        setRunning(true);
    transactionCount.current++;
    try {      
      await executor();
    } finally {
      transactionCount.current--;
      if (transactionCount.current === 0) {
        setRunning(false);
        console.log('setAuthData frmo trans end', transactionState.current);
        setAuthData({ ...transactionState.current, runAuthTask })
      }
    }
  }, []);

  const setUserId = (user: UserId) => {
    if (transactionCount.current > 0) {
      transactionState.current = user;
      console.log('etUserId', transactionState.current);
    } else {
      console.log('setAuthData from setUserId', user);
      setAuthData({ ...user, runAuthTask });
    }
  }

  const [authData, setAuthData] = useState<AuthData>();
  useEffect(() => {
    console.log("AuthProvider: subscribe on auth", auth.currentUser);
    const unsubAuth = auth.onAuthStateChanged((authUser) => {
      console.log("AuthProvider:  auth.onAuthStateChanged", !!authUser);
      if (!authUser) setUserId({ uid: null });
      else {
        authUser.getIdTokenResult().then((res) => {
          console.log('AuthProvider:  getIdTokenResult', typeof res, (typeof(res) === "object")? typeof res.claims : "")
          if (typeof res.claims.role !== "string") {
            throw new Error("token.claims.role is not a string");
          }
          console.log("AuthProvider: uid", authUser.uid, "role", res.claims.role);
          setUserId({ uid: authUser.uid, role: res.claims.role });
        }).catch(error => {
          setAuthData(() => {
            console.log("AuthProvider:  throw auth error from state update")
            throw error;
          })
        });
      }
    });
    return () => {
      console.log('AuthProvider:  unsubscribe auth');
      unsubAuth();
    };
  }, []);
  console.log('render AuthProvider running = ', running);
  if (!authData || running) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full gap-1">
        <h1>Authorizing</h1>
        <Spin />
      </div>
    );
  } else
    return (
      <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
  const user = useContext(AuthContext);
  if (!user) {
    throw new Error("useAuth requires AuthProvider");
  }
  return user;
};

export const withAuthProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <AuthProvider>
        <Component {...props} />
      </AuthProvider>
    );
