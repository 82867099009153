import React, { useRef, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import useSignaturePad from "src/common/components/general/signature-canvas/useSignaturePad";
import useInsertPtp from "../../../utils/useInsertPtp";
import CustomSignatureCanvas from "src/common/components/general/signature-canvas/CustomSignatureCanvas";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface CrewGroupedWorkerListProps extends SiteOrientationStepProps {
  workerId?: string;
  submitterSign?: boolean;
  updateSelectedCrews?: (
    projectWorkerId: string,
    workersPresent: Set<string>,
  ) => void;
}

const PtpQrCodeTaskSign: React.FC<CrewGroupedWorkerListProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const [insertPtp] = useInsertPtp();
  const projWorkerId = props.workerId;
  const { signaturePadProps, isEmpty, uploadSignature } = useSignaturePad();
  const { ptpQrUser, setPtpQrUser } = usePtpQr();
  const [loading, setLoading] = useState(false);
  if (!projWorkerId) throw new Error("projWorkerId not found");

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={async () => {
        setLoading(true);
        try {
          const imageUrl = await uploadSignature();
          const newSet = new Set(ptpQrUser.workersInfo.workersPresent);
          newSet.add(projWorkerId);
          setPtpQrUser((prev) => {
            return {
              ...prev,
              workerSignMap: {
                ...prev.workerSignMap,
                [projWorkerId]: imageUrl,
              },
              workersInfo: {
                ...prev.workersInfo,
                workersPresent: newSet,
              },
            };
          });
          props.updateSelectedCrews &&
            props.updateSelectedCrews(projWorkerId, newSet);
      

          if (!!props?.submitterSign) {
            await insertPtp();
          }
        } finally {
          setLoading(false);
        }
        props.onNext();
      }}
      nextButtonText={langStrings.strings.submit}
      onBackButtonClick={props.onBack}
      loading={loading}
    >
      <SiteFeatureStepsInputLayout headline={langStrings.strings.signYourName}>
        <>
          <CustomSignatureCanvas {...signaturePadProps} />
          <div className="flex flex-col gap-0.5">
            <div className="text-grey text0.5 mb-1">
              {langStrings.strings.bySigningYouAgreeToSiteform}
              <a
                href="https://www.termsfeed.com/terms-conditions/f73fc5afe6a821990e96daebc0b83d39"
                className="underline text-interactive-primary"
                target="_blank"
              >
                {langStrings.strings.termsOfService}
              </a>
              {langStrings.strings.reportAnyUnsafeConditionsToSupervisorOrGc}
            </div>

            <div className="flex flex-col gap-0.5"></div>
          </div>
        </>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrCodeTaskSign;
