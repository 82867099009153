import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { GCSubcontractorQuery } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import GCSubcontractorJHAs from "./jhas/GCSubcontractorJHAs";
import GCSubcontractorSDS from "./sds/GCSubcontractorSDS";
import GCSubcontractorDetail from "./sub-info/GCSubcontractorDetail";
import GCSubcontractorWorkersAndCrews from "src/domain-features/worker-crew/entry-routes/gc/GCSubcontractorWorkersAndCrews";
import TaskInfo from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/TaskInfo";
import GCSubcontractorSSSP from "./safety-plans/GCSubcontractorSSSP";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import useGcEmployee from "../../organization-unit/utils/useGcEmployee";

interface GCSubcontractorProps {
  projectId: string;
  subcontractorId: string;
  subview?: string;
  taskId?: string;
}

export const query = graphql`
  query GCSubcontractorQuery(
    $subId: uuid!
    $projectId: uuid!
    $userId: uuid!
    $gcId: uuid!
    $order: order_by!
    $projectWorkerWhere: project_worker_bool_exp!
  ) {
    ...CrewProjectWorkerOptions_project_worker_connection_frag
      @arguments(projectWorkerWhere: $projectWorkerWhere)
    project_setting_connection(where: { project_id: { _eq: $projectId } }) {
      edges {
        node {
          observation_enabled
        }
      }
    }
    worker_title_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          translation {
            en
            id
          }
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }

    childSubOptions: project_subcontractor_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_id: { _neq: $subId }
      }
      order_by: { subcontractor: { name: asc } }
    ) {
      edges {
        node {
          id
          subcontractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
    parent_child_project_subcontractor_connection(
      where: {
        project_id: { _eq: $projectId }
        parent_subcontractor_id: { _eq: $subId }
      }
      first: 10000
    )
      @connection(
        key: "GCSubcontractorQuery_parent_child_project_subcontractor_connection"
        filters: []
      ) {
      edges {
        node {
          id
          child_subcontractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
    subcontractor_connection(where: { id: { _eq: $subId } }, first: 1)
      @connection(
        key: "GCSubcontractorQuery_subcontractor_connection"
        filters: []
      ) {
      edges {
        node {
          id @__clientField(handle: "pk")
          name
          trade {
            id @__clientField(handle: "pk")
            name
          }
          address {
            id @__clientField(handle: "pk")
            line_1
            line_2
            city
            state {
              code
              name
            }
            zip_code
          }
          subcontractor_employees(order_by: { user: { name: asc } }) {
            user_id
            id
            pk: id @__clientField(handle: "pk")
            user {
              name
              email
              phone_number
              created_password
              notify_for_obs(where: { gc_id: { _eq: $gcId } }) {
                id
                pk: id @__clientField(handle: "pk")
                risk_level_value
              }
              invites(
                where: {
                  claiming_account: { _eq: true }
                  accepted_at: { _is_null: true }
                }
                limit: 1
              ) {
                id
              }
            }
            project_subcontractor_employees(
              where: { project_id: { _eq: $projectId } }
            ) {
              id
            }
            employee_title {
              name_text
            }
          }
          safety_data_sheets(
            where: {
              project_id: { _is_null: true }
              deleted_at: { _is_null: true }
            }
            order_by: { company_name: asc }
          ) {
            ...SdsFrag @relay(mask: false)
          }
          safety_plans(
            where: {
              project_id: { _is_null: true }
              deleted_at: { _is_null: true }
            }
            order_by: { title: asc }
          ) {
            ...SafetyPlanFrag @relay(mask: false)
          }
          subcontractor_project_worker_titles(
            where: { project_id: { _eq: $projectId } }
            order_by: { worker_title: { translation: { en: asc } } }
          ) {
            id
            project_workers_aggregate(
              where: { is_last: { _eq: true }, deleted_at: { _is_null: true } }
            ) {
              aggregate {
                count
              }
            }
            worker_title {
              id
              translation {
                en
                id
              }
            }
            worker_title_id
          }
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }, first: 1) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
          id
          employee {
            general_contractor {
              id
              pk: id @__clientField(handle: "pk")
              name
              observation_notifications(
                where: { notify_sub_poc: { _eq: true } }
                order_by: { risk_level: { weight: asc } }
              ) {
                risk_level {
                  name
                  color_hex
                  value
                }
              }
            }
            employee_projects(
              where: {
                project: {
                  project_subcontractors: { subcontractor_id: { _eq: $subId } }
                }
              }
              order_by: { project: { name: asc } }
            ) {
              project {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }

    prevSubs: worker_previous_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subId } }
      order_by: { worker: { user: { name: $order } } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          worker {
            id
            pk: id @__clientField(handle: "pk")
            user {
              name
              email
              phone_number
            }
            current_worker_role
            worker_title {
              translation {
                en
              }
            }
            subcontractor_id
            subcontractor {
              name
            }
            worker_projects(
              where: {
                project_id: { _eq: $projectId }
                subcontractor_id: { _eq: $subId }
              }
            ) {
              id
              pk: id @__clientField(handle: "pk")
              project_id
              project {
                name
              }
              worker_role
              hard_hat_number
              title {
                translation {
                  en
                  es
                  original
                  pt
                }
              }
              orientation_signature_image {
                created_at
              }
            }
          }
        }
      }
    }
  }
`;

const GCSubcontractor: FC<GCSubcontractorProps> = ({
  subcontractorId,
  projectId,
  subview,
  taskId,
}) => {
  const emp = useGcEmployee();
  const navigate = useNavigate();
  const [order, setOrder] = useState(Order_By.Asc);
  const data = useLazyLoadQuery<GCSubcontractorQuery>(query, {
    projectId,
    projectWorkerWhere: {
      subcontractor_id: { _eq: subcontractorId },
      project_id: { _eq: projectId },
      subcontractor_worker: {},
    },
    gcId: emp.general_contractor.id,
    userId: auth.currentUser?.uid ?? "",
    subId: subcontractorId,
    order,
  });

  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "sub-info",
      label: " POCs & Details",
      content: (
        <GCSubcontractorDetail
          {...{
            data,
            projectId,
            gcId: emp.general_contractor.id,
            subcontractorId,
            refetchData: () =>
              setOrder((prev) =>
                prev === Order_By.Asc ? Order_By.Desc : Order_By.Asc,
              ),
          }}
        />
      ),
    },
    {
      id: "workers-and-crews",
      label: "Workers and Crews",
      content: (
        <GCSubcontractorWorkersAndCrews
          {...{ data: data, projectId, subcontractorId }}
        />
      ),
    },
    {
      id: "jhas",
      label: "JHAs",
      content: (
        <GCSubcontractorJHAs {...{ data, projectId, subcontractorId }} />
      ),
    },
    {
      id: "sds",
      label: "SDS",
      content: <GCSubcontractorSDS {...{ data, projectId, subcontractorId }} />,
    },
    {
      id: "sssp",
      label: "SSSP",
      content: (
        <GCSubcontractorSSSP {...{ data, projectId, subcontractorId }} />
      ),
    },
  ];

  return taskId && subcontractorId && projectId ? (
    <TaskInfo {...{ projectId, subcontractorId, taskId, type: "gce" }} />
  ) : (
    <CustomTabs
      onTabSelect={handleReportsTabClick}
      tabs={tabs}
      selectedTabId={subview}
    />
  );
};
const GCSubcontractorWrapper: FC = () => {
  const { projectId, subcontractorId, subview, taskId } = useParams();
  return subcontractorId && projectId ? (
    <GCSubcontractor {...{ projectId, subcontractorId, subview, taskId }} />
  ) : (
    <Navigate to="gce" replace />
  );
};
export default GCSubcontractorWrapper;
