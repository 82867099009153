import { useSuspenseQuery } from "@apollo/client";
import { FC, useState, useMemo } from "react";
import { Card } from "antd";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  useInsertWeatherObservationMutation,
  useUpdateWeatherObservationMutation,
  useUpdateTextTranslationByPkMutation,
  GetSelectOptionQuery,
  GetSelectOptionDocument,
  GetSelectOptionQueryVariables,
  Select_Option_Type_Enum,
  Daily_Weather_Observation_Set_Input,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkQuery,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import IncidentInputField from "src/domain-features/incident-management/components/basic/InputField";
import IncidentDatepicker from "src/domain-features/incident-management/components/basic/Datepicker";
import IncidentSelectField from "src/domain-features/incident-management/components/basic/SelectField";

interface IncidentWeatherObservationProps {
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
}

const IncidentWeatherObservation: FC<IncidentWeatherObservationProps> = ({
  incident,
}) => {
  const { data: weatherSelectOptions } = useSuspenseQuery<
    GetSelectOptionQuery,
    GetSelectOptionQueryVariables
  >(GetSelectOptionDocument, {
    variables: {
      where: {
        type: {
          _in: [
            Select_Option_Type_Enum.Calamity,
            Select_Option_Type_Enum.Ground,
            Select_Option_Type_Enum.Sky,
            Select_Option_Type_Enum.Wind,
          ],
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  if (!weatherSelectOptions) {
    throw new Error("missing weather select options");
  }

  const [updateText] = useUpdateTextTranslationByPkMutation();
  const [updateWeatherObservation] = useUpdateWeatherObservationMutation();
  const [insertDailyWeatherObservation] = useInsertWeatherObservationMutation();
  const authUser = useAuthUser();
  const weatherObservation = incident.weather_observation;

  const options = useMemo(() => {
    const initialOptions = {
      wind: [] as Array<{ label: string; value: string }>,
      sky: [] as Array<{ label: string; value: string }>,
      ground: [] as Array<{ label: string; value: string }>,
      calamity: [] as Array<{ label: string; value: string }>,
    };
    const options = weatherSelectOptions.select_option.reduce((acc, option) => {
      if (option.type === "wind") {
        acc.wind.push({ label: option.name.en, value: option.id });
      } else if (option.type === "sky") {
        acc.sky.push({ label: option.name.en, value: option.id });
      } else if (option.type === "ground") {
        acc.ground.push({ label: option.name.en, value: option.id });
      } else {
        acc.calamity.push({ label: option.name.en, value: option.id });
      }
      return acc;
    }, initialOptions);

    return options;
  }, [weatherSelectOptions]);

  const [weatherDescription, setWeatherDescription] = useState<string>(
    weatherObservation?.description.en ?? "",
  );
  const [temperature, setTemperature] = useState<string>(
    weatherObservation?.temp ?? "",
  );
  const [precipitation, setPrecipitation] = useState<string>(
    weatherObservation?.precipitation ?? "",
  );
  const [timeObserved, setTimeObserved] = useState<dayjs.Dayjs | undefined>(
    weatherObservation?.time_observed
      ? dayjs(weatherObservation.time_observed)
      : undefined,
  );
  const [skyId, setSkyId] = useState<string | undefined>(
    weatherObservation?.sky_id ?? undefined,
  );
  const [windId, setWindId] = useState<string | undefined>(
    weatherObservation?.wind_id ?? undefined,
  );
  const [groundId, setGroundId] = useState<string | undefined>(
    weatherObservation?.ground_id ?? undefined,
  );

  const insertWeatherObservation = async () => {
    if (!!weatherObservation) {
      const weatherObservationSet: Daily_Weather_Observation_Set_Input = {};

      if (skyId !== weatherObservation?.sky_id) {
        weatherObservationSet.sky_id = skyId;
      }
      if (windId !== weatherObservation?.wind_id) {
        weatherObservationSet.wind_id = windId;
      }
      if (groundId !== weatherObservation?.ground_id) {
        weatherObservationSet.ground_id = groundId;
      }

      if (temperature !== weatherObservation?.temp) {
        weatherObservationSet.temp = temperature;
      }
      if (precipitation !== weatherObservation?.precipitation) {
        weatherObservationSet.precipitation = precipitation;
      }
      if (timeObserved?.format() !== weatherObservation?.time_observed) {
        weatherObservationSet.time_observed = timeObserved?.format();
      }

      updateWeatherObservation({
        variables: {
          id: weatherObservation.id,
          set: weatherObservationSet,
        },
      });

      if (weatherDescription !== weatherObservation.description.en) {
        updateText({
          variables: {
            pk_columns: { id: weatherObservation.description.id },
            _set: { en: weatherDescription, original: weatherDescription },
          },
        });
      }
    } else if (!!timeObserved) {
      await insertDailyWeatherObservation({
        variables: {
          object: {
            sky_id: skyId,
            ground_id: groundId,
            wind_id: windId,
            description: {
              data: {
                en: weatherDescription,
                original: weatherDescription,
              },
            },
            incident_id: incident.id,
            precipitation: precipitation,
            temp: temperature,
            time_observed: timeObserved?.toString(),
            date: timeObserved?.toString(),
            project_id: incident.project_id,
            created_by_project_user: {
              data: {
                employee_id: authUser.uid,
              },
            },
          },
        },
        update(cache, result) {
          const insertedWeatherObservation =
            result.data?.insert_daily_weather_observation_one;
          if (insertedWeatherObservation) {
            cache.writeQuery<
              GetIncidentByPkQuery,
              GetIncidentByPkQueryVariables
            >({
              data: {
                __typename: "query_root",
                incident_by_pk: {
                  ...incident,
                  weather_observation: insertedWeatherObservation,
                },
              },
              query: GetIncidentByPkDocument,
            });
          }
        },
      });
    }
  };

  return (
    <Card title="Weather Details">
      <div className="space-y-1.5">
        <IncidentInputField
          label="General comments related to the weather"
          defaultText={weatherDescription}
          onSave={(value) => {
            setWeatherDescription(value);
            insertWeatherObservation();
          }}
        />

        <IncidentDatepicker
          label="Time observed"
          value={timeObserved?.toString()}
          format="YYYY-MM-DD h:mm A"
          showTime
          onChange={async (date) => {
            setTimeObserved(dayjs(date));
            await insertWeatherObservation();
          }}
        />

        <IncidentSelectField
          title="Sky"
          value={skyId}
          options={options.sky}
          onChange={async (option) => {
            setSkyId(option?.value?.toString());
            await insertWeatherObservation();
          }}
        />

        <IncidentInputField
          label="Temperature"
          defaultText={temperature}
          onSave={(value) => {
            setTemperature(value);
            insertWeatherObservation();
          }}
          validateAsNumber
        />

        <IncidentInputField
          label="Precipitation"
          defaultText={precipitation}
          onSave={(value) => {
            setPrecipitation(value);
            insertWeatherObservation();
          }}
          validateAsNumber
        />

        <IncidentSelectField
          title="Wind"
          value={windId}
          options={options.wind}
          onChange={async (option) => {
            setWindId(option?.value?.toString());
            await insertWeatherObservation();
          }}
        />

        <IncidentSelectField
          title="Ground"
          value={groundId}
          options={options.ground}
          onChange={async (option) => {
            setGroundId(option?.value?.toString());
            await insertWeatherObservation();
          }}
        />
      </div>
    </Card>
  );
};

export default IncidentWeatherObservation;
