import dayjs from "dayjs";
import React, { useState } from "react";
import getDateStr from "src/common/functions/dates/getDateStr";
import GCProjectReportsDailyTopBar from "./components/header/GCProjectReportsDailyTopBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import GCProjectReportsDailyMonthRange from "./components/header/GCProjectReportsDailyMonthRange";
import GCProjectReportsDailyBody from "./components/GCProjectReportsDailyBody";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";
import AppPageLayout from "../../../../common/components/layouts/main/content/AppPageLayout";
import { SettingOutlined } from "@ant-design/icons";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";

const GCProjectReportsDaily: React.FC<ProjectIdProps> = ({ projectId }) => {
  const navigate = useNavigate();
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [selectedSubcontractorIds, setSelectedSubcontractorIds] = useState<
    string[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const searchParamsRange: {
    startMonth?: string;
    endMonth?: string;
  } = {
    startMonth: searchParams.get("startMonth") ?? undefined,
    endMonth: searchParams.get("endMonth") ?? undefined,
  };
  const startMonth = searchParamsRange.startMonth
    ? dayjs(searchParamsRange.startMonth)
    : dayjs();
  const endMonth = searchParamsRange.endMonth
    ? dayjs(searchParamsRange.endMonth)
    : dayjs();

  const handleMonthChange = (
    newStartMonth: dayjs.Dayjs,
    newEndMonth: dayjs.Dayjs,
  ) => {
    setSelectedTagIds([]);
    setSelectedSubcontractorIds([]);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(
      "startMonth",
      getDateStr(newStartMonth, { format: "numeric" }),
    );
    newSearchParams.set(
      "endMonth",
      getDateStr(newEndMonth, { format: "numeric" }),
    );
    setSearchParams(newSearchParams);
  };

  const goToSettings = () => {
    navigate(getGcProjectSettingsPath(projectId, `daily-reports`));
  };

  return (
    <AppPageLayout
      pageTitle={"Daily Reports"}
      pageActions={[
        {
          children: "Settings",
          onClick: goToSettings,
          icon: <SettingOutlined />,
        },
      ]}
    >
      <div className="flex flex-col w-full gap-0">
        <div className="flex flex-col items-center">
          <GCProjectReportsDailyMonthRange
            startMonth={startMonth}
            endMonth={endMonth}
          />
        </div>
        <div
          className={`sticky z-10 flex flex-row justify-center p-0.5 my-0.5 bg-white -top-px`}
        >
          <GCProjectReportsDailyTopBar
            startMonth={startMonth}
            endMonth={endMonth}
            onMonthChange={handleMonthChange}
            onSearchSubmit={setSearchQuery}
          />
        </div>
        <GCProjectReportsDailyBody
          projectId={projectId}
          selectedTagIds={selectedTagIds}
          onSelectedTagIdsChange={setSelectedTagIds}
          selectedSubIds={selectedSubcontractorIds}
          onSelectedSubIdsChange={setSelectedSubcontractorIds}
          startMonth={startMonth}
          endMonth={endMonth}
          searchQuery={searchQuery}
        />
      </div>
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCProjectReportsDaily);
