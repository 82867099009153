import gcProjectsRoute from "./projects/gcProjectsRoute";
import NestedRoute from "../../../../../common/types/manual/NestedRoute";
import gcOrganizationUnitRoute from "./organization-unit/gcOrganizationUnitRoute";
import gcCorporateOfficeRoute from "./corporate-office/gcCorporateOfficeRoute";

const gcHierarchyRoutes: NestedRoute = {
  children: [
    {
      path: "corporate-office",
      ...gcCorporateOfficeRoute,
    },
    {
      path: "organization-unit",
      // includes division, business unit, office
      ...gcOrganizationUnitRoute,
    },
    {
      path: "project",
      ...gcProjectsRoute,
    },
  ],
};
export default gcHierarchyRoutes;
