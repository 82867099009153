import React from "react";
import withAppPageLayout from "../../../../common/components/layouts/main/content/withAppPageLayout";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../common/components/layouts/tabs/CustomTabsRouted";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";

const GCProjectReports: React.FC<ProjectIdProps> = (props) => {
  const basePath = `/gce/hierarchy/projects/${props.projectId}/reports`;
  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      label: "Download",
      path: "download",
    },
    {
      label: "Summary",
      path: "summary",
    },
  ];

  return <CustomTabsRouted tabs={tabs} basePath={basePath} />;
};

export default withAppPageLayout(withProjectIdUrlParam(GCProjectReports), {
  pageTitle: "Reports",
});
