/**
 * @generated SignedSource<<bf68d625ad3cca432154b47057bfdcd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCProjectSettingsWeeklyEmailQuery$variables = {
  projectId: string;
};
export type GCProjectSettingsWeeklyEmailQuery$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly morning_man_power_report_email_time: string;
        readonly project_setting: {
          readonly id: string;
          readonly require_gc_to_mark_observation_complete: boolean;
          readonly send_weekly_email: boolean | null | undefined;
        } | null | undefined;
        readonly timezone: string;
      };
    }>;
  };
};
export type GCProjectSettingsWeeklyEmailQuery = {
  response: GCProjectSettingsWeeklyEmailQuery$data;
  variables: GCProjectSettingsWeeklyEmailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "projectConnection",
    "kind": "LinkedField",
    "name": "project_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projectEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "morning_man_power_report_email_time",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project_setting",
                "kind": "LinkedField",
                "name": "project_setting",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "send_weekly_email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "require_gc_to_mark_observation_complete",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectSettingsWeeklyEmailQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectSettingsWeeklyEmailQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5c879dc48d0865d5c260e46f0361194c",
    "id": null,
    "metadata": {},
    "name": "GCProjectSettingsWeeklyEmailQuery",
    "operationKind": "query",
    "text": "query GCProjectSettingsWeeklyEmailQuery(\n  $projectId: uuid!\n) {\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        morning_man_power_report_email_time\n        timezone\n        project_setting {\n          id\n          send_weekly_email\n          require_gc_to_mark_observation_complete\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "49b7172228615163b8d3fb10b4a1b7cd";

export default node;
