import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import {
  useUpdateIncidentTextByPkMutation,
  GetIncidentByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "src/domain-features/incident-management/utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import getIncidentTypeName from "../../utils/getIncidentTypeName";
import { Form } from "antd";

const TextField: React.FC<
  {
    textId: string;
    autoSize?: { maxRows: number; minRows: number };
    text: string;
    label: string;
    required?: boolean;
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
    saveIncidentField?: () => Promise<void>;
    fieldTypeKey:
      | "environmental"
      | "equipment"
      | "near_miss"
      | "property_damage"
      | "theft"
      | "utility"
      | "vehicle"
      | "injury_detail"
      | "witness_detail"
      | "incident_detail"
      | "root_cause";
  } & (
    | {
        fieldTypeKey: "environmental";
        field: "nature" | "cause";
      }
    | {
        fieldTypeKey: "equipment";
        field: "rented_from" | "rules_voilated" | "damage_details";
      }
    | {
        fieldTypeKey: "near_miss";
        field: "type";
      }
    | {
        fieldTypeKey: "property_damage";
        field: "description" | "cause";
      }
    | {
        fieldTypeKey: "theft";
        field: "local_authorities_details" | "stolen_items";
      }
    | {
        fieldTypeKey: "utility";
        field: "utility_not_marked_detail";
      }
    | {
        fieldTypeKey: "vehicle";
        field: "description" | "cause";
      }
    | {
        fieldTypeKey: "injury_detail";
        incidentUserId: string;
        field:
          | "description"
          | "onsite_treatment_detail"
          | "hospital_treatment_detail"
          | "offsite_treatment_detail";
      }
    | {
        fieldTypeKey: "witness_detail";
        incidentUserId: string;
        field:
          | "statement_description"
          | "unusual_happenings"
          | "future_prevention"
          | "prior_injury_detail";
      }
    | {
        fieldTypeKey: "incident_detail";
        field:
          | "summary"
          | "location"
          | "description"
          | "equipment_type"
          | "project_impacts"
          | "emergency_service"
          | "road_vehicle_info"
          | "corrective_action";
      }
    | {
        fieldTypeKey: "root_cause";
        field: "root_cause" | "analysis_participants" | "additional_details";
      }
  )
> = ({ text, label, textId, autoSize, incident, required, ...props }) => {
  const [newText, setNewText] = useState(text);
  const [updateText, { loading: updating }] =
    useUpdateIncidentTextByPkMutation();
  const authUser = useAuthUser();

  const onSave = async () => {
    if (text.trim() === newText.trim()) return;

    let updatedIncident = incident;

    if (props.fieldTypeKey === "injury_detail") {
      const updatedInjuredUsers = incident.injured_users.map((injuredUser) => {
        if (
          injuredUser.id === props.incidentUserId &&
          injuredUser.injury_detail
        ) {
          return {
            ...injuredUser,
            injury_detail: {
              ...injuredUser.injury_detail,
              [props.field]: newText,
            },
          };
        } else return injuredUser;
      });
      updatedIncident = {
        ...incident,
        injured_users: updatedInjuredUsers,
      };
    } else if (props.fieldTypeKey === "witness_detail") {
      const updatedWitnessUsers = incident.witnesses.map((witness) => {
        if (witness.id === props.incidentUserId && witness.statement_detail) {
          return {
            ...witness,
            statement_detail: {
              ...witness.statement_detail,
              [props.field]: newText,
            },
          };
        } else {
          return witness;
        }
      });

      updatedIncident = {
        ...incident,
        witnesses: updatedWitnessUsers,
      };
    }

    updatedIncident =
      props.fieldTypeKey === "incident_detail"
        ? {
            ...incident,
            [props.field]: { en: newText, id: textId },
          }
        : props.fieldTypeKey === "root_cause"
        ? {
            ...incident,
            root_cause_analysis: {
              ...incident.root_cause_analysis!,
              [props.field]: { en: newText, id: textId },
            },
          }
        : props.fieldTypeKey === "injury_detail" ||
          props.fieldTypeKey === "witness_detail"
        ? updatedIncident
        : {
            ...incident,
            [`${props.fieldTypeKey}_incident`]: {
              ...incident[`${props.fieldTypeKey}_incident`]!,
              [props.field]: { en: newText, id: textId },
            },
          };

    const patch = createIncidentPatch(updatedIncident, incident);

    const userName =
      props.fieldTypeKey === "witness_detail"
        ? incident.witnesses.find(
            (witness) => witness.id === props.incidentUserId,
          )?.user.name
        : props.fieldTypeKey === "injury_detail"
        ? incident.injured_users.find(
            (injuredUser) => injuredUser.id === props.incidentUserId,
          )?.user.name
        : undefined;

    const comment = `Updated ${getIncidentTypeName(props.fieldTypeKey)} ${
      userName ? `for ${userName}` : ``
    } - ${label} to "${newText}"`;

    props.saveIncidentField && (await props.saveIncidentField());
    await updateText({
      variables: {
        pk_columns: {
          id: textId,
        },
        _set: {
          en: newText,
          original: newText,
        },
        objects: {
          edit_type: "text_edit",
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          patch: patch,
          comment: comment,
        },
      },
    });
  };
  return (
    <Form.Item label={label} layout="vertical" required={required}>
      <TextArea
        disabled={updating}
        onBlur={onSave}
        autoSize={autoSize || { maxRows: 10, minRows: 3 }}
        value={newText}
        onChange={(e) => {
          setNewText(e.currentTarget.value);
        }}
      />
    </Form.Item>
  );
};
export default TextField;
