import React, { FC, useRef, useState } from "react";
import { useRefetchableFragment } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import BSpace from "src/common/components/layouts/BSpace";
import StyledContent from "src/common/components/layouts/StyledContent";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { IconPlus } from "@tabler/icons";
import { CrewProjectWorkerOptions_project_worker_connection_frag$key } from "src/common/types/generated/relay/CrewProjectWorkerOptions_project_worker_connection_frag.graphql";
import { GCSubcontractorQuery } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import CrewProjectWorkerOptionsFrag from "src/common/api/relay/fragments/CrewProjectWorkerOptions";
import CrewTable from "./CrewTable";
import CreateNewCrewModal from "./modal/CreateNewCrewModal";

interface ProjectCrewWorkerDetailsProps {
  projectId: string;
  subcontractorId: string;
  showOnsite: boolean;
  onRowClick: (crewId: string) => void;
  workersDataKey: CrewProjectWorkerOptions_project_worker_connection_frag$key;
}

const ProjectCrewWorkerDetails: FC<ProjectCrewWorkerDetailsProps> = ({
  projectId,
  subcontractorId,
  showOnsite,
  onRowClick,
  workersDataKey,
}) => {
  const crewTableRef = useRef<DataScrollTableRef>(null);
  const [showCreateNewCrewModal, setShowCreateNewCrewModal] = useState(false);
  const navigate = useNavigate();

  const [workersOnProject] = useRefetchableFragment<
    GCSubcontractorQuery,
    CrewProjectWorkerOptions_project_worker_connection_frag$key
  >(CrewProjectWorkerOptionsFrag, workersDataKey);

  return (
    <StyledContent padding backgroundColor="white">
      <CreateNewCrewModal
        visible={showCreateNewCrewModal}
        projectId={projectId}
        onCancel={() => setShowCreateNewCrewModal(false)}
        subcontractorId={subcontractorId}
        workersOnProject={workersOnProject}
        onFinish={() => {
          crewTableRef.current?.refetch();
          setShowCreateNewCrewModal(false);
        }}
      />
      <BSpace>
        <CrewTable
          showOnsite={showOnsite}
          workersOnProject={workersOnProject}
          onRowClick={(crew) => onRowClick(crew.pk)}
          topBarButtons={[
            {
              label: "Add Crew",
              onClick: () => setShowCreateNewCrewModal(true),
              icon: IconPlus,
            },
          ]}
          ref={crewTableRef}
          where={{
            subcontractor_id: { _eq: subcontractorId },
            project_id: { _eq: projectId },
            deleted_at: { _is_null: true },
          }}
        />
      </BSpace>
    </StyledContent>
  );
};
export default ProjectCrewWorkerDetails;
