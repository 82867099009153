import React, { FC, useState } from "react";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteInspectionWorkerViewSignatureUI from "./SiteInspectionWorkerSignatureUI";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";

import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";
import useSignaturePad from "src/common/components/general/signature-canvas/useSignaturePad";

interface SiteInspectionWorkerViewStepsSignatureProps
  extends SiteInspectionStepProps {}

const SiteInspectionWorkerViewStepsSignature: FC<
  SiteInspectionWorkerViewStepsSignatureProps
> = (props) => {
  const { signaturePadProps, uploadSignature, isEmpty } = useSignaturePad();
  const [loading, setLoading] = useState<boolean>(false);
  const workerContext = useSiteInspectionUser();
  const langStrings = useCurrentLangStrings();
  const onSign = async (signatureImageUrl: string) => {
    if (signatureImageUrl) {
      workerContext.updateUser((user) =>
        user
          ? {
              ...user,
              signatureImageUrl,
            }
          : user,
      );

      props.onNext();
    } else {
      console.error("Signature not uploaded");
    }
  };

  const onNextClick = async () => {
    setLoading(true);
    try {
      const imageUrl = await uploadSignature();
      await onSign(imageUrl);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SiteFeatureStepLayout
      loading={loading}
      nextButtonText={langStrings.strings.submit}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={isEmpty}
      onNextButtonClick={onNextClick}
    >
      <div className={`flex min-h-full flex-col justify-center `}>
        <div className={`flex flex-col gap-1`}>
          <p className={`text-2`}>{langStrings.strings.signBelow}</p>
          <SiteInspectionWorkerViewSignatureUI
            userName={
              (workerContext.user?.name || workerContext.user?.name) ?? ""
            }
            langStrings={langStrings}
            signaturePadProps={signaturePadProps}
          />
        </div>
      </div>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionWorkerViewStepsSignature;
