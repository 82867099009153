import { useParams } from "react-router-dom";
import React from "react";

/* Usage:
    withUrlParams(GCProjectOrientationDetail, ["orientationId"]),
 */

/* TODO:
1. check that required properties are string (or generate compile time error otherwise)
*/

const withUrlParams = <
  Props extends object,
  RequiredParams extends keyof Props,
>(
  Component: React.FC<Props>,
  requiredParams: RequiredParams[],
) => {
  return (props: Omit<Props, RequiredParams>) => {
    const params = useParams();
    const extractedParams = {} as Record<RequiredParams, string>;

    for (const requiredParam of requiredParams) {
      const value = params[String(requiredParam)];
      if (!value) {
        throw new Error(
          `Missing required URL parameter: ${String(requiredParam)}`,
        );
      }
      extractedParams[requiredParam] = value;
    }
    const fullProps = { ...props, ...extractedParams } as Props;
    return <Component {...fullProps} />;
  };
};
export default withUrlParams;
