/**
 * @generated SignedSource<<f683fe3e6c0619275463a069e503cdd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { obs_notify_user_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation$variables = {
  objects: ReadonlyArray<obs_notify_user_insert_input>;
};
export type useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation$data = {
  readonly insert_obs_notify_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
      readonly risk_level_value: string | null | undefined;
    }>;
  } | null | undefined;
};
export type useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation = {
  response: useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation$data;
  variables: useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "risk_level_value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "obs_notify_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_obs_notify_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "obs_notify_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "obs_notify_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_obs_notify_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "obs_notify_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6ac6e7a22401fd4debf731ac9a26efd",
    "id": null,
    "metadata": {},
    "name": "useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation",
    "operationKind": "mutation",
    "text": "mutation useGetSubadminObsRiskLevelColumns_InsertObsNotify_Mutation(\n  $objects: [obs_notify_user_insert_input!]!\n) {\n  insert_obs_notify_user(objects: $objects) {\n    returning {\n      id\n      pk: id\n      risk_level_value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95a2ef511a3a7ef797040905aabbbbd9";

export default node;
