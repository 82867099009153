import React, { useState } from "react";
import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";

interface InputFieldProps {
  defaultText?: string;
  label: string;
  disabled?: boolean;
  onSave: (val: string) => void;
  validateAsNumber?: boolean;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  defaultText,
  label,
  disabled,
  onSave,
  validateAsNumber = false,
  required = false,
  placeholder,
  helperText,
}) => {
  const [newText, setNewText] = useState(defaultText);
  return (
    <Form.Item
      label={label}
      layout="vertical"
      required={required}
      help={helperText}
    >
      <TextArea
        className="w-full"
        value={newText}
        placeholder={placeholder}
        autoSize={{ minRows: 1, maxRows: 10 }}
        onChange={(val) => {
          const inputValue = val.target.value;
          if (validateAsNumber) {
            if (/^\d*\.?\d*$/.test(inputValue)) {
              setNewText(inputValue);
            }
          } else {
            setNewText(inputValue);
          }
        }}
        onBlur={() => {
          if (typeof newText !== "undefined") onSave(newText);
        }}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default InputField;
