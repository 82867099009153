import React from "react";
import { Navigate } from "react-router-dom";
import getGcProjectSettingsPath from "../../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import withProjectIdUrlParam from "../../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../../common/types/manual/ProjectIdProps";

const ReportManagementRedirect: React.FC<ProjectIdProps> = (props) => {
  return (
    <Navigate
      to={getGcProjectSettingsPath(props.projectId, "reports")}
      replace
    />
  );
};

export default withProjectIdUrlParam(ReportManagementRedirect);
