import { Button, Descriptions, Space } from "antd";
import React, { FC, useState } from "react";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import ProjectCrewsTable from "src/common/components/tables/ProjectCrewsTable";
import {
  GetProjectSubcontractorByPkDocument,
  useGetProjectSubcontractorByPkQuery,
  useInsertProjectCrewsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";

import { useNavigate } from "react-router-dom";
import AddProjectCrewModal from "src/common/components/dialogs/AddProjectCrewModal";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";

const { Item } = Descriptions;

const AdminProjectSubcontractor: FC<ProjectIdProps & SubcontractorProps> = ({
  projectId,
  subcontractorId,
}) => {
  const [addProjectCrewOpen, setAddProjectCrewOpen] = useState(false);
  const { data, loading } = useGetProjectSubcontractorByPkQuery({
    variables: {
      project_id: projectId,
      subcontractor_id: subcontractorId,
    },
  });
  const [insertProjectCrews, { loading: insertingProjectCrews }] =
    useInsertProjectCrewsMutation();
  const navigate = useNavigate();

  if (loading || !data) {
    return <LoadingContent />;
  }

  const {
    project_subcontractor: [projectSub],
  } = data;

  return (
    <StyledContent backgroundColor="transparent">
      <AddProjectCrewModal
        projectForemen={[]}
        visible={addProjectCrewOpen}
        onCancel={() => setAddProjectCrewOpen(false)}
        onCreate={(values) => {
          setAddProjectCrewOpen(false);
          insertProjectCrews({
            awaitRefetchQueries: true,
            variables: {
              objects: [
                {
                  project_id: projectId,
                  subcontractor_id: subcontractorId,
                  name: `${(projectSub?.project_crews.length ?? 0) + 1}`,
                  lead_foreman_project_worker_id:
                    values.leadForemanProjectWorkerId,
                },
              ],
            },
            refetchQueries: [
              {
                query: GetProjectSubcontractorByPkDocument,
                variables: {
                  project_id: projectId,
                  subcontractor_id: subcontractorId,
                },
              },
            ],
          });
        }}
      />
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <StyledContent padding backgroundColor="white">
          {projectSub && (
            <Descriptions size="middle" title="Project Subcontractor">
              <Item label="Project Name">
                <b>{projectSub.project.name}</b>
              </Item>
              <Item label="Subcontractor">{projectSub.subcontractor.name}</Item>
              {/*         <Item label="General Contractor">
              {projectSub?.project.general_contractor.name}  
            </Item> 
*/}
              <Item label="Workers">
                {projectSub.project_workers_aggregate.aggregate?.count}
              </Item>
            </Descriptions>
          )}
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <ProjectCrewsTable
            title={() => (
              <Space direction="vertical">
                <h3>Project Crews</h3>
                <Button
                  type="primary"
                  loading={insertingProjectCrews}
                  onClick={() => setAddProjectCrewOpen(true)}
                >
                  New Crew
                </Button>
              </Space>
            )}
            onRow={(item: { id?: string }) => ({
              onClick: () => {
                if (item.id)
                  navigate(
                    `/admin/data/projects/${projectId}/${subcontractorId}/crew?id=${item.id}`,
                  );
              },
            })}
            dataSource={projectSub?.project_crews ?? []}
            pagination={{
              total: projectSub?.project_crews.length ?? 0,
            }}
          />
        </StyledContent>
      </Space>
    </StyledContent>
  );
};

export default AdminProjectSubcontractor;
