import { Card, Divider, Input, Typography } from "antd";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";
import SwitchWithText from "src/common/components/SwitchWithText";
import React, { useState } from "react";

/*
Make input controllers for these properties of SwitchWithText component;

 checked: boolean;
  text: string | React.ReactNode;
  header?: string;
  child?: boolean;
  subText?: string;
  icon?: TablerIcon;
  hoverText?: string | JSX.Element;
  loading?: boolean;
  onChange: (checked: boolean) => void;
 */

const SwitchDemo: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const [text, setText] = useState("Switch Text");
  const [headerText, setHeaderText] = useState("Header Text");
  const [subText, setSubText] = useState("Sub Text");

  const [tooltipTitle, setTooltipTitle] = useState("Tooltip Text");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <AppPageLayout pageTitle={"Switch"}>
      <div className={`grid grid-cols-2 gap-1`}>
        <Card title={`Controllers`}>
          <Input
            placeholder="Enter text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Divider />
          <Input
            placeholder="Enter header text"
            value={headerText}
            onChange={(e) => setHeaderText(e.target.value)}
          />
          <Divider />
          <Input
            placeholder="Enter sub text"
            value={subText}
            onChange={(e) => setSubText(e.target.value)}
          />
          <Divider />
          <Input
            placeholder="Enter tooltip title text"
            value={tooltipTitle}
            onChange={(e) => setTooltipTitle(e.target.value)}
          />

          <Divider />
          <SwitchWithText
            text={"Loading"}
            checked={loading}
            onChange={setLoading}
          />

          <Divider />
          <SwitchWithText
            text={"Disabled"}
            checked={disabled}
            onChange={setDisabled}
          />
        </Card>
        <div className={`flex flex-col gap-1`}>
          <Card title={<Typography.Text code>SwitchWithText</Typography.Text>}>
            <SwitchWithText
              checked={checked}
              text={text}
              onChange={setChecked}
              subText={subText}
              header={headerText}
              explanation={tooltipTitle}
              loading={loading}
              disabled={disabled}
            />
          </Card>
        </div>
      </div>
    </AppPageLayout>
  );
};

export default SwitchDemo;
