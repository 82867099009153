/**
 * @generated SignedSource<<c2e0af8bd6e7bc92d2a542d5783e8e07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, uploaded_toolbox_talk_bool_exp, uploaded_toolbox_talk_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadedToolboxTalkTableRefetchableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: ReadonlyArray<uploaded_toolbox_talk_order_by>;
  where: uploaded_toolbox_talk_bool_exp;
};
export type UploadedToolboxTalkTableRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UploadedToolboxTalkTable_uploaded_toolbox_talk">;
};
export type UploadedToolboxTalkTableRefetchableQuery = {
  response: UploadedToolboxTalkTableRefetchableQuery$data;
  variables: UploadedToolboxTalkTableRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order_by"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadedToolboxTalkTableRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UploadedToolboxTalkTable_uploaded_toolbox_talk"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadedToolboxTalkTableRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "uploaded_toolbox_talkConnection",
        "kind": "LinkedField",
        "name": "uploaded_toolbox_talk_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "uploaded_toolbox_talkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "uploaded_toolbox_talk",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "due_time",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assign_to",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "added_to_community",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "uploaded_toolbox_talk_project",
                    "kind": "LinkedField",
                    "name": "uploaded_toolbox_talk_projects",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "uploaded_toolbox_talk_subcontractor",
                    "kind": "LinkedField",
                    "name": "uploaded_toolbox_talk_subcontractors",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employee",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "en",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "toolbox_talk",
                    "kind": "LinkedField",
                    "name": "toolbox_talks",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "toolbox_talk_project_worker",
                        "kind": "LinkedField",
                        "name": "toolbox_talk_project_workers",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "project_worker",
                            "kind": "LinkedField",
                            "name": "project_worker",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "worker_id",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "UploadedToolboxTalkTable_uploaded_toolbox_talk_connection",
        "kind": "LinkedHandle",
        "name": "uploaded_toolbox_talk_connection"
      }
    ]
  },
  "params": {
    "cacheID": "dedc2c19bd183b659d552e545ef5f7d6",
    "id": null,
    "metadata": {},
    "name": "UploadedToolboxTalkTableRefetchableQuery",
    "operationKind": "query",
    "text": "query UploadedToolboxTalkTableRefetchableQuery(\n  $after: String\n  $first: Int!\n  $order_by: [uploaded_toolbox_talk_order_by!]!\n  $where: uploaded_toolbox_talk_bool_exp!\n) {\n  ...UploadedToolboxTalkTable_uploaded_toolbox_talk_4g04uc\n}\n\nfragment UploadedToolboxTalkTable_uploaded_toolbox_talk_4g04uc on query_root {\n  uploaded_toolbox_talk_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        created_at\n        name\n        due_time\n        assign_to\n        added_to_community\n        subcontractor_id\n        uploaded_toolbox_talk_projects {\n          project_id\n          id\n        }\n        uploaded_toolbox_talk_subcontractors {\n          subcontractor_id\n          id\n        }\n        created_by_user {\n          name\n          employee {\n            general_contractor {\n              name\n              id\n            }\n            id\n          }\n          subcontractor_employee {\n            subcontractor {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n        general_contractor {\n          name\n          id\n        }\n        content {\n          en\n          id\n        }\n        images {\n          url\n          id\n        }\n        subcontractor {\n          name\n          id\n        }\n        toolbox_talks {\n          project_id\n          toolbox_talk_project_workers {\n            project_worker {\n              worker_id\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55121fa4e5b7512b4d5a83891aaf1033";

export default node;
