import CustomTabs, {
  CustomTabsProps,
} from "../../../../../../../common/components/layouts/tabs/CustomTabs";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import useGcEmployee from "../../organization-unit/utils/useGcEmployee";
import GCAllDivisionLevelChilds from "../../components/child-levels/GCAllDivisionLevelChilds";
import GCAllBuLevelChilds from "../../components/child-levels/GCAllBuLevelChilds";
import GCAllOfficeLevelChilds from "../../components/child-levels/GCAllOfficeLevelChilds";
import GCChildUnitsExplainerText from "../../component/GCChildUnitsExplainerText";
import { Empty, Typography } from "antd";
import getGcCorporateOfficePath from "../utils/getGcCorporateOfficePath";

const GCCorporateOfficeChildUnits: React.FC = (props) => {
  const employee = useGcEmployee();
  const gc = employee.general_contractor;
  const { subview } = useParams();
  const navigate = useNavigate();

  const onTabSelect = (tabId: string) => {
    navigate("./" + tabId);
  };

  const tabs: CustomTabsProps["tabs"] = [
    // TODO unify the tabs to any hierarchy levels
    ...(gc.hierarchy_division_name
      ? [
          {
            label: gc.hierarchy_division_name,
            id: "divisions",
            content: (
              <GCAllDivisionLevelChilds
                divisionWhere={{ general_contractor_id: { _eq: gc.id } }}
              />
            ),
          },
        ]
      : []),
    ...(gc.hierarchy_business_unit_name
      ? [
          {
            label: gc.hierarchy_business_unit_name,
            id: "business-units",
            content: (
              <GCAllBuLevelChilds
                buWhere={{ general_contractor_id: { _eq: gc.id } }}
                divisionWhere={{ general_contractor_id: { _eq: gc.id } }}
              />
            ),
          },
        ]
      : []),
    ...(gc.hierarchy_office_name
      ? [
          {
            label: gc.hierarchy_office_name,
            id: "offices",
            content: (
              <GCAllOfficeLevelChilds
                buWhere={{ general_contractor_id: { _eq: gc.id } }}
                officeWhere={{ general_contractor_id: { _eq: gc.id } }}
                divisionWhere={{ general_contractor_id: { _eq: gc.id } }}
              />
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (!subview && tabs.length > 0) {
      navigate(tabs[0].id);
    }
  }, []);

  return tabs.length ? (
    <CustomTabs selectedTabId={subview} tabs={tabs} onTabSelect={onTabSelect} />
  ) : (
    <Empty description={"No Child Units"}>
      <Typography.Text>
        Before adding Child Units, you need to set up your organization's
        hierarchy. Go to the{" "}
        <Typography.Link
          onClick={() => {
            navigate(getGcCorporateOfficePath(`hierarchy`));
          }}
        >
          Organization Hierarchy page
        </Typography.Link>{" "}
        to configure hierarchy levels.
      </Typography.Text>
    </Empty>
  );
};

export default withAppPageLayout(GCCorporateOfficeChildUnits, {
  pageTitle: "Child Units",
  pageExplainer: <GCChildUnitsExplainerText />,
});
