import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../common/components/layouts/tabs/CustomTabsRouted";
import getGcProjectPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/getGcProjectPath";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";
import AppPageLayout from "../../../../common/components/layouts/main/content/AppPageLayout";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import useGcOrientationData from "../../utils/useGcOrientationData";

const GCProjectOrientation: React.FC<ProjectIdProps> = (props) => {
  const navigate = useNavigate();
  const { getProjectOrientation } = useGcOrientationData(props.projectId);
  const projectData = getProjectOrientation();

  const performDrugtest = projectData.perform_drugtest;
  const backgroundTracker = projectData.background_check_tracker;

  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "log",
      label: "Log",
    },
    {
      path: "dashboard",
      label: "Dashboard",
    },
    ...(performDrugtest
      ? [
          {
            path: "drugtest",
            label: "Drug Tests",
          },
        ]
      : []),
    ...(backgroundTracker
      ? [
          {
            path: "background-check",
            label: "Background Check",
          },
        ]
      : []),

    {
      label: "Builder",
      path: "builder",
    },
    {
      label: "QR Codes",
      path: "qr-codes",
    },
  ];

  const goToSettings = () => {
    navigate(getGcProjectSettingsPath(props.projectId, "orientation"));
  };
  const basePath = getGcProjectPath(props.projectId, "orientation");
  return (
    <AppPageLayout
      pageTitle={"Orientation"}
      pageActions={[
        {
          children: "Settings",
          icon: <SettingOutlined />,
          onClick: goToSettings,
        },
      ]}
    >
      <CustomTabsRouted tabs={tabs} basePath={basePath} />
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCProjectOrientation);
