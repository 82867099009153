import { FC } from "react";
import {
  GetIncidentByPkQuery,
  useGetMonetarySeverityLevelsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentSelectField from "./SelectField";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";

interface Props {
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  incidentType: string;
}

const IncidentMonetarySeverity: FC<Props> = ({ incident, incidentType }) => {
  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  const [updateMonetarySeverity, { loading: updateMonetarySeverityLoading }] =
    useUpdateMonetarySeverity();

  return (
    <IncidentSelectField
      title="Monetary Severity"
      options={
        monetarySeverityLevels?.map((severity) => ({
          label: severity.name.en,
          value: severity.id,
        })) ?? []
      }
      value={incident.monetary_severity_id ?? undefined}
      onChange={async (option) => {
        if (option === null) {
          await updateMonetarySeverity(
            incident,
            null,
            `Updated ${incidentType} - "Monetary Severity" to "None"`,
          );
        } else if (typeof option.value === "string") {
          await updateMonetarySeverity(
            incident,
            option.value,
            `Updated ${incidentType} - "Monetary Severity" to ${option.label}`,
          );
        }
      }}
      loading={updateMonetarySeverityLoading}
    />
  );
};

export default IncidentMonetarySeverity;
