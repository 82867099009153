import { FC } from "react";

import { auth } from "src/common/functions/firebase";
import { UserContextDataType } from "src/utility-features/authorization/UserDataProvider";
import useGcEmployee from "../organization-unit/utils/useGcEmployee";

export interface CorporateAdminWrapperProps {
  employee: NonNullable<UserContextDataType["employee"]>;
}

const withCorporateAdminWrapper =
  <Props extends object>(Component: FC<Props & CorporateAdminWrapperProps>) =>
  (props: Props) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("Undeifned user id");
    const employee = useGcEmployee();
    if (!employee.is_corporate_admin)
      throw new Error("No Logged in gc corporate admin found");

    return <Component employee={employee} {...props} />;
  };

export default withCorporateAdminWrapper;
