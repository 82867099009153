import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import GCOrgUnitUsersCommon from "../components/GCOrgUnitUsersCommon";

interface GCOrgUnitEmployeesProps extends OrganizationUnitProps {}

const GCOrgUnitEmployees: React.FC<GCOrgUnitEmployeesProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  return (
    <GCOrgUnitUsersCommon
      pageTitle={"Employees"}
      organizationUnitId={organizationUnitId}
      organizationUnitType={organizationUnitType}
      includeChildNodeEmployees={true}
    />
  );
};

export default withOrganizationUnitWrapper(GCOrgUnitEmployees);
