import React, { useState } from "react";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import { auth } from "src/common/functions/firebase";
import { usePtpQrUserSignInUsingEmailMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { roleVar } from "src/common/api/apollo/cache";
import SiteOrientationStepsUIEmail from "src/domain-features/siteorientation/components/stepsUI/email/SiteOrientationStepsUIEmail";
import { signInWithCustomToken } from "firebase/auth";
import usePtpQr from "../../../utils/ptpQrHook";
import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";
import showUserErrorMessage from "src/utility-features/error-handling/user-feedback/showUserErrorMessage";

export interface PtpQrUserEmailProps extends SiteOrientationStepProps {
  managerDevice?: boolean;
  onLoginSuccess: (userId: string) => Promise<void>;
}

const PtpQrUserEmail: React.FC<PtpQrUserEmailProps> = (props) => {
  const { ptpQrUser, projectId, setPtpQrUser } = usePtpQr();
  const [ptpQrCodeSignIn] = usePtpQrUserSignInUsingEmailMutation();
  const [loading, setLoading] = useState(false);

  const ptpQrSignIn = async (trimmedEmail: string) => {
    try {
      const { data } = await ptpQrCodeSignIn({
        variables: {
          input: {
            email: trimmedEmail,
            projectId,
          },
        },
      });
      if (data?.ptpQrUserSignInUsingEmail) {
        const { workerId, token, userRole } = data.ptpQrUserSignInUsingEmail;
        if (token) {
          roleVar("none");
          await auth.signOut();
          await signInWithCustomToken(auth, token);
        }

        await props.onLoginSuccess(workerId);
        props.onNext();
        setPtpQrUser((prevState) => ({
          ...prevState,
          userInfo: {
            ...prevState.userInfo,
            email: trimmedEmail,
          },
        }));
      }
    } catch (err) {
      if (hasApolloErrorCode(err, "subcontractor-does-not-belong-to-project")) {
        showUserErrorMessage({
          message:
            "You are currently employed by a company not on this project. To fix, your company needs to be added, or you need to be moved to the correct company",
        });
        return;
      }

      if (
        hasApolloErrorCode(err, "user-does-not-exist") ||
        hasApolloErrorCode(err, "subcontractor-not-assigned")
      ) {
        setPtpQrUser((prevState) => ({
          ...prevState,
          userInfo: {
            ...prevState.userInfo,
            email: trimmedEmail,
          },
        }));
        props.onNext();
      } else {
        throw err;
      }
    }
  };

  const onEnter = async () => {
    const trimmedEmail = (ptpQrUser.userInfo?.email ?? "").trim();
    setLoading(true);
    await ptpQrSignIn(trimmedEmail);
    setLoading(false);
  };

  return (
    <SiteOrientationStepsUIEmail
      loading={loading}
      value={ptpQrUser.userInfo?.email ?? ""}
      onValueChange={(val) =>
        setPtpQrUser((prev) => ({
          ...prev,
          userInfo: { ...prev.userInfo, email: val },
        }))
      }
      onEnter={onEnter}
      onBack={props.onBack}
      onNoEmailPress={() => {
        setPtpQrUser((prev) => ({
          ...prev,
          userInfo: { ...prev.userInfo, email: null },
        }));
        props.onNext();
      }}
    />
  );
};

export default PtpQrUserEmail;
