import NestedRoute from "src/common/types/manual/NestedRoute";
import GCProjectOrientationDetail from "./GCProjectOrientationDetail";

const slidesRoute: NestedRoute = {
  children: [
    {
      path: ":orientationId",
      element: <GCProjectOrientationDetail />,
    },
  ],
};

export default slidesRoute;
