import { UserContextDataType } from "src/utility-features/authorization/UserDataProvider";
import { GCOrganizationUnitType } from "../../../components/hiearchy-navigation/gcHierarchyTreeTypes";

export const getOrganizationUnitNavigationItems = (
  gc: NonNullable<UserContextDataType["employee"]>["general_contractor"],
  orgUnitType?: GCOrganizationUnitType,
): { label: string; key: string }[] => [
  // TODO Add Dashboard page
  // {
  //   label: "Dashboard",
  //   key: "dashboard",
  // },

  // TODO unify organization unit children to a single menu item
  // NOTE we should probably display this for all non-project nodes
  ...(orgUnitType === "division" || orgUnitType === "business_unit"
    ? [
        {
          label: "Child Units",
          key: "child-units",
        },
      ]
    : []),
  ///
  {
    label: "Projects",
    key: "projects",
  },
  {
    label: "Workers",
    key: "workers",
  },
  {
    label: "Employees",
    key: "employees",
  },
  {
    label: "Admins",
    key: "admins",
  },
  ...(gc.observation_enabled
    ? [
        {
          label: "Observations",
          key: "observations",
        },
      ]
    : []),
  ...(gc.incident_enabled
    ? [
        {
          label: "Incidents",
          key: "incidents",
        },
      ]
    : []),

  {
    label: "Settings",
    key: "settings",
  },
];
