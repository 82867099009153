import { InboxOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import { useState } from "react";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  Document_Insert_Input,
  Document_Use_Type,
} from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";
import useUploadImageOrDoc from "src/common/functions/upload-utility/useUploadImagesOrDocs";

const IncidentDocUploader: React.FunctionComponent<{
  docType: "image" | "other";
  acceptedFiles: string;
  groupId: string;
  type: Document_Use_Type["value"];
  title?: string;
  insertDoc: (objects: Document_Insert_Input[]) => Promise<void>;
}> = ({ docType, acceptedFiles, groupId, type, title, insertDoc }) => {
  const authUser = useAuthUser();
  const [form] = Form.useForm();
  const [currentFilesCount, setCurrentFilesCount] = useState(0);
  const { uploadImagesOrDocsFromFiles, uploadingDoc } =
    useUploadImageOrDoc(docType);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={async (v) => {
        if (!v || !v[docType]) return;
        const res = await uploadImagesOrDocsFromFiles(
          v[docType],
          "incident-doc",
        );
        const objectsToInsert = res.map(
          (doc): Document_Insert_Input => ({
            group_id: groupId,
            author_id: authUser.uid,
            document_type: doc.type,
            type: type,
            name: doc.name,
            id: doc.is_image ? uuid.v4() : doc.id,
            url: doc.url,
            image: doc.is_image
              ? { data: { id: doc.id, ...doc.imageResult } }
              : null,
          }),
        );
        await insertDoc(objectsToInsert);
        form.resetFields();
        setCurrentFilesCount(0);
      }}
    >
      <Form.Item
        label={
          docType === "image"
            ? "Upload images and press button to add them"
            : "Upload new files and press button to add them"
        }
        name={docType}
        getValueFromEvent={normalizeUploadFile}
      >
        <Upload.Dragger
          accept={acceptedFiles}
          listType={docType === "image" ? "picture-card" : "text"}
          onChange={(t) => setCurrentFilesCount(t.fileList.length)}
          disabled={uploadingDoc}
          maxCount={4}
          multiple={true}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>
            Click or drag-and-drop{" "}
            {docType === "image"
              ? "Image(s) (.jpg, .jpeg, .png)"
              : "Document(s), Video(s), and Audio file(s)"}{" "}
            here
          </p>
        </Upload.Dragger>
      </Form.Item>
      {currentFilesCount > 0 && (
        <Button type="primary" htmlType="submit" loading={uploadingDoc}>
          {docType === "image" ? "Save" : "Add Documents To Incident"}
        </Button>
      )}
    </Form>
  );
};

export default IncidentDocUploader;
