import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import GCProjectCalendarSettings from "../../../../../../../../domain-features/site-delivery/entry-routes/calendar/settings/GCProjectCalendarSettings";
import { Card } from "antd";
import {
  useUpdateProcoreProjectDataValue,
  useUpdateProjectSettingValue,
  useUpdateProjectValue,
} from "../utils/projectRelatedSettingsUpdateFunctions";
import useGetProjectSettingsData from "../utils/useGetProjectSettingsData";
import SwitchWithText from "../../../../../../../../common/components/SwitchWithText";
import DividerSplitWrapper from "../../../../../../../../common/components/DividerSplitWrapper";

const GCProjectSettingsDeliveries: React.FC<ProjectIdProps> = ({
  projectId,
}) => {
  const { project, procore_project_data, project_settings } =
    useGetProjectSettingsData(projectId);
  const { updateProcoreProjectValue, loading: procoreProjectUpdating } =
    useUpdateProcoreProjectDataValue(projectId, procore_project_data);
  const { updateProjectSettingsValue, loading: projectSettingsUpdating } =
    useUpdateProjectSettingValue(projectId, project_settings);
  const { updateProjectValue, loading: projectUpdating } =
    useUpdateProjectValue(projectId, project);
  // TODO pass loading states to switchers
  return (
    <div className={`flex flex-col gap-1`}>
      {project.is_sitedelivery_active && (
        <Card>
          <DividerSplitWrapper>
            <SwitchWithText
              checked={project.is_sitedelivery_on}
              text={<>Delivery and logistics management</>}
              onChange={(newValue) => {
                updateProjectValue({
                  is_sitedelivery_on: newValue,
                });
              }}
              loading={projectUpdating}
            />

            <SwitchWithText
              checked={!!project.project_setting?.delivery_stacking}
              text={<>Allow stacking or double-booking of deliveries</>}
              onChange={(newValue) => {
                updateProjectSettingsValue({
                  delivery_stacking: newValue,
                });
              }}
              loading={projectSettingsUpdating}
            />

            <SwitchWithText
              checked={project.is_sitedelivery_approval_needed}
              text={
                <>Require approval for deliveries added by subcontractors</>
              }
              loading={projectUpdating}
              onChange={(newValue) => {
                updateProjectValue({
                  is_sitedelivery_approval_needed: newValue,
                });
              }}
            />

            <SwitchWithText
              checked={!!procore_project_data?.upload_delivery_enabled}
              text={
                <>Add completed deliveries to your Daily Report in Procore</>
              }
              onChange={(newValue) => {
                updateProcoreProjectValue({
                  upload_delivery_enabled: newValue,
                });
              }}
              loading={procoreProjectUpdating}
            />
          </DividerSplitWrapper>
        </Card>
      )}
      <GCProjectCalendarSettings />
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectSettingsDeliveries);
