import React from "react";
import { useOutlet, useParams } from "react-router-dom";
import GCBillingInvoices from "./GCBillingInvoices";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import withAppPageLayout from "../../../../../common/components/layouts/main/content/withAppPageLayout";

interface GCBillingProps {}

const GCBilling: React.FC<GCBillingProps> = (props) => {
  const outlet = useOutlet();
  const projectId = useParams().projectId;
  if (!projectId) return <ErrorMessage message="Project id is required" />;
  return outlet ?? <GCBillingInvoices projectId={projectId} />;
};

export default withAppPageLayout(GCBilling, { pageTitle: "Project Billing" });
