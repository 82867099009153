import dayjs from "dayjs";
import React, { useState } from "react";
import GCSubOnsitePerformanceTable, {
  SubOnsiteData,
} from "./GCSubOnsitePerformanceTable";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import { Button, Tooltip } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";

interface GCDashAppReportsProps {
  projectId: string;
  projectTimezone: string;
  dashboardStart: dayjs.Dayjs;
  onsiteSwitcher?: boolean;
  subOnsiteData: SubOnsiteData[];
  crewLeads: { [id: string]: string };
}

const GCSubOnsitePerformance: React.FunctionComponent<
  GCDashAppReportsProps
> = ({
  projectId,
  dashboardStart,
  projectTimezone,
  onsiteSwitcher,
  subOnsiteData,
  crewLeads,
}) => {
  const navigate = useNavigate();
  const [selectedReportsTab, setSelectedReoprtsTab] =
    useState<string>("pretaskplans");

  const handleTabClick = (newTabId: string) => {
    setSelectedReoprtsTab(newTabId);
  };

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "pretaskplans",
      label: "Pretask plans",
      content: (
        <GCSubOnsitePerformanceTable
          crewLeads={crewLeads}
          projectId={projectId}
          subOnsiteData={subOnsiteData}
          projectTimezone={projectTimezone}
          start={dashboardStart}
          reportType="ptpReport"
          onsiteSwitcher={onsiteSwitcher}
        />
      ),
    },
    {
      id: "daily",
      label: "Daily reports",
      content: (
        <GCSubOnsitePerformanceTable
          crewLeads={crewLeads}
          projectTimezone={projectTimezone}
          projectId={projectId}
          start={dashboardStart}
          reportType="dailyReport"
          subOnsiteData={subOnsiteData}
          onsiteSwitcher={onsiteSwitcher}
        />
      ),
    },
  ];

  const goToSettings = () => {
    navigate(getGcProjectSettingsPath(projectId, `onsite`));
  };

  return (
    <CustomTabs
      onTabSelect={handleTabClick}
      tabs={tabs}
      selectedTabId={selectedReportsTab}
      tabBarExtraContent={{
        right: (
          <Tooltip
            title={`
              Onsite Settings
            `}
          >
            <Button onClick={goToSettings} icon={<SettingOutlined />} />
          </Tooltip>
        ),
      }}
    />
  );
};

export default GCSubOnsitePerformance;
