import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCOnsiteSettingsOnDateInsertMutation } from "src/common/types/generated/relay/GCOnsiteSettingsOnDateInsertMutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { useGetCompletionAuditV4Query } from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import { Empty, Switch, Tooltip } from "antd";
import DatesNavigation from "../../entry-routes/qr-project-reports/components/DatesNavigation";
import GCOnsiteSettingsTableUi, {
  OnsiteSubDataType,
} from "./GCOnsiteSettingsTableUi";
import CustomSuspense from "../../../../common/components/general/CustomSuspense";

const text = {
  reportWasAlreadySubmitted: "A report was already submitted on this day",
};

const insertMutation = graphql`
  mutation GCOnsiteSettingsOnDateInsertMutation(
    $projSubOnsiteUpdateObjects: [project_subcontractor_onsite_update_insert_input!]!
    $projSubReportSettingsObjects: [project_subcontractor_report_settings_insert_input!]!
    $crewOnsiteUpdateObjects: [crew_onsite_update_insert_input!]!
    $crewReportSettingsObjects: [crew_report_settings_insert_input!]!
  ) {
    insert_project_subcontractor_onsite_update(
      objects: $projSubOnsiteUpdateObjects
      on_conflict: {
        constraint: project_subcontractor_onsite_update_project_id_subcontractor_id
        update_columns: [type]
      }
    ) {
      affected_rows
    }
    insert_crew_onsite_update(
      objects: $crewOnsiteUpdateObjects
      on_conflict: {
        constraint: crew_onsite_update_project_crew_id_onsite_date_key
        update_columns: [type]
      }
    ) {
      affected_rows
    }
    insert_project_subcontractor_report_settings(
      objects: $projSubReportSettingsObjects
      on_conflict: {
        constraint: project_subcontractor_report_settings_project_id_subcontractor_
        update_columns: [
          safety_reports_required
          toolbox_talks_required
          daily_reports_required
        ]
      }
    ) {
      affected_rows
    }
    insert_crew_report_settings(
      objects: $crewReportSettingsObjects
      on_conflict: {
        constraint: crew_report_settings_project_crew_id_change_date_key
        update_columns: [
          safety_reports_required
          toolbox_talks_required
          daily_reports_required
        ]
      }
    ) {
      affected_rows
    }
  }
`;

interface GCOnsiteSettingsOnDateTableProps {
  projectId: string;
  projectTimezone: string;
  crewLeads: { [id: string]: string };
  date: dayjs.Dayjs;
}

type OnsiteException = Partial<Record<string, boolean>>;

const convertToObjs = (
  setVal: boolean,
  list: Array<string>,
  type: "sub" | "crew",
) => Object.fromEntries(list.map((itemId) => [itemId + "_" + type, setVal]));

const GCOnsiteSettingsOnDateTable: React.FunctionComponent<
  GCOnsiteSettingsOnDateTableProps
> = ({ projectId, projectTimezone, crewLeads, date }) => {
  console.log(projectTimezone);
  const formattedDate = date.format("YYYY-MM-DD");

  const { data, loading, refetch } = useGetCompletionAuditV4Query({
    variables: {
      input: {
        startDate: formattedDate,
        endDate: formattedDate,
        projectId,
        timezone: projectTimezone,
      },
    },
  });

  const [onsiteExceptions, setOnsiteExceptions] = useState<OnsiteException>({});
  const [ptpExceptions, setPtpExceptions] = useState<OnsiteException>({});
  const [drExceptions, setDrExceptions] = useState<OnsiteException>({});
  const [tbtExceptions, setTbtExceptions] = useState<OnsiteException>({});
  useEffect(() => {
    refetch({
      input: {
        startDate: formattedDate,
        endDate: formattedDate,
        timezone: projectTimezone,
        projectId,
      },
    });
    setOnsiteExceptions({});
    setDrExceptions({});
    setPtpExceptions({});
    setTbtExceptions({});
  }, [formattedDate]);

  const [upsert] =
    useAsyncMutation<GCOnsiteSettingsOnDateInsertMutation>(insertMutation);
  const [loadingCrew, setLoadingCrew] = useState<string[]>([]);
  const [loadingSub, setLoadingSub] = useState<string[]>([]);
  type ReportType = "safety_report" | "daily_report" | "toolbox_talk";
  type ReportsRequiredSetType = {
    daily_reports_required: boolean;
    safety_reports_required: boolean;
    toolbox_talks_required: boolean;
  };
  const crewsStartLoading = (loadingCrewIds: Set<string>) => {
    setLoadingCrew((prev) => [...prev, ...loadingCrewIds]);
  };
  const crewsStopLoading = (loadingCrewIds: Set<string>) => {
    setLoadingCrew((prev) => prev.filter((p) => !loadingCrewIds.has(p)));
  };

  const setReportSettingsException = (
    reportType: ReportType,
    itemIds: string[],
    itemType: "crew" | "sub",
    set: ReportsRequiredSetType,
  ) => {
    switch (reportType) {
      case "safety_report":
        setPtpExceptions((prev) => ({
          ...prev,
          ...convertToObjs(set.safety_reports_required, itemIds, itemType),
        }));
        break;
      case "daily_report":
        setDrExceptions((prev) => ({
          ...prev,
          ...convertToObjs(set.daily_reports_required, itemIds, itemType),
        }));
        break;
      case "toolbox_talk":
        setTbtExceptions((prev) => ({
          ...prev,
          ...convertToObjs(set.toolbox_talks_required, itemIds, itemType),
        }));
        break;
    }
  };
  const setProjectCrewSetting = async (
    crewId: string,
    set: ReportsRequiredSetType,
    loadingItem: ReportType,
  ) => {
    crewsStartLoading(new Set([crewId + "_" + loadingItem]));
    try {
      await upsert({
        variables: {
          crewOnsiteUpdateObjects: [],
          crewReportSettingsObjects: [
            {
              ...set,
              project_crew_id: crewId,
              change_date: formattedDate,
            },
          ],
          projSubOnsiteUpdateObjects: [],
          projSubReportSettingsObjects: [],
        },
      });
      setReportSettingsException(loadingItem, [crewId], "crew", set);
    } finally {
      crewsStopLoading(new Set([crewId + "_" + loadingItem]));
    }
  };
  const setProjectSubSetting = async (
    subId: string,
    set: ReportsRequiredSetType,
    loadingItem: ReportType,
    changeForCrews?: { crewId: string; set: ReportsRequiredSetType }[],
  ) => {
    setLoadingSub((prev) => [...prev, subId + "_" + loadingItem]);
    const allCrewsLoadingIds =
      changeForCrews?.map(({ crewId }) => crewId + "_" + loadingItem) || [];
    crewsStartLoading(new Set(allCrewsLoadingIds));
    try {
      await upsert({
        variables: {
          crewOnsiteUpdateObjects: [],
          crewReportSettingsObjects: changeForCrews
            ? changeForCrews.map(({ crewId, set: crewSet }) => ({
                ...crewSet,
                project_crew_id: crewId,
                change_date: formattedDate,
              }))
            : [],
          projSubOnsiteUpdateObjects: [],
          projSubReportSettingsObjects: [
            {
              ...set,
              project_id: projectId,
              subcontractor_id: subId,
              change_date: formattedDate,
            },
          ],
        },
      });
      setReportSettingsException(loadingItem, [subId], "sub", set);
      if (changeForCrews)
        setReportSettingsException(
          loadingItem,
          changeForCrews.map((r) => r.crewId),
          "crew",
          set,
        );
    } finally {
      crewsStopLoading(new Set(allCrewsLoadingIds));
      setLoadingSub((prev) =>
        prev.filter((p) => p !== subId + "_" + loadingItem),
      );
    }
  };

  const setCrewOnOffSite = async (crewId: string, type: boolean) => {
    await upsert({
      variables: {
        crewOnsiteUpdateObjects: [
          {
            project_crew_id: crewId,
            type: type ? "onsite" : "offsite",
            onsite_date: formattedDate,
          },
        ],
        crewReportSettingsObjects: [],
        projSubReportSettingsObjects: [],
        projSubOnsiteUpdateObjects: [],
      },
    });
    setOnsiteExceptions((prev) => ({
      ...prev,
      [crewId + "_crew"]: type,
    }));
  };
  const setSubOnOffSite = async (
    subId: string,
    markOnsite: boolean,
    markCrewIds: string[],
  ) => {
    setLoadingSub((prev) => [...prev, subId]);
    if (markCrewIds.length) crewsStartLoading(new Set(markCrewIds));
    const type = markOnsite ? "onsite" : "offsite";
    try {
      await upsert({
        variables: {
          crewReportSettingsObjects: [],
          projSubReportSettingsObjects: [],
          crewOnsiteUpdateObjects: markCrewIds.map((crewId) => ({
            project_crew_id: crewId,
            type,
            onsite_date: formattedDate,
          })),
          projSubOnsiteUpdateObjects: [
            {
              project_id: projectId,
              subcontractor_id: subId,
              type,
              onsite_date: formattedDate,
            },
          ],
        },
      });
      setOnsiteExceptions((prev) => ({
        ...prev,
        [subId + "_sub"]: markOnsite,
        ...convertToObjs(markOnsite, markCrewIds, "crew"),
      }));
    } finally {
      if (markCrewIds.length) crewsStopLoading(new Set(markCrewIds));
      setLoadingSub((prev) => prev.filter((p) => p !== subId));
    }
  };
  if (loading) return <LoadingContent />;
  if (!data) {
    return <Empty>Data Not Found for this Date</Empty>;
  }
  const returnSettings: (
    itemId: string,
    dailyCompletion: ReportsRequiredSetType,
    type: "sub" | "crew",
  ) => ReportsRequiredSetType = (itemId, dailyCompletion, type) => {
    const ptpException = ptpExceptions[itemId + "_" + type];
    const drException = drExceptions[itemId + "_" + type];
    const tbtException = tbtExceptions[itemId + "_" + type];
    return {
      safety_reports_required:
        typeof ptpException === "boolean"
          ? ptpException
          : dailyCompletion.safety_reports_required,
      daily_reports_required:
        typeof drException === "boolean"
          ? drException
          : dailyCompletion.daily_reports_required,
      toolbox_talks_required:
        typeof tbtException === "boolean"
          ? tbtException
          : dailyCompletion.toolbox_talks_required,
    };
  };

  const tableData: OnsiteSubDataType[] = [];

  [...(data.getCompletionAuditV4 || [])]
    .sort((sub1, sub2) => sub1.name.localeCompare(sub2.name))
    .forEach((sub) => {
      const allCrews = sub.crewData;
      const nonDeletedCrews = sub.crewData.filter((c) => !c.is_deleted);
      const subDateSettings = sub.dailyCompletion.at(0);
      if (!subDateSettings) {
        throw new Error("sub dateSettings Not found");
      }
      const subSettings = returnSettings(sub.id, subDateSettings, "sub");
      const subOnsiteExcep = onsiteExceptions[sub.id + "_sub"];
      const showCrewData = nonDeletedCrews.length > 1;
      const subOnsiteRequired =
        typeof subOnsiteExcep === "boolean"
          ? subOnsiteExcep
          : subDateSettings.onsite_required;
      const getSubReportSettingSwitcher = (
        reportType: ReportType,
      ): React.ReactNode => {
        const subCompleted =
          !!subDateSettings[`${reportType}s_completion_count`];
        const subRequired = subSettings[`${reportType}s_required`];

        return subCompleted ? (
          <Tooltip title={text.reportWasAlreadySubmitted}>
            <Switch value={true} disabled={true} />
          </Tooltip>
        ) : (
          <Switch
            loading={!!loadingSub.find((s) => s === sub.id + "_" + reportType)}
            value={subRequired}
            onChange={async (newValue) => {
              const changeForCrews: {
                crewId: string;
                set: ReportsRequiredSetType;
              }[] = [];
              if (!newValue) {
                allCrews.forEach((crew) => {
                  const crewDateSettings = crew.dailyCompletion.at(0);
                  if (!crewDateSettings)
                    throw new Error("crew crewDateSettings not found");
                  const crewSettings = returnSettings(
                    crew.id,
                    crewDateSettings,
                    "crew",
                  );
                  if (crewSettings[`${reportType}s_required`])
                    changeForCrews.push({
                      crewId: crew.id,
                      set: crewSettings,
                    });
                });
              }
              await setProjectSubSetting(
                sub.id,
                {
                  ...subSettings,
                  [`${reportType}s_required`]: newValue,
                },
                reportType,
                changeForCrews,
              );
            }}
          />
        );
      };
      tableData.push({
        type: "sub",
        id: sub.id,
        name: sub.name,
        switchers: {
          onsite: (() => {
            const subIsOnsite = subDateSettings.is_onsite;
            return subIsOnsite ? (
              <Tooltip title={text.reportWasAlreadySubmitted}>
                <Switch value={true} disabled />
              </Tooltip>
            ) : (
              <Switch
                disabled={subIsOnsite}
                loading={!!loadingSub.find((s) => s === sub.id)}
                value={subOnsiteRequired}
                onChange={async (onOff) => {
                  const crewIdsToOff: string[] = [];
                  if (!onOff) {
                    allCrews.forEach((crew) => {
                      const onsiteExcep = onsiteExceptions[crew.id + "_crew"];
                      if (
                        typeof onsiteExcep === "boolean"
                          ? onsiteExcep
                          : crew.dailyCompletion.at(0)?.onsite_required &&
                            !crew.dailyCompletion.at(0)?.is_onsite
                      ) {
                        crewIdsToOff.push(crew.id);
                      }
                    });
                  }
                  await setSubOnOffSite(sub.id, onOff, crewIdsToOff);
                }}
              />
            );
          })(),
          preTaskPlans: getSubReportSettingSwitcher("safety_report"),
          dailyReports: getSubReportSettingSwitcher("daily_report"),
          toolboxTalks: getSubReportSettingSwitcher("toolbox_talk"),
        },
        children: showCrewData
          ? [...nonDeletedCrews]
              .sort((c1, c2) => c1.name.localeCompare(c2.name))
              .map((crew) => {
                const crewOnsiteExcep = onsiteExceptions[crew.id + "_crew"];
                const crewDateSettings = crew.dailyCompletion.at(0);
                if (!crewDateSettings) throw new Error(`Crew is not found`);
                const crewOnsiteRequired =
                  typeof crewOnsiteExcep === "boolean"
                    ? crewOnsiteExcep
                    : crewDateSettings.onsite_required;

                const crewSettings = returnSettings(
                  crew.id,
                  crewDateSettings,
                  "crew",
                );
                const getReportSettingSwitcher = (
                  reportType: ReportType,
                ): React.ReactNode => {
                  const crewCompleted =
                    !!crewDateSettings[`${reportType}s_completion_count`];
                  const reportRequired =
                    crewSettings[`${reportType}s_required`];

                  return crewCompleted ? (
                    <Tooltip title={text.reportWasAlreadySubmitted}>
                      <Switch value={true} disabled />
                    </Tooltip>
                  ) : (
                    <Switch
                      value={reportRequired}
                      loading={
                        !!loadingCrew.find(
                          (s) => s === crew.id + "_" + reportType,
                        )
                      }
                      onChange={async (newValue) => {
                        const newSettings = {
                          ...crewSettings,
                          [`${reportType}s_required`]: newValue,
                        };

                        if (
                          newValue &&
                          !subSettings[`${reportType}s_required`]
                        ) {
                          //crew is being turned ON but parent is OFF so turn ON parent(sub) too
                          await setProjectSubSetting(
                            sub.id,
                            {
                              ...subSettings,
                              [`${reportType}s_required`]: true,
                            },
                            reportType,
                            [{ crewId: crew.id, set: newSettings }],
                          );
                        } else
                          await setProjectCrewSetting(
                            crew.id,
                            newSettings,
                            reportType,
                          );
                      }}
                    />
                  );
                };
                return {
                  id: crew.id,
                  type: "crew",
                  name:
                    crew.name +
                    (crewLeads[crew.id] ? `, ${crewLeads[crew.id]}` : ""),
                  switchers: {
                    onsite: (() => {
                      // const { hint, ...optionsWithIndexAndDisabled } =
                      //   getOptionsWithIndexAndDisabled(
                      //     crewDateSettings.is_onsite,
                      //     crewOnsiteRequired,
                      //     true,
                      //   );

                      // const getOptionsWithIndexAndDisabled = (
                      //   completed: boolean,
                      //   required: boolean,
                      //   onsiteSetting?: boolean,
                      // ) => {

                      const crewIsOnsite = crewDateSettings.is_onsite;
                      // const onsiteSetting = true;

                      return crewIsOnsite ? (
                        <Tooltip title={text.reportWasAlreadySubmitted}>
                          <Switch value={true} disabled />
                        </Tooltip>
                      ) : (
                        <Switch
                          loading={!!loadingCrew.find((s) => s === crew.id)}
                          value={crewOnsiteRequired}
                          onChange={async (newValue) => {
                            crewsStartLoading(new Set([crew.id]));
                            try {
                              if (newValue && !subOnsiteRequired) {
                                //crew is being turned ON but parent is OFF so turn ON parent(sub) too

                                await setSubOnOffSite(sub.id, newValue, [
                                  crew.id,
                                ]);
                              } else {
                                await setCrewOnOffSite(crew.id, newValue);
                              }
                            } finally {
                              if (newValue && !subOnsiteRequired) {
                                setLoadingSub((prev) =>
                                  prev.filter((p) => p !== sub.id),
                                );
                              }
                              crewsStopLoading(new Set([crew.id]));
                            }
                          }}
                        />
                      );
                    })(),
                    preTaskPlans: getReportSettingSwitcher("safety_report"),
                    dailyReports: getReportSettingSwitcher("daily_report"),
                    toolboxTalks: getReportSettingSwitcher("toolbox_talk"),
                  },
                };
              })
          : undefined,
      });
    });

  return <GCOnsiteSettingsTableUi projectId={projectId} data={tableData} />;
};

interface GCOnsiteSettingsOnDateProps
  extends Omit<GCOnsiteSettingsOnDateTableProps, "date"> {}

const GCOnsiteSettingsOnDate: React.FunctionComponent<
  GCOnsiteSettingsOnDateProps
> = (props) => {
  const [date, setDate] = useState(dayjs().startOf("d"));
  return (
    <div className="flex flex-col w-full gap-1">
      <DatesNavigation
        date={date}
        onChange={(newDate) => setDate(newDate)}
        disabledDate={(current) => current.isAfter(dayjs().endOf("day"))}
      />
      <CustomSuspense>
        <GCOnsiteSettingsOnDateTable date={date} {...props} />
      </CustomSuspense>
    </div>
  );
};

export default GCOnsiteSettingsOnDate;
