import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import VisitorOrientationOrienteeContext from "src/domain-features/sitesafety/entry-routes/visitor/utils/visitorOrientationOrienteeContext";

import { useContext, useState } from "react";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import TextareaAutosize from "react-textarea-autosize";
import useSiteVisitor from "../../utils/siteVisitorHook";

export interface VisitorOrientationStepsCommentProps
  extends SiteOrientationStepProps {}

const VisitorOrientationStepsComment: React.FC<
  VisitorOrientationStepsCommentProps
> = (props) => {
  const visitorContext = useSiteVisitor();
  const [comment, setComment] = useState(visitorContext.visitor?.comment || "");
  const handleNext = () => {
    visitorContext.updateVisitor((u) => (u ? { ...u, comment } : { comment }));
    props.onNext();
  };
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={handleNext}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout headline={"Purpose"}>
        <div className="mt-1 mb-1">{"What is the purpose of your visit?"}</div>
        <TextareaAutosize
          className="p-1 w-full min-h-6 text-gray-500 border-suplementary-3 border border-b-2 border-supplementary-3 flex-1 outline-none rounded-1"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default VisitorOrientationStepsComment;
