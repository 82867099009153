import React from "react";
import { Alert } from "antd";

const defaultErrorMessage = "An Error occurred";

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const message = props.message || defaultErrorMessage;
  return <Alert showIcon message={message} type="error" />;
};

export default ErrorMessage;
