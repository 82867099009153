import React from "react";
import {
  SlackMessageMutationVariables,
  useGetUserQuery,
  User_Set_Input,
  useSlackMessageMutation,
  useUpdateUserMutation,
} from "../../types/generated/apollo/graphQLTypes";
import validateEmail from "../../functions/validateEmail";
import validatePhoneNumber from "../../functions/validatePhoneNumber";
import PhoneNumberInput from "src/common/components/phoneNumberInput/PhoneNumberInput";
import dayjs from "dayjs";
import { isProductionEnvironment } from "../../constants/environment";
import { Button, Form, Input, message, Upload } from "antd";
import sendHotjarEvent from "../../../utility-features/event-tracking/hotjar/sendHotjarEvent";
import withCustomSuspense from "../general/withCustomSuspense";
import { AntCloudOutlined } from "@ant-design/icons";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import useUploadImageOrDoc from "src/common/functions/upload-utility/useUploadImagesOrDocs";
import reportException from "../../../utility-features/error-handling/reportException";

interface SupportContactFormProps {
  userId: string;
  onDone?: () => void;
}

interface FormValues {
  message: string;
  email?: string;
  phone?: string;
  file?: { originFileObj: File; name: string; type: string }[];
}

const SupportContactForm: React.FC<SupportContactFormProps> = (props) => {
  const [form] = Form.useForm();
  const [slackMessageMutation, { loading: slackMessageLoading }] =
    useSlackMessageMutation();
  const [updateUser] = useUpdateUserMutation();

  const { uploadImagesOrDocsFromFiles, uploadingDoc } =
    useUploadImageOrDoc("image");

  const userQueryResponse = useGetUserQuery({
    variables: { userId: props.userId, includeEmployee: false },
    onCompleted: (data) => {
      form.setFieldsValue({
        email: data?.user_by_pk?.email ?? "",
        phone: data?.user_by_pk?.phone_number ?? "",
      });
    },
  });

  const onSendClick = async (values: FormValues) => {
    const user = userQueryResponse.data?.user_by_pk;
    if (!values.message.length || !user) {
      return;
    }
    let uploadedImageUrl: string | null = null;

    if (values.file) {
      const result = await uploadImagesOrDocsFromFiles(
        values.file,
        "support-doc",
      );
      if (result.length === 0) {
        return;
      } else if (result[0]?.url) {
        uploadedImageUrl = result[0].url;
      }
    }

    const userSet: User_Set_Input = {};
    if (!user.email && values.email) {
      userSet.email = values.email;
    }
    if (!user.phone_number && values.phone) {
      userSet.phone_number = values.phone;
    }

    const { name: userFullName, username: userLogin } = user;
    const pageUrl = window.location.href;

    const additionalMessageProperties: [string, string | undefined | null][] = [
      ["User Email", values.email],
      ["User Phone", values.phone],
      ["Page URL", pageUrl],
      ["User ID", "`" + props.userId + "`"],
      ["User Login", "`" + userLogin + "`"],
      ["Time", dayjs().format("MMM D, h:mm A z")],
    ];

    const additionalInfoText = additionalMessageProperties.reduce(
      (prevValue, newValue) =>
        !!newValue[1] ? prevValue + newValue.join(": ") + "\n" : prevValue,
      "",
    );

    const text = `*New Message via Customer Support Form* ${
      isProductionEnvironment ? "`production`" : "`testing`"
    }\n>${values.message
      .split("\n")
      .join(`\n>`)}\n_${userFullName}_\n${additionalInfoText}`;

    const variables: SlackMessageMutationVariables = { text };
    if (uploadedImageUrl) {
      variables.imageUrl = uploadedImageUrl;
    }

    const requests: [ReturnType<typeof slackMessageMutation>, Promise<any>?] = [
      slackMessageMutation({ variables }),
    ];

    if (Object.keys(userSet).length > 0) {
      requests.push(
        updateUser({
          variables: {
            _set: userSet,
            where: { id: { _eq: props.userId } },
          },
          optimisticResponse: {
            update_user: {
              affected_rows: 1,
              returning: [
                {
                  id: props.userId,
                  email: userSet.email ?? user.email,
                  phone_number: userSet.phone_number ?? user.phone_number,
                  __typename: "user",
                },
              ],
            },
          },
        }).catch((error) => reportException(error)),
      );
    }

    const [slackMessageResponse] = await Promise.all(requests);

    if (!slackMessageResponse.data?.slackPostMessage) {
      throw new Error("postSlackMessage retuns null");
    }
    message.success(
      "We've received your message and will reply to you. Thank you!",
    );
    sendHotjarEvent("contact_us_message_sent");
    form.resetFields(["message", "file"]);
    props.onDone?.();
  };

  return (
    <div className="flex flex-col gap-0 w-20">
      <Form
        form={form}
        layout="vertical"
        onFinish={onSendClick}
        initialValues={{
          message: "",
        }}
      >
        <Form.Item
          name="message"
          label="If you need help or want to leave feedback, drop us a message."
          className="mb-0.5"
          rules={[
            {
              required: true,
              message: "Please enter non empty message",
              transform: (value) => value.trim(),
            },
          ]}
        >
          <Input.TextArea placeholder="Text Message" />
        </Form.Item>

        <Form.Item
          className="mb-1.5"
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normalizeUploadFile}
        >
          <Upload.Dragger
            accept=".jpg,.jpeg,.png"
            maxCount={1}
            iconRender={() => <AntCloudOutlined />}
          >
            <p>Click or drag image to this area to upload</p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          name="email"
          label="Leave as a contact, so we can reply to you."
          colon={false}
          className="mb-0.5"
          rules={[
            (form) => {
              const email = form.getFieldValue("email");
              const phone = form.getFieldValue("phone");
              return {
                required: !phone && !email,
                message: "please enter email or phone",
              };
            },
            {
              validator: async (rule, value) => {
                if (value && !validateEmail(value))
                  throw new Error("Please enter valid email");
              },
            },
          ]}
        >
          <Input type="email" placeholder="Email Address" className="text-xs" />
        </Form.Item>

        <Form.Item
          name="phone"
          className="mb-1.5"
          rules={[
            {
              validator: async (rule, value) => {
                if (value && !validatePhoneNumber(value))
                  throw new Error("Phone is incorrect");
              },
            },
          ]}
        >
          <PhoneNumberInput
            disabled={userQueryResponse.loading || slackMessageLoading}
            onChange={() => {}}
            value=""
          />
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            type="primary"
            htmlType="submit"
            loading={uploadingDoc || slackMessageLoading}
            className="w-20"
          >
            Send
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withCustomSuspense(SupportContactForm);
