import IncidentReportCreator from "../../../../../../../../domain-features/incident-management/components/basic/IncidentReportCreator";
import { Button } from "antd";
import { useState } from "react";
import { GetIncidentReportAndChartOptionsQuery } from "../../../../../../../../common/types/generated/apollo/graphQLTypes";
import { OrganizationLevelName } from "../../../utils/hierarchyTypes";
import { FileAddOutlined } from "@ant-design/icons";

export interface GCAdminIncidentsReportCreatorButtonProps {
  options: GetIncidentReportAndChartOptionsQuery | undefined;
  organizationId: string;
  organizationLevel: OrganizationLevelName;
}

const GCAdminIncidentsReportCreatorButton: React.FC<
  GCAdminIncidentsReportCreatorButtonProps
> = (props) => {
  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);
  return (
    <>
      <Button
        icon={<FileAddOutlined />}
        onClick={() => setReportDrawerVisible(true)}
      >
        Report Creator
      </Button>
      {props.options && (
        <IncidentReportCreator
          organizationId={props.organizationId}
          organizationLevel={props.organizationLevel}
          reportModal={reportDrawerVisible}
          handleClose={() => setReportDrawerVisible(false)}
          options={props.options}
        />
      )}
    </>
  );
};

export default GCAdminIncidentsReportCreatorButton;
