import { useNavigate, useOutlet } from "react-router-dom";
import React, { useRef } from "react";
import { Drawer, Button } from "antd";
import ObservationTable from "../../components/ObservationTable";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  GetObservationOptionsDocument,
  GetObservationOptionsQuery,
  GetObservationOptionsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import useGcEmployee from "../../../../root/routes/views/general-contractor/hierarchy/organization-unit/utils/useGcEmployee";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";

const GCProjectObservations: React.FC<ProjectIdProps> = ({ projectId }) => {
  const employee = useGcEmployee();
  const obsTableRef = useRef<DataScrollTableRef>(null);
  const authUser = useAuthUser();
  const { data: options } = useSuspenseQuery<
    GetObservationOptionsQuery,
    GetObservationOptionsQueryVariables
  >(GetObservationOptionsDocument, {
    variables: {
      projectId,
      gcId: employee.general_contractor.id,
      userId: authUser.uid,
      projectWorkerWhere: { id: { _is_null: true } },
      subWhere: { subcontractor_projects: { project_id: { _eq: projectId } } },
    },
  });
  const navigate = useNavigate();
  const outlet = useOutlet();
  return (
    <div>
      <ObservationTable
        ref={obsTableRef}
        showInReview={
          options?.project_setting_by_pk
            ?.require_gc_to_mark_observation_complete
        }
        riskLevels={options?.risk_level}
        onRowClick={(item) => {
          navigate(`${item.id}`);
        }}
        subs={options?.subcontractor}
        loggedInUserData={options?.userData}
        where={{
          project_id: { _eq: projectId },
          _and: [
            {
              _or: [
                { status: { _neq: "draft" } },
                {
                  status: { _eq: "draft" },
                  created_by_uid: { _eq: authUser.uid },
                },
              ],
            },
            {
              _or: [
                {
                  observation_inspection: { submitted_on: { _is_null: false } },
                },
                { observation_inspection_id: { _is_null: true } },
              ],
            },
          ],
        }}
        projectId={projectId}
        excludedKeys={["project_name"]}
      />
      <Drawer
        title={<div className="text-1.5">Observation Details</div>}
        open={!!outlet}
        placement="right"
        onClose={() => navigate(-1)}
        width={"80%"}
      >
        {outlet}
      </Drawer>
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectObservations);
