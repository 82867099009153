import { useLazyLoadQuery } from "react-relay/hooks";
import { useGcOrientationDataQuery } from "src/common/types/generated/relay/useGcOrientationDataQuery.graphql";
import useAuthUser from "src/common/hooks/useAuthUser";
import { graphql } from "babel-plugin-relay/macro";
import { OrientationNotifyEmailUserType } from "../entryRoutes/gcDashboard/routes/settings/components/NotifyUserSettings";

// TODO: Shubham: I would like to see if we should separate data for settings page from this query( if it's only used there and increases load time significantly

const orientationQuery = graphql`
  query useGcOrientationDataQuery(
    $projectId: uuid!
    $userId: uuid!
    $emailProjectUserTypes: [String!]!
  ) {
    orientation_project_options: project_connection(
      where: {
        general_contractor: { projects: { id: { _eq: $projectId } } }
        id: { _neq: $projectId }
      }
      order_by: { name: asc }
    ) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
          orientation_project_id
          linked_orientation_projects {
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }, first: 1)
      @connection(
        key: "useGcOrientationDataQuery_project_connection"
        filters: []
      ) {
      edges {
        node {
          id
          name
          union_labor
          general_contractor_id
          ask_age
          ask_ethnicity
          ask_veteran_type
          ask_years_with_employer
          ask_number_of_years_in_construction
          require_phone
          require_dob
          ask_gender
          ask_residence
          assign_hard_hat
          ask_emergency_contact
          orientation_tv_player
          in_person_orientation
          orientation_qr_code_url
          gc_secret_qr_code_url
          automatically_assign_hard_hat
          add_hard_hat_during_in_person
          gc_orientation_login_required
          worker_scan_in_person_qr_code
          next_hard_hat_number
          require_certification_upload
          perform_drugtest
          drug_test_retest_days
          require_drugtest_upload
          drugtest_validity_days
          required_quiz_percentage
          require_profile_photo
          agc_universal_orientation
          background_check_tracker
          show_onsite_for_not_oriented_workers
          send_email_for_drug_test_worker_arrival
          gc_complete_orientation_in_person_qr_code_url
          add_hard_hat_during_registration
          send_verification_invite
          require_certs
          general_contractor {
            require_phone
            require_dob
            gc_projects {
              project_id
            }
          }
          orientation_project_id
          orientation_project {
            name
          }
          project_subcontractors {
            subcontractor_id
            subcontractor {
              name
            }
          }
          gc_office {
            gc_office_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          gc_division {
            gc_division_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          gc_business_unit {
            gc_business_unit_employees_aggregate(
              where: { user_id: { _eq: $userId } }
            ) {
              aggregate {
                count
              }
            }
          }
          project_employees {
            employee {
              uid
              user {
                name
              }
            }
          }
          orientationEmployees: project_employees(
            where: { user_orientation: {} }
          ) {
            id
          }
          project_certifications {
            id
            certification {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            alternate_certifications {
              id
              alternate_certification {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }
    email_project_user_connection(
      where: {
        project_id: { _eq: $projectId }
        type: { _in: $emailProjectUserTypes }
      }
      order_by: { user: { name: asc } }
      first: 10000
    )
      @connection(
        key: "useGcOrientationDataQuery_email_project_user_connection"
        filters: []
      ) {
      edges {
        node {
          id
          type
          user_id
          user {
            name
          }
        }
      }
    }
    user_to_verify_new_users_connection(
      where: { project_id: { _eq: $projectId } }
      first: 10000
    )
      @connection(
        key: "useGcOrientationDataQuery_user_to_verify_new_users_connection"
        filters: []
      ) {
      edges {
        node {
          id
          user_id
          user {
            name
          }
        }
      }
    }
    project_oac_company_connection(
      where: {
        project: {
          general_contractor: { employees: { user: { id: { _eq: $userId } } } }
        }
      }
      order_by: { oac_company: { name: asc } }
      first: 10000
    )
      @connection(
        key: "useGcOrientationDataQuery_project_oac_company_connection"
        filters: []
      ) {
      edges {
        node {
          project_id
          oac_company_id
          oac_company {
            name
          }
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        _and: [
          {
            _or: [
              {
                general_contractor_id: { _is_null: true }
                project_orientations: {
                  project_id: { _is_null: true }
                  required_by_all_workers: { _eq: true }
                }
              }
              { general_contractor: { projects: { id: { _eq: $projectId } } } }
            ]
          }
          {
            _or: [
              { project_id: { _is_null: true } }
              {
                project: {
                  linked_orientation_projects: { id: { _eq: $projectId } }
                }
              }
            ]
          }
        ]
      }
      order_by: [{ order: asc }, { name: asc }]
      first: 10000
    )
      @connection(
        key: "GCProjectOrientationsQuery_orientation_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
          type
          created_at
          project_id
          user {
            name
            employee {
              general_contractor {
                name
              }
            }
          }
          project_orientations(
            where: {
              project: {
                linked_orientation_projects: { id: { _eq: $projectId } }
              }
            }
            order_by: { created_at: asc }
          ) {
            id
            pk: id @__clientField(handle: "pk")
            orientation_id
            project_id
            hide_but_give_credit
            play_during_in_person
            play_during_registration
            required_by_all_workers
          }
        }
      }
    }
    worker_title_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          translation {
            en
          }
        }
      }
    }
    worker_role_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          value
          translation {
            en
          }
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          employee {
            general_contractor_id
          }
        }
      }
    }
    quiz_code: secret_login_link_connection(
      where: {
        project_id: { _eq: $projectId }
        final_url: { _ilike: "/orientation/quiz" }
      }
      order_by: [{ created_at: desc }]
      first: 1
    ) @connection(key: "GCProjectOrientation_quiz_code", filters: []) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    #added temporary.  TODO: remove ugly query dependency on final_url
    in_person_code: secret_login_link_connection(
      where: {
        project_id: { _eq: $projectId }
        user_id: { _eq: $userId }
        final_url: {
          _ilike: "/gce/orientation/%"
          _nilike: "/gce/orientation/%?%"
        }
      }
      order_by: [{ created_at: desc }]
      first: 1
    ) @connection(key: "GCProjectOrientation_in_person_code", filters: []) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    #added temporary.
    complete_orientation_in_person_code: secret_login_link_connection(
      where: {
        project_id: { _eq: $projectId }
        user_id: { _eq: $userId }
        final_url: {
          _ilike: "/gce/orientation/%?completionType=inpersonComplete"
        }
      }
      order_by: [{ created_at: desc }]
      first: 1
    )
      @connection(
        key: "GCProjectOrientation_complete_orientation_in_person_code"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    certification_connection(
      where: { is_custom: { _eq: false } }
      order_by: { name: asc }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
    worker_title_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          translation {
            en
          }
        }
      }
    }
  }
`;

const useGcOrientationData = (projectId: string) => {
  const authUser = useAuthUser();
  const emailProjectUserTypesToQuery: OrientationNotifyEmailUserType[] = [
    "quiz_failure",
    "worker_with_drug_test_arrival",
    "orientation_auto_email",
  ];
  const orientationData = useLazyLoadQuery<useGcOrientationDataQuery>(
    orientationQuery,
    {
      projectId,
      userId: authUser.uid,
      emailProjectUserTypes: emailProjectUserTypesToQuery,
    },
    { fetchPolicy: "store-or-network" },
  );

  const getProjectOrientation = () => {
    const projectData = (orientationData.project_connection.edges || [])[0]
      ?.node;
    if (!projectData) {
      throw new Error(`Project is not found`);
    }
    return projectData;
  };

  const getOrientations = () =>
    (orientationData?.orientation_connection.edges || []).map((v) => ({
      ...v.node,
      key: v.node.pk,
    }));

  return { orientationData, getOrientations, getProjectOrientation };
};

export default useGcOrientationData;
