import React from "react";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../../common/components/layouts/tabs/CustomTabsRouted";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import getGcProjectSettingsPath from "../../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import ProjectIdProps from "../../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../common/hoc/withProjectIdUrlParam";

const GCProjectSitedelivery: React.FC<ProjectIdProps> = (props) => {
  const navigate = useNavigate();
  const featureSettingsPath = getGcProjectSettingsPath(
    props.projectId,
    "deliveries",
  );

  const tabs: CustomTabsRoutedProps["tabs"] = [
    // TODO change naming
    {
      label: "Calendar",
      path: "calendar",
    },
    {
      label: "SubBlocks",
      path: "sub-blocks",
    },
  ];

  const basePath = `gce/hierarchy/project/:projectId/site-delivery`;

  return (
    <AppPageLayout
      pageTitle={"Deliveries"}
      pageActions={[
        {
          children: "Settings",
          icon: <SettingOutlined />,
          onClick: () => {
            navigate(featureSettingsPath);
          },
        },
      ]}
    >
      <CustomTabsRouted tabs={tabs} basePath={basePath} />
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCProjectSitedelivery);
