import {
  FolderOpenOutlined,
  HistoryOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useSuspenseQuery } from "@apollo/client";
import { IconDownload, IconMail } from "@tabler/icons";
import {
  Button as AntdButton,
  Card,
  Col,
  Input,
  Modal,
  Tabs,
  TabsProps,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { FC, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import { DigTicket } from "src/domain-features/sitesafety/components/PermitDigTicket";
import PermitTable from "src/domain-features/sitesafety/components/PermitTable";
import {
  FieldRecord,
  GridFieldSection,
  Section,
} from "src/domain-features/sitesafety/components/PertmitReportsSections";
import {
  ChecklistTable,
  EquipmentReadingsTable,
  MonitoringEquipmentTable,
  PermitImages,
  SupervisorsTable,
  WorkersTable,
} from "src/domain-features/sitesafety/components/ReportsTables";
import capitalize from "src/common/functions/capitalize";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { emailRegex } from "src/common/functions/regex";
import { titleCase } from "src/common/functions/stringFormatters";
import {
  GetCeilingAccessPermitByPkDocument,
  GetCeilingAccessPermitByPkQuery,
  GetConfinedSpacePermitByPkDocument,
  GetConfinedSpacePermitByPkQuery,
  GetDigExcavationPermitByPkDocument,
  GetDigExcavationPermitByPkQuery,
  GetDigPermitByPkDocument,
  GetDigPermitByPkQuery,
  GetHistoricalAccessPermitByPkDocument,
  GetHistoricalAccessPermitByPkQuery,
  GetHotWorkPermitByPkDocument,
  GetHotWorkPermitByPkQuery,
  GetInteriorPenetrationPermitByPkDocument,
  GetInteriorPenetrationPermitByPkQuery,
  GetLadderPermitByPkDocument,
  GetLadderPermitByPkQuery,
  GetLotoPermitByPkDocument,
  GetLotoPermitByPkQuery,
  GetOutOfBasketPermitByPkDocument,
  GetOutOfBasketPermitByPkQuery,
  GetSteelErectionPermitByPkDocument,
  GetSteelErectionPermitByPkQuery,
  GetWorkAbovePermitByPkDocument,
  GetWorkAbovePermitByPkQuery,
  useEmailPermitPdfMutation,
  useGeneratePermitPdfMutation,
  useGetPermitDetailExtraDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import Image from "src/common/components/general/images/Image";
import getNotPermissibleReadingsCount from "../entry-routes/permits/components/utils/getNotPermissibleReadingsCount";
import withUrlParams from "../../../common/hoc/withUrlParams";

const { Title, Text } = Typography;
export const getTypeConfinedSpaceTitle = (type?: string | null) =>
  type === "reclassified"
    ? "Reclassified "
    : type === "alternate"
    ? "Alternate Entry "
    : type === "permitted"
    ? "Permit Required "
    : "";

export interface PaneProps {
  key?: string;
  icon: ReactNode;
  title: string;
  content?: ReactNode[];
}

export interface DetailSectionPanesProps {
  panes: PaneProps[];
  initialKey?: string | null;
}

const DetailSectionPanes = ({ panes, initialKey }: DetailSectionPanesProps) => {
  const [activeKey, setActiveKey] = useState<string>(
    initialKey ? initialKey : "1",
  );

  const handleTabClick = (toKey: string) => {
    setActiveKey(toKey);
  };
  let x = 0;
  const tabItems: TabsProps["items"] = panes.map((item: PaneProps, idx) => {
    const tab = (
      <span key={"tab_" + idx}>
        {item.icon}
        {item.title}
      </span>
    );
    return { key: `${idx + 1}`, label: tab, children: item.content || null };
    // <TabPane key={(idx + 1).toString()} tab={tab}>
    //   {item.content ?? null}
    // </TabPane>
  });

  return (
    <Tabs
      activeKey={(+activeKey % panes.length || panes.length).toString()} // prevent index overrun
      defaultActiveKey="1"
      tabPosition="left"
      onTabClick={handleTabClick}
      items={tabItems}
    />
  );
};

const selectQueryByType = (permitType: string) => {
  if (permitType === "dig") {
    return GetDigPermitByPkDocument;
  } else if (permitType === "hot_work") {
    return GetHotWorkPermitByPkDocument;
  } else if (permitType === "work_above") {
    return GetWorkAbovePermitByPkDocument;
  } else if (permitType === "confined_space") {
    return GetConfinedSpacePermitByPkDocument;
  } else if (permitType === "ladder") {
    return GetLadderPermitByPkDocument;
  } else if (permitType === "historical_access") {
    return GetHistoricalAccessPermitByPkDocument;
  } else if (permitType === "interior_penetration") {
    return GetInteriorPenetrationPermitByPkDocument;
  } else if (permitType === "loto") {
    return GetLotoPermitByPkDocument;
  } else if (permitType === "steel_erection") {
    return GetSteelErectionPermitByPkDocument;
  } else if (permitType === "out_of_basket") {
    return GetOutOfBasketPermitByPkDocument;
  } else if (permitType === "ceiling_access") {
    return GetCeilingAccessPermitByPkDocument;
  } else {
    return GetDigExcavationPermitByPkDocument;
  }
};

interface FieldList {
  [key: string]: {
    [key: string]: FieldRecord[];
  };
}

type PermitDataQueryType =
  | GetHotWorkPermitByPkQuery
  | GetDigPermitByPkQuery
  | GetConfinedSpacePermitByPkQuery
  | GetWorkAbovePermitByPkQuery
  | GetLadderPermitByPkQuery
  | GetHistoricalAccessPermitByPkQuery
  | GetInteriorPenetrationPermitByPkQuery
  | GetDigExcavationPermitByPkQuery
  | GetLotoPermitByPkQuery
  | GetSteelErectionPermitByPkQuery
  | GetOutOfBasketPermitByPkQuery
  | GetCeilingAccessPermitByPkQuery;

const GCProjectReportsPermitsDetail: FC<{
  projectId: string;
  permitType: string;
  permitId: string;
}> = ({ permitType, projectId, permitId }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailPermitPdf] = useEmailPermitPdfMutation();
  const queryType = selectQueryByType(permitType);
  const [searchParams, setSearchParams] = useSearchParams();
  const paneKey = searchParams.get("paneNumber");
  const lotoLockId = searchParams.get("lotoLockId");
  const { data: projectSettingData, error: eer1 } =
    useGetPermitDetailExtraDataQuery({
      variables: { projectId },
    });

  const { data, error, refetch } = useSuspenseQuery<PermitDataQueryType, any>(
    queryType,
    {
      variables: {
        permitId,
        permitWorkersFilter:
          permitType === "loto"
            ? lotoLockId
              ? { loto_permit_lock_detail_id: { _eq: lotoLockId } }
              : { permit_id: { _is_null: true } }
            : {},
      },
    },
  );
  const permit = data.permit;
  if (error) throw error;
  if (!permit) throw new Error("Permit not found for given permitId");

  const [downloadPermitPdf] = useGeneratePermitPdfMutation();
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const navigate = useNavigate();

  const location = useLocation();

  const handleSendPermit = () => {
    if (emailAddress === "" || !emailRegex.test(emailAddress)) {
      return Modal.warn({
        content: <p>Please enter a valid email address</p>,
      });
    }

    emailPermitPdf({
      variables: {
        input: {
          emailRecipients: [emailAddress],
          permitType,
          permitId,
        },
      },
    }).catch((error) => {
      console.error(error);
      return Modal.warn({
        content: (
          <p>Something went wrong. Could not send permit at this time.</p>
        ),
      });
    });

    Modal.success({
      content: <p>Sent Permit to {emailAddress}.</p>,
    });

    setIsModalVisible(false);
  };

  const lotoLockIndex =
    "loto_permit" in permit && permit.loto_permit
      ? permit.loto_permit.loto_permit_lock_details.findIndex(
          (lock: { id: string }) => lock.id === lotoLockId,
        )
      : -1;
  const lotoLock =
    "loto_permit" in permit && lotoLockIndex !== -1 && permit?.loto_permit
      ? permit.loto_permit.loto_permit_lock_details[lotoLockIndex]
      : undefined;

  const hotWorkPermitName =
    permit?.project?.general_contractor?.hot_work_permit_name;
  useEffect(() => {
    if (permitType === "loto")
      (async () => {
        // setRefetching(true);
        const data1 = await refetch({
          permitId,
          permitWorkersFilter: lotoLockId
            ? { loto_permit_lock_detail_id: { _eq: lotoLockId } }
            : { permit_id: { _is_null: true } },
        });
      })();
  }, [lotoLockId]);
  const fieldsByPermitType: FieldList = {
    shared: {
      "gridsection-closeout-info": [
        {
          key: "closingNotes",
          dataIndex: "",
          displayName: "Closing Notes",
          render: (_: null, permit: any) =>
            permit.permit_histories[0]?.comment?.en,
        },
      ],
      "gridsection-submission-details": [
        {
          key: "submissionField",
          dataIndex: "",
          displayName: "Submitted By",
          render: (_: null, permit: any) =>
            titleCase(permit.applicant_project_worker?.user?.name),
        },
        {
          key: "submissionDate",
          dataIndex: "",
          displayName: "Submitted On",
          fieldProps: { span: 12 },
          render: (_: null, permit: any) => {
            let submitted = "";
            if (permit.is_historical && permit.permit_histories.length > 0) {
              submitted = dayjs(permit.permit_histories[0].created_at).format(
                "lll",
              );
            } else {
              submitted = dayjs(permit.created_at).format("lll");
            }
            return submitted;
          },
        },
        {
          key: "approvedBy",
          displayName: "Reviewed/Approved By",
          dataIndex: "",
          render: (_: null, permit: any) => {
            let approvedBy = "";
            if (!permit.is_historical && permit.permit_histories.length > 0) {
              approvedBy = permit.permit_histories[0].created_by_user.name;
            } else {
              approvedBy = dayjs(permit.created_at).format("lll");
            }
            return approvedBy;
          },
        },
        {
          key: "approvedOn",
          displayName: "Approved On",
          dataIndex: "",
          render: (_: null, permit: any) => {
            let submitted = "";
            if (!permit.is_historical && permit.permit_histories.length > 0) {
              submitted = dayjs(permit.permit_histories[0].created_at).format(
                "lll",
              );
            } else {
              submitted = dayjs(permit.created_at).format("lll");
            }
            return submitted;
          },
        },
        {
          key: "closedBy",
          displayName: "Closed By",
          dataIndex: "",
          render: (_: null, permit: any) => {
            let closedBy = "";
            if (!permit.is_historical && permit.permit_histories.length > 0) {
              closedBy = titleCase(
                permit.permit_histories[0]?.created_by_user?.name ??
                  "Auto Close",
              );
            } else {
              closedBy = titleCase(
                permit.origin_permit_history?.created_by_user?.name ??
                  "Auto Close",
              );
            }
            return closedBy;
          },
        },
        {
          key: "closedOn",
          displayName: "Closed On",
          dataIndex: "",
          render: (_: null, permit: any) => {
            let submitted = "";
            if (!permit.is_historical && permit.permit_histories.length > 0) {
              submitted = dayjs(permit.permit_histories[0].created_at).format(
                "lll",
              );
            } else {
              submitted = dayjs(permit.created_at).format("lll");
            }
            return submitted;
          },
        },
      ],
      "gridsection-project-info": [
        {
          key: "projectGC",
          displayName: "General Contractor",
          dataIndex: ["project", "general_contractor", "name"],
        },
        {
          key: "projectContact",
          displayName: "GC Point Contact",
          dataIndex: [
            "project",
            "permit_contact_project_employee",
            "employee",
            "user",
            "name",
          ],
        },
        {
          key: "projectEmail",
          displayName: "Contact Email",
          dataIndex: [
            "project",
            "permit_contact_project_employee",
            "employee",
            "user",
            "email",
          ],
          render: (value: string) => <a href={`mailto:${value}`}>{value}</a>,
        },
      ],
    },
    steel_erection: {
      "gridsection-work-details": [
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "en"],
        },
        {
          key: "workDuration",
          displayName: "Duration of Work",
          dataIndex: ["steel_erection_permit", "work_duration"],
          render: (v) => (v ? `${v.split(":")[0]} hrs` : ""),
        },
        {
          key: "workStartAt",
          displayName: "Work start at",
          dataIndex: ["work_start_at"],
          render: (d?: string) =>
            d ? dayjs(d).format("MMMM Do YYYY, hh:mm a") : "",
        },
      ],
    },
    out_of_basket: {
      "gridsection-work-details": [
        {
          key: "workStartAt",
          displayName: "Work start at",
          dataIndex: ["work_start_at"],
          render: (d?: string) =>
            d ? dayjs(d).format("MMMM Do YYYY, hh:mm a") : "",
        },
        {
          key: "workDuration",
          displayName: "Duration of Work",
          dataIndex: ["out_of_basket_permit", "work_duration"],
          render: (v) => (v ? `${v.split(":")[0]} hrs` : ""),
        },
        {
          key: "workDescription",
          displayName: "Activity/Task(s) to be performed",
          dataIndex: ["work_description", "en"],
        },
        {
          key: "workSurface",
          displayName: "working surface or platform",
          dataIndex: ["out_of_basket_permit", "work_surface", "en"],
        },
        {
          key: "reasonForExitingMEWP",
          displayName: "Reason for exiting MEWP",
          dataIndex: ["out_of_basket_permit", "reason_for_exiting_mewp", "en"],
        },
        {
          key: "workLocation",
          displayName: "Area (i.e. Building Section)",
          dataIndex: ["out_of_basket_permit", "fall_arrest_system", "en"],
        },
        {
          key: "maxFallArrestDistance",
          displayName: "Max Fall Arrest Distance (in feet)",
          dataIndex: ["out_of_basket_permit", "max_fall_arrest_distance"],
        },
        {
          key: "workingHeight",
          displayName: "Working Height (in feet)",
          dataIndex: ["out_of_basket_permit", "working_height"],
        },
      ],
    },
    dig: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker.user.name)}
                  {worker.title.translation
                    ? `, ${worker.title.translation.clientText}`
                    : ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "workDuration",
          displayName: "Duration of Work",
          dataIndex: ["dig_permit", "work_duration"],
          render: (v) => (v ? `${v.split(":")[0]} hrs` : ""),
        },
        {
          key: "datetimeExpires",
          displayName: "Excavation Types",
          dataIndex: ["dig_permit", "excavationTypes"],
          render: (types: any[]) => (
            <div>
              {types.map((type: any) => (
                <Tag key={type.select_option.name.clientText} color="blue">
                  {type.select_option.name.clientText}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: "soilType",
          displayName: "Soil Type",
          dataIndex: ["dig_permit", "soil_type", "name", "clientText"],
        },
        {
          key: "utilityLocatesPerformed",
          displayName: "Utility Locates Performed",
          dataIndex: ["dig_permit", "did_utility_locates"],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "utilitiesIdentified",
          displayName: "Utilities Identified",
          dataIndex: ["dig_permit", "utilityTypes"],
          render: (types: any[]) => {
            return (
              <div>
                {types.map((type: any) => (
                  <Tag key={type.select_option.name.clientText} color="blue">
                    {type.select_option.name.clientText}
                  </Tag>
                ))}
              </div>
            );
          },
        },
        {
          key: "utilitiesIdentifingMethods",
          displayName: "How were utilities Identified",
          dataIndex: ["dig_permit", "utilityIdentifyingMethods"],
          render: (types: any[]) => (
            <div>
              {types.map((type: any) => (
                <Tag key={type.select_option.name.clientText} color="blue">
                  {type.select_option.name.clientText}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: "protectionSystems",
          displayName: "Protection Systems",
          dataIndex: ["dig_permit", "used_protection_system"],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "protectionSystemTypes",
          displayName: "Protection Systems Types",
          dataIndex: ["dig_permit", "protectionSystemTypes"],
          render: (types: any[]) => (
            <div>
              {types.map((type: any) => (
                <Tag key={type.select_option.name.clientText} color="blue">
                  {type.select_option.name.clientText}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: "excavationDeeperThan20feet",
          displayName: "Deeper than 20 feet",
          dataIndex: ["dig_permit", "excavation_deeper_20_feet"],
          render: (val: boolean) => (val ? "Yes" : "No"),
        },
      ],
    },
    hot_work: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          fieldProps: { span: 12 },
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker.user.name)}
                  {`, ${worker.title?.translation?.clientText}` || ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "workDuration",
          displayName: "Duration of Work",
          dataIndex: ["hot_work_permit", "work_duration"],
          render: (v) => (v ? `${v.split(":")[0]} hrs` : ""),
        },
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "clientText"],
        },
        {
          key: "workDescription",
          displayName: "Work Description",
          dataIndex: ["work_description", "clientText"],
        },
        {
          key: "specialPrecautions",
          displayName: "Special Precautions or Hazards",
          dataIndex: [
            "hot_work_permit",
            "special_precautions_description",
            "clientText",
          ],
          render: (val: string) =>
            val === undefined || val === "" ? (
              "No"
            ) : (
              <Tag color="orange">{val}</Tag>
            ),
        },
      ],
    },
    confined_space: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker.user.name)}
                  {`, ${worker.title?.translation?.clientText}` || ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "entryDateTime",
          displayName: "Date/Time of Entry",
          dataIndex: ["work_start_at"],
          render: (d?: string) => (d ? dayjs(d).format("lll") : ""),
        },
        {
          key: "workDuration",
          displayName: "Duration of Work",
          dataIndex: ["confined_space_permit", "work_duration"],
          render: (v) => (v ? `${v.split(":")[0]} hrs` : ""),
        },
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "clientText"],
        },
        {
          key: "workDescription",
          displayName: "Work Description",
          dataIndex: ["work_description", "clientText"],
        },
        {
          key: "confinedSpaceDescription",
          displayName: "Confined Space Description",
          dataIndex: [
            "confined_space_permit",
            "confined_space_description",
            "clientText",
          ],
        },
        {
          key: "hazardDescription",
          displayName: "Hazards",
          dataIndex: [
            "confined_space_permit",
            "hazards_description",
            "clientText",
          ],
        },
        {
          key: "permittedType",
          displayName: "Permited",
          dataIndex: ["confined_space_permit", "type"],
          render: (value: any) => titleCase(value),
        },
        {
          key: "Emergency Contact",
          displayName: "Emergency Contact",
          dataIndex: ["confined_space_permit", "emergency_contact_employee"],
          render: (worker: any) => titleCase(worker.user.name),
        },
        {
          key: "emergencyPlan",
          displayName: "Rescue Plan",
          dataIndex: ["confined_space_permit", "rescue_plan", "clientText"],
        },
        {
          key: "communicationProcedure",
          displayName: "Communication Procedure",
          dataIndex: [
            "confined_space_permit",
            "communication_procedure",
            "clientText",
          ],
        },
        {
          key: "equipmentUsed",
          displayName: "Equipment Used",
          dataIndex: ["confined_space_permit", "equipment", "clientText"],
        },
      ],
    },
    work_above: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker?.user?.name)}
                  {`, ${worker?.title?.translation.clientText}` || ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker?.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "entryDateTime",
          displayName: "Date/Time of Entry",
          dataIndex: ["work_above_permit", "created_at"],
          render: (d?: string) => (d ? dayjs(d).format("lll") : ""),
        },
        {
          key: "workDuration",
          displayName: "Duration of Work",
          dataIndex: ["work_above_permit", "work_duration"],
          render: (v) => (v ? `${v.split(":")[0]} hrs` : ""),
        },
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "clientText"],
        },
        {
          key: "workLocationType",
          displayName: "Location Type",
          dataIndex: [
            "work_above_permit",
            "work_above_floor",
            "work_above_elevation",
            "work_above_elevation_type",
            "name",
            "en",
          ],
        },
        {
          key: "workLocationName",
          displayName: "Location Name",
          dataIndex: [
            "work_above_permit",
            "work_above_floor",
            "work_above_elevation",
            "name",
            "en",
          ],
        },
        {
          key: "workFloorName",
          displayName: "Location Floor Name",
          dataIndex: ["work_above_permit", "work_above_floor", "name", "en"],
        },
        {
          key: "workDescription",
          displayName: "Work Description",
          dataIndex: ["work_description", "clientText"],
        },
      ],
    },
    ladder: {
      "gridsection-inspection-details": [
        {
          key: "dateTimeInspection",
          displayName: "Date and Time of Inspection",
          dataIndex: ["ladder_permit", "last_inspection_at"],
        },
        {
          key: "userInspected",
          displayName: "User that Inspected",
          dataIndex: [],
          render: (permit: any) => {
            const permitHistory = permit.permit_histories;
            const inspectionProperties = permitHistory
              ? permitHistory.find((history: any) => {
                  return history.status === "inspected";
                })
              : null;
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>{titleCase(permit?.applicant_project_worker?.user?.name)}</p>
                <Image
                  src={inspectionProperties.signature_image.url}
                  preview
                ></Image>
              </span>
            );
          },
        },
        {
          key: "imagesLadder",
          displayName: "Photo's of Ladder",
          dataIndex: [],
          render: (permit) => {
            if (permit.images.length === 0) {
              return <span>Ladder Images</span>;
            } else {
              return (
                <div className="min-w-32 min-h-32 z-50">
                  {permit.images.map((image: any) => {
                    return (
                      <Image src={image.url} key={image.url} preview></Image>
                    );
                  })}
                </div>
              );
            }
          },
        },
      ],
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          fieldProps: { span: 12 },
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker.user.name)}
                  {`, ${worker.title?.translation?.clientText}` || ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "ladderPermitType",
          displayName: "Ladder Permit Type",
          dataIndex: ["ladder_permit", "is_access_ladder"],
          render: (val) => (val ? "Access Ladder" : "Non Access Ladder"),
        },
        {
          key: "workDuration",
          displayName: "Duration",
          dataIndex: ["ladder_permit", "duration"],
          render: (val) => (val === 1 ? "1 day" : `${val} days`),
        },
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "clientText"],
        },
        {
          key: "workDescription",
          displayName: "Work Description",
          dataIndex: ["work_description", "clientText"],
        },
        {
          key: "necessityDescription",
          displayName: "Why it is necessary to use ladder",
          dataIndex: ["ladder_permit", "necessity_description", "clientText"],
        },
        {
          key: "typeOfLadder",
          displayName: "Type of ladder",
          dataIndex: ["ladder_permit", "type_of_ladder"],
        },
        {
          key: "ladderCapacity",
          displayName: "Ladder Capacity",
          dataIndex: ["ladder_permit", "ladder_capcity"],
        },
        {
          key: "ladderHeight",
          displayName: "Ladder Height",
          dataIndex: ["ladder_permit", "ladder_height"],
        },
        {
          key: "ladderIdentification",
          displayName: "Ladder Identification",
          dataIndex: ["ladder_permit", "ladder_identification"],
        },
        {
          key: "specialPrecautions",
          displayName: "Special Precautions or Hazards",
          dataIndex: [
            "ladder_permit",
            "special_precautions_description",
            "clientText",
          ],
          render: (val: string) =>
            val === undefined || val === "" ? (
              "No"
            ) : (
              <Tag color="orange">{val}</Tag>
            ),
        },
      ],
    },
    historical_access: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          fieldProps: { span: 12 },
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker.user.name)}
                  {`, ${worker.title?.translation?.clientText}` || ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "workDuration",
          displayName: "Duration",
          dataIndex: ["historical_access_permit", "duration"],
          render: (val) => (val < 24 ? `${val} hours` : `${val / 24} days`),
        },
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "clientText"],
        },
        {
          key: "workDescription",
          displayName: "Work Description",
          dataIndex: ["work_description", "clientText"],
        },
        {
          key: "hotWorkProtection",
          displayName: `${
            hotWorkPermitName === "burn" ? "Burn" : "Hot Work"
          } being performed`,
          dataIndex: [
            "historical_access_permit",
            "hot_work_protection_description",
            "clientText",
          ],
          render: (val: string) =>
            val === undefined || val === "" ? (
              "No"
            ) : (
              <Tag color="orange">{val}</Tag>
            ),
        },
        {
          key: "wetWorkProtection",
          displayName: "Wet Work being performed",
          dataIndex: [
            "historical_access_permit",
            "wet_work_protection_description",
            "clientText",
          ],
          render: (val: string) =>
            val === undefined || val === "" ? (
              "No"
            ) : (
              <Tag color="orange">{val}</Tag>
            ),
        },
        {
          key: "additionalProtection",
          displayName: "Additional protection",
          dataIndex: [
            "historical_access_permit",
            "additional_protection_description",
            "clientText",
          ],
          render: (val: string) =>
            val === undefined || val === "" ? (
              "No"
            ) : (
              <Tag color="orange">{val}</Tag>
            ),
        },
        {
          key: "specialPrecautions",
          displayName: "Special Precautions or Hazards",
          dataIndex: [
            "historical_access_permit",
            "special_precautions_description",
            "clientText",
          ],
          render: (val: string) =>
            val === undefined || val === "" ? (
              "No"
            ) : (
              <Tag color="orange">{val}</Tag>
            ),
        },
      ],
    },
    interior_penetration: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker.user.name)}
                  {worker.title.translation
                    ? `, ${worker.title.translation.clientText}`
                    : ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "workStartAt",
          displayName: "Work start at",
          dataIndex: ["work_start_at"],
          render: (d?: string) => (d ? dayjs(d).format("MMMM Do YYYY") : ""),
        },
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "clientText"],
        },
        {
          key: "workDescription",
          displayName: "Work Description",
          dataIndex: ["work_description", "clientText"],
        },
        {
          key: "safetyMeetingDate",
          displayName: "Safety Meeting Date",
          dataIndex: ["interior_penetration_permit", "safety_meeting_date"],
          render: (d?: string) => (d ? dayjs(d).format("MMMM Do YYYY") : ""),
        },
        {
          key: "utilityLocateMethods",
          displayName: "Methods used to locate utilities",
          dataIndex: ["interior_penetration_permit", "utilityLocateMethods"],
          render: (types: any[]) => {
            return (
              <div>
                {types.map((type: any) => (
                  <Tag key={type.select_option.name.clientText} color="blue">
                    {type.select_option.name.clientText}
                  </Tag>
                ))}
              </div>
            );
          },
        },
        {
          key: "utilityIdentified",
          displayName: "Utility Identified",
          dataIndex: [
            "interior_penetration_permit",
            "did_utilities_identified",
          ],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "utilityTypes",
          displayName: "Utilities identified in excavation area",
          dataIndex: ["interior_penetration_permit", "utilityTypes"],
          // fieldProps: { hidden: true },
          render: (types: any[]) => (
            <div>
              {types.map((type: any) => (
                <Tag key={type.select_option.name.clientText} color="blue">
                  {type.select_option.name.clientText}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: "didLocateProvidedByThirdParty",
          displayName: "Locates provided by third party",
          dataIndex: [
            "interior_penetration_permit",
            "did_locate_provided_by_third_party",
          ],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "nameOfThirdPartyService",
          displayName: "Name of 3rd Party Service",
          dataIndex: ["interior_penetration_permit", "name_of_third_party"],
        },
        {
          key: "dateLocateProvided",
          displayName: "Date locate provided",
          dataIndex: ["interior_penetration_permit", "date_locate_provided"],
          render: (d?: string) => (d ? dayjs(d).format("MMMM Do YYYY") : ""),
        },
        {
          key: "methodOfSurfacePenetration",
          displayName: "Method of surface penetration",
          dataIndex: [
            "interior_penetration_permit",
            "methodsOfSurfacePenetration",
          ],
          // fieldProps: { hidden: true, },
          render: (types: any[]) => (
            <div>
              {types.map((type: any) => (
                <Tag key={type.select_option.name.clientText} color="blue">
                  {type.select_option.name.clientText}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: "thicknessOfSlab",
          displayName: "Thickness of slab",
          dataIndex: ["interior_penetration_permit", "thickness_of_slab"],
        },
        {
          key: "didPowderActuatesShots",
          displayName:
            "Powder actuated shots or similar penetration being used?",
          dataIndex: [
            "interior_penetration_permit",
            "did_powder_actuated_shots",
          ],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "maxLengthOfShots",
          displayName: "Max length of shots to be used",
          dataIndex: ["interior_penetration_permit", "max_length_of_shots"],
        },
        {
          key: "workerGeneralNotes",
          displayName: "Worker general notes",
          dataIndex: [
            "interior_penetration_permit",
            "worker_general_notes",
            "clientText",
          ],
        },
        {
          key: "gcGeneralNotes",
          displayName: "GC general notes",
          dataIndex: [
            "interior_penetration_permit",
            "gc_general_notes",
            "clientText",
          ],
        },
      ],
    },
    dig_excavation: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker.user.name)}
                  {worker.title.translation
                    ? `, ${worker.title.translation.clientText}`
                    : ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "workStartAt",
          displayName: "Work start at",
          dataIndex: ["work_start_at"],
          render: (d?: string) => (d ? dayjs(d).format("MMMM Do YYYY") : ""),
        },
        {
          key: "safetyMeetingDate",
          displayName: "Safety Meeting Date",
          dataIndex: ["dig_excavation_permit", "safety_meeting_date"],
          render: (d?: string) => (d ? dayjs(d).format("MMMM Do YYYY") : ""),
        },
        {
          key: "workLocation",
          displayName: "Location of Work",
          dataIndex: ["work_location", "clientText"],
        },
        {
          key: "workDescription",
          displayName: "Work Description",
          dataIndex: ["work_description", "clientText"],
        },
        {
          key: "utilityLocateMethods",
          displayName: "Methods used to locate utilities",
          dataIndex: ["dig_excavation_permit", "utilityLocateMethods"],
          render: (types: any[]) => {
            return (
              <div>
                {types.map((type: any) => (
                  <Tag key={type.select_option.name.clientText} color="blue">
                    {type.select_option.name.clientText}
                  </Tag>
                ))}
              </div>
            );
          },
        },
        {
          key: "utilityLocatesTickets",
          displayName: "Utility Locates Ticket",
          dataIndex: ["dig_excavation_permit", "did_utility_locate"],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "utilityIdentified",
          displayName: "Utility Identified",
          dataIndex: ["dig_excavation_permit", "did_utilities_identified"],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "utilityTypes",
          displayName: "Utilities identified in excavation area",
          dataIndex: ["dig_excavation_permit", "utilityTypes"],
          render: (types: any[]) => (
            <div>
              {types.map((type: any) => (
                <Tag key={type.select_option.name.clientText} color="blue">
                  {type.select_option.name.clientText}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: "didLocateProvidedByThirdParty",
          displayName: "Locates provided by third party",
          dataIndex: [
            "dig_excavation_permit",
            "did_locate_provided_by_third_party",
          ],
          render: (didWork: boolean) => (didWork ? "Yes" : "No"),
        },
        {
          key: "nameOfThirdPartyService",
          displayName: "Name of 3rd Party Service",
          dataIndex: ["dig_excavation_permit", "name_of_third_party"],
        },
        {
          key: "dateLocateProvided",
          displayName: "Date locate provided",
          dataIndex: ["dig_excavation_permit", "date_locate_provided"],
          render: (d?: string) => (d ? dayjs(d).format("MMMM Do YYYY") : ""),
        },
        {
          key: "workerGeneralNotes",
          displayName: "Worker general notes",
          dataIndex: [
            "dig_excavation_permit",
            "worker_general_notes",
            "clientText",
          ],
        },
        {
          key: "gcGeneralNotes",
          displayName: "GC general notes",
          dataIndex: [
            "dig_excavation_permit",
            "gc_general_notes",
            "clientText",
          ],
        },
      ],
    },
    loto: {
      "gridsection-work-details": [
        {
          key: "subcontractorName",
          displayName: "Subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          key: "permitApplicant",
          displayName: "Permit Applicant",
          dataIndex: ["applicant_project_worker"],
          render: (worker: any) => {
            return (
              <span style={{ lineHeight: "0.5rem" }}>
                <p>
                  {titleCase(worker?.user?.name)}
                  {`, ${worker?.title?.translation.clientText}` || ""}
                </p>
                {worker.hard_hat_worker && (
                  <p>Hard Hat# {worker?.hard_hat_number}</p>
                )}
              </span>
            );
          },
        },
        {
          key: "entryDateTime",
          displayName: "Date/Time of Entry",
          dataIndex: ["loto_permit", "created_at"],
          render: (d?: string) => (d ? dayjs(d).format("lll") : ""),
        },
        {
          key: "pointOfIsolation",
          displayName: "Point of Isolation",
          dataIndex: ["loto_permit", "work_location", "clientText"],
        },
        ...(lotoLockId
          ? [
              {
                displayName: "Lock name",
                key: "lockName",
                dataIndex: [
                  "loto_permit",
                  "loto_permit_lock_details",
                  `${lotoLockIndex}`,
                  "lock_name",
                  "clientText",
                ],
                render: (d?: string) =>
                  d ? (
                    <div>
                      {d}{" "}
                      <AntdButton
                        onClick={() =>
                          setSearchParams({
                            paneNumber: "2",
                            permitType: permitType,
                            reportId: permitId,
                          })
                        }
                        size="small"
                        type="primary"
                      >
                        Change
                      </AntdButton>
                    </div>
                  ) : (
                    ""
                  ),
              },
              {
                displayName: "Work Description",
                key: "description",
                dataIndex: ["work_description", "clientText"],
              },
              {
                displayName: "Energy Isolation Procedure",
                key: "energy",
                dataIndex: [
                  "loto_permit",
                  "loto_permit_lock_details",
                  `${lotoLockIndex}`,
                  "energy_isolation_procedure",
                  "clientText",
                ],
              },
              {
                key: "lockApplicant",
                displayName: "Lock Applicant",
                dataIndex: [
                  "loto_permit",
                  "loto_permit_lock_details",
                  `${lotoLockIndex}`,
                  "created_by_project_worker",
                ],
                render: (worker: any) => {
                  return (
                    <span style={{ lineHeight: "0.5rem" }}>
                      <p>
                        {titleCase(worker?.user?.name)}
                        {`, ${worker?.title?.translation.clientText}` || ""}
                      </p>
                      {worker.hard_hat_worker && (
                        <p>Hard Hat# {worker?.hard_hat_number}</p>
                      )}
                    </span>
                  );
                },
              },
              {
                displayName: "Lock Work Start time",
                key: "LockWorkStartTime",
                dataIndex: [
                  "loto_permit",
                  "loto_permit_lock_details",
                  `${lotoLockIndex}`,
                  "work_start_at",
                ],
                render: (d: string | null) =>
                  d ? dayjs(d).format("lll") : null,
              },
              {
                displayName: "Removed At",
                key: "removedAt",
                dataIndex: [
                  "loto_permit",
                  "loto_permit_lock_details",
                  `${lotoLockIndex}`,
                  "removed_at",
                ],
                render: (r: string | null) =>
                  r ? dayjs(r).format("lll") : null,
              },
              {
                displayName: "Removed By",
                key: "removedBy",
                dataIndex: [
                  "loto_permit",
                  "loto_permit_lock_details",
                  `${lotoLockIndex}`,
                  "removed_by_user",
                ],
                render: (user: {
                  name: string;
                  employee?: {
                    employee_title?: { name: { en: string } } | null;
                  } | null;
                  worker?: {
                    worker_title?: { translation: { en: string } } | null;
                  } | null;
                }) =>
                  user
                    ? `${user.name} ${
                        user.employee?.employee_title
                          ? `- ${user.employee.employee_title.name.en}`
                          : user.worker?.worker_title
                          ? `- ${user.worker.worker_title.translation.en}`
                          : ""
                      }`
                    : null,
              },
            ]
          : [
              {
                key: "lock_count",
                displayName: "Lock Count",
                dataIndex: [
                  "loto_permit",
                  "loto_permit_lock_details",
                  "length",
                ],
              },
            ]),
      ],
    },
    ceiling_access: {
      "gridsection-closeout-info": [
        {
          key: "closingNotes",
          dataIndex: "",
          displayName: "Closing Notes",
          render: (_: null, permit: any) =>
            permit.status === "closed"
              ? permit.permit_histories[1]?.comment?.en
              : permit.permit_histories[0]?.comment?.en,
        },
        ...(permit.status === "closed"
          ? [
              {
                key: "reviewNotes",
                dataIndex: "",
                displayName: "Review Notes",
                render: (_: null, permit: any) =>
                  permit.permit_histories[0]?.comment?.en,
              },
            ]
          : []),
      ],
      "gridsection-work-details": [
        {
          key: "workStartAt",
          displayName: "Work start at",
          dataIndex: ["work_start_at"],
          render: (d?: string) =>
            d ? dayjs(d).format("MMMM Do YYYY, hh:mm a") : "",
        },
        {
          key: "workDescription",
          displayName: "Reason for Ceiling Access",
          dataIndex: ["work_description", "en"],
        },
        {
          key: "workLocation",
          displayName: "Location of work (building, floor, area)",
          dataIndex: ["work_location", "clientText"],
        },
      ],
    },
  };

  const PageTitle: FC<{ status: string }> = ({ status }) => {
    const isReviewRequired =
      permit.permit_type === "ceiling_access" &&
      "ceiling_access_permit" in permit &&
      permit.status === "submitted" &&
      permit.ceiling_access_permit?.closing_review_status === "in_review";

    return (
      <span>
        <Title level={2}>
          {permit &&
          "confined_space_permit" in permit &&
          permit.confined_space_permit
            ? getTypeConfinedSpaceTitle(permit.confined_space_permit.type) +
              "Confined Space"
            : permitType === "dig_excavation"
            ? "Excavation Penetration"
            : permitType === "hot_work"
            ? hotWorkPermitName === "burn"
              ? "Burn"
              : "Hot Work"
            : permitType === "steel_erection"
            ? "Steel Erection Request"
            : titleCase(permitType, "_")}{" "}
          Permit
        </Title>
        <Text type="secondary" strong>
          Current Status:{" "}
          {isReviewRequired ? "Review Required" : capitalize(status)}
        </Text>
      </span>
    );
  };
  const signInImages = lotoLock
    ? lotoLock.sign_in_images || []
    : (permit.permit_sign_in_images || []).map((p) => p.image) || [];
  const surface_penetration_layout_images =
    "interior_penetration_permit" in permit &&
    permit.interior_penetration_permit
      ? permit.interior_penetration_permit.surface_penetration_layout_images
      : "dig_excavation_permit" in permit && permit.dig_excavation_permit
      ? permit.dig_excavation_permit.surface_penetration_layout_images
      : [];
  const typePermit = `${permitType}_permit` as const;

  const images = (lotoLock ? lotoLock : permit).images || [];

  const closingImages =
    permit.permit_type === "ceiling_access" && permit.status === "closed"
      ? permit.permit_histories[1].images
      : permit.permit_histories[0].images;

  const closingReviewImages =
    permit.permit_type === "ceiling_access" && permit.status === "closed"
      ? permit.permit_histories[0].images
      : null;

  // Side panel pane navigators
  const panes: PaneProps[] = [
    {
      icon: <UserOutlined />,
      title: " Project Info",
      content: [
        <GridFieldSection
          key="gridsection-submission-details"
          title="Submission Details"
          skipKeys={
            permit.status === "submitted" || permit.status === "resubmitted"
              ? ["approvedBy", "approvedOn", "closedBy", "closedOn"]
              : permit.status === "closed"
              ? ["approvedBy", "approvedOn"]
              : ["closedBy", "closedOn"]
          }
          dataSource={permit || {}}
          fields={fieldsByPermitType.shared["gridsection-submission-details"]}
        />,
        <GridFieldSection
          key="gridsection-project-info"
          title="Project Info"
          dataSource={permit || {}}
          fields={fieldsByPermitType.shared["gridsection-project-info"]}
        />,
      ],
    },
    {
      icon: <FolderOpenOutlined />,
      title: " Work Details",
      content: [
        ...(permitType === "loto" && !lotoLockId && "loto_permit" in permit
          ? [
              <BaseTable
                key="locksTable"
                pagination={false}
                title={() => <>Locks</>}
                onRow={(row) => ({
                  onClick: () => {
                    setSearchParams({
                      lotoLockId: row.id,
                      paneNumber: "2",
                      permitType: permitType,
                      reportId: permitId,
                    });
                  },
                })}
                columns={[
                  {
                    title: "Name",
                    key: "lock",
                    dataIndex: ["lock_name", "en"],
                  },
                  {
                    title: "Description",
                    key: "description",
                    dataIndex: ["work_description", "en"],
                  },
                  {
                    title: "Removed",
                    key: "removed",
                    dataIndex: ["removed_at", ""],
                    render: (r) => (r ? dayjs(r).format("lll") : null),
                  },
                ]}
                dataSource={permit.loto_permit?.loto_permit_lock_details}
              />,
            ]
          : []),
        <GridFieldSection
          key="gridsection-work-details"
          title="Work Info"
          dataSource={permit || {}}
          fields={fieldsByPermitType[permitType]["gridsection-work-details"]}
        />,
        permitType === "confined_space" &&
          "confined_space_permit" in permit && (
            <Section key="confinedSpaceTables" justify="center">
              <MonitoringEquipmentTable
                caption="Monitoring Equipment"
                dataSource={
                  permit.confined_space_permit?.monitoring_equipment || []
                }
              />
              <EquipmentReadingsTable
                caption="Readings"
                dataSource={
                  permit.confined_space_permit
                    ?.confined_space_permit_readings || []
                }
              />
            </Section>
          ),
        permitType === "dig" && "dig_permit" in permit && (
          <Section title="Dig Tickets" key="digTicketsSection" justify="center">
            <Col span={24}>
              <DigTicket
                tickets={permit.dig_permit?.dig_permit_tickets || []}
              />
            </Col>
          </Section>
        ),
        permitType === "dig_excavation" &&
          "dig_excavation_permit" in permit &&
          permit.dig_excavation_permit &&
          permit.dig_excavation_permit.permit_tickets.length > 0 && (
            <Section
              title="Utility Locate Ticket"
              key="utilityLocateTicketsSection"
              justify="center"
            >
              <Col span={24}>
                <DigTicket
                  tickets={permit.dig_excavation_permit.permit_tickets}
                />
              </Col>
            </Section>
          ),

        images.length ? (
          <Section key="images">
            <PermitImages dataSource={images} imageTitle="" />
          </Section>
        ) : null,
        surface_penetration_layout_images.length ? (
          <Section key="layoutImages">
            <PermitImages
              dataSource={surface_penetration_layout_images}
              imageTitle="Layout "
            />
          </Section>
        ) : null,
        <Section
          key="signInImages"
          title={
            permitType !== "loto" || lotoLockId || signInImages.length
              ? "Workers"
              : ""
          }
          justify="center"
        >
          <PermitImages dataSource={signInImages} imageTitle="Sign-In " />
        </Section>,
        (permitType !== "loto" || lotoLockId) && (
          <Section key="supervisorsSection" justify="center">
            <SupervisorsTable data={permit.supervisors} />
          </Section>
        ),
        <Section key="workersSection" justify="center">
          {"attendantWorkers" in permit && (
            <WorkersTable
              key="attandatWorkers"
              caption="Attendant Workers"
              data={data && permit.attendantWorkers}
            />
          )}
          {"entrantWorkers" in permit && (
            <WorkersTable
              key="entrantWorkers"
              caption="Entrant Workers"
              data={data && permit.entrantWorkers}
            />
          )}
          {permitType === "hot_work" &&
            "hot_work_permit" in permit && [
              permit.hot_work_permit?.fire_watch_name ? (
                <Col span={24} key="hotWorkFireWatchWorker">
                  <Text>{"Fire-Watch Workers"}</Text>
                  <BaseTable<{ id: string; name: string }>
                    pagination={false}
                    columns={[
                      {
                        title: "Name",
                        key: "fireWatchName",
                        dataIndex: ["name"],
                      },
                    ]}
                    dataSource={[
                      {
                        id: `1`,
                        name: permit.hot_work_permit.fire_watch_name,
                      },
                    ]}
                  />
                </Col>
              ) : null,
            ]}
          {"firewatchWorkers" in permit && !!permit.firewatchWorkers.length && (
            <WorkersTable
              key="firewatchWorkers"
              caption="Fire-Watch Workers"
              data={data && permit.firewatchWorkers}
            />
          )}
          {"operators" in permit && (
            <WorkersTable
              key="operator"
              caption="Operators"
              data={data && permit.operators}
            />
          )}
          {"safetyManagers" in permit && (
            <WorkersTable
              key="safety_manager"
              caption="Safety Managers"
              data={data && permit.safetyManagers}
            />
          )}
          {"workers" in permit &&
            ((permitType !== "ladder" && permitType !== "loto") ||
              (permitType === "loto" && lotoLockId) ||
              (permitType === "ladder" &&
                "ladder_permit" in permit &&
                !permit.ladder_permit?.is_access_ladder)) && (
              <WorkersTable
                key="workers"
                caption="Workers"
                data={data && permit.workers}
              />
            )}
        </Section>,
      ],
    },

    ...(("ceiling_access_permit" in permit &&
      permit.ceiling_access_permit?.closing_review_status === "in_review") ||
    permit.status === "closed"
      ? [
          {
            icon: <FolderOpenOutlined />,
            title: " Closeout Info",
            content: [
              <GridFieldSection
                key="gridsection-closeout-info"
                title="Closeout Info"
                dataSource={permit || {}}
                fields={
                  permitType === "ceiling_access"
                    ? fieldsByPermitType.ceiling_access[
                        "gridsection-closeout-info"
                      ]
                    : fieldsByPermitType.shared["gridsection-closeout-info"]
                }
              />,

              closingImages.length > 0 ? (
                <Section key="closingImages">
                  <PermitImages
                    dataSource={closingImages}
                    imageTitle="Closing"
                  />
                </Section>
              ) : null,

              closingReviewImages && closingReviewImages.length > 0 ? (
                <Section key="closingReviewImages">
                  <PermitImages
                    dataSource={closingReviewImages}
                    imageTitle="Closing Review"
                  />
                </Section>
              ) : null,

              permit.closing_checklist_items.length > 0 && (
                <Section
                  key="permitClosingChecklist"
                  title="Closing Checklists"
                >
                  <ChecklistTable
                    title="Closing Checklist Items"
                    data={permit.closing_checklist_items}
                  />
                </Section>
              ),
            ],
          },
        ]
      : []),

    {
      icon: <ProfileOutlined />,
      title: " Permit Checklist",
      content: [
        <Section key="permitChecklistSection" title="Permit Checklist">
          <ChecklistTable
            title="Checklist Items"
            data={
              permitType === "ladder" && "ladder_permit" in permit
                ? permit.ladder_permit?.is_access_ladder
                  ? permit.permit_checklist_items.filter(
                      (e: any) => e.permit_type === "ladder_access",
                    )
                  : permit.permit_checklist_items.filter(
                      (e: any) => e.permit_type === "ladder_non_access",
                    )
                : permit.permit_checklist_items
            }
          />
        </Section>,
        permit.closing_checklist_items.length > 0 && (
          <Section
            key="permitClosingChecklistSection"
            title="Closing Checklists"
          >
            <ChecklistTable
              title="Closing Checklist Items"
              data={permit.closing_checklist_items}
            />
          </Section>
        ),
      ],
    },
  ];

  if (permitType === "ladder") {
    const now = dayjs();
    if (
      "ladder_permit" in permit &&
      permit.project.project_setting?.require_ladder_inspection_daily &&
      ["inspected", "submitted_and_inspected"].includes(permit.status) &&
      !permit.ladder_permit?.is_access_ladder &&
      dayjs(permit.ladder_permit?.last_inspection_at).endOf("d").format() >
        now.format()
    ) {
      panes.push({
        icon: <ProfileOutlined />,
        title: " Inspection",
        content: [
          <GridFieldSection
            key="gridsection-inspection-details"
            title="Inspection"
            dataSource={permit || {}}
            fields={
              fieldsByPermitType[permitType]["gridsection-inspection-details"]
            }
          />,
          <Section key="permitChecklistSection" title="Permit Checklist">
            <ChecklistTable
              title="Checklist Items"
              data={permit.permit_checklist_items.filter(
                (e: any) => e.permit_type === "ladder_non_access",
              )} // already checked if it's not access and is a ladder permit
            />
          </Section>,
        ],
      });
    }
  }

  if (!permit.is_historical) {
    panes.push({
      icon: <HistoryOutlined />,
      title: " Permit History",
      content: [
        <PermitTable
          title={"History"}
          excludedKeys={["subcontractor", "type", "project"]}
          hotWorkPermitName={hotWorkPermitName}
          where={{
            origin_permit_history: {
              permit_id: {
                _eq: permitId,
              },
            },
          }}
          onRowClick={(row: { pk: string }) => {
            const previous = location.pathname.split("/");
            previous.pop();
            navigate(
              `${previous.join(
                "/",
              )}/permits?permitType=${permitType}&reportId=${
                row.pk
              }&prevPermitId=${permitId}`,
            );
          }}
          extraColumns={
            permit.permit_type === "ladder" &&
            "ladder_permit" in permit &&
            !!projectSettingData?.project_setting_by_pk &&
            !projectSettingData?.project_setting_by_pk?.[
              permit.ladder_permit?.is_access_ladder
                ? "worker_can_add_access_ladder_number"
                : "worker_can_add_working_ladder_number"
            ]
              ? [
                  {
                    title: "Ladder #",
                    dataIndex: ["ladder_permit", "ladder_identification"],
                    key: "ladder_number",
                  },
                  {
                    title: "Returned",
                    dataIndex: ["ladder_permit", "returned_at"],
                    key: "returned_at",
                    render: (_, row) => {
                      return row.ladder_permit?.returned_at
                        ? ` Marked on ${dayjs(
                            row.ladder_permit?.returned_at,
                          ).format("MMM D, YYYY hh:mm a z")} by ${
                            row.ladder_permit?.returned_marked_by_user?.name ||
                            ""
                          }`
                        : "";
                    },
                  },
                ]
              : undefined
          }
        />,
      ],
    });
  }

  let status = permit.status;
  if (
    permit.permit_type === "confined_space" &&
    "confined_space_permit" in permit &&
    permit.confined_space_permit
  ) {
    const readings =
      permit.confined_space_permit.confined_space_permit_readings;

    const showStopWork = readings.find(
      (r) =>
        new Date(r.created_at).getTime() >
          new Date(permit.permit_histories[0].created_at).getTime() &&
        getNotPermissibleReadingsCount(r) > 0,
    );

    if (showStopWork) status = "Stop Work";
  }
  return (
    <Card
      style={{ width: "100%", minWidth: 850 }}
      extra={
        <div className="flex">
          <Button
            onClick={() => setIsModalVisible(!isModalVisible)}
            label={"Email Permit"}
            icon={IconMail}
            loading={loadingDownload}
          ></Button>
          <div className="ml-1">
            <Button
              onClick={async () => {
                setLoadingDownload(true);
                await downloadPermitPdf({
                  variables: {
                    input: {
                      permitId: permitId,
                    },
                  },
                })
                  .then((d) => {
                    if (!d.data?.generatePermitPdf)
                      throw new Error("PermitPdf url is missing");
                    downloadFromUrl(d.data.generatePermitPdf);
                  })
                  .catch((error) => {
                    console.error(error);
                    setLoadingDownload(false);
                    return Modal.warn({
                      content: (
                        <p>
                          Something went wrong. Could not download permit at
                          this time.
                        </p>
                      ),
                    });
                  });
                setLoadingDownload(false);
              }}
              loading={loadingDownload}
              label={"Download Permit"}
              icon={IconDownload}
            ></Button>
          </div>
        </div>
      }
      title={<PageTitle status={status} />}
      className="PermitDetailsView"
    >
      <DetailSectionPanes panes={panes} initialKey={paneKey} />
      <Modal
        title={<Title level={3}>Send Permit PDF</Title>}
        open={isModalVisible}
        okText="Send"
        onOk={handleSendPermit}
        onCancel={() => setIsModalVisible(false)}
      >
        <div>
          <p>Enter your email address to send the permit as a PDF.</p>
          <Input
            type="email"
            placeholder="Email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.currentTarget.value)}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default withUrlParams(GCProjectReportsPermitsDetail, [
  "projectId",
  "permitType",
  "permitId",
]);
