import { CompareFn } from "antd/es/table/interface";
import { SorterData } from "../../ScrollTable";
import { DataRecord } from "../dataScrollTableTypes";
import getFieldValue from "../getFieldValue";

function compareValues(a: any, b: any) {
  return a < b ? -1 : a > b ? 1 : 0;
}
const arraySortData = (
  data: Array<DataRecord>,
  sorter: SorterData<DataRecord>,
): Array<DataRecord> => {
  const compareFn =
    sorter.clientCompareFn ??
    ((a, b) => {
      const valueA = getFieldValue(a, sorter.dataIndex);
      const valueB = getFieldValue(b, sorter.dataIndex);
      return compareValues(!!valueA, !!valueB) || compareValues(valueA, valueB);
    });
  const comparator: CompareFn<DataRecord> = sorter.order === "desc" ? (a, b) => compareFn(b, a) : compareFn;
  const res = [...data].sort(comparator);
  return res;
};
export default arraySortData;
