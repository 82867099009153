import { isApolloError } from "apollo-boost";
import { RRNLError, RRNLRequestError } from "react-relay-network-modern";


const isNetworkError = (error: unknown) => {
  if (error instanceof Error && isApolloError(error)) {
    if (error.graphQLErrors.length > 0) {
      return false;
    }
    return !!error.networkError;
  } else if (error instanceof RRNLError) {
    // error instanceof RRNLRequestError
    // TODO: check this logic
    return error.name === "RRNLBatchMiddlewareError";
  }
}

export default isNetworkError;