import { useState } from "react";
import { Button, Drawer } from "antd";

import { SettingOutlined } from "@ant-design/icons";

interface GCAdminIncidentsSettingsButtonProps {}

const GCAdminIncidentsSettingsButton: React.FC<
  GCAdminIncidentsSettingsButtonProps
> = () => {
  return (
    <Button
      icon={<SettingOutlined />}
      onClick={() => {
        // TODO navigagte to proper incident setting page
      }}
    >
      Settings
    </Button>
  );
};

export default GCAdminIncidentsSettingsButton;
