/**
 * @generated SignedSource<<8faca50c3cbe25f207ff2b385047ae34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCCorporateOfficeOrientationsQuery$variables = {
  gcId: string;
};
export type GCCorporateOfficeOrientationsQuery$data = {
  readonly general_contractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly gc_projects: ReadonlyArray<{
          readonly project: {
            readonly name: string;
            readonly pk: string;
          };
        }>;
      };
    }>;
  };
  readonly orientation_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly project_id: string | null | undefined;
        readonly type: string;
        readonly user: {
          readonly employee: {
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly name: string;
        };
      };
    }>;
  };
};
export type GCCorporateOfficeOrientationsQuery = {
  response: GCCorporateOfficeOrientationsQuery$data;
  variables: GCCorporateOfficeOrientationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gcId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "gcId"
  }
],
v2 = [
  {
    "fields": [
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v14 = {
  "_is_null": true
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": [
      {
        "order": "asc"
      },
      {
        "name": "asc"
      }
    ]
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "deleted_at",
        "value": (v14/*: any*/)
      },
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "general_contractor_id"
      },
      {
        "kind": "Literal",
        "name": "project_id",
        "value": (v14/*: any*/)
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCorporateOfficeOrientationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_project",
                    "kind": "LinkedField",
                    "name": "gc_projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "orientation_connection",
        "args": null,
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "__GCCorporateOfficeOrientationsQuery_orientation_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCCorporateOfficeOrientationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_project",
                    "kind": "LinkedField",
                    "name": "gc_projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "GCCorporateOfficeOrientationsQuery_orientation_connection",
        "kind": "LinkedHandle",
        "name": "orientation_connection"
      }
    ]
  },
  "params": {
    "cacheID": "2dd7c8df34df6d81924cf48f3a74e31d",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "orientation_connection"
          ]
        }
      ]
    },
    "name": "GCCorporateOfficeOrientationsQuery",
    "operationKind": "query",
    "text": "query GCCorporateOfficeOrientationsQuery(\n  $gcId: uuid!\n) {\n  general_contractor_connection(where: {id: {_eq: $gcId}}) {\n    edges {\n      node {\n        gc_projects {\n          project {\n            pk: id\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  orientation_connection(where: {deleted_at: {_is_null: true}, general_contractor_id: {_eq: $gcId}, project_id: {_is_null: true}}, order_by: [{order: asc}, {name: asc}], first: 10000) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n        type\n        created_at\n        project_id\n        user {\n          name\n          employee {\n            general_contractor {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d26b94792c4d19d655953cb2704de683";

export default node;
