import { useSuspenseQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GetProjectSummaryDocument,
  GetProjectSummaryQuery,
  GetProjectSummaryQueryVariables,
  useGenerateProjectSummaryPdfMutation,
  useInsertProjectSummaryMutation,
  useMarkDeleteProjectSummaryMutation,
  useUpdateProjectSummaryMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import GCProjectReportsDailyCollapse from "../../daily-reports/components/GCProjectReportsDailyCollapse";
import { useBoolean, useCopyToClipboard } from "usehooks-ts";
import * as uuid from "uuid";
import useAuthUser from "src/common/hooks/useAuthUser";
import JoditEditor from "jodit-react";
import { JoditConfig } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import { useState } from "react";
import { Button, DatePicker, Drawer, Form, message, Popover } from "antd";
import dayjs from "dayjs";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { FormInstance } from "antd/lib";
import clsx from "clsx";
import withCustomSuspense from "../../../../../common/components/general/withCustomSuspense";

type FormFields = {
  text: string;
  start_date: dayjs.Dayjs;
  end_date: dayjs.Dayjs;
};
const SummaryForm: React.FC<{
  initialValues?: FormFields;
  textBoxHeight?: number | string;
  onCancel: () => void;
  form: FormInstance<FormFields>;
  copyPrevious?: () => void;
  loading: boolean;
  onFinish: (vals: FormFields) => Promise<void>;
}> = ({
  initialValues,
  copyPrevious,
  onFinish,
  form,
  loading,
  onCancel,
  textBoxHeight,
}) => {
  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish}>
      <div className="flex flex-row gap-2">
        <Form.Item
          name={"start_date"}
          label="Start Date"
          rules={[{ required: true, message: "Select start date" }]}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item
          name={"end_date"}
          label="End Date"
          rules={[{ required: true, message: "Select end date" }]}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
      </div>
      <Form.Item
        name={"text"}
        rules={[{ required: true, message: "Enter summary" }]}
      >
        <JoditEditor
          value=""
          config={{ ...JoditConfig, height: textBoxHeight }}
        />
      </Form.Item>

      <div
        className={clsx(
          "flex flex-row",
          copyPrevious ? "justify-between" : "justify-end",
        )}
      >
        {copyPrevious && (
          <Button type="primary" onClick={copyPrevious} loading={loading}>
            Copy Previous
          </Button>
        )}
        <div className="flex flex-end gap-1">
          <BPopconfirm
            onConfirm={onCancel}
            title="Are you sure? any changes will be lost"
          >
            <Button loading={loading}>Cancel</Button>
          </BPopconfirm>

          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
};
export const ShowSummary: React.FC<{
  summary: GetProjectSummaryQuery["project_summary"][number];
  projectId: string;
  showCopyIcon?: boolean;
  onCopy?: () => void;
}> = ({ summary, projectId, showCopyIcon, onCopy }) => {
  const { setFalse: hide, value: expanded, setTrue: open } = useBoolean(false);
  const authUser = useAuthUser();
  const [form] = Form.useForm<FormFields>();
  const [downloadPdf, { loading: downloading }] =
    useGenerateProjectSummaryPdfMutation();
  const [editing, setEditing] = useState(false);
  const [updateEntry, { loading: updating }] =
    useUpdateProjectSummaryMutation();
  const [markDelete, { loading: deleting }] =
    useMarkDeleteProjectSummaryMutation();

  const onUpdate = async (vals: FormFields) => {
    const textChange = vals.text !== summary.text;
    const startChange =
      vals.start_date.format("YYYY-MM-DD") !== summary.start_date;
    const endChange = vals.end_date.format("YYYY-MM-DD") !== summary.end_date;
    if (!(textChange || startChange || endChange)) {
      setEditing(false);
      return;
    }
    const currTime = dayjs().toISOString();
    const newSummaryId = uuid.v4();
    const newEntry = {
      id: newSummaryId,
      project_id: projectId,
      summary_id: summary.summary_id,
      start_date: vals.start_date.format("YYYY-MM-DD"),
      end_date: vals.end_date.format("YYYY-MM-DD"),
      created_by_uid: authUser.uid,
      text: vals.text,
    };
    await updateEntry({
      variables: {
        oldEntryId: summary.id,
        _setOld: {
          archived_at: currTime,
        },
        newEntry: {
          project_id: projectId,
          summary_id: summary.summary_id,
          start_date: vals.start_date.format("YYYY-MM-DD"),
          end_date: vals.end_date.format("YYYY-MM-DD"),
          created_by_uid: authUser.uid,
          text: vals.text,
        },
      },
      optimisticResponse: {
        update_project_summary_by_pk: {
          __typename: "project_summary",
          id: summary.id,
          archived_at: currTime,
        },
        insert_project_summary_one: {
          __typename: "project_summary",
          ...newEntry,
          created_by_user: { __typename: "user", name: "You" },
        },
      },
      update: (cache, { data }) => {
        const updatedId = data?.update_project_summary_by_pk?.id;
        const newEntryRecieved = data?.insert_project_summary_one;
        if (!updatedId || !newEntryRecieved) throw new Error("Update Failed");
        cache.modify<GetProjectSummaryQuery>({
          fields: {
            project_summary: (currList = [], { readField, toReference }) => {
              const listToReturn = [...currList];
              const indexOfUpdatedField = listToReturn.findIndex(
                (c) => readField("id", c) === updatedId,
              );
              const newEntryRef = toReference(newEntryRecieved);
              if (indexOfUpdatedField >= 0 && newEntryRef) {
                listToReturn[indexOfUpdatedField] = newEntryRef;
              }
              return listToReturn;
            },
          },
        });
      },
    });
  };
  const onDelete = async () => {
    const currTime = dayjs().toISOString();
    await markDelete({
      variables: {
        entryId: summary.id,
        _set: {
          deleted_at: currTime,
          deleted_by_uid: authUser.uid,
        },
      },
      optimisticResponse: {
        update_project_summary_by_pk: {
          deleted_at: currTime,
          __typename: "project_summary",
          id: summary.id,
        },
      },
      update: (cache, { data }) => {
        const updatedId = data?.update_project_summary_by_pk?.id;
        if (!updatedId) throw new Error("Server return null");
        cache.modify<GetProjectSummaryQuery>({
          fields: {
            project_summary: (currList = [], { readField }) => {
              const listToReturn = [...currList];
              return listToReturn.filter(
                (c) => readField("id", c) !== updatedId,
              );
            },
          },
        });
      },
    });
  };
  return (
    <GCProjectReportsDailyCollapse
      expand={open}
      key={summary.id}
      expanded={expanded}
      head={
        <div className="flex justify-between w-full">
          <div>
            Date Range: {summary.start_date} To {summary.end_date}, Created/Last
            Edited By: {summary.created_by_user.name}
            <br />
            {!expanded && (
              <div
                className="jodit w-full max-h-1.5 text-ellipsis overflow-hidden"
                dangerouslySetInnerHTML={{ __html: summary.text }}
              />
            )}
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex justify-end gap-1"
          >
            {showCopyIcon && (
              <Button
                size="large"
                shape="circle"
                ghost
                icon={<CopyOutlined />}
                type="primary"
                onClick={() => {
                  onCopy && onCopy();
                }}
              />
            )}
            <Button
              size="large"
              shape="circle"
              ghost
              icon={<DownloadOutlined />}
              loading={downloading}
              type="primary"
              onClick={async () => {
                const { data } = await downloadPdf({
                  variables: { input: { projSummaryId: summary.id } },
                });
                if (data) {
                  downloadFromUrl(data.generateProjectSummaryPdf);
                }
              }}
            />
            <BPopconfirm
              okText="Delete"
              okType="danger"
              onConfirm={onDelete}
              title="Are you sure you want to delete this Summary? This action is irreversible"
            >
              <Button
                danger
                icon={<DeleteOutlined />}
                shape="circle"
                size="large"
              ></Button>
            </BPopconfirm>
          </div>
        </div>
      }
    >
      {editing ? (
        <SummaryForm
          form={form}
          initialValues={{
            text: summary.text,
            start_date: dayjs(summary.start_date, "YYYY-MM-DD"),
            end_date: dayjs(summary.end_date, "YYYY-MM-DD"),
          }}
          loading={updating}
          onCancel={() => setEditing(false)}
          onFinish={onUpdate}
        />
      ) : (
        <div>
          <div
            className="jodit w-full"
            dangerouslySetInnerHTML={{ __html: summary.text }}
          />
          <div className="flex justify-end gap-1">
            <Button onClick={hide}>Close Preview</Button>
            <Button
              type="primary"
              onClick={() => setEditing(true)}
              loading={updating || deleting}
            >
              Edit
            </Button>
          </div>
        </div>
      )}
    </GCProjectReportsDailyCollapse>
  );
};
const GCProjectSummaryList: React.FC = () => {
  const { projectId } = useParams();
  const [createNew, setCreateNew] = useState(false);
  const authUser = useAuthUser();
  const [copiedText, copyFn] = useCopyToClipboard();

  if (!projectId) throw new Error("ProjectId not found");
  const { data } = useSuspenseQuery<
    GetProjectSummaryQuery,
    GetProjectSummaryQueryVariables
  >(GetProjectSummaryDocument, {
    variables: {
      where: {
        archived_at: { _is_null: true },
        deleted_at: { _is_null: true },
        project_id: { _eq: projectId },
      },
    },
  });
  const summaries = [...data.project_summary].sort(
    (a, b) =>
      new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
  );
  const [form] = Form.useForm<FormFields>();
  const [insertSummary, { loading: inserting }] =
    useInsertProjectSummaryMutation();
  const onCreate = async (vals: FormFields) => {
    console.log(
      vals.start_date.format("YYYY-MM-DD"),
      vals.end_date.format("YYYY-MM-DD"),
    );
    const newSummaryId = uuid.v4();
    const newEntry = {
      id: newSummaryId,
      project_id: projectId,
      summary_id: uuid.v4(),
      start_date: vals.start_date.format("YYYY-MM-DD"),
      end_date: vals.end_date.format("YYYY-MM-DD"),
      created_by_uid: authUser.uid,
      text: vals.text,
    };
    await insertSummary({
      variables: { object: newEntry },
      optimisticResponse: {
        insert_project_summary_one: {
          __typename: "project_summary",
          ...newEntry,
          created_by_user: { __typename: "user", name: "You" },
        },
      },
      update: (cache, { data }) => {
        const newEntryRecieved = data?.insert_project_summary_one;
        if (!newEntryRecieved) throw new Error("Insert Failed");
        cache.modify<GetProjectSummaryQuery>({
          fields: {
            project_summary: (currList = [], { toReference }) => {
              const newEntryRef = toReference(newEntryRecieved);
              if (newEntryRef) {
                return [newEntryRef, ...currList];
              }
              return currList;
            },
          },
        });
      },
    });
    form.resetFields();
    setCreateNew(false);
  };
  return (
    <div className="flex flex-col w-full gap-0">
      <Drawer
        open={createNew}
        width={"70%"}
        title="Add New Summary"
        onClose={() => setCreateNew(false)}
      >
        <SummaryForm
          form={form}
          onCancel={() => setCreateNew(false)}
          copyPrevious={
            !summaries[0]
              ? undefined
              : () => {
                  if (!form.getFieldValue("text")?.trim()) {
                    form.setFieldValue("text", summaries[0].text);
                  } else {
                    copyFn(summaries[0].text);
                    message.success("Previous Entry Copied to Clipboard");
                  }
                }
          }
          loading={inserting}
          textBoxHeight={550}
          onFinish={onCreate}
          initialValues={
            summaries[0]
              ? {
                  text: "",
                  start_date: dayjs(summaries[0].end_date, "YYYY-MM-DD"),
                  end_date:
                    new Date(summaries[0].end_date).getTime() >
                    new Date().getTime()
                      ? dayjs(summaries[0].end_date, "YYYY-MM-DD")
                      : dayjs(),
                }
              : undefined
          }
        />
      </Drawer>
      <div className="w-full flex justify-center items-center text-1.25 gap-1 mb-1">
        Project Summaries{" "}
        <Popover title="Add new summary report">
          <Button
            icon={<PlusOutlined />}
            shape="circle"
            type="primary"
            onClick={() => setCreateNew(true)}
          />
        </Popover>
      </div>
      <div className={`flex flex-col gap-0.5`}>
        {summaries.map((summary) => (
          <ShowSummary
            summary={summary}
            key={summary.id}
            projectId={projectId}
          />
        ))}
      </div>
    </div>
  );
};
export default withCustomSuspense(GCProjectSummaryList);
