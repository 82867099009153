import React from "react";
import ObservationDetail from "../../components/ObservationDetail";
import { useOutlet, useParams, useNavigate } from "react-router-dom";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import useGcEmployee from "../../../../root/routes/views/general-contractor/hierarchy/organization-unit/utils/useGcEmployee";

const GCObservation: React.FunctionComponent<ProjectIdProps> = (props) => {
  const { observationId } = useParams();
  if (!observationId) throw new Error("Observation is not found");
  const employee = useGcEmployee();
  return (
    <ObservationDetail
      observationId={observationId}
      projectId={props.projectId}
      gcId={employee.general_contractor.id}
    />
  );
};
export default withProjectIdUrlParam(GCObservation);
