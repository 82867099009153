import React from "react";

interface GCProjectOrientationLinkedMessageProps {
  linkedProjectId: string;
}

const GCProjectOrientationLinkedMessage: React.FC<
  GCProjectOrientationLinkedMessageProps
> = (props) => {
  return (
    <div className="flex flex-col gap-1 mb-1">
      <div className="text-2">Orientation Settings</div>
      <div className="mb-1">
        This project's orientation is linked to another project. To change or
        update Orientation Settings follow this{" "}
        <a
          className="text-interactive-primary font-accent"
          href={`${document.location.origin}/gce/hierarchy/project/${props.linkedProjectId}/orientation/settings`}
        >
          link
        </a>{" "}
        to access the other project. If you do not have access to the Linked
        Project, see your project SiteForm Champion for help.
      </div>
    </div>
  );
};

export default GCProjectOrientationLinkedMessage;
