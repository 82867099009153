/**
 * @generated SignedSource<<6dfda1914e3139ea178b618c14afb852>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { email_general_contractor_user_bool_exp, email_general_contractor_user_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation$variables = {
  objects: ReadonlyArray<email_general_contractor_user_insert_input>;
  where: email_general_contractor_user_bool_exp;
};
export type GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation$data = {
  readonly delete_email_general_contractor_user: {
    readonly returning: ReadonlyArray<{
      readonly general_contractor_id: string;
      readonly id: string;
      readonly type: string;
      readonly user_id: string;
    }>;
  } | null | undefined;
  readonly insert_email_general_contractor_user: {
    readonly returning: ReadonlyArray<{
      readonly general_contractor_id: string;
      readonly id: string;
      readonly pk: string;
      readonly type: string;
      readonly user_id: string;
    }>;
  } | null | undefined;
};
export type GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation = {
  response: GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation$data;
  variables: GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "where"
    }
  ],
  "concreteType": "email_general_contractor_user_mutation_response",
  "kind": "LinkedField",
  "name": "delete_email_general_contractor_user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "email_general_contractor_user",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "email_general_contractor_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_general_contractor_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_general_contractor_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "email_general_contractor_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_general_contractor_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_general_contractor_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a80eb1142d17e185aad4ea7740f64e50",
    "id": null,
    "metadata": {},
    "name": "GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCCompleteCorporateEmailSelectModal_email_general_contractor_user_Mutation(\n  $objects: [email_general_contractor_user_insert_input!]!\n  $where: email_general_contractor_user_bool_exp!\n) {\n  insert_email_general_contractor_user(objects: $objects) {\n    returning {\n      id\n      pk: id\n      general_contractor_id\n      user_id\n      type\n    }\n  }\n  delete_email_general_contractor_user(where: $where) {\n    returning {\n      general_contractor_id\n      user_id\n      type\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "50107835372976000916c3725147b382";

export default node;
