import { useLocation, useParams } from "react-router-dom";
import {
  GcHierarchyTreeNodeType,
  GCOrganizationUnitType,
} from "../gcHierarchyTreeTypes";
import { getOrganizationUnitType } from "./withOrganizationUnitWrapper";

const useGetNodeIdAndType = () => {
  // Define the selected node by the URL
  const params = useParams();
  const location = useLocation();
  let nodeId: string | null = null;
  let organizationUnitType: GCOrganizationUnitType | undefined = undefined;
  let nodeType: GcHierarchyTreeNodeType | null = null;
  if (
    location.pathname.startsWith(`/gce/hierarchy/project/${params.projectId}`)
  ) {
    nodeId = params.projectId || null;
    nodeType = "project";
  } else if (location.pathname.startsWith(`/gce/hierarchy/organization-unit`)) {
    nodeId = params.organizationUnitId || null;
    nodeType = "organization_unit";
    organizationUnitType = getOrganizationUnitType(params.organizationUnitType);
  } else if (location.pathname.startsWith(`/gce/hierarchy/corporate-office`)) {
    nodeType = "corporate_office";
    nodeId = "corporate_office";
  }
  return { nodeId, nodeType, organizationUnitType };
};

export default useGetNodeIdAndType;
