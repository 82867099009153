import React, { FC, useState } from "react";

import { Button, Modal, Space, Typography } from "antd";
import {
  isDevelopmentBuild,
  isDevelopmentEnvironment,
} from "../constants/environment";
import {
  PROCORE_CLIENT_ID_DEV,
  PROCORE_CLIENT_ID_PROD,
  PROCORE_DMSA_CLIENT_ID_DEV,
  PROCORE_DMSA_CLIENT_ID_PROD,
  PROCORE_SERVER_DEV,
  PROCORE_SERVER_PROD,
} from "../constants/procore";
import getGcCorporateOfficePath from "../../root/routes/views/general-contractor/hierarchy/corporate-office/utils/getGcCorporateOfficePath";

//DO NOT MODIFY THESE URLs, thay are used in procore config.
export const procoreCompanyRedirectUrl = `${document.location.origin}/gce/hierarchy/corporate-office/settings/corporate-settings`;
export const procoreProjectRedirectUrl = `${document.location.origin}/gce/procore-project-setup`;

const getProcoreAuthUrl = (sandbox: boolean, dmsa: boolean, projectId?: string) => {
  const redirect_uri = projectId
    ? procoreProjectRedirectUrl
    : procoreCompanyRedirectUrl;
  
  const state: {projectId?: string, sbx?: number, dmsa?: number} = {};
  if (projectId)
    state.projectId = projectId;
  if (sandbox)
    state.sbx = 1;
  if (dmsa)
    state.dmsa = 1;

  const client_id = dmsa ?
    (sandbox ? PROCORE_DMSA_CLIENT_ID_DEV : PROCORE_DMSA_CLIENT_ID_PROD) :
    (sandbox ? PROCORE_CLIENT_ID_DEV      : PROCORE_CLIENT_ID_PROD);

  const params = new URLSearchParams({
    response_type: 'code',
    client_id,
    redirect_uri,
    state: JSON.stringify(state)
  });

  const server = sandbox ? PROCORE_SERVER_DEV : PROCORE_SERVER_PROD;  
  return `https://${server}/oauth/authorize?${params.toString()}`;
};

interface ConnectButtonProps {
  title: string;
  projectId?: string;
  dmsa: boolean;
}

export const ConnectAsUserButtonProd: FC<ConnectButtonProps> = ({
  title,
  projectId,
  dmsa
}) => {
  return (
    <Button
      onClick={() => {
        document.location.href = getProcoreAuthUrl(false, dmsa, projectId);
      }}
    >
      {title}
    </Button>
  );
};
export const ConnectAsUserButtonDev: FC<ConnectButtonProps> = ({
  title,
  projectId,
  dmsa
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const onProduction = () => {
    document.location.href = getProcoreAuthUrl(false, dmsa, projectId);
  };
  const onSandbox = () => {
    document.location.href = getProcoreAuthUrl(true, dmsa, projectId);
  };

  return (
    <>
      <Modal
        onCancel={() => {
          setModalVisible(false);
        }}
        title="Select Environment"
        open={modalVisible}
        footer={() => (
          <Space>
            <Button onClick={onProduction}>Production</Button>
            <Button onClick={onSandbox}>Sandbox</Button>
          </Space>
        )}
      >
        <Typography.Paragraph>
          Do you want to use production or sandbox account?
        </Typography.Paragraph>
      </Modal>

      <Button
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {title}
      </Button>
    </>
  );
};

const ConnectAsUserButton: FC<ConnectButtonProps> = (props) => {
  const show_sandbox =
    isDevelopmentEnvironment ||
    isDevelopmentBuild ||
    document.location.host === "siteform-web-dev.firebaseapp.com" ||
    document.location.host === "siteform-web-dev.web.app" ||
    document.location.host === "localhost";
  return show_sandbox ? (
    <ConnectAsUserButtonDev {...props} />
  ) : (
    <ConnectAsUserButtonProd {...props} />
  );
};

export default ConnectAsUserButton;
