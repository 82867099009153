import { JSONObject } from "src/common/types/manual/JsonType";
import { SearchData } from "../../ScrollTable";
import buildFieldRef from "./buildFieldRef";

function buildSerchPattern(value: string | null): string {
  if (value === null) // what does null mean?/ why we may want to use it?
    return '%';
  return `%${value.replace(/[\W_]+/g, "%")}%`;
}

const buildGraphQLSearchFilter = (
  searchFilter?: SearchData,
  extraSearchDataIndexes?: Array<
    Array<{ index: string; extraConstraint?: JSONObject }>
  >,
) => {
  if (!searchFilter) return {};

  const searchList = searchFilter.fields.map(({ dataIndex }) =>
    buildFieldRef(dataIndex, {
      _ilike: buildSerchPattern(searchFilter.searchValue),
    }),
  );
  if (extraSearchDataIndexes) {
    extraSearchDataIndexes.forEach((searchDataIndexes) => {
      let result: JSONObject = {
        _ilike: buildSerchPattern(searchFilter.searchValue),
      };
      for (let i = searchDataIndexes.length - 1; i >= 0; i--) {
        const item = searchDataIndexes[i];
        const index = item.index;
        result = {
          [index]: {
            ...result,
            ...(item.extraConstraint ? item.extraConstraint : {}),
          },
        };
      }

      searchList.push(result);
    });
  }
  return { _or: searchList };
};
export default buildGraphQLSearchFilter;
