import * as React from "react";
import CustomTabsBase from "./CustomTabsBase";
import { TabsProps } from "antd";

/* Typical Usage:
  ...
  const { subview } = useParams();
  ...
  const onTabSelect = (tabId: string) => {
    navigate("../" + tabId);
  };
  ...
  <CustomTabs
    onTabSelect={onTabSelect}
    tabs={tabs}
    selectedTabId={subview}
  />
  ...
 */

type CustomTabType = {
  id: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  content?: React.ReactNode;
};

export interface CustomTabsProps {
  tabs: CustomTabType[];
  selectedTabId?: string;
  onTabSelect: (id: string) => void;
  tabBarExtraContent?: TabsProps["tabBarExtraContent"];
}

const CustomTabs: React.FunctionComponent<CustomTabsProps> = (props) => {
  const onTabChange = (key: string) => {
    props.onTabSelect(key);
  };

  return (
    <CustomTabsBase
      onChange={onTabChange}
      tabBarExtraContent={props.tabBarExtraContent}
      activeKey={props.selectedTabId}
      items={props.tabs.map((tab) => ({
        key: tab.id,
        label: tab.label,
        children: tab.content,
        icon: tab.icon,
      }))}
    />
  );
};

export default CustomTabs;
