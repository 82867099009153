import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useUpdateGcDivisionEmployeesMutation } from "src/common/types/generated/apollo/graphQLTypes";
import GCHierarchyTeam from "../../../../components/hierarchy-node-users/GCHierarchyTeam";
import useAuthUser from "src/common/hooks/useAuthUser";
import { DivisionProps } from "../../../../utils/withDivisionId";
import GCEmployeesCommonProps from "../../../../utils/GCEmployeesCommonProps";
import getOrgEmployeeWhere from "../../../../utils/getOrgEmployeeWhere";
import useGcEmployee from "../../../utils/useGcEmployee";

interface GCDivisionEmployeesProps
  extends DivisionProps,
    GCEmployeesCommonProps {}

const GCDivisionEmployees: React.FC<GCDivisionEmployeesProps> = ({
  divisionId,
  hierarchyNodeManagersOnly,
}) => {
  const employee = useGcEmployee();
  const [updateDivisionEmp] = useUpdateGcDivisionEmployeesMutation();
  const authUser = useAuthUser();

  return (
    <GCHierarchyTeam
      {...{
        getRemainingGcTeamVariables: {
          projectsToInviteWhere: {
            gc_division_id: { _eq: divisionId },
          },
          gcEmployeeWhere: {
            general_contractor_id: { _eq: employee.general_contractor.id },
            ...(hierarchyNodeManagersOnly
              ? {
                  gc_divisions: { gc_division_id: { _eq: divisionId } },
                }
              : getOrgEmployeeWhere("division", divisionId)),
          },
        },
        title: "Division",
        onInviteSuccess: async (userIds) => {
          await updateDivisionEmp({
            variables: {
              deleteProjEmpWhere: { id: { _is_null: true } },
              //IF added to higher level remove from lower level
              deleteBuEmpWhere: {
                gc_business_unit: { gc_division_id: { _eq: divisionId } },
                user_id: { _in: userIds },
              },
              deleteOfficeEmpWhere: {
                gc_office: { gc_division_id: { _eq: divisionId } },
                user_id: { _in: userIds },
              },
              deleteDivEmpWhere: { id: { _is_null: true } },
              insertDivObjs: userIds.map((u) => ({
                gc_division_id: divisionId,
                created_by_uid: authUser.uid,
                user_id: u,
              })),
            },
          });
        },

        isAdmin: (employee) =>
          employee.gc_divisions.some(
            (division) => division.gc_division_id === divisionId,
          ),

        onRemoveFromTeam: async (userId, projects) => {
          await updateDivisionEmp({
            variables: {
              deleteDivEmpWhere: {
                user_id: { _eq: userId },
                gc_division_id: { _eq: divisionId },
              },
              deleteBuEmpWhere: { id: { _is_null: true } },
              deleteOfficeEmpWhere: { id: { _is_null: true } },
              deleteProjEmpWhere: {
                project_id: { _in: projects },
                employee_id: { _eq: userId },
                direct_project_assign: { _eq: false },
              },
              insertDivObjs: [],
            },
          });
        },

        excludeEmployeeWhere: {
          _or: [
            { is_corporate_admin: { _eq: true } },
            { gc_divisions: { gc_division_id: { _eq: divisionId } } },
          ],
        },
      }}
    />
  );
};

export default withCustomSuspense(GCDivisionEmployees);
