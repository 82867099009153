import GCAdminIncidentsOverview from "../../../../components/incident/GCAdminIncidentsOverview";
import { DivisionProps } from "../../../../utils/withDivisionId";
import useGcEmployee from "../../../utils/useGcEmployee";

const GCDivisionIncidents: React.FC<DivisionProps> = ({ divisionId }) => {
  const emp = useGcEmployee();
  return (
    <GCAdminIncidentsOverview
      organizationLevel="division"
      organizationId={divisionId}
      severityUserFilter={{ division_id: { _eq: divisionId } }}
      toInsertWith={{
        gc_id: emp.general_contractor.id,
        division_id: divisionId,
      }}
    />
  );
};
export default GCDivisionIncidents;
