import { RoutedMenuItem } from "src/utility-features/navigation/routed-menu/routedMenu";
import ChartsDemo from "./charts-demo/ChartsDemo";
import TabsDemo from "./tabs-demo/TabsDemo";
import VideoPlayerDemo from "./video-player-demo/VideoPlayerDemo";
import AudioPlayerDemo from "./audio-player/AudioPlayerDemo";
import SwitchDemo from "./switch/SwitchDemo";
import DemoDraft from "./draft/DemoDraft";
import OrientationPlayerDemo from "./orientation-player/OrientationPlayerDemo";
import tabsDemoNavigationItems from "./tabs-demo/tabsDemoNavigationItems";

const developmentPlaygroundNavigationItems: RoutedMenuItem[] = [
  {
    path: "charts",
    label: `Charts`,
    element: <ChartsDemo />,
  },
  {
    path: "tabs",
    element: <TabsDemo />,
    label: "Tabs",
    children: tabsDemoNavigationItems,
  },
  {
    path: "video-player",
    label: `Video Player`,
    element: <VideoPlayerDemo />,
  },
  {
    path: "orientation",

    label: "Orientation Player",
    element: <OrientationPlayerDemo />,
  },
  {
    path: "audio-player",
    label: "Audio Player",
    element: <AudioPlayerDemo />,
  },
  {
    path: "switch",
    label: "Switch",
    element: <SwitchDemo />,
  },
  {
    path: "draft",
    element: <DemoDraft />,
    label: " Draft",
  },
];

export default developmentPlaygroundNavigationItems;
