import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import useGetProjectSettingsData from "../utils/useGetProjectSettingsData";
import { useUpdateProjectValue } from "../utils/projectRelatedSettingsUpdateFunctions";
import { Card, Divider, Space, Typography } from "antd";
import SwitchWithText from "src/common/components/SwitchWithText";
import VisitorSettings from "src/domain-features/sitesafety/entry-routes/visitor/components/VisitorSettings";

const GcProjectSettingsReportsVisitors: React.FC<ProjectIdProps> = ({
  projectId,
}) => {
  const { project } = useGetProjectSettingsData(projectId);
  const { updateProjectValue, loading } = useUpdateProjectValue(
    projectId,
    project,
  );

  return (
    <div className={`flex flex-col gap-1`}>
      <Card title={`Visitor Settings`}>
        <Space
          className={`w-full`}
          direction={"vertical"}
          split={<Divider />}
          size={0}
        >
          <SwitchWithText
            explanation={
              <Typography.Paragraph>
                Visitors check-in and sign your visitor log. We capture their
                on-site hours in SiteForm and can add the visitors to Procore.
                When this is turned ON, go to <strong>REPORTS</strong> and{" "}
                <strong>Visitors</strong> to access the feature.
              </Typography.Paragraph>
            }
            checked={project.is_visitor_log_and_tracking_active}
            text={"Visitor log and tracking"}
            loading={loading}
            onChange={(newValue) =>
              updateProjectValue({
                is_visitor_log_and_tracking_active: newValue,
              })
            }
          />
          {project.is_visitor_log_and_tracking_active && (
            <SwitchWithText
              checked={!!project.is_visitor_log_procore_integration_enabled}
              text={"Procore Visitor log Integration"}
              onChange={(newValue) =>
                updateProjectValue({
                  is_visitor_log_procore_integration_enabled: newValue,
                })
              }
              loading={loading}
            />
          )}
        </Space>
      </Card>
      <VisitorSettings />
    </div>
  );
};

export default withProjectIdUrlParam(GcProjectSettingsReportsVisitors);
