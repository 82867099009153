/**
 * @generated SignedSource<<e0963ee5edeb479dc21071443e57e52a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { general_contractor_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompanySettingsGeneral_UpdateGeneralContractorMutation$variables = {
  _set: general_contractor_set_input;
  id: string;
};
export type GCCompanySettingsGeneral_UpdateGeneralContractorMutation$data = {
  readonly update_general_contractor_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type GCCompanySettingsGeneral_UpdateGeneralContractorMutation = {
  response: GCCompanySettingsGeneral_UpdateGeneralContractorMutation$data;
  variables: GCCompanySettingsGeneral_UpdateGeneralContractorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "general_contractor",
    "kind": "LinkedField",
    "name": "update_general_contractor_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompanySettingsGeneral_UpdateGeneralContractorMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCCompanySettingsGeneral_UpdateGeneralContractorMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0ac47b1caab52db0546061473db51fb4",
    "id": null,
    "metadata": {},
    "name": "GCCompanySettingsGeneral_UpdateGeneralContractorMutation",
    "operationKind": "mutation",
    "text": "mutation GCCompanySettingsGeneral_UpdateGeneralContractorMutation(\n  $id: uuid!\n  $_set: general_contractor_set_input!\n) {\n  update_general_contractor_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d29258a70cb9b3fe6fb4c7bf47df4f38";

export default node;
