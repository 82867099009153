import clsx from "clsx";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import SiteDeliveryCalendarTimeStampLine from "./SiteDeliveryCalendarTimeStampLine";
import SiteDeliveryCalendarTimeStampDigits from "./SiteDeliveryCalendarTimeStampDigits";

interface SitedeliveryCalendarTimeStampsProps {
  type: "lines" | "digits";
  limitTimeRange?: boolean; // Limits visible time stamps
}

const TIME_BOUNDARIES = {
  DAY_START: 0,
  EARLY_MORNING: 5,
  WORK_START: 6,
  WORK_END: 17,
  DAY_END: 24,
} as const;

const SitedeliveryCalendarTimeStamps: React.FC<
  SitedeliveryCalendarTimeStampsProps
> = (props) => {
  const hour = dayjs().hour();
  const [startTime, setStartTime] = useState<number>(
    hour < TIME_BOUNDARIES.EARLY_MORNING 
      ? TIME_BOUNDARIES.DAY_START 
      : hour < TIME_BOUNDARIES.WORK_END 
        ? TIME_BOUNDARIES.WORK_START 
        : TIME_BOUNDARIES.WORK_END,
  );
  const [endTime, setEndTime] = useState<number>(
    hour < TIME_BOUNDARIES.EARLY_MORNING 
      ? TIME_BOUNDARIES.EARLY_MORNING 
      : hour < TIME_BOUNDARIES.WORK_END 
        ? TIME_BOUNDARIES.WORK_END 
        : TIME_BOUNDARIES.DAY_END,
  );

  useEffect(() => {
    if (props.limitTimeRange) {
      const interval = setInterval(() => {
        const currentHour = dayjs().hour();
        currentHour < TIME_BOUNDARIES.EARLY_MORNING
          ? setStartTime(TIME_BOUNDARIES.DAY_START)
          : currentHour < TIME_BOUNDARIES.WORK_END
          ? setStartTime(TIME_BOUNDARIES.WORK_START)
          : setStartTime(TIME_BOUNDARIES.WORK_END);

        currentHour < TIME_BOUNDARIES.EARLY_MORNING
          ? setEndTime(TIME_BOUNDARIES.EARLY_MORNING)
          : currentHour < TIME_BOUNDARIES.WORK_END
          ? setEndTime(TIME_BOUNDARIES.WORK_END)
          : setEndTime(TIME_BOUNDARIES.DAY_END);
      }, 60000);

      return () => clearInterval(interval);
    }
  }, []);

  const start = !props.limitTimeRange ? TIME_BOUNDARIES.DAY_START : startTime;
  const end = !props.limitTimeRange ? TIME_BOUNDARIES.DAY_END : endTime;

  return (
    <div className="sticky z-10 top-0 left-0 items-center h-full flex-flex-col pointer-events-none">
      <div className="flex flex-row h-full">
        <div
          className={clsx(
            "flex flex-col items-start justify-between h-full ",
            props.type === "digits" ? "w-px" : "w-full",
          )}
        >
          {(() => {
            const element: React.ReactNode[] = [];
            switch (props.type) {
              case "lines":
                for (let i = 0; i < ((end - start) * 60) / 15 + 1; i++) {
                  const hour = i / 4;
                  const isHour = hour === Math.floor(hour);
                  element.push(
                    <SiteDeliveryCalendarTimeStampLine isHour={isHour} />,
                  );
                }
                break;
              case "digits":
                for (let i = start; i <= end; i++) {
                  const hour = i;
                  const timeToDisplay = dayjs(`${hour}`, ["h"]).format("h a");

                  element.push(
                    <SiteDeliveryCalendarTimeStampDigits
                      key={i}
                      timeStr={timeToDisplay}
                    />,
                  );
                }
                break;
            }
            return element;
          })()}
        </div>
      </div>
    </div>
  );
};

export default SitedeliveryCalendarTimeStamps;
