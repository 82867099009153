import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { Project_Crew } from "src/common/types/generated/apollo/graphQLTypes";
import RecursivePartial from "src/common/types/manual/RecursivePartial";
import styled, { StyledComponent } from "styled-components";

type Props = Omit<BaseTableProps<RecursivePartial<Project_Crew>>, "columns">;

type ProjectCrewsTable = (p: Props) => JSX.Element;

const ProjectCrewsTable: StyledComponent<
  ProjectCrewsTable,
  any,
  Props,
  never
> = styled(BaseTable).attrs(({ pagination, ...props }: Props) => {
  const columns = [
    {
      title: "Crew",
      key: "name",
      dataIndex: ["name"],
    },
    {
      title: "Workers",
      dataIndex: ["project_workers_aggregate", "aggregate", "count"],
    },
    {
      title: "Lead Foreman",
      dataIndex: ["lead_foreman_project_worker", "user", "name"],
    },
    {
      title: "Deleted",
      dataIndex: ["deleted_at"],
      render: (v: string | null) =>
        v ? <div className="text-semantic-negative">Deleted</div> : null,
    },
  ];

  return {
    ...props,
    columns,
    summary: () => (
      <tr>
        <th>Total</th>
        <td colSpan={columns.length - 1}>{!!pagination && pagination.total}</td>
      </tr>
    ),
    size: "middle",
  };
})``;

export default ProjectCrewsTable;
