import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Modal, Upload, Select, message, notification } from "antd";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useState } from "react";
import insertTasksMutation from "src/common/api/relay/mutations/InsertTasks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import isNullOrUndefined from "src/common/functions/isNullOrUndefined";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import {
  InsertTasksMutation,
  task_hazard_ecm_type_insert_input,
  task_insert_input,
  task_permit_type_insert_input,
  task_ppe_type_insert_input,
  task_step_hazard_insert_input,
  task_step_insert_input,
} from "src/common/types/generated/relay/InsertTasksMutation.graphql";
import * as xlsx from "xlsx";
import { GCProjectJhasQuery$data } from "src/common/types/generated/relay/GCProjectJhasQuery.graphql";
import readAsArrayBuffer from "src/common/functions/readAsArrayBuffer";

const mapEcms = (ecm: string) => {
  const e = "39ab97a5-47ac-4e5d-b287-21c2cb016c16",
    c = "05660c6a-c370-4c82-86a9-e558772d7d56",
    m = "57774dd6-3e26-45aa-a7cd-c3cb518a75f9";
  if (ecm === "E" || ecm === "e") return [e];
  else if (ecm === "C" || ecm === "c") return [c];
  else if (ecm === "M" || ecm === "m") return [m];
  else if (ecm === "E/C" || ecm === "e/c" || ecm === "E/c" || ecm === "e/C")
    return [e, c];
  else if (ecm === "E/M" || ecm === "e/m" || ecm === "E/m" || ecm === "e/M")
    return [e, m];
  else if (ecm === "C/M" || ecm === "c/m" || ecm === "C/m" || ecm === "c/M")
    return [c, m];
  else if (
    ecm === "E/C/M" ||
    ecm === "e/c/m" ||
    ecm === "E/c/m" ||
    ecm === "e/C/m" ||
    ecm === "e/c/M" ||
    ecm === "E/C/m" ||
    ecm === "E/c/M" ||
    ecm === "e/C/M"
  )
    return [e, c, m];
  else return [];
};

interface UploadTaskModalProps {
  visible: boolean;
  isNotTurnersProject: boolean;
  onCancel: () => void;
  subcontractorId?: string;
  subcontractorOptions?: GCProjectJhasQuery$data["project_subcontractor_connection"]["edges"];
  ppeData: Array<{ name: string; id: string }>;
  permitData: Array<{ name: string; id: string }>;
  setIsOpenUploadModal: Dispatch<SetStateAction<boolean>>;
  projectId?: string;
  setOrder?: (order: Order_By) => void;
  ordering?: Order_By;
  refetch?: () => void;
}

const UploadTaskModal = ({
  isNotTurnersProject,
  onCancel,
  permitData,
  ppeData,
  setIsOpenUploadModal,
  subcontractorId,
  subcontractorOptions,
  visible,
  ordering,
  projectId,
  refetch,
  setOrder,
}: UploadTaskModalProps) => {
  const [insertTasks, loading] =
    useAsyncMutation<InsertTasksMutation>(insertTasksMutation);
  const [destroyOnClose, setDestroyOnClose] = useState(false);
  const [currentFiles, setCurrentFiles]: any = useState([]);
  const [subId, setSubId] = useState(subcontractorId);

  const mapObj = [
    ["<br/>", "\n"],
    ["&apos;", `'`],
    ["&quot;", `"`],
    ["&lt;", `<`],
    ["&gt;", `>`],
    ["&amp;", "&"],
    ["&#x000d;", ""],
  ];

  return (
    <Modal
      confirmLoading={loading}
      open={visible}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      title="Upload JHA"
      onOk={async () => {
        for (let fileId = 0; fileId < currentFiles.length; fileId++) {
          const fileContent = await readAsArrayBuffer(currentFiles[fileId]);

          const workbook = xlsx.read(fileContent, { type: "array" });
          const tasksToInsert: task_insert_input[] = [];
          for (const sheet in workbook.Sheets) {
            let data: any = [];
            data.ppe = [];
            data.permit = [];
            if (
              sheet.toLowerCase() !== "example jha for reference only" &&
              sheet.toLowerCase() !== "example jha nonturner" &&
              sheet.toLowerCase() !== "data"
            ) {
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                let allInfo = workbook.Sheets[sheet];
                let keys = Object.keys(allInfo);
                //console.log(allInfo);
                data.name = allInfo.D2?.h;
                if (isNullOrUndefined(data.name)) {
                  data.name = "";
                }
                for (
                  let replacing = 0;
                  replacing < mapObj.length;
                  replacing++
                ) {
                  data.name = data.name.replaceAll(
                    mapObj[replacing][0],
                    mapObj[replacing][1],
                  );
                }
                // console.log(data.name);
                data.steps = [];

                let turnerSpreadSheet =
                  allInfo["C" + 24]?.h === "Steps to Perform this JHA";

                let i = turnerSpreadSheet ? 25 : 17;
                // console.log(i, allInfo);
                while (true) {
                  let stepName = allInfo["C" + i]?.h;
                  let hazard = allInfo["D" + i]?.h;
                  let control = turnerSpreadSheet
                    ? allInfo["F" + i]?.h
                    : allInfo["E" + i]?.h;
                  let ecm = turnerSpreadSheet ? allInfo["E" + i]?.h : "";
                  // console.log(stepName, hazard, control, ecm);

                  for (
                    let replacing = 0;
                    replacing < mapObj.length;
                    replacing++
                  ) {
                    stepName = stepName?.replaceAll(
                      mapObj[replacing][0],
                      mapObj[replacing][1],
                    );
                    hazard = hazard?.replaceAll(
                      mapObj[replacing][0],
                      mapObj[replacing][1],
                    );
                    control = control?.replaceAll(
                      mapObj[replacing][0],
                      mapObj[replacing][1],
                    );
                  }
                  if (!stepName && !hazard && !control) {
                    break;
                  }
                  if (!stepName && !!hazard && !!control) {
                    data.steps[data.steps.length - 1].hazards.push(hazard);
                    data.steps[data.steps.length - 1].controls.push(control);
                    data.steps[data.steps.length - 1].ecms.push(mapEcms(ecm));
                  }
                  if (!!stepName && !!hazard && !!control) {
                    if (
                      data.steps.length > 0 &&
                      stepName == data.steps[data.steps.length - 1].name
                    ) {
                      data.steps[data.steps.length - 1].hazards.push(hazard);
                      data.steps[data.steps.length - 1].controls.push(control);
                      data.steps[data.steps.length - 1].ecms.push(mapEcms(ecm));
                    } else {
                      let obj: any = {
                        name: stepName,
                        hazards: [hazard],
                        controls: [control],
                        ecms: [mapEcms(ecm)],
                      };
                      data.steps.push(obj);
                    }
                  }
                  if (!!stepName && !hazard && !control) {
                    if (
                      (data.steps.length > 0 &&
                        stepName != data.steps[data.steps.length - 1].name) ||
                      data.steps.length === 0
                    ) {
                      let obj: any = {
                        name: stepName,
                        hazards: [],
                        controls: [],
                        ecms: [],
                      };
                      data.steps.push(obj);
                    }
                  }
                  i += 1;
                }
                // console.log(data);
                let firstPpe = allInfo["E4"]?.h;
                let firstPermit = allInfo["E11"]?.h;
                if (!isNullOrUndefined(firstPpe)) data.ppe.push(firstPpe);
                if (!isNullOrUndefined(firstPermit))
                  data.permit.push(firstPermit);
                for (let k = 5; k <= 10; k++) {
                  if (!isNullOrUndefined(allInfo["C" + k]?.h))
                    data.ppe.push(allInfo["C" + k]?.h);
                  if (!isNullOrUndefined(allInfo["D" + k]?.h))
                    data.ppe.push(allInfo["D" + k]?.h);
                  if (!isNullOrUndefined(allInfo["E" + k]?.h))
                    data.ppe.push(allInfo["E" + k]?.h);
                }

                for (let k = 12; k <= 14; k++) {
                  if (!isNullOrUndefined(allInfo["C" + k]?.h))
                    data.permit.push(allInfo["C" + k]?.h);
                  if (!isNullOrUndefined(allInfo["D" + k]?.h))
                    data.permit.push(allInfo["D" + k]?.h);
                  if (!isNullOrUndefined(allInfo["E" + k]?.h))
                    data.permit.push(allInfo["E" + k]?.h);
                }
                let stepData: task_step_insert_input[] = [];
                for (let i = 0; i < data.steps.length; i++) {
                  const hazardsData: task_step_hazard_insert_input[] = [];
                  for (let i1 = 0; i1 < data.steps[i].hazards.length; i1++) {
                    const ecmTypes: task_hazard_ecm_type_insert_input[] =
                      data.steps[i].ecms[i1].map((ecm: string) => ({
                        ecm_type_id: ecm,
                      }));

                    hazardsData.push({
                      description: {
                        data: {
                          en: data.steps[i].hazards[i1],
                          original: data.steps[i].hazards[i1],
                        },
                      },
                      sort_index: 10000 * (i1 + 1),
                      control: {
                        data: {
                          en: data.steps[i].controls[i1],
                          original: data.steps[i].controls[i1],
                        },
                      },
                      task_hazard_ecm_types: {
                        data: ecmTypes,
                      },
                    });
                  }
                  stepData.push({
                    description: {
                      data: {
                        en: data.steps[i].name,
                        original: data.steps[i].name,
                      },
                    },
                    sort_index: 10000 * (i + 1),
                    task_step_hazards: {
                      data: hazardsData,
                    },
                  });
                }

                //ppe
                data.ppe = [...new Set(data.ppe)];
                let valuesPPE: task_ppe_type_insert_input[] = [];
                for (let i = 0; i < data.ppe.length; i++) {
                  for (let i1 = 0; i1 < ppeData.length; i1++) {
                    if (
                      data.ppe[i].toLowerCase() ==
                        ppeData[i1].name.toLowerCase() ||
                      ((data.ppe[i].toLowerCase() == "hy-vis vest" ||
                        data.ppe[i].toLowerCase() == "reflective") &&
                        ppeData[i1].name.toLowerCase() ==
                          "reflective/hy-vis vest")
                    ) {
                      valuesPPE.push({ ppe_type_id: ppeData[i1].id });
                    }
                  }
                }

                // //permits

                data.permit = [...new Set(data.permit)];
                let valuesPermit: task_permit_type_insert_input[] = [];
                for (let i = 0; i < data.permit.length; i++) {
                  for (let i1 = 0; i1 < permitData.length; i1++) {
                    if (
                      data.permit[i].toLowerCase() ==
                      permitData[i1].name.toLowerCase()
                    ) {
                      valuesPermit.push({
                        permit_type_id: permitData[i1].id,
                      });
                    }
                  }
                }

                const taskInsertInput: task_insert_input = {
                  description: {
                    data: {
                      en: data.name,
                      original: data.name,
                    },
                  },
                  project_id: projectId,
                  created_by_uid: auth.currentUser?.uid,
                  subcontractor_id: subId,
                  task_steps: {
                    data: stepData,
                  },
                  task_permit_types: { data: valuesPermit },
                  task_ppe_types: { data: valuesPPE },
                  task_edit: {
                    data: ordering
                      ? [
                          {
                            edit_type: "Uploaded",
                            edited_by_uid: auth.currentUser?.uid,
                            edited_at: dayjs().format(),
                          },
                        ]
                      : [],
                  },
                };
                // console.log(taskInsertInput);

                //add steps
                tasksToInsert.push(taskInsertInput);
                // break; // if you need to read all lists delete this line
              }
            }
          }
          await insertTasks({
            variables: {
              objects: tasksToInsert,
            },
          });
          message.success("New Task Added");
          onCancel();
          if (!isNullOrUndefined(ordering) && setOrder) {
            if (ordering == Order_By.Asc || ordering == Order_By.AscNullsLast) {
              setOrder(Order_By.AscNullsFirst);
            } else {
              setOrder(Order_By.AscNullsLast);
            }
            setDestroyOnClose(true);
          } else if (refetch) {
            refetch();
            setDestroyOnClose(true);
          }
          setIsOpenUploadModal(false);
        }
      }}
    >
      {!!subcontractorOptions && subcontractorOptions.length > 0 && (
        <Select
          className="w-full mr-2 mb-2"
          options={subcontractorOptions.map((sub) => ({
            key: sub.node.subcontractor_id,
            value: sub.node.subcontractor_id,
            label: sub.node.subcontractor.name,
          }))}
          onChange={(e) => setSubId(e.toString())}
          placeholder={"Select subcontractor"}
        ></Select>
      )}
      <p>
        Download the spreadsheet template, fill it out with JHA information, and
        then upload it in the box below.
      </p>
      <a
        href={
          isNotTurnersProject
            ? "https://firebasestorage.googleapis.com/v0/b/siteform-3170b.appspot.com/o/assets%2FSiteForm%20JHA%20-%20Blank%20for%20Upload.xlsx?alt=media&token=2733e8ed-0927-423f-9472-f2d613c1da94"
            : "https://firebasestorage.googleapis.com/v0/b/siteform-3170b.appspot.com/o/assets%2FSiteForm%20JHA%20-%20Blank%20for%20Upload%20TURNER%20ECM.xlsx?alt=media&token=b085e8f9-d3ad-4a9e-8c63-5c1587d77b46"
        }
        target="_blank"
      >
        <Button type="primary">
          {isNotTurnersProject
            ? "Download template"
            : "Download Turner's Template"}
        </Button>
      </a>
      <p />
      <hr />
      <div>
        <Upload.Dragger
          disabled={!subId}
          customRequest={() => true}
          accept=".xlsx"
          iconRender={() => <AntCloudOutlined />}
          name="files"
          onChange={(info) => {
            setDestroyOnClose(false);
            setCurrentFiles(
              info.fileList.map((cur) => {
                return cur.originFileObj;
              }),
            );
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>Click or drag your completed JHA spreadsheet here to upload</p>
        </Upload.Dragger>
      </div>
    </Modal>
  );
};

export default UploadTaskModal;
