import * as Sentry from "@sentry/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  GetObservationByPkDocument,
  GetObservationByPkQuery,
  GetObservationByPkQueryVariables,
  GetObservationOptionsQueryVariables,
  Todo_Assignee_Insert_Input,
  Unsafe_Observation_Constraint,
  Unsafe_Observation_Update_Column,
  UnsafeObservationAssigneeFragmentFragment,
  useEmailObservationMutation,
  useGenerateObservationPdfMutation,
  useGetObservationOptionsQuery,
  useSendObsNotificationsMutation,
  useSubmitObservationMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import {
  Button,
  Card,
  Drawer,
  Image,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { DataApolloScrollTableRef } from "src/common/components/tables/basic/DataApolloScrollTable";
import { useNavigate, useParams } from "react-router-dom";
import Title from "antd/es/typography/Title";
import capitalize from "src/common/functions/capitalize";
import handleRequestError from "src/utility-features/error-handling/handleError";
import DesktopBirthDatePicker from "src/common/components/birth-date-picker/DesktopBirthDatePicker";
import dayjs from "dayjs";
import ObservationTextField from "./basic/ObservationTextField";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import useAuthUser from "src/common/hooks/useAuthUser";
import ObservationDocUploader from "./basic/ObservationDocUploader";
import useUpdateObservationWithCache, {
  UpdateFunctionInputArgs,
} from "../utils/useUpdateObservationWithCache";
import useUpdateUnsafeObservationWithCache, {
  UpdateUnsafeObservationFuncProps,
} from "../utils/useUpdateUnsafeObservationWithCache";
import ObservationSelectUsers from "./ObservationSelectUsers";
import SelectCard from "./basic/SelectCard";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import getGroupOptionsFromObservationOptionsData from "../utils/getGroupOptionsFromObservationOptionsData";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import ObservationDistributedUserTable from "./ObservationDistributedUserTable";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";
import CompleteObservationButton from "./basic/CompleteObservationButton";
import { useSuspenseQuery } from "@apollo/client";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import showModalConfirmation from "src/common/functions/showModalConfirmation";
import useGcEmployee from "src/root/routes/views/general-contractor/hierarchy/organization-unit/utils/useGcEmployee";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import reportException from "src/utility-features/error-handling/reportException";

const ObservationDetail: React.FunctionComponent<{
  observationId: string;
  subcontractorId?: string;
  projectId: string;
  gcId: string;
}> = ({ observationId, subcontractorId, projectId, gcId }) => {
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const variables = { id: observationId };
  const {
    data,
    error: error1,
    refetch,
  } = useSuspenseQuery<
    GetObservationByPkQuery,
    GetObservationByPkQueryVariables
  >(GetObservationByPkDocument, {
    variables,
    fetchPolicy: "cache-first",
  });
  const observation = data?.observation_by_pk;
  if (!observation) throw new Error("Observation not found");
  const isSubmitted =
    observation.status !== "draft" &&
    (!observation.observation_inspection ||
      !!observation.observation_inspection.submitted_on);

  const optionsVariables: GetObservationOptionsQueryVariables = subcontractorId
    ? {
        projectWorkerWhere: {
          project_id: { _eq: projectId },
          subcontractor_id: { _eq: subcontractorId },
        },
        projectId,
        gcId,
        userId: authUser.uid,
        subWhere: { id: { _eq: subcontractorId } },
      }
    : {
        projectWorkerWhere: { project_id: { _eq: projectId } },
        userId: authUser.uid,
        gcId,
        projectId,
        subWhere: {
          subcontractor_projects: { project_id: { _eq: projectId } },
        },
      };
  const {
    data: optionsData,
    error: err2,
    loading: loading2,
    refetch: refetchOptions,
    client,
  } = useGetObservationOptionsQuery({
    fetchPolicy: "cache-first",
    variables: optionsVariables,
  });

  const { userData } = useUserData();
  const [generatePdf, { loading: downloading }] =
    useGenerateObservationPdfMutation();
  const [submitting, setSubmitting] = useState(false);
  const [submitObservation] = useSubmitObservationMutation();
  const [updateObservation, updating] = useUpdateObservationWithCache();
  const [updateUnsafeObservation, updatingUnsafe] =
    useUpdateUnsafeObservationWithCache();
  const [sendObsNotifications] = useSendObsNotificationsMutation();
  const fmodalRef = useRef<
    FModalRef<{
      distributeTo: Array<string>;
    }>
  >();

  const numberOfFindingsOptions = useMemo(() => {
    const res = [];
    for (let i = 1; i <= 100; i++) res.push(i);
    return res;
  }, []);

  const unsafeObservation = observation.unsafe_observation;
  const { assignedToUsers, correctedByUsers } = useMemo(
    () =>
      (unsafeObservation?.assignees || []).reduce(
        ({ assignedToUsers, correctedByUsers }, assignee) => {
          if (assignee.type === "assignee") {
            assignedToUsers.push(assignee);
          } else if (assignee.type === "corrected_by") {
            correctedByUsers.push(assignee);
          } else
            throw new Error(
              "type of unsafe_observation_assignee is invalid here",
            );
          return { assignedToUsers, correctedByUsers };
        },
        { assignedToUsers: [], correctedByUsers: [] } as {
          assignedToUsers: UnsafeObservationAssigneeFragmentFragment[];
          correctedByUsers: UnsafeObservationAssigneeFragmentFragment[];
        },
      ),
    [unsafeObservation],
  );
  const [categoryTypeId, setCategoryTypeId] = useState<string>();
  const distributionTableRef = useRef<DataApolloScrollTableRef>(null);
  const [emailObs, { loading: emailing }] = useEmailObservationMutation();
  const [notEditing, setNotEditing] = useState(isSubmitted);
  const [openDistributeModal, setOpenDistributeModal] = useState(false);

  useEffect(() => {
    if (observation.observation_category_id && optionsData)
      setCategoryTypeId(
        optionsData?.observation_category_type.find((c) =>
          c.observation_categories.find(
            (o) => o.id === observation.observation_category_id,
          ),
        )?.id,
      );
  }, [observation, optionsData]);
  const categoryType = useMemo(
    () =>
      optionsData?.observation_category_type.find(
        (cate) => cate.id === categoryTypeId,
      ),
    [categoryTypeId, optionsData],
  );
  const user = optionsData?.userData;
  if (err2 || error1) throw err2 || error1;
  if (loading2) return <LargeTableSkeleton />;
  if (!optionsData) throw new Error("Options Data not found for Observation");
  if (!user) throw new Error("Logged In user not found");
  const options = getGroupOptionsFromObservationOptionsData(
    optionsData,
    subcontractorId,
  );

  const showInReview =
    optionsData.project_setting_by_pk?.require_gc_to_mark_observation_complete;
  const isUnsafe =
    !!observation.risk_level_value && observation.risk_level_value !== "safe";
  const pendingRequiredReview =
    observation.status === "closed" &&
    isUnsafe &&
    showInReview &&
    !observation.complete_marked_at;

  const updateUnsafe = async (
    set: UpdateUnsafeObservationFuncProps["set"],
    editComment?: UpdateUnsafeObservationFuncProps["editComment"],
  ) => {
    if (!unsafeObservation) return;
    // should we sliently return,,  is it mistake to use this method if observation is not unsafe
    return await updateUnsafeObservation({
      observation,
      set,
      editComment,
    });
  };

  const onFinalSubmit = async () => {
    setSubmitting(true);

    try {
      const markClose =
        observation.risk_level_value === "safe" ||
        !unsafeObservation ||
        unsafeObservation.is_corrected;
      const todoAssignees: Todo_Assignee_Insert_Input[] = [
        {
          assignee_user_id: authUser.uid,
          assignee_completed_type: "one",
          assignee_project_id: projectId,
          assignee: { data: { employee_id: authUser.uid } },
        },
      ];

      assignedToUsers.forEach((assignee) => {
        todoAssignees.push({
          assignee_user_id: (assignee.project_worker || assignee.employee)?.user
            ?.id,
          assignee_project_id: projectId,
          assignee_completed_type: "one",
          ...(assignee.project_worker
            ? {
                worker_subcontractor_id:
                  assignee.project_worker.subcontractor.id,
                assignee: {
                  data: { project_worker_id: assignee.project_worker.id },
                },
              }
            : {
                assignee: {
                  data: { employee_id: assignee.employee?.user?.id },
                },
              }),
        });
      });
      await submitObservation({
        variables: {
          //TODO fix enum type
          todos:
            isUnsafe && unsafeObservation && !unsafeObservation.is_corrected
              ? [
                  {
                    description: {
                      data: {
                        original: unsafeObservation.action.en,
                      },
                    },
                    due_at: unsafeObservation.due_on,
                    project_id: projectId,
                    type: "with_observation",
                    assignor_user_id: authUser.uid,
                    notifyees: {
                      data: [
                        {
                          notifyee: {
                            data: { employee_id: authUser.uid },
                          },
                        },
                      ],
                    },
                    assigned_to: { data: todoAssignees },
                    unsafe_observation: {
                      data: { observation_id: observation.id },
                      on_conflict: {
                        update_columns: [
                          Unsafe_Observation_Update_Column.TodoId,
                        ],
                        constraint:
                          Unsafe_Observation_Constraint.UnsafeObservationPkey,
                      },
                    },
                  },
                ]
              : [],
          id: observation.id,
          _set: markClose
            ? {
                status: "closed",
                ...(userData.employee && isUnsafe
                  ? {
                      complete_marked_at: dayjs().toISOString(),
                      complete_marked_by_uid: authUser.uid,
                    }
                  : {}),
              }
            : {
                status: "open",
                complete_marked_at: null,
                complete_marked_by_uid: null,
              },
        },
      });
      navigate(-1);
    } finally {
      setSubmitting(false);
    }
  };
  const sendHighRiskLevelNotifcation = async (
    risk_level_value: string,
    sendOrUpdate: "send" | "update",
  ) => {
    const riskLevel = optionsData.risk_level.find(
      (r) => r.value === risk_level_value,
    );
    if (
      riskLevel &&
      riskLevel.value !== "safe" &&
      riskLevel.observation_notifications.length
    ) {
      const confirmed = await showModalConfirmation({
        title: "Observation Risk Alert",
        content: `The selected Risk level sends automated alerts. Confirm ${sendOrUpdate}`,
        okText: "Confirm",
      });
      if (!confirmed) return false;
      sendObsNotifications({
        variables: {
          input: { gcId, projectId, observationId },
        },
      }).catch(reportException);
    }
    return true;
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="overflow-x-hidden">
        <div className="absolute right-2 top-1 flex gap-1">
          <Button
            onClick={async () => {
              const { data } = await generatePdf({
                variables: {
                  input: { observationId, projectId },
                },
              });
              if (data) {
                downloadFromUrl(data.generateObservationPdf);
              }
            }}
            loading={downloading}
            icon={<DownloadOutlined />}
            type="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => {
              setOpenDistributeModal(true);
            }}
            loading={emailing}
            icon={<ShareAltOutlined />}
            type="primary"
          >
            Distribute
          </Button>
        </div>
        <FModal
          ref={fmodalRef}
          open={openDistributeModal}
          onCancel={() => setOpenDistributeModal(false)}
          okText="Send"
          onOk={async () => {
            const vals = await fmodalRef.current?.form.validateFields();
            console.log(vals);
            if (!vals) return;
            setOpenDistributeModal(false);
            await emailObs({
              variables: {
                input: {
                  observationId,
                  projectId,
                  distributeToIds: vals.distributeTo,
                },
              },
            });
            distributionTableRef.current &&
              distributionTableRef.current?.refetch();
          }}
        >
          <FModal.Select
            required
            requiredMessage="Select atleast one user"
            name={"distributeTo"}
            label={"Select users to distribute to"}
            props={{
              mode: "multiple",
              options: options,
              filterOption: (input, option) => {
                if (option?.label && input.length > 0) {
                  return (
                    option.label
                      .toLocaleLowerCase()
                      .indexOf(input.toLocaleLowerCase()) !== -1
                  );
                }
                return false;
              },
            }}
          />
        </FModal>
        <Card
          style={{
            width: "100%",
            minWidth: 1300,
            marginTop: 10,
            marginBottom: 10,
          }}
          title={
            <>
              {isSubmitted && (
                <div className="flex gap-2 mt-1">
                  <Button
                    type="primary"
                    onClick={() => setNotEditing((i) => !i)}
                  >
                    {notEditing ? "Edit" : "Save"}
                  </Button>
                  {(observation.status === "open" || pendingRequiredReview) &&
                    optionsData.userData && (
                      <CompleteObservationButton
                        {...{
                          observationId,
                          projectId,
                          pendingRequiredReview,
                          obs: observation,
                          loading: submitting,
                          setLoading: setSubmitting,
                          loggedInUserData: optionsData.userData,
                          todoId: unsafeObservation?.todo_id,
                          onCompleted: () => {
                            refetchOptions();
                            refetch();
                          },
                        }}
                      />
                    )}
                </div>
              )}
              <div className="flex gap-2 mt-1">
                <Title level={3}>Observation</Title>{" "}
                {observation.status === "draft" && (
                  <Button
                    type="primary"
                    loading={updating}
                    disabled={
                      !(
                        observation.observation_category_id &&
                        observation.observation_date &&
                        observation.risk_level_value &&
                        observation.subcontractor_id &&
                        (observation.risk_level_value === "safe" ||
                          (unsafeObservation &&
                            (unsafeObservation.is_corrected
                              ? unsafeObservation.corrected_on &&
                                correctedByUsers.length
                              : unsafeObservation.due_on &&
                                unsafeObservation.action.en.trim() &&
                                assignedToUsers.length)))
                      )
                    }
                    onClick={async () => {
                      if (!observation.risk_level_value) {
                        message.error("risk level not found");
                        return;
                      }
                      if (
                        !(await sendHighRiskLevelNotifcation(
                          observation.risk_level_value,
                          "send",
                        ))
                      )
                        return;
                      await onFinalSubmit();
                    }}
                  >
                    Create{" "}
                  </Button>
                )}
              </div>
              {pendingRequiredReview ? (
                <div className="flex gap-2">
                  <Typography.Text type="secondary" strong>
                    Status: <span className="font-accent">Review Pending</span>
                  </Typography.Text>
                </div>
              ) : (
                <Typography.Text type="secondary" strong>
                  Status:{" "}
                  <span className="font-accent">
                    {capitalize(observation.status)}
                  </span>
                </Typography.Text>
              )}
            </>
          }
        >
          <div className="flex gap-1 flex-wrap">
            <Card className="w-2/5">
              <div className="flex gap-1 items-center">
                <span className="text-semantic-negative">*</span> Category:{" "}
                {notEditing && categoryType ? (
                  <span className="font-accent">{categoryType?.name.en}</span>
                ) : (
                  <Select
                    style={{ width: "100%" }}
                    placeholder={"Select Observation Category"}
                    // disabled={updating}
                    value={categoryTypeId}
                    showSearch
                    options={optionsData.observation_category_type.map(
                      (type) => ({
                        value: type.id,
                        label: `${type.name.en}`,
                      }),
                    )}
                    filterOption={getNormalSelectOptionsFilter}
                    onChange={(v) => setCategoryTypeId(v)}
                  />
                )}
              </div>
            </Card>
            {categoryType && (
              <SelectCard
                observation={observation}
                required
                notEditing={notEditing}
                label="Sub-Category"
                field="observation_category_id"
                placeholder="Select Observation Sub category"
                options={(
                  optionsData.observation_category_type.find(
                    (type) => type.id === categoryTypeId,
                  )?.observation_categories || []
                ).map((c) => ({ value: c.id, label: c.name.en }))}
              />
            )}
            <SelectCard
              label=" Risk Level"
              required
              notEditing={notEditing}
              observation={observation}
              field="risk_level_value"
              onChange={
                observation.status === "draft"
                  ? undefined
                  : async (newLevel) => {
                      if (typeof newLevel !== "string") return;
                      if (
                        !(await sendHighRiskLevelNotifcation(
                          newLevel,
                          "update",
                        ))
                      )
                        return;

                      await updateObservation({
                        observation,
                        set: { risk_level_value: newLevel },
                        editComment: `changed risk level from ${observation.risk_level_value} to ${newLevel}`,
                      });
                    }
              }
              placeholder="Select Risk level"
              options={optionsData.risk_level.map((risk) => ({
                color: risk.color_hex,
                value: risk.value,
                label: risk.name,
              }))}
            />
            <SelectCard
              label="Number of findings"
              field="number_of_findings"
              notEditing={notEditing}
              required
              observation={observation}
              placeholder="Type a number between 1 to 100"
              options={numberOfFindingsOptions.map((num) => ({
                value: num,
                label: `${num}`,
              }))}
            />
            {!subcontractorId && (
              <SelectCard
                observation={observation}
                required
                notEditing={notEditing}
                placeholder="Select company for observation"
                label="Company"
                field="subcontractor_id"
                options={optionsData.subcontractor.map((v) => ({
                  label: v.name,
                  value: v.id,
                }))}
              />
            )}
            <Card className="w-2/5">
              <div className="flex gap-1 justify-start">
                <span className="text-semantic-negative">*</span> Observation
                Date:{" "}
                {notEditing ? (
                  observation.observation_date ? (
                    dayjs(observation.observation_date).format("MMMM Do, YYYY")
                  ) : null
                ) : (
                  <DesktopBirthDatePicker
                    maxDate={dayjs()}
                    onValueChange={async (newVal) => {
                      if (newVal)
                        await updateObservation({
                          observation,
                          set: {
                            observation_date: dayjs(newVal).toISOString(),
                          },
                          editComment: `changed observation date to ${
                            newVal
                              ? dayjs(newVal).format("MMMM Do, YYYY")
                              : null
                          }`,
                        });
                    }}
                    value={
                      observation.observation_date
                        ? dayjs(observation.observation_date)
                        : null
                    }
                  />
                )}
              </div>
            </Card>
            <ObservationTextField
              fieldName="location"
              key={"location"}
              notEditing={notEditing}
              fieldTypeKey="observation"
              label="Location"
              observation={observation}
              text={observation.location.en}
              textId={observation.location.id}
            />
            <ObservationTextField
              fieldName="description"
              label="Description/Comment"
              key={"description"}
              fieldTypeKey="observation"
              notEditing={notEditing}
              observation={observation}
              text={observation.description.en}
              textId={observation.description.id}
            />
            {isUnsafe && unsafeObservation && (
              <>
                <Card className="w-2/5">
                  <div className="flex gap-1 items-start">
                    <span className="text-semantic-negative">*</span> Corrected:{" "}
                    {notEditing ? (
                      unsafeObservation.is_corrected ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : (
                      <Radio.Group
                        className="flex gap-2"
                        onChange={async (e) => {
                          await updateUnsafe(
                            {
                              is_corrected: e.target.value,
                              ...(e.target.value
                                ? {
                                    corrected_on:
                                      unsafeObservation?.corrected_on ||
                                      dayjs().toISOString(),
                                  }
                                : {}),
                            },
                            "marked " +
                              (e.target.value ? "corrected" : "action reqd."),
                          );
                          if (observation.status !== "draft") {
                            const markClose = observation.status === "closed";
                            await updateObservation({
                              observation,
                              set: markClose
                                ? {
                                    status: "closed",
                                    ...(userData.employee
                                      ? {
                                          complete_marked_at:
                                            dayjs().toISOString(),
                                          complete_marked_by_uid: authUser.uid,
                                        }
                                      : {}),
                                  }
                                : {
                                    status: "open",
                                    complete_marked_at: null,
                                    complete_marked_by_uid: null,
                                  },
                            });
                          }
                        }}
                        value={unsafeObservation.is_corrected}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    )}
                  </div>
                </Card>
                {typeof unsafeObservation.is_corrected === "boolean" && (
                  <>
                    {unsafeObservation.is_corrected && (
                      <Card className="w-2/5">
                        <div className="flex gap-1 items-center">
                          <span className="text-semantic-negative">*</span>{" "}
                          Correction Date:{" "}
                          {notEditing ? (
                            unsafeObservation["corrected_on"] ? (
                              dayjs(unsafeObservation["corrected_on"]).format(
                                "MMMM Do, YYYY",
                              )
                            ) : null
                          ) : (
                            <DesktopBirthDatePicker
                              // disabled={updatingUnsafe}
                              maxDate={dayjs()}
                              onValueChange={(newVal) => {
                                if (newVal)
                                  updateUnsafe(
                                    {
                                      corrected_on: dayjs(newVal).toISOString(),
                                    },
                                    `changed correction date to ${
                                      newVal
                                        ? dayjs(newVal).format("MMMM Do, YYYY")
                                        : ""
                                    }`,
                                  );
                              }}
                              value={
                                unsafeObservation["corrected_on"]
                                  ? dayjs(unsafeObservation["corrected_on"])
                                  : null
                              }
                            />
                          )}
                        </div>
                      </Card>
                    )}
                    {(unsafeObservation.due_on ||
                      !unsafeObservation.is_corrected) && (
                      <Card className="w-2/5">
                        <div className="flex gap-1 items-center">
                          <span className="text-semantic-negative">*</span> Due
                          Date:{" "}
                          {notEditing ? (
                            unsafeObservation["due_on"] ? (
                              dayjs(unsafeObservation["due_on"]).format(
                                "MMMM Do, YYYY",
                              )
                            ) : null
                          ) : (
                            <DesktopBirthDatePicker
                              // disabled={updatingUnsafe}
                              minDate={dayjs()}
                              onValueChange={(newVal) => {
                                if (newVal)
                                  updateUnsafe(
                                    { due_on: dayjs(newVal).toISOString() },
                                    `changed due date to ${
                                      newVal
                                        ? dayjs(newVal).format("MMMM Do, YYYY")
                                        : ""
                                    }`,
                                  );
                              }}
                              value={
                                unsafeObservation["due_on"]
                                  ? dayjs(unsafeObservation["due_on"])
                                  : null
                              }
                            />
                          )}
                        </div>
                      </Card>
                    )}

                    {unsafeObservation.is_corrected && (
                      <ObservationSelectUsers
                        {...{
                          isSubmitted,
                          observation,
                          optionsData,
                          type: "corrected_by",
                          key: "corrected_by",
                          notEditing,
                          subcontractorId,
                        }}
                      />
                    )}
                    {(!unsafeObservation.is_corrected ||
                      assignedToUsers.length > 0) && (
                      <ObservationSelectUsers
                        {...{
                          isSubmitted,
                          observation,
                          optionsData,
                          type: "assignee",
                          key: "assignee",
                          notEditing,
                          subcontractorId,
                        }}
                      />
                    )}

                    {unsafeObservation.is_corrected && (
                      <ObservationTextField
                        fieldName="corrective_action"
                        key={"corrective_action"}
                        fieldTypeKey="unsafe_observation"
                        label={"Describe the corrective action taken"}
                        observation={observation}
                        notEditing={notEditing}
                        text={unsafeObservation.corrective_action.en}
                        textId={unsafeObservation.corrective_action.id}
                      />
                    )}
                    {(!unsafeObservation.is_corrected ||
                      unsafeObservation.action.en.trim()) && (
                      <ObservationTextField
                        fieldName="action"
                        key={"action"}
                        required
                        fieldTypeKey="unsafe_observation"
                        label={"Please describe the action required"}
                        observation={observation}
                        notEditing={notEditing}
                        text={unsafeObservation.action.en}
                        textId={unsafeObservation.action.id}
                      />
                    )}
                    <ObservationTextField
                      fieldName="root_cause"
                      label={"Root cause of the problem"}
                      key={"root_cause"}
                      notEditing={notEditing}
                      fieldTypeKey="unsafe_observation"
                      observation={observation}
                      text={unsafeObservation.root_cause.en}
                      textId={unsafeObservation.root_cause.id}
                    />
                  </>
                )}
              </>
            )}

            <ObservationDocUploader
              docType="image"
              acceptedFiles=".png, .jpg, .jpeg"
              observation={observation}
              editing={!notEditing}
            />

            <ObservationDocUploader
              docType="other"
              editing={!notEditing}
              acceptedFiles="video/*, audio/*, application/pdf"
              observation={observation}
            />
          </div>
        </Card>
        <Space />
        {isSubmitted && (
          <Card title="Observation Edits">
            <Table
              dataSource={observation.observation_edits}
              columns={[
                {
                  title: "Edit Time",
                  dataIndex: ["created_at"],
                  render: (v) => dayjs(v).format("MMMM Do, YYYY, h:mm a"),
                },
                {
                  title: "Edited By",
                  dataIndex: ["edited_by_user", "name"],
                  render: (v) => v,
                },
                {
                  title: "Changes",
                  dataIndex: ["comment", "en"],
                  render: (v, r) =>
                    r.edit_type === "image_add" ? (
                      <>
                        Image added{" "}
                        <Image
                          className="z-0"
                          src={r.new_val!}
                          alt={"Image: "}
                          width={30}
                        />
                      </>
                    ) : r.edit_type === "image_removal" ? (
                      <>
                        Image Removed{" "}
                        <Image
                          width={30}
                          className="z-0"
                          src={r.prev_val!}
                          alt={"Image: "}
                        />
                      </>
                    ) : (
                      v
                    ),
                },
              ]}
            />
          </Card>
        )}
        {isSubmitted ? (
          <ObservationDistributedUserTable
            ref={distributionTableRef}
            where={{ observation_id: { _eq: observationId } }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default withCustomSuspense(ObservationDetail);
