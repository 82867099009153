import { Alert, Form, Modal, Select, TimePicker } from "antd";
import dayjs, { weekdays } from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import { parseTime } from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/timeEvents";
import {
  GetSubBlockFieldsDocument,
  GetSubBlockFieldsQuery,
  GetSubBlockFieldsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";

const timeOfDay = (time: dayjs.Dayjs): number =>
  time.valueOf() - time.startOf("day").valueOf();

type TimeRange = {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
};

export interface SitedeliveryTimeBlockModalValues {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  subcontractorIds: Array<string>;
  calendarIds: Array<string>;
  weekday: number;
}

interface SitedeliveryTimeBlockFormValues {
  timeRange: [dayjs.Dayjs, dayjs.Dayjs];
  subcontractorIds: string;
  calendarIds: Array<string>;
  weekday: Array<number>;
}

interface SitedeliveryTimeBlockValues {
  subBlockId: string;
  calendarsIds: Array<string>;
  subcontractorIds: Array<string>;
  time: TimeRange;
  weekdayMask: number;
}

const weekdayIndexes = [0, 1, 2, 3, 4, 5, 6];
const weekdayNames = dayjs.weekdays(false);

interface SitedeliveryTimeBlockModalProps {
  timeBlock?: SitedeliveryTimeBlockValues;
  onDelete?: (subBlockId: string) => any;
  onSubmit: (values: SitedeliveryTimeBlockModalValues) => any;
  modalClose: () => void;
  modalVisible: boolean;
  loading: boolean;
}

const SitedeliveryTimeBlockModal: React.FC<SitedeliveryTimeBlockModalProps> = (
  props,
) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("ProjectId param is missing");
  }

  const { data: subBlockFieldsData } = useSuspenseQuery<
    GetSubBlockFieldsQuery,
    GetSubBlockFieldsQueryVariables
  >(GetSubBlockFieldsDocument, {
    variables: {
      calendarWhere: {
        projects: {
          project_id: { _eq: projectId },
          is_archive: { _eq: false },
        },
      },
      subcontractorWhere: {
        subcontractor_projects: {
          project_id: { _eq: projectId },
        },
      },
      projectId: projectId,
    },
  });

  if (!subBlockFieldsData) {
    throw new Error("Subblock fields data is missing");
  }

  const subcontractorData = subBlockFieldsData.subcontractor;
  const calendarData = subBlockFieldsData.calendar;

  const defaultDays = weekdayIndexes.filter(
    (day) => ((1 << day) & (props.timeBlock?.weekdayMask ?? 0)) !== 0,
  );

  const otherSubBlockData =
    subBlockFieldsData.project_delivery_sub_block.filter(
      (e) => e.id !== props.timeBlock?.subBlockId,
    ) || [];

  const [statement, setStatement] = useState<string[]>([]);
  const handleTimeBlockSubmit = async () => {
    console.log("before validation");
    form
      .validateFields()
      .then(async (values: SitedeliveryTimeBlockFormValues) => {
        console.log("after validation");
        const overlapBlocksStatement: string[] = [];
        const weekdayMask = values.weekday.reduce(
          (a, day) => a + (1 << day),
          0,
        );
        const calendarIds = values.calendarIds;
        const startTime = values.timeRange[0];
        const endTime = values.timeRange[1];
        const startDayTime = parseTime(startTime.format("HH:mm"));
        const endDayTime = parseTime(endTime.format("HH:mm"));

        for (const subBlock of otherSubBlockData) {
          const commonDaysMask = weekdayMask & subBlock.weekday;

          if (commonDaysMask === 0) continue;
          const overlapingCalendars = subBlock.calendars
            .filter((c) => calendarIds.find((cid) => cid === c.calendar.id))
            .map((c) => c.calendar.name.en ?? c.calendar.id);

          if (overlapingCalendars.length === 0) continue;
          const overlapingWeekdays = weekdayIndexes.filter((day) => {
            const val =
              (commonDaysMask & (1 << day)) !== 0 &&
              parseTime(subBlock.start_time) < endDayTime &&
              parseTime(subBlock.end_time) > startDayTime;
            return val;
          });

          if (
            subBlock.subcontractors.length > 0 &&
            overlapingWeekdays.length > 0
          ) {
            const overlapingSub =
              subBlock.subcontractors[0].subcontractor?.name;
            overlapBlocksStatement.push(
              `Sub block overlaping with ${overlapingSub} block, having calendar ${overlapingCalendars.join(
                ", ",
              )} and time ${subBlock.start_time} - ${
                subBlock.end_time
              } on day ${overlapingWeekdays
                .map((day) => weekdayNames[day])
                .join(", ")}`,
            );
          }
        }
        setStatement(overlapBlocksStatement);

        if (overlapBlocksStatement.length === 0) {
          props.onSubmit({
            startTime: startTime,
            endTime: endTime,
            calendarIds,
            subcontractorIds: [values.subcontractorIds],
            weekday: weekdayMask,
          });
          props.modalClose();
          form.resetFields();
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  // console.log("SITE DELIVERY TIME BLOCK MODAL", props);

  return (
    <>
      <Modal
        title={props.timeBlock ? `Edit Sub Block` : `Add Sub Block`}
        open={props.modalVisible}
        onCancel={props.modalClose}
        onOk={handleTimeBlockSubmit}
        loading={props.loading}
        //okButtonProps={{ hidden: !timeRangeIsValid }}
      >
        <Form form={form} layout="vertical">
          {statement.length > 0 && (
            <Form.Item>
              {statement.map((e) => (
                <Alert message={e} type="warning" />
              ))}
            </Form.Item>
          )}
          <Form.Item
            name="subcontractorIds"
            label="Subcontractors"
            initialValue={props.timeBlock?.subcontractorIds[0]}
            rules={[{ required: true, message: "Select subcontractor" }]}
          >
            <Select
              listItemHeight={10}
              // mode="multiple"
              style={{
                width: "100%",
              }}
              variant="outlined"
              options={subcontractorData?.map((sub, i) => ({
                value: sub.id,
                key: sub.id,
                label: sub.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="calendarIds"
            label="Calendars"
            initialValue={props.timeBlock?.calendarsIds ?? []}
            rules={[{ required: true, message: "Select calendars" }]}
          >
            <Select
              listItemHeight={10}
              mode="multiple"
              style={{
                width: "100%",
              }}
              options={calendarData?.map((calendar, i) => ({
                value: calendar.id,
                key: calendar.id,
                label: calendar.name?.en ?? i,
              }))}
            />
          </Form.Item>
          <Form.Item
            name={"timeRange"}
            label={"Set the time range"}
            trigger="onChange"
            rules={[
              {
                validator: async (rule, value) => {
                  if (value[0] >= value[1])
                    return `The range must be at least 15 minutes long`;
                },
              },
            ]}
          >
            <TimePicker.RangePicker
              showSecond={false}
              showHour={true}
              showMinute={true}
              allowClear={false}
              showNow={false}
              minuteStep={15}
            />
          </Form.Item>
          <Form.Item
            name="weekday"
            label="Select day"
            initialValue={defaultDays}
            rules={[{ required: true, message: "Select day" }]}
          >
            <Select
              listItemHeight={10}
              style={{
                width: "50%",
              }}
              mode="multiple"
              options={weekdayIndexes.map((day) => ({
                value: day,
                key: day,
                label: weekdayNames[day],
              }))}
            />
          </Form.Item>
          {props.timeBlock && props.onDelete && (
            <Button
              tiny
              secondary
              onClick={() => {
                props.onDelete!(props.timeBlock!.subBlockId);
                props.modalClose();
              }}
              label={`Delete Block`}
            />
          )}
        </Form>
      </Modal>
    </>
  );
};

export default SitedeliveryTimeBlockModal;
