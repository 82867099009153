import { notification, Typography } from "antd";
import { DisconnectOutlined } from "@ant-design/icons";
import React from "react";
import contacts from "../../../common/constants/contacts";

const { Paragraph, Link } = Typography;

const showNetworkErrorMessage = () => {  
  notification.error({
    message: "Network Error",
    description: (
      <Paragraph>
        Please check your internet connection and try again.
        <br />
        If your internet connection is fine, please{" "}
        <Link
          href={`mailto:${contacts.supportEmail}?subject=Web App Issue`}
          target="_blank"
        >
          contact us
        </Link>
        .
      </Paragraph>
    ),
    icon: <DisconnectOutlined />,
  });
}

export default showNetworkErrorMessage;