class NotifyUserException extends Error {
  errorTitle?: string;

  constructor(errorMessage: string, errorTitle?: string) {
    super(errorMessage);
    this.name = "NotifyUserException"; 
    this.errorTitle = errorTitle;
  }
}

export default NotifyUserException;
