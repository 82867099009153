import BarChartToShow from "src/common/components/charts/BarChartsToShow";
import ChartTitleNumbers from "src/common/components/charts/ChartTitleNumbers";
import MapCoordinatePoints from "src/common/components/leaflet-maps/MapCoordinatePoints";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";
import {
  GetAgcDashboardDataQueryVariables,
  useGetAgcDashboardDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ZipMapDataType } from "src/domain-features/siteorientation/utils/setDemographicDataMaps";
import getDashboardDataMaps from "../../utils/getDashboardDataMaps";
import { useMemo } from "react";
import { Space } from "antd";
import ChartWrapperCard from "src/common/components/charts/ChartWrapperCard";
import ColumnChart from "src/common/components/charts/ColumnChart";
import colorList from "src/common/components/lists/chartColorList";
import AGCDashboardHeader, {
  AGCDashboardHeaderProps,
} from "../components/AGCDashboardHeader";

interface AGCDashboardProps {
  gcOptions: AGCDashboardHeaderProps["gcOptions"];
}

const AGCDashboard: React.FC<AGCDashboardProps> = ({ gcOptions }) => {
  const osha10Id = "2e934039-81f9-43c2-a8e3-b84031fc8c52";
  const osha30Id = "9ffe2c26-1a2e-4698-b96c-a772d1d55b9d";
  const variables: GetAgcDashboardDataQueryVariables = {
    oshaCertIds: [osha10Id, osha30Id],
    projWhere: { agc_universal_orientation: { _eq: true } },
    gcWhere: { agc_universal_orientation: { _eq: true } },
    projWorkerWhere: {
      project: { agc_universal_orientation: { _eq: true } },
      is_last: { _eq: true },
      user: {
        universal_orientations: {
          universal_orientated_at: { _is_null: false },
        },
      },
    },
    userWhere: {
      universal_orientations: {
        universal_orientated_at: { _is_null: false },
      },
    },
  };
  const { data, refetch, error, loading } = useGetAgcDashboardDataQuery({
    variables,
  });

  const {
    roleMap,
    veteranTypeMap,
    ethnicityMap,
    genderMap,
    zipMap,
    subsCount,
    tradeMap,
    stateMap,
    totalWorkers,
    yearsInConstructionMap,
    yearsWithEmployerMap,
  } = useMemo(() => getDashboardDataMaps(data), [data]);
  const sortedTradeData = useMemo(
    () =>
      [...tradeMap.values()]
        .sort((t1, t2) => t2.count - t1.count)
        .map((item, i) => ({
          value: item.count,
          xField: item.name,
          valueTitle: "Users",
          color: colorList[i],
        })),
    [tradeMap],
  );
  const sortedStateData = useMemo(
    () =>
      [...stateMap.values()]
        .sort((s1, s2) => s2.count - s1.count)
        .map((item, i) => ({
          value: item.count,
          xField: item.name,
          valueTitle: "Users",
          color: colorList[i],
        })),
    [stateMap],
  );
  if (loading) return <LargeTableSkeleton />;
  if (error) throw error;
  if (!data) throw new Error("Data not found");

  return (
    <>
      <AGCDashboardHeader
        variables={variables}
        gcOptions={gcOptions}
        refetch={refetch}
      />
      <ChartTitleNumbers
        items={[
          {
            title: "Total Orientations",
            info: data.user.length,
          },
          {
            title: "Subcontractors",
            info: subsCount,
          },
          {
            title: "Projects",
            info: data.project_aggregate.aggregate?.count || 0,
          },
          {
            title: "General Contractors",
            info: data.general_contractor_aggregate.aggregate?.count || 0,
          },
          {
            title: "OSHA Certifications",
            info: data.oshaCerts.aggregate?.count || 0,
          },
          {
            title: "All Certifications",
            info: data.worker_certification_aggregate.aggregate?.count || 0,
          },
          {
            title: "Orientations Saved",
            info:
              (data.project_worker_aggregate.aggregate?.count || 0) -
              (data.uniqueWorkersOnUoProjects.aggregate?.count || 0),
          },
        ]}
      />
      <Space />
      <div
        className={`w-full relative h-full bg-white rounded-2 overflow-y-auto z-0`}
      >
        <div className="flex flex-wrap">
          <div className="w-1/2">
            <BarChartToShow
              data={roleMap}
              title="Role"
              height={roleMap.size * 20}
            />
            <BarChartToShow data={genderMap} title="Gender" />
          </div>
          <ChartWrapperCard className={"w-1/2"} title="Residence by Zip Code">
            {!!zipMap.size && (
              <MapCoordinatePoints<ZipMapDataType>
                data={Array.from(zipMap.values())}
                height={700}
                renderPopup={(czip) =>
                  `Zip Code: ${czip.zip_code}, City: ${czip.city.name}, Count: ${czip.count}`
                }
              />
            )}
          </ChartWrapperCard>
          <ChartWrapperCard title={"Trade"} className="w-full">
            <ColumnChart dataSource={sortedTradeData} labelFontSize={17} />
          </ChartWrapperCard>
          <BarChartToShow
            data={yearsInConstructionMap}
            title="Years in Construction"
            height={yearsInConstructionMap.size * 20}
          />
          <BarChartToShow
            data={yearsWithEmployerMap}
            height={yearsWithEmployerMap.size * 20}
            title="Years with Employer"
          />
          <BarChartToShow
            data={ethnicityMap}
            title="Ethnicity"
            height={ethnicityMap.size * 20}
          />
          <BarChartToShow data={veteranTypeMap} title="Veteran" />

          <ChartWrapperCard title={"State"} className="w-full">
            <ColumnChart dataSource={sortedStateData} labelFontSize={17} />
          </ChartWrapperCard>
          {/*  {!!zipMap.size && (
            <div className="w-full flex">
         <ChartWrapperCard className={"w-1/2"} title="State Residency Map">
                {!!stateMap.size && (
                  <MapCoordinatePoints<StateMapDataType>
                    data={Array.from(stateMap.values())}
                    height={700}
                    renderPopup={(state) =>
                      `State: ${state.name}, ${state.code} Count: ${state.count}`
                    }
                  />
                )}
              </ChartWrapperCard> 
            </div>
          )}
    <BarChartToShow
            data={stateMap}
            height={stateMap.size * 20}
            title={`State`}
            full
          /> */}
        </div>
      </div>
    </>
  );
};
export default AGCDashboard;
