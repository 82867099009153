import React from "react";
import GCProjectReports from "./GCProjectReports";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import GCProjectReportsDownload from "./download/components/GCProjectReportsDownload";
import GCProjectSummaryList from "./project-summary/GCProjectSummaryList";
import { Navigate } from "react-router-dom";

const gcProjectReportsRoute: NestedRoute = {
  handle: {
    breadcrumb: "Reports",
  },
  element: <GCProjectReports />,
  children: [
    {
      path: "download",
      element: <GCProjectReportsDownload />,
    },
    {
      path: "summary",
      element: <GCProjectSummaryList />,
    },
    {
      path: "",
      element: <Navigate replace to={"download"} />,
    },
  ],
};

export default gcProjectReportsRoute;
