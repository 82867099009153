import GCProjObservationSettings from "../../../../../../../../domain-features/observation/entryRoutes/gc/GCProjObservationSettings";
import React from "react";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../../../../../common/types/manual/ProjectIdProps";

const GCProjectSettingsObservations: React.FC<ProjectIdProps> = (props) => {
  return <GCProjObservationSettings projectId={props.projectId} />;
};

export default withProjectIdUrlParam(GCProjectSettingsObservations);
