import * as React from "react";
import { forwardRef } from "react";
import { SafetyPlanTableQuery } from "src/common/types/generated/relay/SafetyPlanTableQuery.graphql";
import { SafetyPlanTable_safetyPlan$data } from "src/common/types/generated/relay/SafetyPlanTable_safetyPlan.graphql";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "../../../../common/components/tables/basic/DataScrollTable";
import getDateStr from "src/common/functions/dates/getDateStr";
import dayjs from "dayjs";
import { graphql } from "babel-plugin-relay/macro";

export type ColumnKeys =
  | "title"
  | "revision"
  | "revisionDate"
  | "addedDate"
  | "addedBy"
  | "replace"
  | "action"
  | "addToAllProjects"
  | "delete";
const CONNECTION_NAME = "safety_plan_connection";
type DConnection = SafetyPlanTable_safetyPlan$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SafetyPlanTableQuery,
  {}
> & {
  onSafetyPlanRowClick: (safetyPlan: safetyPlan) => void;
  searchString?: string;
  title: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SafetyPlanTableQuery
  >["columns"];
  loading: boolean;
};
export type safetyPlan =
  SafetyPlanTable_safetyPlan$data["safety_plan_connection"]["edges"][0]["node"];
const SafetyPlanTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      onSafetyPlanRowClick,
      searchString,
      where,
      topBarButtons,
      title,
      extraColumns,
      loading,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <DataScrollTable<DConnection, ColumnKeys, SafetyPlanTableQuery>
          {...props}
          newCustomTableLook
          ref={ref}
          title={title}
          loading={loading}
          onRowClick={(safetyPlan) =>
            onSafetyPlanRowClick && onSafetyPlanRowClick(safetyPlan)
          }
          where={where}
          topBarButtons={topBarButtons}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allSafetyPlanConnection"}
          columns={[
            {
              title: "Title",
              dataIndex: ["title"],
              key: "title",
              defaultSortOrder: "asc",
              searchDataIndex: ["title"],
              sortable: true,
              size: "ml",
            },
            {
              title: "Revision",
              dataIndex: ["revision_number"],
              key: "revision",
              defaultSortOrder: "asc",
              searchDataIndex: ["revision_number"],
              sortable: true,
              size: "sm",
              render: (num) => <>{num && <div>{num}</div>}</>,
            },
            {
              title: "Revision Date",
              dataIndex: ["revision_date"],
              key: "revisionDate",
              defaultSortOrder: "asc",
              searchDataIndex: ["revision_date"],
              sortable: true,
              size: "ml",
              render: (date) => (
                <>
                  {date && (
                    <div>{getDateStr(dayjs(date), { format: "full" })}</div>
                  )}
                </>
              ),
            },
            {
              title: "Date Added",
              dataIndex: ["created_at"],
              key: "addedDate",
              searchDataIndex: ["created_at"],
              size: "ml",
              sortable: true,
              render: (date) => (
                <div>{getDateStr(dayjs(date), { format: "full" })}</div>
              ),
            },
            {
              title: "Added By",
              dataIndex: ["created_by_user", "name"],
              key: "addedBy",
              searchDataIndex: ["created_by_user", "name"],
              size: "md",
              sortable: true,
            },
            ...(extraColumns || []),
          ]}
          queryNode={graphql`
            query SafetyPlanTableQuery(
              $first: Int!
              $after: String
              $where: safety_plan_bool_exp!
              $order_by: [safety_plan_order_by!]!
            ) {
              ...SafetyPlanTable_safetyPlan
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...SafetyPlanTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment SafetyPlanTable_total on query_root
            @argumentDefinitions(where: { type: "safety_plan_bool_exp!" })
            @refetchable(queryName: "SafetyPlanTotalRefetchableQuery") {
              allsafety_planConnection: safety_plan_connection(where: $where) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment SafetyPlanTable_safetyPlan on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "safety_plan_bool_exp!" }
              order_by: { type: "[safety_plan_order_by!]!" }
            )
            @refetchable(queryName: "SafetyPlanTableRefetchableQuery") {
              safety_plan_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "SafetyPlanTable_safety_plan_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    ...SafetyPlanFrag @relay(mask: false)
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default SafetyPlanTable;
