import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import LoadingContent from "../general/loading-fallback/LoadingContent";
import {
  CameraOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Webcam from "react-webcam";
import { Button } from "antd";
import { motion } from "framer-motion";

export interface FullScreenCameraProps {
  onClose: () => void;
  multiple?: boolean;
  onCapture: (file: string[]) => Promise<void> | void; // for single Image there will be only one element in list
  facingMode?: MediaTrackConstraints["facingMode"];
  photoDimensions: {
    width: number;
    height: number;
  };
}

function useOutsideClick(
  ref: MutableRefObject<HTMLElement | null>,
  callBack: () => void,
) {
  useEffect(() => {
    //  Run callback if clicked outside the element
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        event.target instanceof HTMLElement &&
        !ref.current.contains(event?.target)
      ) {
        callBack();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const FullScreenCamera: React.FC<FullScreenCameraProps> = (props) => {
  const webcamRef = useRef<any>(null);
  const [capturedImages, setCapturedImages] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const videoConstraints: MediaTrackConstraints = {
    facingMode: props.facingMode ?? "environment",
  };
  const cameraWrapperRef = useRef<HTMLDivElement>(null);
  const [cameraWrapperSize, setCameraWrapperSize] = useState<null | {
    width: number;
    height: number;
  }>(null);
  const capture = React.useCallback(() => {
    const screenshot = webcamRef.current.getScreenshot();
    const img = new Image();
    img.src = screenshot;

    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = props.photoDimensions.width;
      canvas.height = props.photoDimensions.height;
      const ctx = canvas.getContext("2d");

      // Define how much the image should be scaled
      // Set scale to the maximum of corresponding size ratios
      const scale = Math.max(
        canvas.width / img.width,
        canvas.height / img.height,
      );

      // Scaled image size
      const scaledWidth = img.width * scale;
      const scaledHeight = img.height * scale;
      // Size of cropped part of image
      const croppedWidth = canvas.width - scaledWidth;
      const croppedHeight = canvas.height - scaledHeight;

      // Pass the cropping offsets and dimensions to drawImage
      ctx?.drawImage(
        img,
        0, //image x
        0, //image y
        img.width, //image width
        img.height, //image height
        croppedWidth / 2, //image delta x
        croppedHeight / 2, //image delta y
        scaledWidth, //image width scaled to canvas
        scaledHeight, //image height scaled to canvas
      );

      const finalScreenshot = canvas.toDataURL("image/jpeg"); // Convert back to base64
      // Now you can upload finalScreenshot to your backend
      if (props.multiple) {
        setCapturedImages((prev) => [...prev, finalScreenshot]);
      } else props.onCapture([finalScreenshot]);
    };
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, props.onClose);

  const photoAspectRatio =
    props.photoDimensions.width / props.photoDimensions.height;
  const cameraWrapperAspectRatio = cameraWrapperSize
    ? cameraWrapperSize.width / cameraWrapperSize.height
    : 1;

  useEffect(() => {
    const onResize = () => {
      const cameraWrapperRefSize =
        cameraWrapperRef.current?.getBoundingClientRect();
      if (cameraWrapperRefSize) {
        setCameraWrapperSize({
          width: cameraWrapperRefSize.width,
          height: cameraWrapperRefSize.height,
        });
      }
    };
    onResize();
    window?.addEventListener("resize", onResize);
    return () => {
      window?.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className={`w-full h-full fixed inset-0 z-50`}>
      <div className={`w-full h-full flex flex-col`}>
        {/* Camera UI */}
        <div className={`flex-1 relative z-0`}>
          <div
            ref={cameraWrapperRef}
            className={`absolute w-full h-full bg-static-primary`}
          >
            <motion.div
              className={`w-full h-full relative z-10`}
              animate={loading ? "initial" : "animate"}
              variants={{
                initial: {
                  opacity: 0,
                },
                animate: {
                  opacity: 1,
                },
              }}
              initial={"initial"}
            >
              <Webcam
                forceScreenshotSourceSize
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMedia={() => {
                  setLoading(false);
                }}
                screenshotQuality={1}
                mirrored={videoConstraints.facingMode === "user"}
                className={`object-cover w-full h-full`}
              />

              <div
                className={`w-full h-full absolute inset-0 items-center justify-center flex`}
              >
                {cameraWrapperAspectRatio < photoAspectRatio ? (
                  <div className={`relative w-full`}>
                    <div
                      className={`shadow-screen h-0 rounded-1 w-full`}
                      style={{
                        paddingBottom: `${(1 / photoAspectRatio) * 100}%`,
                      }}
                    ></div>
                  </div>
                ) : (
                  <div
                    className={`relative h-full shadow-screen rounded-1`}
                    style={{
                      width: `${
                        (photoAspectRatio / cameraWrapperAspectRatio) * 100
                      }%`,
                    }}
                  ></div>
                )}
              </div>
            </motion.div>

            {loading && (
              <div
                className={`z-0 absolute flex items-center justify-center inset-0 m-auto`}
                ref={ref}
              >
                <LoadingContent />
              </div>
            )}
          </div>
        </div>

        {/* Bottom Buttons UI */}
        <div
          className={`relative z-10 p-0.5 desktop:p-1 bottom-0 inset-x-0 w-full flex flex-col items-center bg-static-primary`}
        >
          <div className={`w-full flex flex-row items-center gap-0.5 max-w-32`}>
            <div>
              <Button
                disabled={loading}
                icon={<CloseOutlined />}
                onClick={props.onClose}
              />
            </div>
            <Button
              block
              type={
                props.multiple && capturedImages.length ? undefined : `primary`
              }
              loading={loading}
              disabled={loading}
              icon={<CameraOutlined />}
              onClick={(e) => {
                if (loading) {
                  return;
                }
                capture();
                if (!props.multiple) {
                  props.onClose();
                }
                e.preventDefault();
              }}
            >
              {props.multiple
                ? capturedImages.length
                  ? "Add Another +"
                  : "Capture"
                : "Capture"}
            </Button>
            {props.multiple && capturedImages.length && (
              <div>
                <Button
                  icon={<CheckOutlined />}
                  type="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={async (e) => {
                    try {
                      setLoading(true);
                      await props.onCapture(capturedImages);
                    } finally {
                      setLoading(false);
                    }
                    e.preventDefault();
                  }}
                >
                  Done ({capturedImages.length})
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenCamera;
