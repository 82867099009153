import { FC } from "react";
import IncidentUser from "./incident-users/IncidentUser";
import { Button, Space } from "antd";
import { useSearchParams } from "react-router-dom";
import InjuryDetail from "./incident-users/InjuryDetail";
import InsuranceDetail from "./incident-users/InsuranceDetail";
import IncidentTypeProps from "../utils/IncidentTypeProps";
import useIncidentUserMutations from "src/domain-features/incident-management/utils/useIncidentUserMutations";
import IncidentSubViewLayout from "./IncidentSubViewLayout";

const IncidentManagementInjury: FC<IncidentTypeProps> = ({
  incident,
  refetch,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const injuredUserId = searchParams.get("id");
  const { insertIncidentUser } = useIncidentUserMutations(
    "injured",
    incident,
    refetch,
  );

  return (
    <IncidentSubViewLayout title="Injured Person Details">
      <div className="flex flex-col gap-1">
        <IncidentUser
          key={injuredUserId}
          title="Injured Person"
          incident={incident}
          incidentUserId={injuredUserId ?? undefined}
          onUpdateUser={async (
            id: string,
            name: string,
            subcontractorId?: string,
          ) => {
            insertIncidentUser(id, name, subcontractorId);
          }}
          userType="injured-user"
        />

        {injuredUserId && (
          <>
            <InjuryDetail
              key={`injury_detail ${injuredUserId}`}
              incident={incident}
              injuredUserId={injuredUserId}
            />
            <InsuranceDetail
              key={`insurance_detail ${injuredUserId}`}
              incident={incident}
              injuredUserId={injuredUserId}
            />
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  const index =
                    incident.injured_users.findIndex(
                      (injuredUser) => injuredUser.id === injuredUserId,
                    ) ?? 0;
                  if (index + 1 < (incident.injured_users.length ?? 0)) {
                    const nextInjuredUserId =
                      incident.injured_users[index + 1].id;
                    if (nextInjuredUserId) {
                      setSearchParams({
                        subview: "injured_user",
                        id: nextInjuredUserId,
                      });
                    }
                  } else {
                    setSearchParams({ subview: "root_cause" });
                  }
                }}
              >
                Save
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setSearchParams({ subview: "injured_user" })}
              >
                Next
              </Button>
            </Space>
          </>
        )}
      </div>
    </IncidentSubViewLayout>
  );
};

export default IncidentManagementInjury;
