import React, { useContext } from "react";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import ptpQrCodeUserContext from "src/domain-features/sitesafety/entry-routes/qr-project-reports/routes/ptp/utils/ptpQrUserContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface PtpSubmitProps extends SiteOrientationStepProps {}

const PtpQrSubmitPage: React.FC<PtpSubmitProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const workerContext = useContext(ptpQrCodeUserContext);
  if (!workerContext) throw new Error("No context found to be used");

  return (
    <SiteFeatureStepLayout
      backButtonText="Start Over"
      refresh={true}
      onBackButtonClick={() => window.location.reload()}
    >
      <SiteFeatureStepsInputLayout>
        <div className="flex flex-col justify-center items-center text-1.25">
          <div> {langStrings.strings.niceWork} </div>{" "}
          <div>{langStrings.strings.ptpSubmitted} 🚧🎉</div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrSubmitPage;
