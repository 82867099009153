import React, { useState } from "react";
import GCProjectReportsRow from "./GCProjectReportsRow";
import MorningManPowerReportCreator from "./MorningManPowerReportCreator";
import { GCProjectReportsDownloadQuery$data } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import withCustomSuspense from "../../../../../../common/components/general/withCustomSuspense";

const MorningManPowerReportWidget: React.FC<{
  projectId: string;
  data: GCProjectReportsDownloadQuery$data;
}> = ({ projectId, data }) => {
  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);

  return (
    <>
      <GCProjectReportsRow
        title="Morning Manpower Report"
        subtitle="Daily email that includes the trades onsite and their reported manpower"
        handleClick={() => {
          setReportDrawerVisible(true);
        }}
      />
      <MorningManPowerReportCreator
        visible={reportDrawerVisible}
        projectId={projectId}
        handleClose={() => setReportDrawerVisible(false)}
        data={data}
      />
    </>
  );
};

export default withCustomSuspense(MorningManPowerReportWidget);
