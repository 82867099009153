import {
  ConnectionHandler,
  RecordProxy,
  RecordSourceSelectorProxy,
} from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsCrew_TBTNotRequire_UpsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_TBTNotRequire_UpsertMutation.graphql";
import { usePermanentReportRequireSettingsCrew_DRNotRequire_UpsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_DRNotRequire_UpsertMutation.graphql";
import { usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation.graphql";
import { usePermanentReportRequireSettingsSub_TBTNotRequire_UpsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_TBTNotRequire_UpsertMutation.graphql";
import { usePermanentReportRequireSettingsSub_DRNotRequire_UpsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_DRNotRequire_UpsertMutation.graphql";
import { usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation.graphql";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import GetFullID from "src/common/functions/GetFullId";
import dayjs from "dayjs";

const updateCrewTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation(
    $currentDate: date!
    $where: crew_toolbox_talk_not_require_period_bool_exp!
  ) {
    update_crew_toolbox_talk_not_require_period(
      where: $where
      _set: { close_date: $currentDate }
    ) {
      returning {
        id
      }
    }
  }
`;

const updateCrewDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation(
    $currentDate: date!
    $where: crew_daily_report_not_require_period_bool_exp!
  ) {
    update_crew_daily_report_not_require_period(
      where: $where
      _set: { close_date: $currentDate }
    ) {
      returning {
        id
      }
    }
  }
`;
const updateCrewPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation(
    $currentDate: date!
    $where: crew_safety_report_not_require_period_bool_exp!
  ) {
    update_crew_safety_report_not_require_period(
      where: $where
      _set: { close_date: $currentDate }
    ) {
      returning {
        id
      }
    }
  }
`;

const updateSubTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation(
    $currentDate: date!
    $where: project_subcontractor_toolbox_talk_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_toolbox_talk_not_require_period(
      where: $where
      _set: { close_date: $currentDate }
    ) {
      returning {
        id
      }
    }
  }
`;

const updateSubDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation(
    $currentDate: date!
    $where: project_subcontractor_daily_report_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_daily_report_not_require_period(
      where: $where
      _set: { close_date: $currentDate }
    ) {
      returning {
        id
      }
    }
  }
`;
const updateSubPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation(
    $currentDate: date!
    $where: project_subcontractor_safety_report_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_safety_report_not_require_period(
      where: $where
      _set: { close_date: $currentDate }
    ) {
      returning {
        id
      }
    }
  }
`;
const upsertCrewTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_TBTNotRequire_UpsertMutation(
    $objects: [crew_toolbox_talk_not_require_period_insert_input!]!
  ) {
    insert_crew_toolbox_talk_not_require_period(
      objects: $objects
      on_conflict: {
        constraint: crew_toolbox_talk_not_require_pe_project_crew_id_start_date_key
        update_columns: [close_date]
      }
    ) {
      returning {
        project_crew_id
        id
      }
    }
  }
`;

const upsertCrewDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_DRNotRequire_UpsertMutation(
    $objects: [crew_daily_report_not_require_period_insert_input!]!
  ) {
    insert_crew_daily_report_not_require_period(
      objects: $objects
      on_conflict: {
        constraint: crew_daily_report_not_require_pe_project_crew_id_start_date_key
        update_columns: [close_date]
      }
    ) {
      returning {
        project_crew_id
        id
      }
    }
  }
`;
const upsertCrewPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation(
    $objects: [crew_safety_report_not_require_period_insert_input!]!
  ) {
    insert_crew_safety_report_not_require_period(
      objects: $objects
      on_conflict: {
        constraint: crew_safety_report_not_require_p_project_crew_id_start_date_key
        update_columns: [close_date]
      }
    ) {
      returning {
        project_crew_id
        id
      }
    }
  }
`;

const upsertSubTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_TBTNotRequire_UpsertMutation(
    $object: project_subcontractor_toolbox_talk_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_toolbox_talk_not_require_period_one(
      object: $object
      on_conflict: {
        constraint: project_subcontractor_toolbox_talk_not_require_period_project_i
        update_columns: [close_date]
      }
    ) {
      id
    }
  }
`;

const upsertSubDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_DRNotRequire_UpsertMutation(
    $object: project_subcontractor_daily_report_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_daily_report_not_require_period_one(
      object: $object
      on_conflict: {
        constraint: project_subcontractor_daily_report_not_require_period_project_i
        update_columns: [close_date]
      }
    ) {
      id
    }
  }
`;
const upsertSubPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation(
    $object: project_subcontractor_safety_report_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_safety_report_not_require_period_one(
      object: $object
      on_conflict: {
        constraint: project_subcontractor_safety_report_not_require_period_project_
        update_columns: [close_date]
      }
    ) {
      id
    }
  }
`;

const usePermanentReportRequireSettings = () => {
  const [updateSubTBTNotRequire, loading1] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation>(
      updateSubTBTNotRequireMutation,
    );
  const [updateSubDRNotRequire, loading2] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation>(
      updateSubDRNotRequireMutation,
    );
  const [updateSubPTPNotRequire, loading3] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation>(
      updateSubPTPNotRequireMutation,
    );
  const [upsertSubTBTNotRequire, loading4] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_TBTNotRequire_UpsertMutation>(
      upsertSubTBTNotRequireMutation,
    );
  const [upsertSubDRNotRequire, loading5] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_DRNotRequire_UpsertMutation>(
      upsertSubDRNotRequireMutation,
    );
  const [upsertSubPTPNotRequire, loading6] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation>(
      upsertSubPTPNotRequireMutation,
    );
  const [updateCrewTBTNotRequire, loading7] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation>(
      updateCrewTBTNotRequireMutation,
    );
  const [updateCrewDRNotRequire, loading8] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation>(
      updateCrewDRNotRequireMutation,
    );
  const [updateCrewPTPNotRequire, loading9] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation>(
      updateCrewPTPNotRequireMutation,
    );
  const [upsertCrewTBTNotRequire, loading10] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_TBTNotRequire_UpsertMutation>(
      upsertCrewTBTNotRequireMutation,
    );
  const [upsertCrewDRNotRequire, loading11] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_DRNotRequire_UpsertMutation>(
      upsertCrewDRNotRequireMutation,
    );
  const [upsertCrewPTPNotRequire, loading12] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation>(
      upsertCrewPTPNotRequireMutation,
    );

  const subNotRequirePeriodUpdater = ({
    store,
    subId,
    newRecords,
    type,
  }: {
    store: RecordSourceSelectorProxy;
    subId: string;
    newRecords?: Array<
      RecordProxy<{
        readonly id: string;
      }>
    >;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "useGetReportManagementDataQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];
      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find((ps) => {
            const toReturn = ps.getValue("subcontractor_id") === subId;
            return toReturn;
          });

          if (projSub) {
            projSub.setLinkedRecords(
              newRecords || [],
              `project_subcontractor_${type}_not_require_periods`,
              { where: { close_date: { _is_null: true } } },
            );
          }
        }
      }
    }
  };

  const crewNotRequirePeriodUpdater = ({
    store,
    subId,
    crewIds,
    newRecords,
    type,
  }: {
    store: RecordSourceSelectorProxy;
    subId: string;
    crewIds: string[];
    newRecords?: Array<
      RecordProxy<{
        readonly id: string;
        readonly project_crew_id: string;
      }>
    >;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "useGetReportManagementDataQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];
      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find((ps) => {
            const toReturn = ps.getValue("subcontractor_id") === subId;
            return toReturn;
          });

          if (projSub) {
            const projCrews =
              projSub.getLinkedRecords("project_crews", {
                order_by: { name: Order_By.Asc },
              }) || [];
            crewIds.forEach((crewId) => {
              const projCrew = projCrews.find(
                (pc) => pc.getValue("id") == GetFullID("project_crew", crewId),
              );
              if (projCrew) {
                projCrew.setLinkedRecords(
                  (newRecords || []).filter(
                    (rec) => rec.getValue("project_crew_id") === crewId,
                  ),
                  `crew_${type}_not_require_periods`,
                  { where: { close_date: { _is_null: true } } },
                );
              }
            });
          }
        }
      }
    }
  };

  const setSubRequire = async ({
    projectId,
    subId,
    type,
  }: {
    projectId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    const variables = {
      where: {
        project_id: { _eq: projectId },
        subcontractor_id: { _eq: subId },
      },
      currentDate: dayjs().format("YYYY-MM-DD"),
    };
    if (type === "toolbox_talk") {
      await updateSubTBTNotRequire({
        variables,
        updater: (store) => subNotRequirePeriodUpdater({ store, subId, type }),
      });
    } else if (type === "daily_report") {
      await updateSubDRNotRequire({
        variables,
        updater: (store) => subNotRequirePeriodUpdater({ store, subId, type }),
      });
    } else if (type === "safety_report") {
      await updateSubPTPNotRequire({
        variables,
        updater: (store) => subNotRequirePeriodUpdater({ store, subId, type }),
      });
    }
  };
  const setSubNotRequire = async ({
    projectId,
    subId,
    type,
  }: {
    projectId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    const variables = {
      object: {
        project_id: projectId,
        subcontractor_id: subId,
        close_date: null,
        start_date: dayjs().format("YYYY-MM-DD"),
      },
    };
    if (type === "toolbox_talk") {
      await upsertSubTBTNotRequire({
        variables,
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_toolbox_talk_not_require_period_one",
          );
          subNotRequirePeriodUpdater({
            store,
            subId,
            newRecords: [insertedVal],
            type,
          });
        },
      });
    } else if (type === "daily_report") {
      await upsertSubDRNotRequire({
        variables,
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_daily_report_not_require_period_one",
          );
          subNotRequirePeriodUpdater({
            store,
            subId,
            newRecords: [insertedVal],
            type,
          });
        },
      });
    } else if (type === "safety_report") {
      await upsertSubPTPNotRequire({
        variables,
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_safety_report_not_require_period_one",
          );
          subNotRequirePeriodUpdater({
            store,
            subId,
            newRecords: [insertedVal],
            type,
          });
        },
      });
    }
  };
  const setCrewRequire = async ({
    crewId,
    subId,
    type,
  }: {
    crewId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    const variables = {
      where: { project_crew_id: { _eq: crewId } },
      currentDate: dayjs().format("YYYY-MM-DD"),
    };
    if (type === "toolbox_talk") {
      await updateCrewTBTNotRequire({
        variables,
        updater: (store) => {
          crewNotRequirePeriodUpdater({
            store,
            subId,
            crewIds: [crewId],

            type,
          });
        },
      });
    } else if (type === "daily_report") {
      await updateCrewDRNotRequire({
        variables,
        updater: (store) => {
          crewNotRequirePeriodUpdater({
            store,
            subId,
            crewIds: [crewId],

            type,
          });
        },
      });
    } else if (type === "safety_report") {
      await updateCrewPTPNotRequire({
        variables,
        updater: (store) => {
          crewNotRequirePeriodUpdater({
            store,
            subId,
            crewIds: [crewId],

            type,
          });
        },
      });
    }
  };
  const setCrewNotRequire = async ({
    crewIds,
    subId,
    type,
  }: {
    crewIds: string[];
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    const variables = {
      objects: crewIds.map((crewId) => ({
        project_crew_id: crewId,
        close_date: null,
        start_date: dayjs().format("YYYY-MM-DD"),
      })),
    };
    if (type === "toolbox_talk") {
      await upsertCrewTBTNotRequire({
        variables,
        updater: (store) => {
          const newRecords =
            store
              .getRootField("insert_crew_toolbox_talk_not_require_period")
              ?.getLinkedRecords("returning") || [];
          crewNotRequirePeriodUpdater({
            store,
            subId,
            crewIds,
            newRecords,
            type,
          });
        },
      });
    } else if (type === "daily_report") {
      await upsertCrewDRNotRequire({
        variables,
        updater: (store) => {
          const newRecords =
            store
              .getRootField("insert_crew_daily_report_not_require_period")
              ?.getLinkedRecords("returning") || [];
          crewNotRequirePeriodUpdater({
            store,
            subId,
            crewIds,
            newRecords,
            type,
          });
        },
      });
    } else if (type === "safety_report") {
      await upsertCrewPTPNotRequire({
        variables,
        updater: (store) => {
          const newRecords =
            store
              .getRootField("insert_crew_safety_report_not_require_period")
              ?.getLinkedRecords("returning") || [];
          crewNotRequirePeriodUpdater({
            store,
            subId,
            crewIds,
            newRecords,
            type,
          });
        },
      });
    }
  };
  return {
    setSubRequire,
    setSubNotRequire,
    setCrewRequire,
    setCrewNotRequire,
  };
};
export default usePermanentReportRequireSettings;
