import GCIncidentCharts from "src/domain-features/incident-management/components/chart/GCIncidentCharts";
import { IncidentSeverityMappingProps } from "./IncidentSeverityMapping";
import { Flex } from "antd";
import { useGetIncidentReportAndChartOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import GCAdminIncidentsHeader from "./header/GCAdminIncidentsHeader";
import useGetGCAdminCommonFilters from "../../utils/useGetGCAdminCommonFilters";
import useGetIncidentFilters from "./utils/useGetIncidentFilters";
import { OrganizationLevelName } from "../../utils/hierarchyTypes";
import getOrgEmployeeWhere from "../../utils/getOrgEmployeeWhere";
import useGetIncidentOptionsQueryVars from "./utils/useGetIncidentOptionsQueryVars";
import getOrgProjectsWhere from "../../utils/getOrgProjectsWhere";

const GCAdminIncidentsOverview: React.FC<
  Omit<IncidentSeverityMappingProps, "gcEmployeeWhere"> & {
    organizationLevel: OrganizationLevelName;
    organizationId: string;
  }
> = ({
  severityUserFilter,
  toInsertWith,
  organizationId,
  organizationLevel,
}) => {
  const projWhere = getOrgProjectsWhere(organizationLevel, organizationId);

  const optionsQueryVars = useGetIncidentOptionsQueryVars(
    organizationLevel,
    organizationId,
  );
  const {
    data: options,
    error: optionsError,
    loading: optionsLoading,
  } = useGetIncidentReportAndChartOptionsQuery({
    variables: optionsQueryVars,
    fetchPolicy: "cache-first",
  });
  const gcEmployeeWhere = getOrgEmployeeWhere(
    organizationLevel,
    organizationId,
  );

  if (optionsError) throw optionsError;
  const {
    selectFilters: incidentSelectFilters,
    bodyPartsFilter,
    dateFilter,
    incidentTypesFilter,
    injuryCauseFilter,
    injuryTypesFilter,
    companiesFilter,
    severityFilter,
    setDateFilter,
  } = useGetIncidentFilters(options);
  const {
    radioFilters,
    selectFilters: commonSelectFilters,
    projectStatusFilter,
    projectsFilter,
    businessUnitsFilter,
    divisionsFilter,
    officesFilter,
  } = useGetGCAdminCommonFilters(options);
  return (
    <Flex gap={"large"} vertical>
      <GCAdminIncidentsHeader
        reportCreatorButtonProps={{
          options,
          organizationLevel,
          organizationId,
        }}
        filters={{
          radioFilters: [...radioFilters],
          selectFilters: [...commonSelectFilters, ...incidentSelectFilters],
          dates: {
            selectedDates: dateFilter,
            onSelectedDatesChange: setDateFilter,
          },
        }}
      />

      <GCIncidentCharts
        projWhere={projWhere}
        optionsLoading={optionsLoading}
        options={options}
        currentFilters={{
          bodyPartsFilter,
          companiesFilter,
          dateFilter,
          incidentTypesFilter,
          severityFilter,
          projectStatusFilter,
          injuryCauseFilter,
          injuryTypesFilter,
          projectsFilter,
          businessUnitsFilter,
          divisionsFilter,
          officesFilter,
        }}
      />
    </Flex>
  );
};
export default GCAdminIncidentsOverview;
