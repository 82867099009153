/**
 * @generated SignedSource<<4720e4c34e7f3e8e1905572769034dfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { document_bool_exp, document_set_input, task_bool_exp, task_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyAndAddJHAs_Insert_Task_Mutation$variables = {
  _set: document_set_input;
  deleteTaskWhere: task_bool_exp;
  objects: ReadonlyArray<task_insert_input>;
  requestId: string;
  where: document_bool_exp;
};
export type VerifyAndAddJHAs_Insert_Task_Mutation$data = {
  readonly delete_task: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_task: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_add_task_request: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_document: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type VerifyAndAddJHAs_Insert_Task_Mutation = {
  response: VerifyAndAddJHAs_Insert_Task_Mutation$data;
  variables: VerifyAndAddJHAs_Insert_Task_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteTaskWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requestId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "document_mutation_response",
    "kind": "LinkedField",
    "name": "update_document",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "task_mutation_response",
    "kind": "LinkedField",
    "name": "insert_task",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "deleteTaskWhere"
      }
    ],
    "concreteType": "task_mutation_response",
    "kind": "LinkedField",
    "name": "delete_task",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "rejected_at": null,
          "rejected_by_uid": null
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "requestId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "add_task_request_mutation_response",
    "kind": "LinkedField",
    "name": "update_add_task_request",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyAndAddJHAs_Insert_Task_Mutation",
    "selections": (v6/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerifyAndAddJHAs_Insert_Task_Mutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "6cd5a4c612937c7099dea2177aa35685",
    "id": null,
    "metadata": {},
    "name": "VerifyAndAddJHAs_Insert_Task_Mutation",
    "operationKind": "mutation",
    "text": "mutation VerifyAndAddJHAs_Insert_Task_Mutation(\n  $where: document_bool_exp!\n  $objects: [task_insert_input!]!\n  $_set: document_set_input!\n  $deleteTaskWhere: task_bool_exp!\n  $requestId: uuid!\n) {\n  update_document(where: $where, _set: $_set) {\n    affected_rows\n  }\n  insert_task(objects: $objects) {\n    affected_rows\n  }\n  delete_task(where: $deleteTaskWhere) {\n    affected_rows\n  }\n  update_add_task_request(where: {id: {_eq: $requestId}}, _set: {rejected_at: null, rejected_by_uid: null}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "48e89f7cd9fd9e74e178bc8d409b818e";

export default node;
