import AppPageLayout from "../../../../common/components/layouts/main/content/AppPageLayout";
import { useNavigate } from "react-router-dom";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import GCProjectReportsPTPsTable from "./components/GCProjectReportsPTPsTable";
import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";
import { SettingOutlined } from "@ant-design/icons";

const GCProjectPreTaskPlans: React.FC<ProjectIdProps> = (props) => {
  const navigate = useNavigate();
  const goToSettings = () => {
    navigate(getGcProjectSettingsPath(props.projectId, "ptp"));
  };
  return (
    <AppPageLayout
      pageTitle={"Pre-Task Plans"}
      pageActions={[
        {
          children: "Settings",
          onClick: goToSettings,
          icon: <SettingOutlined />,
        },
      ]}
    >
      <GCProjectReportsPTPsTable />
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCProjectPreTaskPlans);
