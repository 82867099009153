import GCSendInviteModal, {
  GCSendInviteModalRef,
} from "src/utility-features/invitations/GCSendInviteModal";
import {
  Order_By,
  useGetProjectSubcontractorsQuery,
  useUpdateSubcontractorEmployeeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { FC, useEffect, useRef, useState } from "react";
import { Button, Switch, Table } from "antd";

import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";

import { useParams } from "react-router-dom";

interface SubcontractorAdmin {
  id: string;
  subcontractorName: string;
  name: string;
  title?: string | null;
  email?: string | null;
  phone?: string | null;
  autoEmailsEnabled: boolean;
}

const GCProjectEmployeeVerification: FC = () => {
  const projectId = useParams()["projectId"] as string;
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [subAdmins, setSubAdmins] = useState<Array<SubcontractorAdmin>>([]);
  const sendInvite = useRef<GCSendInviteModalRef>(null);

  const [updateSubcontractorEmployee] =
    useUpdateSubcontractorEmployeeMutation();
  const { data, loading } = useGetProjectSubcontractorsQuery({
    variables: {
      where: { project_id: { _eq: projectId } },
      order_by: [{ subcontractor: { name: Order_By.Asc } }],
    },
  });

  useEffect(() => {
    if (data) {
      setSubAdmins(
        data.project_subcontractor
          .flatMap(
            (projectSubcontractor) =>
              projectSubcontractor.subcontractor.subcontractor_employees,
          )
          .map((subAdmin) => {
            return {
              id: subAdmin.user.id,
              subcontractorName: subAdmin.subcontractor.name,
              name: subAdmin.user.name,
              title: subAdmin.employee_title
                ? subAdmin.employee_title.name.en
                : "",
              email: subAdmin.user.email,
              phone: subAdmin.user.phone_number,
              autoEmailsEnabled: subAdmin.employee_verification_emails_enabled,
            };
          }),
      );
    }
  }, [data]);

  return loading ? (
    <LoadingContent />
  ) : (
    <div className="flex flex-col gap-1 w-full">
      <GCSendInviteModal ref={sendInvite} projectId={projectId} />
      <div className="flex flex-row items-center gap-1">
        <Button onClick={() => sendInvite.current?.open()}>
          Invite Subcontractor Administrator
        </Button>
      </div>
      <div className="flex flex-row w-full h-full overflow-y-scroll">
        <Table
          bordered
          title={() => (
            <>
              Subcontractor Administrators Performing Verification{" "}
              {subAdmins.length > 0 ? ` (${subAdmins.length})` : ""}
            </>
          )}
          rowKey="id"
          className="w-full"
          dataSource={subAdmins}
          pagination={false}
          columns={[
            {
              title: "Send Emails",
              dataIndex: ["autoEmailsEnabled"],
              render: (value, record, index) => {
                return (
                  <div>
                    <Switch
                      loading={loadings[index]}
                      onChange={(newValue) => {
                        setLoadings((prevLoadings) => {
                          const newLoadings = [...prevLoadings];
                          newLoadings[index] = true;
                          return newLoadings;
                        });
                        setSubAdmins((prev) => {
                          const newData = [...prev];

                          const recIndex = newData.findIndex(
                            (subAdmin) => subAdmin.id === record.id,
                          );
                          if (recIndex >= 0)
                            newData[recIndex] = {
                              ...newData[recIndex],
                              autoEmailsEnabled: !value,
                            };
                          return newData;
                        });
                        updateSubcontractorEmployee({
                          variables: {
                            where: { user_id: { _eq: record.id } },
                            _set: {
                              employee_verification_emails_enabled: newValue,
                            },
                          },
                        })
                          .catch((error) => {
                            throw error;
                          })
                          .finally(() => {
                            setLoadings((prevLoadings) => {
                              const newLoadings = [...prevLoadings];
                              newLoadings[index] = false;
                              return newLoadings;
                            });
                          });
                      }}
                      value={value}
                    />
                  </div>
                );
              },
            },
            {
              title: "Company",
              dataIndex: ["subcontractorName"],
              sorter: (a, b) =>
                a.subcontractorName.localeCompare(b.subcontractorName),
              defaultSortOrder: "ascend",
            },
            {
              title: "Name",
              dataIndex: ["name"],
            },
            {
              title: "Title",
              dataIndex: ["title"],
            },
            {
              title: "Email",
              dataIndex: ["email"],
            },
            {
              title: "Phone #",
              dataIndex: ["phone"],
            },
          ]}
        ></Table>
      </div>
    </div>
  );
};

export default GCProjectEmployeeVerification;
