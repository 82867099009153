import { Space, Tag, Typography } from "antd";
import { dateFormat } from "src/common/functions/dates/getDateStr";
import { GCAdminHeaderFilterButtonProps } from "./GCAdminHeaderFilterButton";

type TagType = {
  id: string;
  title: string;
  onRemove: () => void;
};

export interface GCAdminHeaderFilterTagsProps
  extends GCAdminHeaderFilterButtonProps {}

const { Text } = Typography;

const GCAdminHeaderFilterTags: React.FC<GCAdminHeaderFilterTagsProps> = ({
  filters,
}) => {
  const tagsGroups: {
    title: string;
    tags: TagType[];
  }[] = [];
  const dateTags: TagType[] = [];

  if (filters.dates) {
    const dateRange = filters.dates.selectedDates;
    if (dateRange) {
      dateTags.push({
        id: "dates",
        title: `Dates: ${dateRange
          .filter((date) => !!date && date.isValid())
          .map((date) => date?.format(dateFormat.mmddyyyy))
          .join(" - ")}`,
        onRemove: () => {
          filters.dates?.onSelectedDatesChange(null);
        },
      });
    }
    tagsGroups.push({
      title: "Dates",
      tags: dateTags,
    });
  }
  if (filters.radioFilters) {
    for (const filter of filters.radioFilters) {
      if (filter.selectedItem) {
        const tags: TagType[] = [];
        const selectedOpt = filter.options.find(
          (p) => p.value === filter.selectedItem,
        );

        if (selectedOpt && selectedOpt.value !== filter.defaultVal) {
          tags.push({
            id: `${filter.title}_${selectedOpt.value}`,
            title: selectedOpt.label,
            onRemove: () => {
              filter.handleItemChange(filter.defaultVal);
            },
          });

          tagsGroups.push({
            title: filter.title,
            tags: tags,
          });
        }
      }
    }
  }

  if (filters.selectFilters) {
    for (const filter of filters.selectFilters) {
      if (filter.selectedItems.size > 0) {
        const tags: TagType[] = [];
        filter.options
          .filter((p) => filter.selectedItems.has(p.value))
          .forEach((item) => {
            tags.push({
              id: `${filter.title}_${item.value}`,
              title: item.label,
              onRemove: () => {
                filter.handleItemChange(item.value);
              },
            });
          });
        tagsGroups.push({
          title: filter.title,
          tags: tags,
        });
      }
    }
  }
  const filteredTagGroups = tagsGroups.filter(
    (tagGroup) => tagGroup.tags.length > 0,
  );
  if (!filteredTagGroups.length) return null;
  return (
    <Space direction={"vertical"} size={"small"}>
      {filteredTagGroups.map((tagGroup) => (
        <Space direction={"horizontal"} size={"small"} wrap>
          <Text>{tagGroup.title}: </Text>
          {tagGroup.tags.map((tag) => (
            <Tag key={tag.id} closable onClose={tag.onRemove}>
              {tag.title}
            </Tag>
          ))}
        </Space>
      ))}
    </Space>
  );
};

export default GCAdminHeaderFilterTags;
