import React from "react";
import BlockOutDeliveryTimesTable from "./BlockOutDeliveryTimesTable";
import { TimeRange } from "./type";
import useBlockOutDevlieryTimes from "./useBlockOutDeliveryTimes";
import { Card } from "antd";
import SwitchWithText from "../../../../../../../../../common/components/SwitchWithText";
import DividerSplitWrapper from "../../../../../../../../../common/components/DividerSplitWrapper";

interface BlockOutDeliveryTimesProps {
  projectId: string;
  featureOn: boolean;
  loading?: boolean;
}

const BlockOutDeliveryTimes: React.FunctionComponent<
  BlockOutDeliveryTimesProps
> = (props) => {
  const {
    timesTable,
    addBlockOutDelivery,
    removeBlockOutDelivery,
    loading,
    toggleBlockOutSwitch,
  } = useBlockOutDevlieryTimes(props.projectId);

  const handleSwitcherChange = () => {
    toggleBlockOutSwitch(!props.featureOn);
  };

  const handleCreateNewTimeBlock = (time: TimeRange, weekDayNumber: number) => {
    addBlockOutDelivery(time.start, time.end, weekDayNumber);
  };

  const handleRemoveTimeBlock = removeBlockOutDelivery;

  return (
    <Card title="Block-Out Delivery Times">
      <DividerSplitWrapper>
        <SwitchWithText
          checked={props.featureOn}
          text={"restrict deliveries on certain days and times"}
          onChange={toggleBlockOutSwitch}
          loading={props.loading}
        />

        {props.featureOn && (
          <BlockOutDeliveryTimesTable
            loading={loading}
            addTimeBlock={handleCreateNewTimeBlock}
            removeTimeBlock={handleRemoveTimeBlock}
            timesTable={timesTable}
          />
        )}
      </DividerSplitWrapper>
    </Card>
  );
};

export default BlockOutDeliveryTimes;
