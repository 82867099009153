import NestedRoute from "src/common/types/manual/NestedRoute";
import React from "react";
import GCProjectPreTaskPlans from "./GCProjectPreTaskPlans";

const preTaskPlansRoute: NestedRoute = {
  element: <GCProjectPreTaskPlans />,
  handle: {
    breadcrumb: "Pre-Task Plans",
  },
};

export default preTaskPlansRoute;
