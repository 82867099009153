import { Card } from "antd";
import { useMemo } from "react";
import { useGetOrientationDashboardDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import MapCoordinatePoints from "src/common/components/leaflet-maps/MapCoordinatePoints";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";
import getDashboardDataMaps, {
  StateMapDataType,
  ZipMapDataType,
} from "./utils/getDashboardDataMaps";
import DashboardChartSettings, {
  chartSettingsFields,
} from "./DashboardChartSettings";
import BarChartToShow from "src/common/components/charts/BarChartsToShow";
import ProjectIdProps from "../../../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../common/hoc/withProjectIdUrlParam";

const GCProjectOrientationDashboard: React.FC<ProjectIdProps> = ({
  projectId,
}) => {
  const { data, refetch, loading, error } = useGetOrientationDashboardDataQuery(
    {
      variables: {
        projectId,
        where: {
          project_id: { _eq: projectId },
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
        },
      },
    },
  );
  const project = data?.project_by_pk;

  const localCities = new Set(
    (project?.project_local_cities || []).map((c) => c.city_id),
  );
  const {
    roleMap,
    veteranTypeMap,
    ethnicityMap,
    genderMap,
    cityMap,
    zipMap,
    stateMap,
    yearsInConstructionMap,
    yearsWithEmployerMap,
  } = useMemo(() => getDashboardDataMaps(data, localCities), [data]);

  if (loading) return <LargeTableSkeleton />;
  if (!data) throw error;
  if (!project) throw new Error("project not found");
  const chartSettings =
    project.project_setting?.orientation_chart_settings_json;

  return (
    <div
      className={`w-full relative h-full bg-white rounded-2 overflow-y-auto z-0`}
    >
      <DashboardChartSettings
        refetch={refetch}
        projectId={projectId}
        chartSettings={chartSettings}
      />
      <div className="grid grid-cols-2 gap-1">
        {!chartSettings?.[chartSettingsFields.gender] && (
          <BarChartToShow
            className={`col-start-1 col-end-2`}
            data={genderMap}
            title="Gender"
          />
        )}
        {!chartSettings?.[chartSettingsFields.ethnicity] && (
          <BarChartToShow
            data={ethnicityMap}
            title="Ethnicity"
            height={ethnicityMap.size * 20}
            className={`col-start-1 col-end-2`}
          />
        )}

        {!!zipMap.size && (
          <Card
            title={<span>Residency Map</span>}
            styles={{ header: { backgroundColor: "#F0F2F5" } }}
            className={"col-start-2 col-end-3 row-start-1 row-end-3"}
          >
            {chartSettings?.residency_map === "state"
              ? !!stateMap.size && (
                  <MapCoordinatePoints<StateMapDataType>
                    data={Array.from(stateMap.values())}
                    height={700}
                    renderPopup={(state) =>
                      `State: ${state.name}, ${state.code} Count: ${state.count}`
                    }
                  />
                )
              : !!zipMap.size && (
                  <MapCoordinatePoints<ZipMapDataType>
                    data={Array.from(zipMap.values())}
                    height={700}
                    renderPopup={(czip) =>
                      `Zip Code: ${czip.zip_code}, City: ${czip.city.name}, Count: ${czip.count}`
                    }
                  />
                )}
          </Card>
        )}

        {!chartSettings?.[chartSettingsFields.veteran] && (
          <BarChartToShow data={veteranTypeMap} title="Veteran" />
        )}
        {!chartSettings?.[chartSettingsFields.years_in_construction] && (
          <BarChartToShow
            data={yearsInConstructionMap}
            title="Years in Construction"
            height={yearsInConstructionMap.size * 20}
          />
        )}
        {!chartSettings?.[chartSettingsFields.years_with_employer] && (
          <BarChartToShow
            data={yearsWithEmployerMap}
            height={yearsWithEmployerMap.size * 20}
            title="Years with Employer"
          />
        )}
        <BarChartToShow
          data={roleMap}
          title="Role"
          height={roleMap.size * 20}
        />
        {!chartSettings?.[chartSettingsFields.residency] ? (
          <>
            {chartSettings?.residency_map !== "state" && (
              <BarChartToShow
                data={cityMap}
                title={`Residency (${project.project_local_cities.length} Local Cities)`}
              />
            )}
            {chartSettings?.residency_map === "state" && (
              <BarChartToShow
                data={stateMap}
                height={stateMap.size * 20}
                title={`State`}
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
export default withCustomSuspense(
  withProjectIdUrlParam(GCProjectOrientationDashboard),
);
