import { Navigate } from "react-router-dom";
import React from "react";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import slidesRoute from "./routes/slides/slidesRoute";
import GCProjectOrientation from "./GCProjectOrientation";
import GCProjectOrientationStart from "./routes/qrs/GCProjectOrientationQrCodes";
import GCProjectOrientationBuilder from "./routes/builder/GCProjectOrientationBuilder";
import GCProjectOrientationLogs from "./routes/log/GCProjectOrientationLog";
import GCProjectOrientationDashboard from "./routes/dashboard/GCProjectOrientationDashboard";
import GCProjectOrientationDrugTest from "./routes/drugTest/GCProjectOrientationDrugTest";
import GCProjectOrientationBackgroundCheck from "./routes/background-check/GCProjectOrientationBackgroundCheck";

const gcOrientationDashboardRoute: NestedRoute = {
  handle: {
    breadcrumb: "Orientations",
  },
  element: <GCProjectOrientation />,
  children: [
    {
      path: "qr-codes",
      handle: {
        breadcrumb: "qr-codes",
      },
      element: <GCProjectOrientationStart />,
    },
    {
      path: "builder",
      handle: {
        breadcrumb: "Builder",
      },
      children: [
        {
          path: "",
          element: <GCProjectOrientationBuilder />,
        },
        {
          path: "module",
          ...slidesRoute,
        },
      ],
    },

    {
      path: "log",
      element: <GCProjectOrientationLogs />,
    },
    {
      path: "dashboard",
      element: <GCProjectOrientationDashboard />,
    },
    {
      path: "drugtest",
      element: <GCProjectOrientationDrugTest />,
    },
    {
      path: "background-check",
      element: <GCProjectOrientationBackgroundCheck />,
    },
    {
      path: "",
      element: <Navigate replace to="log" />,
    },
  ],
};

export default gcOrientationDashboardRoute;
