import { useUpdateOrientationProjectSettingsMutationReturningValues } from "./useUpdateOrientationProjectSettings";
import useGcOrientationData from "./useGcOrientationData";

const useGcProjectOrientationParams = (projectId: string) => {
  const { getProjectOrientation } = useGcOrientationData(projectId);

  const projectData = getProjectOrientation();

  const params: useUpdateOrientationProjectSettingsMutationReturningValues = {
    ask_gender: projectData.ask_gender,
    ask_age: projectData.ask_age,
    ask_ethnicity: projectData.ask_ethnicity,
    ask_veteran_type: projectData.ask_veteran_type,
    ask_residence: projectData.ask_residence,
    ask_emergency_contact: projectData.ask_emergency_contact,
    ask_number_of_years_in_construction:
      projectData.ask_number_of_years_in_construction,
    ask_years_with_employer: projectData.ask_years_with_employer,
    next_hard_hat_number: projectData.next_hard_hat_number,
    worker_scan_in_person_qr_code: projectData.worker_scan_in_person_qr_code,
    in_person_orientation: projectData.in_person_orientation,
    assign_hard_hat: projectData.assign_hard_hat,
    require_certification_upload: projectData.require_certification_upload,
    perform_drugtest: projectData.perform_drugtest,
    automatically_assign_hard_hat: projectData.automatically_assign_hard_hat,
    require_profile_photo: projectData.require_profile_photo,
    drugtest_validity_days: projectData.drugtest_validity_days ?? undefined,
    require_drugtest_upload: projectData.require_drugtest_upload,
    drug_test_retest_days: projectData.drug_test_retest_days,
    add_hard_hat_during_registration:
      projectData.add_hard_hat_during_registration,
    send_email_for_drug_test_worker_arrival:
      projectData.send_email_for_drug_test_worker_arrival,
    show_onsite_for_not_oriented_workers:
      projectData.show_onsite_for_not_oriented_workers,
    send_verification_invite: projectData.send_verification_invite,
    require_phone: projectData.require_phone,
    require_dob: projectData.require_dob,
    background_check_tracker: projectData.background_check_tracker,
    required_quiz_percentage: projectData.required_quiz_percentage,
    union_labor: projectData.union_labor,
    orientation_tv_player: projectData.orientation_tv_player,
    add_hard_hat_during_in_person: projectData.add_hard_hat_during_in_person,
    require_certs: projectData.require_certs,
  };

  return params;
};

export default useGcProjectOrientationParams;
