import React, { useState } from "react";
import { SubOnsiteData } from "../performance/GCSubOnsitePerformanceTable";

import GCOnsiteSettingsOnDate from "./GCOnsiteSettingsOnDate";
import CustomTabs from "../../../../common/components/layouts/tabs/CustomTabs";
import GCOnsiteSettingsPermanent from "./GCOnsiteSettingsPermanent";

interface GCDashAppReportSettingsProps {
  projectId: string;
  projectTimezone: string;
  subOnsiteData?: SubOnsiteData[];
  crewLeads: { [id: string]: string };
}

const tabIds = ["permanent", "date"] as const;
type TabId = (typeof tabIds)[number];
const isValidTabId = (str: string): str is TabId =>
  tabIds.includes(str as TabId);

const GCOnsiteSettings: React.FunctionComponent<
  GCDashAppReportSettingsProps
> = ({ projectId, subOnsiteData, projectTimezone, crewLeads }) => {
  const [tabId, setTabId] = useState<TabId>("permanent");

  return (
    <CustomTabs
      onTabSelect={(newTabId) => {
        if (isValidTabId(newTabId)) {
          setTabId(newTabId);
        }
      }}
      selectedTabId={tabId}
      tabs={[
        {
          id: "permanent",
          label: "Permanent Settings",
          content: (
            <GCOnsiteSettingsPermanent
              projectId={projectId}
              subOnsiteData={subOnsiteData}
            />
          ),
        },
        {
          id: "date",
          label: "Date Settings",
          content: (
            <GCOnsiteSettingsOnDate
              crewLeads={crewLeads}
              projectTimezone={projectTimezone}
              projectId={projectId}
            />
          ),
        },
      ]}
    />
  );
};

export default GCOnsiteSettings;
