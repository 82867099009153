import { Button, Result, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { HomeOutlined, RollbackOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import sendHotjarEvent from "../../event-tracking/hotjar/sendHotjarEvent";

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
  };
  useEffect(() => {
    sendHotjarEvent("page_not_found");
  }, []);
  return (
    <div className={`min-h-screen flex flex-col items-center justify-center`}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Space>
            <Button
              icon={<RollbackOutlined />}
              type="primary"
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
            <Button icon={<HomeOutlined />} onClick={goHome}>
              Home
            </Button>
          </Space>
        }
      />
    </div>
  );
};

export default PageNotFound;
