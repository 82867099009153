import React from "react";
import { useParams } from "react-router-dom";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "src/common/components/layouts/tabs/CustomTabsRouted";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";

const GCProjectWorkers: React.FunctionComponent = () => {
  const { projectId } = useParams();
  const basePath = `/gce/hierarchy/project/${projectId}/workers`;

  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "table",
      label: "Workers",
    },
    {
      path: "overview",
      label: "Overview",
    },
  ];

  return <CustomTabsRouted tabs={tabs} basePath={basePath} />;
};

export default withAppPageLayout(GCProjectWorkers, { pageTitle: "Workers" });
