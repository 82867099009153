import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useSuspenseQuery } from "@apollo/client";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { useMemo, useState } from "react";
import colorList, {
  getColorFromColorList,
} from "src/common/components/lists/chartColorList";
import {
  GetObservationRequirementsDataDocument,
  GetObservationRequirementsDataQuery,
  GetObservationRequirementsDataQueryVariables,
  useUpdateObservationRequirementMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ObsReqFormVals } from "./obs-requirement/utils/useInsertObsReq";
import CreateNewReqModal, {
  EditingInputNumber,
  EmployeeTitleSelectFormItem,
} from "./obs-requirement/CreateObsRequirement";
import useRemoveObsReq from "./obs-requirement/utils/useRemoveObsReq";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import useUpdateReqEmpTitles from "./obs-requirement/utils/useUpdateReqEmpTitles";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../../withCorporateAdminWrapper";
import compareStringsIgnoreCase from "src/common/functions/compareStringsIgnoreCase";

const tagColors = [
  "processing",
  "success",
  "error",
  "warning",
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];

const GCObservationRequirements: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const gcId = employee.general_contractor.id;
  const { data } = useSuspenseQuery<
    GetObservationRequirementsDataQuery,
    GetObservationRequirementsDataQueryVariables
  >(GetObservationRequirementsDataDocument, {
    variables: {
      where: {
        general_contractor_id: { _eq: gcId },
        archived_at: { _is_null: true },
      },
    },
  });
  const [updateObsReq, { loading: updating }] =
    useUpdateObservationRequirementMutation();
  const [updateTitlesList, updatingTitles] = useUpdateReqEmpTitles(
    data.observation_requirement,
  );
  const [removeObsReq, removing] = useRemoveObsReq();
  const [editingId, setEditingId] = useState<string>();
  const [searchString, setSearchString] = useState("");
  const requirements = data.observation_requirement;
  type DataType = (typeof requirements)[number];
  const employeeTitlesMap = useMemo(() => {
    const titlesMap = new Map(
      data.employee_title.map((title) => [
        title.id,
        {
          label: title.name.en,
          value: title.id,
          alreadyAddedToOther: false,
        },
      ]),
    );
    requirements
      .filter((r) => !editingId || r.id !== editingId)
      .forEach((r) =>
        r.employee_titles.forEach((empTitle) => {
          const titleInMap = titlesMap.get(empTitle.employee_title_id);
          if (titleInMap) titleInMap.alreadyAddedToOther = true;
        }),
      );
    return titlesMap;
  }, [data.employee_title, requirements, editingId]);

  const [form] = Form.useForm<ObsReqFormVals>();
  const isEditing = (id: string) => editingId && id === editingId;

  const edit = (record: DataType) => {
    form.resetFields();
    setEditingId(record.id);
    form.setFieldsValue({
      group_name: record.group_name,
      audits_required: record.audits_required,
      employee_titles: record.employee_titles.map((t) => t.employee_title_id),
      observations_required: record.observations_required,
      findings_per_audit: record.findings_per_audit,
    });
  };

  const save = async () => {
    const vals = await form.validateFields();
    console.log(vals);
    const editingReq = requirements.find((r) => r.id === editingId);
    if (!editingReq) throw new Error("No editing item found");

    const toSet = {
      group_name: vals.group_name,
      audits_required: vals.audits_required,
      observations_required: vals.observations_required,
      findings_per_audit: vals.findings_per_audit,
    };
    await updateObsReq({
      variables: {
        _set: toSet,
        id: editingReq.id,
      },
      optimisticResponse: {
        update_observation_requirement_by_pk: {
          ...editingReq,
          ...toSet,
        },
      },
    });

    const [toInsert, toDelete] = compareTwoLists(
      vals.employee_titles,
      editingReq.employee_titles.map((t) => t.employee_title_id),
    );
    setEditingId(undefined);
    if (toInsert.length || toDelete.length) {
      await updateTitlesList({ toInsert, toDelete, editingObsReq: editingReq });
    }
  };
  const [open, setOpen] = useState(false);
  const titleOptions = [...employeeTitlesMap.values()];
  const requirementsToShow = useMemo(
    () =>
      requirements
        .filter((r) =>
          searchString.trim()
            ? !!r.employee_titles.some(({ employee_title_id }) => {
                const label =
                  employeeTitlesMap.get(employee_title_id)?.label || "";
                console.log(label, searchString, label.includes(searchString));
                return label.toLowerCase().includes(searchString.toLowerCase());
              })
            : true,
        )
        .sort((r1, r2) =>
          compareStringsIgnoreCase(r1.group_name, r2.group_name),
        ),
    [requirements, searchString],
  );
  return (
    <Form form={form}>
      <CreateNewReqModal
        gcId={gcId}
        titleOptions={titleOptions}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Table
        bordered
        pagination={false}
        rowKey={(row) => row.id}
        dataSource={requirementsToShow}
        title={() => (
          <div className="flex flex-row justify-between">
            <div className="flex gap-1">
              <div className="text-1.25">Audit and Observation Goals</div>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => setOpen(true)}
                iconPosition="start"
              >
                Add Group
              </Button>
            </div>

            <div>
              <Input.Search
                placeholder="Search"
                allowClear
                onSearch={(v) => setSearchString(v)}
              />
            </div>
          </div>
        )}
        columns={[
          {
            dataIndex: ["group_name"],
            title: "Group",
            width: "20%",
            key: "group_name",
            render: (v, r) =>
              !isEditing(r.id) ? (
                v
              ) : (
                <Form.Item name="group_name" initialValue={v}>
                  <Input />
                </Form.Item>
              ),
          },
          {
            width: "12%",
            dataIndex: ["audits_required"],
            key: "audits_required",
            title: "# of Audits/Month",
            render: (v, r) =>
              !isEditing(r.id) ? (
                v
              ) : (
                <EditingInputNumber
                  itemKey={"audits_required"}
                  initialValue={v}
                />
              ),
          },
          {
            dataIndex: ["findings_per_audit"],
            key: "findings_per_audit",
            title: "# of Findings/Audit",
            width: "12%",
            render: (v, r) =>
              !isEditing(r.id) ? (
                v
              ) : (
                <EditingInputNumber
                  itemKey={"findings_per_audit"}
                  initialValue={v}
                />
              ),
          },
          {
            dataIndex: ["observations_required"],
            title: "# of One-Off Observations/Month",
            key: "observations_required",
            width: "12%",
            render: (v, r) =>
              !isEditing(r.id) ? (
                v
              ) : (
                <EditingInputNumber
                  itemKey={"observations_required"}
                  initialValue={v}
                />
              ),
          },
          {
            dataIndex: ["employee_titles"],
            title: "Titles",
            key: "employee_titles",
            width: "27",
            render: (_, r) =>
              !isEditing(r.id) ? (
                <div className={`flex flex-row flex-wrap gap-0.5`}>
                  {r.employee_titles.map(({ employee_title_id, id }, i) => (
                    <Tag
                      className={`m-0`}
                      key={id}
                      color={tagColors[i] || getColorFromColorList(i)}
                    >
                      {employeeTitlesMap.get(employee_title_id)?.label}
                    </Tag>
                  ))}
                </div>
              ) : (
                <EmployeeTitleSelectFormItem
                  options={titleOptions}
                  initialValue={r.employee_titles.map(
                    (t) => t.employee_title_id,
                  )}
                />
              ),
          },
          {
            title: "",
            dataIndex: "operation",
            key: "operation",
            render: (_: any, record: DataType) => {
              const editable = isEditing(record.id);
              return editable ? (
                updatingTitles || updating || removing ? (
                  <Spin />
                ) : (
                  <span>
                    <Typography.Link
                      onClick={() => save()}
                      style={{ marginInlineEnd: 8 }}
                    >
                      Save
                    </Typography.Link>
                    <Popconfirm
                      title="Sure to cancel?"
                      onConfirm={() => setEditingId(undefined)}
                    >
                      <a>Cancel</a>
                    </Popconfirm>
                  </span>
                )
              ) : (
                <div className="flex gap-0.5 flex-row">
                  <Button
                    disabled={!!editingId}
                    onClick={() => edit(record)}
                    size={"small"}
                    type={"text"}
                    icon={<EditOutlined />}
                  />

                  <Popconfirm
                    title="Sure to Delete?"
                    onConfirm={async () => {
                      await removeObsReq(record);
                    }}
                  >
                    <Button
                      type={"text"}
                      size={"small"}
                      icon={<DeleteOutlined />}
                      danger
                    />
                  </Popconfirm>
                </div>
              );
            },
          },
        ]}
      />
    </Form>
  );
};

export default withCorporateAdminWrapper(
  withCustomSuspense(GCObservationRequirements),
);
