import NestedRoute from "../../../../common/types/manual/NestedRoute";
import DevelopmentPlayground from "./DevelopmentPlayground";
import developmentPlaygroundNavigationItems from "./developmentPlaygroundNavigationItems";
import getRoutesByRoutedMenu from "../../../../utility-features/navigation/routed-menu/routedMenu";

const developmentPlaygroundRoute: NestedRoute = {
  element: <DevelopmentPlayground />,
  children: getRoutesByRoutedMenu(developmentPlaygroundNavigationItems),
};

export default developmentPlaygroundRoute;
