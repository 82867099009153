import { useSuspenseQuery } from "@apollo/client";
import {
  GetGcDivisionsDocument,
  GetGcDivisionsQuery,
  GetGcDivisionsQueryVariables,
  useInsertGcDivisionMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import useAuthUser from "src/common/hooks/useAuthUser";
import GCAddNewLevelButton from "../GCAddNewLevelButton";
import useGcEmployee from "../../organization-unit/utils/useGcEmployee";
import React from "react";
import DivisionTable from "../DivisionTable";

const GCAllDivisionLevelChilds: React.FC<
  Omit<GetGcDivisionsQueryVariables, "includeTableData">
> = ({ divisionWhere }) => {
  const gc = useGcEmployee().general_contractor;
  const { data, refetch } = useSuspenseQuery<
    GetGcDivisionsQuery,
    GetGcDivisionsQueryVariables
  >(GetGcDivisionsDocument, {
    variables: {
      divisionWhere,
      includeTableData: true,
    },
    fetchPolicy: "network-only",
  });
  const [insertDivision, { loading }] = useInsertGcDivisionMutation();
  const authUser = useAuthUser();
  return gc.hierarchy_division_name ? (
    <DivisionTable
      divisions={data.gc_division}
      officeTitle={gc.hierarchy_office_name}
      divisionTitle={gc.hierarchy_division_name}
      buTitle={gc.hierarchy_business_unit_name}
      addButton={
        <GCAddNewLevelButton
          {...{
            levelName: gc.hierarchy_division_name,
            inserting: loading,
            divisionLevelName: gc.hierarchy_division_name,
            onInsert: async (vals) => {
              await insertDivision({
                variables: {
                  object: {
                    name: vals.name,
                    created_by_uid: authUser.uid,
                    general_contractor_id: gc.id,
                    logo_url: vals.uploadedLogoUrl,
                    address: {
                      data: {
                        line_1: vals.addressLine1,
                        zip_code: vals.zip,
                        city: vals.city,
                        state_code: vals.stateCode,
                      },
                    },
                  },
                },
              });
              refetch();
            },
          }}
        />
      }
    />
  ) : null;
};
export default GCAllDivisionLevelChilds;
