import { Button, DatePicker, Space } from "antd";
import dayjs from "dayjs";
import React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DatePickerProps } from "antd/es/date-picker";
import formatRelativeDate from "../../../../../common/functions/dates/formatRelativeDate";

export interface DatesNavigationProps
  extends Pick<DatePickerProps, "disabledDate" | "onChange"> {
  date: dayjs.Dayjs | undefined;
}

const DatesNavigation: React.FC<DatesNavigationProps> = (props) => {
  // Disabled forward if tomorrow is disabled

  const tomorrow = dayjs(props.date).add(1, "days");
  const disabledForward = props.disabledDate?.(tomorrow, {
    type: "date",
  });
  const yesterday = dayjs(props.date).subtract(1, "days");
  const disabledBackward = props.disabledDate?.(yesterday, {
    type: "date",
  });

  return (
    <Space.Compact direction={"horizontal"}>
      <Button
        icon={<LeftOutlined />}
        onClick={() => {
          props.onChange?.(dayjs(props.date).subtract(1, "days"), []);
        }}
        disabled={disabledBackward}
      />
      <DatePicker
        disabledDate={props.disabledDate}
        value={dayjs(props.date)}
        format={(val) => formatRelativeDate(val)}
        onChange={props.onChange}
        inputReadOnly
        allowClear={false}
      />
      <Button
        disabled={disabledForward}
        icon={<RightOutlined />}
        onClick={() => {
          props.onChange?.(dayjs(props.date).add(1, "days"), []);
        }}
      />
    </Space.Compact>
  );
};

export default DatesNavigation;
