import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { ItemType } from "antd/lib/menu/interface";

/*
Usage Example:

const navigationItems: RoutedMenuItem[] = [
  {
    path: "my-component",
    label: `My Component`,
    element: <MyComponent />,
  },
  ... other navigation elements
];

const routes = getRoutesByRoutedMenu(navigationItems);

const menuItems = getMenuItemsByRoutedMenu(navigationItems);

 */

export type RoutedMenuItem = {
  label?: string;
  icon?: React.ReactNode;
  children?: RoutedMenuItem[];
  showChildrenInMenu?: boolean;
} & Omit<RouteObject, "children">;

export const getRoutesByRoutedMenu: (
  navigationItems: RoutedMenuItem[],
) => RouteObject[] = (navigationItems) => {
  const routes: RouteObject[] = [];
  let redirectPath: null | string = null;

  navigationItems.forEach((item) => {
    const { label, icon, children, ...routeObjectWithoutChildren } = item;
    const routeObject: RouteObject = {
      ...routeObjectWithoutChildren,
      index: false,
      children: item.children
        ? getRoutesByRoutedMenu(item.children)
        : undefined,
    };
    routes.push({ ...routeObject });

    if (!redirectPath && !!item.path) {
      redirectPath = item.path;
    }
  });

  if (redirectPath) {
    routes.push({
      path: "",
      element: <Navigate to={redirectPath} replace />,
    });
  }
  return routes;
};

export const getMenuItemsByRoutedMenu: (
  navigationItems: RoutedMenuItem[],
) => ItemType[] = (navigationItems) => {
  const menuItems: ItemType[] = [];

  navigationItems.forEach((item) => {
    const { label, icon, path, children } = item;
    const menuChildren =
      item.showChildrenInMenu && children?.length
        ? getMenuItemsByRoutedMenu(children)
        : undefined;
    if (!!path && !!label) {
      menuItems.push({
        key: path,
        label,
        icon,
        children: menuChildren,
      });
    }
  });

  return menuItems;
};

export default getRoutesByRoutedMenu;
