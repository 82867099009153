import { projectRelatedSettingsUpdateFunctions_ProcoreProj_Mutation } from "src/common/types/generated/relay/projectRelatedSettingsUpdateFunctions_ProcoreProj_Mutation.graphql";
import { projectRelatedSettingsUpdateFunctions_UpdateProjSettings_Mutation } from "src/common/types/generated/relay/projectRelatedSettingsUpdateFunctions_UpdateProjSettings_Mutation.graphql";
import { projectRelatedSettingsUpdateFunctions_UpdateProject_Mutation } from "src/common/types/generated/relay/projectRelatedSettingsUpdateFunctions_UpdateProject_Mutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  procore_project_data_set_input,
  project_set_input,
  project_setting_set_input,
} from "src/common/types/generated/relay/types";
import { message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import NotifyUserException from "../../../../../../../../utility-features/error-handling/NotifyUserException";

const hasProperty = <T extends {}>(
  value: T,
  prop: string | number | symbol,
): prop is keyof T => {
  return prop in value;
};
const notifyOnSingleBooleanFieldChange = <S extends {}, D extends {}>(
  set: S,
  data: D,
) => {
  const keys = Object.keys(set);
  if (keys.length !== 1) return;
  const key = keys[0];
  if (hasProperty(data, key) && typeof data[key] === "boolean") {
    const value = data[key];
    if (value) {
      message.success("On");
    } else {
      message.error("Off");
    }
  }
};

export const useUpdateProcoreProjectDataValue = (
  projectId: string,
  procore_project_data: projectRelatedSettingsUpdateFunctions_ProcoreProj_Mutation["response"]["update_procore_project_data_by_pk"],
) => {
  const [loading, setLoading] = useState(false);
  const [updateProcoreProject] =
    useAsyncMutation<projectRelatedSettingsUpdateFunctions_ProcoreProj_Mutation>(graphql`
      mutation projectRelatedSettingsUpdateFunctions_ProcoreProj_Mutation(
        $projectId: uuid!
        $_set: procore_project_data_set_input!
      ) {
        update_procore_project_data_by_pk(
          pk_columns: { project_id: $projectId }
          _set: $_set
        ) {
          id
          integration_enabled
          upload_delivery_enabled
          upload_daily_construction_report_enabled
        }
      }
    `);
  const updateProcoreProjectValue = async (
    set: procore_project_data_set_input,
  ) => {
    try {
      const res = await updateProcoreProject({
        variables: {
          projectId: projectId,
          _set: set,
        },
        optimisticResponse: {
          update_procore_project_data_by_pk: {
            ...procore_project_data,
            ...set,
          },
        },
      });
      const data = res.update_procore_project_data_by_pk;
      if (!data) {
        throw new NotifyUserException(
          `Please configure Procore integration first`,
        );
      }
      notifyOnSingleBooleanFieldChange(set, data);
    } finally {
      setLoading(false);
    }
  };
  return { updateProcoreProjectValue, loading };
};

export const useUpdateProjectSettingValue = (
  projectId: string,
  project_settings: projectRelatedSettingsUpdateFunctions_UpdateProjSettings_Mutation["response"]["update_project_setting_by_pk"],
) => {
  const [loading, setLoading] = useState(false);
  const [updateProjectSetting] =
    useAsyncMutation<projectRelatedSettingsUpdateFunctions_UpdateProjSettings_Mutation>(graphql`
      mutation projectRelatedSettingsUpdateFunctions_UpdateProjSettings_Mutation(
        $projectId: uuid!
        $_set: project_setting_set_input!
      ) {
        update_project_setting_by_pk(
          pk_columns: { project_id: $projectId }
          _set: $_set
        ) {
          id
          delivery_stacking
        }
      }
    `);
  const updateProjectSettingsValue = async (set: project_setting_set_input) => {
    try {
      setLoading(true);
      const res = await updateProjectSetting({
        variables: {
          projectId: projectId,
          _set: set,
        },
        optimisticResponse: {
          update_project_by_pk: {
            ...project_settings,
            ...set,
          },
        },
      });
      const data = res.update_project_setting_by_pk;
      if (!data) {
        throw new Error("Project settings record is missing");
      }
      notifyOnSingleBooleanFieldChange(set, data);
    } finally {
      setLoading(false);
    }
  };

  return { updateProjectSettingsValue, loading };
};

export const useUpdateProjectValue = (
  projectId: string,
  project: projectRelatedSettingsUpdateFunctions_UpdateProject_Mutation["response"]["update_project_by_pk"],
) => {
  const [updateProject, loading] =
    useAsyncMutation<projectRelatedSettingsUpdateFunctions_UpdateProject_Mutation>(graphql`
      mutation projectRelatedSettingsUpdateFunctions_UpdateProject_Mutation(
        $projectId: uuid!
        $_set: project_set_input!
      ) {
        update_project_by_pk(pk_columns: { id: $projectId }, _set: $_set) {
          id
          completed
          is_visitor_log_and_tracking_active
          is_sitedelivery_on
          is_visitor_log_procore_integration_enabled
          logo_url
          is_sitedelivery_approval_needed
        }
      }
    `);

  const updateProjectValue = async (set: project_set_input, notify = true) => {
    const res = await updateProject({
      variables: {
        projectId: projectId,
        _set: set,
      },
      optimisticResponse: { update_project_by_pk: { ...project, ...set } },
    });
    const data = res.update_project_by_pk;
    if (!data) {
      throw new Error("Project does not exist");
    }
    if (notify) notifyOnSingleBooleanFieldChange(set, data);
  };
  return { updateProjectValue, loading };
};
