import { useState } from "react";
import { Modal, Card } from "antd";
import { useSuspenseQuery } from "@apollo/client";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  GetCalendarsQuery,
  GetCalendarsQueryVariables,
  GetCalendarsDocument,
  GetDeliveryFieldsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { CalendarBaseType } from "../utilities/sitedeliveryTypes";
import Icon from "src/common/components/general/Icon";
import { IconCheck, IconX } from "@tabler/icons";
import { useInsertProjectCalendarMutation } from "src/common/types/generated/apollo/graphQLTypes";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useParams } from "react-router-dom";

interface SitedeliveryAddCalendarFromOtherProjectModalProps {
  gcId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

type CalendarWithProjectName = Omit<CalendarBaseType, "titleId"> & {
  projectName: string;
};

const SitedeliveryAddCalendarFromOtherProjectModal = ({
  gcId,
  isOpen,
  setIsOpen,
}: SitedeliveryAddCalendarFromOtherProjectModalProps) => {
  const authUser = useAuthUser();
  const { projectId } = useParams();

  const queryVariables: GetCalendarsQueryVariables = {
    where: {
      _and: [
        { project_id: { _neq: projectId } },
        {
          projects: {
            is_archive: { _eq: false },
            project: { general_contractor_id: { _eq: gcId } },
          },
        },
      ],
    },
  };

  const { data } = useSuspenseQuery<
    GetCalendarsQuery,
    GetCalendarsQueryVariables
  >(GetCalendarsDocument, {
    variables: queryVariables,
  });
  const [insertProjectCalendar, { loading }] =
    useInsertProjectCalendarMutation();

  const calendars = data?.calendar || [];

  const sharedCalendars: CalendarWithProjectName[] = calendars
    .filter((calendar) =>
      calendar.projects.some((p) => p.project_id === projectId),
    )
    .map((c) => ({
      id: c.id,
      title: c.name.en,
      colorHex: c.color_hex,
      projectName: c.project?.name ?? "No Project",
    }));

  const [selectedCalendars, setSelectedCalendars] =
    useState<CalendarWithProjectName[]>(sharedCalendars);

  const toggleSelectCalendar = (calendar: CalendarWithProjectName) => {
    if (sharedCalendars.some((c) => c.id === calendar.id)) {
      return;
    }

    setSelectedCalendars((prev) => {
      if (prev.some((c) => c.id === calendar.id)) {
        return prev.filter((c) => c.id !== calendar.id);
      }
      return [...prev, calendar];
    });
  };

  const handleOk = async () => {
    const newCalendarsToInsert = selectedCalendars.filter(
      (calendar) =>
        !sharedCalendars.some((shared) => shared.id === calendar.id),
    );

    if (newCalendarsToInsert.length === 0) {
      setIsOpen(false);
      return;
    }

    await insertProjectCalendar({
      variables: {
        objects: newCalendarsToInsert.map((calendar) => ({
          calendar_id: calendar.id,
          project_id: projectId,
          created_by_user_id: authUser.uid,
        })),
      },
      update: (cache, { data }) => {
        if (!data?.insert_project_calendar)
          throw new Error("No data returned from insertProjectCalendar");
        const newProjectCalendars = data.insert_project_calendar.returning;

        cache.modify<GetDeliveryFieldsQuery>({
          fields: {
            calendar: (existingCalendars = [], { toReference }) => {
              const newCalendarRefs = newProjectCalendars.map(
                (projectCalendar) => toReference(projectCalendar.calendar),
              );
              return [...existingCalendars, ...newCalendarRefs];
            },
          },
        });
      },
    });

    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      title="Select Calendars from Another Project"
      onOk={handleOk}
      okText="Save"
      confirmLoading={loading}
    >
      <Card className="mt-2">
        <h3>Already Shared Calendars</h3>
        {sharedCalendars.map((calendar) => (
          <div
            key={calendar.id}
            className="flex items-center mt-1 justify-between"
          >
            <div className="flex items-center">
              <div
                className="w-1 h-1 rounded-full"
                style={{ backgroundColor: calendar.colorHex }}
              />
              <div className="font-accent w-8 text-static-primary ml-1">
                {calendar.title}
              </div>
              <span className="text-lightgrey ml-0.5">
                {calendar.projectName}
              </span>
            </div>
            <Icon icon={IconCheck} size="small" color="positive" />
          </div>
        ))}
      </Card>

      {selectedCalendars.some(
        (cal) => !sharedCalendars.some((sc) => sc.id === cal.id),
      ) && (
        <Card className="mt-2">
          <h3>Selected for Sharing</h3>
          {selectedCalendars
            .filter(
              (calendar) =>
                !sharedCalendars.some((sc) => sc.id === calendar.id),
            )
            .map((calendar) => (
              <div
                key={calendar.id}
                className="flex items-center mt-1 justify-between"
              >
                <div className="flex items-center">
                  <div
                    className="w-1 h-1 rounded-full"
                    style={{ backgroundColor: calendar.colorHex }}
                  />
                  <div className="font-accent w-8 text-static-primary ml-1">
                    {calendar.title}
                  </div>
                  <span className="text-lightgrey ml-0.5">
                    {calendar.projectName}
                  </span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectCalendar(calendar);
                  }}
                >
                  <Icon icon={IconX} size="small" color="negative" />
                </div>
              </div>
            ))}
        </Card>
      )}

      {Object.entries(
        calendars
          .filter(
            (calendar) => !sharedCalendars.some((sc) => sc.id === calendar.id),
          )
          .reduce((acc, calendar) => {
            const project = calendar.project?.name ?? "No Project";
            const projectId = calendar.project?.id ?? `no-project-${project}`;
            if (!acc[projectId]) {
              acc[projectId] = {
                name: project,
                calendars: [],
              };
            }
            acc[projectId].calendars.push({
              ...calendar,
              title: calendar.name.en,
              colorHex: calendar.color_hex,
              projectName: project,
            });
            return acc;
          }, {} as Record<string, { name: string; calendars: CalendarWithProjectName[] }>),
      ).map(([projectId, { name, calendars }]) => (
        <Card key={projectId} className="mt-2">
          <div className="font-bold text-lg">{name}</div>
          {calendars.map((calendar) => (
            <>
              <Card
                key={calendar.id}
                className={`flex items-center mt-1 h-4 justify-between cursor-pointer`}
                onClick={() => {
                  toggleSelectCalendar(calendar);
                }}
              >
                <div className="flex items-center">
                  <div
                    className="w-1 h-1 rounded-full"
                    style={{ backgroundColor: calendar.colorHex }}
                  />
                  <div className="font-accent min-w-16 max-w-16 text-static-primary ml-1 mr-2">
                    {calendar.title}
                  </div>

                  {selectedCalendars.some((c) => c.id === calendar.id) && (
                    <Icon icon={IconCheck} size="large" color="interactive" />
                  )}
                </div>
              </Card>
            </>
          ))}
        </Card>
      ))}
    </Modal>
  );
};

export default withCustomSuspense(SitedeliveryAddCalendarFromOtherProjectModal);
