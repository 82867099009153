import React, { FC, useState, useRef, useMemo } from "react";
import { Tag, Select, Card, Image } from "antd";
import Icon from "src/common/components/general/Icon";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import * as uuid from "uuid";
import {
  IconAlertTriangle,
  IconEdit,
  IconArrowUp,
  IconArrowDown,
  IconTrash,
} from "@tabler/icons";
import { cloneDeep } from "@apollo/client/utilities";
import {
  PtpQrTaskType,
  TextTranslationUnion,
} from "src/domain-features/sitesafety/entry-routes/qr-project-reports/routes/ptp/types/PtpQrUserTypes";
import { useCurrentLanguage } from "src/utility-features/i18n/context/languageHooks";
import UnsafeSpanEdit from "src/common/components/unsafe/UnsafeSpanEdit";
import compareStringsIgnoreCase from "src/common/functions/compareStringsIgnoreCase";

interface TaskViewQrPtpSubmissionProps {
  task: PtpQrTaskType;
  setTask: React.Dispatch<React.SetStateAction<PtpQrTaskType | undefined>>;
  isTurner: boolean;
  dataForPpe: Array<{
    id: string;
    description: string;
    ppes: Array<{ id: string; description: string }>;
  }>;
  dataForPermit: Array<{ id: string; description: string }>;
  dataForEcm: Array<{ id: string; description: string | undefined }>;
}

const TaskViewQrPtpSubmission: FC<TaskViewQrPtpSubmissionProps> = ({
  task,
  setTask,
  isTurner,
  dataForPpe,
  dataForPermit,
  dataForEcm,
}) => {
  const [addStepJHAId, setAddStepJHAId] = useState<string | undefined>(
    undefined,
  );
  const addHazForm =
    useRef<
      FModalRef<{ newHazard: string; newControl: string; ecms: string[] }>
    >(null);

  const lang = useCurrentLanguage();
  const [addHazJHAId, setAddHazJHAId] = useState<string | undefined>(undefined);
  const addStepForm = useRef<FModalRef<{ newStep: string }>>(null);
  const addPpeForm = useRef<FModalRef<{ ppe: Array<string> }>>(null);
  const addPermitForm = useRef<FModalRef<{ permit: Array<string> }>>(null);
  const [isPpeEditVisible, setIsPpeEditVisible] = useState(false);
  const [isPermitEditVisible, setIsPermitEditVisible] = useState(false);
  const mapEcms = (ecmId: string) => {
    const e = "39ab97a5-47ac-4e5d-b287-21c2cb016c16",
      c = "05660c6a-c370-4c82-86a9-e558772d7d56",
      m = "57774dd6-3e26-45aa-a7cd-c3cb518a75f9";
    if (ecmId === e) return "E";
    else if (ecmId === c) return "C";
    else if (ecmId === m) return "M";
    else return "";
  };

  const ppeMap = new Map<string, string>();
  const permitMap = new Map<string, string>();
  dataForPpe.forEach((category) => {
    category.ppes.forEach((ppe) => {
      ppeMap.set(ppe.id, ppe.description);
    });
  });
  dataForPermit.forEach((permit) => {
    permitMap.set(permit.id, permit.description);
  });

  const updateStepDescription = (stepId: string, value: string) => {
    const taskCopy = cloneDeep(task);
    const step = taskCopy.steps.find((step) => step.id === stepId);
    if (step) {
      step.description = {
        edited: true as const,
        editedValue: value,
      };
      setTask(taskCopy);
    }
  };

  const updateHazardDescription = (
    hazardId: string,
    stepId: string,
    value: string,
  ) => {
    const taskCopy = cloneDeep(task);
    const step = taskCopy.steps.find((step) => step.id === stepId);
    const hazard = step?.hazards.find((h) => h.id === hazardId);
    if (hazard) {
      hazard.description = {
        edited: true as const,
        editedValue: value,
      };
      setTask(taskCopy);
    }
  };

  const updateHazardControl = (
    hazardId: string,
    stepId: string,
    value: string,
  ) => {
    const taskCopy = cloneDeep(task);
    const step = taskCopy.steps.find((step) => step.id === stepId);
    const hazard = step?.hazards.find((h) => h.id === hazardId);
    if (hazard) {
      hazard.control = {
        edited: true,
        editedValue: value,
      };
      setTask(taskCopy);
    }
  };

  const updatePpes = (event: Array<string>) => {
    const taskDeepCopy = cloneDeep(task);
    taskDeepCopy.ppes = event.map((e: string) => ({
      id: e,
      description: ppeMap.get(e) ?? "",
    }));
    taskDeepCopy.ppes.sort((a, b) =>
      compareStringsIgnoreCase(a.description, b.description),
    );
    setTask(taskDeepCopy);
  };

  const updatePermit = (event: Array<string>) => {
    const taskCopy = cloneDeep(task);
    taskCopy.permits = event.map((e) => ({
      id: e,
      description: permitMap.get(e) ?? "",
    }));
    taskCopy.permits.sort((a, b) =>
      compareStringsIgnoreCase(a.description, b.description),
    );
    setTask(taskCopy);
  };

  const updateDescription = (value: string) => {
    const taskDeepCopy = cloneDeep(task);
    taskDeepCopy.description = {
      edited: true as const,
      editedValue: value,
    };
    setTask(taskDeepCopy);
  };

  const onOrderChange = async (from: number, to: number) => {
    if (from === to) return;
    const taskObjectDeepCopy = cloneDeep(task);
    taskObjectDeepCopy.steps[from] = task.steps[to];
    taskObjectDeepCopy.steps[to] = task.steps[from];
    setTask(taskObjectDeepCopy);
  };

  const deleteStep = (stepId: string) => {
    const taskDeepCopy = cloneDeep(task);
    taskDeepCopy.steps = taskDeepCopy.steps.filter(
      (step) => step.id !== stepId,
    );
    setTask(taskDeepCopy);
  };

  const deleteHazard = (hazardId: string, stepId: string) => {
    const taskDeepCopy = cloneDeep(task);
    const step = taskDeepCopy.steps.find((step) => step.id === stepId);
    if (!step) return;
    step.hazards = step.hazards.filter((hazard) => hazard.id !== hazardId);
    setTask(taskDeepCopy);
  };

  const langToShow =
    lang === "fr" || lang === "zh_hans" || lang === "it" ? "en" : lang;

  const getTranslationValue = (description: TextTranslationUnion) => {
    return description.edited
      ? description.editedValue
      : description.translation[langToShow];
  };

  return (
    <div className="bg-suplementary-1 ml-0.5 pl-0.25 pr-0.25 pt-1 m-fit pb-1 text-0.9 rounded-2">
      <p>
        JHA Title:
        <UnsafeSpanEdit
          defaultValue={getTranslationValue(task.description)}
          onChange={updateDescription}
        />
      </p>
      <br />
      <br />
      {task.showType === "images" ? (
        <div>
          <div className="font-accent">Images({task.images.length}):</div>
          <div className="mt-2 grid p-0.5 grid-cols-3 gap-1">
            {task.images.map((o, x) => (
              <div className="font-accent" key={x}>
                <Card
                  hoverable
                  className="w-full"
                  cover={
                    <div>
                      <Image src={o.url} alt={"Image " + (x + 1)} preview />
                    </div>
                  }
                ></Card>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <p>
            <span className="font-accent text-1">PPE</span>{" "}
            <span className="inline-block">
              {" (Personal Protective Equipment)" +
                " (" +
                (task.ppes ? task.ppes.length : "0") +
                ")"}

              <span className="ml-1 mt-0.25 inline-block">
                <Icon
                  color="interactive"
                  icon={IconEdit}
                  onClick={() => setIsPpeEditVisible((prev) => !prev)}
                ></Icon>
              </span>
            </span>
            <br />
            {!isPpeEditVisible &&
              task.ppes.map((ppe) => {
                return (
                  <>
                    <span>
                      <Tag
                        key={ppe.id}
                        id={ppe.id}
                        className={
                          "text-interactive-primary font-accent ml-1 mt-0.75 text-0.98"
                        }
                      >
                        {ppe.description}
                      </Tag>
                    </span>
                  </>
                );
              })}
            <FModal
              ref={addPpeForm}
              open={isPpeEditVisible}
              onCancel={() => setIsPpeEditVisible(false)}
              onOk={() => setIsPpeEditVisible(false)}
              title="Select PPE"
            >
              <Select
                onChange={(ppes) => updatePpes(ppes)}
                value={task.ppes.map((ppe) => ppe.id)}
                mode="multiple"
                size="large"
                showSearch={false}
                className="w-full"
              >
                {dataForPpe.map((c) => (
                  <Select.OptGroup key={c.id} label={c.description}>
                    {c.ppes.map((p) => (
                      <Select.Option
                        key={p.id}
                        value={p.id}
                        label={p.description}
                      >
                        {p.description}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            </FModal>
            <br /> <br />
          </p>
          <br />
          <br />
          <p>
            <span className="font-accent text-1">Permits or Tags</span>
            <span className="inline-flex">
              <span className="ml-0.25 mr-0.5">
                {" Required to Perform this JHA" +
                  " (" +
                  task.permits.length +
                  ")"}
                <span className="ml-1 mt-0.25 inline-block">
                  <Icon
                    color="interactive"
                    icon={IconEdit}
                    onClick={() => setIsPermitEditVisible((prev) => !prev)}
                  ></Icon>
                </span>
              </span>
            </span>

            <br />

            {!isPermitEditVisible &&
              task.permits.map((permit) => {
                return (
                  <>
                    <span>
                      <Tag
                        key={permit.id}
                        id={permit.id}
                        className={
                          "text-interactive-primary font-accent ml-1 mt-0.75 text-0.98"
                        }
                      >
                        {permit.description}
                      </Tag>
                    </span>
                  </>
                );
              })}

            <FModal
              ref={addPermitForm}
              open={isPermitEditVisible}
              onCancel={() => setIsPermitEditVisible(false)}
              onOk={() => setIsPermitEditVisible(false)}
              title="Select Permits"
            >
              <Select
                onChange={(permits) => updatePermit(permits)}
                value={task.permits.map((x) => x.id)}
                mode="multiple"
                size="large"
                showSearch={false}
                className="w-full"
              >
                {dataForPermit.map((p) => (
                  <Select.Option key={p.id} value={p.id} label={p.description}>
                    {p.description}
                  </Select.Option>
                ))}
              </Select>
            </FModal>

            <br />
            <br />
          </p>
          <br /> <br />
          <span className="inline-flex items-center">
            <div className="font-accent text-1 mt-1 mb-1">
              {task.steps.length} Steps
            </div>
          </span>
          <FModal
            ref={addStepForm}
            open={addStepJHAId === "step"}
            onCancel={() => {
              setAddStepJHAId(undefined);
              addStepForm.current?.form.resetFields();
            }}
            onOk={async () => {
              const newStep =
                addStepForm.current?.form.getFieldValue("newStep");
              if (newStep) {
                const newStepObj: (typeof task)["steps"][number] = {
                  id: uuid.v1(),
                  description: { edited: true, editedValue: newStep },
                  hazards: [],
                };
                const taskDeepCopy = cloneDeep(task);
                taskDeepCopy.steps.push(newStepObj);
                setTask(taskDeepCopy);
              }
              setAddStepJHAId(undefined);
              addStepForm.current?.form.resetFields();
            }}
          >
            <FModal.TextArea
              name="newStep"
              label={"New Step Description"}
              requiredMessage="Enter Step"
              required={true}
            />
          </FModal>
          {task.steps.map((step, i) => {
            return (
              <div key={step.id} className="bg-semantic-pending-light">
                <span className="w-full inline-flex items-center mt-1.5">
                  <span>
                    {i !== 0 && (
                      <Icon
                        color="interactive"
                        icon={IconArrowUp}
                        onClick={() => onOrderChange(i, i - 1)}
                      />
                    )}
                  </span>
                  <span className="ml-0.125">
                    {i !== task.steps.length - 1 && (
                      <Icon
                        color="interactive"
                        icon={IconArrowDown}
                        onClick={() => onOrderChange(i, i + 1)}
                      />
                    )}
                  </span>

                  <span
                    className={
                      i === 0 || i === task.steps.length - 1
                        ? "ml-1.5"
                        : "ml-0.5"
                    }
                  >
                    Step
                  </span>
                  <span className="ml-0.3"> {i + 1}:</span>
                  <UnsafeSpanEdit
                    defaultValue={getTranslationValue(step.description)}
                    onChange={(newValue) =>
                      updateStepDescription(step.id, newValue)
                    }
                  />
                  <span className="ml-auto">
                    <Icon
                      color="negative"
                      icon={IconTrash}
                      onClick={() => deleteStep(step.id)}
                    />
                  </span>
                </span>
                <br />
                <br />
                <span className="inline-flex items-center">
                  {step.hazards.length > 0 && (
                    <span className="pl-3 pt-0.5 text-1 text-grey">
                      {step.hazards.length}{" "}
                      {step.hazards.length > 1 ? "Hazards" : "Hazard"}
                    </span>
                  )}
                </span>

                <div className="bg-interactive-primary">
                  {addHazJHAId === "hazard" + i + "th Step" && (
                    <>
                      <FModal
                        ref={addHazForm}
                        open={addHazJHAId === "hazard" + i + "th Step"}
                        onCancel={() => setAddHazJHAId(undefined)}
                        onOk={async () => {
                          const newHazard =
                            addHazForm.current?.form.getFieldValue("newHazard");
                          const newControl =
                            addHazForm.current?.form.getFieldValue(
                              "newControl",
                            );

                          const newEcms =
                            addHazForm.current?.form.getFieldValue("ecms");

                          const ecmData = newEcms?.map((e: string) => ({
                            ecm_type_id: e,
                          }));

                          const ecmObject = ecmData?.map((ecm: any) => {
                            return {
                              id: ecm.ecm_type_id,
                              description: mapEcms(ecm.ecm_type_id),
                            };
                          });

                          if (newHazard && newControl) {
                            const taskCopy = cloneDeep(task);

                            const newHazardObj = {
                              control: {
                                edited: true as const,
                                editedValue: newControl,
                              },
                              description: {
                                edited: true as const,
                                editedValue: newHazard,
                              },
                              ecms: ecmObject ?? [],
                              id: uuid.v1(),
                            };
                            taskCopy.steps[i].hazards.push(newHazardObj);
                            setTask(taskCopy);
                          }
                          setAddHazJHAId(undefined);
                        }}
                      >
                        <FModal.TextArea
                          name="newHazard"
                          label={"New Hazard Description"}
                          requiredMessage="Enter Hazard"
                          // requiredMark={true}
                          required={true}
                        />{" "}
                        <FModal.TextArea
                          name="newControl"
                          label={"New Control Description"}
                          requiredMessage="Enter Control"
                          // requiredMark={true}
                          required={true}
                        />
                        {isTurner && (
                          <FModal.Select
                            name="ecms"
                            label="E,C,M"
                            className="w-full"
                            props={{
                              mode: "tags",
                              placeholder: "Select ECM",
                              options: dataForEcm.map((c) => ({
                                key: c.id,
                                value: c.id,
                                label: c.description,
                              })),
                            }}
                          />
                        )}
                      </FModal>
                    </>
                  )}
                  <div
                    className="bg-semantic-negative ml-1 p-1 text-white font-accent"
                    onClick={() => {
                      addStepJHAId === "hazard" + i + "th Step"
                        ? setAddHazJHAId(undefined)
                        : setAddHazJHAId("hazard" + i + "th Step");
                    }}
                  >
                    ＋ Add Hazard
                  </div>
                  {step.hazards.map((hazard, i2) => {
                    return (
                      <div
                        key={hazard.id}
                        className="bg-semantic-negative-light ml-1 p-1"
                      >
                        <p className="flex pt-1 pl-1">
                          <span>
                            <Icon
                              color="negative"
                              icon={IconAlertTriangle}
                            ></Icon>
                          </span>
                          <span className="ml-0.5">Hazard {i2 + 1}:</span>
                          <UnsafeSpanEdit
                            onChange={(newValue) => {
                              updateHazardDescription(
                                hazard.id,
                                step.id,
                                newValue,
                              );
                            }}
                            defaultValue={getTranslationValue(
                              hazard.description,
                            )}
                          />
                        </p>
                        <span className="float-right">
                          <Icon
                            color="negative"
                            icon={IconTrash}
                            onClick={() => deleteHazard(hazard.id, step.id)}
                          />
                        </span>
                        <p className="flex pl-1 mt-0.5">
                          <span className="ml-2">Control: </span>{" "}
                          <UnsafeSpanEdit
                            onChange={(newValue) => {
                              updateHazardControl(hazard.id, step.id, newValue);
                            }}
                            defaultValue={getTranslationValue(hazard.control)}
                          />
                        </p>
                        {isTurner && hazard.ecms && (
                          <p className="mt-0.5">
                            <span className="ml-2 pl-4">E,C,M: </span>{" "}
                            <span className="ml-2">
                              {hazard.ecms.map((ecm) => (
                                <span>
                                  <span key={ecm.id}>{mapEcms(ecm.id)}</span>
                                </span>
                              ))}
                            </span>
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div
            className="bg-interactive-primary w-full p-1 text-white font-accent"
            onClick={() => setAddStepJHAId("step")}
          >
            ＋ Add Step
          </div>
        </>
      )}
    </div>
  );
};
export default TaskViewQrPtpSubmission;
