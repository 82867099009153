import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import searchParamsProvider from "../../../../common/functions/searchParamsProvider";
import GCInspections from "./GCInspections";

const gcInspectionRoute: NestedRoute = {
  element: <Outlet />,
  handle: {
    breadcrumb: "Inspections",
  },
  children: [
    {
      path: ":subview",
      element: searchParamsProvider(GCInspections),
    },
    {
      path: "",
      element: <Navigate replace to="completed" />,
    },
  ],
};

export default gcInspectionRoute;
