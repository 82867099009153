import NestedRoute from "src/common/types/manual/NestedRoute";
import GCProjectReportVisitors from "./components/GCProjectVisitors";

const visitorsRoute: NestedRoute = {
  element: <GCProjectReportVisitors />,
  handle: {
    breadcrumb: "Visitors",
  },
};

export default visitorsRoute;
