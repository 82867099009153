import React, { useState } from "react";
import hexToRgba from "src/common/functions/hexToRgba";
import SitedeliveryCalendarEventDeliveryPopover from "../components/events/SitedeliveryCalendarEventDeliveryPopover";
import BorderTime from "../components/events/SitedeliveryEventBorderTime";
import { DeliveryType } from "../utilities/sitedeliveryTypes";
import clsx from "clsx";
import Separator from "src/common/components/general/Separator";

interface GCProjectCalendarSitedeliveryDeliveriesBlockProps {
  deliveryExtendedContentShownOnCard?: boolean; // shows delivery title and content without hovering
  deliveryInfo: DeliveryType;
  handleDeliverySelect?: (deliveryId: string) => void;
}

const GCProjectCalendarSitedeliveryDeliveriesBlock: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesBlockProps
> = (props) => {
  return (
    <div className="relative z-20 w-full h-full group">
      <BorderTime top time={props.deliveryInfo.from} />
      <SitedeliveryCalendarEventDeliveryPopover {...props.deliveryInfo}>
        <div
          onClick={() => {
            props.handleDeliverySelect &&
              props.handleDeliverySelect(props.deliveryInfo.id);
          }}
          className={clsx(
            "h-full relative w-full border rounded-0.25 hover:border-static-secondary cursor-pointer",
            !props.deliveryInfo.pendingApproval
              ? "bg-white shadow-small border-suplementary-3 "
              : "bg-suplementary-1 border-static-primary border-dashed",
          )}
        >
          <div className={clsx("flex flex-col h-full overflow-hidden")}>
            <div className={clsx("text-left p-0.25 text-0.75")}>
              {props.deliveryInfo.pendingApproval && (
                <>
                  <div className={"whitespace-nowrap"}>Pending approval...</div>
                  <Separator dark />
                </>
              )}
              <div
                className={`font-accent flex flex-row items-center gap-0.25`}
              >
                {"subcontractor" in props.deliveryInfo &&
                !!props.deliveryInfo.subcontractor
                  ? props.deliveryInfo.subcontractor.title
                  : props.deliveryInfo.title.text}
              </div>
              {props.deliveryExtendedContentShownOnCard &&
                !!props.deliveryInfo.title && (
                  <div className="text-left text-0.75">
                    {props.deliveryInfo.title.text}
                  </div>
                )}
            </div>
            {!!props.deliveryInfo.calendars?.length && (
              <div className="flex-1 p-px z-10 bg-white border-t border-white min-h-px sticky bottom-0 w-full h-full">
                <div className="flex flex-row h-full gap-px">
                  {props.deliveryInfo.calendars.map((calendar, index) => (
                    <div
                      key={index}
                      className="flex-1 w-0 text-0.75 flex flex-col h-full rounded-0.125 p-0.125"
                      style={{
                        backgroundColor: hexToRgba(calendar.colorHex, 0.5),
                      }}
                    >
                      <div className="w-full truncate">{calendar.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </SitedeliveryCalendarEventDeliveryPopover>
      <BorderTime bottom time={props.deliveryInfo.to} />
    </div>
  );
};

export default GCProjectCalendarSitedeliveryDeliveriesBlock;
