import React, { FC, useEffect } from "react";
import { useGetFlaggedTaskDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { useAddTaskToCompanyJhaLibraryMutation } from "src/common/types/generated/apollo/graphQLTypes";
import ViewAndEditTask from "./ViewAndEditTask";
import { GetFlaggedTaskDataOutput } from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import logo from "src/common/assets/logo.png";
import dayjs from "dayjs";
import CustomButton from "src/common/components/general/button/Button";
import { Popover, message } from "antd";
import ViewAndEditTaskType from "src/common/types/manual/ViewAndEditTaskType";

interface FlaggedJHAViewAndEditProps {
  taskId: string;
  requestId: string;
}

const FlaggedJHAViewAndEdit: React.FC<FlaggedJHAViewAndEditProps> = ({
  taskId,
  requestId,
}) => {
  const fetchData = useGetFlaggedTaskDataQuery({
    variables: { input: { taskId: taskId } },
  });

  const [addTaskToCompanyJHALibrary] = useAddTaskToCompanyJhaLibraryMutation({
    variables: { input: { taskId: taskId } },
  });

  if (!fetchData || !fetchData.data?.getFlaggedTaskData)
    return <LoadingContent />;

  const taskData = fetchData.data.getFlaggedTaskData;

  const flaggedTaskResponse: GetFlaggedTaskDataOutput = {
    task: {
      id: taskData.task.id,
      description: taskData.task.description,
      descriptionId: taskData.task.descriptionId,
      permits: taskData.task.permits.map((permit) => {
        return { id: permit.id, description: permit.description };
      }),
      ppes: taskData.task.ppes.map((ppe) => {
        return { id: ppe.id, description: ppe.description };
      }),
      steps: taskData.task.steps.map((step) => {
        return {
          id: step.id,
          description: step.description,
          hazards: step.hazards.map((h) => {
            return {
              id: h.id,
              description: h.description,
              control: h.control,
              ecms: h.ecms.map((ecm) => {
                return {
                  id: ecm.id,
                  description: ecm.description,
                };
              }),
            };
          }),
        };
      }),
    },

    permits: taskData.permits.map((permit) => {
      return { id: permit.id, description: permit.description };
    }),
    ppeCategories: taskData.ppeCategories.map((ppeCategory) => {
      return {
        id: ppeCategory.id,
        description: ppeCategory.description,
        ppes: ppeCategory.ppes.map((ppe) => {
          return { id: ppe.id, description: ppe.description };
        }),
      };
    }),

    ecms: taskData.ecms.map((ecm) => {
      return { id: ecm.id, description: ecm.description };
    }),
    flaggedAt: taskData.flaggedAt,
    flaggedBy: taskData.flaggedBy,
    flagMessage: taskData.flagMessage,
    projectName: taskData.projectName,
    projectGC: taskData.projectGC,
    isNotTurnersProject: taskData.isNotTurnersProject,
  };

  const viewAndEditTaskData: ViewAndEditTaskType = {
    description: taskData.task.description,
    descriptionId: taskData.task.descriptionId,
    ecms: taskData.ecms.map((ecm) => ({
      id: ecm.id,
      description: ecm.description,
    })),
    id: taskId,
    permits: taskData.permits.map((permit) => ({
      id: permit.id,
      description: permit.description,
    })),
    ppeCategories: taskData.ppeCategories.map((ppeCategory) => ({
      id: ppeCategory.id,
      description: ppeCategory.description,
      ppes: ppeCategory.ppes.map((ppe) => ({
        id: ppe.id,
        description: ppe.description,
      })),
    })),
    steps: taskData.task.steps.map((step) => ({
      description: step.description,
      id: step.id,
      hazards: step.hazards.map((haz) => ({
        id: haz.id,
        description: haz.description,
        control: haz.control,
        ecms: haz.ecms.map((ecm) => ({
          id: ecm.id,
          description: ecm.description,
        })),
      })),
    })),

    taskPpes: taskData.task.ppes.map((ppe) => ({
      id: ppe.id,
      description: ppe.description,
    })),

    taskPermits: taskData.permits.map((permit) => ({
      id: permit.id,
      description: permit.description,
    })),
  };

  return !flaggedTaskResponse.flaggedAt ? (
    <StyledContent>
      <div
        className="flex items-center justify-center p-1 cursor-pointer"
        onClick={() => window.open("https://siteform.io/", "_blank")}
      >
        <div>
          <img src={logo} className="w-12" />
        </div>
      </div>
      <div className="flex items-center justify-center mt-3 pb-1 text-1.5">
        {" "}
        The JHA has already been UNFLAGGED
      </div>
      <div className="flex items-center justify-center pb-2 mt-1 text-1.25 ">
        If you ❤️ SiteForm, please consider leaving a ⭐⭐⭐⭐⭐ Review in your
        App Store. Thanks!
      </div>
    </StyledContent>
  ) : (
    <StyledContent>
      <div
        className="flex items-center justify-center mb-4 cursor-pointer"
        onClick={() => window.open("https://siteform.io/", "_blank")}
      >
        <img src={logo} className="w-12" />
      </div>{" "}
      <div className=" ml-3 mt-2 mb-2 text-1.5">
        <div>{flaggedTaskResponse.projectGC}</div>
        <div className="mt-1">{flaggedTaskResponse.projectName}</div>
      </div>
      <div className="flex w-full min-w-full">
        <div className="ml-3 text-1.25 mb-4">
          JHA has been <b>FLAGGED FOR ACTION</b> by{" "}
          {flaggedTaskResponse.flaggedBy} on{" "}
          {dayjs(flaggedTaskResponse.flaggedAt).format("MMM DD, YYYY")}{" "}
          <Popover
            trigger={"hover"}
            content={
              <div className="w-32">
                {" "}
                <div className="text-1.2 mb-1 text-center align-middle">
                  Flagged for Action
                </div>
                <div className="text-1.2">
                  A Job Hazard Analysis (JHA or AHA) is flagged for action when
                  some part(s) of the information may not meet the industry or
                  project requirements and it needs to be edited. The reason for
                  flagging may be noted.
                  <br /> If this work has started, it is critical to update this
                  JHA ASAP so your crew(s) have accurate information.
                </div>
              </div>
            }
          >
            {" "}
            ℹ️{" "}
          </Popover>
          <div className="mt-1">
            <b>Reason to Flag:</b> {flaggedTaskResponse.flagMessage}{" "}
          </div>
        </div>
      </div>
      <ViewAndEditTask
        isNotTurnersProject
        taskId={taskId}
        data={viewAndEditTaskData}
        type="anonymous"
        requestId={requestId}
      />
      <div className="ml-3 mt-2">
        {" "}
        <CustomButton
          large
          label="Add to Library"
          secondary
          onClick={async () => {
            try {
              await addTaskToCompanyJHALibrary({
                variables: { input: { taskId: taskId } },
              });
              message.success("Task has been added to Company JHA Library");
            } catch (err) {
              message.error(
                "Task could not be added to Company JHA Library" +
                  " " +
                  String(err),
              );
            }
          }}
        />{" "}
      </div>
      <div className="ml-4 mt-1 text-1.2  mb-4">
        Add this JHA to your company JHA Library to make it available to other
        foremen on any project
      </div>
    </StyledContent>
  );
};

export default FlaggedJHAViewAndEdit;
