import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { GetGcOfficesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import getGcOrganizationUnitPath from "../utils/getGcOrganizationUnitPath";

type OfficeType = GetGcOfficesQuery["gc_office"][number];

const OfficeTable: React.FC<{
  offices: Array<OfficeType>;
  buTitle?: string | null;
  officeTitle: string;
  addButton?: React.ReactNode;
}> = ({ offices, officeTitle, buTitle, addButton }) => {
  const navigate = useNavigate();
  return (
    <Table
      title={() => (
        <div className="text-1.2 flex items-center">
          {officeTitle} ({offices.length})
          <div className="text-0.9 ml-1">{addButton}</div>
        </div>
      )}
      dataSource={offices}
      rowKey={(o) => o.id}
      onRow={(office) => ({
        onClick: () => {
          navigate(getGcOrganizationUnitPath("office", office.id));
        },
      })}
      columns={[
        {
          title: "",
          dataIndex: ["id"],
          render: (_, r, i) => i + 1,
          width: buTitle ? "10%" : "6",
        },
        {
          title: "Name",
          dataIndex: ["name"],
          width: "30%",
        },
        ...(buTitle
          ? [
              {
                title: buTitle,
                dataIndex: ["gc_business_unit", "name"],
                width: "30%",
              },
            ]
          : []),
        {
          title: "Projects",
          dataIndex: ["projects_aggregate", "aggregate", "count"],
          width: "20%",
          render: (v) => v || "",
        },
      ]}
    />
  );
};
export default OfficeTable;
