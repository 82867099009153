import { Navigate, RouteObject, useParams } from "react-router-dom";
import React from "react";

type RedirectLinkType = {
  path: string;
  destination: string;
};

export const redirectLinks: RedirectLinkType[] = [
  {
    path: "/gce/projects/:projectId/calendar/reportManagement",
    // localhost:3000/gce/projects/a0cf3dd2-906d-475f-b3c4-a3c2f39d65ff/calendar/reportManagement
    destination: "/gce/hierarchy/project/:projectId/settings/reports",
  },
  {
    path: "/gce/projects/:projectId/observations/observations/:obsId",
    destination:
      "/gce/hierarchy/project/:projectId/observations/observations/:obsId",
  },
  {
    path: "/gce/projects/:projectId/observations/observations",
    destination: "/gce/hierarchy/project/:projectId/observations/observations",
  },
];

export const Redirect: React.FC<Pick<RedirectLinkType, "destination">> = (
  props,
) => {
  const params = useParams();

  // Replace placeholders in destination with actual params
  const resolvedDestination = Object.keys(params).reduce(
    (url, key) => url.replace(`:${key}`, params[key] || ""),
    props.destination,
  );

  return <Navigate to={resolvedDestination} replace />;
};

export const redirectLinksRoutes: RouteObject[] = redirectLinks.map(
  (redirectLink) => ({
    path: redirectLink.path,
    element: <Redirect destination={redirectLink.destination} />,
  }),
);
