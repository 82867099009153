import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import GCDivisionDetail from "../../levels/division/detail/GCDivisionDetail";
import GCBusinessUnitDetail from "../../levels/business-unit/detail/GCBusinessUnitDetail";
import GCOfficeDetail from "../../levels/office/detail/GCOfficeDetail";

const GCOrgUnitSettingsGeneral: React.FC<OrganizationUnitProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  switch (organizationUnitType) {
    case "division":
      return <GCDivisionDetail divisionId={organizationUnitId} />;
    case "business_unit":
      return <GCBusinessUnitDetail businessUnitId={organizationUnitId} />;
    case "office":
      return <GCOfficeDetail officeId={organizationUnitId} />;
  }
};

export default withOrganizationUnitWrapper(GCOrgUnitSettingsGeneral);
