import React, { useContext } from "react";
import SiteOrientationStepsUIFullName from "src/domain-features/siteorientation/components/stepsUI/fullName/SiteOrientationStepsUIFullName";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import VisitorOrientationOrienteeContext from "src/domain-features/sitesafety/entry-routes/visitor/utils/visitorOrientationOrienteeContext";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";
import useSiteVisitor from "../../utils/siteVisitorHook";

export interface SiteOrientationInPersonWorkerStepsFullNameProps
  extends SiteOrientationStepProps {}

const VisitorOrientationStepsFullName: React.FC<
  SiteOrientationInPersonWorkerStepsFullNameProps
> = (props) => {
  const visitorContext = useSiteVisitor();

  const langStrings = useCurrentLangStrings();
  const handleNameSubmit = (fullName: string) => {
    visitorContext.updateVisitor((u) =>
      u ? { ...u, name: fullName } : { name: fullName },
    );
    props.onNext();
  };
  return (
    <SiteOrientationStepsUIFullName
      onNameSubmit={handleNameSubmit}
      langStrings={langStrings}
      defaultName={visitorContext.visitor?.name || ""}
      onBack={props.onBack}
      onNext={props.onNext}
    />
  );
};

export default VisitorOrientationStepsFullName;
