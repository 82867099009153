import { Button, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import sendHotjarEvent from "../../../../../utility-features/event-tracking/hotjar/sendHotjarEvent";

// Define if user can go forward

const AppPageHistoryNavigation: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
    sendHotjarEvent("history_back");
  };

  return (
    <div className={`flex flex-row items-center gap-0.25`}>
      <Tooltip title={"Go Back"}>
        <Button icon={<LeftOutlined />} onClick={goBack} />
      </Tooltip>
    </div>
  );
};

export default AppPageHistoryNavigation;
