import {
  IconCalendar,
  IconCopy,
  IconDotsVertical,
  IconDownload,
  IconPencil,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import iconLogo from "src/common/assets/icon-logo.png";
import { QRCodeCanvas } from "qrcode.react";
import React, { forwardRef, useState } from "react";
import Button from "src/common/components/general/button/Button";
import CounterLabel from "src/common/components/general/CounterLabel";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { InspectionQRTableQuery } from "src/common/types/generated/relay/InspectionQRTableQuery.graphql";
import { InspectionQRTable_inspection_qr_codes$data } from "src/common/types/generated/relay/InspectionQRTable_inspection_qr_codes.graphql";
import { OptionType } from "../../utils/siteInspectionTypes";
import CreateInspectionQRModal from "../modals/CreateInspectionQRModal";
import { Dropdown, message, notification } from "antd";
import Icon, { WarningFilled } from "@ant-design/icons";
import useUpdateInspectionQRCode from "../../utils/useUpdateInspectionQR";
import dayjs from "dayjs";
import Tag from "src/common/components/general/Tag";
import { CustomTableCellWrapper } from "src/common/components/tables/basic/CustomTableCell";
import getDateStr from "src/common/functions/dates/getDateStr";
import { useNavigate } from "react-router-dom";
import { useCopyToClipboard } from "usehooks-ts";
import BPopconfirm from "../../../../common/components/dialogs/BPopconfirm";
import inspectionQROptionsDeleteUpdater from "../../utils/inspectionQROptionsDeleteUpdater";
import { downloadQRCode } from "../../../../common/components/OpenQrCode";

type ColumnKeys =
  | "date"
  | "name"
  | "inspections"
  | "created_by"
  | "action"
  | "last_scanned_on"
  | "last_scanned_by";
const CONNECTION_NAME = "inspection_qr_code_connection";
type DConnection =
  InspectionQRTable_inspection_qr_codes$data[typeof CONNECTION_NAME];
export type InspectionQRType = DConnection["edges"][number]["node"];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  InspectionQRTableQuery,
  string
> & {
  showDateRangeFilter?: boolean;
  OnRowItemClick: (QRinst: InspectionQRType) => void;
  projectId: string;
  onEdit: (qr: InspectionQRType) => void;
  inspectionsInstancesOptions: Array<OptionType>;
};

const InspectionQRTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Inspection QR Codes",
      onRowClick,
      showDateRangeFilter,
      ...props
    },
    ref,
  ) => {
    const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>(
      {},
    );
    const [_, copy] = useCopyToClipboard();
    const navigate = useNavigate();
    const [updateQR] = useUpdateInspectionQRCode();
    const [openModal, setOpenModal] = useState(false);
    return (
      <>
        <CreateInspectionQRModal
          projectId={props.projectId}
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          onFinish={() => setOpenModal(false)}
          inspectionsInstancesOptions={props.inspectionsInstancesOptions}
        />
        <DataScrollTable<DConnection, ColumnKeys, InspectionQRTableQuery>
          {...props}
          newCustomTableLook
          ref={ref}
          title={title}
          defaultTableSort={{ key: "date", order: "desc" }}
          datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
          onRowClick={(qrInst) => {
            props.OnRowItemClick && props.OnRowItemClick(qrInst);
          }}
          topBarButtons={[
            ...(props.topBarButtons || []),
            {
              label: "Create New QR",
              icon: IconPlus,
              onClick: () => {
                setOpenModal(true);
              },
            },
          ]}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allInstancesConnection"}
          columns={[
            {
              title: " QR Code Name",
              key: "name",
              size: "md",
              sortable: true,
              dataIndex: ["name"],
              searchDataIndex: ["name"],
              contentType: {
                type: "row-title",
              },
            },
            {
              title: "Inspections",
              key: "inspections",
              size: "sm",
              dataIndex: ["inspection_qr_code_instances"],
              render: (_, row) => {
                return row.inspection_qr_code_instances.length ? (
                  row.inspection_qr_code_instances.length === 1 ? (
                    <Tag status="inactive">
                      {
                        row.inspection_qr_code_instances[0].inspection_instance
                          .name.en
                      }
                    </Tag>
                  ) : (
                    <CounterLabel
                      count={row.inspection_qr_code_instances.length}
                      label="Inspections"
                      popover={{
                        placement: "bottom",
                        trigger: "hover",
                        content: (
                          <div>
                            {row.inspection_qr_code_instances.map((qr) => (
                              <div
                                className="mt-0.5"
                                key={qr.inspection_instance.id}
                              >
                                {qr.inspection_instance.name.en}
                              </div>
                            ))}
                          </div>
                        ),
                      }}
                    />
                  )
                ) : (
                  ""
                );
              },
            },
            {
              title: "Last Scanned",
              key: "last_scanned_on",
              size: "ml",
              dataIndex: ["inspection_results", "created_at"],
              render: (_, row) => {
                return row.inspection_results.length > 0 ? (
                  <CustomTableCellWrapper>
                    <div className="flex flex-row gap-0.5 items-center ">
                      <Icon {...{ icon: IconCalendar, color: "static" }} />
                      <span className="text-1">
                        {getDateStr(
                          dayjs(row.inspection_results[0].created_at),
                          {
                            format: "full",
                            withTime: true,
                          },
                        )}
                      </span>{" "}
                    </div>
                  </CustomTableCellWrapper>
                ) : (
                  ""
                );
              },
            },
            {
              title: "Last Scanned By",
              key: "last_scanned_by",
              size: "md",
              dataIndex: ["inspection_results", "user", "name"],
              render: (_, row) =>
                row.inspection_results[0]
                  ? row.inspection_results[0].user_name ??
                    row.inspection_results[0].user?.name
                  : "",
            },

            {
              title: "Created On",
              key: "date",
              sortable: true,
              contentType: {
                type: "date",
                renderOptions: () => ({ format: "full", withTime: true }),
              },
              size: "ml",
              defaultSortOrder: "desc",
              dataIndex: ["created_at"],
            },
            {
              title: "Created By",
              key: "created_by",
              sortable: true,
              size: "sm",
              defaultSortOrder: "asc",
              dataIndex: ["created_by_user", "name"],
            },
            {
              title: "Action",
              key: "action",
              size: "icon",
              dataIndex: ["pk"],
              render: (pk, row) => {
                const downloadableFileName = row.name;
                return (
                  <div className="flex gap-0.5">
                    <div className="hidden">
                      <QRCodeCanvas
                        className="w-full h-full inset-0 cursor-pointer"
                        value={`${document.location.origin}/site-inspection/${pk}/${row.project_id}`}
                        imageSettings={{
                          src: iconLogo,
                          excavate: true,
                          height: 100,
                          width: 100,
                        }}
                        id={downloadableFileName}
                        bgColor={`transparent`}
                        size={800}
                        style={{ width: "200px", height: "200px" }}
                      />
                    </div>
                    <Dropdown
                      placement={"bottomRight"}
                      menu={{
                        items: [
                          {
                            key: "copy_link",
                            label: (
                              <Button
                                label="Copy Link"
                                icon={IconCopy}
                                green
                                onClick={() => {
                                  copy(
                                    `${document.location.origin}/site-inspection/${pk}/${row.project_id}`,
                                  );
                                  message.success("Link copied successfully");
                                }}
                              />
                            ),
                          },
                          {
                            key: "delete",
                            label: (
                              <BPopconfirm
                                cancelText={"No"}
                                icon={<WarningFilled color="red" />}
                                onConfirm={async (e) => {
                                  //delete worker
                                  e?.stopPropagation();
                                  if (pk) {
                                    setLoadingRows((prev) => ({
                                      ...prev,
                                      [pk]: true,
                                    }));
                                    try {
                                      await updateQR({
                                        variables: {
                                          id: pk,
                                          _set: {
                                            deleted_at: dayjs().toISOString(),
                                          },
                                        },
                                        updater: (store) => {
                                          inspectionQROptionsDeleteUpdater(
                                            row.id,
                                            store,
                                          );
                                        },
                                      });
                                      message.success(
                                        "Deleted Inspection QR code Successfully",
                                      );
                                    } catch (e) {
                                      notification.error({
                                        message:
                                          "Couldn't delete QR: " + row.name,
                                        description:
                                          e instanceof Error
                                            ? e.message
                                            : JSON.stringify(e),
                                      });
                                    }
                                    setLoadingRows((prev) => ({
                                      ...prev,
                                      [pk]: false,
                                    }));
                                  }
                                }}
                                placement="topRight"
                                title={
                                  <div className="w-24">
                                    <div className="text-semantic-negative font-accent">
                                      Delete this QR
                                    </div>
                                    <div>
                                      Do you want to delete this QR? deleting
                                      this means you agree to remove this QR
                                      from your project and anywhere it is used
                                      and scanned will make it invalid. <br />
                                      Are you sure?
                                    </div>
                                  </div>
                                }
                              >
                                <Button
                                  label="Delete "
                                  loading={!!loadingRows[pk]}
                                  delete
                                  icon={IconTrash}
                                  onClick={() => {}}
                                />
                              </BPopconfirm>
                            ),
                          },
                          {
                            key: "edit",
                            label: (
                              <Button
                                label="Edit"
                                icon={IconPencil}
                                onClick={() => props.onEdit(row)}
                              />
                            ),
                          },
                          {
                            key: "download_qr",
                            label: (
                              <Button
                                label="Download QR"
                                icon={IconDownload}
                                onClick={() => {
                                  downloadQRCode(downloadableFileName);
                                  message.success("Link copied successfully");
                                }}
                              />
                            ),
                          },
                        ],
                      }}
                      trigger={["click"]}
                    >
                      <Button
                        secondary
                        icon={IconDotsVertical}
                        onClick={() => console.log("")}
                      />
                    </Dropdown>
                  </div>
                );
              },
            },
          ]}
          queryNode={graphql`
            query InspectionQRTableQuery(
              $first: Int!
              $after: String
              $where: inspection_qr_code_bool_exp!
              $order_by: [inspection_qr_code_order_by!]!
            ) {
              ...InspectionQRTable_inspection_qr_codes
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...InspectionQRTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment InspectionQRTable_total on query_root
            @argumentDefinitions(
              where: { type: "inspection_qr_code_bool_exp!" }
            )
            @refetchable(queryName: "InspectionQRTableTotalRefetchableQuery") {
              allInstancesConnection: inspection_qr_code_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment InspectionQRTable_inspection_qr_codes on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "inspection_qr_code_bool_exp!" }
              order_by: { type: "[inspection_qr_code_order_by!]!" }
            )
            @refetchable(queryName: "InspectionQRTableRefetchableQuery") {
              inspection_qr_code_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "InspectionQRTable_inspection_qr_code_connection"
                  filters: []
                ) {
                edges {
                  node {
                    ...InspectionQRCodeFrag @relay(mask: false)
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default InspectionQRTable;
