import { UserOutlined } from "@ant-design/icons";
import React from "react";
import { Card } from "antd";
import { Outlet } from "react-router-dom";
import TabsDemoNestedTabLink from "./TabsDemoNestedTabLink";
import { RoutedMenuItem } from "../../../../../utility-features/navigation/routed-menu/routedMenu";

const tabsNumber = 5;

const tabsDemoNavigationItems: RoutedMenuItem[] = [];
for (let i = tabsDemoNavigationItems.length; i < tabsNumber; i++) {
  tabsDemoNavigationItems.push({
    path: `tab-${i}`,
    icon: <UserOutlined />,
    label: `Tab ${i}`,
    element: (
      <Card>
        <div>Tab {i}</div>
        <Outlet />
      </Card>
    ),
    children: [
      {
        path: "",
        element: <TabsDemoNestedTabLink />,
        label: "Nested Tab Link",
      },
      {
        path: `nested-tab`,
        element: <Card>Nested Tab Content</Card>,
        label: "Nested Tab",
      },
    ],
  });
}

export default tabsDemoNavigationItems;
