import { ConfigProvider } from "antd";
import React, { FC, useEffect, useState } from "react";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import VisitorOrientationStepsHello from "src/domain-features/sitesafety/entry-routes/visitor/steps/hello/VisitorOrientationStepsHello";

import VisitorOrientationOrienteeContext from "src/domain-features/sitesafety/entry-routes/visitor/utils/visitorOrientationOrienteeContext";
import VisitorOrientationStepsFullName from "src/domain-features/sitesafety/entry-routes/visitor/steps/fullName/VisitorOrientationStepsFullName";

import VisitorOrientationStepsCompany from "src/domain-features/sitesafety/entry-routes/visitor/steps/company/VisitorOrientationStepsCompany";
import VisitorOrientationStepsHours from "src/domain-features/sitesafety/entry-routes/visitor/steps/hours/VisitorOrientationStepsHours";
import VisitorOrientationStepsEmail from "src/domain-features/sitesafety/entry-routes/visitor/steps/email/VisitorOrientationStepsEmail";
import { VisitorType } from "src/domain-features/sitesafety/entry-routes/visitor/utils/visitorOrientationTypes";
import VisitoOrientationStepsSignature from "src/domain-features/sitesafety/entry-routes/visitor/steps/signature/VisitorOrientationStepsSignature";

import VisitorTnC from "src/domain-features/sitesafety/entry-routes/visitor/components/visitorTermsAndConditions";

import {
  GetVisitorLogDataV2Document,
  GetVisitorLogDataV2Query,
  GetVisitorLogDataV2QueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import SiteFeatureLayoutBody, {
  SiteFeatureLayoutBodyProps,
} from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import SiteOrientationLayoutWrapper from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutWrapper";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";
import setVisitorFromData from "../utils/setVisitorFromData";
import {
  useSiteFeatureStepsNavigationDirection,
  useSiteFeatureStepsNavigationEffect,
} from "src/common/components/layouts/steps-navigation-layout/siteFeatureRegistrationNavigationHooks";
import { useSuspenseQuery } from "@apollo/client";
import VisitorOrientationStepsComment from "../steps/comment/VisitorOrientationStepsComment";

const VisitorQuestionsBody: FC<ProjectIdProps> = ({ projectId }) => {
  const { goToPrevStep, goToNextStep, clearStepDirection, nextStepDirection } =
    useSiteFeatureStepsNavigationDirection();

  const {
    data: { getVisitorLogDataV2: logData },
    refetch,
  } = useSuspenseQuery<
    GetVisitorLogDataV2Query,
    GetVisitorLogDataV2QueryVariables
  >(GetVisitorLogDataV2Document, { variables: { input: { projectId } } });

  useEffect(() => {
    setVisitor((prev) => setVisitorFromData(logData, prev?.email));
  }, [logData]);

  const [visitor, setVisitor] = useState<VisitorType | null>(null);
  const skip = !!logData.user;
  const waiverText = logData.waiverText;
  const gcProj = logData.GCProject;
  const [showWaiverText, setShowWaiverText] = useState<boolean>(false);
  const steps: SiteFeatureLayoutBodyProps["steps"] = [
    {
      title: "Hello",
      body: (
        <VisitorOrientationStepsHello
          onNext={goToNextStep}
          gcName={gcProj.gcName}
          projectName={gcProj.projectName}
          logo={logData.logoToShow || undefined}
        />
      ),
      id: "hello",
    },
    {
      title: "Email",
      body: (
        <VisitorOrientationStepsEmail
          onNext={goToNextStep}
          onEnterEmail={async (email) => {
            const data22 = await refetch({ input: { projectId, email } });
            console.log(data22);
            localStorage.setItem("visitorEmail", email);
          }}
          onBack={goToPrevStep}
          gcId={gcProj.gcId}
        />
      ),
      id: "email",
    },

    {
      disabled: skip,
      title: "Full Name",
      body: (
        <VisitorOrientationStepsFullName
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      id: "fullName",
    },
    {
      disabled: skip,
      title: "Company",
      body: (
        <VisitorOrientationStepsCompany
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      id: "company",
    },

    {
      title: "Hours",
      body: (
        <VisitorOrientationStepsHours
          onNext={goToNextStep}
          onBack={goToPrevStep}
          gcName={gcProj.gcName}
        />
      ),
      id: "hours",
    },
    {
      disabled: !showWaiverText || false,
      title: "Terms",
      body: <VisitorTnC onBack={goToPrevStep} gcName={gcProj.gcName} />,
      id: "TnC",
    },
    {
      title: "Comment",
      body: (
        <VisitorOrientationStepsComment
          onBack={goToPrevStep}
          onNext={goToNextStep}
        />
      ),
      id: "comment",
    },

    {
      title: "Signature",
      body: (
        <VisitoOrientationStepsSignature
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      id: "signature",
    },
  ];
  const { currentStepId } = useSiteFeatureStepsNavigationEffect(
    nextStepDirection,
    clearStepDirection,
    steps,
  );
  return (
    <VisitorOrientationOrienteeContext.Provider
      value={{
        projectId,
        updateVisitor: setVisitor,
        visitor: visitor ?? undefined,
        companies: logData?.companies || [],
        skip,
        waiverText,
        updateShowWaiverText: setShowWaiverText,
        showWaiverText: showWaiverText,
        showTnc: !!logData?.showWaiverText,
      }}
    >
      <SiteFeatureLayoutBody
        steps={steps}
        currentStepId={currentStepId || undefined}
      />
    </VisitorOrientationOrienteeContext.Provider>
  );
};

const VisitorQuestions: FC<ProjectIdProps> = ({ projectId }) => {
  return (
    <SiteOrientationLayoutWrapper>
      <ConfigProvider componentSize={`large`}>
        <LanguageProvider>
          <VisitorQuestionsBody projectId={projectId} />
        </LanguageProvider>
      </ConfigProvider>
    </SiteOrientationLayoutWrapper>
  );
};

export default VisitorQuestions;
