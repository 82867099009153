import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import EmailProjectUserSettings from "../EmailProjectUserSettings";

const GCProjectSettingsIncidents: React.FC<ProjectIdProps> = ({
  projectId,
}) => {
  return (
    <div>
      <EmailProjectUserSettings
        key="incident_reporting"
        type="incident_reporting"
        title="Incident Management"
        description="Select project team members who should be copied on all incident reports"
        projectEmpFilter={{
          _or: [
            { direct_project_assign: { _eq: true } },
            { direct_project_assign: { _is_null: true } },
          ],
        }}
        projectId={projectId}
      />
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectSettingsIncidents);
