import { Button, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { ScrollTableColumn } from "src/common/components/tables/basic/ScrollTable";
import { ProjectTableQuery } from "src/common/types/generated/relay/ProjectTableQuery.graphql";
import { ProjectTable_projects$data } from "src/common/types/generated/relay/ProjectTable_projects.graphql";
import ChangeProjectHierarchyModal from "./ChangeProjectHierarchyModal";
import useGcEmployee from "../organization-unit/utils/useGcEmployee";
import getGcProjectPath from "../projects/getGcProjectPath";

type ColumnKeys =
  | "name"
  | "gc"
  | "subcontractors"
  | "division"
  | "business-unit"
  | "employees"
  | "office"
  | "status"
  | "action"
  | "extra";
const CONNECTION_NAME = "project_connection";
type DConnection = ProjectTable_projects$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  ProjectTableQuery,
  string
> & {
  showLevels: Array<"division" | "business-unit" | "office">;
};
type ColumnsType = Array<
  ScrollTableColumn<DConnection["edges"][number]["node"], ColumnKeys> & {
    // queryIncludeVarKey?: keyof ProjectTableQuery["variables"];
  }
>;
const ProjectTable = forwardRef<DataScrollTableRef, Props>(
  ({ title = "Projects", showLevels, onRowClick, ...props }, ref) => {
    const { general_contractor: gc } = useGcEmployee();

    const [selectedProjectId, setSelectedProjectId] = useState<string>();
    const navigate = useNavigate();
    if (!gc) return null;
    return (
      <DataScrollTable<DConnection, ColumnKeys, ProjectTableQuery>
        {...props}
        ref={ref}
        title={title}
        onRowClick={(project) => {
          if (onRowClick) onRowClick(project.pk);
          else if (gc) navigate(getGcProjectPath(project.pk));
        }}
        newCustomTableLook
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allProjectsConnection"}
        columns={[
          {
            title: "Name",
            key: "name",
            sortable: true,
            defaultSortOrder: "asc",
            dataIndex: ["name"],
            searchDataIndex: ["name"],
          },
          {
            title: "General Contractor",
            key: "gc",
            dataIndex: ["general_contractor", "name"],
            searchDataIndex: ["general_contractor", "name"],
          },
          ...(showLevels.includes("division") && gc.hierarchy_division_name
            ? [
                {
                  title: gc.hierarchy_division_name,
                  key: "division" as ColumnKeys,
                  dataIndex: ["gc_division", "name"],
                },
              ]
            : []),
          ...(showLevels.includes("business-unit") &&
          gc.hierarchy_business_unit_name
            ? [
                {
                  title: gc.hierarchy_business_unit_name,
                  key: "business-unit" as ColumnKeys,
                  dataIndex: ["gc_business_unit", "name"],
                },
              ]
            : []),
          ...(showLevels.includes("office") && gc.hierarchy_office_name
            ? [
                {
                  title: gc.hierarchy_office_name,
                  key: "office" as ColumnKeys,
                  dataIndex: ["gc_office", "name"],
                },
              ]
            : []),
          {
            title: "Active Employees",
            key: "employees",
            dataIndex: ["project_employees_aggregate", "aggregate", "count"],
            render: (v) => v || "",
          },
          {
            title: "Status",
            key: "status",
            dataIndex: ["completed"],
            render: (v) => (v ? <Tag>Inactive</Tag> : <Tag>Active</Tag>),
          },
          ...(gc
            ? ([
                {
                  title: "Action",
                  key: "action" as ColumnKeys,
                  dataIndex: ["pk"],
                  render: (_, r) => {
                    return (
                      <div onClick={(e) => e.stopPropagation()}>
                        <CustomSuspense>
                          {selectedProjectId === r.pk && (
                            <ChangeProjectHierarchyModal
                              projectId={r.pk}
                              showLevels={showLevels}
                              officeId={r.gc_office?.pk}
                              divisionId={r.gc_division?.pk}
                              businessUnitId={r.gc_business_unit?.pk}
                              onSuccess={() => {
                                if (ref && typeof ref !== "function") {
                                  ref.current?.refetch();
                                }
                                setSelectedProjectId(undefined);
                              }}
                              onCancel={() => setSelectedProjectId(undefined)}
                            />
                          )}
                          <Button
                            onClick={() => {
                              setSelectedProjectId(r.pk);
                            }}
                          >
                            Edit
                          </Button>
                        </CustomSuspense>
                      </div>
                    );
                  },
                },
              ] as ColumnsType)
            : []),
        ]}
        queryNode={graphql`
          query ProjectTableQuery(
            $first: Int!
            $after: String
            $where: project_bool_exp!
            $order_by: project_order_by!
          ) {
            ...ProjectTable_projects
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...ProjectTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment ProjectTable_total on query_root
          @argumentDefinitions(where: { type: "project_bool_exp!" })
          @refetchable(queryName: "ProjectTableTotalRefetchableQuery") {
            allProjectsConnection: project_connection(where: $where) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment ProjectTable_projects on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "project_bool_exp!" }
            order_by: { type: "project_order_by!" }
          )
          @refetchable(queryName: "ProjectTableRefetchableQuery") {
            project_connection(
              first: $first
              after: $after
              where: $where
              order_by: [{ completed: asc }, $order_by]
            )
              @connection(
                key: "ProjectTable_project_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  name
                  completed
                  project_employees_aggregate(
                    where: { status: { _eq: "Active" } }
                  ) {
                    aggregate {
                      count
                    }
                  }
                  gc_division {
                    pk: id @__clientField(handle: "pk")
                    id
                    name
                  }
                  gc_business_unit {
                    pk: id @__clientField(handle: "pk")
                    id
                    name
                  }
                  gc_office {
                    pk: id @__clientField(handle: "pk")
                    id
                    name
                  }
                  general_contractor {
                    name
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default ProjectTable;
