import React from "react";
import { notification, Typography } from "antd";
import contacts from "../../../common/constants/contacts";

const { Text, Paragraph, Link } = Typography;

const showUnexpectedErrorMessage = (sentryEventId?: string, errorTitle?: string) => {
    notification.error({
      message: errorTitle ?? "Unexpected Error Occurred",
      description: (
        <Typography>
          <Paragraph>
            We apologize for the inconvenience. Please try again, or refresh the page. If the issue continues, contact our support team.
            <br />
            Need immediate help?{" "}
            <Link
              href={`mailto:${contacts.supportEmail}?subject=Web App Issue`}
              target="_blank"
            >
              Contact us
            </Link>
            .
          </Paragraph>
          {!!sentryEventId && (
            <Paragraph>
              <Text type={"secondary"}>Error Id: </Text>
              <Text code>{sentryEventId}</Text>
            </Paragraph>
          )}
        </Typography>
      ),
    });
  }

export default showUnexpectedErrorMessage;