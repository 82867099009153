import React, { useState } from "react";
import { Card, Input, Select } from "antd";
import {
  IncidentCommonUserFragmentFragment,
  useCreateGeneralPersonMutation,
  useGetIncidentOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentInputField from "../basic/InputField";

interface IncidentCommonUserProps {
  title: string;
  projectId: string;
  user?: IncidentCommonUserFragmentFragment;
  onUpdateUser: (id: string, name: string) => Promise<void>;
  onUpdateUserInfo: (
    id: string,
    key: "email" | "phone_number",
    value: string,
  ) => Promise<void>;
  onUpdateGeneralPerson: (val: string) => Promise<void>;
}

const IncidentCommonUser: React.FC<IncidentCommonUserProps> = ({
  title,
  projectId,
  user,
  onUpdateUser,
  onUpdateUserInfo,
  onUpdateGeneralPerson,
}) => {
  const { data: incidentOptions, loading } = useGetIncidentOptionsQuery({
    variables: { projectId },
    fetchPolicy: "cache-first",
  });
  const [createGeneralPerson, { loading: creatingGeneralPerson }] =
    useCreateGeneralPersonMutation();

  const [generalPerson, setGeneralPerson] = useState({
    name: user?.name ?? "",
    email: user?.email ?? "",
    phone: user?.phone_number ?? "",
  });
  const [role, setRole] = useState(
    user?.role === "subcontractor_employee" ? "worker" : user?.role,
  );
  const [subId, setSubId] = useState<string | undefined>(
    user?.worker?.subcontractor_id ?? undefined,
  );
  if (loading) return <div className="flex ">Loading Options Data</div>;

  if (!incidentOptions) {
    return <div className="flex">Incident user options not found</div>;
  }

  const userOptions = (type: "worker" | "employee") => {
    if (type === "worker")
      return incidentOptions.project_worker
        .filter((pw) => pw.subcontractor_id === subId)
        .map((data) => ({
          label: data.user!.name,
          value: data.user!.id,
        }));
    else
      return incidentOptions.project_employee.map((data) => ({
        label: data.employee.user.name,
        value: data.employee.user.id,
      }));
  };

  const insertGeneralPerson = async () => {
    if (!generalPerson.name) return;

    const res = await createGeneralPerson({
      variables: {
        name: generalPerson.name,
        projectId: projectId,
      },
    });
    const generalPersonId = res.data?.createGeneralPerson;

    if (generalPersonId) {
      onUpdateUser(generalPersonId, generalPerson.name);
    }
  };

  const generalPersonId = user?.general_person?.uid;
  const userName = user?.name ?? "";

  return (
    <div className="top-5 flex flex-col">
      <Card title={title}>
        <div className="space-y-1.5">
          <div className="flex flex-col">
            <div className="text-sm font-medium text-gray-700 mb-0.5">
              Select one
            </div>
            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setRole(e);
              }}
              showSearch
              filterOption={(input, option) => {
                if (option?.label) {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                } else {
                  return false;
                }
              }}
              value={
                (role
                  ? role
                  : user?.role === "subcontractor_employee"
                  ? "worker"
                  : user?.role) ?? undefined
              }
              options={[
                { label: "Worker", value: "worker" },
                { label: "Employee", value: "employee" },
                { label: "Other", value: "general_person" },
              ]}
            />
          </div>

          {role === "worker" && (
            <div className="flex flex-col">
              <div className="text-sm font-medium text-gray-700 mb-1">
                Select Subcontractor
              </div>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => {
                  setSubId(e);
                }}
                showSearch
                filterOption={(input, option) => {
                  if (option?.label) {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  } else {
                    return false;
                  }
                }}
                value={subId}
                options={incidentOptions.project_subcontractor.map((ps) => ({
                  value: ps.subcontractor.id,
                  label: ps.subcontractor.name,
                }))}
              />
            </div>
          )}

          {(role === "employee" || (role === "worker" && !!subId)) && (
            <>
              <div className="flex flex-col">
                <div className="text-sm font-medium text-gray-700 mb-0.5">
                  Select Person
                </div>
                <Select
                  className="w-full"
                  value={user?.id ?? undefined}
                  options={userOptions(role)}
                  showSearch
                  filterOption={(input, option) => {
                    if (option?.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    } else {
                      return false;
                    }
                  }}
                  onChange={(_, option) => {
                    const user = option as {
                      label: string | undefined;
                      value: string | undefined;
                    };

                    if (user.value && user.label) {
                      onUpdateUser(user.value, user.label);
                    }
                  }}
                />
              </div>

              {user && (
                <IncidentInputField
                  label="Phone number"
                  onSave={(val) => {
                      onUpdateUserInfo(user.id, "phone_number", val);
                  }}
                  defaultText={user?.phone_number ?? undefined}
                />
              )}

              {user && (
                <IncidentInputField
                  label="Email"
                  onSave={(val) => {
                      onUpdateUserInfo(user.id, "email", val);
                  }}
                  defaultText={user.email ?? undefined}
                />
              )}
            </>
          )}

          {role === "general_person" && (
            <>
              <div className="mt-3">
                <div className="flex gap-1 items-center">
                  <div className="font-accent">Enter Details</div>
                </div>

                <div className="flex gap-1 items-center mt-0.75">
                  <div className="w-10">Name</div>
                  <Input
                    className="w-full"
                    value={generalPerson.name ?? user?.name}
                    onChange={(e) =>
                      setGeneralPerson((prev) => {
                        return { ...prev, name: e.target.value };
                      })
                    }
                    onBlur={insertGeneralPerson}
                    disabled={creatingGeneralPerson}
                  />
                </div>
                <div className="flex gap-1 items-center mt-0.5">
                  <div className="w-10">Phone number</div>
                  <Input
                    className="w-full"
                    value={generalPerson.phone ?? user?.phone_number}
                    onChange={(e) =>
                      setGeneralPerson((prev) => {
                        return { ...prev, phone: e.target.value };
                      })
                    }
                    onBlur={() => {
                      if (!!user) {
                        onUpdateUserInfo(
                          user.id,
                          "phone_number",
                          generalPerson.phone ?? user.phone_number,
                        );
                      } else {
                        insertGeneralPerson();
                      }
                    }}
                    disabled={creatingGeneralPerson}
                  />
                </div>
                <div className="flex gap-1 items-center mt-0.5">
                  <div className="w-10">Email</div>
                  <Input
                    className="w-full"
                    value={generalPerson.email ?? user?.email}
                    onChange={(e) =>
                      setGeneralPerson((prev) => {
                        return { ...prev, email: e.target.value };
                      })
                    }
                    onBlur={() => {
                      if (!!user) {
                        onUpdateUserInfo(
                          user.id,
                          "email",
                          generalPerson.email ?? user.email,
                        );
                      } else {
                        insertGeneralPerson();
                      }
                    }}
                    disabled={creatingGeneralPerson}
                  />
                </div>

                {generalPersonId && (
                  <IncidentInputField
                    label="Employer"
                    defaultText={user?.general_person?.employer ?? undefined}
                    onSave={(val) => {
                        onUpdateGeneralPerson(val);
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default IncidentCommonUser;
