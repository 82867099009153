import { useSuspenseQuery } from "@apollo/client";
import {
  GetOrgUnitNameDocument,
  GetOrgUnitNameQuery,
  GetOrgUnitNameQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";

const OrganizationUnitBreadcrumb: React.FC<OrganizationUnitProps> = (props) => {
  const unitId = props.organizationUnitId;
  const orgType = props.organizationUnitType;
  const { data } = useSuspenseQuery<
    GetOrgUnitNameQuery,
    GetOrgUnitNameQueryVariables
  >(GetOrgUnitNameDocument, {
    variables: {
      unitId,
      includeBu: orgType === "business_unit",
      includeDiv: orgType === "division",
      includeOffice: orgType === "office",
    },
  });
  const orgUnit =
    data.gc_office_by_pk ||
    data.gc_business_unit_by_pk ||
    data.gc_division_by_pk;
  return <>{orgUnit?.name} </>;
};

export default withOrganizationUnitWrapper(OrganizationUnitBreadcrumb);
