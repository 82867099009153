import { Table, TableColumnsType, Tooltip, Typography } from "antd";
import ExplanationIcon from "../../../../common/components/ExplanationIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import getGcProjectPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/getGcProjectPath";
import { OnsiteSettingsSwitchers } from "./onsiteSettings";

const SWITCH_COLUMN_WIDTH = `15%`;

type CrewOrSub = "crew" | "sub";

interface OnsiteSettingsUnitType<T = CrewOrSub> {
  id: string;
  name: React.ReactNode;
  type: T;
  switchers: OnsiteSettingsSwitchers;
}

export interface OnsiteCrewDataType extends OnsiteSettingsUnitType<"crew"> {}

export interface OnsiteSubDataType extends OnsiteSettingsUnitType<"sub"> {
  children?: OnsiteCrewDataType[];
}

type TableDataType = OnsiteSubDataType | OnsiteCrewDataType;

interface GCOnsiteSettingsTableUiProps {
  data?: OnsiteSubDataType[];
  projectId: string;
}

const GCOnsiteSettingsTableUi: React.FC<GCOnsiteSettingsTableUiProps> = (
  props,
) => {
  const navigate = useNavigate();
  const columns: TableColumnsType<TableDataType> = [
    {
      title: "Subcontractor/Crew",
      dataIndex: "name",
      key: "name",
      render: (_, rowData) => {
        if (rowData.type === "sub") {
          if (!!rowData.children?.length) {
            return (
              <>
                {rowData.name}
                <>
                  <Tooltip title={"Manage Crew(s)"}>
                    <Typography.Link
                      onClick={() => {
                        navigate(
                          getGcProjectPath(
                            props.projectId,
                            `subcontractors/${rowData.id}/workers-and-crews`,
                          ),
                        );
                      }}
                    >
                      {" "}
                      {`${rowData.children.length} Crew(s)`}
                    </Typography.Link>{" "}
                  </Tooltip>
                </>
              </>
            );
          } else {
            return rowData.name;
          }
        } else {
          return rowData.name;
        }
      },
    },
    {
      title: (
        <Typography.Text>
          Onsite Management{" "}
          <ExplanationIcon
            content={
              <p>
                Setting to “Onsite” will mark a Sub or Crew as working on the
                project indefinitely until they marked “Offsite”. This triggers
                your scheduled push notifications in the mobile app and emails.
                Onsite status is only Monday through Friday. Marking a
                subcontractor as Onsite on the weekends is done under
                “Dashboard” in the mobile app.
                <br />
                Reports are only required when the Sub or Crew is marked Onsite
              </p>
            }
          />
        </Typography.Text>
      ),
      dataIndex: "onsiteManagement",
      key: "onsiteManagement",
      width: SWITCH_COLUMN_WIDTH,
      render: (_, rowData) => rowData.switchers.onsite,
    },
    {
      title: "Pre-Task Plan",
      dataIndex: "ptp",
      key: "ptp",
      width: SWITCH_COLUMN_WIDTH,
      render: (_, rowData) => rowData.switchers.preTaskPlans,
    },
    {
      title: "Daily Report",
      dataIndex: "dailyReport",
      width: SWITCH_COLUMN_WIDTH,
      key: "dailyReport",
      render: (_, rowData) => rowData.switchers.dailyReports,
    },
    {
      title: "Toolbox Talk",
      dataIndex: "toolboxTalk",
      width: SWITCH_COLUMN_WIDTH,
      key: "toolboxTalk",
      render: (_, rowData) => rowData.switchers.toolboxTalks,
    },
  ];

  return (
    <Table<TableDataType>
      bordered
      rowKey={(rec) => rec.id}
      columns={columns}
      dataSource={props.data}
      pagination={false}
    />
  );
};

export default GCOnsiteSettingsTableUi;
