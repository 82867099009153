import { useContext } from "react";
import { AppNavigationLayoutContext } from "../../../../../../common/components/layouts/main/utils/AppNavigationLayoutContext";
import remToPx from "../../../../../../common/functions/remToPx";

/* Note from Seva:
  I'm not sure I like this solution...
  Because the deliveries component depends on its parents.
  E.g. it might be used within another parent, and we'll have to update this hook to take the new usage context into account
 */

const useDeliveryHeaderOffset = () => {
  const appLayoutContext = useContext(AppNavigationLayoutContext);
  const headerHeightPx = appLayoutContext?.headerHeightPx;
  return headerHeightPx ? `${headerHeightPx + remToPx(0.25)}px` : "0";
};

export default useDeliveryHeaderOffset;
