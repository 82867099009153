import { Alert, DatePicker, Form, Input, message, Modal, Select } from "antd";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import pluralize from "pluralize";
import {
  groupBlockOutIntervalsByDay,
  groupSubBlockIntervalsByDay,
} from "./events/DeliveryEvents";
import {
  eventTimeSeq,
  formatTime,
  mergeTimeSeq,
  MS_PER_HOUR,
} from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/timeEvents";
import useLangStrings from "src/utility-features/i18n/context/languageHooks";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  useInsertDeliveryMutation,
  GetDeliveryFieldsQuery,
  GetDeliveryFieldsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";

export interface SitedeliveryAddNewDeliveryProps {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  subcontractorIds: Array<string>;
  calendarIds: Array<string>;
  weekday: number;
}

export interface SubcontractorId {
  type: "sub";
  subId: string;
}

export interface GeneralContractorId {
  type: "gc";
  gcId: string;
}

export type DeliveryCompanyId = SubcontractorId | GeneralContractorId;

export type DeliveryCompany = DeliveryCompanyId & {
  name: string;
};

function GetDeliveryCompanyId(company: DeliveryCompanyId): string {
  switch (company.type) {
    case "sub":
      return `sub_${company.subId}`;
    case "gc":
      return `gc_${company.gcId}`;
    default:
      throw new Error("Uknown company type");
  }
}

interface SitedeliveryTimeBlockFormValues {
  name: string;
  calendars: Array<string>;
  deliveryCompanyId?: string;
  dateAndTime: dayjs.Dayjs;
  storageLocation: string;
  detail: string;
  duration: number;
}

interface SitedeliveryAddNewDeliveryModalProps {
  modalClose: () => void;
  modalVisible: boolean;
  projectId: string;
  isUserGC: boolean;
  gcId: string;
  timezone?: string;
  isApprovalNeeded: boolean;
  deliveryCompanies: Array<DeliveryCompany>;
  subcontractorId?: string;
  deliveryData: GetDeliveryFieldsQuery;
  queryVariables: GetDeliveryFieldsQueryVariables;
}

type SubBlockNode =
  GetDeliveryFieldsQuery["project_delivery_sub_block"][number];

type TimeInterval = {
  start: number;
  end: number;
};

const SitedeliveryAddNewDeliveryModal: React.FC<
  SitedeliveryAddNewDeliveryModalProps
> = (props) => {
  const authUser = useAuthUser();
  const langStrings = useLangStrings("en");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [form] = Form.useForm<SitedeliveryTimeBlockFormValues>();
  const { projectId } = useParams();
  const [insertDeliveryOne, { loading }] = useInsertDeliveryMutation();
  if (!projectId) throw new Error("Project id is not defined");

  const project = props.deliveryData.project[0];
  if (!project) {
    throw new Error("Project does not exist");
  }

  const block_out_edges = project.is_sitedelivery_block_out_active
    ? props.deliveryData.project_delivery_block_out
    : [];
  const block_outs = useMemo(
    () => groupBlockOutIntervalsByDay(block_out_edges),
    [block_out_edges],
  );

  const sub_block_edges = props.deliveryData.project_delivery_sub_block;
  const sub_blocks = useMemo(
    () => groupSubBlockIntervalsByDay(sub_block_edges),
    [sub_block_edges],
  );

  const getDeliveryCompany = (values: SitedeliveryTimeBlockFormValues) =>
    values.deliveryCompanyId
      ? props.deliveryCompanies.find(
          (company) =>
            GetDeliveryCompanyId(company) === values.deliveryCompanyId,
        )
      : null;

  const validateFields = (values: SitedeliveryTimeBlockFormValues) => {
    const startAtDateTime = values.dateAndTime;
    if (!startAtDateTime) {
      setErrorMessage("Please select Date");
      return false;
    }
    const startInProjectZone = dayjs(startAtDateTime).tz(
      project.timezone,
      true,
    );
    const isInPast = startInProjectZone < dayjs().subtract(2, "minutes");
    if (isInPast) {
      const now_local = dayjs();
      const now_project = now_local.clone().tz(project.timezone, true);
      const same_timezone = now_local.valueOf() === now_project.valueOf();
      // TODO: localize
      const suffix = same_timezone
        ? ""
        : ". Current project time is " +
          dayjs().tz(project.timezone).format("HH:mm");

      setErrorMessage("Cannot create or edit delivery in past" + suffix);
      return false;
    }

    const deliveryDurationHours = values.duration;

    const deliveryStartTime = startInProjectZone.valueOf();
    const deliveryEndTime =
      deliveryStartTime + deliveryDurationHours * MS_PER_HOUR;
    const startOfFirstDay = startInProjectZone.clone().startOf("day").valueOf();
    const startTime = deliveryStartTime - startOfFirstDay;
    const endTime = deliveryEndTime - startOfFirstDay;
    const startWeekDay = startInProjectZone.day();

    const overlappedDelivery = props.deliveryData.delivery.find((dc) => {
      const hasCommonCalendar = dc.calendars.some(
        (calendar) =>
          calendar.calendar && values.calendars.includes(calendar.calendar.id),
      );
      if (!hasCommonCalendar) return false;
      const startTime = dayjs(dc.start_at).valueOf();
      const endTime = startTime + dc.duration * MS_PER_HOUR;
      return deliveryStartTime < endTime && deliveryEndTime > startTime;
    });

    if (overlappedDelivery) {
      const calendars = overlappedDelivery.calendars.map(
        (c) => c.calendar.name.en,
      );
      const startTime = startInProjectZone.format("HH:mm");
      const endTime = startInProjectZone
        .clone()
        .add(overlappedDelivery.duration, "h")
        .format("HH:mm");
      const overlapWarning = langStrings.strings.deliveryOverlapWarning(
        calendars.join(", "),
        overlappedDelivery.name.en,
        startTime,
        endTime,
      );
      const delivery_stacking =
        project.project_setting?.delivery_stacking ?? true;
      if (delivery_stacking) {
        setWarningMessage(overlapWarning);
      } else {
        setErrorMessage(overlapWarning);
        return false;
      }
    }

    const same_calendar_sub_blocks = eventTimeSeq(
      sub_blocks,
      startWeekDay,
      (event) => {
        const hasCommonCalendar = event.block.calendars.some(
          (calendar) =>
            calendar.calendar &&
            values.calendars.includes(calendar.calendar.id),
        );
        return hasCommonCalendar;
      },
    );

    const deliveryCompany = getDeliveryCompany(values);

    const subId = deliveryCompany
      ? deliveryCompany.type == "sub"
        ? deliveryCompany.subId
        : null
      : props.subcontractorId;

    let block_out_count = 0;
    let sub_block_same_sub = 0;
    let overlapBlockedInterval = false;
    let subBlockForWarning = null;
    const blockedIntervals: TimeInterval[] = [];
    let currentTime = startTime;
    let lastTime = 0;
    const otherSubBlocks = new Set<SubBlockNode>();

    for (const event of mergeTimeSeq(
      eventTimeSeq(block_outs, startWeekDay),
      same_calendar_sub_blocks,
    )) {
      if (event.time > currentTime) {
        //        console.log(`move sub_block_same_sub = ${sub_block_same_sub} sub_block_other_sub = ${sub_block_other_sub} block_out_count = ${block_out_count}`)
        if (sub_block_same_sub === 0) {
          if (block_out_count > 0) {
            overlapBlockedInterval = true;
          } else if (otherSubBlocks.size > 0) {
            for (const block of otherSubBlocks) {
              subBlockForWarning = block;
              break;
            }
          }
        }
        currentTime = event.time;
      }

      const isBlocked = sub_block_same_sub === 0 && block_out_count > 0;
      if (event.time > lastTime) {
        if (isBlocked) {
          if (
            blockedIntervals.length > 0 &&
            blockedIntervals[blockedIntervals.length - 1].end === lastTime
          ) {
            blockedIntervals[blockedIntervals.length - 1].end = event.time;
          } else {
            blockedIntervals.push({ start: lastTime, end: event.time });
          }
        }
        lastTime = event.time;
      }

      if (currentTime >= endTime && !isBlocked) {
        break;
      }
      if (event.type === "block_out_start") {
        block_out_count++;
      } else if (event.type === "block_out_end") {
        block_out_count--;
      } else if (
        event.type === "sub_block_start" ||
        event.type === "sub_block_end"
      ) {
        const start = event.type === "sub_block_start";
        const same_sub = event.block.subcontractors.some(
          (sub) => sub.subcontractor.id === subId,
        )
          ? 1
          : 0;
        if (same_sub) {
          const delta = start ? 1 : -1;
          sub_block_same_sub += delta;
        } else {
          if (start) {
            otherSubBlocks.add(event.block);
          } else {
            otherSubBlocks.delete(event.block);
          }
        }
      }
    }

    //  'This delivery is scheduled during someone else's Block. You are only allowed to proceed if you have coordinated with that entity.'
    if (subBlockForWarning) {
      const companies = subBlockForWarning.subcontractors.map(
        (sub) => sub.subcontractor.name,
      );
      setErrorMessage(
        `This delivery is scheduled during ${companies.join(
          ", ",
        )}'s Delivery Block (${subBlockForWarning.start_time} - ${
          subBlockForWarning.end_time
        }). Only proceed if you have coordinated this delivery with them.`,
      );
      return false;
    }

    if (overlapBlockedInterval) {
      const relevantIntervals = blockedIntervals.filter(
        (v) => startTime < v.end && v.start < endTime,
      );
      const status = `${
        langStrings.strings.deliveryBlockOutError
      } ${relevantIntervals
        .map((v) => `${formatTime(v.start)} - ${formatTime(v.end)}`)
        .join(", ")}`;

      setErrorMessage(status);
      return false;
    }

    return true;
  };

  const calendars = useMemo(
    () =>
      props.deliveryData.calendar.filter(
        (calendar) =>
          !calendar.projects.find((project) => project.project.id === projectId)
            ?.is_archive,
      ) ?? [],
    [props.deliveryData],
  );

  const durationOptions = useMemo(
    () =>
      [...Array(47)].map((_, i) => {
        if (i % 2 == 0)
          return {
            title:
              (i != 0
                ? pluralize("hour", Math.floor((i + 1) * 0.5), true) + " "
                : "") +
              "30 " +
              "minutes",
            value: (i + 1) * 0.5,
          };
        else
          return {
            title: pluralize("hour", Math.floor((i + 1) * 0.5), true),
            value: (i + 1) * 0.5,
          };
      }),
    [],
  );

  const onAddNewDelivery = async () => {
    const values = await form.validateFields().catch(() => null);
    if (!values) return;
    const isValid = validateFields(values);
    if (!isValid) return;

    const deliveryCompany = getDeliveryCompany(values);

    const subId = deliveryCompany
      ? deliveryCompany.type === "sub"
        ? deliveryCompany.subId
        : null
      : props.subcontractorId;

    await insertDeliveryOne({
      variables: {
        object: {
          project_id: projectId,
          created_by_user_id: authUser.uid,
          duration: values.duration,
          name: {
            data: {
              en: values.name,
              original: values.name,
            },
          },
          detail: !values.detail
            ? null
            : {
                data: {
                  en: values.detail,
                  original: values.detail,
                },
              },
          storage_location: !values.storageLocation
            ? null
            : {
                data: {
                  original: values.storageLocation,
                },
              },
          subcontractor_id: subId,
          start_at: dayjs(values.dateAndTime)
            .tz(props.timezone, true)
            .toISOString(),
          calendars: {
            data: values.calendars.map((calendarId) => ({
              calendar_id: calendarId,
            })),
          },
          status:
            props.isUserGC || !props.isApprovalNeeded ? "Approved" : "Pending",
        },
        deleteFilterCalendarWhere: {
          calendar_id: { _in: values.calendars },
          filter_type: { _eq: "web-deliveries-hidden-calendar" },
          user_id: { _eq: authUser.uid },
          project_id: { _eq: projectId },
        },
      },
      update: (cache, { data }) => {
        if (!data?.insert_delivery_one) return;
        cache.modify<GetDeliveryFieldsQuery>({
          fields: {
            user_project_filter_calendar(existing = [], { readField }) {
              if (!data.delete_user_project_filter_calendar?.returning?.length)
                return existing;

              const deletedIds = new Set(
                data.delete_user_project_filter_calendar.returning.map(
                  (item) => item.id,
                ),
              );
              return existing.filter((item) => {
                const id: string | undefined = readField("id", item);
                if (!id) return false;
                return !deletedIds.has(id);
              });
            },

            delivery(existing = [], { toReference }) {
              if (!data.insert_delivery_one) return existing;
              const newDeliveryRef = toReference(data.insert_delivery_one);
              if (!newDeliveryRef) return existing;
              return [...existing, newDeliveryRef];
            },
          },
        });
      },
    });

    message.success("Delivery Added");
    props.modalClose();
  };

  return (
    <Modal
      title={"Add a New Delivery"}
      open={props.modalVisible}
      onCancel={() => {
        props.modalClose();
      }}
      onOk={() => {
        onAddNewDelivery();
      }}
      loading={loading}
      okText={"Add"}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        {errorMessage.length > 0 && (
          <Alert message={`NOTE: ${errorMessage}`} type="error" />
          //            <div className="mb-1" style={{color: 'red'}}>NOTE: {errorMessage}</div>
        )}
        {warningMessage.length > 0 && (
          <Alert message={warningMessage} type="warning" />
          //            <div className="mb-1" style={{color: '#ba8e23'}}>{warningMessage}</div>
        )}
        <Form.Item
          label={"Delivery Name"}
          name={"name"}
          rules={[{ required: true, message: "Enter Name of the delivery" }]}
        >
          <Input placeholder="What is a good name for this delivery?" />
        </Form.Item>
        <Form.Item
          label={"Select which Calendar(s)"}
          name={"calendars"}
          rules={[
            {
              required: true,
              message: "Select atleast one calendar for the delivery",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={
              "What access point(s), equipment, lifts are required for this delivery?"
            }
            showSearch
            filterOption={getNormalSelectOptionsFilter}
            options={calendars.map((c) => ({
              value: c.id,
              label: c.name.en,
            }))}
          />
        </Form.Item>

        {props.deliveryCompanies.length > 0 && (
          <Form.Item
            label={"Delivery for"}
            name={"deliveryCompanyId"}
            initialValue={GetDeliveryCompanyId(props.deliveryCompanies[0])}
            rules={[
              { required: true, message: "Select whom the delivery is for" },
            ]}
          >
            <Select
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              options={props.deliveryCompanies.map((company) => ({
                label: company.name,
                value: GetDeliveryCompanyId(company),
              }))}
            />
          </Form.Item>
        )}

        <Form.Item
          label={"Date and Time of Delivery"}
          name={"dateAndTime"}
          rules={[
            {
              required: true,
              message: "Select date and time of the delivery",
            },
          ]}
        >
          <DatePicker
            showTime={{
              format: "h:mm A",
              minuteStep: 5,
            }}
            minDate={dayjs().subtract(30, "days")}
            placeholder="When is the delivery arriving?"
            format="YYYY-MM-DD h:mm A"
            className="w-full"
          />
        </Form.Item>
        <Form.Item
          label={"Duration of Delivery"}
          name={"duration"}
          rules={[
            { required: true, message: "Select duration of the delivery" },
          ]}
        >
          <Select placeholder={"Duration of the Delivery"}>
            {durationOptions.map((option) => (
              <Select.Option
                id={option.value}
                value={option.value}
                label={option.title}
              >
                {option.title}
              </Select.Option>
            ))}{" "}
          </Select>
        </Form.Item>
        <Form.Item label={"Delivery Contents and Details"} name={"detail"}>
          <Input placeholder="What is being delivered?" />
        </Form.Item>
        <Form.Item label={"Storage Location"} name={"storageLocation"}>
          <Input placeholder="Where will it be stored?" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SitedeliveryAddNewDeliveryModal;
