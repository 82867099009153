import React from "react";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import GCReportsPermitsSettings from "src/domain-features/sitesafety/entry-routes/permits/components/settings/GCReportsPermitsSettings";

const GCCorporateOfficePermitsSettings: React.FC<
  CorporateAdminWrapperProps
> = () => {
  return <GCReportsPermitsSettings corporateLevel />;
};

export default withCorporateAdminWrapper(GCCorporateOfficePermitsSettings);
