import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import searchParamsProvider from "src/common/functions/searchParamsProvider";
// import GCProjectCalendarSettings from "src/root/routes/views/general-contractor/hierarchy/projects/calendar/settings/GCProjectCalendarSettings";
import siteboardEntryRoutes from "src/domain-features/siteboard/siteboardEntryRoutes";
import siteOrientationRoutes from "src/domain-features/siteorientation/siteOrientationEntryRoutes";
import siteSafetyRoutes from "src/domain-features/sitesafety/siteSafetyRoutes";
import Feedback from "src/root/routes/views/Feedback";
import JHAReviewedAndAcceptedWrapper from "src/root/routes/views/JHAReviewedAndAccepted";
import UploadSingleCertWrapper from "src/root/routes/views/UploadSingleCert";
import SCEmailReview from "src/root/routes/views/anonymous/SubAdminEmailReview";

import RejectedOrArchivedCerts from "src/root/routes/views/sf-employee/RejectedOrArchivedCertsTable";
import SFData from "src/root/routes/views/sf-employee/SFData";
import SFEmployee from "src/root/routes/views/sf-employee/SFEmployee";
import SFProfile from "src/root/routes/views/sf-employee/SFProfile";
import SFRequestedJHAs from "src/root/routes/views/sf-employee/SFRequestedJHAs";
import SFRequestedSDS from "src/root/routes/views/sf-employee/SFRequestedSDS";
import SFRequestedSubcontractors from "src/root/routes/views/sf-employee/SFRequestedSubcontractors";
import SFUpdateCertsData from "src/root/routes/views/sf-employee/SFUpdateCertsData";
import SFVerifyProfilePhotoTable from "src/root/routes/views/sf-employee/SFVerifyProfilePhoto";
import SFShowWorkerTable from "src/root/routes/views/sf-employee/SFWorkerToVerifyTable";
import VerifyAndAddJHAs from "src/root/routes/views/sf-employee/VerifyAndAddJHAs";
import VerifyCerts from "src/root/routes/views/sf-employee/VerifyCerts";
import VerifySubcontractor from "src/root/routes/views/sf-employee/VerifySubcontractor";
import Subcontractor from "src/root/routes/views/subcontractor/Subcontractor";
import SCEmployees from "src/root/routes/views/subcontractor/people/employees/SCEmployees";
import SCPeople from "src/root/routes/views/subcontractor/people/employees/SCPeople";
import SecretCodeSignInLink from "src/utility-features/authorization/SecretCodeSignInLink";
import adminRoute from "./views/admin/adminRoute";
import GCInviteSubAdminsToAddJhas from "./views/general-contractor/hierarchy/projects/subcontractors/sub-info/GCInviteSubAdminsToAddJhas";
import VerifyAndAddSDS from "./views/sf-employee/VerifyAndAddSDS"; // import SCWorker from "src/root/routes/views/subcontractor/people/workers/SCWorker";
import siteInspectionEntryRoutes from "src/domain-features/siteinspection/siteInspectionEntryRoutes";
import SiteOrientationCertificateUpload from "src/domain-features/siteorientation/components/SiteOrientationCertificateUpload";
import SiteOrientationCertificateUploadBySubadmin from "src/domain-features/siteorientation/components/SiteOrientationCertificateUploadBySubadmin";
import VisitorTnC from "src/domain-features/sitesafety/entry-routes/visitor/components/visitorTermsAndConditions";
import FlaggedJHAViewAndEdit from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/FlaggedTaskEmailLinkView";
import UnflagTaskConfirmation from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/UnflagTaskConfirmation";
import siteSafetyDataSheetRoutes from "src/domain-features/sitesafety/safety-data-sheets/siteSafetyDataSheetEntryRoutes";
import siteSafetyPlanRoutes from "src/domain-features/sitesafety/siteSafetyPlan/siteSafetyPlanEntryRoutes";
import SubProjectMobilizationPseudoLoginView from "src/domain-features/sitesafety/sub-project-mobilization/components/SubProjectMobilizationPseudoLoginView";
import SCProjectCrew from "src/domain-features/worker-crew/entry-routes/sub/SCProjectCrew";
import workerEntryRoutes from "src/domain-features/worker-web-app/workerEntryRoutes";
import GuestCovidFail from "src/root/routes/views/GuestCovidFail";
import GuestCovidPass from "src/root/routes/views/GuestCovidPass";
import GuestCovidQuestions from "src/root/routes/views/GuestCovidQuestions";
import SCBilling from "src/root/routes/views/subcontractor/billing/SCBilling";
import SCWorkers from "src/root/routes/views/subcontractor/people/workers/SCWorkers";
import SCProfile from "src/root/routes/views/subcontractor/profile/SCProfile";
import SCProject from "src/root/routes/views/subcontractor/projects/SCProject";
import SCProjectTask from "src/root/routes/views/subcontractor/projects/SCProjectTask";
import SCProjects from "src/root/routes/views/subcontractor/projects/SCProjects";
import SCProjectsNavigation from "src/root/routes/views/subcontractor/projects/SCProjectsNavigation";
import SCTask from "src/root/routes/views/subcontractor/tasks/SCTask";
import SCTasks from "src/root/routes/views/subcontractor/tasks/SCTasks";
import SCTasksNavigation from "src/root/routes/views/subcontractor/tasks/SCTasksNavigation";
import AToken from "src/utility-features/authorization/AToken";
import ForgotPassword from "src/utility-features/authorization/ForgotPassword";
import ResetPassword from "src/utility-features/authorization/ResetPassword";
import Invite from "src/utility-features/invitations/Invite";
import InviteSuccessMobile from "src/utility-features/invitations/InviteSuccessMobile";
import NestedRoute from "../../common/types/manual/NestedRoute";
import VisitorOrientationDone from "../../domain-features/sitesafety/entry-routes/visitor/components/VisitorOrientationDone";
import VisitorQuestions from "../../domain-features/sitesafety/entry-routes/visitor/components/VisitorQuestions";
import SFDrugtestTable from "./views/sf-employee/SFDrugtestTable";
import VerifyDrugtest from "./views/sf-employee/VerifyDrugtest";
import SCBillingProjectsNavigation from "./views/subcontractor/projects/SCBillingProjectsNavigation"; // import DevelopmentPlayground from "./views/DevelopmentPlayground";
import { UserDataProvider } from "src/utility-features/authorization/UserDataProvider";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";
import { isDevelopmentEnvironment } from "../../common/constants/environment";
import csrRoute from "src/utility-features/customer-portal/entryRoute";
import PtpQrCodeView from "src/domain-features/sitesafety/entry-routes/qr-project-reports/routes/ptp/components/PtpQrCodeView";
import DataErasureRequestScreen from "src/utility-features/DataErasureRequestScreen";
import SCChildSubsView from "./views/subcontractor/projects/child-subs/SCChildSubsView";
import developmentPlaygroundRoute from "./views/development-playground/developmentPlaygroundRoute";
import { redirectLinksRoutes } from "./redirectLinks";
import gcRoutes from "./views/general-contractor/gcRoutes";
import PageNotFound from "../../utility-features/error-handling/components/PageNotFound";

const Login = React.lazy(
  () => import("src/utility-features/authorization/Login"),
);

const homeRoute: NestedRoute = {
  errorElement: <PageNotFound />,
  element: <Outlet />,
  children: [
    ...redirectLinksRoutes,
    { path: "webform/erasure", element: <DataErasureRequestScreen /> },
    {
      path: "orientations/:projectId",
      ...siteOrientationRoutes.remoteOrientation,
    },
    {
      path: "orientations/test/:projectId",
      ...siteOrientationRoutes.orientationTest,
    },
    {
      path: "universal/orientation",
      ...siteOrientationRoutes.universalOrientation,
    },
    {
      path: "uo/:code",
      ...siteOrientationRoutes.universalOrientationShort,
    },
    {
      path: "ptp/:projectId",
      element: <PtpQrCodeView />,
    },
    {
      path: "",
      element: (
        <UserDataProvider>
          <Outlet />
        </UserDataProvider>
      ),
      children: [
        {
          path: "siteboard/:projectId", // A link localhost:3000/siteboard/4d7a8072-fdef-467c-b708-ba42e3cf7fb8
          ...siteboardEntryRoutes.mainEntry,
        },
        {
          path: "qr", //example: http://localhost:3000/qr/p/944334ea-32e7-4246-9693-e7247e4c1afd
          ...siteSafetyRoutes.qrProjectReports,
        },
        { path: "agc", ...siteOrientationRoutes.agcDashboard },
        {
          path: "site-inspection/:qrCodeId/:projectId",
          ...siteInspectionEntryRoutes.workerInspection,
        },
        {
          path: "requestToSub/:requestId",
          element: searchParamsProvider(SCEmailReview),
        },
        {
          path: "flagged-jha-edit/:taskId/:requestId",
          element: searchParamsProvider(FlaggedJHAViewAndEdit),
        },

        {
          path: "unflagged-jha-confirmation/:taskId",
          element: searchParamsProvider(UnflagTaskConfirmation),
        },
        {
          path: "invite/:inviteId",
          element: searchParamsProvider(Invite),
        },
        {
          path: "im",
          element: searchParamsProvider(InviteSuccessMobile),
        },
        {
          path: "covid/:projectId/form",
          element: searchParamsProvider(GuestCovidQuestions),
        },
        {
          path: "covid/:projectId/pass",
          element: searchParamsProvider(GuestCovidPass),
        },
        {
          path: "covid/:projectId/fail",
          element: searchParamsProvider(GuestCovidFail),
        },
        {
          path: "visitors/:projectId/form",
          element: searchParamsProvider(VisitorQuestions),
        },
        {
          path: "visitors/:projectId/form/success",
          element: searchParamsProvider(VisitorOrientationDone),
        },
        {
          path: "visitors/:projectId/form/visitorTnC",
          element: searchParamsProvider(VisitorTnC),
        },
        {
          path: "atoken",
          element: searchParamsProvider(AToken),
        },
        {
          path: "admin",
          ...adminRoute,
        },
        {
          path: "reviewedandacceptedtask",
          element: (
            <RequireRoleWrapper requiredRole={["pseudo_worker"]}>
              <JHAReviewedAndAcceptedWrapper />
            </RequireRoleWrapper>
          ),
        },
        {
          path: "send-invite-link-to-subadmins",
          element: (
            <RequireRoleWrapper requiredRole="pseudo_worker">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: searchParamsProvider(GCInviteSubAdminsToAddJhas),
            },
          ],
        },
        {
          path: "sub-admin-mobilization",
          element: (
            <RequireRoleWrapper requiredRole="mobilization_user">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: searchParamsProvider(
                SubProjectMobilizationPseudoLoginView,
              ),
            },
          ],
        },
        {
          path: "upload-certificates-by-worker",
          element: (
            <RequireRoleWrapper requiredRole="pseudo_worker">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: (
                <LanguageProvider>
                  <SiteOrientationCertificateUpload />
                </LanguageProvider>
              ),
            },
          ],
        },
        {
          path: "upload-certificates-by-subadmin",
          element: (
            <RequireRoleWrapper requiredRole="subcontractor_employee">
              <Outlet />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "",
              element: (
                <LanguageProvider>
                  <SiteOrientationCertificateUploadBySubadmin />
                </LanguageProvider>
              ),
            },
          ],
        },
        {
          path: "worker",
          ...workerEntryRoutes,
        },
        {
          path: "gce",
          ...gcRoutes,
        },
        { path: "csr", ...csrRoute },
        {
          path: "sfe",
          element: (
            <RequireRoleWrapper requiredRole="sf_employee">
              <CustomSuspense>
                <SFEmployee />
              </CustomSuspense>
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "profile",
              element: searchParamsProvider(SFProfile),
              children: [],
            },
            {
              path: "",
              element: searchParamsProvider(SFData),
              children: [
                {
                  path: "verify",
                  children: [
                    {
                      path: "updateCerts",
                      element: searchParamsProvider(SFUpdateCertsData),
                    },
                    {
                      path: "certificate",
                      element: searchParamsProvider(SFShowWorkerTable),
                    },
                    {
                      path: "certificate/:workerId",
                      element: searchParamsProvider(VerifyCerts),
                    },
                    {
                      path: "drugtest",
                      element: searchParamsProvider(SFDrugtestTable),
                    },
                    {
                      path: "drugtest/:certId/:workerId",
                      element: searchParamsProvider(VerifyDrugtest),
                    },
                    {
                      path: "rejected_archived",
                      element: searchParamsProvider(RejectedOrArchivedCerts),
                    },
                    {
                      path: "profile_photo",
                      element: searchParamsProvider(SFVerifyProfilePhotoTable),
                    },
                    {
                      path: "jha",
                      element: searchParamsProvider(SFRequestedJHAs),
                    },
                    {
                      path: "sds",
                      element: searchParamsProvider(SFRequestedSDS),
                    },
                    {
                      path: "sds/:requestId",
                      element: searchParamsProvider(VerifyAndAddSDS),
                    },
                    {
                      path: "jha/:requestId",
                      element: searchParamsProvider(VerifyAndAddJHAs),
                    },
                    {
                      path: "subcontractors",
                      element: searchParamsProvider(SFRequestedSubcontractors),
                    },
                    {
                      path: "subcontractors/:certId",
                      element: searchParamsProvider(VerifySubcontractor),
                    },
                    {
                      path: "",
                      element: <Navigate to={"certificate"} />,
                    },
                  ],
                },
                {
                  path: "",
                  element: <Navigate to={"verify"} />,
                },
              ],
            },
          ],
        },
        {
          path: "sub",
          element: (
            <RequireRoleWrapper requiredRole="subcontractor_employee">
              <Subcontractor />
            </RequireRoleWrapper>
          ),
          children: [
            {
              path: "profile",
              element: <SCProfile />,
            },
            // {
            //   path: "observations",
            //   ...observationEntryRoutes.scObservationRoute,
            // },
            {
              path: "feedback",
              element: <Feedback />,
            },
            { path: "toolbox-talks", ...siteSafetyRoutes.scTBTs },
            {
              path: "reports",
              ...siteSafetyRoutes.scReports,
            },
            {
              path: "people",
              element: <SCPeople />,
              children: [
                {
                  path: "workers",
                  element: <Outlet />,
                  children: [
                    {
                      path: "verify",
                      element: (
                        <SCWorkers showEmployeeVerificationModal={true} />
                      ),
                    },
                    {
                      path: "",
                      element: <SCWorkers />,
                    },
                  ],
                },
                {
                  path: "employees",
                  element: <SCEmployees />,
                },
                { path: "", element: <Navigate to={"workers"} /> },
                {
                  path: "",
                  element: <Navigate to={`workers`} replace />,
                },
              ],
            },
            {
              path: "tasks",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <SCTasks />,
                },
                {
                  path: ":taskId",
                  element: <SCTasksNavigation />,
                  children: [{ path: "", element: <SCTask /> }],
                },
              ],
            },
            {
              path: "sds",
              ...siteSafetyDataSheetRoutes.scSdsRoute,
            },
            { path: "safety-plan", ...siteSafetyPlanRoutes.safetyPlanRoute },
            {
              path: "projects",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <SCProjects />,
                },
                {
                  path: ":projectId",
                  element: <SCProjectsNavigation />,
                  children: [
                    {
                      path: "child-sub/:childSubId",
                      element: <SCChildSubsView />,
                    },
                    {
                      path: ":subview",
                      element: searchParamsProvider(SCProject),
                    },
                    {
                      path: "",
                      element: <Navigate replace to="workers-and-crews" />,
                    },

                    {
                      path: "crew",
                      element: <SCProjectCrew />,
                    },
                    {
                      path: "task",
                      element: <SCProjectTask />,
                    },
                  ],
                },
                { path: "", element: <Navigate to="workers" /> },
              ],
            },
            {
              path: "billing",
              element: <SCBillingProjectsNavigation />,
              children: [
                {
                  path: ":projectId",
                  element: <SCBilling />,
                },
              ],
            },

            // {
            //   path: "companySettings",
            //   element: <SCCompanySettings />,
            // },

            {
              path: "",
              element: <Navigate to="people" replace />,
            },
          ],
        },
        {
          path: "upload/:docId",
          element: (
            <RequireRoleWrapper
              requiredRole={["employee", "worker", "subcontractor_employee"]}
            >
              <UploadSingleCertWrapper />
            </RequireRoleWrapper>
          ),
        },
        {
          path: "worker-universal-orientation",
          ...siteOrientationRoutes.workerUniversalOrientation,
        },

        {
          path: "orientation/quiz",
          ...siteOrientationRoutes.quizQR,
        },
        {
          path: "singInLink/:code",
          // Used by qrcode
          element: searchParamsProvider(SecretCodeSignInLink),
        },
        {
          path: "sl/:code",
          element: searchParamsProvider(SecretCodeSignInLink),
        },
        {
          path: "resetPassword/:forgotPasswordKey",
          element: searchParamsProvider(ResetPassword),
        },
        {
          path: "forgotPassword",
          element: searchParamsProvider(ForgotPassword),
        },
        {
          path: "",
          element: <Login />,
        },
      ],
    },
    // development playground is used for testing and should be only available in dev environment
    ...(isDevelopmentEnvironment
      ? [
          {
            path: "development-playground",
            ...developmentPlaygroundRoute,
          },
        ]
      : []),
  ],
};

export default homeRoute;
