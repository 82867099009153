import {
  useUpdateIncidentUserMutation,
  Address_Insert_Input,
  GetIncidentByPkQuery,
  useUpsertAddressMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const useUpdateAddress = () => {
  const [upsertAddress] = useUpsertAddressMutation();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();
  const authUser = useAuthUser();

  const updateAddress = async (
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    object: Omit<Address_Insert_Input, "id">,
    type: "home" | "employer",
    incidentUserId: string,
    userType: "injured-user" | "witness",
  ) => {
    const res = await upsertAddress({
      variables: { object: object },
    });
    const insertedAddress = res.data?.insert_address_one;
    if (!insertedAddress || !incidentUserId) return;

    const userName =
      incident.injured_users.find(
        (incidentUser) => incidentUser.id === incidentUserId,
      )?.user?.name ?? "";

    const incidentUsers =
      userType === "injured-user" ? incident.injured_users : incident.witnesses;
    const updatedIncidentUsers: typeof incidentUsers = incidentUsers.map(
      (incidentUser) => {
        if (incidentUser.id === incidentUserId)
          return {
            ...incidentUser,
            home_address:
              type === "home" ? insertedAddress : incidentUser.home_address,
            employer_address:
              type === "employer"
                ? insertedAddress
                : incidentUser.employer_address,
          };
        return incidentUser;
      },
    );

    const updatedIncident: typeof incident = {
      ...incident,
      injured_users:
        userType === "injured-user"
          ? updatedIncidentUsers
          : incident.injured_users,
      witnesses:
        userType === "witness" ? updatedIncidentUsers : incident.witnesses,
    };

    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Updated ${type} address for the ${
      userType === "injured-user" ? "injured user" : "witness"
    } ${userName} `;

    await updateIncidentUser({
      variables: {
        set:
          type === "home"
            ? { home_address_id: insertedAddress.id }
            : { employer_address_id: insertedAddress.id },
        id: incidentUserId,
        objects: {
          patch: patch,
          comment: comment,
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
          edit_type: "incident-user-address-edit",
        },
      },
    });
  };

  return [updateAddress];
};

export default useUpdateAddress;
