import { Space, Button } from "antd";
import { useState } from "react";
import AddProjectEmployeeModal from "src/common/components/dialogs/AddProjectEmployeeModal";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import {
  GetAdminProjectQuery,
  useInsertProjectEmployeesMutation,
  useGetGeneralContractorEmployeesQuery,
  Order_By,
  GetNewProjectEmployeeDocument,
  GetAdminProjectDocument,
} from "src/common/types/generated/apollo/graphQLTypes";

const ProjEmployeesTable: React.FC<{
  projectId: string;
  projEmployees: GetAdminProjectQuery["project_employee"];
  loading: boolean;
}> = ({ loading, projEmployees, projectId }) => {
  const [insertProjectEmployees, { loading: insertingProjectEmployee }] =
    useInsertProjectEmployeesMutation();
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const { data: projectEmployeeData, loading: projectEmployeeLoading } =
    useGetGeneralContractorEmployeesQuery({
      variables: {
        order_by: [
          {
            user: {
              name: Order_By.Asc,
            },
          },
        ],
        where: {
          //          general_contractor: { projects: { id: { _eq: projectId } } },
          general_contractor: {
            gc_projects: { project_id: { _eq: projectId } },
          },
          _not: { employee_projects: { project_id: { _eq: projectId } } },
        },
      },
    });
  if (projectEmployeeLoading) return <LoadingContent />;
  return (
    <StyledContent padding backgroundColor="white">
      <AddProjectEmployeeModal
        gcEmployees={projectEmployeeData?.general_contractor_employee || []}
        visible={addEmployeeOpen}
        onCancel={() => setAddEmployeeOpen(false)}
        onCreate={(values) => {
          insertProjectEmployees({
            awaitRefetchQueries: true,
            variables: {
              objects: [
                { employee_id: values.employeeUid, project_id: projectId },
              ],
            },
            refetchQueries: [
              {
                query: GetNewProjectEmployeeDocument,
                variables: { projectId },
              },
              { query: GetAdminProjectDocument, variables: { projectId } },
            ],
          });
          setAddEmployeeOpen(false);
        }}
      />
      <BaseTable
        loading={loading}
        title={() => (
          <Space direction="vertical">
            <h3>Project Employees</h3>
            <Button
              type="primary"
              loading={insertingProjectEmployee}
              onClick={() => setAddEmployeeOpen(true)}
            >
              Add Employee
            </Button>
          </Space>
        )}
        rowKey={(pe) => pe.employee.uid}
        dataSource={projEmployees}
        summary={() => (
          <tr>
            <th>Total</th>
            <td colSpan={4}>{projEmployees.length}</td>
          </tr>
        )}
        columns={[
          {
            title: "Name",
            key: "name",
            dataIndex: ["employee", "user", "name"],
          },
          {
            title: "Title",
            key: "title",
            dataIndex: ["employee", "employee_title", "name"],
          },
          {
            title: "Phone",
            key: "phone",
            dataIndex: ["employee", "user", "phone_number"],
          },
          {
            title: "Email",
            key: "email",
            dataIndex: ["employee", "user", "email"],
          },
          {
            title: "Username",
            key: "username",
            dataIndex: ["employee", "user", "username"],
          },
        ]}
      />
    </StyledContent>
  );
};
export default ProjEmployeesTable;
