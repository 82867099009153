import React from "react";
import { SubOnsiteData } from "../performance/GCSubOnsitePerformanceTable";
import GCOnsiteSettingsTableUi, {
  OnsiteSubDataType,
} from "./GCOnsiteSettingsTableUi";
import useGetSwitchers from "./useGetSwitchersOld";

interface GCOnsiteSettingsPermanentProps {
  projectId: string;
  subOnsiteData?: SubOnsiteData[];
}

const GCOnsiteSettingsPermanent: React.FunctionComponent<
  GCOnsiteSettingsPermanentProps
> = ({ projectId, subOnsiteData }) => {
  // const switchers = useGetOnsiteSwithcers(projectId);

  // function to create switcher for each cell

  // const createSwitcher: (
  //   unitType: CrewOrSub,
  //   unitId: string,
  //   switcherKey: OnsiteSettingsSwitcherKey,
  // ) => SwitchProps = (unitType, unitId, switcherKey) => {
  //   const disabledSwitchProps: SwitchProps = { disabled: true };
  //   if (unitType === "sub") {
  //     const sub = subOnsiteData?.find((sub) => sub.subId === unitId);
  //     if (!sub) return disabledSwitchProps;
  //     return switchers.sub[switcherKey](sub);
  //   } else if (unitType === "crew") {
  //     const sub = subOnsiteData?.find((sub) =>
  //       sub.crews.some((crew) => crew.crewId === unitId),
  //     );
  //     if (!sub) return disabledSwitchProps;
  //     const crew = sub.crews.find((crew) => crew.crewId === unitId);
  //     if (!crew) return disabledSwitchProps;
  //     return switchers.crew[switcherKey](crew, sub);
  //   }
  //   return disabledSwitchProps;
  // };

  // Table columns
  const { getSubSwitchersFor, getCrewSwitchersFor } =
    useGetSwitchers(projectId);
  const tableData: OnsiteSubDataType[] = [];
  subOnsiteData?.forEach((sub) => {
    const subSwitchers = getSubSwitchersFor(sub);
    const nonDeletedCrews = sub.crews.filter((c) => !c.deleted_at);
    const showCrewData = nonDeletedCrews.length > 1;
    tableData.push({
      id: sub.subId,
      name: sub.subName,
      switchers: subSwitchers,
      type: "sub",
      children: showCrewData
        ? nonDeletedCrews.map((crew) => {
            const crewSwitchers = getCrewSwitchersFor(crew, sub);
            return {
              id: crew.crewId,
              name: crew.crewName,
              switchers: crewSwitchers,
              type: "crew",
            };
          })
        : undefined,
    });
  });

  return <GCOnsiteSettingsTableUi projectId={projectId} data={tableData} />;
};

export default GCOnsiteSettingsPermanent;
