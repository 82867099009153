import { GCOrganizationUnitType } from "../../../components/hiearchy-navigation/gcHierarchyTreeTypes";
import { getOrganizationUnitNavigationItems } from "./getOrganizationUnitNavigationItems";
import useGcEmployee from "./useGcEmployee";

const useGetOrganizationUnitNavigationItems = (
  orgUnitType?: GCOrganizationUnitType,
) => {
  const gce = useGcEmployee();
  return getOrganizationUnitNavigationItems(
    gce.general_contractor,
    orgUnitType,
  );
};

export default useGetOrganizationUnitNavigationItems;
