import React from "react";
import SiteOrientationGcQrCode from "./SiteOrientationGcQrCode";
import * as uuid from "uuid";
import { message } from "antd";
import { auth } from "../../../../../../../../common/functions/firebase";
import useInsertSecretCode from "../../utils/useInsertSecretCode";
import buildSecretCodeUpdater from "../../utils/buildSecretCodeUpdater";
import SwitchWithText from "src/common/components/SwitchWithText";

export interface SiteOrientationGcQrCodeInPersonProps {
  projectId: string;
  destinationUrl?: string;
  workerIsScanning: boolean;
  onWorkerScanQRSettingChange: (newVal: boolean) => void;
}

const SiteOrientationGcQrCodeInPerson: React.FC<
  SiteOrientationGcQrCodeInPersonProps
> = ({
  projectId,
  destinationUrl,
  onWorkerScanQRSettingChange,
  workerIsScanning,
}) => {
  const insertSecretCode = useInsertSecretCode();
  const userId = auth.currentUser?.uid;

  if (!userId) {
    throw new Error(`Orientation Settings user is not signed in`);
  }
  const generateQrCodeUrl = async () => {
    const finalUrl = `/gce/orientation/${projectId}`;
    const id = uuid.v4();
    await insertSecretCode({
      variables: {
        projectId,
        userId,
        id,
        only_project_data: false,
        finalUrl,
      },
      updater: buildSecretCodeUpdater("GCProjectOrientation_in_person_code"),
    }).catch((err) => {
      message.error("Error: " + err);
    });
  };
  return (
    <SiteOrientationGcQrCode
      title={
        <>
          In-Person Registration <br />
          (Keep this QR code PRIVATE)
        </>
      }
      body={
        <div>
          <SwitchWithText
            text="Turn ON if workers are scaning this QR with their Device"
            onChange={(e) => {
              onWorkerScanQRSettingChange(e.valueOf());
            }}
            checked={workerIsScanning}
          />
          <ul className="p-1 list-disc text-static-secondary">
            <li>
              If you are not doing in-person orientations then you will not use
              this code.
            </li>
            <li>Start an in-person orientation by scanning this code.</li>
            <li>
              Workers that registered with the first QR code are listed on the
              screen. Select any of the workers that are present for this
              orientation session. Then START the session and hand the iPad or
              tablet to the first worker. Existing workers will select their
              name and sign, which documents they have completed orientation.
            </li>
            <li>New Workers will register during the orientation.</li>
          </ul>
        </div>
      }
      qrCode={{
        destinationUrl: workerIsScanning
          ? `${document.location.origin}/orientations/${projectId}/inperson`
          : destinationUrl,
        alwaysVisible: true,
        generateQrCodeUrl: generateQrCodeUrl,
        downloadableFileName: "In-Person_Orientation",
      }}
    />
  );
};

export default SiteOrientationGcQrCodeInPerson;
