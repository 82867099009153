import { graphql } from "babel-plugin-relay/macro";
import React, { forwardRef, useEffect, useState } from "react";
import { PermitTable_permits$data } from "src/common/types/generated/relay/PermitTable_permits.graphql";
import { PermitTable_UpdateLadder_Mutation } from "src/common/types/generated/relay/PermitTable_UpdateLadder_Mutation.graphql";
import { PermitTableQuery } from "src/common/types/generated/relay/PermitTableQuery.graphql";

import {
  IconArrowBack,
  IconBriefcase,
  IconCheck,
  IconMapPin,
} from "@tabler/icons";
import { Input } from "antd";
import { ColumnFilterItem } from "antd/lib/table/interface";
import dayjs from "dayjs";
import Icon from "src/common/components/general/Icon";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import capitalize from "src/common/functions/capitalize";
import capitalizePhrase from "src/common/functions/capitalizePhrase";
import { auth } from "src/common/functions/firebase";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import useInsertPermitHistoryWithPermit from "../entry-routes/permits/components/utils/useInsertPermitHistoryWithPermit";
import getNotPermissibleReadingsCount from "../entry-routes/permits/components/utils/getNotPermissibleReadingsCount";

export type ColumnKeys =
  | "date"
  | "type"
  | "project"
  | "subcontractor"
  | "status"
  | "location"
  | "description"
  | "workers"
  | "extra"
  | "ladder_number"
  | "returned_at";
const CONNECTION_NAME = "permit_connection";
type DConnection = PermitTable_permits$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  PermitTableQuery,
  { type: string; pk: string }
> & {
  hotWorkPermitName?: string;
  gcAddLadderIdentification?: (isAccessLadder: boolean) => boolean;
  showDateRangeFilter?: boolean;
  ladderInspectionRequired?: boolean;
  defaultSortColumnKey?: ColumnKeys;
  defaultCustomInitialSort?: boolean;
  defaultSortOrder?: "desc" | "asc";
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    PermitTableQuery
  >["columns"];
};

const allStatusFilterList: Array<ColumnFilterItem> = [
  { text: "Submitted", value: "submitted" },
  { text: "Resubmitted", value: "resubmitted" },
  { text: "Closed", value: "closed" },
  { text: "Approved", value: "approved" },
  { text: "Rejected", value: "rejected" },
  { text: "Inspect", value: "inspect" },
];

const getStatusValue = (
  status:
    | "submitted"
    | "resubmitted"
    | "closed"
    | "approved"
    | "rejected"
    | "inspect"
    | string,
) => {
  switch (status) {
    case "submitted":
      return 1;
    case "resubmitted":
      return 2;
    case "approved":
      return 3;
    case "closed":
      return 4;
    case "inspect":
      return 5;
    default:
      return 6;
  }
};
const useUpdateLadderPermit = () =>
  useAsyncMutation<PermitTable_UpdateLadder_Mutation>(
    graphql`
      mutation PermitTable_UpdateLadder_Mutation(
        $permitId: uuid!
        $_set: ladder_permit_set_input!
      ) {
        update_ladder_permit_by_pk(
          pk_columns: { permit_id: $permitId }
          _set: $_set
        ) {
          ladder_identification
          id
          returned_at
          returned_marked_by_uid
          returned_marked_by_user {
            name
          }
        }
      }
    `,
  );
const EditLadderIdentificationCell: React.FC<{
  permit_id: string;
  permitStatus: string;
  ladder_permit: NonNullable<
    PermitTable_permits$data["permit_connection"]["edges"][number]["node"]["ladder_permit"]
  >;
}> = ({ ladder_permit, permit_id, permitStatus }) => {
  const [editing, setEditing] = useState(!ladder_permit.ladder_identification);
  // const [val, setVal] = useState(value);
  const [updateLadder] = useUpdateLadderPermit();
  const [value, setValue] = useState(ladder_permit.ladder_identification);
  const insertPermitHistoryWithPermit = useInsertPermitHistoryWithPermit();
  useEffect(() => {
    if (editing) {
      setValue(ladder_permit.ladder_identification);
    }
  }, [editing]);

  const onSave = async () => {
    if (!value?.trim()) {
      return;
    }
    const prevVal = ladder_permit.ladder_identification;
    if (value !== prevVal) {
      try {
        await updateLadder({
          variables: {
            permitId: permit_id,
            _set: { ladder_identification: value },
          },
          optimisticResponse: {
            update_ladder_permit_by_pk: {
              ...ladder_permit,
              ladder_identification: value,
            },
          },
        });
      } catch (err) {
        setValue(prevVal);
        throw err;
      }
      insertPermitHistoryWithPermit({
        permitId: permit_id,
        newStatus: permitStatus, // here we will not change permit status
        historyObjects: [
          {
            notify_users: false,
            comment: {
              data: {
                original: `Ladder Identification number changed from ${prevVal} to ${value}`,
              },
            },
          },
        ],
      });
    }
    setEditing(false);
  };
  const onCancel = () => {
    setEditing(false);
  };

  if (editing && auth.currentUser) {
    return (
      <Input
        value={value || undefined}
        onChange={(e) => setValue(e.target.value)}
        size="large"
        className="min-w-0.5"
        onBlur={onSave}
        onPressEnter={onSave}
        onKeyDown={(e) => {
          if (e.key === "Esc" || e.key === "Escape") {
            e.stopPropagation();
            e.preventDefault();
            setEditing(false);
          }
        }}
      />
    );
  }
  return (
    <div
      className="editable-cell-value-wrap min-w-0.5"
      onClick={() => setEditing(true)}
    >
      {value}
    </div>
  );
};

const PermitTable = forwardRef<DataScrollTableRef, Props>(
  ({ title = "Permits", onRowClick, showDateRangeFilter, ...props }, ref) => {
    //const [currentType, setCurrentType] = useState("All");
    const [updateLadder] = useUpdateLadderPermit();
    const insertPermitHistoryWithPermit = useInsertPermitHistoryWithPermit();
    const allTypesFilterList: Array<ColumnFilterItem> = [
      //ReadonlyArray<DropDownFilterItem> =  [
      // { text: "All" },
      {
        text: capitalizePhrase(props.hotWorkPermitName),
        value: "hot_work",
      },
      { text: "Confined space", value: "confined_space" },
      { text: "Dig", value: "dig" },
      { text: "Work Above", value: "work_above" },
      { text: "Historical Access", value: "historical_access" },
      { text: "Interior Penetration", value: "interior_penetration" },
      { text: "Excavation Penetration", value: "dig_excavation" },
      { text: "Ladder", value: "ladder" },
      { text: "LOTO", value: "loto" },
      { text: "Steel Erection Request", value: "steel_erection" },
      { text: "Out of Basket", value: "out_of_basket" },
      { text: "Ceiling Access", value: "ceiling_access" },
    ];

    const typeDisplayText: { [key: string]: string } = {
      hot_work: capitalizePhrase(props.hotWorkPermitName),
      confined_space: "Confined Space",
      dig: "Dig",
      work_above: "Work Above",
      historical_access: "Historical Access",
      interior_penetration: "Interior Penetration",
      dig_excavation: "Excavation Penetration",
      ladder: "Ladder",
      loto: "LOTO",
      out_of_basket: "Out of Basket",
      steel_erection: "Steel Erection",
      ceiling_access: "Ceiling Access",
      permitted_confined_space: "Permit Required Confined Space(PRCS)",
      reclassified_confined_space: "Reclassified Confined Space",
      alternate_confined_space: "Alternate Entry Confined Space",
    };

    return (
      <DataScrollTable<DConnection, ColumnKeys, PermitTableQuery>
        {...props}
        title={title}
        newCustomTableLook
        filterNotVisibleByDefault
        defaultCustomInitialSort={props.defaultCustomInitialSort}
        ref={ref}
        onRowClick={(permit) => {
          onRowClick && onRowClick(permit);
        }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allPermitsConnection"}
        datePickerDataIndex={showDateRangeFilter ? ["updated_at"] : undefined}
        extraSearchDataIndexes={[
          [{ index: "permit_checklist_items" }, { index: "text_response" }, { index: "en" }]
        ]}
        columns={[
          {
            title: "Date",
            key: "date",
            contentType: {
              type: "date",
              renderOptions: () => ({
                format: "full",
                withTime: true,
                withTimeZone: true,
              }),
            },
            sortable: true,
            size: "md",
            defaultSortOrder:
              props.defaultSortColumnKey === undefined ||
              props.defaultSortColumnKey === "date"
                ? props.defaultSortOrder ?? "desc"
                : undefined,
            dataIndex: ["updated_at"],
          },
          {
            title: "Type",
            key: "type",
            size: "ml",
            contentType: { type: "without-wrapper" },
            dataIndex: ["type"],
            filters: {
              dataIndex: ["type"],
              options: allTypesFilterList,
            },

            render: (type, permit) => {
              let content =
                typeDisplayText[
                  (!permit.confined_space_permit?.type ||
                  permit.confined_space_permit?.type === "not_permitted"
                    ? ""
                    : permit.confined_space_permit.type + "_") + type
                ];
              if (type === "ladder" && permit.ladder_permit) {
                content += permit.ladder_permit.is_access_ladder
                  ? " - Access/Egress"
                  : " - Working";
              }
              return (
                <div
                  className="self-center flex flex-row  justify-between h-full w-full items-center gap-0.5 p-0.75"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex items-center px-1 py-0.5 rounded-full bg-semantic-inactive-light">
                    <span className="text-sm">{content}</span>
                  </div>
                  {permit.ladder_permit &&
                    (props.gcAddLadderIdentification &&
                    props.gcAddLadderIdentification(
                      !!permit.ladder_permit.is_access_ladder,
                    ) ? (
                      <>
                        {permit.ladder_permit.returned_at ? (
                          permit.ladder_permit.ladder_identification
                        ) : (
                          <EditLadderIdentificationCell
                            ladder_permit={permit.ladder_permit}
                            permit_id={permit.pk}
                            permitStatus={permit.current_status}
                          />
                        )}{" "}
                        {!!permit.ladder_permit.ladder_identification?.trim() &&
                          (permit.ladder_permit.returned_at ? (
                            <Icon
                              color="positive"
                              icon={IconCheck}
                              hoverContent={{
                                content: `${
                                  permit.ladder_permit.returned_marked_by_user
                                    ?.name || ""
                                } Marked ladder returned on ${dayjs(
                                  permit.ladder_permit.returned_at,
                                ).format("MMMM Do YYYY, h:mm A")}`,
                              }}
                            />
                          ) : auth.currentUser ? (
                            <Icon
                              color="interactive"
                              size="small"
                              hoverContent={{
                                content: "Return ladder tag",
                                enableOnClick: true,
                              }}
                              onClick={async () => {
                                await updateLadder({
                                  variables: {
                                    permitId: permit.pk,
                                    _set: {
                                      returned_at: dayjs().toISOString(),
                                      returned_marked_by_uid:
                                        auth.currentUser?.uid,
                                    },
                                  },
                                  optimisticResponse: {
                                    update_ladder_permit_by_pk: {
                                      ...permit.ladder_permit,
                                      returned_at: dayjs().toISOString(),
                                      returned_marked_by_uid:
                                        auth.currentUser?.uid,
                                      returned_marked_by_user: { name: "You" },
                                    },
                                  },
                                });
                                insertPermitHistoryWithPermit({
                                  permitId: permit.pk,
                                  newStatus: "closed", // here we will not change permit status
                                  historyObjects: [
                                    {
                                      notify_users: false,
                                      comment: {
                                        data: {
                                          original: `Ladder is marked returned`,
                                        },
                                      },
                                    },
                                  ],
                                });
                              }}
                              icon={IconArrowBack}
                              // small
                            />
                          ) : null)}
                      </>
                    ) : (
                      capitalize(
                        permit.ladder_permit.ladder_identification || "",
                      )
                    ))}{" "}
                </div>
              );
            },
          },
          {
            title: "Project",
            key: "project",
            size: "md",
            queryIncludeVarKey: "includeProject",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
          },
          {
            title: "Location",
            key: "location",
            contentType: {
              type: "with-icon",
              renderOptions: (value) => ({ icon: IconMapPin, label: value }),
            },
            size: "sm",
            dataIndex: ["work_location", "en"],
            searchDataIndex: ["work_location", "en"],
          },
          {
            title: "Logs",
            key: "description",
            size: "sm",
            dataIndex: ["work_description", "en"],
            searchDataIndex: ["work_description", "en"],
            render: (v, permit) =>
              // TODO
              {
                if (permit.type == "loto") {
                  return "";
                }
                return v;
              },
          },
          {
            title: "Subcontractor",
            key: "subcontractor",
            size: "md",
            queryIncludeVarKey: "includeSub",
            dataIndex: ["subcontractor", "name"],
            searchDataIndex: ["subcontractor", "name"],
            contentType: {
              type: "with-icon",
              renderOptions: (value) => ({
                icon: IconBriefcase,
                label: value,
              }),
            },
          },
          {
            title: "Status",
            key: "status",
            contentType: {
              type: "tag",
              renderOptions: (value) => {
                switch (value) {
                  case "Approved":
                    return {
                      status: "positive",
                      label: value,
                    };
                  case "Closed":
                    return {
                      status: "inactive",
                      label: value,
                    };
                  case "Stop Work":
                    return { status: "negative", label: value };
                  case "Submitted":
                    return { status: "pending", label: value };
                  case "Review Required":
                    return { status: "corrected", label: value };
                  case "Inspect":
                    return { status: "negative", label: value };
                  default:
                    return { label: value };
                }
              },
            },
            dataIndex: ["current_status"],
            size: "lg",
            sortable: true,
            defaultSortOrder:
              props.defaultSortColumnKey === "status"
                ? props.defaultSortOrder ?? "asc"
                : undefined,
            clientCompareFn: (a, b) => {
              const valueA = getStatusValue(a.current_status);
              const valueB = getStatusValue(b.current_status);
              return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
            },
            filters: {
              dataIndex: ["current_status"],
              options: allStatusFilterList,
            },
            render: (status, permit) => {
              if (permit.type === "ladder" && permit.ladder_permit) {
                const now = dayjs();
                if (
                  props.ladderInspectionRequired &&
                  [
                    "approved",
                    "resubmitted",
                    "inspected",
                    "inspecting",
                    "submitted",
                    "submitted_and_inspecting",
                    "submitted_and_inspected",
                  ].includes(permit.current_status) &&
                  !permit.ladder_permit.is_access_ladder &&
                  dayjs(permit.ladder_permit.last_inspection_at)
                    .endOf("d")
                    .format() < now.format()
                ) {
                  return capitalize("Inspect");
                } else {
                  return capitalize(status);
                }
              } else if (
                permit.type === "confined_space" &&
                permit.confined_space_permit
              ) {
                const readings =
                  permit.confined_space_permit.confined_space_permit_readings;
                const showStopWork = !!readings.find(
                  (r) =>
                    new Date(r.created_at).getTime() >
                      new Date(
                        permit.permit_histories[0]?.created_at,
                      ).getTime() && getNotPermissibleReadingsCount(r) > 0,
                );

                if (showStopWork) return "Stop Work";
                return capitalize(status);
              } else if (
                permit.type === "ceiling_access" &&
                permit.ceiling_access_permit
              ) {
                const reviewRequired =
                  permit.current_status === "submitted" &&
                  permit.ceiling_access_permit.closing_review_status ===
                    "in_review";
                if (reviewRequired) return "Review Required";
                return capitalize(status);
              } else {
                return capitalize(status);
              }
            },
          },
          {
            title: "Workers",
            key: "workers",
            size: "sm",
            contentType: { type: "counter" },
            sortable: true,
            dataIndex: [
              "permit_project_workers_aggregate",
              "aggregate",
              "count",
            ],
          },
          ...(props.extraColumns || []),
        ]}
        queryNode={graphql`
          query PermitTableQuery(
            $first: Int!
            $after: String
            $where: permit_bool_exp!
            $order_by: [permit_order_by!]!
            $includeSub: Boolean!
            $includeProject: Boolean!
          ) {
            ...PermitTable_permits
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
                includeSub: $includeSub
                includeProject: $includeProject
              )
            ...PermitTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment PermitTable_total on query_root
          @argumentDefinitions(where: { type: "permit_bool_exp!" })
          @refetchable(queryName: "PermitTableTotalRefetchableQuery") {
            allPermitsConnection: permit_connection(where: $where) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment PermitTable_permits on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "permit_bool_exp!" }
            order_by: { type: "[permit_order_by!]!" }
            includeSub: { type: "Boolean!" }
            includeProject: { type: "Boolean!" }
          )
          @refetchable(queryName: "PermitTableRefetchableQuery") {
            permit_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            ) @connection(key: "PermitTable_permit_connection", filters: []) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  type
                  created_at
                  updated_at
                  current_status
                  work_location {
                    id
                    en
                  }
                  work_description {
                    id
                    en
                  }
                  project @include(if: $includeProject) {
                    name
                  }
                  subcontractor @include(if: $includeSub) {
                    name
                  }
                  permit_histories(order_by: { created_at: desc }, limit: 1) {
                    created_at
                  }
                  confined_space_permit {
                    type
                    confined_space_permit_readings(
                      order_by: { created_at: desc }
                    ) {
                      id
                      type
                      o2_percent
                      created_at
                      co_ppm
                      h2s_ppm
                      lower_explosive_limit
                    }
                  }
                  ladder_permit {
                    is_access_ladder
                    ladder_identification
                    last_inspection_at
                    id
                    returned_at
                    returned_marked_by_uid
                    returned_marked_by_user {
                      name
                    }
                  }
                  ceiling_access_permit {
                    closing_review_status
                  }
                  permit_project_workers_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default PermitTable;
