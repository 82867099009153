import { Form, Select } from "antd";
import React, { useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  UpdateEmailGcUserDocument,
  UpdateEmailGcUserMutation,
  UpdateEmailGcUserMutationVariables,
  Email_General_Contractor_User_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface SelectIncorrectWorkersModalProps {
  visible: boolean;
  onClose: () => void;
  gcUsers: Array<{ name: string; id: string }>;
  selectedUsers: Array<string>;
  gcId: string;
}

type FormValues = {
  users?: string[];
};

const SelectIncorrectWorkersModal: React.FC<
  SelectIncorrectWorkersModalProps
> = ({ visible, onClose, selectedUsers, gcId, gcUsers }) => {
  const [updateEmailGCUser, { loading: isUpdating }] = useMutation<
    UpdateEmailGcUserMutation,
    UpdateEmailGcUserMutationVariables
  >(UpdateEmailGcUserDocument, {
    onCompleted: (data) => {
      console.log("Mutation completed:", data);
      onClose();
    },
  });
  const modalRef = useRef<FModalRef<FormValues>>(null);
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = async () => {
    const values = await modalRef.current?.form?.validateFields();

    if (!values) return;

    const insertEmailGCUsers: Email_General_Contractor_User_Insert_Input[] =
      values.users?.map((user: string) => ({
        general_contractor_id: gcId,
        user_id: user,
        type: "incorrect_workers",
      })) || [];
    await updateEmailGCUser({
      variables: {
        objects: insertEmailGCUsers,
        deletewhere: {
          _and: [
            { general_contractor_id: { _eq: gcId } },
            { type: { _eq: "incorrect_workers" } },
          ],
        },
      },
    });
  };

  return (
    <FModal
      ref={modalRef}
      form={{
        layout: "vertical",
        name: "form_in_modal",
        initialValues: { users: selectedUsers || [] },
      }}
      open={visible}
      confirmLoading={isUpdating}
      title="Employee Verification Email"
      okText="Confirm"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Form.Item
        name="users"
        label="Select Team Members to receive employee verification emails"
        rules={[
          {
            required: true,
            message: "Please select at least one team member",
          },
        ]}
      >
        <Select
          mode="multiple"
          disabled={isUpdating}
          placeholder="Select team members"
          options={gcUsers.map((gcu) => ({
            value: gcu.id,
            label: gcu.name,
          }))}
          filterOption={getNormalSelectOptionsFilter}
        />
      </Form.Item>
    </FModal>
  );
};

export default SelectIncorrectWorkersModal;
