import {
  GetAdminObservationOptionsQueryVariables,
  Project_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";
import { OrganizationLevelName } from "../../utils/hierarchyTypes";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import { useSearchParams } from "react-router-dom";
import GCObsLeaderboard from "./GCObsLeaderboard";
import GCAdminObsDashboard from "./GCAdminObsDashboard";
import getOrgEmployeeWhere from "../../utils/getOrgEmployeeWhere";
import useGcEmployee from "../../organization-unit/utils/useGcEmployee";

const GCAdminObservations: React.FC<{
  organizationLevel: Exclude<OrganizationLevelName, "project">;
  organizationId: string;
  projWhere: Project_Bool_Exp;

  optionsQueryVars: Omit<
    GetAdminObservationOptionsQueryVariables,
    "includeProjSetting"
  >;
}> = ({ organizationLevel, organizationId, optionsQueryVars, projWhere }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const employee = useGcEmployee();
  const gcEmployeeWhere = getOrgEmployeeWhere(
    organizationLevel,
    organizationId,
  );

  const subview = searchParams.get("subview") || "dashboard";
  const handleReportsTabClick = (tabId: string) => {
    setSearchParams({ subview: tabId });
  };

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "dashboard",
      content: (
        <GCAdminObsDashboard
          {...{
            optionsQueryVars,
            organizationId,
            organizationLevel,
            projWhere,
          }}
        />
      ),
      label: "Dashboard",
    },
    {
      id: "leaderboard",
      content: (
        <GCObsLeaderboard
          {...{
            gcEmployeeWhere,
            projWhere,
            organization_level: organizationLevel,
            organization_unit_id: organizationId,
            gcId: employee.general_contractor.id,
          }}
        />
      ),
      label: "Leaderboard",
    },
  ];
  return (
    <CustomTabs
      onTabSelect={handleReportsTabClick}
      tabs={tabs}
      selectedTabId={subview}
    />
  );
};
export default GCAdminObservations;
