import { ItemType } from "antd/lib/menu/interface";
import { MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const getMatchingMenuKeys: (
  locationPathName: string,
  basePath: string,
  menuItems: ItemType[],
) => string[] = (locationPathName, basePath, menuItems) => {
  const matchingMenuItem = menuItems.find((item) =>
    item ? locationPathName.startsWith(`${basePath}/${item.key}`) : false,
  );

  if (matchingMenuItem?.key) {
    const mainKey: string = matchingMenuItem.key + "";

    const resultKeys: string[] = [mainKey];

    if (
      matchingMenuItem &&
      "children" in matchingMenuItem &&
      matchingMenuItem.children?.length
    ) {
      const childKeys = getMatchingMenuKeys(
        locationPathName,
        `${basePath}/${mainKey}`,
        matchingMenuItem.children,
      );

      resultKeys.push(...childKeys);
    }

    return resultKeys;
  }
  return [];
};

const useSelectedMenuKeys: (
  basePath: string,
  menuItems: ItemType[],
) => MenuProps["selectedKeys"] = (basePath, menuItems) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const location = useLocation();

  useEffect(() => {
    setSelectedKeys(
      getMatchingMenuKeys(location.pathname, basePath, menuItems),
    );
  }, [location.pathname]);

  return selectedKeys;
};

export default useSelectedMenuKeys;
