/**
 * @generated SignedSource<<9e28aa2a6a4d2104b2b5e4a7d5f1bcf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_qr_code_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateInspectionQRModalMutation$variables = {
  objects: ReadonlyArray<inspection_qr_code_insert_input>;
};
export type CreateInspectionQRModalMutation$data = {
  readonly insert_inspection_qr_code: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly created_at: string;
      readonly created_by_uid: string;
      readonly created_by_user: {
        readonly name: string;
      };
      readonly id: string;
      readonly inspection_qr_code_instances: ReadonlyArray<{
        readonly id: string;
        readonly inspection_instance: {
          readonly deleted_at: string | null | undefined;
          readonly id: string;
          readonly inspection_results_aggregate: {
            readonly aggregate: {
              readonly count: number;
            } | null | undefined;
          };
          readonly name: {
            readonly en: string;
          };
          readonly pk: string;
        };
      }>;
      readonly inspection_results: ReadonlyArray<{
        readonly created_at: string;
        readonly id: string;
        readonly user: {
          readonly name: string;
        } | null | undefined;
        readonly user_name: string | null | undefined;
      }>;
      readonly name: string;
      readonly pk: string;
      readonly project_id: string;
    }>;
  } | null | undefined;
};
export type CreateInspectionQRModalMutation = {
  response: CreateInspectionQRModalMutation$data;
  variables: CreateInspectionQRModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_by_uid",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v9 = [
  (v5/*: any*/)
],
v10 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "desc"
    }
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_name",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "inspection_instance": {
        "deleted_at": {
          "_is_null": true
        }
      }
    }
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted_at",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "inspection_result_aggregate",
  "kind": "LinkedField",
  "name": "inspection_results_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "inspection_result_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v18 = [
  (v5/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInspectionQRModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "inspection_qr_code_mutation_response",
        "kind": "LinkedField",
        "name": "insert_inspection_qr_code",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_qr_code",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "created_by_user",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "inspection_result",
                "kind": "LinkedField",
                "name": "inspection_results",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v11/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": "inspection_results(limit:1,order_by:{\"created_at\":\"desc\"})"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "inspection_instance_qr_code",
                "kind": "LinkedField",
                "name": "inspection_qr_code_instances",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "inspection_instance",
                    "kind": "LinkedField",
                    "name": "inspection_instance",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v13/*: any*/),
                      (v4/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "inspection_qr_code_instances(where:{\"inspection_instance\":{\"deleted_at\":{\"_is_null\":true}}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInspectionQRModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "inspection_qr_code_mutation_response",
        "kind": "LinkedField",
        "name": "insert_inspection_qr_code",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_qr_code",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "created_by_user",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "inspection_result",
                "kind": "LinkedField",
                "name": "inspection_results",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v11/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": "inspection_results(limit:1,order_by:{\"created_at\":\"desc\"})"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "inspection_instance_qr_code",
                "kind": "LinkedField",
                "name": "inspection_qr_code_instances",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "inspection_instance",
                    "kind": "LinkedField",
                    "name": "inspection_instance",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v13/*: any*/),
                      (v4/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "inspection_qr_code_instances(where:{\"inspection_instance\":{\"deleted_at\":{\"_is_null\":true}}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aba237c83c4fd10c88be2d610416e200",
    "id": null,
    "metadata": {},
    "name": "CreateInspectionQRModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateInspectionQRModalMutation(\n  $objects: [inspection_qr_code_insert_input!]!\n) {\n  insert_inspection_qr_code(objects: $objects) {\n    affected_rows\n    returning {\n      pk: id\n      id\n      name\n      created_at\n      created_by_uid\n      project_id\n      created_by_user {\n        name\n        id\n      }\n      inspection_results(limit: 1, order_by: {created_at: desc}) {\n        id\n        user_name\n        created_at\n        user {\n          name\n          id\n        }\n      }\n      inspection_qr_code_instances(where: {inspection_instance: {deleted_at: {_is_null: true}}}) {\n        id\n        inspection_instance {\n          pk: id\n          deleted_at\n          id\n          inspection_results_aggregate {\n            aggregate {\n              count\n            }\n          }\n          name {\n            en\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4af21c9778625016fb037e0642ec0b73";

export default node;
