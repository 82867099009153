import SettingsPageLayout from "../../../../../../../common/components/layouts/settings-page/SettingsPageLayout";
import { getMenuItemsByRoutedMenu } from "../../../../../../../utility-features/navigation/routed-menu/routedMenu";
import gcProjectSettingsRoutedMenu from "./gcProjectSettingsRoutedMenu";
import ProjectIdProps from "../../../../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../../common/hoc/withProjectIdUrlParam";
import getGcProjectSettingsPath from "./getGcProjectSettingsPath";

const GCProjectSettings: React.FC<ProjectIdProps> = (props) => {
  const menuItems = getMenuItemsByRoutedMenu(gcProjectSettingsRoutedMenu);
  const basePath = getGcProjectSettingsPath(props.projectId);

  return <SettingsPageLayout basePath={basePath} menuItems={menuItems} />;
};

export default withProjectIdUrlParam(GCProjectSettings);
