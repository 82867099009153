/**
 * @generated SignedSource<<44629c56c58991c8280a9fb0069e97e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_daily_report_not_require_period_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation$variables = {
  currentDate: string;
  where: project_subcontractor_daily_report_not_require_period_bool_exp;
};
export type usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation$data = {
  readonly update_project_subcontractor_daily_report_not_require_period: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation = {
  response: usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation$data;
  variables: usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "close_date",
            "variableName": "currentDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "project_subcontractor_daily_report_not_require_period_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_subcontractor_daily_report_not_require_period",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project_subcontractor_daily_report_not_require_period",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "71467009e1412bff83781bb0682c82d7",
    "id": null,
    "metadata": {},
    "name": "usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation(\n  $currentDate: date!\n  $where: project_subcontractor_daily_report_not_require_period_bool_exp!\n) {\n  update_project_subcontractor_daily_report_not_require_period(where: $where, _set: {close_date: $currentDate}) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c28cacc94835cc20c467741e81aa1e5a";

export default node;
