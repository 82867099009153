import withProjectIdUrlParam from "../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../common/types/manual/ProjectIdProps";
import GCIncidentTabsLayout from "./GCIncidentTabsLayout";
import { useNavigate } from "react-router-dom";
import getGcProjectSettingsPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import getGcProjectPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/getGcProjectPath";
import { SettingOutlined } from "@ant-design/icons";

const GCProjectIncidentTabs: React.FC<ProjectIdProps> = (props) => {
  const navigate = useNavigate();

  const basePath = getGcProjectPath(props.projectId, "incidents");
  const goToSettings = () => {
    navigate(getGcProjectSettingsPath(props.projectId, "incidents"));
  };
  return (
    <GCIncidentTabsLayout
      basePath={basePath}
      pageActions={[
        {
          icon: <SettingOutlined />,
          children: "Settings",
          onClick: goToSettings,
        },
      ]}
    />
  );
};
export default withProjectIdUrlParam(GCProjectIncidentTabs);
