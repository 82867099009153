import { Menu } from "antd";
import useGcEmployee from "../../../hierarchy/organization-unit/utils/useGcEmployee";
import useHierarchyMenuProps from "../utils/useHierarchyMenuProps";

const BASE_PATH = "/gce/hierarchy/corporate-office";

interface GCNavigationHierarchyMenuCorporateOfficeProps {}

const GCNavigationHierarchyMenuCorporateOffice: React.FC<
  GCNavigationHierarchyMenuCorporateOfficeProps
> = () => {
  const { general_contractor: gc } = useGcEmployee();

  const featureMenuItems: { key: string; label: string }[] = [
    // TODO Add dashboard
    // {
    //   key: "dashboard",
    //   label: "Dashboard",
    // },
    {
      label: "Child Units",
      key: "child-units",
    },
    {
      label: "Projects",
      key: "projects",
    },
    {
      key: "workers",
      label: "Workers",
    },
    {
      key: "employees",
      label: "Employees",
    },
    ...(gc.observation_enabled
      ? [
          {
            label: "Observations",
            key: "observations",
          },
        ]
      : []),
    {
      key: "incidents",
      label: "Incidents",
    },

    {
      key: "permits",
      label: "Permits",
    },
    {
      key: "orientations",
      label: "Orientations",
    },

    {
      key: "admins",
      label: "Admins",
    },
    {
      key: "hierarchy",
      label: "Organization Hierarchy",
    },
    {
      key: "settings",
      label: "Settings",
    },
  ];

  const menuProps = useHierarchyMenuProps(
    featureMenuItems,
    BASE_PATH,
    "Corporate Office",
  );

  return <Menu {...menuProps} />;
};

export default GCNavigationHierarchyMenuCorporateOffice;
