import React, { FC, useMemo } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { auth } from "src/common/functions/firebase";
import GCUserOACCompanyTable from "./components/GCUserOACCompanyTable";
import GCProjectLinking from "./components/GCProjectLinking";
import GCProjectOrientationLinkedMessage from "./GCProjectOrientationLinkedMessage";
import useGcProjectOrientationParams from "../../../../utils/useGcProjectOrientationParams";
import ProjectIdProps from "../../../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../common/hoc/withProjectIdUrlParam";
import useGcOrientationData from "../../../../utils/useGcOrientationData";
import NotifyUserSettings from "./components/NotifyUserSettings";
import { Card } from "antd";

const GCProjectOrientationSettings: FC<ProjectIdProps> = ({ projectId }) => {
  const { orientationData, getProjectOrientation } =
    useGcOrientationData(projectId);

  const projectData = getProjectOrientation();

  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error(`Orientation Settings user is not signed in`);
  }

  const allProjectOACCompanies: { id: string; name: string }[] = [];
  orientationData.project_oac_company_connection.edges.forEach((poc) => {
    if (
      allProjectOACCompanies.findIndex((allPoc) => {
        return allPoc.id === poc.node.oac_company_id;
      }) === -1
    ) {
      allProjectOACCompanies.push({
        id: poc.node.oac_company_id,
        name: poc.node.oac_company.name,
      });
    }
  });

  const currentProjectOACCompanies: string[] = [];
  orientationData.project_oac_company_connection.edges
    .filter((poc) => {
      return poc.node.project_id === projectId;
    })
    .forEach((poc) => {
      if (
        currentProjectOACCompanies.findIndex((currPoc) => {
          return currPoc === poc.node.oac_company_id;
        }) === -1
      ) {
        currentProjectOACCompanies.push(poc.node.oac_company_id);
      }
    });
  const params = useGcProjectOrientationParams(projectId);
  const orientationAutoEmailsTypeName = "orientation_auto_email";
  const orientationAutoEmailUsers = useMemo(
    () =>
      orientationData.email_project_user_connection.edges.reduce(
        (users, { node }) => {
          if (node.type === orientationAutoEmailsTypeName)
            users.push({ id: node.user_id, name: node.user.name });
          return users;
        },
        [] as { id: string; name: string }[],
      ),
    [
      orientationData.email_project_user_connection.edges,
      orientationAutoEmailsTypeName,
    ],
  );
  const gcUserOptions = useMemo(
    () =>
      projectData.project_employees.map((pe) => ({
        label: pe.employee.user.name,
        value: pe.employee.uid,
      })),
    [projectData.project_employees],
  );
  return projectData.orientation_project_id === projectId ? (
    <div className="flex flex-col gap-1">
      <Card title={`Automatic Emails`}>
        <NotifyUserSettings
          projectId={projectId}
          currentSelectedUsers={orientationAutoEmailUsers}
          type={orientationAutoEmailsTypeName}
          userOptions={gcUserOptions}
          title="Select Team members that will receive emails about Orientation specific information, such as a weekly email of workers onsite but have not completed orientation and when a training or certificate cannot be processed."
        />
      </Card>
      <GCUserOACCompanyTable
        projectId={projectId}
        gcId={projectData.general_contractor_id}
        allProjectOACCompanies={allProjectOACCompanies}
        currentProjectOACCompanies={currentProjectOACCompanies}
      />
      <GCProjectLinking
        projectId={projectId}
        params={params}
        currentLinkedProjects={orientationData.orientation_project_options.edges
          .filter((p) => p.node.orientation_project_id === projectId)
          .map((p) => ({
            name: p.node.name,
            id: p.node.pk,
          }))}
        orientationProjectOptions={orientationData.orientation_project_options.edges
          .filter((p) => p.node.linked_orientation_projects.length <= 1)
          .map((p) => ({
            name: p.node.name,
            id: p.node.pk,
          }))}
      />
    </div>
  ) : (
    <GCProjectOrientationLinkedMessage
      linkedProjectId={projectData.orientation_project_id}
    />
  );
};

export default withCustomSuspense(
  withProjectIdUrlParam(GCProjectOrientationSettings),
);
