import { RoutedMenuItem } from "src/utility-features/navigation/routed-menu/routedMenu";
import GCCompanySettingsGeneral from "./general/GCCompanySettingsGeneral";
import GCObservationRequirements from "../observation/components/GCObservationRequirements";
import GCCorporateOfficePermitsSettings from "../permits/GCCorporateOfficePermitsSettings";
import IncidentSeverityMapping from "../../components/incident/IncidentSeverityMapping";
import { Navigate } from "react-router-dom";
import GCCorporateObsNotifications from "../../corporate/settings/company/GCCorporateObsNotifications";
import GCObsSettingTabs from "../../corporate/settings/company/GCObsSettingTabs";

const gcCompanySettingsRoutedMenu: RoutedMenuItem[] = [
  {
    path: "general",
    label: "General",
    element: <GCCompanySettingsGeneral />,
  },
  {
    path: "observations",
    label: "Observations",
    element: <GCObsSettingTabs />,
    children: [
      {
        path: "requirements",
        label: "Requirements",
        element: <GCObservationRequirements />,
      },
      {
        path: "notification",
        element: <GCCorporateObsNotifications />,
      },
      {
        path: "",
        element: <Navigate to="requirements" />,
      },
    ],
  },
  {
    path: "incidents",
    label: "Incidents",
    element: <IncidentSeverityMapping />,
  },
  {
    path: "permits",
    label: "Permits",
    element: <GCCorporateOfficePermitsSettings />,
  },
];

export default gcCompanySettingsRoutedMenu;
