import { Reference, StoreObject } from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

const aggregateCountUpdater = <T extends StoreObject | Reference>(
  existingAggregate: T,
  readField: ReadFieldFunction,
  countIncrement: number,
) => {
  const aggregateReaded = readField("aggregate", existingAggregate);
  if (!aggregateReaded || typeof aggregateReaded !== "object")
    return existingAggregate as T;
  return {
    ...existingAggregate,
    aggregate: {
      ...aggregateReaded,
      count:
        aggregateReaded &&
        "count" in aggregateReaded &&
        typeof aggregateReaded.count === "number"
          ? Math.max(aggregateReaded.count + countIncrement, 0)
          : 0, // Ensure count doesn't go negative
    },
  } as T;
};
export default aggregateCountUpdater;
