import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Typography,
} from "antd";
import { useState } from "react";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  useDeleteEmailProjectUserMutation,
  useGetProjectObservationSettingsQuery,
  useInsertEmailProjectUserMutation,
  useInsertUserGroupOneMutation,
  useUpdateObservationProjectSettingMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import ShowGCGroup from "../../components/basic/ShowGCGroup";
import * as uuid from "uuid";
import dayjs from "dayjs";
import SwitchWithText from "src/common/components/SwitchWithText";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import { PlusOutlined } from "@ant-design/icons";
import DividerSplitWrapper from "../../../../common/components/DividerSplitWrapper";

const { Text, Paragraph, Title } = Typography;

const GCProjObservationSettings: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const emailProjUserNoWeeklyType = "no_weekly_open_obs_email";

  const [addNewGroup, setAddNewGroup] = useState(false);
  const variables = {
    projectId,
    gcEmpWhere: {
      employee_projects: {
        project_id: { _eq: projectId },
        _or: [
          { direct_project_assign: { _is_null: true } },
          { direct_project_assign: { _eq: true } },
        ],
      },
    },
    emailProjUserType: emailProjUserNoWeeklyType,
    userGroupWhere: {
      project_id: { _eq: projectId },
      type: { _eq: "observation" },
    },
  };
  const [insertEmailProjUser] = useInsertEmailProjectUserMutation();
  const [deleteEmailProjUser] = useDeleteEmailProjectUserMutation();
  const [updateObsProjSetting] = useUpdateObservationProjectSettingMutation();
  const { data, loading, error } = useGetProjectObservationSettingsQuery({
    variables,
  });

  const [form] = Form.useForm<{ name: string; users: Array<string> }>();
  const authUser = useAuthUser();
  const [insertGroup, { loading: inserting }] = useInsertUserGroupOneMutation();
  if (loading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("Data not found");
  const insertEpUser = (userId: string) => {
    const id = uuid.v4();
    const obj = {
      id: id,
      type: emailProjUserNoWeeklyType,
      project_id: projectId,
      user_id: userId,
    };
    insertEmailProjUser({
      variables: { objects: [obj] },
      optimisticResponse: {
        insert_email_project_user: {
          returning: [{ __typename: "email_project_user", ...obj }],
        },
      },
      update: (cache, returning) => {
        const insertedObjs =
          returning.data?.insert_email_project_user?.returning || [];
        if (insertedObjs.length === 0)
          throw new Error("No new inserted entry found");
        console.log("email_project_user", insertedObjs);
        cache.modify<typeof data>({
          fields: {
            email_project_user(existing = [], { toReference }) {
              const newList = [
                ...existing,
                ...insertedObjs.map((v) => toReference(v)!),
              ];
              console.log(newList);
              return newList;
            },
          },
        });
      },
    });
  };
  const deleteEpUser = (userId: string) => {
    const foundEpu = data.email_project_user.find(
      (epu) => epu.user_id === userId,
    );
    if (!foundEpu) throw new Error("Notifyee not found");
    deleteEmailProjUser({
      variables: {
        where: { id: { _eq: foundEpu.id } },
      },
      optimisticResponse: {
        delete_email_project_user: {
          returning: [{ ...foundEpu }],
        },
      },
      update: (cache, returning) => {
        const deletedId =
          returning.data?.delete_email_project_user?.returning[0]?.id;
        if (!deletedId)
          throw new Error("Server returning null for deleted notifyee");
        cache.modify<typeof data>({
          fields: {
            email_project_user(existing = [], { readField }) {
              const newList = existing.filter((notifyee) => {
                const id = readField("id", notifyee);
                return typeof id === "string" && id !== deletedId;
              });
              console.log(newList);
              return newList;
            },
          },
        });
      },
    });
  };
  console.log(data.email_project_user);
  const empOptions = data.general_contractor_employee.map((gc) => ({
    id: gc.uid,
    value: gc.uid,
    label:
      gc.user.name +
      (gc.employee_title ? `, ${gc.employee_title.name.en}` : ""),
  }));
  return (
    <Flex vertical gap={"large"}>
      <Modal
        okText={`Create Group`}
        open={addNewGroup}
        onCancel={() => setAddNewGroup(false)}
        okButtonProps={{ loading: inserting }}
        onOk={form.submit}
        loading={inserting}
        title={"Add New Group"}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (v: { name: string; users: Array<string> }) => {
            const toInsert = {
              type: "observation",
              created_by_user_id: authUser.uid,
              project_id: projectId,
              name: v.name,
              id: uuid.v4(),
              user_group_members: {
                data: v.users.map((u) => ({
                  id: uuid.v4(),
                  general_contractor_employee_id: u,
                })),
              },
            };
            const expectedReturningGroup: (typeof data.user_group)[number] = {
              __typename: "user_group" as const,
              ...toInsert,
              pendingAccount: [],
              active: toInsert.user_group_members.data.map((u) => {
                const gcUser = data.general_contractor_employee.find(
                  (emp) => emp.uid === u.general_contractor_employee_id,
                );
                if (!gcUser)
                  throw new Error(
                    "GC employee relation not found for one of the selected users",
                  );
                return {
                  deleted_at: null,
                  general_contractor_employee_id: gcUser.uid,
                  project_worker_id: null,
                  project_worker: null,
                  created_at: dayjs().toISOString(),
                  id: uuid.v4(),
                  employee: gcUser,
                  __typename: "user_group_member" as const,
                };
              }),
            };
            await insertGroup({
              variables: { object: toInsert },
              optimisticResponse: {
                insert_user_group_one: expectedReturningGroup,
              },
              update: (cache, returningData) => {
                const returningGroup =
                  returningData.data?.insert_user_group_one;
                if (!returningGroup)
                  throw new Error("Server returning null for inserted group");
                cache.modify<typeof data>({
                  fields: {
                    user_group(groups = [], { toReference }) {
                      const newGroupRef = toReference(returningGroup);
                      if (!newGroupRef) return groups;
                      return [...groups, newGroupRef];
                    },
                  },
                });
              },
            });
            form.resetFields();
            setAddNewGroup(false);
          }}
        >
          <Form.Item name={"name"} label={"Group Name"}>
            <Input width={100} />
          </Form.Item>
          <Form.Item
            name={"users"}
            label={"Select Group Members for this Group"}
            layout={"vertical"}
          >
            <Select
              mode="multiple"
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              options={empOptions}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Card title={"Foreman Verification"}>
        <SwitchWithText
          checked={
            !!data.project_setting_by_pk
              ?.require_gc_to_mark_observation_complete
          }
          /*TODO pass loading*/
          loading={false}
          text="Require Verification of observations completed by foremen"
          onChange={(checked) => {
            updateObsProjSetting({
              variables: {
                projectId,
                _set: { require_gc_to_mark_observation_complete: checked },
              },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  project_id: projectId,
                  __typename: "project_setting",
                  require_gc_to_mark_observation_complete: checked,
                },
              },
            });
          }}
        />
      </Card>
      <Card
        title={"Distribution Group(s)"}
        extra={
          <Button
            loading={inserting}
            onClick={() => {
              setAddNewGroup(true);
            }}
            icon={<PlusOutlined />}
          >
            Add New Group
          </Button>
        }
      >
        <DividerSplitWrapper>
          {data.user_group.map((gp) => {
            return (
              <ShowGCGroup
                key={gp.id}
                group={gp}
                allUsers={data.general_contractor_employee}
              />
            );
          })}
        </DividerSplitWrapper>
      </Card>
      <Card title={"Weekly Distribution Email"}>
        <Form.Item
          layout={`vertical`}
          label={`Select users NOT to send the Weekly Open Observation Email`}
        >
          <Select
            mode="multiple"
            value={data.email_project_user.map((epu) => epu.user_id)}
            onDeselect={(userId) => deleteEpUser(userId)}
            onSelect={(userId) => insertEpUser(userId)}
            showSearch
            filterOption={getNormalSelectOptionsFilter}
            options={empOptions}
          />
        </Form.Item>
      </Card>
    </Flex>
  );
};
export default GCProjObservationSettings;
