import { useMemo, useRef, useState } from "react";
import IncidentTable, {
  Props as TableProps,
} from "../../../components/table/IncidentTable";
import {
  GetIncidentFilterDataQuery,
  GetIncidentReportAndChartOptionsQuery,
  Incident_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { useNavigate, useOutlet } from "react-router-dom";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import { Dayjs } from "dayjs";

const GCIncidentsPage: React.FC<
  TableProps & {
    optionsData:
      | GetIncidentFilterDataQuery
      | GetIncidentReportAndChartOptionsQuery
      | undefined;
  }
> = ({ optionsData, ...props }) => {
  const incidentTableRef = useRef<DataScrollTableRef>(null);
  const navigate = useNavigate();
  const [selectedCompanies, setSelectedCompanies] = useState<Array<string>>([]);
  const [selectedInjuryTypes, setSelectedInjuryTypes] = useState<Array<string>>(
    [],
  );
  const [selectedIncidentTypes, setSelectedIncidentTypes] = useState<
    Array<string>
  >([]);
  const [selectedInjuryCause, setSelectedInjuryCause] = useState<Array<string>>(
    [],
  );
  const [selectedInjuredBodyPart, setSelectedInjuredBodyPart] = useState<
    Array<string>
  >([]);
  const [selectedStatus, setSelectedStatus] = useState<Array<string>>([]);
  const [selectedSeverity, setSelectedSeverity] = useState<Array<string>>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [selectedInjurySeverity, setSelectedInjurySeverity] = useState<
    Array<string>
  >([]);

  const where = useMemo(() => {
    const filters: Incident_Bool_Exp = { ...props.where };
    if (dateRange[0] && dateRange[1]) {
      filters.incident_time = {
        _gte: dateRange[0].toISOString(),
        _lte: dateRange[1].toISOString(),
      };
    }

    if (selectedCompanies?.length > 0) {
      filters._or = [
        { subcontractor_id: { _in: selectedCompanies } },
        {
          equipment_incident: {
            operator: {
              _or: [
                { worker: { subcontractor_id: { _in: selectedCompanies } } },
                {
                  employee: {
                    general_contractor_id: { _in: selectedCompanies },
                  },
                },
              ],
            },
          },
        },
        {
          property_damage_incident: {
            property_owner: {
              _or: [
                { worker: { subcontractor_id: { _in: selectedCompanies } } },
                {
                  employee: {
                    general_contractor_id: { _in: selectedCompanies },
                  },
                },
              ],
            },
          },
        },
        {
          vehicle_incident: {
            _or: [
              {
                vehicle_owner: {
                  _or: [
                    {
                      worker: {
                        subcontractor_id: { _in: selectedCompanies },
                      },
                    },
                    {
                      employee: {
                        general_contractor_id: { _in: selectedCompanies },
                      },
                    },
                  ],
                },
              },
              {
                driver: {
                  _or: [
                    {
                      worker: {
                        subcontractor_id: { _in: selectedCompanies },
                      },
                    },
                    {
                      employee: {
                        general_contractor_id: { _in: selectedCompanies },
                      },
                    },
                  ],
                },
              },
              {
                person_involved: {
                  _or: [
                    {
                      worker: {
                        subcontractor_id: { _in: selectedCompanies },
                      },
                    },
                    {
                      employee: {
                        general_contractor_id: { _in: selectedCompanies },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          supervisor_user: {
            _or: [
              { worker: { subcontractor_id: { _in: selectedCompanies } } },
              {
                employee: {
                  general_contractor_id: { _in: selectedCompanies },
                },
              },
            ],
          },
        },
        {
          incident_users: {
            user: {
              _or: [
                { worker: { subcontractor_id: { _in: selectedCompanies } } },
                {
                  employee: {
                    general_contractor_id: { _in: selectedCompanies },
                  },
                },
              ],
            },
          },
        },
      ];
    }

    if (
      selectedInjuryTypes?.length > 0 ||
      selectedInjuryCause?.length > 0 ||
      selectedInjuredBodyPart?.length > 0 ||
      selectedInjurySeverity?.length > 0
    ) {
      filters.incident_users = {
        injury_detail: {
          ...(selectedInjuryTypes?.length > 0 && {
            injury_type: { _in: selectedInjuryTypes },
          }),
          ...(selectedInjuryCause?.length > 0 && {
            injury_cause: { _in: selectedInjuryCause },
          }),
          ...(selectedInjuredBodyPart?.length > 0 && {
            body_parts_affected: {
              injury_body_part_affected: {
                value: { _in: selectedInjuredBodyPart },
              },
            },
          }),
          ...(selectedInjurySeverity?.length > 0 && {
            injury_severity: {
              severity_type: { _eq: "injury" },
              id: { _in: selectedInjurySeverity },
            },
          }),
        },
      };
    }

    if (selectedIncidentTypes?.length > 0) {
      filters.incident_types = {
        incident_type: { value: { _in: selectedIncidentTypes } },
      };
    }

    if (selectedStatus?.length > 0) {
      filters.status = { _in: selectedStatus };
    }

    if (selectedSeverity?.length > 0) {
      filters.monetary_severity = {
        severity_type: { _eq: "monetary" },
        id: { _in: selectedSeverity },
      };
    }

    return filters;
  }, [
    props.where,
    dateRange,
    selectedCompanies,
    selectedInjuryTypes,
    selectedInjuryCause,
    selectedInjuredBodyPart,
    selectedStatus,
    selectedSeverity,
    selectedInjurySeverity,
    selectedIncidentTypes,
  ]);

  const customFilters: Array<CustomFilterType> = !optionsData
    ? []
    : [
        {
          type: "date-range",
          title: "Incident Date",
          removeFilter: () => setDateRange([null, null]),
          onOk: (from: Dayjs | null, to: Dayjs | null) =>
            setDateRange([from?.toDate() || null, to?.toDate() || null]),
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Incident Type",
          removeFilter: () => setSelectedIncidentTypes([]),
          options: (optionsData.incident_type || []).map((type) => ({
            option: type.translation.en,
            onApply: () =>
              setSelectedIncidentTypes((prev) => [...prev, type.value]),
            onCancel: () =>
              setSelectedIncidentTypes((prev) =>
                prev.filter((val) => val !== type.value),
              ),
          })),
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Company",
          removeFilter: () => setSelectedCompanies([]),
          options: [
            ...("general_contractor_project" in optionsData
              ? optionsData.general_contractor_project
              : []
            ).map((gcProject) => ({
              option: gcProject.general_contractor.name,
              onApply: () =>
                setSelectedCompanies((prev) => [
                  ...prev,
                  ...prev,
                  gcProject.general_contractor.id,
                ]),
              onCancel: () =>
                setSelectedCompanies((prev) =>
                  prev.filter((val) => val !== gcProject.general_contractor.id),
                ),
            })),
            ...("project_subcontractor" in optionsData
              ? optionsData.project_subcontractor
              : []
            ).map((projSub) => ({
              option: projSub.subcontractor.name,
              onApply: () =>
                setSelectedCompanies((prev) => [
                  ...prev,
                  projSub.subcontractor.id,
                ]),
              onCancel: () =>
                setSelectedCompanies((prev) =>
                  prev.filter((val) => val !== projSub.subcontractor.id),
                ),
            })),
          ],
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Status",
          removeFilter: () => setSelectedStatus([]),
          options: [
            { value: "open", label: "Open" },
            { value: "closed", label: "Closed" },
            { value: "pending", label: "In-progress" },
          ].map((status) => ({
            option: status.label,
            onApply: () => setSelectedStatus((prev) => [...prev, status.value]),
            onCancel: () =>
              setSelectedStatus((prev) =>
                prev.filter((val) => val !== status.value),
              ),
          })),
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Monetary Severity",
          removeFilter: () => setSelectedSeverity([]),
          options: optionsData.incident_severity
            .filter((severity) => severity.severity_type === "monetary")
            .map((severity) => ({
              option: severity.name.en,
              onApply: () =>
                setSelectedSeverity((prev) => [...prev, severity.id]),
              onCancel: () =>
                setSelectedSeverity((prev) =>
                  prev.filter((val) => val !== severity.id),
                ),
            })),
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Injury Classification",
          removeFilter: () => setSelectedInjurySeverity([]),
          options: optionsData.incident_severity
            .filter((severity) => severity.severity_type === "injury")
            .map((severity) => ({
              option: severity.name.en,
              onApply: () =>
                setSelectedInjurySeverity((prev) => [...prev, severity.id]),
              onCancel: () =>
                setSelectedInjurySeverity((prev) =>
                  prev.filter((val) => val !== severity.id),
                ),
            })),
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Injury Type",
          removeFilter: () => setSelectedInjuryTypes([]),
          options: optionsData.injury_type.map((type) => ({
            option: type.translation.en,
            onApply: () =>
              setSelectedInjuryTypes((prev) => [...prev, type.value]),
            onCancel: () =>
              setSelectedInjuryTypes((prev) =>
                prev.filter((val) => val !== type.value),
              ),
          })),
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Body Part Affected",
          removeFilter: () => setSelectedInjuredBodyPart([]),
          options: optionsData.injury_body_part_affected.map((bodyPart) => ({
            option: bodyPart.translation.en,
            onApply: () =>
              setSelectedInjuredBodyPart((prev) => [...prev, bodyPart.value]),
            onCancel: () =>
              setSelectedInjuredBodyPart((prev) =>
                prev.filter((val) => val !== bodyPart.value),
              ),
          })),
          initialClose: true,
        },
        {
          type: "checkbox",
          title: "Injury Cause",
          removeFilter: () => setSelectedInjuryCause([]),
          options: optionsData.injury_cause.map((injuryCause) => ({
            option: injuryCause.translation.en,
            onApply: () =>
              setSelectedInjuryCause((prev) => [...prev, injuryCause.value]),
            onCancel: () =>
              setSelectedInjuryCause((prev) =>
                prev.filter((val) => val !== injuryCause.value),
              ),
          })),
          initialClose: true,
        },
      ];
  const outlet = useOutlet();
  return (
    <>
      <div className="flex flex-col gap-1">{outlet}</div>
      <IncidentTable
        {...props}
        customFilters={customFilters}
        ref={incidentTableRef}
        onRowClick={({ id }) => navigate(`${id}?subview=project_info`)}
        where={where}
      />
    </>
  );
};
export default GCIncidentsPage;
