import React from "react";
import AppNavigationLayout from "../../../../common/components/layouts/main/AppNavigationLayout";
import developmentPlaygroundNavigationItems from "./developmentPlaygroundNavigationItems";
import { Outlet, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { getMenuItemsByRoutedMenu } from "../../../../utility-features/navigation/routed-menu/routedMenu";

const DevelopmentPlayground: React.FC = () => {
  const navigate = useNavigate();
  return (
    <AppNavigationLayout
      sideMenu={{
        body: (
          <Menu
            onSelect={({ key }) => {
              navigate(key);
            }}
            items={getMenuItemsByRoutedMenu(
              developmentPlaygroundNavigationItems,
            )}
          />
        ),
      }}
    >
      <Outlet />
    </AppNavigationLayout>
  );
};

export default DevelopmentPlayground;
