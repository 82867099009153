import React, { FC } from "react";
import { PermitProps } from "src/common/types/manual/PermitProps";

import BackButton from "src/common/components/general/button/BackButton";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import DateProps from "src/common/types/manual/DateProps";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import ProjectReportsPermitsDetail from "src/domain-features/sitesafety/components/ProjectReportsPermitsDetail";

const QrProjectPermitReport: FC = () => {
  return (
    <MobileViewWrapper footer={<BackButton fullWidth />}>
      <div className="w-full min-h-full overflow-x-auto bg-white">
        <ProjectReportsPermitsDetail />
      </div>
    </MobileViewWrapper>
  );
};

export default QrProjectPermitReport;
