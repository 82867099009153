import React from "react";
import ExplanationIcon from "../../../../../../../../common/components/ExplanationIcon";
import { Typography } from "antd";

interface GCProjectSiteAlertExplanationIconProps {
  isSmsEnabled?: boolean;
  phoneNumber?: string;
}

const GCProjectSiteAlertExplanationIcon: React.FC<
  GCProjectSiteAlertExplanationIconProps
> = (props) => {
  const hasSmsNumber = !!props.phoneNumber;

  return (
    <ExplanationIcon
      title={`Communicate with your workforce!`}
      content={
        <Typography>
          <Typography.Paragraph>
            SiteAlert is a text message or push notification sent to any worker
            with a phone number or is logged into the SiteForm mobile app.
            Sending push notifications to app users is FREE. Because we use a
            3rd party service to send/receive text messages, there is additional
            cost which is billed monthly and based on usage. <br />
            <br />
            Email{" "}
            <Typography.Link
              href="mailto:support@siteform.io"
              className="text-interactive-primary"
            >
              support@siteform.io
            </Typography.Link>
            for pricing, general questions, or to turn this feature ON/OFF.
          </Typography.Paragraph>
          {hasSmsNumber && (
            <Typography.Paragraph>
              <Typography.Text type={"secondary"}>
                SiteAlert with Text Message is:
              </Typography.Text>{" "}
              <Typography.Text>
                {props.isSmsEnabled ? "ON" : "OFF"}
              </Typography.Text>
              <br />
              <Typography.Text type={"secondary"}>
                Your project Phone Number:
              </Typography.Text>{" "}
              <Typography.Text>{props.phoneNumber}</Typography.Text>
            </Typography.Paragraph>
          )}
        </Typography>
      }
    />
  );
};

export default GCProjectSiteAlertExplanationIcon;
