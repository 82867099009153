/**
 * @generated SignedSource<<09f976e5cfa2518f813b65251acec863>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { email_project_employee_user_bool_exp, email_project_employee_user_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MorningManPowerReportEmailMutation$variables = {
  objects: ReadonlyArray<email_project_employee_user_insert_input>;
  where: email_project_employee_user_bool_exp;
};
export type MorningManPowerReportEmailMutation$data = {
  readonly delete_email_project_employee_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_email_project_employee_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
      readonly user: {
        readonly email: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  } | null | undefined;
};
export type MorningManPowerReportEmailMutation = {
  response: MorningManPowerReportEmailMutation$data;
  variables: MorningManPowerReportEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "where"
    }
  ],
  "concreteType": "email_project_employee_user_mutation_response",
  "kind": "LinkedField",
  "name": "delete_email_project_employee_user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "email_project_employee_user",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MorningManPowerReportEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "email_project_employee_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_employee_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_employee_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MorningManPowerReportEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "email_project_employee_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_employee_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_employee_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "871ce11c79f4b1614d9ac591552c7099",
    "id": null,
    "metadata": {},
    "name": "MorningManPowerReportEmailMutation",
    "operationKind": "mutation",
    "text": "mutation MorningManPowerReportEmailMutation(\n  $objects: [email_project_employee_user_insert_input!]!\n  $where: email_project_employee_user_bool_exp!\n) {\n  insert_email_project_employee_user(objects: $objects) {\n    returning {\n      id\n      pk: id\n      user {\n        id\n        pk: id\n        name\n        email\n      }\n    }\n  }\n  delete_email_project_employee_user(where: $where) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32ab862cde3b8bf4b69ec3e82ea75a43";

export default node;
