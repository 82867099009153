import { Button, Space } from "antd";
import GCAdminObservationsReportCreatorButton, {
  GCAdminObservationsReportCreatorButtonProps,
} from "./GCAdminObservationsReportCreatorButton";
import GCAdminHeaderFilterTags from "../../filter-inputs/GCAdminHeaderFilterTags";
import GCAdminHeaderFilterButton, {
  GCAdminHeaderFilterButtonProps,
} from "../../filter-inputs/GCAdminHeaderFilterButton";
import { SettingOutlined } from "@ant-design/icons";

interface GCAdminObservationsHeaderProps {
  reportCreatorButtonProps: GCAdminObservationsReportCreatorButtonProps;
  // observationSettingsButtonProps: GCAdminObservationsSettingsButtonProps;
  filters?: GCAdminHeaderFilterButtonProps["filters"];
  onSettingsClick?: () => void;
}

const GCAdminObservationsHeader: React.FC<GCAdminObservationsHeaderProps> = (
  props,
) => {
  return (
    <div className={`flex flex-col gap-1`}>
      <div className={`flex flex-row gap-1`}>
        <Space>
          {props.filters && (
            <GCAdminHeaderFilterButton filters={props.filters} />
          )}
          <GCAdminObservationsReportCreatorButton
            {...props.reportCreatorButtonProps}
          />
          {props.onSettingsClick && (
            <Button
              icon={<SettingOutlined />}
              onClick={() => props.onSettingsClick?.()}
            >
              Settings
            </Button>
          )}
        </Space>
      </div>
      {props.filters && <GCAdminHeaderFilterTags filters={props.filters} />}
    </div>
  );
};

export default GCAdminObservationsHeader;
