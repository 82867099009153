import React from "react";
import GCProjectDashboard from "./GCProjectDashboard";
import NestedRoute from "../../../../common/types/manual/NestedRoute";

const gcProjectDashboardRoute: NestedRoute = {
  element: <GCProjectDashboard />,
  handle: {
    breadcrumb: "Dashboard",
  },
};

export default gcProjectDashboardRoute;
