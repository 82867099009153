import { useApolloClient } from "@apollo/client";
import { message } from "antd";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { procoreProjectRedirectUrl } from "src/common/components/ConnectProcoreUserButton";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  ProcoreSetUserAccountDocument,
  ProcoreSetUserAccountMutation,
  ProcoreSetUserAccountMutationVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import getGcProjectSettingsPath from "./hierarchy/projects/settings/getGcProjectSettingsPath";
import reportException from "src/utility-features/error-handling/reportException";


class ProcoreSetupError extends Error {
  projectId: string;
  description?: string;

  constructor(projectId: string, message: string, description?: string) {
    super(message); // Pass the errorTitle to the Error constructor
    this.name = "NotifyUserException"; // Set the name of the error
    this.projectId = projectId;
    this.description = description; // Set the userInstructions
  }
}


// this page is used as callback during procore auth.
const GCProcoreProjectSetup: FC = () => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const setupProcoreAccount = async () => {
    const params = new URLSearchParams(document.location.search);
    const stateStr = params.get("state");
    if (!stateStr) {
      throw new Error("state is not specified");
    }
    const state = JSON.parse(stateStr);

//    const items = state.split("|");
    const projectId = state.projectId;

    if (!projectId) {
      throw new Error("project id is not specified");
    }
    const sandbox = !!state.sbx;
    const dmsa = !!state.dmsa;
    const error = params.get("error");
    const code = params.get("code");
    if (!code)
      throw new ProcoreSetupError(projectId, "code is missing");

    if (error) {
      const error_description = params.get("error_description") || "";
      throw new ProcoreSetupError(projectId, error, error_description);
    }
    await client
    .mutate<
      ProcoreSetUserAccountMutation,
      ProcoreSetUserAccountMutationVariables
    >({
      mutation: ProcoreSetUserAccountDocument,
      variables: {
        projectId,
        code,
        sandbox,
        redirect_url: procoreProjectRedirectUrl,
        dmsa,
      },
    })
    navigate(getGcProjectSettingsPath(projectId));
  }

  useEffect(() => {
    setupProcoreAccount().catch(error => {
      reportException(error);
      if (error instanceof ProcoreSetupError) {
        const searchParams = new URLSearchParams({error: error.message, error_description: error.description ?? ""});
        navigate(
          `${getGcProjectSettingsPath(error.projectId)}?${searchParams.toString()}`,
        );
      } else {
        message.error(String(error));
      }
    })
  }, []);
  return (
    <StyledContent align="center">
      <LoadingContent />
    </StyledContent>
  );
};

export default GCProcoreProjectSetup;
