import {
  IconArrowRight,
  IconArrowUp,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons";

import * as React from "react";
import { Fragment } from "react";
import Icon, { IconProps } from "src/common/components/general/Icon";
import { Tooltip } from "antd";
import clsx from "clsx";
import ProgressBar, {
  ProgressBarProps,
} from "src/common/components/general/ProgressBar";
import getDateStr from "src/common/functions/dates/getDateStr";
import compareStringsIgnoreCase from "src/common/functions/compareStringsIgnoreCase";
import { ExtendedRow } from "./gcSubOnsitePerformanceTypes";

export interface GCPerformanceTableProps {
  title: string;
  initiallyHideChilds?: boolean;
  id: string;
  rows: ExtendedRow[];
  switchTitles: Array<{ title: string; icon?: IconProps }>;
  onSortingClick?: () => void;
  hint?: string;
  columns: { title: string; id: string }[];
}

interface WrappedProgressBarProps extends ProgressBarProps {
  missingReportsDates: Date[];
}

const columnSorter = (
  row1: ExtendedRow,
  row2: ExtendedRow,
  columnId: string,
) => {
  const row1ColData = row1.columnsData.get(columnId) || {
    total: 0,
    submitted: 0,
  };
  const row2ColData = row2.columnsData.get(columnId) || {
    total: 0,
    submitted: 0,
  };
  if (row1ColData.total && row2ColData.total) {
    return (
      row2ColData.submitted / row2ColData.total -
      row1ColData.submitted / row1ColData.total
    );
  }
  if (row1ColData.total === 0) return 1;
  if (row2ColData.total === 0) return -1;

  return compareStringsIgnoreCase(row1.title, row2.title);
};
const WrappedProgressBar: React.FunctionComponent<WrappedProgressBarProps> = (
  props,
) => {
  return (
    <div className="relative">
      <Tooltip
        placement="left"
        trigger={props.missingReportsDates.length > 0 ? ["hover"] : []}
        overlay={
          <div className="overflow-auto max-h-24">
            {props.missingReportsDates.map((date) => (
              <span key={date.toString()} className={`block pr-0.5`}>
                {getDateStr(date)}
              </span>
            ))}
          </div>
        }
      >
        <div>
          <ProgressBar total={props.total} value={props.value} />
        </div>
      </Tooltip>
    </div>
  );
};

const GCSubOnsitePerformanceTableUi: React.FunctionComponent<
  GCPerformanceTableProps
> = (props) => {
  const [hideChildsFor, setHideChildsFor] = React.useState<{
    [key: string]: boolean;
  }>(
    props.initiallyHideChilds
      ? Object.fromEntries(
          props.rows
            .filter((row) => row.childRows.length)
            .map((r) => [r.id, true]),
        )
      : {},
  );
  const [selectedSorterColumn, setSelectedSorterColumn] = React.useState<{
    columnId: string;
    desc?: boolean;
  }>();
  // const [selectedColumn, toggleColumnVisibility] =
  //   useSelectedIndicies<string>();

  const columnsTitleDataMap = React.useMemo(() => {
    const columnsTitleDataMap: Map<
      string,
      { columnTotal: number; columnSubmitted: number }
    > = new Map();
    props.rows.forEach((row) => {
      row.columnsData.forEach((column, columnId) => {
        const colTitleData = columnsTitleDataMap.get(columnId);
        if (colTitleData) {
          colTitleData.columnSubmitted += column.submitted;
          colTitleData.columnTotal += column.total;
        } else {
          columnsTitleDataMap.set(columnId, {
            columnSubmitted: column.submitted,
            columnTotal: column.total,
          });
        }
      });
    });
    return columnsTitleDataMap;
  }, [props.rows]);
  const rowsToShow = (
    selectedSorterColumn
      ? props.rows.sort(
          (row1, row2) =>
            (selectedSorterColumn.desc ? 1 : -1) *
            (selectedSorterColumn.columnId === "name"
              ? compareStringsIgnoreCase(row2.title, row1.title)
              : columnSorter(row1, row2, selectedSorterColumn.columnId)),
        )
      : props.rows
  ).flatMap((r) => [
    { ...r, parentId: null },
    ...r.childRows.map((c) => ({ ...c, parentId: r.id })),
  ]);
  const SortingIcon = ({ columnId }: { columnId: string }) => {
    return (
      <Icon
        color="interactive"
        size="small"
        icon={
          selectedSorterColumn?.columnId === columnId &&
          selectedSorterColumn.desc
            ? IconSortDescending
            : IconSortAscending
        }
        onClick={() =>
          setSelectedSorterColumn({
            columnId,
            desc:
              selectedSorterColumn?.columnId === columnId
                ? !selectedSorterColumn.desc
                : true,
          })
        }
      />
    );
  };
  return (
    <>
      <table>
        <thead>
          <tr>
            <td className="sticky z-50 justify-center items-center text-center gap-5 top-0 bg-white font-accent w-40">
              <div
                className={`transition-opacity flex flex-row text-center justify-center w-full gap-px overflow-hidden `}
              >
                {props.title}
                <SortingIcon columnId={"name"} />
              </div>
            </td>
            {props.columns.map((column) => {
              const titleData = columnsTitleDataMap.get(column.id);
              return (
                <td
                  key={column.title}
                  className={clsx(
                    "sticky z-50 justify-center items-center gap-0.5 top-0 bg-white font-accent",
                    // selectedColumn.has(`column${i}`) ? "w-15" : "w-5",
                  )}
                >
                  <div
                    className={clsx(
                      `transition-opacity flex flex-row justify-center text-center w-full gap-px overflow-hidden`,
                    )}
                  >
                    <div
                      className={clsx(
                        `truncate whitespace-nowrap`,
                        // {
                        // hidden: selectedColumn.has(`column${i}`),
                        // }
                      )}
                    >
                      <span className="font-accent">
                        {`${column.title}, ${
                          titleData
                            ? Math.round(
                                (100 / titleData.columnTotal) *
                                  titleData.columnSubmitted,
                              ) + "%"
                            : ""
                        } `}
                      </span>
                      {titleData && (
                        <span className="font-normal text-0.75">
                          {`${titleData.columnSubmitted} of ${titleData.columnTotal}`}
                        </span>
                      )}
                    </div>
                    {/* <Icon
                      color="interactive"
                      size="small"
                      icon={
                        selectedColumn.has(column.id) ? IconCirclePlus : IconX
                      }
                      onClick={() => toggleColumnVisibility(column.id)}
                    /> */}
                    <SortingIcon columnId={column.id} />
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rowsToShow.map((item, i) => {
            return !item.parentId || !hideChildsFor[item.parentId] ? (
              <tr key={item.id}>
                <td>
                  <div
                    className={clsx(
                      "flex flex-row justify-between items-center gap-0.5",
                      { " font-accent": item.parentId },
                    )}
                  >
                    <div>
                      <span
                        style={{
                          marginLeft:
                            item.parentId && !hideChildsFor[item.parentId]
                              ? "10px"
                              : "0px",
                          paddingBottom:
                            item.switchers.length > 0 ? undefined : "9.2px",
                        }}
                      >
                        {item.title}
                      </span>
                    </div>
                    {!item.parentId && rowsToShow[i + 1]?.parentId && (
                      <Icon
                        color="interactive"
                        icon={
                          hideChildsFor[item.id] ? IconArrowRight : IconArrowUp
                        }
                        onClick={() => {
                          setHideChildsFor((prev) => {
                            const newObj = { ...prev };
                            if (hideChildsFor[item.id]) {
                              delete newObj[item.id];
                            } else {
                              newObj[item.id] = true;
                            }
                            return newObj;
                          });
                        }}
                      />
                    )}
                    {item.switchers.length > 0 && (
                      <div className="flex flex-row justify-end w-60">
                        {item.switchers.map((switcher, i) => (
                          <Fragment key={i}>{switcher}</Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
                {props.columns.map((column) => {
                  const rowColData = item.columnsData.get(column.id);
                  return !rowColData ? null : (
                    <td
                      key={column.title}
                      className={
                        clsx()
                        // selectedColumn.has(column.id) ? "w-15" : "w-5",
                      }
                    >
                      <div
                        className={clsx(
                          "relative w-full",
                          // {
                          // hidden: selectedColumn.has(column.id),
                          // }
                        )}
                      >
                        <div
                          className={`transition-opacity flex flex-col justify-center w-full gap-px overflow-hidden `}
                        >
                          <WrappedProgressBar
                            key={item.id + column.id}
                            {...{
                              total: rowColData.total,
                              missingReportsDates: rowColData.missingDates,
                              value: rowColData.submitted,
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ) : null;
          })}
        </tbody>
      </table>
    </>
  );
};
export default GCSubOnsitePerformanceTableUi;
