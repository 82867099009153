/**
 * @generated SignedSource<<00f0ccce99e1e8fbf74b1334d9f7606f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { work_above_floor_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkAboveElevationFloor_FloorInsert_Mutation$variables = {
  object: work_above_floor_insert_input;
};
export type WorkAboveElevationFloor_FloorInsert_Mutation$data = {
  readonly insert_work_above_floor_one: {
    readonly completed_at: string | null | undefined;
    readonly has_netting: boolean;
    readonly id: string;
    readonly name: {
      readonly en: string;
      readonly id: string;
    };
    readonly name_id: string;
    readonly order: number;
    readonly pk: string;
  } | null | undefined;
};
export type WorkAboveElevationFloor_FloorInsert_Mutation = {
  response: WorkAboveElevationFloor_FloorInsert_Mutation$data;
  variables: WorkAboveElevationFloor_FloorInsert_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "has_netting",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "en",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkAboveElevationFloor_FloorInsert_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "work_above_floor",
        "kind": "LinkedField",
        "name": "insert_work_above_floor_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkAboveElevationFloor_FloorInsert_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "work_above_floor",
        "kind": "LinkedField",
        "name": "insert_work_above_floor_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "586b8d8c02b26947ce6d976cf4d7895b",
    "id": null,
    "metadata": {},
    "name": "WorkAboveElevationFloor_FloorInsert_Mutation",
    "operationKind": "mutation",
    "text": "mutation WorkAboveElevationFloor_FloorInsert_Mutation(\n  $object: work_above_floor_insert_input!\n) {\n  insert_work_above_floor_one(object: $object) {\n    id\n    order\n    name_id\n    pk: id\n    completed_at\n    has_netting\n    name {\n      id\n      en\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f03323069e9761c18afc6b909cf348d";

export default node;
