import React, { ComponentType, forwardRef, PropsWithoutRef } from "react";
import AppPageLayout, { AppPageLayoutProps } from "./AppPageLayout";

const withAppPageLayout = <Props,>(
  Component: ComponentType<PropsWithoutRef<Props>>,
  options: Omit<AppPageLayoutProps, "children">,
) =>
  forwardRef((props: PropsWithoutRef<Props>, ref) => {
    return (
      <AppPageLayout {...options}>
        <Component {...props} ref={ref} />
      </AppPageLayout>
    );
  });

export default withAppPageLayout;
