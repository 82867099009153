import { useGetProjectDisabledFeaturesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Menu } from "antd";
import GCNavigationHierarchyMenuLoadingFallback from "../GCNavigationHierarchyMenuLoadingFallback";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import useAuthUser from "../../../../../../../common/hooks/useAuthUser";
import { gcProjectVisitorsQuery } from "../../../../../../../domain-features/sitesafety/entry-routes/visitor/components/GCProjectVisitors";
import { GCProjectVisitorsQuery } from "src/common/types/generated/relay/GCProjectVisitorsQuery.graphql";
import useHierarchyMenuProps from "../utils/useHierarchyMenuProps";

interface GCNavigationHierarchyMenuProjectProps {
  projectId: string;
}

const GCNavigationHierarchyMenuProject: React.FC<
  GCNavigationHierarchyMenuProjectProps
> = (props) => {
  const authUser = useAuthUser();

  // TODO check visitor feature with useGetProjectDisabledFeaturesQuery instead
  const projectVisitorsData = useLazyLoadQuery<GCProjectVisitorsQuery>(
    gcProjectVisitorsQuery,
    { projectId: props.projectId, empId: authUser.uid },
    //refreshedQueryOptions ?? {}
  );

  const { data: projectDisabledFeaturesData, loading } =
    useGetProjectDisabledFeaturesQuery({
      variables: { projectId: props.projectId },
    });

  const setting = projectDisabledFeaturesData?.project_setting_by_pk;

  const basePath = `/gce/hierarchy/project/${props.projectId}`;

  const featureMenuItems: { label: string; key: string }[] = [
    {
      label: "Dashboard",
      key: `dashboard`,
      // icon: <Icon icon={IconChartAreaLine} />,
    },
    {
      label: "Reports",
      key: "reports",
      // icon: <Icon icon={IconReportAnalytics} />,
    },
    {
      label: "Subcontractors",
      key: `subcontractors`,
      // icon: <Icon icon={IconBriefcase} />,
    },
    {
      label: "Workers",
      key: `workers`,
      // icon: <Icon icon={IconUsers} />,
    },
    {
      label: "Team",
      key: `team`,
      // icon: <Icon icon={IconTie} />,
    },
    {
      label: "Orientation",
      key: `orientation`,
      // icon: <Icon icon={IconUserCheck} />,
    },

    {
      label: "Alerts",
      key: `alerts`,
      // icon: <Icon icon={IconMessage} />,
    },
    {
      key: "daily-reports",
      label: "Daily Reports",
    },
    {
      label: "Deliveries",
      key: `deliveries`,
      // icon: <Icon icon={IconCalendar} />,
    },
    ...(projectVisitorsData.project_connection.edges &&
    projectVisitorsData.project_connection.edges[0].node
      .is_visitor_log_and_tracking_active
      ? [
          {
            key: "visitors",
            label: "Visitors",
          },
        ]
      : []),

    {
      label: "Job Hazard Analysis",
      key: `jha`,
    },
    {
      key: "pre-task-plans",
      label: "Pre-Task Plans",
    },

    {
      key: "permits",
      label: "Permits",
    },
    {
      key: "toolbox-talks",
      label: "Toolbox Talks",
    },

    ...(setting?.site_inspection_enabled
      ? [
          {
            label: "Inspections",
            key: "inspections",
            // icon: <Icon icon={IconChecklist} />,ete
          },
        ]
      : []),
    ...(setting?.observation_enabled
      ? [
          {
            label: "Observations",
            key: `observations`,
            // icon: <Icon icon={IconReportSearch} />,
          },
        ]
      : []),
    ...(setting?.incident_enabled
      ? [
          {
            label: "Incidents",
            key: `incidents`,
            // icon: <Icon icon={IconFirstAidKit} />,
          },
        ]
      : []),

    {
      // icon: <Icon icon={IconFlask} />,
      label: "SDS",
      key: "sds",
    },
    {
      label: "Settings",
      key: `settings`,
      // icon: <Icon icon={IconSettings} />,
    },
    {
      label: "Billing",
      key: "billing",
      // icon: <Icon icon={IconCash} />,
    },
  ];

  const menuProps = useHierarchyMenuProps(
    featureMenuItems,
    basePath,
    "Projects",
  );

  if (loading) {
    return <GCNavigationHierarchyMenuLoadingFallback />;
  }
  return <Menu {...menuProps} />;
};

export default GCNavigationHierarchyMenuProject;
