import withAppPageLayout from "../../../../../../common/components/layouts/main/content/withAppPageLayout";
import React from "react";
import ProjectIdProps from "../../../../../../common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../common/hoc/withProjectIdUrlParam";
import GCProjectOrientationBuilderRegistrationQuestions from "./GCProjectOrientationBuilderRegistrationQuestions";
import GCProjectOrientationBuilderModules from "./GCProjectOrientationBuilderModules";
import GCProjectOrientationBuilderOtherSettings from "./GCProjectOrientationBuilderOtherSettings";
import useGcOrientationData from "src/domain-features/siteorientation/utils/useGcOrientationData";
import GCProjectOrientationLinkedMessage from "../settings/GCProjectOrientationLinkedMessage";

const GCProjectOrientationBuilder: React.FC<ProjectIdProps> = (props) => {
  const { getProjectOrientation } = useGcOrientationData(props.projectId);
  const projectData = getProjectOrientation();
  return projectData.orientation_project_id === props.projectId ? (
    <div className={`flex flex-col gap-1`}>
      {/*  Modules*/}
      <GCProjectOrientationBuilderModules projectId={props.projectId} />
      {/* Registration Questions */}
      <GCProjectOrientationBuilderRegistrationQuestions
        projectId={props.projectId}
      />
      {/* Other Settings TODO: Restructure*/}
      <GCProjectOrientationBuilderOtherSettings projectId={props.projectId} />
    </div>
  ) : (
    <GCProjectOrientationLinkedMessage
      linkedProjectId={projectData.orientation_project_id}
    />
  );
};

export default withAppPageLayout(
  withProjectIdUrlParam(GCProjectOrientationBuilder),
  {
    pageTitle: "Project Orientation Builder",
  },
);
