import { Card } from "antd";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";
import { ButtonProps } from "antd/es/button";
import { BaseButtonProps } from "antd/lib/button/button";

const actions: ButtonProps[] = [];

for (let i = 0; i < 5; i++) {
  actions.push({
    children: `Action ${i}`,
    onClick: () => {
      console.log(`Action ${i} clicked`);
    },
    type: ["primary", "default", "dashed", "link", "text"][
      i % 5
    ] as BaseButtonProps["type"],
  });
}

const DemoDraft: React.FC = () => {
  return (
    <AppPageLayout pageTitle={"Demo Draft"} pageActions={actions}>
      <Card>test your component here...</Card>
    </AppPageLayout>
  );
};

export default DemoDraft;
