import {
  Button,
  Card,
  Form,
  Image,
  message,
  notification,
  Popconfirm,
  Radio,
  Switch,
  Typography,
  Tooltip,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { SelectorStoreUpdater } from "relay-runtime";
import AddLogoModal, {
  AddLogoModalRef,
} from "src/common/components/dialogs/AddLogoModal";
import AddProcoreIntegrationModal, {
  AddProcoreIntegrationModalRef,
} from "src/common/components/dialogs/AddProcoreIntegrationModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useProcoreSetUserAccountMutation } from "src/common/types/generated/apollo/graphQLTypes";
import {
  GCCompanySettingsGeneralQuery,
  project_set_input,
} from "src/common/types/generated/relay/GCCompanySettingsGeneralQuery.graphql";
import { GCCompanySettingsGeneral_clearAuthData_Mutation } from "src/common/types/generated/relay/GCCompanySettingsGeneral_clearAuthData_Mutation.graphql";
import {
  GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation,
  GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation$data,
} from "src/common/types/generated/relay/GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation.graphql";
import { GCCompanySettingsGeneral_removeAuthData_Mutation } from "src/common/types/generated/relay/GCCompanySettingsGeneral_removeAuthData_Mutation.graphql";
import { GCCompanySettingsGeneral_UpdateGeneralContractorMutation } from "src/common/types/generated/relay/GCCompanySettingsGeneral_UpdateGeneralContractorMutation.graphql";
import { GCCompanySettingsGeneral_removeProjectData_Mutation } from "src/common/types/generated/relay/GCCompanySettingsGeneral_removeProjectData_Mutation.graphql";
import GCCompleteCorporateEmailSelectModal from "../components/GCCompleteCorporateEmailSelectModal";
import GetFullID from "src/common/functions/GetFullId";
import { GCCompanySettingsGeneral_updateGC_Mutation } from "src/common/types/generated/relay/GCCompanySettingsGeneral_updateGC_Mutation.graphql";
import { GCCompanySettingsGeneral_updateProjects_Mutation } from "src/common/types/generated/relay/GCCompanySettingsGeneral_updateProjects_Mutation.graphql";
import { GCCompanySettingsGeneral_UpdateWaiverText_Mutation } from "src/common/types/generated/relay/GCCompanySettingsGeneral_UpdateWaiverText_Mutation.graphql";
import SwitchWithText from "src/common/components/SwitchWithText";
import JoditEditor from "jodit-react";
import { JoditConfig } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import useAuthUser from "src/common/hooks/useAuthUser";
import ConnectAsUserButton, {
  procoreCompanyRedirectUrl,
} from "src/common/components/ConnectProcoreUserButton";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import withCorporateAdminWrapper from "../../withCorporateAdminWrapper";
import SelectIncorrectWorkersModal from "src/root/routes/views/general-contractor/hierarchy/corporate/settings/company/SelectIncorrectWorkersModal";

// const GetFullID = (table: string, id: string) =>
//   btoa(`[1, "public", "${table}", "${id}"]`);
import {
  DeleteOutlined,
  PictureOutlined,
  UserOutlined,
} from "@ant-design/icons";
import DividerSplitWrapper from "../../../../../../../../common/components/DividerSplitWrapper";

const SettingsQuery = graphql`
  query GCCompanySettingsGeneralQuery($uid: uuid!) {
    email_general_contractor_user_connection(
      where: {
        general_contractor: { employees: { uid: { _eq: $uid } } }
        user: { terminated_at: { _is_null: true } }
      }
      first: 10000
    )
      @connection(
        key: "GCCompanySettingsGeneralQuery_email_general_contractor_user_connection"
        filters: []
      ) {
      edges {
        node {
          general_contractor_id
          user_id
          id
          type
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    project_connection(
      where: { general_contractor: { employees: { uid: { _eq: $uid } } } }
      first: 10000
      order_by: { name: asc }
    )
      @connection(
        key: "GCCompanySettingsGeneralQuery_project_connection"
        filters: []
      ) {
      edges {
        node {
          name
          id
          send_corporate_video_completion_report
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    user_connection(where: { id: { _eq: $uid } }) {
      edges {
        node {
          employee {
            general_contractor_id
            is_corporate_admin
            general_contractor {
              logo_url
              require_phone
              hierarchy_division_name
              hierarchy_business_unit_name
              hierarchy_office_name
              require_dob
              send_weekly_email
              visitor_waiver_text
              employees(
                where: { user: { terminated_at: { _is_null: true } } }
                order_by: { user: { name: asc } }
              ) {
                uid
                user {
                  name
                }
              }
              procore_auth_data {
                id
                service_account_client_id
                user_id
                user_name
                user_login
                integration_enabled
                sandbox
              }
              hot_work_permit_name
            }
          }
        }
      }
    }
  }
`;
const procoreSwitchUpdater: SelectorStoreUpdater<
  GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation$data
> = (store, payload) => {
  if (!payload || !payload.insert_procore_auth_data_one) return;
  const gc_id = payload.insert_procore_auth_data_one
    .general_contractor_id as string;
  const value = payload.insert_procore_auth_data_one.integration_enabled;
  const parent_id = GetFullID("general_contractor", gc_id);
  const parent = store.get(parent_id);
  let record = parent?.getLinkedRecord("procore_auth_data");
  if (!record) {
    const record_id = GetFullID("procore_auth_data", gc_id);
    record = store.get(record_id);
    if (!record) record = store.create(record_id, "procore_auth_data");
    parent?.setLinkedRecord(record, "procore_auth_data");
  }
  record.setValue(value, "integration_enabled");
};

const GCCompanySettingsGeneral: FC = () => {
  // Refresh
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState(Object);
  const queryOptions = refreshedQueryOptions ?? {};

  const navigate = useNavigate();

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "network-only",
    }));
  }, []);
  // Refresh ends
  const [upsertProcoreIntegrationEnabled] =
    useAsyncMutation<GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation>(graphql`
      mutation GCCompanySettingsGeneral_procoreSetIntegrationSwitch_Mutation(
        $switch: Boolean!
        $GCId: uuid!
      ) {
        insert_procore_auth_data_one(
          object: { general_contractor_id: $GCId, integration_enabled: $switch }
          on_conflict: {
            constraint: procore_auth_data_pkey
            update_columns: [integration_enabled]
          }
        ) {
          general_contractor_id
          integration_enabled
        }
      }
    `);

  const [updateGeneralContractor] =
    useAsyncMutation<GCCompanySettingsGeneral_UpdateGeneralContractorMutation>(graphql`
      mutation GCCompanySettingsGeneral_UpdateGeneralContractorMutation(
        $id: uuid!
        $_set: general_contractor_set_input!
      ) {
        update_general_contractor_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `);
  const authUser = useAuthUser();
  const addProcoreIntegrationModal =
    useRef<AddProcoreIntegrationModalRef>(null);
  const data = useLazyLoadQuery<GCCompanySettingsGeneralQuery>(
    SettingsQuery,
    {
      uid: authUser.uid,
    },
    queryOptions,
  );

  const employee = data.user_connection.edges[0]?.node.employee;

  const general_contractor_id = employee?.general_contractor_id;
  if (!general_contractor_id)
    throw new Error("General contractor is not specified");

  const auth_data = employee?.general_contractor.procore_auth_data;

  // Use useMemo to segregate users based on their types
  const corporateCompletionUsers = useMemo(() => {
    return data.email_general_contractor_user_connection.edges
      .filter((gcu) => gcu.node.type === "corporate_completion_weekly_email")
      .map((gcu) => gcu.node.user_id);
  }, [data.email_general_contractor_user_connection.edges]);

  const incorrectWorkersUsers = useMemo(() => {
    return data.email_general_contractor_user_connection.edges
      .filter((gcu) => gcu.node.type === "incorrect_workers")
      .map((gcu) => gcu.node.user_id);
  }, [data.email_general_contractor_user_connection.edges]);

  const [hotWorkPermitName, setHotWorkPermitName] = useState<string>(
    employee?.general_contractor.hot_work_permit_name ?? "",
  );

  const sandbox_suffix = auth_data?.sandbox ? " [sandbox]" : "";
  const conection_state = auth_data?.user_login
    ? `Procore User Login: ${auth_data.user_login} ${
        auth_data.user_name || ""
      }${sandbox_suffix}`
    : auth_data?.service_account_client_id
    ? `Service Account Client Id: ${auth_data.service_account_client_id}${sandbox_suffix}`
    : "Please select authentication method";

  const has_auth_data = !!(
    auth_data?.user_login || auth_data?.service_account_client_id
  );

  const procoreUserButtonTitle = auth_data?.user_login // "Connect as Procore User";
    ? "Change Procore User"
    : "Connect as Procore User";

  const serviceAccountButtonTitle =
    !auth_data?.user_login && auth_data?.service_account_client_id
      ? "Change Service Account"
      : "Connect as Service Account";

  const [SetProcoreUserAccount] = useProcoreSetUserAccountMutation({
    onCompleted: (data) => {
      if (data.procoreSetUserAccount?.user_id !== auth_data?.user_id) {
        removeProjectData();
      }
      refresh();
    },
  });
  const integrationEnabled = auth_data?.integration_enabled || false;
  // Remove Client
  const [corporateCompletionModalOpen, setCorporateCompletionModalOpen] =
    useState(false);
  const [incorrectWorkersModalOpen, setIncorrectWorkersModalOpen] =
    useState(false);
  const [clearIntegrationSettings] =
    useAsyncMutation<GCCompanySettingsGeneral_removeProjectData_Mutation>(
      graphql`
        mutation GCCompanySettingsGeneral_removeProjectData_Mutation(
          $general_contractor_id: uuid!
        ) {
          delete_procore_subcontractor(
            where: {
              project: {
                general_contractor_id: { _eq: $general_contractor_id }
                procore_project_data: {
                  user_id: { _is_null: true }
                  service_account_client_id: { _is_null: true }
                }
              }
            }
          ) {
            affected_rows
            returning {
              id @deleteRecord
            }
          }
          delete_procore_project_data(
            where: {
              project: {
                general_contractor_id: { _eq: $general_contractor_id }
                procore_project_data: {
                  user_id: { _is_null: true }
                  service_account_client_id: { _is_null: true }
                }
              }
            }
          ) {
            affected_rows
            returning {
              id @deleteRecord
            }
          }
        }
      `,
    );
  const [clearAuthData] =
    useAsyncMutation<GCCompanySettingsGeneral_clearAuthData_Mutation>(
      graphql`
        mutation GCCompanySettingsGeneral_clearAuthData_Mutation(
          $general_contractor_id: uuid!
        ) {
          update_procore_auth_data_by_pk(
            pk_columns: { general_contractor_id: $general_contractor_id }
            _set: {
              user_id: null
              user_name: null
              user_login: null
              refresh_token: null
              access_token: null
              token_expires_at: null
              service_account_client_id: null
              service_account_client_secret: null
            }
          ) {
            user_id
            user_name
            user_login
            refresh_token
            access_token
            token_expires_at
            service_account_client_id
            service_account_client_secret
          }
        }
      `,
    );
  const [updateGCData] =
    useAsyncMutation<GCCompanySettingsGeneral_updateGC_Mutation>(graphql`
      mutation GCCompanySettingsGeneral_updateGC_Mutation(
        $gcId: uuid!
        $_set: general_contractor_set_input!
      ) {
        update_general_contractor_by_pk(
          pk_columns: { id: $gcId }
          _set: $_set
        ) {
          id
          require_phone
          send_weekly_email
          require_dob
        }
      }
    `);
  const [updateProject] =
    useAsyncMutation<GCCompanySettingsGeneral_updateProjects_Mutation>(graphql`
      mutation GCCompanySettingsGeneral_updateProjects_Mutation(
        $gcId: uuid!
        $_set: project_set_input!
      ) {
        update_project(
          where: { general_contractor_id: { _eq: $gcId } }
          _set: $_set
        ) {
          returning {
            id
            require_phone
            require_dob
          }
        }
      }
    `);
  const [removeAuthData] =
    useMutation<GCCompanySettingsGeneral_removeAuthData_Mutation>(
      graphql`
        mutation GCCompanySettingsGeneral_removeAuthData_Mutation(
          $general_contractor_id: uuid!
        ) {
          delete_procore_auth_data(
            where: { general_contractor_id: { _eq: $general_contractor_id } }
          ) {
            affected_rows
          }
        }
      `,
    );
  const [updateWaiverText] =
    useAsyncMutation<GCCompanySettingsGeneral_UpdateWaiverText_Mutation>(graphql`
      mutation GCCompanySettingsGeneral_UpdateWaiverText_Mutation(
        $gcId: uuid!
        $waiverText: String!
      ) {
        update_general_contractor(
          where: { id: { _eq: $gcId } }
          _set: { visitor_waiver_text: $waiverText }
        ) {
          affected_rows
        }
      }
    `);
  const removeLoginData = () => {
    removeAuthData({
      variables: {
        general_contractor_id,
      },
      onCompleted: () => {
        message.success("Done");
      },
      onError: (e) => {
        notification.error({
          message: "Error in Removing Procore Integration ConfiguationS",
          description: e.message,
          duration: null,
        });
      },
    });
  };
  const removeProjectData = () => {
    return clearIntegrationSettings({
      variables: {
        general_contractor_id,
      },
      // onCompleted: () => {
      //   message.success("Done Remove " + l.name + " data");
      // },
      // onError: (e) => {
      //   notification.error({
      //     message: "Error in Removing Project Data",
      //     description: e.message,
      //     duration: null,
      //   });
      // },
    });
  };

  const setIntegrationEnabeld = (enabled: boolean) => {
    return upsertProcoreIntegrationEnabled({
      variables: {
        GCId: general_contractor_id,
        switch: enabled,
      },
      optimisticResponse: {
        insert_procore_auth_data_one: {
          id: GetFullID("procore_auth_data", general_contractor_id),
          general_contractor_id,
          integration_enabled: enabled,
        },
      },
      optimisticUpdater: procoreSwitchUpdater,
      updater: procoreSwitchUpdater,
    });
  };

  useEffect(() => {
    const search = document.location.search;
    const params = new URLSearchParams(search);
    if (params.has("error")) {
      notification.error({
        message: "Error: " + params.get("error"),
        description: params.get("error_description"),
      });
    }
    if (params.has("code")) {
      const sandbox = params.get("state") === "sbx";
      const code = params.get("code");
      const lastCode = sessionStorage.getItem("lastCode");
      if (code && code !== lastCode) {
        sessionStorage.setItem("lastCode", code);
        SetProcoreUserAccount({
          variables: {
            GCId: general_contractor_id,
            code,
            sandbox,
            redirect_url: procoreCompanyRedirectUrl,
            dmsa: false,
          },
        });
      }
    }
  }, []);

  const [form] = Form.useForm();
  // Logo Modal
  const [openHierarchy, setOpenHierarchy] = useState(false);
  const addLogoModal = useRef<AddLogoModalRef>(null);
  const logoUrl = employee?.general_contractor.logo_url;
  const showLogo = logoUrl !== null && logoUrl !== undefined;
  const updateLogoText = showLogo ? "Update Logo" : "Add Logo";
  if (!employee) {
    return null;
  }

  const updateValueForAllProjects = async (set: project_set_input) => {
    const requests = [
      updateProject({
        variables: {
          gcId: general_contractor_id,
          _set: set,
        },
      }),
      updateGCData({
        variables: {
          gcId: general_contractor_id,
          _set: set,
        },
        optimisticResponse: {
          update_general_contractor_by_pk: {
            id: GetFullID("general_contractor", general_contractor_id),
            require_phone: employee.general_contractor.require_phone,
            require_dob: employee.general_contractor.require_dob,
            ...set,
          },
        },
      }),
    ];
    await Promise.all(requests);
    message.success(Object.values(set)[0] ? "ON" : "OFF");
  };

  return (
    <>
      <GCCompleteCorporateEmailSelectModal
        visible={corporateCompletionModalOpen}
        gcId={employee.general_contractor_id}
        onClose={() => setCorporateCompletionModalOpen(false)}
        selectedProjects={data.project_connection.edges
          .filter((p) => p.node.send_corporate_video_completion_report)
          .map((p) => p.node.pk)}
        selectedUsers={corporateCompletionUsers}
        projects={
          employee
            ? data.project_connection.edges.map((v) => ({
                name: v.node.name,
                id: v.node.pk,
              }))
            : []
        }
        gcUsers={
          employee
            ? employee.general_contractor.employees.map((v) => ({
                name: v.user.name,
                id: v.uid,
              }))
            : []
        }
      />
      <SelectIncorrectWorkersModal
        visible={incorrectWorkersModalOpen}
        gcId={employee.general_contractor_id}
        onClose={() => setIncorrectWorkersModalOpen(false)}
        selectedUsers={incorrectWorkersUsers}
        gcUsers={
          employee
            ? employee.general_contractor.employees.map((v) => ({
                name: v.user.name,
                id: v.uid,
              }))
            : []
        }
      />
      {/* {employee.is_corporate_admin && (
        <div className="mb-2">
          <GcHierarchyModal
            open={openHierarchy}
            gcId={employee.general_contractor_id}
            hierarchy_business_unit_name={
              employee.general_contractor.hierarchy_business_unit_name
            }
            hierarchy_division_name={
              employee.general_contractor.hierarchy_division_name
            }
            hierarchy_office_name={
              employee.general_contractor.hierarchy_office_name
            }
            onCancel={() => setOpenHierarchy(false)}
          />
          <Button
            icon={IconTable}
            label={"Add Hierarchy Levels"}
            onClick={() => setOpenHierarchy(true)}
          ></Button>
        </div>
      )} */}
      <div className="flex flex-col gap-1">
        <Card title={"Logo"}>
          <div className={`flex flex-col gap-1 items-start`}>
            {showLogo && (
              <Image width={200} height={200} src={logoUrl as string} />
            )}
            <AddLogoModal
              ref={addLogoModal}
              isLogo={showLogo}
              id={general_contractor_id}
              onSubmit={() => {
                addLogoModal.current?.close();
              }}
            />
            <Button
              icon={<PictureOutlined />}
              onClick={() => addLogoModal.current?.open()}
            >
              {updateLogoText}
            </Button>
          </div>
        </Card>
        <Card title={`Corporate Video Email Settings`}>
          <Button onClick={() => setCorporateCompletionModalOpen(true)}>
            Update
          </Button>
        </Card>
        <Card title={"Employee Verification Email Settings"}>
          <Tooltip title="Select your Team Member that will be notified by email when a Subcontractor marks a worker as “Not our Employee” meaning they do not recognize an employee">
            <Button onClick={() => setIncorrectWorkersModalOpen(true)}>
              Update
            </Button>
          </Tooltip>
        </Card>
        <Card title={"Permit Name"}>
          <Radio.Group
            style={{ width: "100%" }}
            value={hotWorkPermitName}
            onChange={async (e) => {
              const newPermitName = e.target.value;
              setHotWorkPermitName(newPermitName);
              await updateGeneralContractor({
                variables: {
                  id: general_contractor_id,
                  _set: {
                    hot_work_permit_name: newPermitName,
                  },
                },
              });
              message.success("Permit Name Updated");
            }}
          >
            <Radio value="hot work">Hot Work</Radio>
            <Radio value="burn">Burn</Radio>
          </Radio.Group>
        </Card>

        <AddProcoreIntegrationModal
          ref={addProcoreIntegrationModal}
          has_auth_data={has_auth_data}
          GCId={employee?.general_contractor_id as string}
          onSubmit={async () => {
            await removeProjectData();
            setIntegrationEnabeld(true);
            //                  refresh();
          }}
        />
        <Card title={"Weekly Email"}>
          <SwitchWithText
            //disabled={!has_auth_data}
            text="Turn ON weekly email for all Projects by default"
            onChange={async (checked) => {
              await updateGCData({
                variables: {
                  gcId: general_contractor_id,
                  _set: { send_weekly_email: checked },
                },
                optimisticResponse: {
                  update_general_contractor_by_pk: {
                    id: GetFullID("general_contractor", general_contractor_id),
                    send_weekly_email: checked,
                    require_phone: employee.general_contractor.require_phone,
                    require_dob: employee.general_contractor.require_dob,
                  },
                },
              });
              message.success(checked ? "ON" : "OFF");
            }}
            checked={!!employee.general_contractor.send_weekly_email}
          />
        </Card>
        <Card title={"Workers Registration"}>
          <DividerSplitWrapper>
            <SwitchWithText
              //disabled={!has_auth_data}
              text="Require ALL workers on ALL projects to provide their phone number during registration"
              explanation={
                "This will remove the requirement for workers to provide their Phone # on ALL projects"
              }
              onChange={async (checked) => {
                await updateValueForAllProjects({ require_phone: checked });
              }}
              checked={employee.general_contractor.require_phone}
            />

            <SwitchWithText
              //disabled={!has_auth_data}
              explanation={
                "This will remove the requirement for workers to provide their DOB on ALL projects"
              }
              text="Require ALL workers on ALL projects to provide their date of birth during registration"
              onChange={async (checked) => {
                await updateValueForAllProjects({ require_dob: checked });
              }}
              checked={employee.general_contractor.require_dob}
            />
          </DividerSplitWrapper>
        </Card>

        <Card title={"Visitor Waiver"}>
          <Form form={form} layout="vertical">
            <Form.Item
              name="waiverText"
              initialValue={employee.general_contractor.visitor_waiver_text}
            >
              <JoditEditor
                value=""
                config={{
                  ...JoditConfig,
                  readonly: false,
                }}
              />
            </Form.Item>
          </Form>
          <Button
            onClick={async () => {
              const values = await form.validateFields().catch((v) => null);
              if (!values) return;
              await updateWaiverText({
                variables: {
                  gcId: employee.general_contractor_id,
                  waiverText: values.waiverText,
                },
              });
              notification.success({
                message: "Done",
                duration: 2,
              });
            }}
          >
            Save
          </Button>
        </Card>
        <Card title={"Procore"}>
          <div className={`flex flex-col gap-1 items-start`}>
            <Typography>
              <Switch
                onChange={async (checked) => {
                  await setIntegrationEnabeld(checked);
                  if (checked) {
                    message.success("On");
                  } else {
                    message.error("Off");
                  }
                }}
                checked={integrationEnabled}
              />{" "}
              <Typography.Text>Procore Integration</Typography.Text>
            </Typography>
            {/*TODO: implment dmsa logic*/}
            <ConnectAsUserButton title={procoreUserButtonTitle} dmsa={false} />
            <Button
              icon={<UserOutlined />}
              onClick={() => addProcoreIntegrationModal.current?.open()}
            >
              {serviceAccountButtonTitle}
            </Button>
            {has_auth_data && (
              <>
                <Popconfirm
                  title="Are you sure to clear procore integration configuation?"
                  onConfirm={async () => {
                    await Promise.all([
                      clearAuthData({
                        variables: {
                          general_contractor_id,
                        },
                      }),
                      removeProjectData(),
                    ]);
                    refresh();
                  }}
                  onCancel={() => {}}
                  okText="Confirm"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />}>Remove login data</Button>
                </Popconfirm>
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default GCCompanySettingsGeneral;
