import clsx from "clsx";
import React from "react";
import datesFromRange from "src/common/functions/dates/datesFromRange";
import { DatesRangeType } from "src/common/types/manual/DatesRange";
import SitedeliveryCalendarWeekChangeButton from "../components/SitedeliveryCalendarWeekChangeButton";
import { DeliveryType } from "../utilities/sitedeliveryTypes";
import GCProjectCalendarSitedeliveryDeliveriesCalendarsFilter, {
  GCProjectCalendarSitedeliveryDeliveriesCalendarsFilterProps,
} from "./GCProjectCalendarSitedeliveryDeliveriesCalendarsFilter";
import GCProjectCalendarSitedeliveryDeliveriesDay from "./GCProjectCalendarSitedeliveryDeliveriesDay";
import {
  GetDeliveryFieldsQuery,
  Project_Calendar_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import SitedeliveryCalendarTimeStamps from "../../components/time-stamps/SitedeliveryCalendarTimeStamps";

export interface GCProjectCalendarSitedeliveryDeliveriesUIProps
  extends GCProjectCalendarSitedeliveryDeliveriesCalendarsFilterProps {
  deliveries: DeliveryType[];
  datesRange: DatesRangeType;
  logisticPlan: GetDeliveryFieldsQuery["project"][number]["logistic_plans"];
  onDatesRangeChange?: (newDatesRange: DatesRangeType) => void;
  loading?: boolean;
  timezone?: string;
  deliveryContentShownOnCards?: boolean; // shows delivery title and content without hovering
  keepTimelineInView?: boolean;
  hideLogistic?: boolean;
  gcId?: string;
  handleDeliverySelect?: (deliveryId: string) => void;
  openAddNewDeliveryModal?: () => void;
  calendars: any[];
  onInsertCalendar: (
    calendar: Project_Calendar_Insert_Input[],
  ) => Promise<void>;
}

const GCProjectCalendarSitedeliveryDeliveriesUI: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesUIProps
> = (props) => {
  const goToPrevWeek = () => {
    if (!props.onDatesRangeChange) return;
    const from = props.datesRange.from.subtract(7, "days");
    const to = props.datesRange.to.subtract(7, "days");
    props.onDatesRangeChange({
      from,
      to,
    });
  };

  const goToNextWeek = () => {
    if (!props.onDatesRangeChange) return;
    const to = props.datesRange.to.add(7, "days");
    const from = props.datesRange.from.add(7, "days");
    props.onDatesRangeChange({
      from,
      to,
    });
  };

  // TODO: filter them in parent component...

  return (
    <div className="flex flex-col w-full h-full gap-1">
      {!!props.calendars && (
        <GCProjectCalendarSitedeliveryDeliveriesCalendarsFilter
          calendars={props.calendars}
          logisticPlan={props.logisticPlan}
          toggleCalendarVisible={props.toggleCalendarVisible}
          hideLogistic={props.hideLogistic}
          gcId={props.gcId}
          openAddNewDeliveryModal={props.openAddNewDeliveryModal}
          onInsertCalendar={props.onInsertCalendar}
        />
      )}
      <div className="flex-1 w-full h-0">
        <div
          className={clsx(
            "relative w-full h-full",
            props.loading && "cursor-wait pointer-events-none",
          )}
        >
          <div className="relative w-full h-full">
            <div className="flex flex-row h-full w-full gap-0.25 relative">
              <div
                className={clsx(
                  props.keepTimelineInView ? `min-h-[125rem]` : `min-h-[60rem]`,
                  `h-full flex w-full flex-row gap-0.25 relative`,
                )}
              >
                <div className={`absolute pt-2 h-full bottom-0 left-0`}>
                  <SitedeliveryCalendarTimeStamps
                    limitTimeRange={!props.keepTimelineInView}
                    type={"digits"}
                  />
                </div>

                {!!props.onDatesRangeChange ? (
                  <SitedeliveryCalendarWeekChangeButton
                    prev
                    onClick={goToPrevWeek}
                  />
                ) : (
                  <div className={`w-2`}></div>
                )}
                {datesFromRange(props.datesRange).map((date) => {
                  const dayStart = date;
                  const nextDayStart = date.add(1, "day");

                  const deliveriesOfDay = props.deliveries.filter(
                    (delivery) =>
                      delivery.from.isBefore(nextDayStart) &&
                      delivery.to.isAfter(dayStart),
                  );

                  return (
                    <div
                      key={date.valueOf()}
                      style={
                        deliveriesOfDay.length > 0
                          ? {
                              flex: "2 1 0%",
                            }
                          : {
                              flex: "1 1 0%",
                            }
                      }
                      className={"w-0"}
                    >
                      <GCProjectCalendarSitedeliveryDeliveriesDay
                        deliveries={deliveriesOfDay}
                        date={date}
                        deliveryContentShownOnCard={
                          props.deliveryContentShownOnCards
                        }
                        keepTimelineInView={props.keepTimelineInView}
                        handleDeliverySelect={props.handleDeliverySelect}
                      />
                    </div>
                  );
                })}
                {!!props.onDatesRangeChange && (
                  <SitedeliveryCalendarWeekChangeButton
                    next
                    onClick={goToNextWeek}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GCProjectCalendarSitedeliveryDeliveriesUI;
