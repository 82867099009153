import JHAEditNotifications from "../../../../../../../../common/components/JHAEditNotifications";
import React from "react";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../../../../../common/types/manual/ProjectIdProps";

const GCProjectSettingsJha: React.FC<ProjectIdProps> = (props) => {
  return (
    <div>
      <JHAEditNotifications projectId={props.projectId} />
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectSettingsJha);
