/**
 * @generated SignedSource<<6831e48e67aedde15438b4ccb29b7e72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { crew_safety_report_not_require_period_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation$variables = {
  objects: ReadonlyArray<crew_safety_report_not_require_period_insert_input>;
};
export type usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation$data = {
  readonly insert_crew_safety_report_not_require_period: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly project_crew_id: string;
    }>;
  } | null | undefined;
};
export type usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation = {
  response: usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation$data;
  variables: usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "crew_safety_report_not_require_p_project_crew_id_start_date_key",
          "update_columns": [
            "close_date"
          ]
        }
      }
    ],
    "concreteType": "crew_safety_report_not_require_period_mutation_response",
    "kind": "LinkedField",
    "name": "insert_crew_safety_report_not_require_period",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "crew_safety_report_not_require_period",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_crew_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f5e306fde4dd11a13cda592d5ff4141",
    "id": null,
    "metadata": {},
    "name": "usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentReportRequireSettingsCrew_PTPNotRequire_UpsertMutation(\n  $objects: [crew_safety_report_not_require_period_insert_input!]!\n) {\n  insert_crew_safety_report_not_require_period(objects: $objects, on_conflict: {constraint: crew_safety_report_not_require_p_project_crew_id_start_date_key, update_columns: [close_date]}) {\n    returning {\n      project_crew_id\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05d87bd66c4d9c998fdc583d0ac6b272";

export default node;
