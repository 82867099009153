import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "src/utility-features/authorization/AuthProvider";

export type UserRole =
  | "employee"
  | "admin"
  | "subcontractor_employee"
  | "worker"
  | "pseudo_worker"
  | "sf_employee"
  | "customer_portal"
  | "mobilization_user"
  | "quiz_qr_user";
interface RequireRoleWrapperProps {
  requiredRole: UserRole | Array<UserRole>;
  children: React.ReactNode;
}

const RequireRoleWrapper: React.FunctionComponent<RequireRoleWrapperProps> = ({
  requiredRole,
  children,
}) => {
  // use roleVar because does not rerender component on role change (like useGetUserRoleQuery does)
  const auth = useAuth();
  const role = auth.uid ? auth.role : "anonymous";

  const roleMatch = Array.isArray(requiredRole)
    ? requiredRole.includes(role as UserRole)
    : role == requiredRole;
  const location = useLocation();
  if (!roleMatch) {
    let navigateLink = "/";
    switch (role) {
      case "employee":
        navigateLink = "/gce";
        break;
      case "admin":
        navigateLink = "/admin";
        break;
      case "subcontractor_employee":
        navigateLink = "/sub";
        break;
      case "sf_employee":
        navigateLink = "/sfe";
        break;
      case "customer_portal":
        navigateLink = "/csr";
        break;
      case "worker":
        navigateLink = "/worker";
        break;
      default:
        break;
    }
    if (location.pathname.trim() && navigateLink === "/") navigateLink = `/?path=${location.pathname}`;
    return <Navigate to={navigateLink} replace />;
  }
  //}
  return <>{children}</>;
};

export default RequireRoleWrapper;
