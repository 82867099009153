import ChartWrapperCard from "src/common/components/charts/ChartWrapperCard";
import ShowPieChart from "src/common/components/charts/pie/ShowPieChart";
import { PieChartProps } from "src/common/components/charts/pie/PieChart";
import colorList, {
  getColorFromColorList,
} from "src/common/components/lists/chartColorList";
import { Flex, Progress } from "antd";
import { useMemo } from "react";
import { GetObsLeaderboardDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import ColumnChart, {
  ColumnChartProps,
} from "src/common/components/charts/ColumnChart";
import getObsFindingsCount from "../utils/getObsFindingsCount";

type MapValueType = {
  name: string;
  oneOffFindings: number;
  auditsCount: number;
  auditFindings: number;
};

const LeaderboardCharts: React.FC<{
  users: GetObsLeaderboardDataQuery["user"];
  monthsCount: number;
  titleReqMap: Map<
    string,
    GetObsLeaderboardDataQuery["observation_requirement"][number]
  >;
}> = ({ users, titleReqMap, monthsCount }) => {
  const { titlesList, groupsList, auditsDone, auditsReq } = useMemo(() => {
    const titlesMap = new Map<string, MapValueType>();
    const groupsMap = new Map<string, MapValueType>();
    let auditsReq = 0,
      auditsDone = 0;
    users.forEach((user) => {
      const { oneOffFindings, auditFindings } = getObsFindingsCount(
        user.created_observations,
      );
      const title = user.employee?.employee_title;
      if (title) {
        const titleCountMapVal = titlesMap.get(title.id);
        if (titleCountMapVal) {
          titleCountMapVal.oneOffFindings += oneOffFindings;
          titleCountMapVal.auditsCount +=
            user.created_observation_inspections.length;
          titleCountMapVal.auditFindings += auditFindings;
        } else
          titlesMap.set(title.id, {
            name: title.name.en,
            oneOffFindings,
            auditsCount: user.created_observation_inspections.length,
            auditFindings,
          });
        const requirement = titleReqMap.get(title.id);
        if (requirement) {
          auditsDone += user.created_observation_inspections.length;
          auditsReq += requirement.audits_required * monthsCount;
          const groupCountMapVal = groupsMap.get(requirement.id);
          if (groupCountMapVal) {
            groupCountMapVal.oneOffFindings += oneOffFindings;
            groupCountMapVal.auditsCount +=
              user.created_observation_inspections.length;
            groupCountMapVal.auditFindings += auditFindings;
          } else
            groupsMap.set(title.id, {
              name: title.name.en,
              oneOffFindings,
              auditsCount: user.created_observation_inspections.length,
              auditFindings,
            });
        }
      }
    });

    const titlesList: {
      oneOffFindingsList: PieChartProps["dataSource"];
      auditsList: PieChartProps["dataSource"];
      findingsPerAuditList: PieChartProps["dataSource"];
    } = { oneOffFindingsList: [], auditsList: [], findingsPerAuditList: [] };
    let titleInd = 0;

    titlesMap.forEach((title) => {
      if (title.oneOffFindings)
        titlesList.oneOffFindingsList.push({
          name: title.name,
          value: title.oneOffFindings,
          color: colorList[titleInd],
        });
      if (title.auditsCount) {
        titlesList.auditsList.push({
          name: title.name,
          value: title.auditsCount,
          color: colorList[titleInd],
        });
        if (title.auditFindings)
          titlesList.findingsPerAuditList.push({
            name: title.name,
            value: title.auditFindings / title.auditsCount,
            color: colorList[titleInd],
          });
      }
      titleInd += 1;
    });

    const groupsList: {
      oneOffFindingsList: PieChartProps["dataSource"];
      auditsList: PieChartProps["dataSource"];
      findingsPerAuditList: PieChartProps["dataSource"];
    } = { oneOffFindingsList: [], auditsList: [], findingsPerAuditList: [] };
    let groupInd = 0;

    groupsMap.forEach((group) => {
      if (group.oneOffFindings)
        groupsList.oneOffFindingsList.push({
          name: group.name,
          value: group.oneOffFindings,
          color: colorList[groupInd],
        });
      if (group.auditsCount) {
        groupsList.auditsList.push({
          name: group.name,
          value: group.auditsCount,
          color: colorList[groupInd],
        });
        if (group.auditFindings)
          groupsList.findingsPerAuditList.push({
            name: group.name,
            value: group.auditFindings / group.auditsCount,
            color: colorList[groupInd],
          });
      }
      groupInd += 1;
    });

    return { titlesList, groupsList, auditsDone, auditsReq };
  }, [users, titleReqMap, monthsCount]);
  const auditsCompletionPercent = auditsReq
    ? (auditsDone / auditsReq) * 100
    : 0;
  return (
    <Flex gap={"small"} vertical>
      <div className={`grid grid-cols-2 gap-1`}>
        <ChartWrapperCard title="Progress Toward Goal">
          <Progress
            type="circle"
            percent={auditsCompletionPercent}
            size={400}
            format={(percent) => (
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {typeof percent === "number"
                    ? `${Math.round(percent * 100) / 100}%`
                    : null}
                </div>
                <div style={{ color: "#888" }}>
                  {auditsDone}/{auditsReq}
                </div>
              </div>
            )}
          />
        </ChartWrapperCard>
        <ChartWrapperCard title="Audits per Employee">
          <ColumnChart
            dataSource={users.reduce((list, u, i) => {
              if (u.created_observation_inspections.length) {
                list.push({
                  value: u.created_observation_inspections.length,
                  xField: u.name,
                  color: getColorFromColorList(i),
                  valueTitle: "Audits",
                });
              }
              return list;
            }, [] as ColumnChartProps["dataSource"])}
          />
        </ChartWrapperCard>
        <ChartWrapperCard title={`One-off Observations by Titles`}>
          <ShowPieChart
            dataSource={titlesList.oneOffFindingsList}
            valueTitle="One-off Observations"
          />
        </ChartWrapperCard>
        <ChartWrapperCard title={`One-off Observations by Groups`}>
          <ShowPieChart
            dataSource={groupsList.oneOffFindingsList}
            valueTitle="One-off Observations"
          />
        </ChartWrapperCard>

        <ChartWrapperCard title={`Audits by Titles`}>
          <ShowPieChart
            dataSource={titlesList.auditsList}
            valueTitle="Audits"
          />
        </ChartWrapperCard>
        <ChartWrapperCard title={`Audits by Groups`}>
          <ShowPieChart
            dataSource={groupsList.auditsList}
            valueTitle="Audits"
          />
        </ChartWrapperCard>
        <ChartWrapperCard title={`Findings/Audit by Titles`}>
          <ShowPieChart
            dataSource={titlesList.findingsPerAuditList}
            valueTitle="Findings/Audit"
          />
        </ChartWrapperCard>
        <ChartWrapperCard title={`Findings/Audit by Groups`}>
          <ShowPieChart
            dataSource={groupsList.findingsPerAuditList}
            valueTitle="Findings/Audit"
          />
        </ChartWrapperCard>
      </div>
    </Flex>
  );
};
export default LeaderboardCharts;
