import { FC } from "react";
import {
  useUpdateRootCauseAnalysisMutation,
  Root_Cause_Analysis_Set_Input,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card } from "antd";
import IncidentTextField from "./basic/TextField";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import IncidentDocViewAndUpload from "./basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "./basic/IncidentBottomButtons";
import IncidentDatepicker from "./basic/Datepicker";
import { DeletedDocument } from "./basic/IncidentDocViewer";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../utils/IncidentTypeProps";
import useUploadIncidentDocuments from "../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../utils/useDeleteIncidentDocument";
import IncidentSubViewLayout from "./IncidentSubViewLayout";

const RootCauseAnalysis: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [updateRootCause, { loading: updateRootCauseLoading }] =
    useUpdateRootCauseAnalysisMutation();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const rootCauseAnalysis = incident.root_cause_analysis;
  if (!rootCauseAnalysis) {
    throw new Error("root_cause_analysis id missing");
  }

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "root_cause_analysis",
      incident,
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "root_cause_analysis",
      incident,
      objects,
    });
  };

  const updateRootCauseAnalysis = async (
    _set: Omit<Root_Cause_Analysis_Set_Input, "incident_id" | "id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      root_cause_analysis: {
        ...rootCauseAnalysis,
        ..._set,
      },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateRootCause({
      variables: {
        id: rootCauseAnalysis.id,
        _set: _set,
        objects: {
          patch: patch,
          incident_id: incident.id,
          edit_type: "root_cause_analysis_update",
          edited_by_uid: authUser.uid,
          comment: comment,
        },
      },
      optimisticResponse: {
        update_root_cause_analysis_by_pk: {
          ...rootCauseAnalysis,
          id: rootCauseAnalysis.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  return (
    <>
      <IncidentSubViewLayout title="Root Cause Details">
        <div className="flex flex-col gap-1">
          <Card>
            <IncidentTextField
              label="Brief Root Cause of incident"
              required={true}
              text={rootCauseAnalysis.root_cause.en}
              textId={rootCauseAnalysis.root_cause.id}
              fieldTypeKey={"root_cause"}
              field="root_cause"
              incident={incident}
            />
          </Card>

          <Card>
            <IncidentTextField
              label="Who participated in this analysis"
              text={rootCauseAnalysis.analysis_participants.en}
              textId={rootCauseAnalysis.analysis_participants.id}
              fieldTypeKey={"root_cause"}
              field="analysis_participants"
              incident={incident}
            />
          </Card>
          <Card>
            <IncidentDatepicker
              format="YYYY-MM-DD"
              label="Date of Root Cause Meeting"
              onChange={async (date) => {
                await updateRootCauseAnalysis(
                  { date_of_meeting: date },
                  `Updated Root Cause Analysis - "Date of Root Cause Meeting" to "${dayjs(
                    date,
                  ).format("DD MMM, YYYY")}"`,
                );
              }}
              value={rootCauseAnalysis.date_of_meeting ?? undefined}
              disabled={updateRootCauseLoading}
            />
          </Card>

          <Card>
            <IncidentTextField
              label="Additional Details"
              text={rootCauseAnalysis.additional_details.en}
              textId={rootCauseAnalysis.additional_details.id}
              fieldTypeKey={"root_cause"}
              field="additional_details"
              incident={incident}
            />
          </Card>

          <Card>
            <IncidentDocViewAndUpload
              deleteDocument={deleteDocument}
              documents={rootCauseAnalysis.document}
              groupId={rootCauseAnalysis.id}
              uploadDocument={uploadDocument}
              type="root_cause_analysis"
            />
          </Card>

          <IncidentBottomButtons
            saveDisabled={
              !rootCauseAnalysis.root_cause.en ||
              !rootCauseAnalysis.date_of_meeting
            }
            onNextClick={() => setSearchParams({ subview: "witness" })}
            onSave={() => {
              updateRootCauseAnalysis(
                { completed_at: dayjs().format() },
                `Root Cause Analysis section marked as completed`,
              );
            }}
          />
        </div>
      </IncidentSubViewLayout>
    </>
  );
};

export default RootCauseAnalysis;
