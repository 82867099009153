import { DivisionProps } from "../../../../utils/withDivisionId";
import GCAdminObservations from "../../../../components/observation/GCAdminObservations";
import useGcEmployee from "../../../utils/useGcEmployee";

const GCDivisionObservations: React.FC<DivisionProps> = ({ divisionId }) => {
  const emp = useGcEmployee();
  const projWhere = { gc_division_id: { _eq: divisionId } };

  return (
    <GCAdminObservations
      optionsQueryVars={{
        projWhere,
        gcDivisionWhere: { id: { _is_null: true } },
        gcBuWhere: emp.general_contractor.hierarchy_business_unit_name
          ? { gc_division_id: { _eq: divisionId } }
          : { id: { _is_null: true } },
        gcOfficeWhere: emp.general_contractor.hierarchy_office_name
          ? { gc_division_id: { _eq: divisionId } }
          : { id: { _is_null: true } },
      }}
      projWhere={projWhere}
      organizationId={divisionId}
      organizationLevel="division"
    />
  );
};
export default GCDivisionObservations;
