import { useRef, useState } from "react";
import { Select, Form, Switch } from "antd";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import {
  GetObsNotificationsQuery,
  useInsertObsNotifMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { notifToggleLabels, ToggleFields } from "./GCObsNotifications";
import * as uuid from "uuid";

const notifToggleKeys: ToggleFields[] = [
  "notify_selected_user",
  "notify_proj_team",
  "notify_sub_poc",
  "notify_worker",
];
type FormVals = {
  risk_level: string;
  notify_sub_poc: boolean;
  notify_worker: boolean;
  notify_proj_team: boolean;
  notify_selected_user: boolean;
  selectedUserIds: string[];
};
const CreateNewObsNotifModal: React.FC<{
  open: boolean;
  gcId: string;
  riskLevels: GetObsNotificationsQuery["risk_level"];
  selectableUsers: { label: string; value: string }[];
  onClose: () => void;
  onFinish: () => void;
}> = ({ open, onFinish, onClose, riskLevels, selectableUsers, gcId }) => {
  const [insertObsNotif, { loading }] = useInsertObsNotifMutation();
  const insertNew = async (vals: FormVals) => {
    const selectedRiskLevel = riskLevels.find(
      (r) => r.value === vals.risk_level,
    );
    if (!selectedRiskLevel) throw new Error("selected risk level not found");
    const expectedNotifObj = {
      id: uuid.v4(),
      notify_proj_team: vals.notify_proj_team,
      notify_sub_poc: vals.notify_sub_poc,
      notify_worker: vals.notify_worker,
      notify_selected_user: vals.notify_selected_user,
    };
    const notifyUsers = vals.notify_selected_user
      ? vals.selectedUserIds.map((user_id) => ({
          user_id,
          id: uuid.v4(),
        }))
      : [];
    await insertObsNotif({
      variables: {
        object: {
          gc_id: gcId,
          ...expectedNotifObj,
          risk_level_value: vals.risk_level,
          obs_notify_users: { data: notifyUsers },
        },
      },
      optimisticResponse: {
        insert_observation_notification_one: {
          __typename: "observation_notification",
          ...expectedNotifObj,
          obs_notify_users: notifyUsers.map((u) => ({
            __typename: "obs_notify_user",
            ...u,
          })),
          risk_level: selectedRiskLevel,
        },
      },
      update: (cache, { data }) => {
        const inserted = data?.insert_observation_notification_one;
        if (!inserted) throw new Error("Server returned null for inserted");
        cache.modify<GetObsNotificationsQuery>({
          fields: {
            observation_notification(existing = [], { toReference }) {
              const insertedRef = toReference(inserted);
              if (!insertedRef) return existing;
              return [insertedRef, ...existing];
            },
          },
        });
      },
    });
    onFinish();
  };
  const modalRef = useRef<FModalRef<FormVals>>();
  const [showUsersToSelect, setShowUsersToSelect] = useState(false);
  return (
    <FModal
      ref={modalRef}
      title={
        <div>
          <div className="text-1.25">Add New Risk-Level Distribution</div>
          <div className="text-grey">
            Send emails and text messages of all observations with a specific
            Risk to the following groups
          </div>
        </div>
      }
      confirmLoading={loading}
      form={{ layout: "horizontal" }}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onOk={async () => {
        const vals = await modalRef.current?.form
          .validateFields()
          .catch(() => null);
        if (!vals) return;
        console.log(vals);
        await insertNew(vals);
      }}
    >
      <FModal.Select
        name="risk_level"
        label="Select observation Risk"
        rules={[
          { required: true, message: "Select the risk level for notification" },
        ]}
      >
        {riskLevels.map((risk) => (
          <Select.Option
            value={risk.value}
            key={risk.value}
            style={{ color: risk.color_hex }}
          >
            {risk.name}
          </Select.Option>
        ))}
      </FModal.Select>
      {notifToggleKeys.map((key) => (
        <div key={key}>
          <Form.Item
            name={key}
            label={notifToggleLabels[key]}
            initialValue={false}
          >
            <Switch
              className="flex-end  float-right"
              onChange={
                key === "notify_selected_user"
                  ? (val) => setShowUsersToSelect(val)
                  : undefined
              }
            />
          </Form.Item>
          {showUsersToSelect && key === "notify_selected_user" ? (
            <Form.Item
              label="Select users to be notified"
              name="selectedUserIds"
              className="ml-1.5"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isOtherFieldTrue = getFieldValue(
                      "notify_selected_user",
                    ); // Replace 'otherFieldName' with the actual name of the other field
                    if (
                      isOtherFieldTrue &&
                      (!value || !Array.isArray(value) || value.length === 0)
                    ) {
                      return Promise.reject("Atleast 1 user is required");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select mode="multiple" options={selectableUsers} />
            </Form.Item>
          ) : null}
        </div>
      ))}
    </FModal>
  );
};
export default CreateNewObsNotifModal;
