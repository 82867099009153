/**
 * @generated SignedSource<<48662dfb789d8fdc5b97259922a9ebb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation$variables = {
  endDate?: string | null | undefined;
  projectId: string;
  startDate?: string | null | undefined;
};
export type GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation$data = {
  readonly update_project_by_pk: {
    readonly id: string;
    readonly pause_dash_notification_end_date: string | null | undefined;
    readonly pause_dash_notification_start_date: string | null | undefined;
  } | null | undefined;
};
export type GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation = {
  response: GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation$data;
  variables: GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "pause_dash_notification_end_date",
            "variableName": "endDate"
          },
          {
            "kind": "Variable",
            "name": "pause_dash_notification_start_date",
            "variableName": "startDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "project",
    "kind": "LinkedField",
    "name": "update_project_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pause_dash_notification_start_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pause_dash_notification_end_date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "20fb0e52f789b4bf3bc6c3ae1cd1638f",
    "id": null,
    "metadata": {},
    "name": "GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectSettingsOnsite_UpdatePauseDateNotification_Mutation(\n  $projectId: uuid!\n  $startDate: timestamptz\n  $endDate: timestamptz\n) {\n  update_project_by_pk(pk_columns: {id: $projectId}, _set: {pause_dash_notification_start_date: $startDate, pause_dash_notification_end_date: $endDate}) {\n    id\n    pause_dash_notification_start_date\n    pause_dash_notification_end_date\n  }\n}\n"
  }
};
})();

(node as any).hash = "18ff907ddd59c8a78ee7822ff714bad3";

export default node;
