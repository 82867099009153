/**
 * @generated SignedSource<<8dcb629a3a48a2c67831b0607d9f69f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentOnsiteSettingSubOffsiteMutation$variables = {
  closeDate: string;
  projectId: string;
  subId: string;
};
export type usePermanentOnsiteSettingSubOffsiteMutation$data = {
  readonly update_crew_onsite_period: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly project_crew_id: string;
    }>;
  } | null | undefined;
  readonly update_project_subcontractor_onsite_period: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type usePermanentOnsiteSettingSubOffsiteMutation = {
  response: usePermanentOnsiteSettingSubOffsiteMutation$data;
  variables: usePermanentOnsiteSettingSubOffsiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "closeDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subId"
},
v3 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "close_date",
      "variableName": "closeDate"
    }
  ],
  "kind": "ObjectValue",
  "name": "_set"
},
v4 = {
  "kind": "Literal",
  "name": "close_date",
  "value": {
    "_is_null": true
  }
},
v5 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "projectId"
    }
  ],
  "kind": "ObjectValue",
  "name": "project_id"
},
v6 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "subId"
    }
  ],
  "kind": "ObjectValue",
  "name": "subcontractor_id"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      (v3/*: any*/),
      {
        "fields": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_subcontractor_onsite_period_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_subcontractor_onsite_period",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project_subcontractor_onsite_period",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v3/*: any*/),
      {
        "fields": [
          (v4/*: any*/),
          {
            "fields": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "project_crew"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "crew_onsite_period_mutation_response",
    "kind": "LinkedField",
    "name": "update_crew_onsite_period",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "crew_onsite_period",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_crew_id",
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentOnsiteSettingSubOffsiteMutation",
    "selections": (v8/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "usePermanentOnsiteSettingSubOffsiteMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "0d2d3d4e97984b2e107e57aff492e38d",
    "id": null,
    "metadata": {},
    "name": "usePermanentOnsiteSettingSubOffsiteMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentOnsiteSettingSubOffsiteMutation(\n  $projectId: uuid!\n  $subId: uuid!\n  $closeDate: date!\n) {\n  update_project_subcontractor_onsite_period(where: {project_id: {_eq: $projectId}, subcontractor_id: {_eq: $subId}, close_date: {_is_null: true}}, _set: {close_date: $closeDate}) {\n    returning {\n      id\n    }\n  }\n  update_crew_onsite_period(where: {close_date: {_is_null: true}, project_crew: {project_id: {_eq: $projectId}, subcontractor_id: {_eq: $subId}}}, _set: {close_date: $closeDate}) {\n    returning {\n      project_crew_id\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "672b9b20590d1d2559e0338ebbf8819d";

export default node;
