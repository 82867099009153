import { Button, Form, Input, message, Select } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ConnectionHandler } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import { SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation } from "src/common/types/generated/relay/SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation.graphql";
import { SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation } from "src/common/types/generated/relay/SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation.graphql";
import { SiteOrientationSettingSwitchCards_Delete_Certificates_Mutation } from "src/common/types/generated/relay/SiteOrientationSettingSwitchCards_Delete_Certificates_Mutation.graphql";
import { SiteOrientationSettingSwitchCards_Delete_Users_To_Verify_New_Users_Mutation } from "src/common/types/generated/relay/SiteOrientationSettingSwitchCards_Delete_Users_To_Verify_New_Users_Mutation.graphql";
import { SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation } from "src/common/types/generated/relay/SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation.graphql";
import * as uuid from "uuid";
import { useGcOrientationDataQuery$data } from "src/common/types/generated/relay/useGcOrientationDataQuery.graphql";
import useUpdateOrientationProjectSettings, {
  useUpdateOrientationProjectSettingsMutationReturningValues,
} from "src/domain-features/siteorientation/utils/useUpdateOrientationProjectSettings";
import SiteOrientationSettingAlternateCerts from "./SiteOrientationSettingAlternateCerts";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import NotifyUserSettings from "./NotifyUserSettings";
import { project_set_input } from "src/common/types/generated/relay/useUpdateOrientationProjectSettingsMutation.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import SwitchWithText from "../../../../../../../common/components/SwitchWithText";
import DividerSplitWrapper from "../../../../../../../common/components/DividerSplitWrapper";

interface SwitchingProps {
  projectId: string;
  params: useUpdateOrientationProjectSettingsMutationReturningValues;
  userOptions: { label: string; value: string }[];
  currentlySelectedUsersToVerifyNewUsers: useGcOrientationDataQuery$data["user_to_verify_new_users_connection"]["edges"];
  allCertificates: useGcOrientationDataQuery$data["certification_connection"]["edges"];
  currentlySelectedCertificates: useGcOrientationDataQuery$data["project_connection"]["edges"][0]["node"]["project_certifications"];
  emailProjectUsers: useGcOrientationDataQuery$data["email_project_user_connection"]["edges"];
}

const mutationToAddUsers = graphql`
  mutation SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation(
    $objects: [user_to_verify_new_users_insert_input!]!
  ) {
    insert_user_to_verify_new_users(objects: $objects) {
      returning {
        user_id
        id
        user {
          name
        }
      }
    }
  }
`;
const mutationToRemoveUsers = graphql`
  mutation SiteOrientationSettingSwitchCards_Delete_Users_To_Verify_New_Users_Mutation(
    $projectId: uuid!
  ) {
    delete_user_to_verify_new_users(
      where: { project_id: { _eq: $projectId } }
    ) {
      returning {
        user_id
        id
        user {
          name
        }
      }
    }
  }
`;
const mutationToChangeUsersToNotifyForFailedQuiz = graphql`
  mutation SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation(
    $objects: [email_project_user_insert_input!]!
    $projectId: uuid!
    $toBeDeletedUserIds: [uuid!]!
  ) {
    delete_email_project_user(
      where: {
        project_id: { _eq: $projectId }
        type: { _eq: "quiz_failure" }
        user_id: { _in: $toBeDeletedUserIds }
      }
    ) {
      returning {
        user_id
        id
        user {
          name
        }
      }
    }
    insert_email_project_user(objects: $objects) {
      returning {
        user_id
        id
        user {
          name
        }
      }
    }
  }
`;
const mutationToAddCertificates = graphql`
  mutation SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation(
    $objects: [project_certification_insert_input!]!
  ) {
    insert_project_certification(objects: $objects) {
      returning {
        id
        certification {
          name
        }
        alternate_certifications {
          alternate_certification {
            name
          }
        }
      }
    }
  }
`;
const mutationToRemoveCertificates = graphql`
  mutation SiteOrientationSettingSwitchCards_Delete_Certificates_Mutation(
    $projectId: uuid!
  ) {
    delete_project_certification(where: { project_id: { _eq: $projectId } }) {
      returning {
        id
        certification {
          name
        }
      }
    }
  }
`;

const SiteOrientationSettingSwitchCards: FC<SwitchingProps> = ({
  params,
  projectId,
  userOptions,
  currentlySelectedUsersToVerifyNewUsers,

  allCertificates,
  currentlySelectedCertificates,
  emailProjectUsers,
}) => {
  const [updateProject, isUpdating] = useUpdateOrientationProjectSettings({
    projectId,
    returningFieldsVal: params,
  });
  const [addUsers] =
    useAsyncMutation<SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation>(
      mutationToAddUsers,
    );
  const [deleteUsers] =
    useAsyncMutation<SiteOrientationSettingSwitchCards_Delete_Users_To_Verify_New_Users_Mutation>(
      mutationToRemoveUsers,
    );
  const [addCertificates] =
    useAsyncMutation<SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation>(
      mutationToAddCertificates,
    );
  const [deleteCertificates] =
    useAsyncMutation<SiteOrientationSettingSwitchCards_Delete_Certificates_Mutation>(
      mutationToRemoveCertificates,
    );
  const quizFailureTypeName = "quiz_failure";
  const workerWithDTArrivalTypeName = "worker_with_drug_test_arrival";
  const [editUsers, setEditUsers] = useState(false);
  const [editCertificates, setEditCertificates] = useState(false);
  const { quizFailureUsers, workerWithDrugTextUser } = useMemo(
    () =>
      emailProjectUsers.reduce(
        ({ quizFailureUsers, workerWithDrugTextUser }, { node }, index) => {
          if (node.type === quizFailureTypeName)
            quizFailureUsers.push({ id: node.user_id, name: node.user.name });
          else if (node.type === workerWithDTArrivalTypeName)
            workerWithDrugTextUser.push({
              id: node.user_id,
              name: node.user.name,
            });
          return { quizFailureUsers, workerWithDrugTextUser };
        },
        {
          quizFailureUsers: [] as { id: string; name: string }[],
          workerWithDrugTextUser: [] as { id: string; name: string }[],
        },
      ),
    [emailProjectUsers, quizFailureTypeName, workerWithDTArrivalTypeName],
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editDays, setEditDays] = useState(false);
  const [editDTRestestDays, setEditDTRestestDays] = useState(false);
  const [validDays, setValidDays] = useState(params.drugtest_validity_days);
  const [dtRestestDays, setDtRestestDays] = useState(
    params.drug_test_retest_days,
  );
  const [editPercent, setEditPercent] = useState(false);
  const [validPercent, setValidPercent] = useState(
    params.required_quiz_percentage,
  );
  useEffect(() => {
    setValidDays(params.drugtest_validity_days);
    setDtRestestDays(params.drug_test_retest_days);
    setValidPercent(params.required_quiz_percentage);
  }, [params]);
  const updateProjectSetting = async (changes: project_set_input) => {
    try {
      await updateProject(changes);
      Object.entries(changes).forEach((e) => {
        e[1]
          ? typeof e[1] === "boolean"
            ? message.success("On")
            : message.success(`changed to ${e[1]} `)
          : message.warning("Off");
      });
    } catch (e) {
      setValidDays(params.drugtest_validity_days);
      setDtRestestDays(params.drug_test_retest_days);
      setValidPercent(params.required_quiz_percentage);
      const msg = e instanceof Error ? e.message : JSON.stringify(e);
      console.log("Settings change error:", e);
      message.error("Setting change error: " + msg);
    }
  };
  return (
    <DividerSplitWrapper>
      <SwitchWithText
        text="turn ON if you are providing a FORMAL in-person orientation that requires a signature"
        header="In-Person Orientation"
        subText="Note - this uses an additional QR Code"
        checked={params.in_person_orientation}
        onChange={async (checked) => {
          updateProjectSetting({ in_person_orientation: checked });
        }}
      />
      {params.in_person_orientation && (
        <SwitchWithText
          text="Use SiteForm’s interactive Orientation player to play your videos and slides"
          checked={params.orientation_tv_player}
          onChange={(checked) => {
            updateProjectSetting({ orientation_tv_player: checked });
          }}
        />
      )}
      <SwitchWithText
        text={
          "turn ON if you are using Hard Hat numbers on this project AND want to track them in SiteForm"
        }
        header="Hard Hat Numbers"
        checked={params.assign_hard_hat}
        onChange={(checked) => {
          updateProjectSetting({ assign_hard_hat: checked });
        }}
      />
      {params.assign_hard_hat && (
        <>
          <SwitchWithText
            text="Online Orientation: Workers enter their HH# on the last screen of Online Orientation"
            checked={params.add_hard_hat_during_registration}
            onChange={(checked) => {
              updateProjectSetting({
                add_hard_hat_during_registration: checked,
              });
            }}
          />
          <div className="ml-0.5 flex ">
            <div className="mt-0.75">In-Person Orientation:</div>
            <div className="">
              <SwitchWithText
                text="Turn ON if Workers enter their given HH# on the last screen of In-Person Orientation"
                checked={params.add_hard_hat_during_in_person}
                onChange={(checked) => {
                  updateProjectSetting({
                    add_hard_hat_during_in_person: checked,
                  });
                }}
              />
              <SwitchWithText
                text=" - or -  Turn ON if you want SiteForm to assign HH#s sequentially to workers as they complete In-Person Orientation"
                checked={params.automatically_assign_hard_hat}
                onChange={(checked) => {
                  updateProjectSetting({
                    automatically_assign_hard_hat: checked,
                  });
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* {params.in_person_orientation &&
        false && ( // temorary remove this option
          <SwitchWithText
            text="Require Login when new device is detected for in-personOrientation"
            checked={params.gc_orientation_login_required}
            onChange={async (checked) => {
              await updateProjectSetting({
                gc_orientation_login_required: checked,
              });
            }}
          />
        )} */}
      <SwitchWithText
        text="require workers to provide their phone number"
        header="Phone Number"
        checked={params.require_phone}
        onChange={(checked) => {
          updateProjectSetting({ require_phone: checked });
        }}
      />
      <SwitchWithText
        header="Date of Birth"
        text="require workers to provide their date of birth"
        checked={params.require_dob}
        onChange={(checked) => {
          updateProjectSetting({ require_dob: checked });
        }}
      />
      <SwitchWithText
        header="Training Certs"
        text="workers can take photos of their training and certification cards"
        checked={params.require_certification_upload}
        onChange={async (checked) => {
          updateProjectSetting({ require_certification_upload: checked });
        }}
      />
      <SwitchWithText
        header="Require a Cert"
        text="All workers are required to have a training or certification"
        checked={params.require_certs}
        onChange={async (checked) => {
          await updateProjectSetting({
            require_certs: checked,
          });
        }}
        explanation={
          <>
            Requiring a Cert/Training does not prevent workers from completing
            Orientation. If workers do not upload the required Certification or
            Training during orientation, SiteForm will send automatic
            communications to the worker, and their employer, requesting a copy
            of the cert. We will CC members of your Staff that are set to
            receive “Automatic Emails” related to Orientation below.
          </>
        }
      />
      {params.require_certs && (
        <div className="flex flex-col mb-1.5 mt-0.75">
          {editCertificates ? (
            <Form
              form={form}
              className="w-2/5"
              initialValues={{
                selectedCertificates: currentlySelectedCertificates.map(
                  (u) => u.certification.pk,
                ),
              }}
            >
              <Form.Item name="selectedCertificates">
                <Select
                  mode="multiple"
                  disabled={loading}
                  showSearch
                  filterOption={getNormalSelectOptionsFilter}
                  options={allCertificates.map((u) => ({
                    label: u.node.name,
                    value: u.node.pk,
                  }))}
                />
              </Form.Item>
            </Form>
          ) : (
            <div>
              {currentlySelectedCertificates.map((cert) => (
                <SiteOrientationSettingAlternateCerts
                  projectId={projectId}
                  certification={cert}
                  allCertificates={allCertificates}
                />
              ))}
            </div>
          )}
          <div className="my-0.5">
            <Button
              className="ml-1 rounded-2"
              size="small"
              loading={loading}
              onClick={async () => {
                if (editCertificates) {
                  const newVals = form.getFieldValue("selectedCertificates");
                  setLoading(true);
                  await deleteCertificates({
                    variables: { projectId },
                    updater: (store) => {
                      const conn = ConnectionHandler.getConnection(
                        store.getRoot(),
                        "useGcOrientationDataQuery_project_connection",
                      );
                      if (conn) {
                        const edges = conn.getLinkedRecords("edges");
                        if (edges?.length) {
                          const node = edges[0].getLinkedRecord("node");
                          if (node) {
                            node?.setLinkedRecords(
                              [],
                              "project_certifications",
                            );
                          }
                        }
                      }
                    },
                  });
                  await addCertificates({
                    variables: {
                      objects: newVals.map((certification_id: string) => ({
                        certification_id: certification_id,
                        project_id: projectId,
                      })),
                    },
                    updater: (store) => {
                      const newVals = store.getRootField(
                        "insert_project_certification",
                      );
                      const conn = ConnectionHandler.getConnection(
                        store.getRoot(),
                        "useGcOrientationDataQuery_project_connection",
                      );
                      if (conn && newVals) {
                        const edges = conn.getLinkedRecords("edges");
                        if (edges?.length) {
                          const node = edges[0].getLinkedRecord("node");
                          if (node) {
                            node?.setLinkedRecords(
                              newVals.getLinkedRecords("returning"),
                              "project_certifications",
                            );
                          }
                        }
                      }
                    },
                  }).catch(console.log);
                  setLoading(false);
                  setEditCertificates(false);
                } else {
                  setEditCertificates(true);
                }
              }}
            >
              {editCertificates
                ? "Done"
                : currentlySelectedCertificates &&
                  currentlySelectedCertificates.length > 0
                ? " add or edit a required cert/training"
                : "select a required cert/training"}
            </Button>
            {editCertificates && (
              <Button
                loading={loading}
                className="ml-1 rounded-2"
                size="small"
                onClick={() => setEditCertificates(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      )}
      <SwitchWithText
        header="Profile Photo"
        text="workers have the option to add a profile photo (think selfie)"
        subText="turning this setting ON will require each worker to provide a Profile Photo"
        checked={params.require_profile_photo}
        onChange={async (checked) => {
          updateProjectSetting({ require_profile_photo: checked });
        }}
      />
      <SwitchWithText
        text="this project will mainly use Union labor"
        header="Union"
        checked={params.union_labor}
        onChange={(checked) => {
          updateProjectSetting({ union_labor: checked });
        }}
      />
      <SwitchWithText
        header="Quiz"
        text="require workers to pass a quiz"
        checked={!!params.required_quiz_percentage}
        onChange={async (checked) => {
          if (checked)
            updateProjectSetting({
              required_quiz_percentage: 70,
            });
          else
            updateProjectSetting({
              required_quiz_percentage: 0,
            });
        }}
      />
      {params.required_quiz_percentage > 0 && (
        <div className="mb-1 mt-0.75">
          <>
            <span>Lowest allowable score to pass quiz:</span>
            <Input
              type={"number"}
              className="w-5"
              disabled={!editPercent}
              max={100}
              placeholder=" Enter %"
              value={validPercent ?? undefined}
              onChange={(e) =>
                setValidPercent(e.target.value as unknown as number)
              }
            />
            %{" "}
            <Button
              type="primary"
              size="small"
              className="rounded-1"
              onClick={() => {
                if (editPercent) {
                  updateProjectSetting({
                    required_quiz_percentage: validPercent,
                  });
                }
                setEditPercent(!editPercent);
              }}
            >
              {editPercent ? "Done" : "Edit"}
            </Button>
          </>
          <NotifyUserSettings
            title="Members of your team that are to be notified if a worker scores below the desired %"
            type={quizFailureTypeName}
            projectId={projectId}
            currentSelectedUsers={quizFailureUsers}
            userOptions={userOptions}
          />
        </div>
      )}
      <div className={`flex flex-col gap-1 items-start`}>
        <SwitchWithText
          header="New Ops Staff"
          text="an email will be sent to your entire team when a new employee of your company completes orientation. Turn OFF to select who to send to "
          checked={params.send_verification_invite}
          onChange={async (checked) => {
            await updateProjectSetting({
              send_verification_invite: checked,
            });
          }}
          explanation={
            <>
              We protect who can access your project information. Only people
              <br />
              invited to SiteForm can log in. We automate this step by sending
              you
              <br />
              an email to verify when someone from your team completes
              <br />
              orientation.
            </>
          }
        />
        {!params.send_verification_invite && (
          <>
            {editUsers ? (
              <Form
                form={form}
                className="w-2/5"
                initialValues={{
                  selectedGCs: currentlySelectedUsersToVerifyNewUsers.map(
                    (u) => u.node.user_id,
                  ),
                }}
              >
                <Form.Item name="selectedGCs">
                  <Select
                    mode="multiple"
                    disabled={loading}
                    options={userOptions}
                  />
                </Form.Item>
              </Form>
            ) : (
              <div>
                {currentlySelectedUsersToVerifyNewUsers.map((u) => (
                  <span
                    className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-interactive-light text-interactive-primary"
                    key={u.node.user_id}
                  >
                    {u.node.user.name}
                  </span>
                ))}
              </div>
            )}
            <Button
              className="ml-1 rounded-2"
              size="small"
              type="primary"
              loading={loading}
              onClick={async () => {
                if (editUsers) {
                  const newVals = form.getFieldValue("selectedGCs");
                  setLoading(true);
                  await deleteUsers({
                    variables: { projectId },
                    updater: (store) => {
                      const deleteEdges = store.getRootField(
                        "delete_user_to_verify_new_users",
                      );
                      const conn = ConnectionHandler.getConnection(
                        store.getRoot(),
                        "useGcOrientationDataQuery_user_to_verify_new_users_connection",
                      );
                      if (conn) {
                        deleteEdges
                          .getLinkedRecords("returning")
                          .forEach((s) => {
                            ConnectionHandler.deleteNode(conn, s.getDataID());
                          });
                      }
                    },
                  });
                  await addUsers({
                    variables: {
                      objects: newVals.map((u: string) => ({
                        user_id: u,
                        project_id: projectId,
                      })),
                    },
                    updater: (store) => {
                      const newVals = store.getRootField(
                        "insert_user_to_verify_new_users",
                      );
                      const conn = ConnectionHandler.getConnection(
                        store.getRoot(),
                        "useGcOrientationDataQuery_user_to_verify_new_users_connection",
                      );
                      if (conn && newVals) {
                        newVals.getLinkedRecords("returning").forEach((val) => {
                          const edge = store.create(uuid.v4(), "edge");
                          edge.setLinkedRecord(val, "node");
                          ConnectionHandler.insertEdgeAfter(conn, edge);
                        });
                      }
                    },
                  }).catch(console.log);
                  setLoading(false);
                  setEditUsers(false);
                } else {
                  setEditUsers(true);
                }
              }}
            >
              {editUsers ? "Done" : "Edit Users"}
            </Button>
            {editUsers && (
              <Button
                loading={loading}
                className="ml-1 rounded-2"
                size="small"
                onClick={() => setEditUsers(false)}
              >
                Cancel
              </Button>
            )}
          </>
        )}
        <SwitchWithText
          header="Track Onsite Workers"
          text="mark workers who are onsite but have not completed Orientation "
          subText="(only applies if using other SiteForm modules such as Daily Reporting, Hot Work Permits, TBTs, PTPs, etc.)"
          checked={params.show_onsite_for_not_oriented_workers}
          onChange={async (checked) => {
            updateProjectSetting({
              show_onsite_for_not_oriented_workers: checked,
            });
          }}
        />
        <SwitchWithText
          header="Background Check"
          text="track background checks for this Pproject"
          checked={params.background_check_tracker}
          onChange={async (checked) => {
            updateProjectSetting({
              background_check_tracker: checked,
            });
          }}
        />
        <SwitchWithText
          header={"Drug Test"}
          text={
            <div className="flex">performing drug testing on this project</div>
          }
          checked={params.perform_drugtest}
          onChange={async (checked) => {
            updateProjectSetting({ perform_drugtest: checked });
          }}
        />
        {params.perform_drugtest && (
          <div className="ml-0.5">
            <div>
              ({" "}
              {params.require_drugtest_upload
                ? "This project allows workers to upload images of their Drug Test during Orientation, if you want to turn this OFF, please contact your SiteForm Admin"
                : "This project does not allows workers to upload images of their Drug Test during Orientation, if you want to turn this ON, please contact your SiteForm Admin"}{" "}
              )
            </div>
            <div>
              <span className="mr-1.5">
                Select how long the Drug Test is good for (# of days)
              </span>
              <Input
                type={"number"}
                className="w-6"
                disabled={!editDays}
                placeholder=" Enter # of days"
                value={validDays ?? undefined}
                onChange={(e) =>
                  setValidDays(e.target.value as unknown as number)
                }
              />{" "}
              days{" "}
              <Button
                type="primary"
                size="small"
                className="rounded-1 ml-23"
                onClick={() => {
                  if (editDays) {
                    updateProjectSetting({
                      drugtest_validity_days: validDays,
                    });
                  }
                  setEditDays((i) => !i);
                }}
              >
                {editDays ? "Done" : "Edit"}
              </Button>
            </div>
            <div>
              <span className="mr-1.5">
                Select how long until a worker that tests Positive can be
                re-tested{" "}
              </span>
              <Input
                type={"number"}
                className="w-6"
                disabled={!editDTRestestDays}
                bordered={false}
                placeholder=" Enter # of days"
                value={dtRestestDays ?? undefined}
                onChange={(e) =>
                  setDtRestestDays(e.target.value as unknown as number)
                }
              />
              days{" "}
              <Button
                type="primary"
                size="small"
                className="rounded-1 ml-23"
                onClick={() => {
                  if (editDTRestestDays) {
                    updateProjectSetting({
                      drug_test_retest_days: dtRestestDays,
                    });
                  }
                  setEditDTRestestDays((i) => !i);
                }}
              >
                {editDTRestestDays ? "Done" : "Edit"}
              </Button>
              <div className="-mt-1">
                <SwitchWithText
                  text="Send an email if a worker arrives with a valid Negative or a Positive test from another project"
                  checked={params.send_email_for_drug_test_worker_arrival}
                  onChange={async (checked) => {
                    updateProjectSetting({
                      send_email_for_drug_test_worker_arrival: checked,
                    });
                  }}
                />
                <div className="mt-0.25"></div>
                {params.send_email_for_drug_test_worker_arrival && (
                  <NotifyUserSettings
                    title={"Select members of your team that will be notified"}
                    type={workerWithDTArrivalTypeName}
                    projectId={projectId}
                    currentSelectedUsers={workerWithDrugTextUser}
                    userOptions={userOptions}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </DividerSplitWrapper>
  );
};
export default SiteOrientationSettingSwitchCards;
