import { IncidentSeverityMappingProps } from "../IncidentSeverityMapping";

const validLevels = ["business_unit", "division", "office"];

const getIncidentSeverityProps = (
  gcId: string,
  orgUnit?: { level: string; id: string },
): IncidentSeverityMappingProps => {
  if (!orgUnit) {
    return {
      severityUserFilter: {
        gc_id: { _eq: gcId },
        user: { employee: { is_corporate_admin: { _eq: true } } },
      },
      toInsertWith: { gc_id: gcId },
      gcEmployeeWhere: {
        is_corporate_admin: { _eq: true },
        general_contractor_id: { _eq: gcId },
      },
    };
  }

  const { level, id: orgUnitId } = orgUnit;
  if (!validLevels.includes(level)) {
    throw new Error("passed level is not a valid org unit level");
  }
  return {
    severityUserFilter: { [`${level}_id`]: { _eq: orgUnitId } },
    toInsertWith: { gc_id: gcId, [`${level}_id`]: orgUnitId },
    gcEmployeeWhere: {
      [`gc_${level}s`]: { [`gc_${level}_id`]: { _eq: orgUnitId } },
    },
  } as IncidentSeverityMappingProps;
};
export default getIncidentSeverityProps;
