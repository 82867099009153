import React, { useEffect, useMemo, useRef, useState } from "react";
import { InspectionInstanceType } from "../tables/InspectionInstanceTable";
import { Drawer, Form, message, notification, Button } from "antd";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import Tag from "src/common/components/general/Tag";
import {
  InspectionChecklistItemProps,
  InspectionInsertValues,
  NotifyUserType,
  OptionType,
} from "../../utils/siteInspectionTypes";
import AddInspectionChecklistItemModal from "../modals/AddInspectionChecklistItemModal";
import { generalChecklistType } from "../../utils/generalChecklistType";
import noop from "src/common/functions/noop";
import { reorderArrayInt } from "src/common/functions/reorderRecords";
import InspectionChecklistItemTypeTable from "../tables/InspectionChecklistItemTypeTable";
import * as uuid from "uuid";
import { IconPencil } from "@tabler/icons";
import EditInspectionFields from "../EditInspectionFields";
import { InspectionInstanceMutation } from "src/common/types/generated/relay/InspectionInstanceMutation.graphql";
import {
  inspection_instance_qr_code_insert_input,
  inspection_template_insert_input,
  inspection_checklist_item_type_insert_input,
  inspection_instance_checklist_item_insert_input,
} from "src/common/types/generated/relay/types";

import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import dayjs from "dayjs";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import { getCommomChecklistItemObject } from "../../utils/getCommonChecklistItemObject";
import { auth } from "src/common/functions/firebase";
import capitalize from "src/common/functions/capitalize";
import { ConnectionHandler } from "relay-runtime";
import newQRCodeInsertionUpdater from "../../utils/newQRCodeInsertionUpdater";
import getToInsertTemplateObjectFromInstance from "../../utils/getToInsertTemplateObjectFromInstance";
import InspectionInstanceDropdown from "../action-buttons/InspectionInstanceDropdown";
import { EditOutlined } from "@ant-design/icons";

interface InspectionInstanceProps {
  data: InspectionInstanceType;
  visible: boolean;
  projectId: string;
  generalContractorId?: string;
  inspectionQROptions: Array<OptionType>;
  projectEmployees: Array<NotifyUserType>;
  labelOptions: Array<OptionType>;
  onClose: () => void;
  onOpenNew: (data: InspectionInstanceType) => void;
}

const editInspectionMutation = graphql`
  mutation InspectionInstanceMutation(
    $inspectionNameId: uuid!
    $newName: String!
    $now: timestamptz!
    $inspectionInstanceId: uuid!
    $templateObjects: [inspection_template_insert_input!]!
    $toInsertInspectionChecklistItemTypes: [inspection_checklist_item_type_insert_input!]!
    $instanceSet: inspection_instance_set_input!
    $toDeleteLabels: [uuid!]!
    $addLabelObjects: [inspection_label_insert_input!]!
    $toDeleteInstanceQRs: [uuid!]!
    $addInstanceQRObjects: [inspection_instance_qr_code_insert_input!]!
    $toDeleteChecklistItems: [uuid!]!
    $toInsertChecklistItemObjects: [inspection_instance_checklist_item_insert_input!]!
  ) {
    update_text_translation_by_pk(
      pk_columns: { id: $inspectionNameId }
      _set: { original: $newName, en: $newName }
    ) {
      en
      id
    }

    insert_inspection_label(objects: $addLabelObjects) {
      returning {
        id
        label_id
      }
    }
    delete_inspection_label(
      where: {
        label_id: { _in: $toDeleteLabels }
        inspection_instance_id: { _eq: $inspectionInstanceId }
      }
    ) {
      returning {
        id
      }
    }
    insert_inspection_instance_qr_code(objects: $addInstanceQRObjects) {
      returning {
        id
        inspection_qr_code_id
        inspection_qr_code {
          name
        }
      }
    }
    delete_inspection_instance_qr_code(
      where: {
        inspection_qr_code_id: { _in: $toDeleteInstanceQRs }
        inspection_instance_id: { _eq: $inspectionInstanceId }
      }
    ) {
      returning {
        id
      }
    }
    insert_inspection_checklist_item_type(
      objects: $toInsertInspectionChecklistItemTypes
    ) {
      returning {
        id
        text {
          en
        }
      }
    }
    update_inspection_instance_checklist_item(
      where: { id: { _in: $toDeleteChecklistItems } }
      _set: { deleted_at: $now }
    ) {
      returning {
        id
        deleted_at
      }
    }
    insert_inspection_instance_checklist_item(
      objects: $toInsertChecklistItemObjects
    ) {
      returning {
        ...InspectionInstanceChecklistItemFrag @relay(mask: false)
      }
    }
    insert_inspection_template(objects: $templateObjects) {
      affected_rows
    }
    update_inspection_instance_by_pk(
      pk_columns: { id: $inspectionInstanceId }
      _set: $instanceSet
    ) {
      ...InspectionInstanceFrag @relay(mask: false)
    }
  }
`;
export const setInstanceChecklistItems = (data: InspectionInstanceType) => {
  const returnObj: {
    [type: string]: { [id: string]: InspectionChecklistItemProps };
  } = {};
  data.inspection_instance_checklist_items.forEach((cli) => {
    const typeKey = cli.inspection_checklist_item_type
      ? cli.inspection_checklist_item_type.text.en
      : generalChecklistType;
    const obj: InspectionChecklistItemProps = {
      id: cli.pk,
      item_id: cli.item_id,
      sort_index: cli.sort_index,
      template_item_id: cli.inspection_template_checklist_item_id,
      description: cli.description.en,
      correct_answer: cli.correct_answer === "yes" ? "yes" : "no",
      notify_on_deficient: cli.notify_on_deficient,
      item_type_label: cli.inspection_checklist_item_type?.text?.en,
      type: typeKey,
      show_na: cli.show_na,
      notifyees: cli.inspection_checklist_emails.map((p) => p.user_id),
      require_photo: cli.require_photo,
    };

    returnObj[typeKey] = {
      ...(returnObj[typeKey] ?? {}),
      [cli.pk]: obj,
    };
  });
  return returnObj;
};
const InspectionInstance: React.FC<InspectionInstanceProps> = ({
  data,
  projectEmployees,
  visible,
  projectId,
  onClose,
  onOpenNew,
  inspectionQROptions,
  labelOptions,
  generalContractorId,
}) => {
  const [currentChecklistItems, setCurrentChecklistItems] = useState<{
    [type: string]: { [id: string]: InspectionChecklistItemProps };
  }>({});
  const initialChecklistItems = useMemo(
    () => setInstanceChecklistItems(data),
    [data],
  );
  const [newChecklistItem, setNewChecklistItem] = useState(false);
  const [editInspection] = useAsyncMutation<InspectionInstanceMutation>(
    editInspectionMutation,
  );

  useEffect(() => {
    setCurrentChecklistItems(setInstanceChecklistItems(data));
  }, [data]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  // const modal = useRef<FModalRef<InspectionInsertValues>>(null);
  const [form] = Form.useForm<InspectionInsertValues>();
  const [allowChecklistItemPhoto, setAllowChecklistItemPhoto] = useState(
    data.allow_photo_to_checklist_item,
  );
  useEffect(() => {
    setAllowChecklistItemPhoto(data.allow_photo_to_checklist_item);
  }, [editing, data]);
  const onSave = async () => {
    if (editing) {
      setLoading(true);
      try {
        const values = await form.validateFields();
        if (!values?.name) {
          throw new Error("name cannot be empty");
        }
        if (values.model_number && values.model_number_required)
          throw new Error(
            "You can only either enter a defauly model number or require inspector to enter,not both, please try again",
          );
        const checklistItemTypeToIdMap: { [key: string]: string } = {};
        data.inspection_instance_checklist_items.forEach((item) => {
          if (item.inspection_checklist_item_type)
            checklistItemTypeToIdMap[
              item.inspection_checklist_item_type.text.en
            ] = item.inspection_checklist_item_type.pk;
        });
        const [toInsertQRs, toDeleteQRs] = compareTwoLists(
          values.inspection_qr_codes ?? [],
          data.inspection_instance_qr_codes.map((p) => p.inspection_qr_code_id),
        );
        const [toInsertLabels, toDeleteLabels] = compareTwoLists(
          values.labels ?? [],
          data.inspection_labels.map((p) => p.label_id),
        );
        const addInstanceQRObjects: Array<inspection_instance_qr_code_insert_input> =
          toInsertQRs.map((qrId) => ({
            inspection_qr_code_id: qrId,
            inspection_instance_id: data.pk,
          }));
        if (values.new_inspection_qr_code) {
          addInstanceQRObjects.push({
            inspection_instance_id: data.pk,

            inspection_qr_code: {
              data: {
                name: values.new_inspection_qr_code,
                project_id: projectId,
                created_by_uid: auth.currentUser?.uid,
              },
            },
          });
        }
        const toInsertChecklistItemObjects: Array<inspection_instance_checklist_item_insert_input> =
          [];
        const toDeleteChecklistItems: Array<string> = [];
        const typeMap: { [key: string]: string } = {};
        if (
          JSON.stringify(currentChecklistItems) !==
          JSON.stringify(initialChecklistItems)
        ) {
          Object.entries(currentChecklistItems).forEach(
            ([type, checklistItems]) => {
              if (type !== generalChecklistType && !typeMap[type]) {
                typeMap[type] = checklistItemTypeToIdMap[type] ?? uuid.v4();
              }
              const checklistItemTypeId =
                type !== generalChecklistType ? typeMap[type] : null;
              const toInsert: (
                checklistItem: InspectionChecklistItemProps,
              ) => inspection_instance_checklist_item_insert_input = (
                checklistItem,
              ) => ({
                ...getCommomChecklistItemObject(checklistItem),
                item_id: checklistItem.item_id ?? uuid.v4(),
                inspection_instance_id: data.pk,
                ...(checklistItemTypeId
                  ? {
                      inspection_checklist_item_type_id: checklistItemTypeId,
                    }
                  : {}),
              });
              if (!initialChecklistItems[type]) {
                Object.values(checklistItems).forEach((checklistItem) => {
                  toInsertChecklistItemObjects.push(toInsert(checklistItem));
                });
              } else {
                Object.entries(checklistItems).forEach(
                  ([id, currentChecklistItem]) => {
                    const initialChecklistItem =
                      initialChecklistItems[type][id];
                    if (!initialChecklistItem?.id) {
                      toInsertChecklistItemObjects.push(
                        toInsert(currentChecklistItem),
                      );
                    } else {
                      if (
                        initialChecklistItem.sort_index !==
                          currentChecklistItem.sort_index ||
                        initialChecklistItem.description !==
                          currentChecklistItem.description ||
                        initialChecklistItem.correct_answer !==
                          currentChecklistItem.correct_answer ||
                        initialChecklistItem.require_photo !==
                          currentChecklistItem.require_photo ||
                        initialChecklistItem.notify_on_deficient !==
                          currentChecklistItem.notify_on_deficient ||
                        initialChecklistItem.show_na !==
                          currentChecklistItem.show_na ||
                        (initialChecklistItem.notifyees || [])
                          .sort((a, b) => a.localeCompare(b))
                          .join(", ") !==
                          (currentChecklistItem.notifyees || [])
                            .sort((a, b) => a.localeCompare(b))
                            .join(", ")
                      ) {
                        toInsertChecklistItemObjects.push(
                          toInsert(currentChecklistItem),
                        );
                        toDeleteChecklistItems.push(initialChecklistItem.id);
                      }
                    }
                  },
                );
              }
            },
          );
          Object.entries(initialChecklistItems).forEach(
            ([type, checklistItems]) => {
              if (!currentChecklistItems[type]) {
                Object.keys(checklistItems).forEach((id) =>
                  toDeleteChecklistItems.push(id),
                );
              } else {
                Object.keys(checklistItems).forEach((id) => {
                  if (!currentChecklistItems[type][id]) {
                    toDeleteChecklistItems.push(id);
                  }
                });
              }
            },
          );
        }
        const toInsertInspectionChecklistItemTypes = Object.entries(typeMap)
          .filter(([type, id]) => !checklistItemTypeToIdMap[type])
          .map(
            ([type, id]): inspection_checklist_item_type_insert_input => ({
              id,
              text: {
                data: {
                  en: capitalize(type),
                  original: capitalize(type),
                },
              },
            }),
          );
        let newTemplateId = uuid.v4();
        let newTemplateObj: inspection_template_insert_input | undefined =
          undefined;
        if (values.community || values.company) {
          newTemplateObj = {
            ...getToInsertTemplateObjectFromInstance(
              values,
              currentChecklistItems,
            ),
            id: newTemplateId,
          };
        }
        const setModelNubmer = values.model_number_required
          ? null
          : values.model_number && values.model_number.trim()
          ? values.model_number.trim()
          : null;
        const setModelNubmerReq = values.is_multi_inspection
          ? true
          : values.model_number_required;
        const inspSet = {
          model_number: setModelNubmer,
          model_number_required: setModelNubmerReq,
          is_multi_inspection: values.is_multi_inspection,
          allow_photo_to_checklist_item: values.allow_photo_to_checklist_item,
          image_required: values.image_required,
        };
        await editInspection({
          // console.log({
          variables: {
            templateObjects: newTemplateObj
              ? values.community && values.company
                ? [
                    {
                      ...newTemplateObj,
                      general_contractor_id: generalContractorId,
                    },
                    {
                      ...getToInsertTemplateObjectFromInstance(
                        values,
                        currentChecklistItems,
                      ),
                      id: uuid.v4(),
                    },
                  ]
                : values.community
                ? [newTemplateObj]
                : values.company
                ? [
                    {
                      ...newTemplateObj,
                      general_contractor_id: generalContractorId,
                    },
                  ]
                : []
              : [],
            inspectionInstanceId: data.pk,
            addInstanceQRObjects,
            addLabelObjects: toInsertLabels.map((labelId) => ({
              inspection_instance_id: data.pk,
              label_id: labelId,
            })),
            toDeleteLabels,
            toInsertInspectionChecklistItemTypes,
            toDeleteInstanceQRs: toDeleteQRs,
            instanceSet: {
              ...inspSet,
              ...(newTemplateObj
                ? {
                    inspection_template_id:
                      values.company &&
                      !data.inspection_template?.general_contractor_id
                        ? newTemplateId
                        : data.inspection_template_id || newTemplateId,
                  }
                : {}),
            },
            newName: values.name,
            inspectionNameId: data.name_id,
            now: dayjs().toISOString(),
            toDeleteChecklistItems,
            toInsertChecklistItemObjects,
          },
          updater: (store) => {
            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "InspectionInstanceTable_inspection_instance_connection",
            );
            const updatedInsp = store.getRootField(
              "update_inspection_instance_by_pk",
            );
            if (conn) {
              const edges = conn.getLinkedRecords("edges");
              if (values.new_inspection_qr_code) {
                newQRCodeInsertionUpdater(
                  store,
                  inspectionQROptions,
                  updatedInsp.getLinkedRecords("inspection_instance_qr_codes", {
                    where: {
                      inspection_qr_code: { deleted_at: { _is_null: true } },
                    },
                  }),
                );
              }
              if (edges) {
                const edgeIndex = edges.findIndex((edge) => {
                  const node = edge.getLinkedRecord("node");
                  return node?.getValue("id") === data.id;
                });
                const edge = store.create(uuid.v4(), "edge");
                edge.setLinkedRecord(updatedInsp, "node");
                edges[edgeIndex] = edge;
                conn.setLinkedRecords(edges, "edges");
              }
            }
          },
        });
        setEditing(false);
        onClose();
        message.success("Successfully Edited Inspection");
      } catch (err) {
        console.error(err);
        notification.error({
          description: "Inspection could not be edited",
          message: err instanceof Error ? err.message : JSON.stringify(err),
        });
      }
      setLoading(false);
    }
  };

  return (
    <Drawer
      width={"85%"}
      title={editing ? "Create & Edit Inspection" : "Inspection Details"}
      open={visible}
      onClose={() => {
        if (editing) {
          setEditing(false);
        } else onClose();
      }}
      extra={
        editing ? undefined : (
          <div className="flex justify-end gap-0.5">
            <Button
              type="primary"
              loading={loading}
              icon={<EditOutlined />}
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
            <InspectionInstanceDropdown {...{ data, onOpenNew, projectId }} />
          </div>
        )
      }
      footer={
        <div className="flex gap-1">
          <Button
            loading={loading}
            onClick={() => {
              if (editing) {
                setEditing(false);
              } else onClose();
            }}
          >
            {editing ? "Cancel" : "Close"}
          </Button>
          {editing && (
            <Button type="primary" onClick={onSave} loading={loading}>
              Save
            </Button>
          )}
        </div>
      }
    >
      <Form form={form}>
        {editing ? (
          <EditInspectionFields
            showAddToCommunity={
              !!(
                !data.inspection_template ||
                data.inspection_template.general_contractor_id
              )
            }
            showAddToCompany={
              !data.inspection_template ||
              !data.inspection_template.general_contractor_id
            }
            form={form}
            labelOptions={labelOptions}
            inspectionQROptions={inspectionQROptions}
            onAllowItemPhotoChange={(checked) =>
              setAllowChecklistItemPhoto(checked)
            }
            initialValues={{
              name: data.name.en,
              is_multi_inspection: data.is_multi_inspection,
              allow_photo_to_checklist_item: data.allow_photo_to_checklist_item,
              image_required: data.image_required,
              model_number: data.model_number ?? undefined,
              model_number_required: data.model_number_required,
              inspection_qr_codes: data.inspection_instance_qr_codes.map(
                (p) => p.inspection_qr_code_id,
              ),
              labels: data.inspection_labels.map((p) => p.label_id),
            }}
          />
        ) : (
          <>
            <div className="flex mt-1">
              <span className="font-accent">Inspection: &nbsp;&nbsp;</span>
              {data.name.en}
            </div>
            {data.inspection_instance_qr_codes.length > 0 && (
              <div className="mt-1">
                <span className="font-accent">Added to QRs</span>:
                <div className="flex flex-wrap gap-0.5 ml-1">
                  {data.inspection_instance_qr_codes.map((p) => (
                    <Tag key={p.inspection_qr_code_id} status="pending">
                      {p.inspection_qr_code.name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {data.model_number && (
              <div className="flex mt-1">
                <span className="font-accent">Model Number: &nbsp;&nbsp;</span>
                {data.model_number}
              </div>
            )}

            <div className="mt-1">
              Multi-Inspection: {data.is_multi_inspection ? "ON" : "OFF"}
            </div>

            {!data.is_multi_inspection && (
              <div className="mt-1">
                Require Model Number:{" "}
                {data.model_number_required ? "ON" : "OFF"}
              </div>
            )}
            <div className="mt-1">
              Require to Add Image(s): {data.image_required ? "ON" : "OFF"}
            </div>
            <div className="mt-1">
              Allow adding photos to checklist items:{" "}
              {data.allow_photo_to_checklist_item ? "ON" : "OFF"}
            </div>
            {data.inspection_labels.length > 0 && (
              <div className="mt-1">
                Inspection Labels:
                <div className="flex flex-wrap gap-0.5 ml-1">
                  {data.inspection_labels.map((p) => (
                    <Tag key={p.label_id} status="pending">
                      {p.label.label_name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        <div className="font-accent my-1">
          Checklist Items ({data.inspection_instance_checklist_items.length})
        </div>
        {Object.entries(currentChecklistItems).map(
          ([type, checklistItemTypeObject]) => {
            return (
              <div key={type}>
                <InspectionChecklistItemTypeTable
                  allowChecklistItemPhoto={allowChecklistItemPhoto}
                  dataSource={Object.values(checklistItemTypeObject)}
                  type={type}
                  projectEmployees={projectEmployees}
                  editing={editing}
                  onDeleteItem={(item) => {
                    item.id
                      ? setCurrentChecklistItems((prev) => {
                          const newObj = { ...prev };
                          if (Object.entries(newObj[type]).length <= 1)
                            delete newObj[type];
                          else {
                            delete newObj[type][item.id!];
                          }
                          return newObj;
                        })
                      : noop();
                  }}
                  onEditOrEditDoneItem={(newItem, prevItem) => {
                    if (newItem?.id === prevItem.id) {
                      setCurrentChecklistItems((prev) => ({
                        ...prev,
                        [type]: {
                          ...prev[type],
                          [prevItem.id!]: { ...newItem },
                        },
                      }));
                    }
                  }}
                  onAddNewItem={(item, type) => {
                    const checklistItemId = uuid.v4();
                    setCurrentChecklistItems((prev) => ({
                      ...prev,
                      [type]: {
                        ...(prev[type] ?? {}),
                        [checklistItemId]: {
                          ...item,
                          item_type_label:
                            type !== generalChecklistType ? type : undefined,
                          id: checklistItemId,
                          sort_index: Object.keys(prev[type] ?? {}).length + 1,
                        },
                      },
                    }));
                  }}
                  onMoveRow={(dragIndex, hoverIndex) =>
                    setCurrentChecklistItems((prev) => {
                      const list = Object.values(prev[type]);
                      const newList =
                        reorderArrayInt<InspectionChecklistItemProps>(
                          list,
                          dragIndex,
                          hoverIndex,
                        );
                      return {
                        ...prev,
                        [type]: Object.fromEntries(
                          newList.map((val) => [val.id, val]),
                        ),
                      };
                    })
                  }
                />
              </div>
            );
          },
        )}
        <AddInspectionChecklistItemModal
          projectEmployees={projectEmployees}
          allowChecklistItemPhoto={allowChecklistItemPhoto}
          checklistItemTypes={Object.keys(currentChecklistItems).filter(
            (p) => p != generalChecklistType,
          )}
          visible={newChecklistItem}
          onCancel={() => setNewChecklistItem(false)}
          onCreate={(values, creatingAnother) => {
            const typeKey = values.item_type_label
              ? values.item_type_label
              : generalChecklistType;
            const checklistItemId = uuid.v4();
            setCurrentChecklistItems((prev) => ({
              ...prev,
              [typeKey]: {
                ...(prev[typeKey] ?? {}),
                [checklistItemId]: {
                  ...values,
                  id: checklistItemId,
                  template_item_id: uuid.v4(),
                  sort_index: Object.keys(prev[typeKey] ?? {}).length + 1,
                },
              },
            }));
            if (!creatingAnother) {
              setNewChecklistItem(false);
            }
          }}
        />
        {editing && (
          <Button
            className="mt-1"
            type="primary"
            onClick={() => setNewChecklistItem(true)}
          >
            Add checklist items
          </Button>
        )}
      </Form>
    </Drawer>
  );
};
export default InspectionInstance;
