import AppPageLayout from "../../../../../../../../common/components/layouts/main/content/AppPageLayout";
import GCCorporateUsers from "../../../components/hierarchy-node-users/GCCorporateUsers";

interface GCCorporateOfficeAdminsProps {}

const GCCorporateOfficeAdmins: React.FC<GCCorporateOfficeAdminsProps> = (
  props,
) => {
  return (
    <AppPageLayout pageTitle={"Admins"}>
      <GCCorporateUsers hierarchyNodeManagersOnly={true} />
    </AppPageLayout>
  );
};

export default GCCorporateOfficeAdmins;
