import { FC } from "react";
import {
  useUpdatePropertyDamageIncidentMutation,
  Property_Damage_Incident_Set_Input,
  useGetMonetarySeverityLevelsQuery,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentCommonUser from "../incident-users/IncidentCommonUser";
import { Card } from "antd";
import { useSearchParams } from "react-router-dom";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/TextField";
import IncidentInputField from "../basic/InputField";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import dayjs from "dayjs";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../../utils/useUpdateIncidentGeneralPerson";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import IncidentSubViewLayout from "../IncidentSubViewLayout";
import IncidentMonetarySeverity from "../basic/IncidentMonetarySeverity";

const PropertyDamageIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();

  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;

  const [updateMonetarySeverity, { loading: updateMonetarySeverityLoading }] =
    useUpdateMonetarySeverity();
  const [insertIncidentType, { loading: insertIncidentTypeLoading }] =
    useInsertIncidentType();
  const [updatePropertyDamage, { loading: updatePropertyDamageLoading }] =
    useUpdatePropertyDamageIncidentMutation();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const propertyDamage = incident.property_damage_incident;
  const documents = getIncidentDocuments(incident, "property_damage");
  if (!propertyDamage) {
    throw new Error("property_damage id missing");
  }

  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "property_damage");
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_type",
      incident,
      type: "property_damage",
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_type",
      incident,
      objects,
      type: "property_damage",
    });
  };

  const updatePropertyDamageIncident = async (
    _set: Omit<Property_Damage_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      property_damage_incident: { ...propertyDamage, ..._set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updatePropertyDamage({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "property-damage-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_property_damage_incident_by_pk: {
          ...propertyDamage,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const propertyOwner = propertyDamage.property_owner;

  return (
    <>
      <IncidentSubViewLayout title="Property Damage">
        <div className="flex flex-col gap-1">
          <IncidentCommonUser
            projectId={incident.project_id}
            title="Property Owner"
            user={propertyOwner ?? undefined}
            onUpdateUser={async (id, name) => {
              updatePropertyDamageIncident(
                { property_owner_user_id: id },
                `Updated Property Damage - "Property Owner" to ${name}`,
              );
            }}
            onUpdateUserInfo={async (id, key, val) => {
              if (!!propertyOwner) {
                const updatedIncident = {
                  ...incident,
                  property_damage_incident: {
                    ...propertyDamage,
                    property_owner: {
                      ...propertyOwner,
                      [key]: val,
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const label = key === "phone_number" ? "phone number" : "email";
                const comment = `Updated the ${label} of the "Property Owner" to ${val}`;
                updateIncidentCommonUser(
                  id,
                  { [key]: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
            onUpdateGeneralPerson={async (val) => {
              if (propertyOwner && propertyOwner.general_person) {
                const updatedIncident = {
                  ...incident,
                  property_damage_incident: {
                    ...propertyDamage,
                    property_owner: {
                      ...propertyOwner,
                      general_person: {
                        ...propertyOwner.general_person,
                        employer: val,
                      },
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const comment = `Updated employer of the "Property Owner" to ${val}`;
                updateGeneralPerson(
                  propertyOwner.id,
                  { employer: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
          />
          <Card title="Party responsible for damage">
            <IncidentInputField
              label="Employer Name"
              onSave={(val) => {
                updatePropertyDamageIncident(
                  {
                    party_responsible_employer_name: val,
                  },
                  `Updated Property Damage - "Party responsible for damage" to ${val}`,
                );
              }}
              defaultText={
                propertyDamage.party_responsible_employer_name ?? undefined
              }
            />

            <div className="mt-1">
              <IncidentInputField
                label="Contact Information"
                onSave={(val) => {
                  updatePropertyDamageIncident(
                    {
                      party_responsible_employer_name: val,
                    },
                    `Updated Property Damage - "Contact number of Party responsible for damage " to ${val}`,
                  );
                }}
                defaultText={
                  propertyDamage.party_responsible_employer_phone_number ??
                  undefined
                }
              />
            </div>
          </Card>

          <Card>
            <IncidentMonetarySeverity
              incident={incident}
              incidentType="Property Damage"
            />

            <IncidentTextField
              label="Description of Property Damage"
              text={propertyDamage.description.en}
              textId={propertyDamage.description.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey={"property_damage"}
              field={"description"}
              incident={incident}
            />

            <IncidentTextField
              required={true}
              label="Cause of Property Damage"
              text={propertyDamage.cause.en}
              textId={propertyDamage.cause.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey={"property_damage"}
              field={"cause"}
              incident={incident}
            />

            <IncidentInputField
              label="Estimated Cost of Damages (in USD)"
              validateAsNumber={true}
              onSave={(val) => {
                if (val === "") return;
                const damage = parseInt(val);
                updatePropertyDamageIncident(
                  { estimated_cost_of_damage: damage },
                  `Updated Property Damage - "Estimated Cost of Damages" to ${val}`,
                );
              }}
              defaultText={String(
                propertyDamage.estimated_cost_of_damage ?? "",
              )}
            />

            <IncidentInputField
              label="Actual Cost of Damages (in USD)"
              validateAsNumber={true}
              onSave={(val) => {
                if (val === "") return;
                const damage = parseInt(val);
                updatePropertyDamageIncident(
                  { actual_cost_of_damage: damage },
                  `Updated Property Damage -"Actual Cost of Damages" to ${val}`,
                );
              }}
              defaultText={String(propertyDamage.actual_cost_of_damage ?? "")}
            />
          </Card>

          {incident.incident_types.findIndex(
            (indexType) => indexType.type_value === "property_damage",
          ) !== -1 && (
            <Card>
              <IncidentDocViewAndUpload
                deleteDocument={deleteDocument}
                documents={documents}
                groupId={incident.id}
                uploadDocument={uploadDocument}
                type="property_damage"
              />
            </Card>
          )}

          <IncidentBottomButtons
            saveDisabled={!propertyDamage.cause.en}
            onNextClick={() => {
              const next = getNextPage(incident, "property_damage");
              setSearchParams({ subview: next });
            }}
            onSave={() => {
              updatePropertyDamageIncident(
                { completed_at: dayjs().format() },
                `Property Damage Section marked as completed`,
              );
            }}
          />
        </div>
      </IncidentSubViewLayout>
    </>
  );
};

export default PropertyDamageIncident;
