import React, { useMemo, useState } from "react";
import { Form, Select } from "antd";
import { useLazyQuery, useSuspenseQuery } from "@apollo/client";
import {
  GetIncidentByPkDocument,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetProjectSubcontractorsDocument,
  GetProjectSubcontractorsQuery,
  GetProjectSubcontractorsQueryVariables,
  GetTasksForIncidentDocument,
  GetTasksForIncidentQuery,
  GetTasksForIncidentQueryVariables,
  Order_By,
  Task_Insert_Input,
  useInsertTaskOneMutation,
  useUpdateTextTranslationByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import TextArea from "antd/es/input/TextArea";

interface Props {
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
}

const IncidentTaskUploader: React.FC<Props> = ({ incident }) => {
  const { data: projectSubcontractorsData } = useSuspenseQuery<
    GetProjectSubcontractorsQuery,
    GetProjectSubcontractorsQueryVariables
  >(GetProjectSubcontractorsDocument, {
    variables: {
      where: {
        project_id: { _eq: incident.project_id },
      },
      order_by: {
        subcontractor: {
          name: Order_By.Asc,
        },
      },
    },
    fetchPolicy: "cache-first",
  });

  const [getTaskData, { data: taskData }] = useLazyQuery<
    GetTasksForIncidentQuery,
    GetTasksForIncidentQueryVariables
  >(GetTasksForIncidentDocument);

  const { taskStepsMap } = useMemo(() => {
    const taskStepsMap: {
      [taskId: string]: {
        description: string;
        descriptionId: string;
        steps: Array<{
          id: string;
          description: string;
          descriptionId: string;
        }>;
      };
    } = {};

    taskData?.task?.forEach((t) => {
      taskStepsMap[t.id] = {
        description: t.description.en,
        descriptionId: t.description.id,
        steps: t.task_steps.map((step) => ({
          id: step.id,
          description: step.description.en,
          descriptionId: step.description.id,
        })),
      };
    });

    return {
      taskStepsMap,
    };
  }, [taskData]);

  const [newTaskVisible, setNewTaskVisible] = useState(false);
  const [newTaskDescription, setNewTaskDescription] = useState<string>("");
  const incidentTask = incident.task;

  const [taskId, setTaskId] = useState<string | undefined>(
    incidentTask?.id ?? undefined,
  );

  const [subcontractorId, setSubcontractorId] = useState<string | undefined>(
    incidentTask?.subcontractor_id ?? undefined,
  );

  const [taskStepId, setTaskStepId] = useState<string | undefined>(
    incidentTask?.task_steps.at(0)?.id ?? undefined,
  );
  const [insertTaskOne] = useInsertTaskOneMutation();
  const [updateText] = useUpdateTextTranslationByPkMutation();

  const insertIncidentTask = async () => {
    if (!incident.task) {
      let newTaskObject: Task_Insert_Input = {};
      if (newTaskVisible && newTaskDescription) {
        newTaskObject = {
          incident_id: incident.id,
          description: {
            data: { en: newTaskDescription, original: newTaskDescription },
          },
        };
      } else {
        if (!taskId || !taskStepId) return;

        const description = taskStepsMap[taskId].description;
        const taskStepDescription = taskStepsMap[taskId].steps.find(
          (step) => step.id === taskStepId,
        )?.description;

        newTaskObject = {
          incident_id: incident.id,
          description: {
            data: { en: description, original: description },
          },
          task_steps: {
            data: [
              {
                description: {
                  data: {
                    en: taskStepDescription,
                    original: taskStepDescription,
                  },
                },
                sort_index: 1,
              },
            ],
          },
        };
      }

      await insertTaskOne({
        variables: {
          object: newTaskObject,
        },
        update(cache, result) {
          const insertedTask = result.data?.insert_task_one;
          const updatedIncident = { ...incident, task: insertedTask };
          if (insertedTask) {
            cache.writeQuery<
              GetTasksForIncidentQuery,
              GetTasksForIncidentQueryVariables
            >({
              query: GetTasksForIncidentDocument,
              data: {
                __typename: "query_root",
                task: taskData?.task
                  ? [...taskData.task, insertedTask]
                  : [insertedTask],
              },
            });
          }
          cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>(
            {
              query: GetIncidentByPkDocument,
              data: {
                __typename: "query_root",
                incident_by_pk: updatedIncident,
              },
            },
          );
        },
      });
    } else {
      if (taskId) {
        const description = taskStepsMap[taskId].description;
        const descriptionId = incident.task.description.id;
        const initialTaskDescription = incident.task.description.en;

        if (description !== initialTaskDescription) {
          await updateText({
            variables: {
              _set: { en: description, original: description },
              pk_columns: { id: descriptionId },
            },
          });
        }

        if (taskStepId) {
          const taskStepDescription = taskStepsMap[taskId].steps.find(
            (step) => step.id === taskStepId,
          )?.description;

          const taskStepDescriptionId =
            incident.task.task_steps[0].description.id;
          const initialTaskStepDescription =
            incident.task.task_steps[0].description.en;

          if (taskStepDescription !== initialTaskStepDescription) {
            await updateText({
              variables: {
                pk_columns: { id: taskStepDescriptionId },
                _set: {
                  en: taskStepDescription,
                  original: taskStepDescription,
                },
              },
            });
          }
        }
      }
    }
  };

  const filteredTasks = useMemo(() => {
    return (
      taskData?.task.filter(
        (task) => !subcontractorId || task.subcontractor_id === subcontractorId,
      ) ?? []
    );
  }, [taskData?.task, subcontractorId]);

  const options = useMemo(() => {
    return [
      ...filteredTasks.map((task) => ({
        value: task.id,
        label: taskStepsMap[task.id].description,
      })),
      { value: "add_new", label: "Add New JHA" },
    ];
  }, [filteredTasks, taskStepsMap]);

  const handleSubcontractorChange = (val: string | undefined) => {
    setSubcontractorId(val);
    setTaskId(undefined);
    setTaskStepId(undefined);

    if (val) {
      getTaskData({
        variables: {
          where: {
            _or: [
              {
                project_id: { _eq: incident.project_id },
                subcontractor: {
                  subcontractor_projects: {
                    project_id: { _eq: incident.project_id },
                  },
                },
                deleted_at: { _is_null: true },
                is_pending_acceptance: { _eq: false },
                request_state: { _is_null: true },
                report_id: { _is_null: true },
              },
              { incident_id: { _is_null: false } },
            ],
          },
        },
      });
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Subcontractor" layout="vertical">
        <Select
          className="w-full"
          allowClear
          placeholder="Select a subcontractor"
          options={projectSubcontractorsData.project_subcontractor.map(
            (projSub) => ({
              value: projSub.subcontractor.id,
              label: projSub.subcontractor.name,
            }),
          )}
          value={subcontractorId}
          onChange={handleSubcontractorChange}
          onBlur={insertIncidentTask}
        />
      </Form.Item>

      {subcontractorId && (
        <Form.Item label="Job Hazard Analysis" layout="vertical">
          <Select
            className="w-full"
            placeholder="Select a JHA"
            options={options}
            value={taskId}
            onChange={(val) => {
              if (val === "add_new") {
                setNewTaskVisible(true);
              } else {
                setTaskId(val);
                setNewTaskVisible(false);
              }
            }}
            onBlur={insertIncidentTask}
          />
        </Form.Item>
      )}

      {newTaskVisible && (
        <Form.Item label="Enter JHA Description" layout="vertical">
          <TextArea
            value={newTaskDescription}
            onChange={(e) => setNewTaskDescription(e.currentTarget.value)}
            onBlur={insertIncidentTask}
          />
        </Form.Item>
      )}

      {taskId &&
        taskStepsMap[taskId]?.steps &&
        taskStepsMap[taskId].steps.length > 0 && (
          <Form.Item label="JHA Step" layout="vertical">
            <Select
              className="w-full"
              placeholder="Select a JHA step"
              options={taskStepsMap[taskId].steps.map((step) => ({
                key: step.id,
                value: step.id,
                label: step.description,
              }))}
              value={taskStepId}
              onChange={(id) => {
                setTaskStepId(id);
              }}
              onBlur={insertIncidentTask}
            />
          </Form.Item>
        )}
    </Form>
  );
};

export default IncidentTaskUploader;
