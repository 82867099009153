import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../common/components/layouts/tabs/CustomTabsRouted";
import AppPageLayout, {
  AppPageLayoutProps,
} from "../../../../common/components/layouts/main/content/AppPageLayout";

interface GCIncidentTabsLayoutProps {
  basePath: string;
  pageActions?: AppPageLayoutProps["pageActions"];
}

const GCIncidentTabsLayout: React.FC<GCIncidentTabsLayoutProps> = (props) => {
  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "charts",
      label: "Overview",
    },
    {
      path: "table",
      label: "Incident Log",
    },
  ];
  return (
    <AppPageLayout pageTitle={"Incidents"} pageActions={props.pageActions}>
      <CustomTabsRouted tabs={tabs} basePath={props.basePath} />
    </AppPageLayout>
  );
};

export default GCIncidentTabsLayout;
