import { FC } from "react";
import {
  useUpdateEnvironmentalIncidentMutation,
  useGetMonetarySeverityLevelsQuery,
  Environmental_Incident_Set_Input,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card } from "antd";
import { useSearchParams } from "react-router-dom";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/TextField";
import IncidentSelectField from "../basic/SelectField";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import dayjs from "dayjs";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import IncidentSubViewLayout from "../IncidentSubViewLayout";
import IncidentMonetarySeverity from "../basic/IncidentMonetarySeverity";

const EnvironmentalIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  const [deleteIncidentDocument] = useDeleteIncidentDocument();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [updateMonetarySeverity, { loading: updateMonetarySeverityLoading }] =
    useUpdateMonetarySeverity();
  const [insertIncidentType, { loading: insertIncidentTypeLoading }] =
    useInsertIncidentType();
  const environmental = incident.environmental_incident;
  const documents = getIncidentDocuments(incident, "environmental");
  if (!environmental) {
    throw new Error("environmental_incident is missing");
  }
  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "environmental");
  };

  const [
    updateEnvironmentalIncident,
    { loading: updateEnvironmentalIncidentLoading },
  ] = useUpdateEnvironmentalIncidentMutation();

  const updateEnvironmental = async (
    _set: Omit<Environmental_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      environmental_incident: {
        ...environmental,
        ..._set,
      },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateEnvironmentalIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "environmental-incident-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_environmental_incident_by_pk: {
          ...environmental,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_type",
      incident,
      type: "environmental",
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_type",
      incident: incident,
      objects: objects,
      type: "environmental",
    });
  };

  const environmentalIncidentTypeOptions = [
    { label: "Fire/Smoke", value: "fire" },
    {
      label: "Indoor Air Quality",
      value: "indoor_air_quality",
    },
    { label: "Spill/Leak", value: "spill" },
    { label: "SWPPP", value: "swppp" },
    { label: "Waste Disposal", value: "waste_disposal" },
    { label: "Waste Handling", value: "waste_handling" },
    { label: "Other", value: "other" },
  ];

  return (
    <>
      <IncidentSubViewLayout title="Environmental Incident">
        <div className="flex flex-col gap-1">
          <Card className="mb-2">
            <IncidentSelectField
              title="Select type of environmental incident"
              required={true}
              options={environmentalIncidentTypeOptions}
              value={environmental.type ?? undefined}
              onChange={async (option) => {
                if (option === null) {
                  await updateEnvironmental(
                    {
                      type: null,
                    },
                    `Removed "Select type of environmental incident" from Environmental Section`,
                  );
                } else if (typeof option.value === "string") {
                  await updateEnvironmental(
                    {
                      type: option.value,
                    },
                    `Updated Environmental - "Select type of environmental incident" to ${option.label}`,
                  );
                }
              }}
              loading={
                insertIncidentTypeLoading || updateEnvironmentalIncidentLoading
              }
            />

            <IncidentMonetarySeverity
              incident={incident}
              incidentType="Environmental"
            />

            <IncidentTextField
              required={true}
              label="Details of environmental incident"
              text={environmental.nature.en}
              textId={environmental.nature.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey="environmental"
              field="nature"
              incident={incident}
            />

            <IncidentTextField
              required={true}
              label="Cause of incident"
              text={environmental.cause.en}
              textId={environmental.cause.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey="environmental"
              field="cause"
              incident={incident}
            />

            {incident.incident_types.findIndex(
              (indexType) => indexType.type_value === "environmental",
            ) !== -1 && (
              <IncidentDocViewAndUpload
                deleteDocument={deleteDocument}
                documents={documents}
                groupId={incident.id}
                uploadDocument={uploadDocument}
                type="environmental"
              />
            )}
          </Card>
          <div className="-mt-2">
            <IncidentBottomButtons
              saveDisabled={
                !environmental.type ||
                !environmental.nature.en ||
                !environmental.cause.en
              }
              onNextClick={() => {
                const next = getNextPage(incident, "environmental");
                setSearchParams({ subview: next });
              }}
              onSave={() => {
                updateEnvironmental(
                  { completed_at: dayjs().format() },
                  `Environmental Section marked as completed`,
                );
              }}
            />
          </div>
        </div>
      </IncidentSubViewLayout>
    </>
  );
};

export default EnvironmentalIncident;
