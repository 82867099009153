import React from "react";
import { Modal, Form, Select } from "antd";

interface AddIncidentValues {
  types: Array<string>;
}

interface AddIncidentModalProps {
  visible: boolean;
  onCreate: (values: AddIncidentValues) => Promise<void>;
  onCancel: () => void;
}

const AddIncidentModal: React.FC<AddIncidentModalProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      title="Create a New Incident"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as AddIncidentValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="types"
          label="Incident Types"
          rules={[{ required: true, message: "Enter a task description" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select all that apply"
            options={[
              {
                value: "injury_illness",
                label: "Injury/Illness",
              },
              {
                value: "environmental",
                label: "Environmental Incident",
              },
              {
                value: "equipment",
                label: "Equipment Incident",
              },
              {
                value: "near_miss",
                label: "Near Miss Incident",
              },
              {
                value: "property_damage",
                label: "Property Damage",
              },
              {
                value: "theft",
                label: "Theft / Vandalism Incident",
              },
              {
                value: "utility",
                label: "Utility / Service Strike",
              },
              {
                value: "vehicle",
                label: "Vehicle / Road Incident",
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddIncidentModal;
