import useGcOrientationData from "../../../../utils/useGcOrientationData";
import React, { useState } from "react";
import WorkerRegistrationQuestionsModal from "../settings/components/WorkerRegistrationQuestionsModal";
import { Button, Card, Typography } from "antd";
import ExplanationIcon from "../../../../../../common/components/ExplanationIcon";
import { yearsWithEmployerOptions } from "../../../../utils/demographicQuestionOptions";
import withCustomSuspense from "../../../../../../common/components/general/withCustomSuspense";

interface QuestionProps {
  title: React.ReactNode;
  question: React.ReactNode;
  children: React.ReactNode;
}

const Question: React.FC<QuestionProps> = (props) => {
  return (
    <Typography.Paragraph>
      <Typography.Text strong>{props.title}</Typography.Text>
      <br />
      <Typography.Text>{props.question}</Typography.Text>
      <br />
      <Typography.Text type={"secondary"}>{props.children}</Typography.Text>
    </Typography.Paragraph>
  );
};

interface GCProjectOrientationBuilderRegistrationQuestionsProps {
  projectId: string;
}

const GCProjectOrientationBuilderRegistrationQuestions: React.FC<
  GCProjectOrientationBuilderRegistrationQuestionsProps
> = (props) => {
  const gcOrientationData = useGcOrientationData(props.projectId);
  const projectData = gcOrientationData.getProjectOrientation();

  const [openQuestions, setOpenQuestions] = useState(false);
  return (
    <>
      <WorkerRegistrationQuestionsModal
        key={`qm_${openQuestions}`} // use key to reset state in modal
        visible={openQuestions}
        projectData={projectData}
        projectId={props.projectId}
        onClose={() => setOpenQuestions(false)}
      />

      <Card
        title={
          <>
            Worker Diversity Questions{" "}
            <ExplanationIcon
              content={
                <p className="">
                  Understand the Diversity and Demographic of your workers by
                  selecting from a list of questions. During registration,
                  workers will be required to answer each question.
                  <br />
                  <br />
                  Note: this information is private and will only be made
                  available to you and your team at a macro level. Think charts,
                  graphs, and percentages.
                  <br />
                  <br />
                  These questions are not editable. Please contact your SiteForm
                  representative for more information
                </p>
              }
            />
          </>
        }
      >
        <Typography>
          {projectData.ask_gender && (
            <Question title={`Gender`} question={`What is your gender?`}>
              Possible answers are: Male; Female
            </Question>
          )}
          {projectData.ask_ethnicity && (
            <Question title={`Race`} question={`What is your race?`}>
              Possible answers are: American Indian or Alaska Native; Asian;
              Black or African American; Cape Verdean; Hispanic, Latino or
              Spanish; Native Hawaiian and Pacific Islander; White or Non
              Hispanic; Some Other Race
            </Question>
          )}
          {projectData.ask_veteran_type && (
            <Question title={`Veteran`} question={`Are you a Veteran?`}>
              Possible answers are: Non-Veteran; Veteran, Disabled Veteran
            </Question>
          )}
          {!(
            projectData.require_dob ||
            projectData.general_contractor.require_dob
          ) &&
            projectData.ask_age && (
              <Question title={`Age`} question={`What is your age?`}>
                Enter Age
              </Question>
            )}
          {projectData.ask_residence && (
            <Question
              title={`Residence`}
              question={`What city or zip code do you live in?`}
            >
              Select the city
            </Question>
          )}
          {projectData.ask_emergency_contact && (
            <Question
              title={`Emergency Contact`}
              question={`What is your emergency contact?`}
            >
              Enter Emergency contact
            </Question>
          )}
          {projectData.ask_number_of_years_in_construction && (
            <Question
              title={`Years in Construction`}
              question={`How many years have you been in Construction work?`}
            >
              Select number of years you have been in construction work
            </Question>
          )}
          {projectData.ask_years_with_employer && (
            <Question
              title={`Years with Employer`}
              question={`How many years have you been employed with this company?`}
            >
              Select a time range, e.g.{" "}
              {yearsWithEmployerOptions.map((y, i) => (i === 0 ? y : ", " + y))}
            </Question>
          )}
          <Button onClick={() => setOpenQuestions(true)}>
            Add Registration Questions +{" "}
          </Button>
        </Typography>
      </Card>
    </>
  );
};

export default withCustomSuspense(
  GCProjectOrientationBuilderRegistrationQuestions,
);
