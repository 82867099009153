import React, { FC, useRef, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IconDotsVertical, IconDownload } from "@tabler/icons";
import { Button, Popover } from "antd";
import noPropagation from "src/common/functions/noPropagation";
import Icon from "src/common/components/general/Icon";
import usePermanentOnsiteSetting from "src/domain-features/sitesafety/utils/usePermanentOnsiteSetting";
import { GCSubcontractorsRecordType } from "src/common/components/tables/ProjectSubcontractorTableNew";
import { useGetSignJhaPdfMutation } from "src/common/types/generated/apollo/graphQLTypes";
import OpenQrCode from "src/common/components/OpenQrCode";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import GCSendInviteModal, {
  GCSendInviteModalRef,
} from "src/utility-features/invitations/GCSendInviteModal";
import { useNavigate } from "react-router-dom";

const SubTableActionPopover: FC<{
  row: GCSubcontractorsRecordType;
  projectId: string;
  showsubadmininvite?: boolean;
  onclicked?: (r: GCSubcontractorsRecordType) => any;
}> = ({ row, showsubadmininvite, onclicked, projectId }) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const currentWorkingCrews = row.project_crews.filter((c) => !c.deleted_at);
  const navigate = useNavigate();
  const [getSignJHAPdf, { loading: getSignJHAPdfLoading }] =
    useGetSignJhaPdfMutation();
  const {
    setSubOffSite,
    setSubOnSite,
    loading: onsiteLoading,
  } = usePermanentOnsiteSetting(row.project.pk);
  const sendInvite = useRef<GCSendInviteModalRef>(null);

  return (
    <>
      <GCSendInviteModal
        ref={sendInvite}
        projectId={projectId}
        subcontractorId={row.subcontractor_id}
      />
      <Popover
        open={open}
        placement="bottom"
        onOpenChange={handleOpenChange}
        key={row.pk}
        trigger={"click"}
        // closeOnChange={closeOnChange}
        content={
          <div className="flex flex-col gap-0.5 items-start justify-center">
            <Button
              type="text"
              onClick={() => {
                handleOpenChange(false);
                sendInvite.current?.open();
              }}
            >
              Invite Subcontractor Administrator
            </Button>
            {showsubadmininvite && (
              <Button
                className="-mt-0.25"
                type="text"
                onClick={noPropagation(() => {
                  if (onclicked) {
                    handleOpenChange(false);
                    onclicked(row);
                  }
                })}
              >
                Request a JHA
              </Button>
            )}
            <div className="ml-1">
              JHA QR Code Poster
              <Popover
                className="ml-1"
                overlayStyle={{
                  width: "20%",
                }}
                placement="topRight"
                content={
                  <div>
                    <p>
                      JHA QR Codes allow workers to ACCESS, REVIEW, and SIGN
                      their JHAs.
                    </p>
                    <p>
                      Download and give the JHA QR Code Poster to Foremen or
                      Crew Leads to post on Huddle Boards or in work areas.
                    </p>
                  </div>
                }
              >
                {/* <Button>TR</Button> */}
                <InfoCircleOutlined />
              </Popover>
            </div>
            <div className="ml-1">
              <Button
                icon={<IconDownload />}
                loading={getSignJHAPdfLoading}
                type="text"
                onClick={async (e) => {
                  e.stopPropagation();

                  getSignJHAPdf({
                    variables: {
                      input: {
                        projectId: projectId,
                        subcontractorId: row.subcontractor_id,
                      },
                    },
                  }).then((data) => {
                    if (data.data?.generateSignJHAPdf) {
                      downloadFromUrl(data.data?.generateSignJHAPdf);
                    }
                  });
                }}
              >
                Download Poster{" "}
              </Button>
              <OpenQrCode
                onlyIcons
                destinationUrl={`${document.location.origin}/qr/p/${projectId}/sub/${row.subcontractor_id}`}
              />
            </div>

            {currentWorkingCrews.length > 1 ? (
              <Button
                type="text"
                onClick={() =>
                  navigate(
                    `/gce/hierarchy/project/${projectId}/subcontractors/${row.subcontractor_id}/workers-and-crews`,
                  )
                }
              >
                Manage Crews
              </Button>
            ) : (
              <Button
                type="text"
                style={{
                  color:
                    row.project_subcontractor_onsite_periods.length > 0
                      ? "red"
                      : "green",
                }}
                loading={onsiteLoading}
                onClick={async () => {
                  if (row.project_subcontractor_onsite_periods.length > 0)
                    await setSubOffSite(row.subcontractor.pk);
                  else await setSubOnSite(row.subcontractor.pk);
                }}
              >
                {row.project_subcontractor_onsite_periods.length > 0
                  ? "Mark Offsite"
                  : "Mark Onsite"}
              </Button>
            )}
          </div>
        }
      >
        <Button className="rounded-1 font-accent">
          <Icon icon={IconDotsVertical} color="interactive" />
        </Button>
      </Popover>
    </>
  );
};
export default SubTableActionPopover;
