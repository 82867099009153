import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useUpdateGcBuEmployeesMutation } from "src/common/types/generated/apollo/graphQLTypes";
import GCHierarchyTeam from "../../../../components/hierarchy-node-users/GCHierarchyTeam";
import useAuthUser from "src/common/hooks/useAuthUser";
import { BusinessUnitProps } from "../../../../utils/withBusinessUnitId";
import GCEmployeesCommonProps from "../../../../utils/GCEmployeesCommonProps";
import getOrgEmployeeWhere from "../../../../utils/getOrgEmployeeWhere";
import useGcEmployee from "../../../utils/useGcEmployee";

interface GCBusinessUnitEmployeesProps
  extends BusinessUnitProps,
    GCEmployeesCommonProps {}

const GCBusinessUnitEmployees: React.FC<GCBusinessUnitEmployeesProps> = ({
  businessUnitId,
  hierarchyNodeManagersOnly,
}) => {
  const employee = useGcEmployee();
  const [updateBusinessUnitEmp] = useUpdateGcBuEmployeesMutation();
  const authUser = useAuthUser();

  return (
    <GCHierarchyTeam
      {...{
        getRemainingGcTeamVariables: {
          projectsToInviteWhere: {
            gc_business_unit_id: { _eq: businessUnitId },
          },
          gcEmployeeWhere: {
            general_contractor_id: { _eq: employee.general_contractor.id },
            ...(hierarchyNodeManagersOnly
              ? {
                  gc_business_units: {
                    gc_business_unit_id: { _eq: businessUnitId },
                  },
                }
              : getOrgEmployeeWhere("business_unit", businessUnitId)),
          },
        },
        title: "BusinessUnit",
        isAdmin: (employee) =>
          employee.gc_business_units.some(
            (bu) => bu.gc_business_unit_id === businessUnitId,
          ),

        onInviteSuccess: async (userIds) => {
          await updateBusinessUnitEmp({
            variables: {
              deleteProjEmpWhere: { id: { _is_null: true } },
              deleteDivEmpWhere: { id: { _is_null: true } },
              deleteOfficeEmpWhere: {
                gc_office: { gc_business_unit_id: { _eq: businessUnitId } },
                user_id: { _in: userIds },
              },
              deleteBuEmpWhere: { id: { _is_null: true } },
              insertBuEmpObjs: userIds.map((u) => ({
                gc_business_unit_id: businessUnitId,
                created_by_uid: authUser.uid,
                user_id: u,
              })),
            },
          });
        },
        onRemoveFromTeam: async (userId, projects) => {
          await updateBusinessUnitEmp({
            variables: {
              insertBuEmpObjs: [],
              deleteBuEmpWhere: {
                gc_business_unit_id: { _eq: businessUnitId },
                user_id: { _eq: userId },
              },
              deleteDivEmpWhere: { id: { _is_null: true } },
              deleteOfficeEmpWhere: { id: { _is_null: true } },
              deleteProjEmpWhere: {
                project_id: { _in: projects },
                employee_id: { _eq: userId },
                direct_project_assign: { _eq: false },
              },
            },
          });
        },
        excludeEmployeeWhere: {
          _or: [
            { is_corporate_admin: { _eq: true } },
            {
              gc_business_units: {
                gc_business_unit_id: { _eq: businessUnitId },
              },
            },
            {
              gc_divisions: {
                gc_division: {
                  gc_business_units: { id: { _eq: businessUnitId } },
                },
              },
            },
          ],
        },
      }}
    />
  );
};

export default withCustomSuspense(GCBusinessUnitEmployees);
