import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { AddWorkerDrugTestModalInsertCertsMutation } from "src/common/types/generated/relay/AddWorkerDrugTestModalInsertCertsMutation.graphql";
import { image_insert_input } from "src/common/types/generated/relay/types";
import FModal, { FModalRef } from "./FModal";
import Tag from "../general/Tag";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import useInsertDTResult from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/drugTest/utils/useInsertDTResult";
import { auth } from "src/common/functions/firebase";
import uploadFiles from "src/common/functions/upload-utility/uploadFiles";
import * as uuid from "uuid";

type FormValues = {
  date: Dayjs;
  result: "positive" | "negative" | "non-negative";
  certificationFrontImage?: Array<{ originFileObj: File }>;
  certificationBackImage?: Array<{ originFileObj: File }>;
};

// TODO enforce only 1 of 2 ids
export type AddWorkerDrugTestModalDataProps = {
  workerId: string;
  subcontractorId?: string | null;
  gcProps?: {
    projectDrugtestValidityDays: number;
    projectId: string;
    performingDrugtest: boolean;
    includeImages?: boolean;
  };
};

export type AddWorkerDrugTestModalProps = {
  onInserted: () => void;
} & AddWorkerDrugTestModalDataProps;

export type AddWorkerDrugTestModalRef = FModalRef<FormValues> | null;
const AddWorkerDrugTestModal = forwardRef<
  AddWorkerDrugTestModalRef,
  AddWorkerDrugTestModalProps
>(({ workerId, onInserted, subcontractorId, gcProps }, ref) => {
  const modal = useRef<FModalRef<FormValues>>(null);
  const [inserting, setInserting] = useState(false);
  const [insertDrugTest] =
    useAsyncMutation<AddWorkerDrugTestModalInsertCertsMutation>(
      graphql`
        mutation AddWorkerDrugTestModalInsertCertsMutation(
          $objects: [worker_drug_test_insert_input!]!
        ) {
          insert_worker_drug_test(objects: $objects) {
            affected_rows
            returning {
              id
              pk: id @__clientField(handle: "pk")
            }
          }
        }
      `,
    );

  useImperativeHandle<AddWorkerDrugTestModalRef, AddWorkerDrugTestModalRef>(
    ref,
    () => modal.current,
  );

  const [insertDTResult] = useInsertDTResult();

  return (
    <FModal
      ref={modal}
      title={"Add a drug test to worker"}
      okText={"Add"}
      confirmLoading={inserting}
      onOk={async () => {
        const form = modal.current?.form;
        if (!form) return;
        const values = await form.validateFields().catch((v) => null);
        if (!values) return;
        setInserting(true);
        try {
          if (gcProps) {
            const frontFile = values.certificationFrontImage
              ? (
                  await uploadFiles(
                    values.certificationFrontImage.map((o) => o.originFileObj),
                  )
                )[0]
              : [];
            const backFile = values.certificationBackImage
              ? (
                  await uploadFiles(
                    values.certificationBackImage.map((o) => o.originFileObj),
                  )
                )[0]
              : [];

            // TODO promisify
            const drugtestImages: image_insert_input[] = frontFile[0]
              ? [
                  {
                    id: uuid.v4(),
                    url: frontFile[0].url,
                    description: "Front Image",
                    created_by_user_id: auth.currentUser?.uid,
                  },
                ]
              : [];
            if (backFile[0]) {
              drugtestImages.push({
                id: uuid.v4(),
                url: backFile[0].url,
                description: "Back Image",
                created_by_user_id: auth.currentUser?.uid,
              });
            }
            const isDTValid = dayjs(values.date)
              .add(gcProps?.projectDrugtestValidityDays ?? 0, "days")
              .isAfter(dayjs());
            if (isDTValid) {
              await insertDTResult({
                projectId: gcProps?.projectId,
                workerIds: [workerId],
                resultsMap: {
                  [workerId]: {
                    result: values.result,
                    date: values.date,
                    projectIdsToInsert: [gcProps?.projectId],
                    subcontractorId: subcontractorId,
                  },
                },
                onFinish: onInserted,
                images: drugtestImages,
              });
            } else {
              await insertDrugTest({
                variables: {
                  objects: [
                    {
                      entered_through_uid: auth.currentUser?.uid,
                      drug_test_date: values.date.format(),
                      status: values.result,
                      project_id: gcProps.projectId,
                      subcontractor_id: subcontractorId,
                      worker_id: workerId,
                      images: { data: drugtestImages },
                    },
                  ],
                },
              });
            }
          } else {
            await insertDrugTest({
              variables: {
                objects: [
                  {
                    entered_through_uid: auth.currentUser?.uid,
                    drug_test_date: values.date.format(),
                    status: values.result,
                    subcontractor_id: subcontractorId,
                    worker_id: workerId,
                  },
                ],
              },
            });
          }
          onInserted();
        } finally {
          setInserting(false);
        }
      }}
    >
      <FModal.Date
        name="date"
        label="Date of test"
        requiredMessage="Enter the date"
      />
      <FModal.RadioGroup
        name="result"
        label="Drug Test Result"
        requiredMessage="Enter Drug Test Result"
      >
        <div style={{ display: "flex" }}>
          <FModal.Radio props={{ value: "negative" }}>
            <Tag status="positive">Negative</Tag>
          </FModal.Radio>
          <FModal.Radio props={{ value: "positive" }}>
            <Tag status="negative">Positive</Tag>
          </FModal.Radio>
          <FModal.Radio props={{ value: "non-negative" }}>
            <Tag status="inactive"> Non-Negative</Tag>
          </FModal.Radio>
        </div>
      </FModal.RadioGroup>
      {gcProps?.includeImages ? (
        <>
          <FModal.SingleImgDragUpload
            requiredMessage={"Enter Front Image of Drug Test"}
            label="Front Image"
            name="certificationFrontImage"
          />
          <FModal.SingleImgDragUpload
            label="Back Image"
            name="certificationBackImage"
          />
        </>
      ) : (
        <></>
      )}
    </FModal>
  );
});

export default AddWorkerDrugTestModal;
