import GCAdminIncidentsOverview from "../../../../components/incident/GCAdminIncidentsOverview";
import { OfficeProps } from "../../../../utils/withOfficeId";
import useGcEmployee from "../../../utils/useGcEmployee";

const GCOfficeIncidents: React.FC<OfficeProps> = ({ officeId }) => {
  const gc = useGcEmployee().general_contractor;
  return (
    <GCAdminIncidentsOverview
      organizationLevel="office"
      organizationId={officeId}
      severityUserFilter={{ office_id: { _eq: officeId } }}
      toInsertWith={{
        gc_id: gc.id,
        office_id: officeId,
      }}
    />
  );
};
export default GCOfficeIncidents;
