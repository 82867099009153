import gcProjectDashboardRoute from "./entry-routes/dashboard/gcProjectDashboardRoute";
import gcProjectReportsRoute from "./entry-routes/reports/gcProjectReportsRoute";
import qrProjectReportsRoute from "./entry-routes/qr-project-reports/qrProjectReportsRoute";
import scReportsRoute from "./entry-routes/sub-reports/scReportsRoute";
import scToolboxTalksRoute from "./entry-routes/sub-toolbox-talks/scToolboxTalksRoute";

const siteSafetyRoutes = {
  gcProjectDashboard: gcProjectDashboardRoute,
  gcProjectReports: gcProjectReportsRoute,
  qrProjectReports: qrProjectReportsRoute,
  scReports: scReportsRoute,
  scTBTs: scToolboxTalksRoute,
};

export default siteSafetyRoutes;
