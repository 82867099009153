import {
  Button,
  Checkbox,
  Divider,
  Form,
  Popconfirm,
  Radio,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  GetGcPermitChecklistItemsDocument,
  useDeletePermitOptionsMutation,
  useInsertPermitOptionsMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import noPropagation from "src/common/functions/noPropagation";
import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitChecklistsSettingTables from "./basic/PermitChecklistsSettingTables";

const AccessLadderSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  project,
  checklistGc,
}) => {
  const [accessLadderEditing, setAccessLadderEditing] = useState(false);
  const [ladderPermitForm] = Form.useForm();
  const [insertPermitOptions, { loading: insertPermitOptionsLoading }] =
    useInsertPermitOptionsMutation();
  const [deletePermitOptions, { loading: deletePermitOptionsLoading }] =
    useDeletePermitOptionsMutation();
  const initialAccessLadderHeight = data.gcAccessLadderPermitHeight.map(
    (type) => type.value,
  )[0];
  const [updateProjectSetting] = useUpdateProjectSettingByPkForPermitMutation();
  const project_setting = project?.project_setting;
  const initialAccessLadderType = data.gcAccessLadderPermitTypes.map(
    (type) => type.value,
  );

  const accessLadderTypesOptions = ["Extension", "Job Made", "Other"];

  const accessLadderHeightOptions = [
    "20 feet",
    "21 feet",
    "22 feet",
    "23 feet",
    "24 feet",
    "25 feet",
  ];

  return (
    <>
      <StyledContent>
        <>
          {!accessLadderEditing && (
            <>
              <div className="flex flex-col mb-2">
                <p style={{ fontWeight: 400 }}>
                  {initialAccessLadderType.length > 0
                    ? "Ladders that are allowed on the project and require a Permit"
                    : "No Ladder Types selected "}
                </p>
                <div className="-mx-0.25 mt-0.5 ">
                  {initialAccessLadderType.map((type) => (
                    <span
                      className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                      key={type}
                    >
                      {type}
                    </span>
                  ))}
                </div>
              </div>
              {initialAccessLadderHeight ? (
                <>
                  <div className="flex flex-col mb-2">
                    <p style={{ fontWeight: 400 }}>
                      Height fall protection is required if working above{" "}
                      {initialAccessLadderHeight} feet
                    </p>
                  </div>
                </>
              ) : (
                <div className="flex flex-col mb-2">
                  <p style={{ fontWeight: 400 }}>
                    Height fall protection is not required
                  </p>
                </div>
              )}
            </>
          )}
          {accessLadderEditing && (
            <Form
              form={ladderPermitForm}
              layout="vertical"
              initialValues={{ modifier: "public" }}
              onSubmitCapture={async (e) => {
                const accessLadderTypes: string[] =
                  ladderPermitForm.getFieldValue("accessLadderTypes");
                const accessLadderHeight: string =
                  ladderPermitForm.getFieldValue("accessLadderHeight");
                const insertPermitOptionValues: Array<{
                  type: string;
                  value: string;
                }> = [
                  ...accessLadderTypes
                    .filter(
                      (e) => !initialAccessLadderType.find((ie) => ie === e),
                    )
                    .map((e) => ({
                      type: "access_ladder_type",
                      value: e,
                    })),
                ];
                if (
                  accessLadderHeight !== initialAccessLadderHeight &&
                  accessLadderHeight !== "0"
                ) {
                  insertPermitOptionValues.push({
                    type: "access_ladder_height",
                    value: accessLadderHeight,
                  });
                }
                const deletePermitOptionValues: Array<{
                  type: string;
                  value: string;
                }> = [
                  ...initialAccessLadderType
                    .filter((ie) => !accessLadderTypes.find((e) => ie === e))
                    .map((ie) => ({
                      type: "access_ladder_type",
                      value: ie,
                    })),
                ];
                if (
                  initialAccessLadderHeight &&
                  accessLadderHeight !== initialAccessLadderHeight
                ) {
                  deletePermitOptionValues.push({
                    type: "access_ladder_height",
                    value: initialAccessLadderHeight,
                  });
                }
                await insertPermitOptions({
                  variables: {
                    objects: insertPermitOptionValues.map((e) => ({
                      type: e.type,
                      value: e.value,
                      general_contractor_id: queryVariables.gcId,
                    })),
                  },
                });
                await deletePermitOptions({
                  variables: {
                    where: {
                      _or: deletePermitOptionValues.map((e) => ({
                        type: { _eq: e.type },
                        value: { _eq: e.value },
                        general_contractor_id: { _eq: queryVariables.gcId },
                      })),
                    },
                  },
                });
                const newData: typeof data = {
                  ...data,
                  gcAccessLadderPermitTypes: accessLadderTypes.map((e) => ({
                    value: e,
                  })),
                  gcAccessLadderPermitHeight:
                    accessLadderHeight !== "0"
                      ? [{ value: accessLadderHeight }]
                      : [],
                };
                gcPermitCheckListItems.client.writeQuery({
                  query: GetGcPermitChecklistItemsDocument,
                  variables: queryVariables,
                  data: newData,
                });
                setAccessLadderEditing(false);
              }}
            >
              <Form.Item
                name="accessLadderTypes"
                label="Select the ladders that are allowed on the project and require a Permit"
                initialValue={initialAccessLadderType}
              >
                <Select
                  listItemHeight={10}
                  mode="multiple"
                  showArrow
                  bordered
                  style={{ width: 400 }}
                  options={accessLadderTypesOptions.map((o) => ({
                    value: o,
                    key: o,
                    label: o,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="accessLadderHeight"
                label="Select what height fall protection is required if working above:"
                initialValue={initialAccessLadderHeight}
              >
                <Select
                  listItemHeight={4}
                  showArrow
                  bordered
                  style={{ width: 400 }}
                  options={[
                    {
                      value: "0",
                      key: "0",
                      label: "Not needed",
                    },
                    ...accessLadderHeightOptions.map((o, index) => ({
                      value: (index + 20).toString(),
                      key: (index + 20).toString(),
                      label: o,
                    })),
                  ]}
                />
              </Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={
                    insertPermitOptionsLoading || deletePermitOptionsLoading
                  }
                >
                  Done
                </Button>
                <Button onClick={() => setAccessLadderEditing(false)}>
                  Cancel
                </Button>
              </Space>
            </Form>
          )}
          {!accessLadderEditing && (
            <Popconfirm
              title={`Changes to these questions will be applied company wide on all projects. Are you sure?`}
              onConfirm={() => setAccessLadderEditing(true)}
              onCancel={noPropagation()}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button type={"primary"} onClick={noPropagation()}>
                Edit Ladder Permit Configurations
              </Button>
            </Popconfirm>
          )}
          <Divider />
          {project_setting && (
            <>
              <StyledContent>
                <Checkbox
                  checked={project_setting.worker_can_add_access_ladder_number}
                  onChange={async (e) => {
                    const set = {
                      worker_can_add_access_ladder_number: e.target.checked,
                    };
                    console.log(project_setting, "2");
                    await updateProjectSetting({
                      variables: {
                        project_id: project_setting.project_id,
                        _set: set,
                      },
                      optimisticResponse: {
                        update_project_setting_by_pk: {
                          ...project_setting,
                          ...set,
                        },
                      },
                    });
                    console.log(project_setting, "3");
                  }}
                >
                  Worker enters ladder ID or Tag # during permit submission
                </Checkbox>
              </StyledContent>
              <StyledContent>
                Permit is valid for{" "}
                <Radio.Group
                  value={project_setting.access_ladder_max_duration}
                  options={[
                    { value: "week", label: "Week" },
                    { value: "day", label: "Day" },
                  ]}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const set = {
                      access_ladder_max_duration: newValue,
                    };
                    updateProjectSetting({
                      variables: {
                        project_id: project_setting.project_id,

                        _set: set,
                      },
                      optimisticResponse: {
                        update_project_setting_by_pk: {
                          ...project_setting,
                          ...set,
                        },
                      },
                    });
                  }}
                />
              </StyledContent>
            </>
          )}
          <PermitChecklistsSettingTables
            {...{
              checklistGc,
              data,
              project,
              gcPermitCheckListItems,
              queryVariables,
              checklistItemsType: "ladder_access",
            }}
          />
          <Divider />
        </>
      </StyledContent>
    </>
  );
};
export default AccessLadderSettings;
