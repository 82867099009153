import React from "react";
import { Skeleton } from "antd";

interface LoadingContentProps {}

const LoadingContent: React.FC<LoadingContentProps> = () => {
  return <Skeleton active />;
};

export default LoadingContent;
