import NestedRoute from "src/common/types/manual/NestedRoute";
import React from "react";
import GCProjectReportsTBTs from "./components/GCProjectReportsTBTs";

const toolboxTalksRoute: NestedRoute = {
  element: <GCProjectReportsTBTs />,
  handle: {
    breadcrumb: "Toolbox Talks",
  },
};

export default toolboxTalksRoute;
