import { RoutedMenuItem } from "../../../../../../../utility-features/navigation/routed-menu/routedMenu";
import GCProjectSettingsGeneral from "./general/GCProjectSettingsGeneral";
import GCProjectSettingsReportsGeneral from "./onsite/GCProjectSettingsOnsite";
import GCProjectSettingsObservations from "./observations/GCProjectSettingsObservations";
import GCProjectSettingsJha from "./jha/GCProjectSettingsJha";
import GCProjectSettingsDeliveries from "./deliveries/GCProjectSettingsDeliveries";
import GCProjectSettingsTodos from "./todos/GCProjectSettingsTodos";
import GCProjectSettingsIncidents from "./incidents/GCProjectSettingsIncidents";
import GCProjectReportsDailySettings from "./daily/GCProjectReportsDailySettings";
import GCProjectSettingsReportsPermits from "./permits/GCProjectSettingsReportsPermits";
import GCProjectSettingsReportsPtp from "./ptp/GCProjectSettingsReportsPtp";
import GcProjectSettingsReportsVisitors from "./visitors/GcProjectSettingsReportsVisitors";
import GcProjectSettingsLogisticPlans from "./logisticPlans/GcProjectSettingsLogisticPlans";
import GCProjectOrientationSettings from "../../../../../../../domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/GCProjectOrientationSettings";

const gcProjectSettingsRoutedMenu: RoutedMenuItem[] = [
  {
    path: "general",
    label: "General",
    element: <GCProjectSettingsGeneral />,
  },
  {
    path: "onsite",
    label: "Onsite",
    element: <GCProjectSettingsReportsGeneral />,
  },
  {
    path: "orientation",
    label: "Orientation",
    element: <GCProjectOrientationSettings />,
  },
  {
    path: "daily-reports",
    label: "Daily Reports",
    element: <GCProjectReportsDailySettings />,
  },
  {
    label: "TODOs",
    path: "todos",
    element: <GCProjectSettingsTodos />,
  },
  {
    path: "deliveries",
    label: "Deliveries",
    element: <GCProjectSettingsDeliveries />,
  },
  {
    label: "Logistic Plans",
    path: "logistic-plans",
    element: <GcProjectSettingsLogisticPlans />,
  },
  {
    path: "visitors",
    label: "Visitors",
    element: <GcProjectSettingsReportsVisitors />,
  },
  {
    path: "jha",
    label: "Job Hazard Analysis",
    element: <GCProjectSettingsJha />,
  },
  {
    path: "ptp",
    label: "Pre-Task Plans",
    element: <GCProjectSettingsReportsPtp />,
  },
  {
    label: "Observations",
    path: "observations",
    element: <GCProjectSettingsObservations />,
  },
  {
    label: "Incidents",
    path: "incidents",
    element: <GCProjectSettingsIncidents />,
  },
  {
    path: "permits",
    label: "Permits",
    element: <GCProjectSettingsReportsPermits />,
  },

  // {
  //   path: "tbt",
  //   label: "Toolbox Talks",
  //   element: <GcProjectSettingsReportsTbt />,
  // },
];

export default gcProjectSettingsRoutedMenu;
