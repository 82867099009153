import { Button, Card, Form, Select } from "antd";
import React, { FC, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GetFullID from "src/common/functions/GetFullId";
import { GCProjectLinking_AddOrientationProject_Mutation } from "src/common/types/generated/relay/GCProjectLinking_AddOrientationProject_Mutation.graphql";
import { GCProjectLinking_RemoveOrientationProject_Mutation } from "src/common/types/generated/relay/GCProjectLinking_RemoveOrientationProject_Mutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { useUpdateOrientationProjectSettingsMutationReturningValues } from "src/domain-features/siteorientation/utils/useUpdateOrientationProjectSettings";

const addOrientationProjectMutation = graphql`
  mutation GCProjectLinking_AddOrientationProject_Mutation(
    $_set: project_set_input
    $newLinkedProjectIds: [uuid!]!
  ) {
    update_project(_set: $_set, where: { id: { _in: $newLinkedProjectIds } }) {
      returning {
        name
        id
        orientation_project_id
      }
    }
  }
`;
const removeOrientationProjectMutation = graphql`
  mutation GCProjectLinking_RemoveOrientationProject_Mutation(
    $projectId: uuid!
  ) {
    update_project_by_pk(
      _set: { orientation_project_id: $projectId }
      pk_columns: { id: $projectId }
    ) {
      name
      id
      orientation_project_id
    }
  }
`;

interface GCProjectLinkingProps {
  projectId: string;
  params: useUpdateOrientationProjectSettingsMutationReturningValues;
  currentLinkedProjects: Array<{ name: string; id: string }>;
  orientationProjectOptions: Array<{ name: string; id: string }>;
}

const GCProjectLinking: FC<GCProjectLinkingProps> = ({
  projectId,
  currentLinkedProjects,
  orientationProjectOptions,
  params,
}) => {
  const [showSelection, setShowSelection] = useState(false);

  const [addOrientationProject] =
    useAsyncMutation<GCProjectLinking_AddOrientationProject_Mutation>(
      addOrientationProjectMutation,
    );
  const [removeOrientationProject] =
    useAsyncMutation<GCProjectLinking_RemoveOrientationProject_Mutation>(
      removeOrientationProjectMutation,
    );
  return (
    <Card title={"Link Orientation to Another Project"}>
      <div className="mb-1">
        Select the project to link to. This allows 2 or more projects to share
        an Orientation
        <div className="text-grey">
          For example your team is building 2 separate projects at the same
          time, but only providing one orientation for both projects (one
          orientation log). Anyone that completes orientation will be added to
          ALL linked projects.
        </div>
      </div>

      {showSelection ? (
        <Form
          initialValues={{
            selectedProjectIds: currentLinkedProjects.map((p) => p.id),
          }}
          onFinish={async (values) => {
            console.log(values);

            if (values.selectedProjectIds) {
              setShowSelection(false);

              const toRemoveLinkedProjects = currentLinkedProjects.filter(
                (p) =>
                  !values.selectedProjectIds.find((sp: string) => {
                    return sp === p.id;
                  }),
              );
              const newLinkedProjectIds = orientationProjectOptions.filter(
                (sp) =>
                  !currentLinkedProjects.find((p) => sp.id === p.id) &&
                  values.selectedProjectIds.includes(sp.id),
              );
              toRemoveLinkedProjects.map(
                async (p) =>
                  await removeOrientationProject({
                    variables: { projectId: p.id },
                    optimisticResponse: {
                      update_project_by_pk: {
                        id: GetFullID("project", p.id),
                        name: p.name,
                        orientation_project_id: p.id,
                      },
                    },
                  }),
              );
              await addOrientationProject({
                variables: {
                  newLinkedProjectIds: newLinkedProjectIds.map((p) => p.id),
                  _set: {
                    orientation_project_id: projectId,
                    //update settings
                    ...params,
                  },
                },
                optimisticResponse: {
                  update_project: {
                    returning: newLinkedProjectIds.map((p) => ({
                      id: GetFullID("project", p.id),
                      name: p.name,
                      orientation_project_id: projectId,
                    })),
                  },
                },
              });
            }
          }}
        >
          <Form.Item
            name="selectedProjectIds"
            label="Select the project to link to"
          >
            <Select
              placeholder="Select Shared Project"
              mode="multiple"
              className="w-3/5"
            >
              {orientationProjectOptions.map((o) => (
                <Select.Option key={o.id} value={o.id}>
                  {o.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex flex-row">
            <Button htmlType="submit" type="primary">
              Confirm
            </Button>
            <Button onClick={() => setShowSelection(false)}>Cancel</Button>
          </div>
        </Form>
      ) : (
        <div className="justify-start">
          {currentLinkedProjects.length > 0 && (
            <div className="mx-0.25">
              Current Linked projects:
              {currentLinkedProjects.map((p) => (
                <div
                  className="mx-0.25 inline-block mt-1 text-1 px-0.5 py-0.25 rounded-2 bg-interactive-light text-interactive-primary"
                  key={p.id}
                >
                  {p.name}
                </div>
              ))}
            </div>
          )}
          {/* <BPopconfirm
            title="Confirm you are linking to this Project's Orientation, the Orientation Logs will be identical. Are you sure?"
            onConfirm={}
          > */}
          <Button loading={false} onClick={() => setShowSelection(true)}>
            {currentLinkedProjects.length > 0 ? "Add" : "Select"}
          </Button>
          {/* </BPopconfirm> */}
        </div>
      )}
    </Card>
  );
};
export default GCProjectLinking;
