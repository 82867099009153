import { Menu } from "antd";
import useGetOrganizationUnitNavigationItems from "../../../hierarchy/organization-unit/utils/useGetOrganizationUnitNavigationItems";
import { GCOrganizationUnitType } from "../gcHierarchyTreeTypes";
import useGcEmployee from "../../../hierarchy/organization-unit/utils/useGcEmployee";
import useHierarchyMenuProps from "../utils/useHierarchyMenuProps";

interface GCNavigationHierarchyMenuOrganizationUnitProps {
  // TODO deprecate organizationUnitType
  organizationUnit: { id: string; name: string; type: GCOrganizationUnitType };
}

const GCNavigationHierarchyMenuOrganizationUnit: React.FC<
  GCNavigationHierarchyMenuOrganizationUnitProps
> = ({ organizationUnit }) => {
  const gc = useGcEmployee().general_contractor;
  const organizationUnitNavigationItems = useGetOrganizationUnitNavigationItems(
    organizationUnit.type,
  );
  if (!organizationUnit) throw new Error("Organization unit not found");

  const basePath = `/gce/hierarchy/organization-unit/${organizationUnit.type}/${organizationUnit.id}`;

  const menuProps = useHierarchyMenuProps(
    organizationUnitNavigationItems,
    basePath,
    gc[`hierarchy_${organizationUnit.type}_name`] ?? undefined,
  );

  return <Menu {...menuProps} />;
};

export default GCNavigationHierarchyMenuOrganizationUnit;
