import SwitchWithText from "../../../../../../../../common/components/SwitchWithText";
import GetFullID from "../../../../../../../../common/functions/GetFullId";
import { message } from "antd";
import React from "react";
import useAsyncMutation from "../../../../../../../../common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import ProjectIdProps from "../../../../../../../../common/types/manual/ProjectIdProps";
import { useLazyLoadQuery } from "react-relay/hooks";
import { GCProjectSettingsWeeklyEmailQuery } from "src/common/types/generated/relay/GCProjectSettingsWeeklyEmailQuery.graphql";
import { GCProjectSettingsWeeklyEmail_updateProjects_Mutation } from "src/common/types/generated/relay/GCProjectSettingsWeeklyEmail_updateProjects_Mutation.graphql";

const GCProjectSettingsWeeklyEmail: React.FC<ProjectIdProps> = (props) => {
  const data = useLazyLoadQuery<GCProjectSettingsWeeklyEmailQuery>(
    graphql`
      query GCProjectSettingsWeeklyEmailQuery($projectId: uuid!) {
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              id
              morning_man_power_report_email_time
              timezone
              project_setting {
                id
                send_weekly_email
                require_gc_to_mark_observation_complete
              }
            }
          }
        }
      }
    `,
    {
      projectId: props.projectId,
    },
  );

  const sendWeeklyEmailIsOn =
    data.project_connection.edges[0].node.project_setting?.send_weekly_email;
  const [updateProject] =
    useAsyncMutation<GCProjectSettingsWeeklyEmail_updateProjects_Mutation>(graphql`
      mutation GCProjectSettingsWeeklyEmail_updateProjects_Mutation(
        $id: uuid!
        $_set: project_setting_set_input!
      ) {
        update_project_setting_by_pk(
          pk_columns: { project_id: $id }
          _set: $_set
        ) {
          id
          send_weekly_email
        }
      }
    `);

  return (
    <div>
      <SwitchWithText
        //disabled={!has_auth_data}
        text="Turn ON weekly project email"
        onChange={(checked) => {
          updateProject({
            variables: {
              id: props.projectId,
              _set: { send_weekly_email: checked },
            },
            optimisticResponse: {
              update_project_setting_by_pk: {
                id: GetFullID("project_setting", props.projectId),
                send_weekly_email: checked,
              },
            },
          })
            .then(() => message.success(checked ? "ON" : "OFF"))
            .catch((e) => {
              throw e;
            });
        }}
        checked={!!sendWeeklyEmailIsOn}
      />
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectSettingsWeeklyEmail);
