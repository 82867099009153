import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import GCOrgUnitUsersCommon from "../components/GCOrgUnitUsersCommon";

interface GCOrgUnitAdminsProps extends OrganizationUnitProps {}

const GCOrgUnitAdmins: React.FC<GCOrgUnitAdminsProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  return (
    <GCOrgUnitUsersCommon
      {...{
        organizationUnitId,
        organizationUnitType,
        pageTitle: "Admins",
        includeChildNodeEmployees: false,
      }}
    />
  );
};

export default withOrganizationUnitWrapper(GCOrgUnitAdmins);
