import { useUserData } from "../../../../../../../utility-features/authorization/UserDataProvider";

const useGcEmployee = () => {
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("employee not found");
  return employee;
};

export default useGcEmployee;
