import dayjs from "dayjs";
import { useMemo } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { useGetReportManagementDataQuery } from "src/common/types/generated/relay/useGetReportManagementDataQuery.graphql";

const query = graphql`
  query useGetReportManagementDataQuery($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }, first: 1)
      @connection(
        key: "useGetReportManagementDataQuery_project_connection"
        filters: []
      ) {
      edges {
        node {
          id
          timezone
          dashboard_updated_at
          is_project_reporting_active
          pause_dash_notification_start_date
          pause_dash_notification_end_date
          timezone
          mark_sub_onsite_on_report_submission
          project_subcontractors(order_by: { subcontractor: { name: asc } }) {
            subcontractor_id
            subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            id
            pk: id @__clientField(handle: "pk")
            project_subcontractor_onsite_periods(
              where: { close_date: { _is_null: true } }
            ) {
              id
            }
            project_subcontractor_safety_report_not_require_periods(
              where: { close_date: { _is_null: true } }
            ) {
              id
            }
            project_subcontractor_daily_report_not_require_periods(
              where: { close_date: { _is_null: true } }
            ) {
              id
            }
            project_subcontractor_toolbox_talk_not_require_periods(
              where: { close_date: { _is_null: true } }
            ) {
              id
            }

            project_crews(order_by: { name: asc }) {
              id
              name
              deleted_at
              pk: id @__clientField(handle: "pk")
              lead_foreman_project_worker {
                user {
                  name
                }
              }
              crew_onsite_periods(where: { close_date: { _is_null: true } }) {
                id
              }
              crew_safety_report_not_require_periods(
                where: { close_date: { _is_null: true } }
              ) {
                id
              }
              crew_daily_report_not_require_periods(
                where: { close_date: { _is_null: true } }
              ) {
                id
              }
              crew_toolbox_talk_not_require_periods(
                where: { close_date: { _is_null: true } }
              ) {
                id
              }
            }
          }
        }
      }
    }
  }
`;
const useGetReportManagementData = (projectId: string) => {
  const data = useLazyLoadQuery<useGetReportManagementDataQuery>(
    query,
    {
      projectId,
    },
    { fetchPolicy: "store-or-network" },
  );

  const project = data.project_connection.edges[0]?.node;
  if (!project) throw new Error("project not found for given projectid");

  const crewLeads: { [id: string]: string } = {};
  const subData = useMemo(
    () =>
      project.project_subcontractors.map((ps) => {
        return {
          subId: ps.subcontractor.pk,
          projectSubId: ps.pk,
          subName: ps.subcontractor.name,
          safety_reports_required:
            !ps.project_subcontractor_safety_report_not_require_periods.length,
          toolbox_talks_required:
            !ps.project_subcontractor_toolbox_talk_not_require_periods.length,
          daily_reports_required:
            !ps.project_subcontractor_daily_report_not_require_periods.length,
          onsite: !!ps.project_subcontractor_onsite_periods.length,
          crews: ps.project_crews.map((pcrew) => {
            if (pcrew.lead_foreman_project_worker)
              crewLeads[pcrew.pk] =
                pcrew.lead_foreman_project_worker.user!.name;
            return {
              crewName: pcrew.name,
              crewLead: pcrew.lead_foreman_project_worker?.user?.name,
              crewId: pcrew.pk,
              safety_reports_required:
                !pcrew.crew_safety_report_not_require_periods.length,
              toolbox_talks_required:
                !pcrew.crew_toolbox_talk_not_require_periods.length,
              daily_reports_required:
                !pcrew.crew_daily_report_not_require_periods.length,
              onsite: !!pcrew.crew_onsite_periods.length,
              deleted_at: pcrew.deleted_at,
            };
          }),
        };
      }),
    [[project.project_subcontractors]],
  );

  return {
    crewLeads,
    project,
    dashboardStart: dayjs(project.dashboard_updated_at),
    subOnsiteData: subData,
  };
};
export default useGetReportManagementData;
