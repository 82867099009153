import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import daysOfMonth from "src/common/functions/dates/daysOfMonth";
import { GCProjectReportsDailyBodyQuery } from "src/common/types/generated/relay/GCProjectReportsDailyBodyQuery.graphql";
import { useBoolean } from "usehooks-ts";
import GCProjectReportsDailySummary, {
  gcDailyReportsSummaryProps,
  gcMonthlyReportsSummaryProps,
  GCProjectReportsDailySummaryProps,
} from "./summary/GCProjectReportsDailySummary";
import ErrorMessage from "../../../../../utility-features/error-handling/components/ErrorMessage";
import GCProjectReportsDailyMonth from "./month/GCProjectReportsDailyMonth";
import GCDownloadMonthlySummaryModal from "./month/GCProjectReportsDailyMonthDownloadModal";
import GCProjectReportsDailyMonthDownloadChart from "./month/GCProjectReportsDailyMonthDownloadChart";
import GCProjectDailyWorkLogContext from "../GCProjectDailyWorkLogContext";

const query = graphql`
  query GCProjectReportsDailyBodyQuery(
    $projectId: uuid!
    $startDate: date!
    $endDate: date!
    $startTime: timestamptz!
    $endTime: timestamptz!
  ) {
    tag_connection(where: { project_id: { _eq: $projectId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          color_hex
          name {
            pk: id @__clientField(handle: "pk")
            en
          }
        }
      }
    }
    daily_work_log_connection(
      order_by: { date: desc }
      where: {
        project_id: { _eq: $projectId }
        date: { _lte: $endDate, _gte: $startDate }
        is_deleted: { _eq: false }
      }
    ) {
      edges {
        node {
          date
          submitted_at
          created_at
          subcontractor_id
          worker_hours
          worker_count
          images(order_by: { sort_index: asc }) {
            id
            url
            created_at
          }
          description {
            ...TextTranslationFrag @relay(mask: false)
          }
          daily_work_log_tags {
            id
            pk: id @__clientField(handle: "pk")
            tag {
              id
              pk: id @__clientField(handle: "pk")
              color_hex
              name {
                pk: id @__clientField(handle: "pk")
                en
              }
            }
          }
          daily_work_log_comments {
            pk: id @__clientField(handle: "pk")
            text {
              en
            }
          }
          daily_work_log_tags {
            id
          }
          daily_work_log_comments {
            id
          }
          subcontractor {
            name
          }
          general_contractor {
            id
            name
          }
          daily_weather_observations {
            id
          }
          created_by_project_user {
            project_worker {
              user {
                name
              }
            }
            employee {
              user {
                name
              }
            }
          }
          daily_work_log_workers {
            hours
            project_worker {
              worker_id
            }
          }
        }
      }
    }
    daily_safety_report_connection(
      first: 10000
      order_by: { date: desc }
      where: {
        project_id: { _eq: $projectId }
        date: { _lte: $endDate, _gte: $startDate }
      }
    )
      @connection(
        key: "GCProjectReportsDaily_daily_safety_report_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          created_at
          date
          created_by_user {
            id
            name
            pk: id @__clientField(handle: "pk")
          }
          subcontractor {
            name
            pk: id @__clientField(handle: "pk")
          }
          images(order_by: { sort_index: asc }) {
            id
            url
            created_at
          }
          daily_safety_report_tags {
            id
            pk: id @__clientField(handle: "pk")
            tag {
              ...TagFrag @relay(mask: false)
            }
          }
          daily_safety_report_comments {
            id
            pk: id @__clientField(handle: "pk")
            text_translation {
              en
            }
          }
        }
      }
    }
    delivery_connection(
      where: {
        project_id: { _eq: $projectId }
        start_at: { _gte: $startTime, _lte: $endTime }
        status: { _in: ["Approved", "Pending"] }
      }
    ) {
      edges {
        node {
          id
          subcontractor_id
          start_at
          name {
            en
          }
          detail {
            en
          }
          storage_location {
            en
          }
          user {
            name
          }
          subcontractor {
            name
          }
        }
      }
    }
    report_connection(
      where: {
        project_id: { _eq: $projectId }
        created_at: { _gte: $startTime, _lte: $endTime }
      }
    ) {
      edges {
        node {
          id
          created_at
          subcontractor_id
          subcontractor {
            name
          }
        }
      }
    }
    daily_schedule_event_connection(
      where: {
        project_id: { _eq: $projectId }
        date: { _lte: $endDate, _gte: $startDate }
      }
    ) {
      edges {
        node {
          id
          date
          created_at
          daily_schedule_event_subs {
            subcontractor_id
            subcontractor {
              name
            }
          }
        }
      }
    }
    daily_note_connection(
      where: {
        project_id: { _eq: $projectId }
        date: { _lte: $endDate, _gte: $startDate }
      }
    ) {
      edges {
        node {
          text {
            en
          }
          created_by_employee {
            user {
              pk: id @__clientField(handle: "pk")
              name
            }
          }
          pk: id @__clientField(handle: "pk")
          date
          daily_note_subs {
            pk: id @__clientField(handle: "pk")
            subcontractor {
              name
              pk: id @__clientField(handle: "pk")
            }
          }
          daily_note_tags {
            pk: id @__clientField(handle: "pk")
            tag {
              id
              pk: id @__clientField(handle: "pk")
              name {
                pk: id @__clientField(handle: "pk")
                en
              }
              color_hex
            }
          }
        }
      }
    }
    toolbox_talk_connection(
      where: {
        project_id: { _eq: $projectId }
        created_at: { _gte: $startTime, _lte: $endTime }
      }
    ) {
      edges {
        node {
          id
          created_at
          subcontractor_id
          subcontractor {
            name
          }
        }
      }
    }
    permit_connection(
      where: {
        project_id: { _eq: $projectId }
        created_at: { _gte: $startTime, _lte: $endTime }
        current_status: { _nin: ["submitted", "resubmitted"] }
        is_historical: { _eq: true }
      }
    ) {
      edges {
        node {
          id
          created_at
          subcontractor_id
          subcontractor {
            name
          }
        }
      }
    }
    project_subcontractor_connection(
      where: { project_id: { _eq: $projectId } }
      order_by: { subcontractor: { name: asc } }
    ) {
      edges {
        node {
          subcontractor_id
          subcontractor {
            name
          }
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project: { linked_orientation_projects: { id: { _eq: $projectId } } }
        }
        _or: [
          {
            project: {
              linked_orientation_projects: { id: { _eq: $projectId } }
            }
          }
          {
            project_id: { _is_null: true }
            general_contractor: { projects: { id: { _eq: $projectId } } }
          }
        ]
      }
      first: 1
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          id
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          in_person_orientation
          assign_hard_hat
        }
      }
    }
    project_worker_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_worker: {}
        user_orientation: {
          _or: [
            { completed_at: { _gte: $startTime, _lte: $endTime } }
            { orientated_at: { _gte: $startTime, _lte: $endTime } }
            { in_person_orientated_at: { _gte: $startTime, _lte: $endTime } }
          ]
          _not: {
            _and: [
              { completed_at: { _is_null: true } }
              { orientated_at: { _is_null: true } }
              { in_person_orientated_at: { _is_null: true } }
            ]
          }
        }
      }
    ) {
      edges {
        node {
          id
          hard_hat_number
          user_orientation {
            orientated_at
            completed_at
            in_person_orientated_at
          }
        }
      }
    }
    project_visitor_certification_connection(
      where: {
        created_at: { _lte: $endTime, _gte: $startTime }
        project_id: { _eq: $projectId }
      }
    ) {
      edges {
        node {
          id
          hours_planning_to_stay
          created_at
        }
      }
    }
  }
`;

export interface GCProjectReportsDailyBodyProps {
  projectId: string;
  selectedTagIds: string[];
  onSelectedTagIdsChange: (tags: string[]) => void;
  selectedSubIds: string[];
  onSelectedSubIdsChange: (subIds: string[]) => void;
  startMonth: dayjs.Dayjs;
  endMonth: dayjs.Dayjs;
  searchQuery: string;
}

const today = dayjs();

const GCProjectReportsDailyBody: React.FC<GCProjectReportsDailyBodyProps> = (
  props,
) => {
  const [monthForDownload, setMonthForDownload] = useState(dayjs());
  const [subIdForDownload, setSubIdForDownload] = useState<string>();

  const [workerImageUrl, setWorkerImageUrl] = useState<string>("");
  const [manhourImageUrl, setManhourImageUrl] = useState<string>("");
  const [preparingChart, setPreparingChart] = useState(false);
  const {
    setFalse: hideDownloadModal,
    setTrue: showDownloadModal,
    value: downloadModalVisible,
  } = useBoolean(false);

  const handleTagFilterChange = (tagId: string, tagChecked: boolean) => {
    const newSelectedTagIds = props.selectedTagIds.filter((id) => id !== tagId);
    if (tagChecked) newSelectedTagIds.push(tagId);
    props.onSelectedTagIdsChange(newSelectedTagIds);
  };

  const handleSubcontractorFilterChange = (
    subcontractorId: string,
    subcontractorChecked: boolean,
  ) => {
    const newSelectedsubcontractorIds = props.selectedSubIds.filter(
      (id) => id !== subcontractorId,
    );
    if (subcontractorChecked) newSelectedsubcontractorIds.push(subcontractorId);
    props.onSelectedSubIdsChange(newSelectedsubcontractorIds);
  };

  const isDataPresentInSummary = (
    currentSummary: GCProjectReportsDailySummaryProps,
  ) => {
    return !(
      currentSummary.entries === 0 &&
      currentSummary.workers === 0 &&
      currentSummary.deliveries.length === 0 &&
      currentSummary.manHours === 0 &&
      currentSummary.permits === 0 &&
      currentSummary.photos.length === 0 &&
      currentSummary.ptps === 0 &&
      currentSummary.subcontractors === 0 &&
      currentSummary.weather === 0 &&
      currentSummary.schedule === 0 &&
      currentSummary.workersOriented === 0 &&
      currentSummary.tags.length === 0 &&
      currentSummary.tbts === 0
    );
  };

  const data = useLazyLoadQuery<GCProjectReportsDailyBodyQuery>(query, {
    projectId: props.projectId,
    startDate: dayjs(props.startMonth).startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs(props.endMonth).endOf("month").format("YYYY-MM-DD"),
    startTime: dayjs(props.startMonth).startOf("month").format(),
    endTime: dayjs(props.endMonth).endOf("month").format(),
  });

  const projectData = data.project_connection.edges[0].node;
  const hasInPerson = projectData.in_person_orientation;
  const assignHardHat = projectData.assign_hard_hat;
  const hasSlides = data.orientation_connection.edges.length > 0;
  let tempMonth = dayjs(props.endMonth);
  const days: Map<string, dayjs.Dayjs[]> = new Map();
  const finalSummaryMap: Map<string, GCProjectReportsDailySummaryProps[]> =
    new Map();
  const finalDayMap: Map<string, dayjs.Dayjs[]> = new Map();
  while (tempMonth.isSameOrAfter(props.startMonth)) {
    days.set(
      tempMonth.format(),
      daysOfMonth(tempMonth)
        .reverse()
        .filter((day) => day.isSameOrBefore(today, "day")),
    );
    tempMonth = tempMonth.subtract(1, "month");
  }
  const subcontractors = data.project_subcontractor_connection.edges.map(
    (s) => ({ name: s.node.subcontractor.name, id: s.node.subcontractor_id }),
  );
  [...days.keys()].forEach((key) => {
    const tempSummaryList: GCProjectReportsDailySummaryProps[] = [];
    const tempDayList: dayjs.Dayjs[] = [];
    days.get(key)?.forEach((day) => {
      const currentSummary = gcDailyReportsSummaryProps(
        data,
        day,
        hasInPerson,
        hasSlides,
        assignHardHat,
        handleTagFilterChange,
        handleSubcontractorFilterChange,
        props.selectedTagIds,
        props.selectedSubIds,
        props.searchQuery,
      );
      if (
        (props.searchQuery.length > 0 && currentSummary.entries > 0) ||
        (props.searchQuery.length === 0 &&
          isDataPresentInSummary(currentSummary))
      ) {
        tempDayList.push(day);
        tempSummaryList.push(currentSummary);
      }
    });
    if (tempDayList.length > 0) {
      finalDayMap.set(key, tempDayList);
      finalSummaryMap.set(key, tempSummaryList);
    }
  });

  // TODO: split gcMonthlyReportsSummaryProps into parts
  const dailySummaryProps = useMemo(
    () =>
      gcMonthlyReportsSummaryProps(
        data,
        hasInPerson,
        hasSlides,
        assignHardHat,
        handleTagFilterChange,
        handleSubcontractorFilterChange,
        props.selectedTagIds,
        props.selectedSubIds,
        props.searchQuery,
      ),
    [
      data,
      hasInPerson,
      hasSlides,
      assignHardHat,
      handleTagFilterChange,
      handleSubcontractorFilterChange,
      props.selectedTagIds,
      props.selectedSubIds,
      props.searchQuery,
    ],
  );

  return (
    <GCProjectDailyWorkLogContext.Provider
      value={{ data, projectId: props.projectId }}
    >
      <GCDownloadMonthlySummaryModal
        defaultMonth={monthForDownload}
        projectId={props.projectId}
        onMonthChange={setMonthForDownload}
        workerImageUrl={workerImageUrl}
        manhourImageUrl={manhourImageUrl}
        visible={downloadModalVisible}
        hide={hideDownloadModal}
        preparingChart={preparingChart}
        subcontractors={{
          all: subcontractors,
          selected: subIdForDownload ?? undefined,
          selectSubcontractor: setSubIdForDownload,
        }}
      />

      <div className="flex flex-col w-full gap-0 mt-3 h-fit">
        <div className={`flex flex-row items-center justify-center w-full`}>
          <GCProjectReportsDailySummary {...dailySummaryProps} />
        </div>
        <div className={`flex mt-6 flex-col gap-1`}>
          {(() => {
            const monthKeysArr = [...finalDayMap.keys()];
            return !!monthKeysArr.length ? (
              monthKeysArr.map((key) => {
                const monthDays: GCProjectReportsDailyMonth["days"] = [];

                finalDayMap.get(key)?.forEach((day, dayIndex) => {
                  const monthDaySummaries = finalSummaryMap.get(key);
                  if (!monthDaySummaries) return;
                  const monthDaySummary = monthDaySummaries[dayIndex];
                  if (monthDaySummary) {
                    monthDays.push({
                      day,
                      summary: monthDaySummary,
                    });
                  }
                });

                return (
                  <GCProjectReportsDailyMonth
                    key={key}
                    month={dayjs(key)}
                    days={monthDays ?? []}
                    onDownloadMonthClick={() => {
                      const month = dayjs(key);
                      setMonthForDownload(month);
                      showDownloadModal();
                    }}
                    searchQuery={props.searchQuery}
                    projectId={props.projectId}
                    selectedTagIds={props.selectedTagIds}
                    selectedSubcontractorIds={props.selectedSubIds}
                  />
                );
              })
            ) : (
              <ErrorMessage message={`No entries found`} />
            );
          })()}
        </div>
      </div>
      {downloadModalVisible && (
        <GCProjectReportsDailyMonthDownloadChart
          {...{
            data,
            pickedMonth: monthForDownload,
            setManhourImageUrl,
            setWorkerImageUrl,
            subIdForDownload,
            selectedSubId: subIdForDownload,
            setPreparingChart,
          }}
        />
      )}
    </GCProjectDailyWorkLogContext.Provider>
  );
};

export default withCustomSuspense(GCProjectReportsDailyBody);
