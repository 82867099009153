import GCOrgUnitOffices from "../GCOrgUnitOffices";
import useGcEmployee from "../utils/useGcEmployee";
import CustomTabs, {
  CustomTabsProps,
} from "../../../../../../../common/components/layouts/tabs/CustomTabs";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";
import GCAllBuLevelChilds from "../../components/child-levels/GCAllBuLevelChilds";
import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import GCChildUnitsExplainerText from "../../component/GCChildUnitsExplainerText";

const GCOrgUnitChildUnits: React.FC<OrganizationUnitProps> = (props) => {
  const employee = useGcEmployee();
  const gc = employee.general_contractor;
  const { subview } = useParams();
  const navigate = useNavigate();

  const onTabSelect = (tabId: string) => {
    navigate("./" + tabId);
  };

  const tabs: CustomTabsProps["tabs"] = [
    // TODO unify the tabs to any hierarchy levels
    ...(props.organizationUnitType === "division" &&
    gc.hierarchy_business_unit_name
      ? [
          {
            label: gc.hierarchy_business_unit_name,
            id: "business-units",
            content: (
              <GCAllBuLevelChilds
                {...{
                  addNewLevelInitialVals: {
                    divisionId: props.organizationUnitId,
                  },
                  divisionWhere: { id: { _eq: props.organizationUnitId } },
                  buWhere: {
                    gc_division_id: { _eq: props.organizationUnitId },
                  },
                }}
              />
            ),
          },
        ]
      : []),
    ...((props.organizationUnitType === "division" ||
      props.organizationUnitType === "business_unit") &&
    gc.hierarchy_office_name
      ? [
          {
            label: gc.hierarchy_office_name,
            id: "offices",
            content: (
              <GCOrgUnitOffices
                organizationUnitId={props.organizationUnitId}
                organizationUnitType={props.organizationUnitType}
              />
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (!subview && tabs.length > 0) {
      navigate(tabs[0].id);
    }
  }, []);

  // TODO display something like "No child levels" if this level doesn't have any sub levels
  return (
    <CustomTabs selectedTabId={subview} tabs={tabs} onTabSelect={onTabSelect} />
  );
};

export default withAppPageLayout(
  withOrganizationUnitWrapper(GCOrgUnitChildUnits),
  {
    pageTitle: "Child Units",
    pageExplainer: <GCChildUnitsExplainerText />,
  },
);
