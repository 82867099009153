import withOrganizationUnitWrapper, {
  OrganizationUnitProps,
} from "../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import GCBusinessUnitIncidents from "../levels/business-unit/incident/GCBusinessUnitIncidents";
import GCDivisionIncidents from "../levels/division/incident/GCDivisionIncidents";
import GCOfficeIncidents from "../levels/office/incident/GCOfficeIncidents";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";

const GCOrgUnitIncidents: React.FC<OrganizationUnitProps> = ({
  organizationUnitId,
  organizationUnitType,
}) => {
  switch (organizationUnitType) {
    case "division":
      return <GCDivisionIncidents divisionId={organizationUnitId} />;
    case "business_unit":
      return <GCBusinessUnitIncidents businessUnitId={organizationUnitId} />;
    case "office":
      return <GCOfficeIncidents officeId={organizationUnitId} />;
  }
};

export default withAppPageLayout(
  withOrganizationUnitWrapper(GCOrgUnitIncidents),
  { pageTitle: "Incidents" },
);
