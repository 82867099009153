import { Input, Select, message } from "antd";
import React, { useContext, useRef, useState } from "react";

import visitorOrientationOrienteeContext from "src/domain-features/sitesafety/entry-routes/visitor/utils/visitorOrientationOrienteeContext";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import onEnterDown from "src/domain-features/siteorientation/utils/onEnterDown";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";
import useSiteVisitor from "../../utils/siteVisitorHook";

export interface VisitorOrientationStepsCompanyProps
  extends SiteOrientationStepProps {}

const VisitorOrientationStepsCompany: React.FC<
  VisitorOrientationStepsCompanyProps
> = (props) => {
  const langStrings = useCurrentLangStrings();
  const visitorContext = useSiteVisitor();
  const visitor = visitorContext.visitor;
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
    visitor
      ? visitor.generalContractorId ||
          visitor.subcontractorId ||
          visitor.oacCompanyId ||
          visitor.visitorCompanyId ||
          (visitor.visitorCompanyName ? "other" : undefined)
      : undefined,
  );
  const gcNames: { id: string; name: string }[] = [];
  const subNames: { id: string; name: string }[] = [];
  const oacNames: { id: string; name: string }[] = [];
  const vcNames: { id: string; name: string }[] = [];
  const companyType = useRef<{ [key: string]: string }>({});
  visitorContext.companies.forEach((element) => {
    if (element.type == "gc")
      gcNames.push({ id: element.id, name: element.name });
    else if (element.type == "sub")
      subNames.push({ id: element.id, name: element.name });
    else if (element.type == "oac")
      oacNames.push({ id: element.id, name: element.name });
    else vcNames.push({ id: element.id, name: element.name });

    companyType.current[element.id] = element.type;
  });

  vcNames.push({ id: "other", name: "Other" });
  companyType.current["other"] = "Other";
  const companyNames = [
    { name: "General Contractors", list: gcNames },
    { name: "Subcontractors", list: subNames },
    { name: " OAE Companies", list: oacNames },
    { name: "Other Companies", list: vcNames },
  ];
  const [newCompany, setnewCompany] = useState<string | undefined>(
    !visitor?.visitorCompanyId ? visitor?.visitorCompanyName : undefined,
  );
  const [isOther, setIsOther] = useState<boolean>(
    !visitor?.visitorCompanyId && !!visitor?.visitorCompanyName,
  );
  const onOtheCompanyEnter = (newCompanyName: string) => {
    const changes = {
      visitorCompanyName: newCompanyName,
      generalContractorId: undefined,
      subcontractorId: undefined,
      oacCompanyId: undefined,
      visitorCompanyId: undefined,
    };
    visitorContext?.updateVisitor &&
      visitorContext.updateVisitor((u) =>
        u ? { ...u, ...changes } : { ...changes },
      );
    props.onNext();
  };
  const onEnter = (companyId: string) => {
    const selectedCompanyType = companyType.current[companyId];
    const companySet = {
      subcontractorId: selectedCompanyType == "sub" ? companyId : undefined,
      generalContractorId: selectedCompanyType == "gc" ? companyId : undefined,
      oacCompanyId: selectedCompanyType == "oac" ? companyId : undefined,
      visitorCompanyId: selectedCompanyType == "vc" ? companyId : undefined,
      visitorCompanyName: undefined,
    };
    visitorContext?.updateVisitor &&
      visitorContext.updateVisitor((u) =>
        u ? { ...u, ...companySet } : { ...companySet },
      );

    props.onNext();
  };
  return (
    <SiteFeatureStepLayout
      nextButtonDisabled={
        !selectedCompany || (selectedCompany === "other" && !newCompany?.trim())
      }
      onNextButtonClick={() => {
        if (selectedCompany === "other" && newCompany && newCompany.trim()) {
          onOtheCompanyEnter(newCompany.trim());
        } else if (selectedCompany) onEnter(selectedCompany);
        else message.error("No Selected company found");
      }}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whoDoyYouWorkFor}
      >
        <p className="text-grey text-1">
          {langStrings.strings.ifYouDoNotSeeYourCompanyContactYourGCOrOffice}
        </p>
        <Select
          virtual={false}
          placeholder={langStrings.strings.selectYourCompany}
          onChange={async (v, op) => {
            if (companyType.current[v] == "Other") {
              setIsOther(true);
              setSelectedCompany("other");
              return;
            }
            setSelectedCompany(v);
            onEnter(v);
          }}
          value={selectedCompany}
        >
          {companyNames.map(({ name, list }) => (
            <Select.OptGroup key={name} label={name}>
              {list.map(({ id, name }) => (
                <Select.Option key={id} value={id} className="text-1">
                  <span translate={`no`}>{name}</span>
                </Select.Option>
              ))}
              {}
            </Select.OptGroup>
          ))}
        </Select>
        {isOther ? (
          <Input
            value={newCompany}
            placeholder="Company Name"
            onKeyDown={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/(\b[a-z](?!\s))/g, (c) =>
                c.toUpperCase(),
              );
              if (e.key === "Enter")
                onEnterDown(e, () => onOtheCompanyEnter(input.value));

              // if (input.value) setNamePresent(() => input.value);
            }}
            onChange={(e) => {
              setnewCompany(e.target.value);
            }}
          />
        ) : (
          ""
        )}
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default VisitorOrientationStepsCompany;
