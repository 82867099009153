import {
  IconArrowBackUp,
  IconLockOpen,
  IconMinus,
  IconPlus,
  IconSend,
  IconStar,
  IconUser,
  IconUserMinus,
  IconUserX,
} from "@tabler/icons";
import React from "react";
import Button from "src/common/components/general/button/Button";
import ContactLink from "src/common/components/general/ContactLink";
import Image from "src/common/components/general/images/Image";
import { message, Modal } from "antd";
import {
  GetUserHierarchyProjectsDocument,
  GetUserHierarchyProjectsQuery,
  GetUserHierarchyProjectsQueryVariables,
  useForgotPasswordMutation,
  useUpdateUserHierarchyTeamsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import useAuthUser from "src/common/hooks/useAuthUser";

const textData = {
  active: "Active",
  inactive: "Inactive",
};

export interface GCProjectTeamListItemProps {
  name: string;
  userId: string;
  generalContractorId: string;
  isCorporateAdmin: boolean;
  onRemoveFromTeam?: (userId: string) => Promise<void>;
  dataId?: string;
  avatarUrl?: string;
  title?: string;
  email?: string;
  phoneNumber?: string;
  loading: boolean;
  created_password: boolean;
  reSendInvite: () => void;
  terminatedAt?: string;
  type: "hierarchy" | "project";
  isAdmin?: boolean;
  status?: {
    active: boolean;
    onDeactivate: () => void;
    onActivate: () => void;
  };
}

const GCProjectTeamListItem: React.FC<GCProjectTeamListItemProps> = (props) => {
  const client = useApolloClient();
  const { confirm } = Modal;
  const [forgotPassword] = useForgotPasswordMutation();
  const authUser = useAuthUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const { section } = useParams();
  const [updateUserHierarchyTeams] = useUpdateUserHierarchyTeamsMutation();

  const sendResetPasswordLink = async () => {
    if (!props.email) {
      message.error("Email is missing");
      return;
    }

    await forgotPassword({
      variables: {
        input: {
          loginIdentifier: props.email,
        },
      },
    });
    message.success("Sent Successfully");
  };

  const activateOrDeactivateUser = async () => {
    const { data } = await client.query<
      GetUserHierarchyProjectsQuery,
      GetUserHierarchyProjectsQueryVariables
    >({
      query: GetUserHierarchyProjectsDocument,
      variables: {
        gcId: props.generalContractorId,
        userId: props.userId,
        includeAllGcProjects: !!props.isCorporateAdmin,
      },
    });

    const projectIds = props.isCorporateAdmin
      ? (data.general_contractor_project || []).map(
          (gcProject) => gcProject.project_id,
        )
      : (data.general_contractor_employee || []).flatMap((gcEmployee) => [
          ...(gcEmployee.gc_business_units || []).flatMap((gcBu) =>
            gcBu.gc_business_unit.projects.map((proj) => proj.id),
          ),
          ...(gcEmployee.gc_divisions || []).flatMap((gcBu) =>
            gcBu.gc_division.projects.map((proj) => proj.id),
          ),
          ...(gcEmployee.gc_offices || []).flatMap((gcBu) =>
            gcBu.gc_office.projects.map((proj) => proj.id),
          ),
        ]);

    await updateUserHierarchyTeams({
      variables: {
        userId: props.userId,
        isReactivation: !!props.terminatedAt,
        userUpdates: {
          terminated_at: !!props.terminatedAt ? null : dayjs().format(),
          terminated_by_uid: !!props.terminatedAt ? null : authUser.uid,
        },
        projectEmployeeUpdates: {
          status: !!props.terminatedAt ? "Active" : "Inactive",
        },
        projectEmployees: projectIds.map((projId) => ({
          project_id: projId,
          employee_id: props.userId,
          direct_project_assign: false,
        })),
      },
      optimisticResponse: {
        update_user: {
          affected_rows: 1,
          returning: [
            {
              id: props.userId,
              terminated_at: !!props.terminatedAt ? null : dayjs().format(),
              terminated_by_uid: !!props.terminatedAt ? null : authUser.uid,
            },
          ],
        },
        insert_project_employee: { affected_rows: 1 },
        update_project_employee: { affected_rows: 1 },
      },
    });
  };

  const showResetPasswordConfirm = () => {
    confirm({
      title: "Send Reset Password Link?",
      okText: "Yes",
      content: "",
      onOk: sendResetPasswordLink,
    });
  };

  const subview = searchParams.get("subview");

  return (
    <div className="flex flex-row items-center gap-1 mr-2">
      {/* flex flex-row items-center mt-2 gap-1 */}

      <div className="flex flex-row items-center flex-1 border gap-1 rounded-2 border-suplementary-1 bg-suplementary-1">
        <div className="w-3 h-3 overflow-hidden rounded-2">
          <Image alt={props.name} icon={IconUser} src={props.avatarUrl} />
        </div>
        <div className="py-0.25 pr-1 pl-0.5 flex flex-1 flex-row ">
          <div className="flex flex-col items-start flex-1">
            <div className="flex items-center gap-2">
              <span className="block font-accent">{props.name}</span>
            </div>

            {!!props.title && (
              <span className="block text-0.75">{props.title}</span>
            )}
          </div>

          <div>
            {(!!props.phoneNumber || !!props.email) && (
              <div className="flex flex-row items-center gap-0.5 ">
                <div>
                  {props.isAdmin && section !== "admins" && (
                    <Button
                      secondary
                      fake
                      label=""
                      icon={IconUser}
                      large
                      hint={"Admin"}
                      secondaryIcon={IconStar}
                    />
                  )}
                </div>
                <div>
                  {!!props.email && (
                    <ContactLink hideValue type="email" value={props.email} />
                  )}
                </div>
                <div>
                  {!!props.phoneNumber && (
                    <ContactLink
                      hideValue
                      type="phone-number"
                      value={props.phoneNumber}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {props.terminatedAt ? (
        <>
          <div className="flex items-center justify-center w-16 px-1 py-0.5 rounded-2 bg-suplementary-1 font-bold">
            <span>
              <b>Deactivated</b>{" "}
              {dayjs(props.terminatedAt).format("MMM DD, YYYY")}
            </span>
          </div>
        </>
      ) : props.created_password && props.status ? (
        <>
          <div
            className={`flex items-center justify-center w-8 px-1 py-0.5 rounded-2 ${
              props.status.active
                ? "bg-suplementary-1"
                : "border border-suplementary-1"
            }`}
          >
            <span className="">
              {props.status.active ? textData.active : textData.inactive}
            </span>
          </div>
          <Button
            loading={props.loading}
            onClick={
              props.status.active
                ? props.status.onDeactivate
                : props.status.onActivate
            }
            small
            icon={props.status.active ? IconMinus : IconPlus}
            hint={
              props.status.active
                ? "Make inactive (will no longer receive notifications)"
                : "Activate"
            }
          />
        </>
      ) : props.created_password ? (
        <>
          <div className="flex items-center justify-center w-8 px-1 py-0.5 rounded-2 bg-suplementary-1 font-bold">
            <b> Active</b>
          </div>

          <Button
            onClick={showResetPasswordConfirm}
            hint="Send Reset Password Link"
            small
            icon={IconLockOpen}
          />
        </>
      ) : (
        <>
          <div className="flex items-center justify-center w-8 px-1 py-0.5 rounded-2 bg-suplementary-1 font-bold">
            <b> Invited</b>
          </div>
          <Button
            onClick={async () => {
              props.reSendInvite();
            }}
            hint="Resend Invite"
            small
            icon={IconSend}
          />
        </>
      )}

      {props.onRemoveFromTeam &&
        subview === "active" &&
        section === "admins" && (
          <Button
            loading={props.loading}
            onClick={() => props.onRemoveFromTeam?.(props.userId)}
            small
            icon={IconUserMinus}
            hint={
              "Remove this User’s Admin Credentials?\nThis will remove their access to this Admin Level of your organization and all projects"
            }
          />
        )}

      <BPopconfirm
        title="Are you sure?"
        description={
          props.terminatedAt ? (
            <div className="w-24">
              <b>Reactive User</b>
              <br />
              Restore access for this previously deactivated user, allowing
              access to SiteForm.
            </div>
          ) : (
            <div className="w-24">
              <b>Deactivate User</b>
              <br />
              Remove access for current or terminated employees. This will log
              them out of SiteForm, prevent future logins, and cancel any
              pending invites if a password has not been set.
            </div>
          )
        }
        onConfirm={activateOrDeactivateUser}
      >
        <Button
          loading={props.loading}
          onClick={() => {}}
          small
          green={!!props.terminatedAt}
          delete={!props.terminatedAt}
          icon={props.terminatedAt ? IconArrowBackUp : IconUserX}
        />
      </BPopconfirm>
    </div>
  );
};

export default GCProjectTeamListItem;
