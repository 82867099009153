import OrientationModulesTable from "../../../../components/OrientationModulesTable";
import React, { useState } from "react";
import useGcOrientationData from "../../../../utils/useGcOrientationData";
import { useUserData } from "../../../../../../utility-features/authorization/UserDataProvider";
import CreateNewOrientationModal from "../../../../../../common/components/dialogs/CreateNewOrientationModal";
import { useNavigate } from "react-router-dom";
import getGcProjectPath from "../../../../../../root/routes/views/general-contractor/hierarchy/projects/getGcProjectPath";

interface GCProjectOrientationBuilderModulesProps {
  projectId: string;
}

const GCProjectOrientationBuilderModules: React.FC<
  GCProjectOrientationBuilderModulesProps
> = (props) => {
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { getProjectOrientation, getOrientations } = useGcOrientationData(
    props.projectId,
  );

  const projectData = getProjectOrientation();
  const orientations = getOrientations();
  const { userData } = useUserData();
  const gcId = projectData.general_contractor_id;

  const isHigherLevelAdmin = !!(
    projectData.gc_business_unit?.gc_business_unit_employees_aggregate.aggregate
      ?.count ||
    projectData.gc_office?.gc_office_employees_aggregate.aggregate?.count ||
    projectData.gc_division?.gc_division_employees_aggregate.aggregate?.count ||
    userData.employee?.is_corporate_admin
  );

  const openModule = (orientationId: string) => {
    navigate(
      getGcProjectPath(
        props.projectId,
        `orientation/builder/module/${orientationId}`,
      ),
    );
  };

  return (
    <div>
      <CreateNewOrientationModal
        visible={showCreateModal}
        projectId={props.projectId}
        gcProjectIds={projectData.general_contractor.gc_projects.map(
          (p) => p.project_id,
        )}
        gcId={gcId}
        onSuccess={(orientationId) => {
          setShowCreateModal(false);
          openModule(orientationId);
        }}
        onCancel={() => setShowCreateModal(false)}
      />

      <OrientationModulesTable
        isHigherLevelLogin={isHigherLevelAdmin}
        hasUniversal={projectData.agc_universal_orientation}
        onAddOrientation={() => {
          setShowCreateModal(true);
        }}
        hasInPerson={projectData.in_person_orientation}
        projectId={props.projectId}
        dataSource={orientations}
        onRow={(item: any) => {
          const orientationId = item.pk;
          openModule(orientationId);
        }}
      />
    </div>
  );
};

export default GCProjectOrientationBuilderModules;
