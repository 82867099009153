import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import GCAdminIncidentsOverview from "../../components/incident/GCAdminIncidentsOverview";

const GCCorporateIncidentsOverview: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => (
  <GCAdminIncidentsOverview
    organizationLevel="gc"
    organizationId={employee.general_contractor.id}
    severityUserFilter={{
      gc_id: { _eq: employee.general_contractor.id },
      user: { employee: { is_corporate_admin: { _eq: true } } },
    }}
    toInsertWith={{ gc_id: employee.general_contractor.id }}
  />
);
export default withCorporateAdminWrapper(GCCorporateIncidentsOverview);
