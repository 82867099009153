/**
 * @generated SignedSource<<02378e85211f4cde91793fdf581f8905>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_to_verify_new_users_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation$variables = {
  objects: ReadonlyArray<user_to_verify_new_users_insert_input>;
};
export type SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation$data = {
  readonly insert_user_to_verify_new_users: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly user: {
        readonly name: string;
      };
      readonly user_id: string;
    }>;
  } | null | undefined;
};
export type SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation = {
  response: SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation$data;
  variables: SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user_to_verify_new_users_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_to_verify_new_users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_to_verify_new_users",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user_to_verify_new_users_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_to_verify_new_users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_to_verify_new_users",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5464c28fcc74b0bbae441fb29410a733",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationSettingSwitchCards_Insert_Users_To_Verify_New_Users_Mutation(\n  $objects: [user_to_verify_new_users_insert_input!]!\n) {\n  insert_user_to_verify_new_users(objects: $objects) {\n    returning {\n      user_id\n      id\n      user {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5887c0a488290e74e35fa78fd56f471d";

export default node;
