import React, { FC } from "react";
import { Switch, Typography } from "antd";
import ExplanationIcon from "./ExplanationIcon";

interface SwitchWithTextProps {
  checked: boolean;
  text: React.ReactNode;
  header?: string;
  subText?: string;
  explanation?: React.ReactNode;
  loading?: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const SwitchWithText: FC<SwitchWithTextProps> = (props) => {
  return (
    <div className="flex flex-row gap-0.5 items-center">
      <Typography className={`flex-1`}>
        {/* Text */}
        {!!props.header && (
          <>
            <Typography.Text strong>{props.header}</Typography.Text>
            {!!props.explanation && (
              <>
                {" "}
                <ExplanationIcon content={props.explanation} />
              </>
            )}
            <br />
          </>
        )}

        <Typography.Text> {props.text}</Typography.Text>
        {!props.header && !!props.explanation && (
          <>
            {" "}
            <ExplanationIcon content={props.explanation} />
          </>
        )}
        {props.subText && (
          <>
            <br />
            <Typography.Text type={"secondary"}>
              {props.subText}
            </Typography.Text>
          </>
        )}
      </Typography>

      <Switch
        disabled={props.disabled}
        loading={props.loading}
        checked={props.checked}
        onChange={props.onChange}
      />
    </div>
  );
};

export default SwitchWithText;
