import { useLocation, useNavigate } from "react-router-dom";
import GCNavigationHierarchyMenuProject from "./project/GCNavigationHierarchyMenuProject";
import useGcHierarchyTree, {
  GCHierarchyTreeNode,
} from "./utils/useGcHierarchyTree";
import GCNavigationHierarchyMenuUI, {
  GCNavigationHierarchyMenuUiProps,
} from "./GCNavigationHierarchyMenuUI";
import GCNavigationHierarchyMenuCorporateOffice from "./corporate-office/GCNavigationHierarchyMenuCorporateOffice";
import GCNavigationHierarchyMenuOrganizationUnit from "./organization-unit/GCNavigationHierarchyMenuOrganizationUnit";
import { useEffect, useMemo } from "react";
import useGetNodeIdAndType from "./utils/useGetNodeIdAndType";

// Constants
const HIERARCHY_NAVIGATION_BASE_PATH = "/gce/hierarchy";
const HIERARCHY_UNITS_BASE_PATHS = {
  corporateOffice: `${HIERARCHY_NAVIGATION_BASE_PATH}/corporate-office`,
  division: `${HIERARCHY_NAVIGATION_BASE_PATH}/organization-unit/division`,
  businessUnit: `${HIERARCHY_NAVIGATION_BASE_PATH}/organization-unit/business_unit`,
  office: `${HIERARCHY_NAVIGATION_BASE_PATH}/organization-unit/office`,
  project: `${HIERARCHY_NAVIGATION_BASE_PATH}/project`,
};

// Component
const GCNavigationHierarchyMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    hierarchyTree,
    loading: dataLoading,
    searchInLocalTree,
  } = useGcHierarchyTree();

  //
  const getSubPath = (fullPath: string, staticPathPart: string) => {
    const subRoutes = fullPath.replace(staticPathPart, "").split("/");
    if (subRoutes.length > 2) {
      return subRoutes[2];
    }
    return null;
  };

  // Go to office or project
  const goToNode = (id: string) => {
    const foundItem = searchInLocalTree(id);
    const nodeType = foundItem?.type;
    let hierarchyNodePath: null | string = null;
    let subPath: null | string = null;

    // Regarding subPath...
    // 1. Get sub path from the current location
    // 2. Check if the new location node type is relevant to the current location node type
    // 3. If not, clear the sub path
    // 4. If yes, keep the sub path

    const prevNodeType = selectedNode?.type;
    if (prevNodeType) {
      if (prevNodeType === "project" && nodeType === "project") {
        subPath = getSubPath(
          location.pathname,
          HIERARCHY_UNITS_BASE_PATHS.project,
        );
      } else if (
        (prevNodeType === "office" ||
          prevNodeType === "division" ||
          prevNodeType === "business_unit") &&
        (nodeType === "office" ||
          nodeType === "division" ||
          nodeType === "business_unit")
      ) {
        const staticPath = (() => {
          switch (prevNodeType) {
            case "office":
              return HIERARCHY_UNITS_BASE_PATHS.office;
            case "division":
              return HIERARCHY_UNITS_BASE_PATHS.division;
            case "business_unit":
              return HIERARCHY_UNITS_BASE_PATHS.businessUnit;
            default:
              return null;
          }
        })();
        if (staticPath) {
          subPath = getSubPath(location.pathname, staticPath);
        }
      }
    }
    // Build the path
    switch (nodeType) {
      case "corporate_office":
        hierarchyNodePath = `${HIERARCHY_UNITS_BASE_PATHS.corporateOffice}`;
        break;
      case "division":
        // TODO unify the organization unit paths
        hierarchyNodePath = `${HIERARCHY_UNITS_BASE_PATHS.division}/${id}`;
        break;
      case "business_unit":
        // TODO unify the organization unit paths
        hierarchyNodePath = `${HIERARCHY_UNITS_BASE_PATHS.businessUnit}/${id}`;
        break;
      case "office":
        // TODO unify the organization unit paths
        hierarchyNodePath = `${HIERARCHY_UNITS_BASE_PATHS.office}/${id}`;
        break;
      case "project":
        hierarchyNodePath = `${HIERARCHY_UNITS_BASE_PATHS.project}/${id}`;
        break;
      default:
        break;
    }
    if (hierarchyNodePath) {
      let pathToNavigate = hierarchyNodePath;
      if (subPath) {
        pathToNavigate += `/${subPath}`;
      }
      navigate(pathToNavigate);
    }
  };

  //
  const { nodeId, nodeType } = useGetNodeIdAndType();
  const selectedNode = useMemo(
    () => (nodeId ? searchInLocalTree(nodeId) : undefined),
    [searchInLocalTree, nodeId, hierarchyTree],
  );

  // Remap the original tree to UI type

  const remapTree: (
    originalTree: GCHierarchyTreeNode[],
  ) => GCNavigationHierarchyMenuUiProps["tree"] = (originalTree) =>
    originalTree.map((node) => {
      return node.type === "project"
        ? {
            label: node.label,
            value: node.value,
            type: "project",
            completed: node.completed,
          }
        : {
            label: node.label,
            value: node.value,
            children: node.children ? remapTree(node.children) : undefined,
            type:
              node.type === "corporate_office"
                ? "corporate_office"
                : "organization_unit",
          };
    });

  const hierarchyTreeToDisplay: GCNavigationHierarchyMenuUiProps["tree"] =
    remapTree(hierarchyTree);

  // Redirect to the first node if no node is selected
  useEffect(() => {
    const path = location.pathname;
    if (
      path.startsWith(HIERARCHY_NAVIGATION_BASE_PATH) &&
      !nodeId &&
      hierarchyTree.length > 0
    ) {
      goToNode(hierarchyTree[0].value);
    }
  }, [hierarchyTree, nodeId]);

  return (
    <div>
      <GCNavigationHierarchyMenuUI
        onSelect={(selectedNode) => {
          goToNode(selectedNode.value);
        }}
        // TODO allow clearing the selected node -> redirect to "Home" page
        // onClear={clearSelectedNode}
        tree={hierarchyTreeToDisplay}
        loading={dataLoading}
        selectedNodeValue={nodeId}
      />
      {(() => {
        // TODO change null to a default view
        switch (nodeType) {
          case "corporate_office":
            return <GCNavigationHierarchyMenuCorporateOffice />;

          case "project":
            return nodeId ? (
              <GCNavigationHierarchyMenuProject projectId={nodeId} />
            ) : null;
          case "organization_unit":
            if (nodeId) {
              const organizationUnit =
                selectedNode &&
                (selectedNode.type === "business_unit" ||
                  selectedNode.type === "division" ||
                  selectedNode.type === "office")
                  ? {
                      id: selectedNode.value,
                      name: selectedNode.label,
                      type: selectedNode.type,
                    }
                  : undefined;
              if (organizationUnit) {
                return (
                  <GCNavigationHierarchyMenuOrganizationUnit
                    // TODO deprecate selectedNode.type check
                    organizationUnit={organizationUnit}
                  />
                );
              }
            }
            break;
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default GCNavigationHierarchyMenu;
