/**
 * @generated SignedSource<<9fa5fc8121214ca1a4e2967db5ff2eb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompanySettingsGeneral_removeProjectData_Mutation$variables = {
  general_contractor_id: string;
};
export type GCCompanySettingsGeneral_removeProjectData_Mutation$data = {
  readonly delete_procore_project_data: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly delete_procore_subcontractor: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type GCCompanySettingsGeneral_removeProjectData_Mutation = {
  response: GCCompanySettingsGeneral_removeProjectData_Mutation$data;
  variables: GCCompanySettingsGeneral_removeProjectData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "general_contractor_id"
  }
],
v1 = {
  "_is_null": true
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "general_contractor_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "general_contractor_id"
          },
          {
            "kind": "Literal",
            "name": "procore_project_data",
            "value": {
              "service_account_client_id": (v1/*: any*/),
              "user_id": (v1/*: any*/)
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "project"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "filters": null,
    "handle": "deleteRecord",
    "key": "",
    "kind": "ScalarHandle",
    "name": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompanySettingsGeneral_removeProjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "delete_procore_subcontractor",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_project_data_mutation_response",
        "kind": "LinkedField",
        "name": "delete_procore_project_data",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_project_data",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCCompanySettingsGeneral_removeProjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "delete_procore_subcontractor",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_project_data_mutation_response",
        "kind": "LinkedField",
        "name": "delete_procore_project_data",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_project_data",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6582b0fc0e7b25adf6d4c6a459000b9a",
    "id": null,
    "metadata": {},
    "name": "GCCompanySettingsGeneral_removeProjectData_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCCompanySettingsGeneral_removeProjectData_Mutation(\n  $general_contractor_id: uuid!\n) {\n  delete_procore_subcontractor(where: {project: {general_contractor_id: {_eq: $general_contractor_id}, procore_project_data: {user_id: {_is_null: true}, service_account_client_id: {_is_null: true}}}}) {\n    affected_rows\n    returning {\n      id\n    }\n  }\n  delete_procore_project_data(where: {project: {general_contractor_id: {_eq: $general_contractor_id}, procore_project_data: {user_id: {_is_null: true}, service_account_client_id: {_is_null: true}}}}) {\n    affected_rows\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f9ca0a3581aa3781e6645a40e4a0ac99";

export default node;
