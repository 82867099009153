import GCAdminHeaderFilterButton, {
  GCAdminHeaderFilterButtonProps,
} from "src/root/routes/views/general-contractor/hierarchy/components/filter-inputs/GCAdminHeaderFilterButton";
import { Space, Flex, Divider } from "antd";
import { useState, useEffect } from "react";
import GCAdminHeaderFilterTags from "src/root/routes/views/general-contractor/hierarchy/components/filter-inputs/GCAdminHeaderFilterTags";
import dayjs from "dayjs";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import {
  GetAgcDashboardDataQueryVariables,
  GetAgcDashboardDataQuery,
  User_Bool_Exp,
  Project_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ApolloQueryResult } from "@apollo/client";
import useIsFirstRender from "src/common/hooks/useIsFirstRender";

export interface AGCDashboardHeaderProps {
  gcOptions: { label: string; value: string }[];
  variables: GetAgcDashboardDataQueryVariables;
  refetch: (
    variables?: Partial<GetAgcDashboardDataQueryVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetAgcDashboardDataQuery>>;
}

const AGCDashboardHeader: React.FC<AGCDashboardHeaderProps> = ({
  gcOptions,
  refetch,
  variables,
}) => {
  const [gcFilter, handleGcChange, setGcFilter] = useSelectedIndicies<string>();
  const isFirstRender = useIsFirstRender();
  const [dateFilter, setDateFilter] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);
  useEffect(() => {
    const userUoFiltering: User_Bool_Exp =
      dateFilter && dateFilter.length > 0
        ? {
            universal_orientations: {
              universal_orientated_at: {
                ...(dateFilter[0] ? { _gte: dateFilter[0].toISOString() } : {}),
                ...(dateFilter[1] ? { _lte: dateFilter[1].toISOString() } : {}),
              },
            },
          }
        : {
            universal_orientations: {
              universal_orientated_at: { _is_null: false },
            },
          };
    const projectGcFiltering: Project_Bool_Exp =
      gcFilter.size > 0
        ? { general_contractor_id: { _in: [...gcFilter] } }
        : {};

    if (!isFirstRender) {
      if ((!dateFilter || !dateFilter.length) && !gcFilter.size) {
        refetch(variables);
        return;
      }
      refetch({
        ...variables,
        userWhere: {
          ...userUoFiltering,
          worker: { worker_projects: { project: projectGcFiltering } },
        },
        projWhere: {
          agc_universal_orientation: { _eq: true },
          ...projectGcFiltering,
        },
        gcWhere: {
          agc_universal_orientation: { _eq: true },
          ...(gcFilter.size > 0 ? { id: { _in: [...gcFilter] } } : {}),
        },
        projWorkerWhere: {
          is_last: { _eq: true },
          user: userUoFiltering,
          project: {
            agc_universal_orientation: { _eq: true },
            ...projectGcFiltering,
          },
        },
      });
    }
  }, [gcFilter, dateFilter]);
  const filters: GCAdminHeaderFilterButtonProps["filters"] = {
    dates: { selectedDates: dateFilter, onSelectedDatesChange: setDateFilter },
    selectFilters: [
      {
        options: gcOptions,
        selectedItems: gcFilter,
        handleItemChange: handleGcChange,
        title: "General Contractors",
        placeholder: "All GCs Included",
        setSelectedItems: setGcFilter,
      },
    ],
  };
  return (
    <Space direction={"vertical"}>
      <Flex justify={"end"}>
        <Space>
          <GCAdminHeaderFilterButton filters={filters} />
        </Space>
      </Flex>
      <Divider />
      <GCAdminHeaderFilterTags filters={filters} />
    </Space>
  );
};

export default AGCDashboardHeader;
