import React, { useState } from "react";
import { GetIncidentByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Card, Image, Modal } from "antd";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Icon from "src/common/components/general/Icon";
import {
  IconClipboardData,
  IconDeviceAudioTape,
  IconTrash,
  IconVideo,
} from "@tabler/icons";
import PdfModal from "src/common/components/modals/PdfModal";
import AudioPlayer from "src/common/components/media-players/audio-player/AudioPlayer";
import VideoPlayerForAnyUrl from "src/common/components/media-players/video-player/VideoPlayerForAnyUrl";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";

import dayjs from "dayjs";

const { Meta } = Card;

export interface DeletedDocument {
  id: string;
  url: string;
  name: string;
}

interface IncidentDocViewerProps {
  documents: NonNullable<
    GetIncidentByPkQuery["incident_by_pk"]
  >["incident_types"][number]["documents"];
  deleteDocument: (doc: DeletedDocument) => Promise<void>;
}

const IncidentDocViewer: React.FunctionComponent<IncidentDocViewerProps> = ({
  documents,
  deleteDocument,
}) => {
  const [selectedPdf, setSelectedPdf] = useState<string>();
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>();

  return (
    <>
      <Modal
        footer={<></>}
        width={700}
        open={!!selectedVideoUrl}
        onCancel={() => {
          setSelectedVideoUrl(undefined);
        }}
        destroyOnClose
      >
        {selectedVideoUrl && <VideoPlayerForAnyUrl url={selectedVideoUrl} />}
      </Modal>
      <PdfModal
        visible={!!selectedPdf}
        pdfUrl={selectedPdf}
        destroyOnClose
        onClose={() => setSelectedPdf(undefined)}
      />
      {documents.length > 0 && (
        <>{documents.at(0)?.image ? "Images" : "Videos or Documents"}:</>
      )}
      <div className="flex gap-0.5 flex-wrap">
        {documents?.map((doc, i) => {
          const createdDate = dayjs(doc.created_at).format("DD-MMM-YYYY");

          return doc.image ? (
            <Card
              key={doc.id}
              className="w-1/6"
              styles={{
                body: {
                  padding: 2,
                  borderColor: "black",
                  borderWidth: 2,
                  borderStyle: "solid",
                },
              }}
            >
              <div className="relative z-0">
                <Image
                  className="z-0"
                  src={doc.url}
                  alt={"Image: " + (i + 1)}
                />
              </div>
              <Meta
                description={
                  <BPopconfirm
                    onConfirm={(e) => {
                      e?.stopPropagation();
                      deleteDocument({
                        id: doc.id,
                        url: doc.url,
                        name: doc.name ?? "Deleted Document",
                      });
                    }}
                    title="Remove this Image from the incident"
                  >
                    <div className="flex justify-between cursor-pointer p-0.5">
                      Delete <Icon icon={IconTrash} color="negative" />
                    </div>
                  </BPopconfirm>
                }
              />
              <div className="text-xs truncate">
                {doc.name + " (" + createdDate + ")"}
              </div>
            </Card>
          ) : (
            <Card
              key={doc.id}
              onClick={() => {
                if (doc.document_type?.includes("video")) {
                  setSelectedVideoUrl(doc.url);
                } else if (doc.document_type?.includes("pdf")) {
                  setSelectedPdf(doc.url);
                }
              }}
              styles={{
                body: {
                  padding: 5,
                  border: "1px solid black",
                },
              }}
            >
              <div className="flex mx-1 justify-between">
                <Icon
                  icon={
                    doc.document_type?.includes("video")
                      ? IconVideo
                      : doc.document_type?.includes("audio")
                      ? IconDeviceAudioTape
                      : IconClipboardData
                  }
                />
                <div className="cursor-pointer">
                  <BPopconfirm
                    onConfirm={(e) => {
                      e?.stopPropagation();
                      deleteDocument({
                        id: doc.id,
                        url: doc.url,
                        name: doc.name ?? "Deleted Document",
                      });
                    }}
                    title="Remove this Doc from the incident"
                  >
                    <Icon icon={IconTrash} color="negative" />
                  </BPopconfirm>
                </div>
              </div>
              <div className="text-xs truncate">
                {doc.name + " (" + createdDate + ")"}
              </div>
              {doc.document_type?.includes("audio") && (
                <LanguageProvider>
                  <AudioPlayer
                    audioSrc={doc.url}
                    allowedControls={["rewind", "skip"]}
                  />
                </LanguageProvider>
              )}
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default IncidentDocViewer;
