import SiteOrientationSettingSwithCards from "../settings/components/SiteOrientationSettingSwitchCards";
import { Card } from "antd";
import React, { useMemo } from "react";
import useGcProjectOrientationParams from "../../../../utils/useGcProjectOrientationParams";
import useGcOrientationData from "../../../../utils/useGcOrientationData";
import CustomSuspense from "../../../../../../common/components/general/CustomSuspense";

interface GCProjectOrientationBuilderOtherSettingsProps {
  projectId: string;
}

const GCProjectOrientationBuilderOtherSettings: React.FC<
  GCProjectOrientationBuilderOtherSettingsProps
> = (props) => {
  const { orientationData, getProjectOrientation } = useGcOrientationData(
    props.projectId,
  );
  const projectData = getProjectOrientation();
  const params = useGcProjectOrientationParams(props.projectId);

  const gcUserOptions = useMemo(
    () =>
      projectData.project_employees.map((pe) => ({
        label: pe.employee.user.name,
        value: pe.employee.uid,
      })),
    [projectData.project_employees],
  );

  return (
    <SiteOrientationSettingSwithCards
      projectId={props.projectId}
      params={params}
      userOptions={gcUserOptions}
      emailProjectUsers={orientationData.email_project_user_connection.edges}
      currentlySelectedUsersToVerifyNewUsers={
        orientationData.user_to_verify_new_users_connection.edges
      }
      allCertificates={orientationData.certification_connection.edges}
      currentlySelectedCertificates={projectData.project_certifications}
    />
  );
};

const GCProjectOrientationBuilderOtherSettingsCard: React.FC<
  GCProjectOrientationBuilderOtherSettingsProps
> = (props) => {
  return (
    <Card title={"Other Orientation Settings"}>
      <CustomSuspense>
        <GCProjectOrientationBuilderOtherSettings {...props} />
      </CustomSuspense>
    </Card>
  );
};

export default GCProjectOrientationBuilderOtherSettingsCard;
