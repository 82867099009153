import { Button, Input, Select } from "antd";
import { memo, useMemo, useState } from "react";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import {
  GetCsrProjectEmployeesDocument,
  GetCsrProjectEmployeesQuery,
  GetCsrProjectEmployeesQueryVariables,
  Order_By,
  Project_Employee_Order_By,
  useCsrUpdateGeneralContractorEmployeeMutation,
  useCsrUpdateProjectEmployeeMutation,
  useGetGeneralContractorEmployeesQuery,
  useInsertCsrEditMutation,
  useInsertProjectEmployeesMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import UserUpdateString from "../components/UserUpdateString";
import { PlusOutlined } from "@ant-design/icons";
import SwitchWithText from "src/common/components/SwitchWithText";
import AddProjectEmployeeModal from "src/common/components/dialogs/AddProjectEmployeeModal";
import useAuthUser from "src/common/hooks/useAuthUser";

const SelectTrade = memo(
  ({
    value,
    emp,
    options,
  }: {
    value: string | undefined | null;
    options: Array<{ label: string; value: string }>;
    emp: GetCsrProjectEmployeesQuery["project_employee"][number]["employee"];
  }) => {
    const authUser = useAuthUser();
    const [updateTitle, { loading }] =
      useCsrUpdateGeneralContractorEmployeeMutation();
    return (
      <Select
        className="w-24"
        value={value}
        options={options}
        onChange={(val) => {
          const _set = { title_id: val };
          updateTitle({
            variables: {
              _set,
              uid: emp.uid,
              csrEdits: [
                {
                  change_log: [{ _set, prevVal: value }],
                  csr_user_id: authUser.uid,
                  edit_text: `Changed title for ${emp.user.name}`,
                  entity_id: emp.uid,
                  name: "general_contractor_employee",
                  operation_name: "update",
                },
              ],
            },
            optimisticResponse: {
              update_general_contractor_employee_by_pk: {
                ...emp,
                title_id: val,
              },
              insert_csr_edit: { affected_rows: 1 },
            },
          });
        }}
        loading={loading}
      />
    );
  },
);

const CsrProjectEmployees: React.FunctionComponent<{
  projectId: string;
  tradeOptions: Array<{ label: string; value: string }>;
}> = ({ projectId, tradeOptions }) => {
  const { orderBy, setOrderBy } = useColumnOrderBy<Project_Employee_Order_By>({
    employee: {
      user: { name: Order_By.Asc },
    },
  });
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const authUser = useAuthUser();
  const nameSearch = useColumnSearch();
  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<
      GetCsrProjectEmployeesQueryVariables["where"],
      GetCsrProjectEmployeesQueryVariables
    > => ({
      where: {
        project_id: { _eq: projectId },
        // direct_project_assign: { _eq: true },
        ...(nameSearch.value
          ? {
              employee: {
                user: {
                  _or: [
                    { name: { _ilike: nameSearch.value } },
                    { email: { _ilike: nameSearch.value } },
                  ],
                },
              },
            }
          : {}),
      },
      extraQueryVariables: { projectId },
      orderBy: orderBy,
      queryDoc: GetCsrProjectEmployeesDocument,
      dataIndex: "project_employee",
      aggregateCountIndex: "project_employee_aggregate",
    }),
    [nameSearch.value, orderBy],
  );
  const [insertCsrEdit] = useInsertCsrEditMutation();
  const [updateProjEmployee] = useCsrUpdateProjectEmployeeMutation();
  const [insertProjectEmployees, { loading: insertingProjectEmployee }] =
    useInsertProjectEmployeesMutation();
  const [employees, { loading, page, setPage, count, pageSize, refetch }] =
    usePaginatedQuery<
      GetCsrProjectEmployeesQuery["project_employee"][number],
      GetCsrProjectEmployeesQuery
    >(queryVariables);
  const { data: projectEmployeeData, loading: projectEmployeeLoading } =
    useGetGeneralContractorEmployeesQuery({
      variables: {
        order_by: [{ user: { name: Order_By.Asc } }],
        where: {
          general_contractor: {
            gc_projects: { project_id: { _eq: projectId } },
          },
          _not: { employee_projects: { project_id: { _eq: projectId } } },
        },
      },
    });
  console.log(employees, loading);
  return (
    <>
      <AddProjectEmployeeModal
        gcEmployees={projectEmployeeData?.general_contractor_employee ?? []}
        visible={addEmployeeOpen}
        onCancel={() => setAddEmployeeOpen(false)}
        onCreate={async (values) => {
          const object = {
            employee_id: values.employeeUid,
            project_id: projectId,
          };
          const { data: insertedData } = await insertProjectEmployees({
            awaitRefetchQueries: true,
            variables: {
              objects: [object],
            },
          });
          if (insertedData?.insert_project_employee?.returning[0]?.id)
            await insertCsrEdit({
              variables: {
                csrEdits: [
                  {
                    change_log: [object],
                    csr_user_id: authUser.uid,
                    edit_text: `added new project employee relation`,
                    entity_id:
                      insertedData.insert_project_employee.returning[0].id,
                    name: "project_employee",
                    operation_name: "insert",
                  },
                ],
              },
            });
          else
            throw new Error(
              "No returining id found for inserted relation of project and employee",
            );
          setAddEmployeeOpen(false);
          refetch();
        }}
      />
      <BaseTable<(typeof employees)[number]>
        loading={loading}
        columns={[
          {
            title: "Name",
            key: "name",
            dataIndex: ["user", "name"],
            render: (_, r) => (
              <UserUpdateString type="name" row={r.employee.user} />
            ),
          },
          {
            title: "Email",
            key: "email",
            dataIndex: ["user", "email"],
            render: (_, r) => (
              <UserUpdateString type="email" row={r.employee.user} />
            ),
          },
          {
            title: "Trade",
            key: "trade",
            dataIndex: ["employee", "title_id"],
            render: (v, r) => {
              return (
                <SelectTrade
                  value={v}
                  emp={r.employee}
                  options={tradeOptions}
                />
              );
            },
          },
          {
            title: "status",
            key: "status",
            dataIndex: ["status"],
            render: (v, r) => {
              return (
                <SwitchWithText
                  text="Active"
                  checked={r.status === "Active"}
                  onChange={(checked) => {
                    const newStatus = checked ? "Active" : "Inactive";
                    const _set = { status: newStatus };
                    updateProjEmployee({
                      variables: {
                        _set,
                        projEmpId: r.id,
                        csrEdits: [
                          {
                            change_log: [{ _set }],
                            csr_user_id: authUser.uid,
                            edit_text: `Changed status to ${newStatus} for ${r.employee.user.name}`,
                            entity_id: r.employee.uid,
                            name: "project_employee",
                            operation_name: "update",
                          },
                        ],
                      },
                      optimisticResponse: {
                        update_project_employee_by_pk: {
                          __typename: "project_employee",
                          id: r.id,
                          status: newStatus,
                        },
                      },
                    });
                  }}
                />
              );
            },
          },
        ]}
        sortColumns={{
          name: true,
          workers: true,
        }}
        onChange={(_: any, filters: any, sorter: any) => {
          setOrderBy(sorter);
        }}
        title={() => (
          <div className="flex justify-between">
            <div className="flex gap-1">
              <h3>Employees</h3>
              <Input
                type="primary"
                style={{ margin: "10px", width: "200px" }}
                placeholder={"Search"}
                onChange={(event) => {
                  nameSearch.setter(event.target.value);
                }}
              ></Input>
            </div>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setAddEmployeeOpen(true); //TODO cd OPTION TO ADD EMPLOYEE TO PROJ
              }}
            >
              Add Employee
            </Button>
          </div>
        )}
        searchColumns={{ name: nameSearch.setter, email: nameSearch.setter }}
        dataSource={employees}
        pagination={{
          current: page,
          pageSize,
          total: count,
          onChange: setPage,
        }}
      />
    </>
  );
};
export default CsrProjectEmployees;
