import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import GetFullID from "src/common/functions/GetFullId";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCCompanyHierarchyMutation } from "src/common/types/generated/relay/GCCompanyHierarchyMutation.graphql";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";

const GCCompanyHierarchy: React.FC<CorporateAdminWrapperProps> = ({
  employee: emp,
}) => {
  const gc = emp.general_contractor;
  const [divisionLevelName, setDivisionLevelName] = useState(
    gc.hierarchy_division_name,
  );
  const [buLevelName, setBuLevelName] = useState(
    gc.hierarchy_business_unit_name,
  );
  const [officeLevelName, setOfficeLevelName] = useState(
    gc.hierarchy_office_name,
  );
  const { updateUserData } = useUserData();
  const [updateGCData, updating] =
    useAsyncMutation<GCCompanyHierarchyMutation>(graphql`
      mutation GCCompanyHierarchyMutation(
        $gcId: uuid!
        $_set: general_contractor_set_input!
      ) {
        update_general_contractor_by_pk(
          pk_columns: { id: $gcId }
          _set: $_set
        ) {
          id
          hierarchy_division_name
          hierarchy_business_unit_name
          hierarchy_office_name
        }
      }
    `);

  const [addDivisionLevel, setAddDivisionLevel] = useState(
    !!gc.hierarchy_division_name?.trim(),
  );
  const [addBuLevel, setAddBuLevel] = useState(
    !!gc.hierarchy_business_unit_name?.trim(),
  );
  const [addOfficeLevel, setAddOfficeLevel] = useState(
    !!gc.hierarchy_office_name?.trim(),
  );
  const changed =
    gc.hierarchy_business_unit_name?.trim() !== buLevelName?.trim() ||
    gc.hierarchy_division_name?.trim() !== divisionLevelName?.trim() ||
    gc.hierarchy_office_name?.trim() !== officeLevelName?.trim();
  const onSave = async () => {
    if (!changed) {
      return;
    }
    const changes = {
      hierarchy_division_name:
        addDivisionLevel && divisionLevelName?.trim()
          ? divisionLevelName?.trim()
          : null,
      hierarchy_business_unit_name:
        addBuLevel && buLevelName?.trim() ? buLevelName?.trim() : null,
      hierarchy_office_name:
        addOfficeLevel && officeLevelName?.trim()
          ? officeLevelName?.trim()
          : null,
    };

    await updateGCData({
      variables: { gcId: gc.id, _set: changes },
      optimisticResponse: {
        update_general_contractor_by_pk: {
          id: GetFullID("general_contractor", gc.id),
          ...changes,
        },
      },
    });
    updateUserData((user) => ({
      ...user,
      employee: user?.employee
        ? {
            ...user.employee,
            general_contractor: {
              ...user.employee.general_contractor,
              ...changes,
            },
          }
        : undefined,
    }));
  };
  return (
    <div>
      <div>
        {
          "An organization can have 3 hierarchy levels between the Corporate Office and Projects. For example, Corporate Office > Regions > Business Units > Offices > Projects"
        }
      </div>
      <br />
      <div>Add each level of your organization</div>
      <br />
      {!(addDivisionLevel && addBuLevel && addOfficeLevel) && (
        <Button
          icon={<PlusOutlined />}
          shape="circle"
          size="small"
          onClick={() => {
            if (!addOfficeLevel) setAddOfficeLevel(true);
            else if (!addBuLevel) setAddBuLevel(true);
            else if (!addDivisionLevel) setAddDivisionLevel(true);
          }}
        />
      )}
      {addDivisionLevel && (
        <div className="flex gap-1 mb-1">
          Level 1 Name
          <Input
            value={divisionLevelName || ""}
            onChange={(e) => {
              setDivisionLevelName(e.target.value);
            }}
          />
          <Button
            icon={<MinusOutlined />}
            shape="circle"
            size="small"
            onClick={() => {
              setDivisionLevelName(null);
              setAddDivisionLevel(false);
            }}
          />
        </div>
      )}
      {addBuLevel && (
        <div className="flex gap-1 mb-1">
          Level 2 Name
          <Input
            value={buLevelName || ""}
            onChange={(e) => setBuLevelName(e.target.value)}
          />
          {!addDivisionLevel && (
            <Button
              icon={<MinusOutlined />}
              shape="circle"
              size="small"
              onClick={() => {
                setBuLevelName(null);
                setAddBuLevel(false);
              }}
            />
          )}
        </div>
      )}
      {addOfficeLevel && (
        <div className="flex gap-1 mb-1">
          Level 3 Name
          <Input
            value={officeLevelName || ""}
            onChange={(e) => setOfficeLevelName(e.target.value)}
          />
          {!addDivisionLevel && !addBuLevel && (
            <Button
              icon={<MinusOutlined />}
              shape="circle"
              size="small"
              onClick={() => {
                setOfficeLevelName(null);
                setAddOfficeLevel(false);
              }}
            />
          )}
        </div>
      )}
      <div className="w-32 flex flex-col justify-center items-center">
        <div>Corporate Office</div>
        {divisionLevelName?.trim() && (
          <>
            <div>|</div>
            <div>|</div>
            <div>{divisionLevelName.trim()}</div>
          </>
        )}
        {buLevelName?.trim() && (
          <>
            <div>|</div>
            <div>|</div>
            <div>{buLevelName.trim()}</div>
          </>
        )}
        {officeLevelName?.trim() && (
          <>
            <div>|</div>
            <div>|</div>
            <div>{officeLevelName.trim()}</div>
          </>
        )}
        <div>|</div>
        <div>|</div>
        <div>Projects</div>
      </div>
      {changed ? (
        <div className="mt-2 flex-row gap-1 flex-end">
          <Button
            onClick={() => {
              setDivisionLevelName(gc.hierarchy_division_name);
              setBuLevelName(gc.hierarchy_business_unit_name);
              setOfficeLevelName(gc.hierarchy_office_name);
              setAddDivisionLevel(!!gc.hierarchy_division_name?.trim());
              setAddBuLevel(!!gc.hierarchy_business_unit_name?.trim());
              setAddOfficeLevel(!!gc.hierarchy_office_name?.trim());
            }}
          >
            Revert back changes
          </Button>
          <Button
            type="primary"
            onClick={async () => await onSave()}
            loading={updating}
          >
            Save Changes
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default withAppPageLayout(
  withCorporateAdminWrapper(GCCompanyHierarchy),
  {
    pageTitle: "Company Hierarchy",
  },
);
