import GCDivisionTeam from "../../levels/division/team/GCDivisionEmployees";
import GCBusinessUnitTeam from "../../levels/business-unit/team/GCBusinessUnitEmployees";
import GCOfficeTeam from "../../levels/office/team/GCOfficeEmployees";
import { OrganizationUnitProps } from "../../../../components/hiearchy-navigation/utils/withOrganizationUnitWrapper";
import AppPageLayout from "../../../../../../../../common/components/layouts/main/content/AppPageLayout";

interface GCOrgUnitUsersCommonProps extends OrganizationUnitProps {
  pageTitle: string;
  includeChildNodeEmployees?: boolean;
}

const GCOrgUnitUsersCommon: React.FC<GCOrgUnitUsersCommonProps> = ({
  organizationUnitId,
  organizationUnitType,
  pageTitle,
  includeChildNodeEmployees,
}) => {
  return (
    <AppPageLayout pageTitle={pageTitle}>
      {(() => {
        switch (organizationUnitType) {
          case "division":
            return (
              <GCDivisionTeam
                hierarchyNodeManagersOnly={!includeChildNodeEmployees}
                divisionId={organizationUnitId}
              />
            );
          case "business_unit":
            return (
              <GCBusinessUnitTeam
                hierarchyNodeManagersOnly={!includeChildNodeEmployees}
                businessUnitId={organizationUnitId}
              />
            );
          case "office":
            return (
              <GCOfficeTeam
                hierarchyNodeManagersOnly={!includeChildNodeEmployees}
                officeId={organizationUnitId}
              />
            );
        }
      })()}
    </AppPageLayout>
  );
};

export default GCOrgUnitUsersCommon;
