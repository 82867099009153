import { FC, useEffect } from "react";
import { Table } from "antd";
import {
  GetIncidentEditDocument,
  GetIncidentEditQuery,
  GetIncidentEditQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import dayjs from "dayjs";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import IncidentSubViewLayout from "../IncidentSubViewLayout";

const IncidentLogsTable: FC<{ incidentId: string }> = ({ incidentId }) => {
  const { data, refetch } = useSuspenseQuery<
    GetIncidentEditQuery,
    GetIncidentEditQueryVariables
  >(GetIncidentEditDocument, {
    variables: {
      where: {
        incident_id: { _eq: incidentId },
        edit_type: { _in: ["log"] },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <IncidentSubViewLayout title="Incident Logs">
      <div className="flex flex-col gap-1">
        <Table
          bordered
          dataSource={data.incident_edit}
          columns={[
            {
              title: "Edit Time",
              dataIndex: ["edited_at"],
              render: (v) => dayjs(v).format("MMMM Do, YYYY, h:mm a"),
            },
            {
              title: "Edited By",
              dataIndex: ["edited_by_user", "name"],
              render: (v) => v,
            },
            {
              title: "Changes",
              dataIndex: ["comment", "en"],
              render: (v, r) => {
                const documentEdits: Array<{
                  action: string;
                  field: string;
                  documents: Array<{ url: string; name: string }>;
                }> = r.document_edit;

                return (
                  <div>
                    {r.comment}
                    {documentEdits.map((docEdit, index) => {
                      return (
                        <div key={index} className="mt-2">
                          {`Documents ${docEdit.action} for ${docEdit.field}`}
                          {docEdit.documents.map((doc) => (
                            <div
                              key={doc.url}
                              className="hover:text-interactive-primary py-0.25 px-0.75 text-1 cursor-pointer"
                              onClick={() => {
                                downloadFromUrl(doc.url, true);
                              }}
                            >
                              {doc.name}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </IncidentSubViewLayout>
  );
};
export default withCustomSuspense(IncidentLogsTable);
