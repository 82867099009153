import { DatePicker, Input } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useRef, useState } from "react";

const { Search } = Input;

export interface GCProjectReportsDailyMonthProps {
  startMonth: dayjs.Dayjs;
  endMonth: dayjs.Dayjs;
  onMonthChange: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void;
  onSearchSubmit: (searchValue: string) => void;
}

const { RangePicker } = DatePicker;

const GCProjectReportsDailyTopBar: React.FC<
  GCProjectReportsDailyMonthProps
> = ({ startMonth, endMonth, onMonthChange, onSearchSubmit }) => {
  const searchRef = useRef<React.ElementRef<typeof Input>>(null);

  const [localMonthRange, setLocalMonthRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([startMonth, endMonth]);

  return (
    <div className="sticky flex flex-row items-center gap-1">
      <RangePicker
        disabledDate={(current: Dayjs) => {
          const customDate = dayjs().format("MMMM YYYY");
          return (
            current && current >= dayjs(customDate, "MMMM YYYY").add(1, "month")
          );
        }}
        className={"w-20"}
        picker="month"
        format={"MMMM YYYY"}
        value={[startMonth, endMonth]}
        onChange={(newMonthRange) => {
          setLocalMonthRange(newMonthRange ?? [null, null]);
        }}
        onOpenChange={(open) => {
          if (!open) {
            const start = dayjs(localMonthRange[0]);
            const end = dayjs(localMonthRange[1]);
            start.isValid() && end.isValid() && onMonthChange(start, end);
          }
        }}
      />
      <Search
        className={`w-12`}
        ref={searchRef}
        placeholder="Search"
        allowClear
        onClear={() => {
          onSearchSubmit("");
        }}
        onSearch={onSearchSubmit}
      />
    </div>
  );
};

export default GCProjectReportsDailyTopBar;
