import { useMemo } from "react";
import { useGetUserHierarchyQuery } from "src/common/types/generated/apollo/graphQLTypes";
import {
  GcHierarchyTreeNodeType,
  GCOrganizationUnitType,
} from "../gcHierarchyTreeTypes";
import useGcEmployee from "../../../hierarchy/organization-unit/utils/useGcEmployee";

type GCHierarchyTreeNodeType =
  | Exclude<GcHierarchyTreeNodeType, "organization_unit">
  | GCOrganizationUnitType;

// Define the structure of a tree node
export interface GCHierarchyTreeNode {
  label: string;
  value: string;
  // TODO
  // put `type: GcHierarchyTreeNodeType` when organization unit type is deprecated
  type: GCHierarchyTreeNodeType;
  gc_division_id?: string | null;
  gc_business_unit_id?: string | null;
  gc_office_id?: string | null;
  children?: GCHierarchyTreeNode[];
  // TODO instead of `children` and `completed`, use `childUnits` and `projects`. Only the projects array would have `completed` prop
  completed?: boolean;
}

// Orphaned nodes grouped by their level
interface OrphanedNodes {
  [level: string]: GCHierarchyTreeNode[];
}

// Function to build the tree from multi-level lists
type LevelsListType = {
  levelNodes: GCHierarchyTreeNode[];
  levelName: string;
}[];
const searchInTree = (
  nodeList: GCHierarchyTreeNode[],
  searchVal: string,
): GCHierarchyTreeNode | undefined => {
  if (nodeList.length === 0) return undefined;
  let foundItemHere: GCHierarchyTreeNode | undefined = undefined;
  for (const node of nodeList) {
    if (node.value === searchVal) return node;
    if (node.children && node.children.length) {
      foundItemHere = searchInTree(node.children, searchVal);
    }
    if (foundItemHere) return foundItemHere;
  }
  return undefined;
};

function buildTreeWithMultipleParents(
  levels: LevelsListType,
): Omit<
  GCHierarchyTreeNode,
  "gc_office_id" | "gc_business_unit_id" | "gc_division_id"
>[] {
  const idMap: { [key: string]: GCHierarchyTreeNode } = {}; // Global ID map for all nodes
  const orphansByLevel: OrphanedNodes = {}; // Store orphans grouped by level
  const tree: GCHierarchyTreeNode[] = []; // Final tree with root nodes

  // Helper to find a node's parent based on priority
  const findParent = (
    node: GCHierarchyTreeNode,
  ): GCHierarchyTreeNode | null => {
    if (node.gc_office_id && idMap[node.gc_office_id])
      return idMap[node.gc_office_id];
    if (node.gc_business_unit_id && idMap[node.gc_business_unit_id])
      return idMap[node.gc_business_unit_id];
    if (node.gc_division_id && idMap[node.gc_division_id])
      return idMap[node.gc_division_id];
    return null;
  };

  // Helper to process a level and attach nodes to their appropriate parent
  const processLevel = ({ levelNodes, levelName }: LevelsListType[number]) => {
    levelNodes.forEach((node) => {
      idMap[node.value] = { ...node, children: [] }; // Initialize node in the global ID map

      const parent = findParent(node); // Find the parent based on priority
      if (parent) {
        // Parent exists, attach to parent's children
        
        parent.children?.push(idMap[node.value]);
        // } else {
        // Parent not found, add to orphan list for this level
        //   if (!orphansByLevel[levelName]) orphansByLevel[levelName] = [];
        //   orphansByLevel[levelName].push(idMap[node.value]);
        // }
      } else {
        // No parent references, treat as a root node
        tree.push(idMap[node.value]);
      }
    });
  };

  // Process each level
  levels.forEach(processLevel);

  // Add orphans as separate groups in the tree
  // Object.keys(orphansByLevel).forEach((level) => {
  //   tree.push({
  //     value: level,
  //     label: level,
  //     children: orphansByLevel[level],
  //     type:level.
  //   });
  // });

  return tree;
}

const useGcHierarchyTree = () => {
  const employee = useGcEmployee();
  const gc = employee.general_contractor;
  const {
    data: hierarchyData,
    loading: dataLoading,
    error,
  } = useGetUserHierarchyQuery({
    variables: {
      projEmpWhere: { employee_id: { _eq: employee.uid } },
      divisionWhere: employee.is_corporate_admin
        ? { general_contractor_id: { _eq: gc.id } }
        : { gc_division_employees: { user_id: { _eq: employee.uid } } },
      businessUnitWhere: employee.is_corporate_admin
        ? { general_contractor_id: { _eq: gc.id } }
        : {
            _or: [
              {
                gc_business_unit_employees: { user_id: { _eq: employee.uid } },
              },
              {
                gc_division: {
                  gc_division_employees: { user_id: { _eq: employee.uid } },
                },
              },
            ],
          },
      officeWhere: employee.is_corporate_admin
        ? { general_contractor_id: { _eq: gc.id } }
        : {
            _or: [
              { gc_office_employees: { user_id: { _eq: employee.uid } } },
              {
                gc_business_unit: {
                  gc_business_unit_employees: {
                    user_id: { _eq: employee.uid },
                  },
                },
              },
              {
                gc_division: {
                  gc_division_employees: { user_id: { _eq: employee.uid } },
                },
              },
            ],
          },
    },
  });
  if (error) throw error;

  // Define the structure of a node

  const hierarchyTree: GCHierarchyTreeNode[] = useMemo(() => {
    const levels: LevelsListType = [];
    if (hierarchyData) {
      if (gc.hierarchy_division_name)
        levels.push({
          levelNodes: hierarchyData.gc_division.map((d) => ({
            ...d,
            value: d.id,
            label: d.name,
            type: "division",
          })),
          levelName: gc.hierarchy_division_name,
        });
      if (gc.hierarchy_business_unit_name)
        levels.push({
          levelNodes: hierarchyData.gc_business_unit.map((d) => ({
            ...d,
            value: d.id,
            label: d.name,
            type: "business_unit",
          })),
          levelName: gc.hierarchy_business_unit_name,
        });
      if (gc.hierarchy_office_name)
        levels.push({
          levelNodes: hierarchyData.gc_office.map((d) => ({
            ...d,
            value: d.id,
            label: d.name,
            type: "office",
          })),
          levelName: gc.hierarchy_office_name,
        });

      levels.push({
        levelNodes: hierarchyData.project_employee.map((p) => ({
          ...p.project,
          value: p.project.id,
          label: p.project.name,
          type: "project",
          completed: p.project.completed,
        })),
        levelName: "Projects",
      });
    }
    const resultTree: GCHierarchyTreeNode[] =
      buildTreeWithMultipleParents(levels);

    return employee.is_corporate_admin
      ? [
          {
            label: "Corporate Office",
            value: "corporate_office",
            children: resultTree,
            type: "corporate_office",
          },
        ]
      : resultTree;
  }, [hierarchyData, gc]);

  const searchInLocalTree = (id: string) => searchInTree(hierarchyTree, id);

  return { loading: dataLoading, hierarchyTree, searchInLocalTree };
};

export default useGcHierarchyTree;
