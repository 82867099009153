import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import PermitChecklistsSettingTables from "./basic/PermitChecklistsSettingTables";
import StyledContent from "src/common/components/layouts/StyledContent";
import { Checkbox } from "antd";
import {
  useUpdateGeneralContractorForPermitMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

const OutOfBasketSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  checklistGc,
  mainGc,
  project,
}) => {
  const projSetting = project?.project_setting;
  const settingUseLevel = projSetting?.permit_setting_level;
  const requireOnlySubmitter =
    projSetting && settingUseLevel === "project"
      ? projSetting.only_submitter_sign_the_out_of_basket_permit
      : mainGc.only_submitter_sign_the_out_of_basket_permit;
  return (
    <>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: projSetting,
          field: "only_submitter_sign_the_out_of_basket_permit",
          label:
            "Check to only require the person submitting the permit (foreman/competent person) to sign. When unchecked, both the Foreman and Onsite Safety Manager are required to sign",
        }}
      />
      <PermitGcProjectSettingCheckbox
        field="hide_quick_sign_in_for_out_of_basket"
        mainGc={mainGc}
        project_setting={projSetting}
        label="Require all workers to sign the Permit. Do not allow photo documentation of the crew."
      />
      {!requireOnlySubmitter && (
        <PermitGcProjectSettingCheckbox
          field="require_workers_to_sign_out_of_basket_permit"
          mainGc={mainGc}
          project_setting={projSetting}
          label="Require Workers Exiting Basket to sign the permit"
        />
      )}
      <PermitChecklistsSettingTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "out_of_basket",
        }}
      />
    </>
  );
};
export default OutOfBasketSettings;
